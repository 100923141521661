import { Component, OnInit } from "@angular/core";
import { ProfileService } from "src/app/Services/profile.service";
import { TokenService } from "../../Services/token.service";
import { Router } from "@angular/router";
import Stepper from "bs-stepper";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Meta, Title } from "@angular/platform-browser";

import { Subscription } from "rxjs";
declare function modalImgHover(): any;
@Component({
  selector: "app-fileupload",
  templateUrl: "./fileupload.component.html",
  styleUrls: ["./fileupload.css"],
})
export class fileuploadComponent implements OnInit {
  public success = null;
  public error = null;
  private stepper: Stepper;

  public countrlist = [];
  public countrCity = [];
  public ansData = [];
  public jobs = [];
  public incomes = [];
  public netIncomes = [];
  public cryptoPurposes = [];
  public sourceInvestments = [];
  public tradingVolumes = [];
  public cryptoPlans = [];
  private profileSubscriptionDetails: Subscription;
  private profileSubscriptionSurvey: Subscription;
  userdata: any = [];
  isDisabled = false;
  isDisabledSurvey = false;
  isDisabledIdentity = false;
  isDisabledAddress = false;
  isDisabledPhoto = false;
  isDisabledPassport = false;
  identityFormforeign = false;
  files: any;
  files2: any;
  files3: any;
  files4: any;
  files5: any;
  Number: any;

  constructor(
    private profile: ProfileService,
    private Token: TokenService,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private metaService: Meta, private titleService: Title
  ) {}

  ngOnInit() {
    const pageTitle =
    "Bitistanbul | Hesap Aç";
  this.titleService.setTitle(pageTitle);
  

    modalImgHover();
    this.stepper = new Stepper(document.querySelector("#stepper1"), {
      linear: false,
      animation: true,
    });
    this.getUserDetails();
    this.fetchProfileDetails();

    this.profileSubscriptionSurvey = this.profile.getSurveyData().subscribe(
      (data) => this.getSurveyDataList(data),
      (error) => this.handleError(error)
    );
  }

  async fetchProfileDetails() {
    try {
      const data = await this.profile.getprofileDetails().toPromise();
      this.handleProfileDetails(data);
    } catch (error) {
      this.handleError(error);
    }
  }

  ngOnDestroy() {
    if (this.profileSubscriptionDetails) {
      this.profileSubscriptionDetails.unsubscribe();
    }

    if (this.profileSubscriptionSurvey) {
      this.profileSubscriptionSurvey.unsubscribe();
    }
  }

  getUserDetails() {
    const user_details = this.Token.getUserDetails();
  }

  handleProfileDetails(data) {
    if (data.success) {
      this.userdata = data.result;
      this.checkLastStepper();
    }
  }

  getSurveyDataList(data) {
    if (data.success) {
      this.ansData = data.result.questions;
      this.countrlist = data.result.questions[8].options;
      this.countrCity = data.result.questions[9].options;
      this.countrCity.pop();
      this.jobs = data.result.questions[10].options;
      this.incomes = data.result.questions[11].options;
      this.netIncomes = data.result.questions[12].options;
      this.cryptoPurposes = data.result.questions[13].options;
      this.sourceInvestments = data.result.questions[14].options;
      this.tradingVolumes = data.result.questions[15].options;
      this.cryptoPlans = data.result.questions[16].options;
    } else {
      this.ansData = [];
    }
  }

  // #region kimlik bilgileri

  folderUpload(event) {
    this.files = event.target.files[0];
  }

  folderUpload2(event) {
    this.files2 = event.target.files[0];
  }

  onSaveIdentity() {
    this.isDisabledIdentity = true;
    this.error = null;
    this.success = null;
    if (this.files === undefined) {
      this.isDisabledIdentity = false;
      this.error = "Kimlik Ön Yüz Seçiniz!";
    } else if (this.files2 === undefined) {
      this.isDisabledIdentity = false;
      this.error = "Kimlik Arka Yüz Seçiniz!";
    } else {
      const formData = new FormData();
      formData.append("document[1]", this.files, this.files.name);
      formData.append("document[2]", this.files2, this.files2.name);
      return this.profile.docUpload_multi(formData).subscribe(
        (data) => this.handleResponse(data),
        (error) => this.handleError(error)
      );
    }
  }

  handleResponse(res) {
    if (res.success) {
      // this.success = res.message;
      if (res.message !== "") {
        this.ngxService.start();
        setTimeout(() => {
          this.isDisabledIdentity = false;
          this.stepper.next();
          this.ngxService.stop();
        }, 0);
      } else {
        this.error = "Kimlik Bilgileri Yüklenirken Hata Oluştu!";
        this.isDisabledIdentity = false;
        return;
      }
    } else {
      this.error = res.message;
      this.isDisabledIdentity = false;
    }
  }
  // #endregion kimlik bilgileri bitiş

  // #region Fatura / İkametgah

  folderUpload3(event) {
    this.files3 = event.target.files[0];
  }

  onSaveAddress() {
    this.isDisabledAddress = true;
    this.error = null;
    this.success = null;
    if (this.files3 === undefined) {
      this.isDisabledAddress = false;
      this.error = "Fatura / İkametgah Seçiniz!";
    } else {
      const formData = new FormData();
      formData.append("document[3]", this.files3, this.files3.name);
      return this.profile.docUpload_multi(formData).subscribe(
        (data) => this.handleResponseAddress(data),
        (error) => this.handleError(error)
      );
    }
  }

  handleResponseAddress(res) {
    if (res.success) {
      // this.success = res.message;
      if (res.message !== "") {
        this.ngxService.start();
        setTimeout(() => {
          this.isDisabledAddress = false;
          this.stepper.next();
          this.ngxService.stop();
        }, 0);
      } else {
        this.error = "Fatura/İkametgah Yüklenirken Hata Oluştu!";
        this.isDisabledAddress = false;
        return;
      }
    } else {
      this.error = res.message;
      this.isDisabledAddress = false;
    }
  }

  // #endregion Fatura / İkametgah

  // #region Dilekçeli Fotoğraf

  folderUpload4(event) {
    this.files4 = event.target.files[0];
  }

  onSavePetitionPhoto() {
    this.isDisabledPhoto = true;
    this.error = null;
    this.success = null;
    if (this.files4 === undefined) {
      this.isDisabledPhoto = false;
      this.error = "Dilekçeli Fotoğraf Seçiniz!";
    } else {
      const formData = new FormData();
      formData.append("document[4]", this.files4, this.files4.name);
      return this.profile.docUpload_multi(formData).subscribe(
        (data) => this.handleResponsePetitionPhoto(data),
        (error) => this.handleError(error)
      );
    }
  }

  handleResponsePetitionPhoto(res) {
    if (res.success) {
      // this.success = res.message;
      if (res.message !== "") {
        this.ngxService.start();
        setTimeout(() => {
          this.isDisabledPhoto = false;
          this.stepper.next();
          this.ngxService.stop();
        }, 0);
      } else {
        this.error = "Dilekçeli Fotoğraf Yüklenirken Hata Oluştu!";
        this.isDisabledPhoto = false;
        return;
      }
    } else {
      this.error = res.message;
      this.isDisabledPhoto = false;
    }
  }

  // #endregion Dilekçeli Fotoğraf

  // #region pasaport

  folderUpload5(event) {
    this.files5 = event.target.files[0];
  }
  onSaveIdentityPasaport() {
    this.isDisabledPassport = true;
    this.error = null;
    this.success = null;

    if (this.files5 === undefined) {
      this.isDisabledPassport = false;
      this.error = "Pasaport Ön Yüz Seçiniz!";
    } else {
      const formData = new FormData();
      formData.append("document[5]", this.files5, this.files5.name);
      return this.profile.docUpload_multi(formData).subscribe(
        (data) => this.handleResponsePasap(data),
        (error) => this.handleError(error)
      );
    }
  }

  handleResponsePasap(res) {
    if (res.success) {
      // this.success = res.message;
      if (res.message !== "") {
        this.ngxService.start();
        setTimeout(() => {
          this.isDisabledPassport = false;
          this.stepper.next();
          this.ngxService.stop();
        }, 0);
      } else {
        this.error = "Pasaport Ön Yüz Yüklenirken Hata Oluştu!";
        this.isDisabledPassport = false;
        return;
      }
    } else {
      this.error = res.message;
      this.isDisabledPassport = false;
    }
  }
  // #endregion pasaport

  // #region Müşteri tanıma formu

  public form = {
    survey_id: 1,
    firstName: null,
    lastname: null,
    tc: null,
    datebirth: null,
    phone_no: null,
    email: null,
    nationality: null,
    country: null,
    city: null,
    city_other: null,
    jobOccupation: null,
    income: null,
    netIncome: null,
    cryptoPurpose: null,
    sourceInvestment: null,
    tradingVolume: null,
    cryptoPlan: null,
    jobOccupation_other: null,
    income_other: null,
    sourceInvestment_other: null,
  };

  onSubmitSurvey() {
    this.isDisabledSurvey = true;
    this.error = null;
    this.success = null;
    const formData = new FormData();
    formData.append("survey_id", this.form.survey_id.toString());
    formData.append("questions[1]", this.userdata.firstname);
    formData.append("questions[2]", this.userdata.lastname);
    if (this.userdata.tc) {
      formData.append("questions[3]", this.userdata.tc);
    } else {
      formData.append("questions[3]", this.userdata.identity_number);
    }
    formData.append("questions[4]", this.userdata.datebirth);
    formData.append("questions[5]", this.userdata.phone_no);
    formData.append("questions[6]", this.userdata.email);
    formData.append("questions[7]", this.form.nationality);
    formData.append("questions[8]", this.form.country);
    if (this.form.country === "301") {
      formData.append("questions[9]", this.form.city);
    } else {
      formData.append("questions[9]", "360");
    }
    formData.append("questions_other[9]", this.form.city_other);
    formData.append("questions[10]", this.form.jobOccupation);
    formData.append("questions_other[10]", this.form.jobOccupation_other);
    formData.append("questions[11]", this.form.income);
    formData.append("questions_other[11]", this.form.income_other);
    formData.append("questions[12]", this.form.netIncome);
    formData.append("questions[13]", this.form.cryptoPurpose);
    formData.append("questions[14]", this.form.sourceInvestment);
    formData.append("questions_other[14]", this.form.sourceInvestment_other);
    formData.append("questions[15]", this.form.tradingVolume);
    formData.append("questions[16]", this.form.cryptoPlan);
    return this.profile.survey(formData).subscribe(
      (data) => this.handleResponseSurvey(data),
      (error) => this.handleError(error)
    );
  }

  handleResponseSurvey(res) {
    if (res.success) {
      // this.success = res.message;
      if (res.message !== "" && res.result === 1) {
        setTimeout(() => {
          this.isDisabledSurvey = false;
          this.router.navigateByUrl("/durum");
        }, 1000);
      } else {
        this.error = "Müşteri Formunda Hata Oluştu!";
        this.isDisabledSurvey = false;
        return;
      }
    } else {
      this.error = res.message;
      this.isDisabledSurvey = false;
    }
  }

  // #endregion Müşteri tanıma formu

  //#region checkLastStepper

  checkLastStepper() {
    if (this.stepper) {
      if (
        this.userdata &&
        this.userdata.document_status &&
        this.userdata.document_status["1"] === 0 &&
        this.userdata.document_status["2"] === 0 &&
        this.userdata.document_status["3"] === 0 &&
        this.userdata.document_status["4"] === 0
      ) {
        this.stepper.to(1);
      } else if (
        this.userdata &&
        this.userdata.document_status &&
        this.userdata.document_status["3"] === 0 &&
        this.userdata.document_status["4"] === 0 &&
        this.userdata.document_status["5"] === 0 &&
        this.userdata.document_status["6"] === 0
      ) {
        this.stepper.to(1);
      } else if (
        this.userdata &&
        this.userdata.document_status &&
        this.userdata.document_status["1"] === 1 &&
        this.userdata.document_status["2"] === 1 &&
        this.userdata.document_status["3"] === 0 &&
        this.userdata.document_status["4"] === 0
      ) {
        this.stepper.to(2);
      } else if (
        this.userdata &&
        this.userdata.document_status &&
        this.userdata.document_status["3"] === 0 &&
        this.userdata.document_status["4"] === 0 &&
        this.userdata.document_status["5"] === 1
      ) {
        this.stepper.to(2);
      } else if (
        this.userdata &&
        this.userdata.document_status &&
        this.userdata.document_status["1"] === 1 &&
        this.userdata.document_status["2"] === 1 &&
        this.userdata.document_status["3"] === 1 &&
        this.userdata.document_status["4"] === 0
      ) {
        this.stepper.to(3);
      } else if (
        this.userdata &&
        this.userdata.document_status &&
        this.userdata.document_status["3"] === 1 &&
        this.userdata.document_status["4"] === 0 &&
        this.userdata.document_status["5"] === 1
      ) {
        this.stepper.to(3);
      } else if (
        this.userdata &&
        this.userdata.document_status &&
        this.userdata.document_status["1"] === 1 &&
        this.userdata.document_status["2"] === 1 &&
        this.userdata.document_status["3"] === 1 &&
        this.userdata.document_status["6"] === 0 &&
        this.userdata.document_status["4"] === 1
      ) {
        this.stepper.to(4);
      } else if (
        this.userdata &&
        this.userdata.document_status &&
        this.userdata.document_status["3"] === 1 &&
        this.userdata.document_status["4"] === 1 &&
        this.userdata.document_status["5"] === 1 &&
        this.userdata.document_status["6"] === 0
      ) {
        this.stepper.to(4);
      } else {
        this.error = "Dosyalar yüklenirken bir hata oluştu!";
      }
    }
  }
  //#endregion checkLastStepper

  next() {
    this.stepper.next();
  }

  back() {
    this.ngxService.start();
    setTimeout(() => {
      this.stepper.previous();
      this.ngxService.stop();
    }, 0);
  }

  handleError(error) {
    this.error = error.message;
  }
}

import { Component, OnInit, VERSION } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { IntermediateService } from '../../Services/intermediate.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  // public version = VERSION.full;
  token = null;
  hidePassword = true;
  hidePasswordConfirmation = true;
  isDisabled: boolean;
  // Dynamic parameters for this component's route: /example-params/:first/:second
  routeParams: Params;
  // Query parameters found in the URL: /example-params/one/two?query1=one&query2=two
  queryParams: Params;
  public error = null;
  public success = null;

  constructor(
    private route: ActivatedRoute,
    private myservice: IntermediateService,
    private router: Router
  ) {}

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
    $('header').addClass('innerheader');
  }

  public form = {
    email: null,
    password: null,
    password_confirmation: null,
    recaptcha: null,
  };

  onSubmit() {
    this.isDisabled = true;
    this.error = null;
    this.success = null;
    this.form.recaptcha = null;
    const formData = new FormData();
    formData.append('token', this.token);
    formData.append('email', this.form.email);
    formData.append('password', this.form.password);
    formData.append('password_confirmation', this.form.password_confirmation);
    return this.myservice.resetPassword(formData).subscribe(
      (data) => this.handleResponse(data),
      (error) => this.handleError(error)
    );
  }

  handleResponse(error) {
    this.isDisabled = false;
    this.error = null;
    this.success = null;
    if (!error.success) {
      this.error = error.message;
    } else {
      this.success = error.message;
      setTimeout(() => {
        this.router.navigateByUrl('/giris');
      }, 4000);
    }
  }
  handleError(error) {
    this.error = error.error.message;
  }
}

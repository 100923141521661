import { Component, OnInit } from "@angular/core";
//import { LyTheme2 } from '@alyle/ui';
import { ProfileService } from "src/app/Services/profile.service";
import { TokenService } from "../../Services/token.service";
declare var $: any;
import { Subscription } from "rxjs";
import { FormattingService } from "src/app/Services/numberFormat.services";
import { AlertifyService } from "src/app/Services/alertify.service";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  public error = null;
  public success = null;
  public res = null;
  public userlogin = null;
  public profileData: any;
  public isCheckedEmail = false;
  public isCheckedSms = false;
  public coinKeys;
  public activeKey;
  public son = {};
  private profileDetailsSubscription: Subscription;
  private profileDetailsInfoSubscription: Subscription;
  private limitListSubscription: Subscription;
  private personDataStatusSubscription: Subscription;
  private profileAgreementSubscription: Subscription;
  private userTwofaListSubscription: Subscription;
  public email;
  public sms;
  public checkboxName;
  public checkboxValue;
  userdataPersonStatus: any = [];
  identityFront: any = [];
  PasaportFront: any = [];
  user_twofa_list: any = [];
  agree_profile: any;
  identityBackFace: any = [];
  invoice: any = [];
  petition: any = [];
  limits: any = [];
  agreements: any = [];
  trading_commission: any = {};
  cari_commission: any = {};
  isDisabled: boolean = false;
  userdata: any = [];
  informationdata: any = [];
  UserLogin: any = [];
  totalTRY: any;
  files1: any;
  files2: any;
  files3: any;
  files4: any;
  files5: any;

  //classes = this.theme.addStyleSheet(styles);

  constructor(
    private profile: ProfileService,
    //private theme: LyTheme2,
    private Token: TokenService,
    public formattingService: FormattingService,
    private alertifyService: AlertifyService,
    private metaService: Meta, private titleService: Title
  ) {}

  ngOnInit() {

    this.metaService.addTags([
      {
        name: "description",
        content:
          "Kolay kullanım, yüksek likidite ve hızlı kripto işlemleri. 7/24 para yatırma çekme. Rekabetçi fiyatlar, düşük komisyon oranlarıyla güvenle kripto al, sat, sakla!",
      },
      {
        name: "keywords",
        content: "hesabım",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);

    const pageTitle = "Bitistanbul | Türkiye'nin Güvenilir Kripto Para Platformu ";
    this.titleService.setTitle(pageTitle);







    this.profileDetailsSubscription = this.profile
      .getprofileDetails()
      .subscribe(
        (dataGetprofile) => this.handleProfileDetails(dataGetprofile),
        (error) => this.handleError(error)
      );

    this.profileDetailsInfoSubscription = this.profile
      .getprofileDetailsInfo()
      .subscribe(
        (getAccountInformationData) =>
          this.handleInformationsDetails(getAccountInformationData),
        (error) => this.handleError(error)
      );

    this.limitListSubscription = this.profile.getLimitList().subscribe(
      (getLimitListData) => this.handleLimitList(getLimitListData),
      (error) => this.handleError(error)
    );

    this.personDataStatusSubscription = this.profile
      .getPersonDataStatus()
      .subscribe(
        (getPersonDataStatusData) =>
          this.getPersonData(getPersonDataStatusData),
        (error) => this.handleError(error)
      );

    this.profileAgreementSubscription = this.profile
      .getprofile_agreement()
      .subscribe(
        (agreementData) => this.getProfileAgreement(agreementData),
        (error) => this.handleError(error)
      );

    this.userTwofaListSubscription = this.profile
      .user_twofa_list_Login()
      .subscribe(
        (user_twofa_listData) => this.getUserTwofaListData(user_twofa_listData),
        (error) => this.handleError(error)
      );
  }

  ngOnDestroy() {
    // Tüm abonelikleri iptal et
    this.profileDetailsSubscription.unsubscribe();
    this.profileDetailsInfoSubscription.unsubscribe();
    this.limitListSubscription.unsubscribe();
    this.personDataStatusSubscription.unsubscribe();
    this.profileAgreementSubscription.unsubscribe();
    this.userTwofaListSubscription.unsubscribe();
  }

  handleProfileDetails(dataGetprofile) {
    if (dataGetprofile.success) {
      this.userdata = dataGetprofile.result;
      this.UserLogin = dataGetprofile.UserLogin;
      this.agreements = dataGetprofile.result.agreements;
      this.son = dataGetprofile.result.son;
      this.trading_commission = dataGetprofile.result?.thirtyVolume;
      this.cari_commission = dataGetprofile.result?.currentCommission;
      this.totalTRY = dataGetprofile.result.totalThirtyVolume;
    }

    this.coinKeys = Object.keys(this.son); // Anahtarları bir diziye çeviriyoruz: ['BTC', 'ETH', 'USDT']
    this.activeKey = this.coinKeys.length > 0 ? this.coinKeys[0] : null; // İlk tab'ı aktif yapmak için
  }

  handleInformationsDetails(getAccountInformationData) {
    if (getAccountInformationData.success) {
      var res = getAccountInformationData.result;
      this.profileData = {
        Name: res.name,
        Email: res.email,
        Phoneno: res.phoneno,
        Memberdate: res.memberdate,
        Depositlimit: res.Depositlimit,
        Cryptocurrency: res.Cryptocurrency,
        hr24deposit: res.hr24deposit,
        hr30deposit: res.hr30deposit,
        Withdrawallimit: res.Withdrawallimit,
        hr24withdraw: res.hr24withdraw,
        hr30withdraw: res.hr30deposit,
      };

      this.informationdata = this.profileData;
    } else {
      this.informationdata = [];
    }
  }

  handleLimitList(getLimitListData) {
    if (getLimitListData.success) {
      this.limits = getLimitListData.result;
    } else {
      this.limits = null;
    }
  }

  getPersonData(getPersonDataStatusData) {
    if (getPersonDataStatusData.success) {
      this.userdataPersonStatus = getPersonDataStatusData.documents;
      this.identityFront = getPersonDataStatusData.documents[1];
      this.identityBackFace = getPersonDataStatusData.documents[2];
      this.invoice = getPersonDataStatusData.documents[3];
      this.petition = getPersonDataStatusData.documents[4];
      this.PasaportFront = getPersonDataStatusData.documents[5];
    }
  }

  selectedDocument: any = {}; // Boş obje ile başlatıyoruz

  editIdentityFront(identityFrontValue: any) {
    this.selectedDocument = identityFrontValue || {}; // Eğer değer null ise boş obje ata
  }

  editIdentityBackFace(identityBackFaceValue: any) {
    this.selectedDocument = identityBackFaceValue;
  }

  editInvoice(invoiceValue: any) {
    this.selectedDocument = invoiceValue;
  }

  editPetition(petitionValue: any) {
    this.selectedDocument = petitionValue;
  }

  editPasaportFront(PasaportFrontValue: any) {
    this.selectedDocument = PasaportFrontValue;
  }

  fileUpload(event: any): void {
    this.files1 = event.target.files[0]; // Yüklenen ilk dosyayı alıyoruz
    this.files2 = event.target.files[0];
    this.files3 = event.target.files[0];
    this.files4 = event.target.files[0];
    this.files5 = event.target.files[0];
  }

  submitEdit() {
    const formData = new FormData();

    // Seçilen belgeye göre dosyayı ve type_id'yi formData'ya ekle
    switch (this.selectedDocument.type_id) {
      case 1: // Kimlik Ön Yüz
        formData.append("document", this.files1, this.files1.name);
        formData.append("document_type", "1"); // Kimlik ön yüz = 1
        break;
      case 2: // Kimlik Arka Yüz
        formData.append("document", this.files2, this.files2.name);
        formData.append("document_type", "2"); // Kimlik arka yüz = 2
        break;
      case 3: // Fatura
        formData.append("document", this.files3, this.files3.name);
        formData.append("document_type", "3"); // Fatura = 3
        break;
      case 4: // Dilekçe
        formData.append("document", this.files4, this.files4.name);
        formData.append("document_type", "4"); // Dilekçe = 4
        break;
      case 5: // Pasaport Ön Yüz
        formData.append("document", this.files5, this.files5.name);
        formData.append("document_type", "5"); // Pasaport ön yüz = 5
        break;
      default:
        console.error("Bilinmeyen belge türü!");
        return; // Eğer bilinmeyen bir type_id varsa işlemi sonlandır
    }

    // API çağrısı
    return this.profile.docUpload_one(formData).subscribe(
      (data) => this.handleResponse(data),
      (error) => this.handleError(error)
    );
  }

  handleResponse(res) {
    if (res.success) {
      // this.success = res.message;
      //this.uploadFile();
      $("#editModal").modal("hide");
      this.profile.getPersonDataStatus().subscribe(
        (getPersonDataStatusData) =>
          this.getPersonData(getPersonDataStatusData),
        (error) => this.handleError(error)
      );
    } else {
      $("#editModal").modal("hide");
      this.alertifyService.error(res.message);
      //this.uploadFile();
    }
  }

  getUserTwofaListData(user_twofa_listData) {
    if (user_twofa_listData.success) {
      this.user_twofa_list = user_twofa_listData.result;
    }
  }

  getProfileAgreement(agreementData) {
    if (agreementData.success) {
      this.agree_profile = agreementData.aggrement;

      if (
        this.agree_profile.hasOwnProperty("5") &&
        this.agree_profile.hasOwnProperty("6")
      ) {
        // 5 ve 6 numaralı objeler varsa
        this.isCheckedSms = this.agree_profile["5"].user_value === 1; // Sms checkbox'unun durumunu user_value değerine göre belirliyoruz
        this.isCheckedEmail = this.agree_profile["6"].user_value === 1; // Email checkbox'unun durumunu user_value değerine göre belirliyoruz
      } else if (this.agree_profile.hasOwnProperty("4")) {
        // 5 ve 6 numaralı objeler yoksa, 4 numaralı objeyi kontrol ediyoruz
        if (this.agree_profile["4"].user_value === 1) {
          // User_value değeri 1 ise, her iki checkbox da aktif olacak
          this.isCheckedSms = true;
          this.isCheckedEmail = true;
        }
      }
    }
  }

  onCheckboxChange(event: any) {
    this.checkboxName = event.target.name;
    this.checkboxValue = event.target.checked;
    if (this.checkboxName === "email") {
      if (this.isCheckedEmail && this.checkboxValue) {
        this.isCheckedEmail = this.checkboxValue;
        $("#commercialMessage").modal("show");
      } else {
        this.isCheckedEmail = this.checkboxValue;
        this.sendToAPI();
      }
    } else if (this.checkboxName === "sms") {
      if (this.isCheckedSms && this.checkboxValue) {
        this.isCheckedSms = this.checkboxValue;
        $("#commercialMessage").modal("show");
      } else {
        this.isCheckedSms = this.checkboxValue;
        this.sendToAPI();
      }
    }
  }

  sendToAPI() {
    this.email = this.isCheckedEmail ? 1 : 0;
    this.sms = this.isCheckedSms ? 1 : 0;
    const formData = new FormData();
    formData.append("agreement[5]", this.sms);
    formData.append("agreement[6]", this.email);
    return this.profile.ticari(formData).subscribe(
      (data) => this.handleTicari(data),
      (error) => this.handleError(error)
    );
  }

  handleTicari(res) {
    if (res.success) {
      this.success = res.message;
      this.profile.getprofileDetails().subscribe(
        (dataGetprofile) => this.handleProfileDetails(dataGetprofile),
        (error) => this.handleError(error)
      );
    } else {
      this.error = res.message;
    }
    $("#commercialMessage").modal("hide");
  }

  closeTicModal() {
    $("#commercialMessage").modal("hide");
  }

  handleError(error) {
    this.error = error.message;
  }
}

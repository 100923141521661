<!-- Duyurular Pages -->
<app-header></app-header>
<article>
  <section class="innerbannerpage">
    <div class="container">
      <div class="row">
        <table id="company-announcement-header">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: 21px"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                  alt=""
                />
                &nbsp;&nbsp;
                <small> {{ "Announcements" | translate }}</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row row-style">
        <div class="col-sm-8 t-white">
          <ul>
            <li>
              Yeni işlem çiftleri <b>çok yakında </b>Bitistanbul
              platformlarında...
            </li>
          </ul>
        </div>
        <div class="col-sm-4 t-white text-center">
          <span class="span-margin"> 22/06/2021 </span>
        </div>
      </div>
      <div class="row line"></div>
      <div class="row row-style">
        <div class="col-sm-8 t-white">
          <ul>
            <li>
              Bitistanbul mobil uygulamaları
              <b>çok yakında</b> hizmetinizde...
            </li>
          </ul>
        </div>
        <div class="col-sm-4 t-white text-center">
          <span class="span-margin"> 28/05/2021 </span>
        </div>
      </div>
      <div class="row line"></div>
      <div class="row row-style">
        <div class="col-sm-8 t-white">
          <ul>
            <li>Bitistanbul<b> Nisan 2021</b> 'de işlemlere başladı.</li>
          </ul>
        </div>
        <div class="col-sm-4 t-white text-center">
          <span class="span-margin"> 28/05/2021 </span>
        </div>
      </div>
      <div class="row line"></div>
      <div class="row row-style">
        <div class="col-sm-8 t-white">
          <ul>
            <li>
              Bitistanbul Kasım 2024'te sermayesini <b>70 milyon TL</b>'ye
              yükseltti.
            </li>
          </ul>
        </div>
        <div class="col-sm-4 t-white text-center">
          <span> 06/11/2024 </span>
        </div>
      </div>

      <div class="row line"></div>

      <div class="row row-style">
        <div class="col-sm-8 t-white">
          <ul>
            <li>
              Bitistanbul Sermaye Piyasası Kurulu’na Kripto Varlık Hizmet
              Sağlayıcılığı Faaliyeti’nde bulunduğuna ilişkin
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp; beyanda bulunarak Kurul tarafından
              yayımlanan <b>'Faaliyette Bulunanlar Listesi </b>'nde yer almıştır.
            </li>
          </ul>
        </div>
        <div class="col-sm-4 t-white text-center">
          <span> 05/08/2024 </span>
        </div>
      </div>
      <div class="row line"></div>
    </div>
  </section>
</article>
<app-footer></app-footer>

<app-header></app-header>
<article>
 
    <section class="innerbannerpage">
      <!-- depositHistoryHeader -->
      <div class="container">
        <div class="row">
          <table id="depositHistoryHeader">
            <tbody>
              <tr>
                <td style="text-align: center; height: 35px">
                  <img
                    src="assets/images/071.svg"
                    alt=""
                    class="text-center"
                    style="width: 15px; margin-right: 170px"
                  />
                </td>
              </tr>
              <tr>
                <td style="text-align: center">
                  <img
                    alt=""
                    src="assets/images/070.svg"
                    class="text-center"
                    style="width: 24px; vertical-align: sub"
                  />
                  &nbsp;&nbsp;
                  <small
                    >{{ "Deposit" | translate }}
                    {{ "History" | translate }}</small
                  >
                </td>
              </tr>
              <tr>
                <td colspan="3" style="height: 25px">
                  <hr />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="container" id="notcontainer">
        <!-- buttons -->
        <div class="row">
          <div class="col-md-12 text-right top-style">
            <div class="form-group tg-select text-right margin-top-style">
              <button
                type="button"
                class="btn tradeHistory-purchase-sale"
                routerLink="/islem-gecmisi"
              >
                {{ "Trade" | translate }}
              </button>
              <button type="button" class="btn tradeHistory-deposit">
                {{ "Deposit" | translate }}
              </button>
              <button type="button" class="btn tradeHistory-withdraw">
                <a routerLink="/cekme-gecmisi">{{ "Withdraw" | translate }}</a>
              </button>

              <button
                type="button"
                (click)="downloadExcel()"
                style="margin-left: 20px"
                class="btn"
                id="tradeHistory-export"
                [disabled]="histroys == undefined || histroys?.length == 0"
              >
                Dışa Aktar
              </button>
            </div>
          </div>
        </div>

        <!-- ngForm -->
        <div class="row">
          <form
            class="innerformbg"
            #searchForm="ngForm"
            (ngSubmit)="onSubmit()"
            id="searchForm"
          >
            <!-- startdate -->
            <div class="col-md-4 col-sm-4">
              <div class="form-group">
                <label class="t-white" for="startdate">{{
                  "StartDate" | translate
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  name="startdate"
                  id="startdate"
                  #start="bsDatepicker"
                  bsDatepicker
                  [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
                  [outsideClick]="false"
                  [class.is-invalid]="startdate.invalid && startdate.touched"
                  #startdate="ngModel"
                  [(ngModel)]="form.startdate"
                  required
                />
                <span class="input-group-btn date-input">
                  <button
                    title="Geçmiş"
                    type="button"
                    class="btn btn-default stbtn"
                    (click)="start.toggle()"
                    [attr.aria-expanded]="start.isOpen"
                  >
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                  </button>
                </span>
              </div>
            </div>

            <!-- enddate -->
            <div class="col-md-4 col-sm-4">
              <div class="form-group">
                <label class="t-white" for="enddate">{{
                  "EndDate" | translate
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  name="enddate"
                  id="enddate"
                  #end="bsDatepicker"
                  bsDatepicker
                  [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
                  [minDate]="form.startdate"
                  [outsideClick]="false"
                  [class.is-invalid]="enddate.invalid && enddate.touched"
                  #enddate="ngModel"
                  [(ngModel)]="form.enddate"
                  required
                />
                <span class="input-group-btn date-input">
                  <button
                    title="Geçmiş"
                    type="button"
                    class="btn btn-default stbtn"
                    (click)="end.toggle()"
                    [attr.aria-expanded]="end.isOpen"
                  >
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                  </button>
                </span>
              </div>
            </div>

            <!-- Coin -->
            <div class="col-md-4 col-sm-4">
              <div class="form-group tg-select">
                <label class="t-white" for="Coin">{{
                  "Coin" | translate
                }}</label>
                <select
                  class="form-control"
                  name="Coin"
                  id="Coin"
                  (change)="navigateTo($event.target.value)"
                >
                  <option
                    *ngFor="let coinlist of coinlists"
                    [selected]="coinlist.coinsymbol == data.coinname"
                  >
                    {{ coinlist.coinsymbol }}
                  </option>
                </select>
              </div>
            </div>

            <!-- clearData -->
            <div class="col-md-12 text-right mt-5">
              <div class="form-group tg-select">
                <button
                  type="submit"
                  class="btn tradeHistory-clear"
                  [disabled]="!searchForm.valid"
                  (click)="clearData(getcoin)"
                >
                  {{ "Clear" | translate }}</button
                >&nbsp;
                <button
                  type="submit"
                  class="btn tradeHistory-search"
                  [disabled]="!searchForm.valid"
                >
                  Ara
                </button>
              </div>
            </div>
          </form>
        </div>

        <!-- table-template -->
        <div class="row mt-50">
          <div
            *ngIf="cointype == 'fiat'; then fiatBlock; else cryptoBlock"
          ></div>

          <!-- fiatBlock -->
          <ng-template #fiatBlock>
            <table
              width="100%"
              class="table site-table sitetab dataTable-deposithistroy"
            >
              <thead>
                <tr>
                  <th>{{ "Date" | translate }}</th>
                  <th class="histroy-tradingPair">
                    {{ "TradingPair" | translate }}
                  </th>
                  <th>{{ "Amount" | translate }}</th>
                  <th>{{ "CreditAmount" | translate }}</th>
                  <th>{{ "Status" | translate }}</th>
                </tr>
              </thead>
              <tbody *ngIf="histroys && histroys?.length > 0">
                <tr
                  *ngFor="
                    let histroy of histroys
                      | slice : (page - 1) * pageSize : page * pageSize
                  "
                >
                  <td>{{ histroy.date }}</td>
                  <td class="histroy-tradingPair">{{ histroy.coin }}</td>
                  <td>
                    {{ formattingService.formatNumberTRY(histroy.amount) }}
                  </td>
                  <td>
                    {{
                      formattingService.formatCryptoWithCondition(
                        histroy.coin,
                        histroy.coin,
                        histroy.camount
                      )
                    }}
                  </td>
                  <td>{{ histroy.status }}</td>
                </tr>
              </tbody>

              <tbody *ngIf="histroys == undefined || histroys?.length == 0">
                <tr>
                  <td colspan="10" class="text-center">
                    <p class="mt-30">Yatırma işlem geçmişi bulunamadı!</p>
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="pagination-table" *ngIf="histroys?.length > 10">
              <tr>
                <td class="text-center">
                  <ngb-pagination
                    [collectionSize]="collectionSize"
                    [(page)]="page"
                    [pageSize]="pageSize"
                    [directionLinks]="false"
                    (pageChange)="refreshHistorys()"
                    [boundaryLinks]="true"
                    [rotate]="true"
                  >
                  </ngb-pagination>
                </td>
              </tr>
              <tr>
                <td class="text-center">
                  <select
                    title="Sayfa Listele"
                    class="form-control"
                    [(ngModel)]="pageSize"
                    (ngModelChange)="refreshHistorys()"
                  >
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="20">20</option>
                    <option [ngValue]="30">30</option>
                    <option [ngValue]="histroys?.length">Tüm</option>
                  </select>
                </td>
              </tr>
            </table>
          </ng-template>

          <!-- cryptoBlock -->
          <ng-template #cryptoBlock>
            <table
              width="100%"
              class="table site-table sitetab dataTable-deposithistroy"
            >
              <thead>
                <tr>
                  <th>{{ "Date" | translate }}</th>
                  <th class="histroy-tradingPair">
                    {{ "TradingPair" | translate }}
                  </th>
                  <th>TX/ID</th>
                  <!-- <th class="histroy-tradingPair">Masrafssss</th> -->
                  <!-- <th>{{ "Sender" | translate }}</th> -->
                  <th>{{ "Amount" | translate }}</th>
                  <th>{{ "Status" | translate }}</th>
                </tr>
              </thead>

              <tbody *ngIf="histroys && histroys?.length > 0">
                <tr
                  *ngFor="
                    let histroy of histroys
                      | slice : (page - 1) * pageSize : page * pageSize
                  "
                >
                  <td>{{ histroy.created_at | localizedDate }}</td>
                  <td class="histroy-tradingPair">{{ histroy.coin }}</td>

                  <td
                    *ngIf="
                      histroy.from == 'admindeposit' &&
                      (histroy.coin == 'BTC' ||
                        histroy.coin == 'USDT' ||
                        histroy.coin == 'ETH')
                    "
                  >
                    -
                  </td>

                  <td
                    *ngIf="
                      histroy.from != 'admindeposit' && histroy.coin == 'BTC'
                    "
                  >
                    <a
                      href="https://www.blockchain.com/explorer/transactions/btc/{{
                        histroy.txid
                      }}"
                      target="_blank"
                      rel="noopener"
                      (click)="copyMsg(histroy.txid)"
                      title="{{ histroy.txid }}"
                    >
                      <img
                        src="assets/images/068.svg"
                        alt="TX/ID"
                        style="width: 17px; height: 17px"
                      />
                    </a>
                  </td>

                  <td
                    *ngIf="
                      histroy.from != 'admindeposit' &&
                      (histroy.coin == 'USDT' || histroy.coin == 'ETH')
                    "
                  >
                    <a
                      href="https://etherscan.io/tx/{{ histroy.txid }}"
                      target="_blank"
                      rel="noopener"
                      (click)="copyMsg(histroy.txid)"
                      title="{{ histroy.txid }}"
                    >
                      <img
                        src="assets/images/068.svg"
                        alt="TX/ID"
                        style="width: 17px; height: 17px"
                      />
                    </a>
                  </td>

                  <td *ngIf="histroy.coin == 'TRY'">-</td>

                  <td>
                    {{
                      formattingService.formatCryptoWithCondition(
                        histroy.coin,
                        histroy.coin,
                        histroy.amount
                      )
                    }}
                  </td>
                  <td>{{ histroy.status }}</td>
                </tr>
              </tbody>

              <tbody *ngIf="histroys == undefined || histroys?.length == 0">
                <tr>
                  <td colspan="9" class="text-center">
                    <p class="mt-30">Yatırma işlem geçmişi bulunamadı!</p>
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="pagination-table" *ngIf="histroys?.length > 10">
              <tr>
                <td class="text-center">
                  <ngb-pagination
                    [collectionSize]="collectionSize"
                    [(page)]="page"
                    [pageSize]="pageSize"
                    [directionLinks]="false"
                    (pageChange)="refreshHistorys()"
                    [boundaryLinks]="true"
                    [rotate]="true"
                  >
                  </ngb-pagination>
                </td>
              </tr>
              <tr>
                <td class="text-center">
                  <select
                    title="Sayfa Listele"
                    class="form-control"
                    [(ngModel)]="pageSize"
                    (ngModelChange)="refreshHistorys()"
                  >
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="20">20</option>
                    <option [ngValue]="30">30</option>
                    <option [ngValue]="histroys?.length">Tüm</option>
                  </select>
                </td>
              </tr>
            </table>
          </ng-template>
        </div>
      </div>
    </section>
 
</article>
<app-footer></app-footer>

import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Meta, Title } from "@angular/platform-browser";
declare const DOMPurify: any;

@Component({
  selector: 'app-kvkk',
  templateUrl: './kvkk.component.html',
  styleUrls: ['./kvkk.component.css'],
})
export class KvkkComponent implements OnInit {
  modalRef: BsModalRef;

  public isValidFlg: boolean = true;
  public form = {
    fullName: null,
    tc: null,
    nat: null,
    address: null,
    phone: null,
    mail: null,
    comprel: null,
    comprel20: null,
    comprel21: null,
    comprel3: null,
    inforeq: null,
    opt1: null,
    opt2: null,
    opt3: null,
  };

  public p: string;

  constructor(private modalService: BsModalService, private metaService: Meta, private titleService: Title) {}

  openModalWithClass(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-lg' })
    );
  }

  ngOnInit() {


     // Meta etiketlerini ayarla
     this.metaService.addTags([
      {
        name: "description",
        content:
          "Kişisel verilerinizin nasıl korunduğunu öğrenmek için tıklayın",
      },
      {
        name: "keywords",
        content: "Kişisel Verilerin Korunması",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);

    const pageTitle = 'Kişisel Verilerin Korunması';
    this.titleService.setTitle(pageTitle);
  }

  print() {
    var left = (screen.width - 900) / 2;
    var top = (screen.height - 900) / 4;
    const printContent = document.getElementById('printable');
    const WindowPrt = window.open(
      '',
      '',
      'left=' +
        left +
        ',top=' +
        top +
        ',width=900,height=900,toolbar=0,scrollbars=0,status=0'
    );

    const sanitizeInput = (input: string) => DOMPurify.sanitize(input || '').toUpperCase();

    var _fullName = printContent.getElementsByClassName('fullname')[0];
    if (this.form.fullName != null)
      _fullName.setAttribute('value', sanitizeInput(this.form.fullName));

    var _tc = printContent.getElementsByClassName('tc')[0];
    if (this.form.tc != null)
      _tc.setAttribute('value', sanitizeInput(this.form.tc));

    var _nat = printContent.getElementsByClassName('nat')[0];
    if (this.form.nat != null)
      _nat.setAttribute('value', sanitizeInput(this.form.nat));

    var _address = printContent.getElementsByClassName('address')[0];
    if (this.form.address != null)
      _address.innerHTML = sanitizeInput(this.form.address);

    var _phone = printContent.getElementsByClassName('phone')[0];
    if (this.form.phone != null)
      _phone.setAttribute('value', sanitizeInput(this.form.phone));

    var _mail = printContent.getElementsByClassName('mail')[0];
    if (this.form.mail != null)
      _mail.setAttribute('value', sanitizeInput(this.form.mail));

    var _comprel = printContent.getElementsByClassName('comprel')[0];
    if (this.form.comprel != null)
      _comprel.setAttribute('value', sanitizeInput(this.form.comprel));

    var _comprel2 = printContent.getElementsByClassName('comprel21')[0];
    if (this.form.comprel21 != null && this.form.comprel21 == '1')
      _comprel2.setAttribute('checked', 'checked');

    var _comprel20 = printContent.getElementsByClassName('comprel20')[0];
    if (this.form.comprel20 != null && this.form.comprel20 == '0')
      _comprel20.setAttribute('checked', 'checked');

    var _comprel3 = printContent.getElementsByClassName('comprel3')[0];
    if (this.form.comprel3 != null)
      _comprel3.setAttribute('value', sanitizeInput(this.form.comprel3));

    var _inforeq = printContent.getElementsByClassName('inforeq')[0];
    if (this.form.inforeq != null)
      _inforeq.innerHTML = sanitizeInput(this.form.inforeq);

    var _opt1 = printContent.getElementsByClassName('opt1')[0];
    if (this.form.opt1 != null) _opt1.setAttribute('checked', 'checked');

    var _opt2 = printContent.getElementsByClassName('opt2')[0];
    if (this.form.opt2 != null) _opt2.setAttribute('checked', 'checked');

    var _opt3 = printContent.getElementsByClassName('opt3')[0];
    if (this.form.opt3 != null) _opt3.setAttribute('checked', 'checked');

    WindowPrt.document.write(printContent.innerHTML);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }

  validatePhoneNo(field) {
    var phoneNumDigits = field.value.replace(/\D/g, '');
    this.isValidFlg = phoneNumDigits.length == 0 || phoneNumDigits.length == 10;

    var formattedNumber = phoneNumDigits;
    if (phoneNumDigits.length >= 6)
      formattedNumber =
        '(' +
        phoneNumDigits.substring(0, 3) +
        ') ' +
        phoneNumDigits.substring(3, 6) +
        '-' +
        phoneNumDigits.substring(6);
    else if (phoneNumDigits.length >= 3)
      formattedNumber =
        '(' +
        phoneNumDigits.substring(0, 3) +
        ') ' +
        phoneNumDigits.substring(3);

    field.value = formattedNumber;
  }
}

import { getTextFormatter, number } from '@amcharts/amcharts4/core';
import { Injectable } from '@angular/core';
import { Theme, theme_bitistanbul, theme_light, theme_dark } from './theme';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private active: Theme = theme_bitistanbul;
  private availableThemes: Theme[] = [
    theme_bitistanbul,
    theme_light,
    theme_dark,
  ];
  private readonly stylesBasePath = `node_modules/@angular/material/prebuilt-themes/`;

  getAvailableThemes(): Theme[] {
    return this.availableThemes;
  }

  getActiveTheme(): Theme {
    return this.active;
  }

  isDarkTheme(): boolean {
    return this.active.name == 'theme_bitistanbul';
  }

  setDarkTheme(): void {
    this.setActiveTheme(theme_dark);
  }

  setDefaultTheme(): void {
    this.setActiveTheme(theme_bitistanbul);
  }

  toggleTheme() {
    try {
      var currtheme = localStorage.getItem('theme');

      if (currtheme === null) {
        var _id = this.active.id;

        if (this.availableThemes.length <= _id) {
          this.setActiveTheme(this.availableThemes[0]);
          localStorage.setItem('theme', this.availableThemes[0].id.toString());
          return;
        }

        _id++;
        var GetTheme = this.availableThemes.filter(function (item) {
          return item.id === _id;
        })[0];

        this.setActiveTheme(GetTheme);

        localStorage.setItem('theme', GetTheme.id.toString());
      } else {
        var _currentLocalthemeId = Number(localStorage.getItem('theme'));

        if (this.availableThemes.length <= _currentLocalthemeId) {
          this.setActiveTheme(this.availableThemes[0]);
          localStorage.setItem('theme', this.availableThemes[0].id.toString());

          return;
        }

        _currentLocalthemeId++;
        var GetTheme = this.availableThemes.filter(function (item) {
          return item.id === _currentLocalthemeId;
        })[0];

        this.setActiveTheme(GetTheme);

        localStorage.setItem('theme', GetTheme.id.toString());
      }
    } catch (e) {
      
    }
  }

  LoadCurrentTheme() {
    try {
      var currtheme = localStorage.getItem('theme');

      if (currtheme === null) {
        var _id = this.active.id;

        if (this.availableThemes.length <= _id) {
          this.setActiveTheme(this.availableThemes[0]);
          localStorage.setItem('theme', this.availableThemes[0].id.toString());
          return;
        }

        _id++;
        var GetTheme = this.availableThemes.filter(function (item) {
          return item.id === _id;
        })[0];

        this.setActiveTheme(GetTheme);

        localStorage.setItem('theme', GetTheme.id.toString());
      } else {
        var _currentLocalthemeId = Number(localStorage.getItem('theme'));

        if (
          this.availableThemes.length < _currentLocalthemeId ||
          1 > _currentLocalthemeId
        ) {
          this.setActiveTheme(this.availableThemes[0]);
          localStorage.setItem('theme', this.availableThemes[0].id.toString());

          return;
        }

        var GetTheme = this.availableThemes.filter(function (item) {
          return item.id === _currentLocalthemeId;
        })[0];

        this.setActiveTheme(GetTheme);

        localStorage.setItem('theme', GetTheme.id.toString());
      }
    } catch (e) {
       
    }
  }

  setActiveTheme(theme: Theme): void {
    this.active = theme;

    Object.keys(this.active.properties).forEach((property) => {
      document.documentElement.style.setProperty(
        property,
        this.active.properties[property]
      );
    });
  }
}

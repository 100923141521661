import { Component, OnInit, Input, TemplateRef } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AuthService } from "src/app/Services/auth.service";
import { environment } from "src/environments/environment";
import { Subscription } from "rxjs";
import * as XLSX from "xlsx";
import { ChartingLibraryWidgetOptions } from "src/assets/charting_library/charting_library.min";
import { WebSocketIOService } from "src/app/Services/web-socket-io.service";
import { WalletService } from "src/app/Services/wallet.service";
import { TradeService } from "src/app/Services/trade.service";
import { v4 as uuidv4 } from "uuid";
import { FormattingService } from "src/app/Services/numberFormat.services";
import { Meta, Title } from "@angular/platform-browser";
declare const TradingView: any;

@Component({
  selector: "app-exchange",
  templateUrl: "./exchange.component.html",
  styleUrls: ["./exchange.component.css"],
})
export class ExchangeComponent implements OnInit {
  public title = "Türkiye'nin Güvenilir Kripto Para Platformu ";
  public error = null;
  public success = null;
  public data = null;
  public loggedIn: boolean;
  public buylimiterror = null;
  public selllimitsuccess = null;
  public selllimiterror = null;
  public buymarketsuccess = null;
  public buymarketerror = null;
  public sellmarketsuccess = null;
  public sellmarketerror = null;
  public coinone = "BTC";
  public cointwo = "TRY";
  public buytrades = null;
  public selltrades = null;
  public completes = null;
  public marketpair = null;
  public buyfee: number = 0;
  public sellfee: number = 0;
  public buycomm = null;
  public sellcomm = null;
  public mypendingtrade = null;
  public completeHistory = null;
  public minmax = null;
  public usdtryPrice;
  public balances = {};
  public orderbookMarket = {};
  public askMarketQuantity = 0;
  public preBidMaliyet: number = 0.0;
  public maliyet1234: number = 0.0;
  public sendCountBuy: number = 0;
  public sendCountSell: number = 0;
  public sendCountSellMarket: number = 0;
  public sendCountBuyMarket: number = 0;
  public bidMarketQuantity = 0;
  public objCommission: {} = {};
  public lastClickClientUuid = "";
  public kulBakiye: any;
  public bidBookCount: any;
  pair: any = "BTC/TRY";
  askBook: Array<any> = [];
  bidBook: Array<any> = [];
  buylimitisDisabled = false;
  selllimitisDisabled = false;
  buymarketisDisabled = false;
  sellmarketisDisabled = false;
  sub: Subscription;
  baseUrl = environment.baseUrl;
  tradeUrl = environment.tradeUrl;
  siteUrl = environment.siteUrl;
  marketpairsDataPrice: any;
  marketpairsData24Hvolume: any;
  marketpairsDataExchange: any;
  comissionData: any;
  loggedInDisabled: boolean = false;

  public dataget = {
    Last: "0.00000000",
    Volume: "0.00000000",
    Exchange: "0.00",
  };
  public balance = {
    coinone: "0.00000000",
    cointwo: "0.00",
  };
  public commission = {
    coinone: 0,
    cointwo: 0,
  };

  public form = {
    buyprice: null,
    buyvolume: null,
    buytotal: "0.00",
    coin: null,
    sellprice: null,
    sellvolume: null,
    selltotal: "0.00",
    buymarketvolume: null,
    sellmarketvolume: null,
  };

  private myChart: any;
  // trading
  // trading view
  // Dynamic parameters for this component's route: /example-params/:first/:second
  routeParams: Params;
  // Query parameters found in the URL: /example-params/one/two?query1=one&query2=two
  queryParams: any;
  websocketResult: any;
  private _datafeedUrl = this.tradeUrl;

  @Input()
  set datafeedUrl(datafeedUrl: string) {
    this._datafeedUrl = datafeedUrl || this._datafeedUrl;
  }
  tradepair: any;
  results: any;
  constructor(
    private trades: TradeService,
    private Auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private WebSocketServiceIO: WebSocketIOService,
    private wallet: WalletService,
    public formattingService: FormattingService,
    private metaService: Meta,
    private titleService: Title
  ) {
    this.getRouteParams();
    this.Auth.authStatus.subscribe((value) => (this.loggedIn = value));
  }

  exportData() {
    /* table id is passed over here */
    const element = document.getElementById("dataTable");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const mils = Math.floor(Date.now() / 1000);
    const fileName = "Bitistanbul Alım Satım İşlem Geçmişi " + mils + ".xlsx";
    /* save to file */
    XLSX.writeFile(wb, fileName);
  }

  createdChart() {
    //const dataUrl = environment.tradeUrl;
    const _datafeed = new (window as any).Datafeeds.UDFCompatibleDatafeed();
    //dataUrl
    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: this.coinone + "/" + this.cointwo, // default symbol
      height: 400,
      width: 850,
      locale: "tr",
      interval: "S", // default interval
      fullscreen: true, // displays the chart in the fullscreen mode
      datafeed: _datafeed,
      enabled_features: [],
      //disabled_features: ["use_localstorage_for_settings"],
      //library_path: environment.siteUrl + "/assets/charting_library/",
      container_id: "tradingview_16a6e",
      theme: "Light",
    };

    //this.myChart = new TradingView.widget(widgetOptions);

    //   this.myChart = new TradingView.widget({
    //   width: '%100',
    //   symbol: 'BTC/TRY', // default symbol
    //   height: 400,
    //   interval: 'M', // default interval
    //   fullscreen: true, // displays the chart in the fullscreen mode
    //   datafeed: _datafeed ,
    //   enabled_features: [],
    //   disabled_features: ['use_localstorage_for_settings'],
    //   library_path: environment.siteUrl+'/assets/charting_library/',
    //   container_id: 'tradingview_16a6e'
    // });

    //   symbol: coinone+'/'+cointwo,
    //   datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(this._datafeedUrl),
    //   interval: this._interval,
    //   container_id: this._containerId,
    //   library_path: this._libraryPath,
    //   locale: getLanguageFromURL() || 'en',
    //   disabled_features: ['use_localstorage_for_settings'],
    //   enabled_features: [],
    //   charts_storage_url: this._chartsStorageUrl,
    //   charts_storage_api_version: this._chartsStorageApiVersion,
    //   client_id: this._clientId,
    //   user_id: this._userId,
    //   fullscreen: this._fullscreen,
    //   autosize: this._autosize,
    //   theme: 'Light',
  }

  getMarketDepth(coinone, cointwo) {
    // const er =
    //   environment.tradeUrl + "/marketdepthvalue/" + coinone + "/" + cointwo;
    // const i = 1;
    /* Chart code */
    // Themes begin
    // am4core.useTheme(am4themes_animated);
    // // Themes end
    // // Create chart instance
    // let chart = am4core.create('depth_chart', am4charts.XYChart);
    // // Add data
    // chart.dataSource.url = environment.tradeUrl+'/marketdepthvalue/' + coinone+'/'+cointwo;
    // chart.dataSource.reloadFrequency = 30000;
    // chart.dataSource.adapter.add('parsedData', function (data) {
    //   // Function to process (sort and calculate cummulative volume)
    //   function processData(list, type, desc) {
    //     // Convert to data points
    //     for (var i = 0; i < list.length; i++) {
    //       list[i] = {
    //         value: Number(list[i][0]),
    //         volume: Number(list[i][1]),
    //       }
    //     }
    //     // Sort list just in case
    //     list.sort(function (a, b) {
    //       if (a.value > b.value) {
    //         return 1;
    //       }
    //       else if (a.value < b.value) {
    //         return -1;
    //       }
    //       else {
    //         return 0;
    //       }
    //     });
    //     // Calculate cummulative volume
    //     if (desc) {
    //       for (var i = list.length - 1; i >= 0; i--) {
    //         if (i < (list.length - 1)) {
    //           list[i].totalvolume = list[i + 1].totalvolume + list[i].volume;
    //         }
    //         else {
    //           list[i].totalvolume = list[i].volume;
    //         }
    //         let dp = {};
    //         dp['value'] = list[i].value;
    //         dp[type + 'volume'] = list[i].volume;
    //         dp[type + 'totalvolume'] = list[i].totalvolume;
    //         res.unshift(dp);
    //       }
    //     }
    //     else {
    //       for (var i = 0; i < list.length; i++) {
    //         if (i > 0) {
    //           list[i].totalvolume = list[i - 1].totalvolume + list[i].volume;
    //         }
    //         else {
    //           list[i].totalvolume = list[i].volume;
    //         }
    //         let dp = {};
    //         dp['value'] = list[i].value;
    //         dp[type + 'volume'] = list[i].volume;
    //         dp[type + 'totalvolume'] = list[i].totalvolume;
    //         res.push(dp);
    //       }
    //     }
    //   }
    //   // Init
    //   let res = [];
    //   processData(data.bids, 'bids', true);
    //   processData(data.asks, 'asks', false);
    //   return res;
    // });
    // // Set up precision for numbers
    // chart.numberFormatter.numberFormat = '#,###.####';
    // // Create axes
    // let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    // xAxis.dataFields.category = 'value';
    // //xAxis.renderer.grid.template.location = 0;
    // xAxis.renderer.minGridDistance = 80;
    // xAxis.title.text = 'Price ('+coinone+'/'+cointwo+')';
    // let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // yAxis.title.text = 'Volume';
    // // Create series
    // let series = chart.series.push(new am4charts.StepLineSeries());
    // series.dataFields.categoryX = 'value';
    // series.dataFields.valueY = 'bidstotalvolume';
    // series.strokeWidth = 2;
    // series.stroke = am4core.color('#0f0');
    // series.fill = series.stroke;
    // series.fillOpacity = 0.1;
    // series.tooltipText = 'Ask: [bold]{categoryX}[/]\nTotal volume: [bold]{valueY}[/]\nVolume: [bold]{bidsvolume}[/]'
    // let series2 = chart.series.push(new am4charts.StepLineSeries());
    // series2.dataFields.categoryX = 'value';
    // series2.dataFields.valueY = 'askstotalvolume';
    // series2.strokeWidth = 2;
    // series2.stroke = am4core.color('#f00');
    // series2.fill = series2.stroke;
    // series2.fillOpacity = 0.1;
    // series2.tooltipText = 'Ask: [bold]{categoryX}[/]\nTotal volume: [bold]{valueY}[/]\nVolume: [bold]{asksvolume}[/]'
    // let series3 = chart.series.push(new am4charts.ColumnSeries());
    // series3.dataFields.categoryX = 'value';
    // series3.dataFields.valueY = 'bidsvolume';
    // series3.strokeWidth = 0;
    // series3.fill = am4core.color('#000');
    // series3.fillOpacity = 0.2;
    // let series4 = chart.series.push(new am4charts.ColumnSeries());
    // series4.dataFields.categoryX = 'value';
    // series4.dataFields.valueY = 'asksvolume';
    // series4.strokeWidth = 0;
    // series4.fill = am4core.color('#000');
    // series4.fillOpacity = 0.2;
    // // Add cursor
    // chart.cursor = new am4charts.XYCursor();
  }

  getTradingViewchart(coinone, cointwo) {
    /* Tradin View Chart */
    // function getLanguageFromURL(): LanguageCode | null {
    //   const regex = new RegExp('[\\?&]lang=([^&#]*)');
    //   const results = regex.exec(location.search);
    //   return results ===== null ? null : decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
    // }
    // const widgetOptions: ChartingLibraryWidgetOptions = {
    //   symbol: coinone+'/'+cointwo,
    //   datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(this._datafeedUrl),
    //   interval: this._interval,
    //   container_id: this._containerId,
    //   library_path: this._libraryPath,
    //   locale: getLanguageFromURL() || 'en',
    //   disabled_features: ['use_localstorage_for_settings'],
    //   enabled_features: [],
    //   charts_storage_url: this._chartsStorageUrl,
    //   charts_storage_api_version: this._chartsStorageApiVersion,
    //   client_id: this._clientId,
    //   user_id: this._userId,
    //   fullscreen: this._fullscreen,
    //   autosize: this._autosize,
    //   theme: 'Light',
    // };
    // const tvWidget = new widget(widgetOptions);
    // this._tvWidget = tvWidget;
  }

  async ngOnInit() {
    this.metaService.addTags([
      {
        name: "description",
        content:
          "Rekabetçi fiyatlar ve gelişmiş al-sat deneyimi. Kurumsal kullanıcılar için ayrıcalıklı çözümler. Bitistanbul’da her işlem kolay! Yüksek likidite ve tahta derinliğine güvenle erişin!",
      },
      {
        name: "keywords",
        content: "exchange, Bitistanbul, kripto borsa, sigorta, Bitcoin",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);
    const pageTitle = "Canlı Borsa";
    this.titleService.setTitle(pageTitle);

    // Socket IO verilerini alma fonksiyonunu çağır
    await this.fetchSocketData();

    //await this.getSocketIODataBoard();

    // LocalStorage'dan değerleri al
    let storedCoinOne = localStorage.getItem("coinone");
    let storedCoinTwo = localStorage.getItem("cointwo");
    // Eğer localStorage'da değerler yoksa varsayılan değerlere yönlendir
    if (!storedCoinOne || !storedCoinTwo) {
      storedCoinOne = "BTC";
      storedCoinTwo = "TRY";
      this.router.navigateByUrl(`/borsa/${storedCoinOne}/${storedCoinTwo}`);
    }

    // Rota parametrelerini al
    const paramCoinOne = storedCoinOne;
    const paramCoinTwo = storedCoinTwo;
    // Eğer rota parametrelerinden biri eksikse veya localStorage'daki değerlerle uyumsuzsa,
    // localStorage'daki değerleri kullanarak rota parametrelerini güncelle
    if (paramCoinOne !== storedCoinOne || paramCoinTwo !== storedCoinTwo) {
      this.router.navigateByUrl(`/borsa/${storedCoinOne}/${storedCoinTwo}`);
      return;
    }

    // Eğer rota parametrelerinde veya localStorage'da uyumsuzluk yoksa,
    // localStorage'daki değerleri güncelle
    this.coinone = storedCoinOne;
    this.cointwo = storedCoinTwo;

    // LocalStorage'a güncel değerleri kaydet
    localStorage.setItem("coinone", this.coinone);
    localStorage.setItem("cointwo", this.cointwo);

    // Verileri yenileme fonksiyonunu çağır
    this.refreshData();

    // Kök rotayı güncelle
    this.router.navigateByUrl(`/borsa/${this.coinone}/${this.cointwo}`);

    // İlgili işlemleri yap
    this.getorderbookdetails(this.coinone, this.cointwo);
    this.setBookRoomMethod(this.coinone + this.cointwo);
    // this.WebSocketServiceIO.setPair(this.coinone, this.cointwo);
    this.getMarketDepth(this.coinone, this.cointwo);
    this.getTradingViewchart(this.coinone, this.cointwo);
    this.createdChart();
    this.getCommission();
  }

  //Sayfa terk edilince socket verisini durdurur.
  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    this.WebSocketServiceIO.leaveAllPairRooms();
  }

  refreshData() {
    this.fetchSocketData();
  }

  setBookRoomMethod(symbol): void {
    // setBookRoom
    this.WebSocketServiceIO.setBookRoom(symbol);
  }

  getorderbookdetails(coinone, cointwo) {
    const myFormData = new FormData();
    if (coinone !== null) {
      myFormData.append("coinone", coinone);
    }
    if (cointwo !== null) {
      myFormData.append("cointwo", cointwo);
    }
    this.Auth.authStatus.subscribe((value) => (this.loggedIn = value));
  }

  //#region  getCommission
  getCommission() {
    this.wallet.getCommissionData().subscribe(
      (data) => this.handleGetCommission(data),
      (error) => this.handleError(error)
    );
  }

  handleGetCommission(data) {
    if (data.success) {
      data.commission.forEach((item) => {
        this.objCommission[item.source] = item;
      });
    }
  }

  //#endregion getCommission

  async fetchSocketData(): Promise<void> {
    try {
      // Set the pair before subscribing
      this.WebSocketServiceIO.setPair(this.coinone, this.cointwo);

      // Wait for the data to be handled
      await new Promise<void>((resolve, reject) => {
        this.sub = this.WebSocketServiceIO.getData().subscribe(
          (data) => {
            this.handleGetSocketDataBoard(data);
            resolve(); // Resolve the promise when data is handled
          },
          (error) => {
            this.handleError(error);
            reject(error); // Reject the promise if there's an error
          }
        );
      });
    } catch (error) {
      // Handle any errors from the Promise
    }
  }

  handleGetSocketDataBoard(data) {
    if (data) {
      const json = data;

      if (
        json.buylimit_result &&
        json.buylimit_result.client_uuid === this.lastClickClientUuid
      ) {
        if (json.buylimit_result.success) {
          this.success = json.buylimit_result.message;
          (<HTMLFormElement>document.getElementById("buylimitForm")).reset();
          this.buylimitisDisabled = false;
        } else {
          this.error = json.buylimit_result.message;
          this.buylimitisDisabled = false;
        }
      }

      if (
        json.buylimitcancel_result &&
        json.buylimitcancel_result.client_uuid === this.lastClickClientUuid
      ) {
        if (json.buylimitcancel_result.success) {
          this.success = json.buylimitcancel_result.message;
          // (<HTMLFormElement>document.getElementById('buylimitForm')).reset();
          // this.buylimitisDisabled = false;
        } else {
          this.error = json.buylimitcancel_result.message;
          // this.buylimitisDisabled = false;
        }
      }
      if (
        json.alllimitcancel_result &&
        json.alllimitcancel_result.client_uuid === this.lastClickClientUuid
      ) {
        if (json.alllimitcancel_result.success) {
          this.success = json.alllimitcancel_result.message;
          // (<HTMLFormElement>document.getElementById('buylimitForm')).reset();
          // this.buylimitisDisabled = false;
        } else {
          this.error = json.alllimitcancel_result.message;
          // this.buylimitisDisabled = false;
        }
      }

      if (
        json.selllimit_result &&
        json.selllimit_result.client_uuid === this.lastClickClientUuid
      ) {
        if (json.selllimit_result.success) {
          this.success = json.selllimit_result.message;
          (<HTMLFormElement>document.getElementById("selllimitForm")).reset();
          this.selllimitisDisabled = false;
        } else {
          this.error = json.selllimit_result.message;
          this.selllimitisDisabled = false;
        }
      }

      if (
        json.selllimitcancel_result &&
        json.selllimitcancel_result.client_uuid === this.lastClickClientUuid
      ) {
        if (json.selllimitcancel_result.success) {
          this.success = json.selllimitcancel_result.message;
          // (<HTMLFormElement>document.getElementById('buylimitForm')).reset();
          // this.buylimitisDisabled = false;
        } else {
          this.error = json.selllimitcancel_result.message;
          // this.buylimitisDisabled = false;
        }
      }

      if (
        json.buymarket_result &&
        json.buymarket_result.client_uuid === this.lastClickClientUuid
      ) {
        if (json.buymarket_result.success) {
          this.success = json.buymarket_result.message;
          this.form.buymarketvolume = null;
          this.buymarketisDisabled = false;
        } else {
          this.error = json.buymarket_result.message;
          this.buymarketisDisabled = false;
        }
      }

      if (
        json.sellmarket_result &&
        json.sellmarket_result.client_uuid === this.lastClickClientUuid
      ) {
        if (json.sellmarket_result.success) {
          this.success = json.sellmarket_result.message;
          this.form.sellmarketvolume = null;
          this.sellmarketisDisabled = false;
        } else {
          this.error = json.sellmarket_result.message;
          this.sellmarketisDisabled = false;
        }
      }

      if (json.mypendingtradehistroy) {
        this.mypendingtrade = json.mypendingtradehistroy.map((trade) => {
          return {
            ...trade,
            price: parseFloat(trade.price)?.toFixed(2),
            total: parseFloat(trade.total)?.toFixed(2),
          };
        });
      }

      if (json.completehistory) {
        this.completeHistory = json.completehistory.map((trade) => {
          return {
            ...trade,

            real_price: parseFloat(trade.real_price)?.toFixed(2),
            real_total: parseFloat(trade.real_total)?.toFixed(2),
            volume: parseFloat(trade.volume)?.toFixed(8),
          };
        });
      }

      if (json.balances) {
        this.balances = {};
        this.kulBakiye = json.balances[this.coinone];
        for (const key in json.balances) {
          if (json.balances.hasOwnProperty(key)) {
            // Eğer key 'BTC' ise, ondalık kısmı 8 hane olarak sabitle
            const value =
              key === "BTC"
                ? parseFloat(json.balances[key]).toFixed(8)
                : parseFloat(json.balances[key]);
            this.balances[key] = value;
          }
        }
      }

      if (json.orderbook_market) {
        this.orderbookMarket = json.orderbook_market;
        let _marketData = [];
        for (const [key, item] of Object.entries(json.orderbook_market)) {
          _marketData.push(item);
        }

        this.marketpair = _marketData;
      }

      if (json.orderbook) {
        this.data = json.orderbook;
        this.completes = this.data.complete;
        this.dataget = this.data.dataget;
      }

      if (json.ask_book_10) {
        this.handleGetSocketDataAsk(json.ask_book_10);
      }

      if (json.bid_book_10) {
        this.handleGetSocketDataBid(json.bid_book_10);
      }

      if (json.complete) {
        this.completes = json.complete;

        // Verileri işle
        for (let i = 0; i < this.completes.length; i++) {
          const item = this.completes[i];

          // 'Rate,' 'Quantity' ve 'Total' alanlarını ondalık sayılara dönüştür
          item.Rate = parseFloat(item.Rate);
          item.Quantity = parseFloat(item.Quantity);
          item.Total = parseFloat(item.Total);
        }
      }
    }
  }

  // public i_gelen_ask = 0;
  handleGetSocketDataAsk(data) {
    if (data) {
      this.usdtryPrice = data.usdtry;
      this.askBook = data.ask;

      // Dizi içindeki her bir elemanı işleyin
      this.askBook.forEach((item) => {
        // Dizinin ilk elemanını alın, parseFloat ile işleyin
        const parsedValue = parseFloat(item[0]);
        // Değeri güncelleyin
        item[0] = parsedValue;
      });

      // this.maliyetHesaplaAsk();
    } else {
      this.error = "handleGetSocketDataAsk yüklenirken hata oluştu";
    }
  }

  handleGetSocketDataBid(data) {
    if (data) {
      this.usdtryPrice = data.usdtry;
      this.bidBook = data.bid;
      //this.changeMarketQuantityBidInput();
    } else {
      this.error = "handleGetSocketDataBid yüklenirken hata oluştu";
    }
  }

  getRouteParams() {
    // Route parameters
    this.route.params.subscribe((params) => {
      this.coinone = this.route.snapshot.paramMap.get("coinone");
      this.cointwo = this.route.snapshot.paramMap.get("cointwo");
    });
  }

  // inputa değer girmesini engeller
  preventChanges(event: Event): void {
    event.preventDefault();
  }

  onQuantityInput(event: any) {
    const inputValue = event.target.value; // Input alanındaki değeri al
    const limitedValue = this.formattingService.limitDecimals(
      this.coinone,
      this.cointwo,
      inputValue
    );
    event.target.value = limitedValue; // Sınırlı değeri geri yaz
  }

  getFormattedPercentage(data): string {
    const percentage = (100 * data.filled) / data.quantity;
    // Kontrol ediyoruz, eğer NaN ise 0 olarak döndürüyoruz
    return isNaN(percentage) ? "0" : percentage.toFixed(1);
  }

  //#region iptal & Tümünü iptal et

  cancelorder(tradeid, type) {
    // var myFormData = new FormData();
    // myFormData.append('type', type);
    // myFormData.append('tradeid', id);
    const client_uuid = uuidv4();

    this.lastClickClientUuid = client_uuid;
    const myFormData = {
      type,
      tradeid,
      client_uuid,
    };

    this.trades.canceltrade(myFormData).subscribe(
      (data) => this.handlecancelDetials(data),
      (error) => this.handleError(error)
    );
  }

  handlecancelDetials(res) {
    if (res.success) {
      // this.success = res.message;
      // this.getWebsocket(this.coinone, this.cointwo);
      this.getRouteParams();
    } else {
      this.error = res.message;
    }
  }

  allCancelOrder() {
    const client_uuid = uuidv4();
    this.lastClickClientUuid = client_uuid;
    const allCancelFormData = {
      client_uuid,
    };

    this.trades.allCancelTrade(allCancelFormData).subscribe(
      (data) => this.handleAllCancelDetials(data),
      (error) => this.handleError(error)
    );
  }

  handleAllCancelDetials(res) {
    if (res.success) {
      // this.success = res.message;
    } else {
      this.error = res.message;
    }
  }

  //#endregion iptal & Tümünü iptal et

  //#region Limit/Alış Form POST

  onBuyLimitSubmit() {
    if (!this.loggedIn) {
      this.router.navigateByUrl("/giris");
    }

    this.buylimitisDisabled = true;
    const uuid = uuidv4();
    this.lastClickClientUuid = uuid;
    const formData = {
      buyprice: this.form.buyprice,
      buyvolume: this.form.buyvolume,
      coinone: this.coinone,
      cointwo: this.cointwo,
      client_uuid: uuid,
    };

    return this.trades.postBuylimitorder(formData).subscribe(
      (data) => this.handleResponse(data),
      (error) => this.handleError(error)
    );
  }

  handleResponse(res) {
    // this.buylimitisDisabled = false;
    if (res.success) {
      this.buyfee = 0;
      // this.success = res.message;
      // this.getWebsocket(this.coinone, this.cointwo);
      // this.getmypendingtradehistory(this.coinone, this.cointwo);
      // this.getbalanceTrade(this.coinone, this.cointwo);
      // (<HTMLFormElement>document.getElementById('buylimitForm')).reset();
    } else {
      this.error = res.message;
      this.buylimitisDisabled = false;
    }
  }

  //#endregion Limit/Alış Form POST

  //#region Limit/ALIŞ hesaplamalar

  onKeyupcalculateBuy(event: KeyboardEvent): void {
    const input = event.key;
    const inputValue = (event.target as HTMLInputElement).value;
    const isNumber = /^[0-9]$/.test(input);

    if (isNumber || input === "Backspace" || input === "Delete") {
      // Eğer input değeri boş veya sıfırsa, masraf ve toplam değerlerini sıfırla
      if (
        inputValue === "" ||
        parseFloat(inputValue) === 0 ||
        !this.form.buyprice ||
        this.form.buyprice === undefined
      ) {
        this.buyfee = 0.0;
        this.form.buytotal = "0.00";
        return;
      }

      // Eğer input geçerli bir sayı ise, calculateBuy fonksiyonunu çağır
      this.calculateBuy();
    }
  }

  calculateBuy() {
    if (
      !this.form.buyprice ||
      this.form.buyprice === "0" ||
      !this.form.buyvolume ||
      this.form.buyvolume === "0"
    ) {
      this.buyfee = 0;
      this.form.buytotal = "0";
      return;
    }

    this.sendCountBuy++;
    const formDataBuyCalculate = {
      price: this.form.buyprice,
      volume: this.form.buyvolume,
      coinone: this.coinone,
      cointwo: this.cointwo,
      count: this.sendCountBuy,
      type: "buylimit",
    };

    return this.trades
      .getcommissionsCalculateBuy(formDataBuyCalculate)
      .subscribe(
        (data) => this.handlecommissionsBuy(data),
        (error) => this.handleError(error)
      );
  }

  handlecommissionsBuy(res) {
    if (res.success) {
      if (res.count == this.sendCountBuy) {
        this.buyfee = res.fee;
        this.form.buytotal = res.total;
      }
    } else {
      this.error = res.message;
      this.buyfee = 0;
    }
  }

  //#endregion Limit/alış hesaplamalar

  //#region Limit/ALIŞ yüzdelik butonlar

  onPartialBuy(pRate) {
    const formDataBuyCalculateRate = {
      price: this.form.buyprice,
      coinone: this.coinone,
      cointwo: this.cointwo,
      rate: pRate,
    };

    return this.trades
      .getcommissionsCalculateBuy(formDataBuyCalculateRate)
      .subscribe(
        (data) => this.handlecommissionsBuyRate(data),
        (error) => this.handleError(error)
      );
  }

  handlecommissionsBuyRate(res) {
    if (res.success) {
      //if (res.count == this.sendCountBuy) {
      this.buyfee = res.fee;
      this.form.buytotal = res.total;
      this.form.buyvolume = res.quantity.toFixed(8);
      //}
    } else {
      this.error = res.message;
      this.buyfee = 0;
    }
  }
  //#endregion  Limit/alış yüzdelik butonlar

  //#region Limit/SATIŞ Form POST

  onSellLimitSubmit() {
    // const formData = new FormData();
    // formData.append('sellprice', this.form.sellprice);
    // formData.append('sellvolume', this.form.sellvolume);
    // formData.append('coinone', this.coinone);
    // formData.append('cointwo', this.cointwo);
    if (!this.loggedIn) {
      this.router.navigateByUrl("/giris");
    }

    this.selllimitisDisabled = true;
    const uuid = uuidv4();
    this.lastClickClientUuid = uuid;
    const formData = {
      sellprice: this.form.sellprice,
      sellvolume: this.form.sellvolume,
      coinone: this.coinone,
      cointwo: this.cointwo,
      client_uuid: uuid,
    };

    return this.trades.postSelllimitorder(formData).subscribe(
      (data) => this.handleSellResponse(data),
      (error) => this.handleError(error)
    );
  }

  handleSellResponse(res) {
    // this.selllimitisDisabled = false;
    if (res.success) {
      this.sellfee = 0;
      // this.success = res.message;
      // this.getWebsocket(this.coinone, this.cointwo);
      // this.getmypendingtradehistory(this.coinone, this.cointwo);
      // this.getbalanceTrade(this.coinone, this.cointwo);
      // (<HTMLFormElement>document.getElementById('selllimitForm')).reset();
    } else {
      this.error = res.message;
      this.selllimitisDisabled = false;
    }
  }

  //#endregion Limit/SATIŞ Form POST

  //#region Limit/SATIŞ hesaplamalar

  onKeyupcalculateSell(event: KeyboardEvent): void {
    const input = event.key;
    const inputValue = (event.target as HTMLInputElement).value;
    const isNumber = /^[0-9]$/.test(input);

    if (isNumber || input === "Backspace" || input === "Delete") {
      // Eğer input değeri boş, sıfır veya sellvolume boş veya null ise, masraf ve toplam değerlerini sıfırla
      if (
        inputValue === "" ||
        parseFloat(inputValue) === 0 ||
        !this.form.sellprice ||
        !this.form.sellvolume
      ) {
        this.sellfee = 0.0;
        this.form.selltotal = "0.00";
        return;
      }

      // Eğer input geçerli bir sayı ise, calculateSell fonksiyonunu çağır
      this.calculateSell();
    }
  }

  calculateSell() {
    this.sendCountSell++;
    const formDataBuyCalculate = {
      price: this.form.sellprice,
      volume: this.form.sellvolume,
      coinone: this.coinone,
      cointwo: this.cointwo,
      count: this.sendCountSell,
      type: "selllimit",
    };

    return this.trades.getcommissionsCalculate(formDataBuyCalculate).subscribe(
      (data) => this.handleCommissionsSellRate(data),
      (error) => this.handleError(error)
    );
  }

  handleCommissionsSellRate(res) {
    if (res.success) {
      if (res.count == this.sendCountSell) {
        this.sellfee = res.fee;
        this.form.selltotal = res.total;
      }
    } else {
      this.error = res.message;
      this.sellfee = 0;
    }
  }

  //#endregion Limit/satış hesaplamalar

  //#region Limit/SATIŞ yüzdelik butonlar

  onPartialSell(pRate) {
    // Check if this.kulBakiye is defined, otherwise set sellvolume to undefined or 0
    if (
      this.kulBakiye !== undefined &&
      this.kulBakiye !== null &&
      this.kulBakiye > 0
    ) {
      this.form.sellvolume = ((this.kulBakiye * pRate) / 100).toFixed(8);
    } else {
      this.form.sellvolume = undefined; // or "0.00" based on your preference
    }

    const formDataBuyCalculateRateSell = {
      price: this.form.sellprice,
      coinone: this.coinone,
      cointwo: this.cointwo,
      volume: this.form.sellvolume,
      type: "selllimit",
    };

    return this.trades
      .getcommissionsCalculate(formDataBuyCalculateRateSell)
      .subscribe(
        (data) => this.handlecommissionsSell(data),
        (error) => this.handleError(error)
      );
  }

  handlecommissionsSell(res) {
    // sellprice veya sellvolume null veya boş ise selltotal ve/veya sellvolume'i "0.00" olarak ayarla
    if (
      !this.form.sellprice ||
      !this.form.sellvolume ||
      isNaN(this.form.sellvolume)
    ) {
      this.form.selltotal = "0.00";
      this.sellfee = 0.0;
    }

    if (res.success && this.form.sellprice && this.form.sellvolume !== "0.00") {
      if (
        res.fee !== undefined &&
        res.total !== undefined &&
        res.quantity !== undefined
      ) {
        this.sellfee = res.fee;
        this.form.selltotal = res.total;
        this.form.sellvolume = res.quantity.toFixed(8);
      }
    } else if (!res.success) {
      this.error = res.message;
      this.sellfee = 0;
    }
  }

  //#endregion Limit/SATIŞ yüzdelik butonlar

  //#region Piyasa/ALIŞ form POST

  onBuyMarketSubmit() {
    if (!this.loggedIn) {
      this.router.navigateByUrl("/giris");
    }

    this.buymarketisDisabled = true;
    const uuid = uuidv4();
    this.lastClickClientUuid = uuid;
    const formData = {
      buymarketvolume: this.form.buymarketvolume,
      coinone: this.coinone,
      cointwo: this.cointwo,
      client_uuid: uuid,
    };

    return this.trades.postBuymarketorder(formData).subscribe(
      (data) => this.handleBuyMarketResponse(data),
      (error) => this.handleError(error)
    );
  }

  handleBuyMarketResponse(res) {
    // this.buymarketisDisabled = false;
    if (res.success) {
      this.maliyet1234 = 0;
      // this.'success = res.message;
      // this.getWebsocket(this.coinone, this.cointwo);
      // this.getmypendingtradehistory(this.coinone, this.cointwo);
      // this.getbalanceTrade(this.coinone, this.cointwo);
      // (<HTMLFormElement>document.getElementById('buymarketForm')).reset();
    } else {
      this.error = res.message;
      this.buymarketisDisabled = false;
    }
  }

  //#endregion Piyasa/ALIŞ form POST

  //#region Piyasa ALış Hesaplamalar

  onKeyupBuyMarket(event: KeyboardEvent): void {
    const input = event.key;
    const inputValue = (event.target as HTMLInputElement).value;
    const isNumber = /^[0-9]$/.test(input);

    if (isNumber || input === "Backspace" || input === "Delete") {
      // Eğer input değeri boş veya sıfırsa, masraf ve toplam değerlerini sıfırla
      if (inputValue === "" || parseFloat(inputValue) === 0) {
        this.maliyet1234 = 0;

        return;
      }

      // Eğer input geçerli bir sayı ise, calculateBuy fonksiyonunu çağır
      this.changeMarketQuantityAskInput();
    }
  }

  changeMarketQuantityAskInput() {
    this.sendCountBuyMarket++;
    const formDataBuyCalculateMarket = {
      //price: this.form.sellprice,
      volume: this.form.buymarketvolume,
      coinone: this.coinone,
      cointwo: this.cointwo,
      count: this.sendCountBuyMarket,
      buy_sell: 1,
    };

    return this.trades
      .getcommissionsCalculateMarket(formDataBuyCalculateMarket)
      .subscribe(
        (data) => this.handleCommissionsBuyRateMarket(data),
        (error) => this.handleError(error)
      );
  }

  handleCommissionsBuyRateMarket(res) {
    if (res.success) {
      if (res.count == this.sendCountBuyMarket) {
        this.maliyet1234 = res.fee;
      }
    } else {
      this.error = res.message;
    }
  }

  //#endregion Piyasa ALış Hesaplamalar

  //#region Piyasa/SATIŞ  form POST
  onSellMarketSubmit() {
    if (!this.loggedIn) {
      this.router.navigateByUrl("/giris");
    }

    this.sellmarketisDisabled = true;
    const uuid = uuidv4();
    this.lastClickClientUuid = uuid;
    const formData = {
      sellmarketvolume: this.form.sellmarketvolume,
      coinone: this.coinone,
      cointwo: this.cointwo,
      client_uuid: uuid,
    };

    return this.trades.postSellmarketorder(formData).subscribe(
      (data) => this.handleSellMarketResponse(data),
      (error) => this.handleError(error)
    );
  }

  handleSellMarketResponse(res) {
    // this.sellmarketisDisabled = false;
    if (res.success) {
      this.preBidMaliyet = 0;
      // this.success = res.message;
      // this.getWebsocket(this.coinone, this.cointwo);
      // this.getmypendingtradehistory(this.coinone, this.cointwo);
      // this.getbalanceTrade(this.coinone, this.cointwo);
      // (<HTMLFormElement>document.getElementById('sellmarketForm')).reset();
    } else {
      this.error = res.message;
      this.sellmarketisDisabled = false;
    }
  }

  //#endregion Piyasa/SATIŞ  form POST

  //#region Piyasa satış Hesaplamalar

  onKeyupSellMarket(event: KeyboardEvent): void {
    const input = event.key;
    const inputValue = (event.target as HTMLInputElement).value;
    const isNumber = /^[0-9]$/.test(input);

    if (isNumber || input === "Backspace" || input === "Delete") {
      // Eğer input değeri boş veya sıfırsa, masraf ve toplam değerlerini sıfırla
      if (inputValue === "" || parseFloat(inputValue) === 0) {
        this.preBidMaliyet = 0;
        return;
      }

      // Eğer input geçerli bir sayı ise, calculateBuy fonksiyonunu çağır
      this.changeMarketQuantityBidInput();
    }
  }

  changeMarketQuantityBidInput() {
    this.sendCountSellMarket++;
    const formDataBuyCalculateMarket = {
      //price: this.form.sellprice,
      volume: this.form.sellmarketvolume,
      coinone: this.coinone,
      cointwo: this.cointwo,
      count: this.sendCountSellMarket,
      buy_sell: 2,
    };

    return this.trades
      .getcommissionsCalculateMarket(formDataBuyCalculateMarket)
      .subscribe(
        (data) => this.handleCommissionsSellRateMarket(data),
        (error) => this.handleError(error)
      );
  }

  handleCommissionsSellRateMarket(res) {
    if (res.success) {
      if (res.count == this.sendCountSellMarket) {
        this.preBidMaliyet = res.fee;
      }
    } else {
      this.error = res.message;
    }
  }

  //#endregion Piyasa satış Hesaplamalar

  //#region Emir Defteri Kayıtlarına tiklandiginda

  pickBuyorder(price, quantity, index) {
    this.form.buyprice = price;
    // Tıklanan öğe ve sonraki öğeleri al
    const selectedAndNextItems = this.askBook.slice(index);
    // İstenilen öğeleri ve quantity değerlerini topla
    const totalQuantity = selectedAndNextItems.reduce(
      (accumulator, item) => accumulator + item[1],
      0
    );
    // Toplam quantity değerini this.form.buyvolume'e eşitle
    this.form.buyvolume = totalQuantity.toFixed(4);
    // calculateBuy fonksiyonunu çağır
    this.calculateBuy();
  }

  pickSellorder(price, quantity, index) {
    // Form verilerini güncelle
    this.form.sellprice = price;

    // Tıklanan değere kadar olan tüm miktarları topla
    let totalQuantity = 0;
    for (let i = 0; i <= index; i++) {
      totalQuantity += this.bidBook[i][1];
    }
    // Form verilerini güncelle
    this.form.sellvolume = totalQuantity.toFixed(8);
    // Satışı hesapla
    this.calculateSell();
  }

  //#endregion Emir Defteri Kayıtlarına tiklandiginda

  navigateTo(coinone: any, cointwo: any, marketpairs: any) {
    if (coinone && marketpairs) {
      // this.form.buyprice = '';
      // this.form.buyvolume = '';
      // this.form.buytotal = '';
      // this.form.buyvolume;
      // Store the values in localStorage
      localStorage.setItem("coinone", coinone);
      localStorage.setItem("cointwo", cointwo);
      // Update component properties with new data
      this.marketpairsDataPrice = marketpairs?.price;
      this.marketpairsData24Hvolume = marketpairs?.volume;
      this.marketpairsDataExchange = marketpairs?.exchange;
      // Continue with the existing logic
      this.setBookRoomMethod(coinone + cointwo);
      this.WebSocketServiceIO.setPair(coinone, cointwo);
      // Navigate to the new route
      this.router.navigate(["borsa", coinone, cointwo]);
    }
    return false;
  }

  handleError(error) {
    this.error = error.message;
  }
}

<!-- Yasal Düzenlemeler Pages ok-->
<app-header></app-header>
<article>
  <section class="innerbannerpage">
    <div class="container">
      <div class="row">
        <table id="company-legal-regulation-header">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: 135px"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                  alt=""
                />
                &nbsp;&nbsp;
                <small> {{ "LegalRegulations" | translate }}</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row row-style">
        <ul>
          <li>
            <b>16 Nisan 2021</b> tarihli Türkiye Cumhuriyeti Merkez Bankası
            yönetmeliği ile
            <b>ödemelerde kripto varlıkların kullanılmamasına</b> dair
            düzenlemeler <b>30 Nisan</b> 2021 tarihi itibari ile yürürlüğe
            girmiştir. Ödemelerde kripto varlık kullanılmamasının yanısıra ödeme
            ve elektronik para kuruluşlarının kripto
            <b>varlıklara ilişkin</b> alım-satım, saklama, transfer ve/veya
            ihraç hizmeti veren platformlara ve bu platformlardan yapılacak fon
            aktarımlarına <b>aracılık edemeyeceği</b> belirtilmiştir.
          </li>
        </ul>
      </div>

      <div class="row row-style">
        <ul>
          <li>
            <b>Bitistanbul;</b> Haziran 2024 tarihi itibariyle sermaye artışına
            giderek sermayesini <b>70.000.000 TL</b>'ye yükselten
            <b>Hotto Kripto Varlık Alım Satım Platformu A.Ş.</b>'nin
            <b>dijital varlık alım satım hizmeti</b> veren markasıdır ve
            <b>güçlü sermaye</b> yapısının yanısıra, yurtiçi ve yurtdışındaki
            geniş çaptaki iş ortaklıklarıyla dijital varlık piyasalarında
            <b>etkin bir role</b> sahiptir.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            Kripto Varlıklara ilişkin düzenlemeler içeren Sermaye Piyasası Kanunu’nda Değişiklik Yapılmasına Dair Kanun 02/07/2024 tarihinde Resmi Gazete’de yayımlanarak yürürlüğe girdi.
          </li>
        </ul>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

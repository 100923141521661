import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: 'app-how-to-store-bitcoin',
  templateUrl: './how-to-store-bitcoin.component.html',
  styleUrls: ['./how-to-store-bitcoin.component.css']
})
export class HowToStoreBitcoinComponent implements OnInit {

  constructor(private metaService: Meta, private titleService: Title) {}

  ngOnInit() {
    
    // Meta etiketlerini ayarla
    this.metaService.addTags([
      {
        name: "description",
        content:
          "Bitcoinlerinizi nasıl saklayacağınız hakkında bilgi edinin.",
      },
      {
        name: "keywords",
        content: "Bitcoin Nasıl Saklanır?",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);

    const pageTitle = 'Bitcoin Nasıl Saklanır?';
    this.titleService.setTitle(pageTitle);
  }

}

<!-- Güvenlik Pages -->
<app-header></app-header>
<article>
  <section class="innerbannerpage">
    <div class="container">
      <div class="row">
        <table id="security-page-header">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: 6px;"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                  alt=""
                />
                &nbsp;&nbsp;
                <small> {{ "Security" | translate }}</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row row-style">
        <p>
          Bitistanbul’da kişisel bilgilerinizin ve varlıklarınızın gizliliği ve
          güvenliğini sağlamak öncelikli hedefimizdir. Güvenlik ekiplerimiz risk
          bazlı bir yaklaşımla müşteri varlıklarının en üst seviyede korunmasını
          sağlamak için 7/24 sistem takibi yapmakta, güvenlik önlemleri
          almaktadır.
        </p>
      </div>
      <div class="row row-style">
        <p>
          Sistem güvenliği ve varlıklarınızın güvenliğine ilişkin temel olarak
          <b>aşağıdaki noktalarda önlemler</b> alınmaktadır :
        </p>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            <b>Dijital Varlıkların Güvenli Olarak Depolanması : </b>Müşteri
            varlıkları büyük çoğunluğu dağıtık olarak soğuk cüzdanlarda
            tutulmakta,
          </li>
          müşterilerimizin çekim taleplerinin anlık karşılanabilmesi için
          yeterli rezervler herzaman tutulmaktadır.
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            <b>Platform Güvenliği : </b>Sunucularımız yüksek güvenlikli veri
            merkezlerinde yedekli bir yapıda bulundurulmaktadır.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            <b>Bilgi Güvenliği :</b> Bütün hassas hesap verileri hem sistem ve
            veri tabanı seviyesinde şifreli ve güvenli bir şekilde
            saklanmaktadır.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            <b>Penetrasyon Testleri : </b> Düzenli olarak Bitistanbul sistemleri
            üzerinde Sızma (Penetrasyon) Testleri yürütülmektedir.
          </li>
        </ul>
      </div>
      <br />
      <div class="row row-style">
        <p>
          Bitistanbul’un aldığı tedbirlerin yanısıra kişisel bilgilerinizin
          güvenliğini sağlamanız için aşağıdaki güvenlik tedbirlerini almanızı
          tavsiye ederiz :
        </p>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            Bitistanbul’a girişte kullandığınız şifrenizi düzenli aralıklarla
            güncelleyin ve kullandığınız şifrenin başka bir yerde kullanılmamış
            olmasına dikkat edin.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>2FA ( iki faktörlü doğrulama) kodunu aktive edin.</li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            Size göndermiş olduğumuz bildirim emaillerini dikkatli inceleyin ve
            içerikleri konusunda mutabık kalın.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>E-mail hesabı şifrenizi düzenli olarak değiştirin.</li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            Sadece Bitistanbul hesap girişinde kullanacağınız yeni bir email
            adresi oluşturun.
          </li>
        </ul>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  private _url: string = environment.baseUrl;
  private _tradeURL: string = environment.tradeUrl;


  constructor(private http: HttpClient) {}

  getCommissions() {
    return this.http
      .get(`${this._url}/system_commissions`)
      .catch(this.errorHandler);
  }

  getprofileDetails() {
    return this.http
      .get(`${this._url}/profile_information`)
      .catch(this.errorHandler);
  }

  canceleDaction(data) {
    return (
      this.http
        // .post(`${this._url}/withdrawrequest`, data)
        .post(`${this._url}/canceledaction`, data)
        .catch(this.errorHandler)
    );
  }


  getprofileDetailsInfo() {
    return this.http
      .get(`${this._url}/validation_contact_info`)
      .catch(this.errorHandler);
  }

  // getuser_agreement3() {
  //   return this.http
  //     .get(`${this._url}/user_agreement/3`)
  //     .catch(this.errorHandler);
  // }

  getprofile_agreement() {
    return this.http
      .get(`${this._url}/agreement_for_user`)
      .catch(this.errorHandler);
  }

  getLimitList() {
    return this.http
      .post(`${this._url}/getlimitlist`, [])
      .catch(this.errorHandler);
  }

 
  userloginDevice() {
    return this.http
      .get(`${this._url}/device_log_detail`)
      .catch(this.errorHandler);
  }
  

  userloginBrowser() {
    return this.http
      .get(`${this._url}/loggin_detail`)
      .catch(this.errorHandler);
  }
  
 
  sendSmsAgain(data) {
    return this.http.post(`${this._url}/sms_send`, []).catch(this.errorHandler);
  }

  sendEmailAgain(data) {
    return this.http
      .post(`${this._url}/mail_send`, [])
      .catch(this.errorHandler);
  }

  sendEmailDeviceKey(data) {
    return this.http
      .post(`${this._url}/resenddevicekey`, data)
      .catch(this.errorHandler);
  }

  getSurveyData() {
    return this.http.get(`${this._url}/survey/1`).catch(this.errorHandler);
  }

  getPersonDataStatus() {
    return this.http
      .get(`${this._url}/user_document_upload_list`)
      .catch(this.errorHandler);
  }

  updatePassword(data) {
    return this.http
      .post(`${this._url}/changepassword`, data)
      .catch(this.errorHandler);
  }

  fishing(data) {
    return this.http
      .post(`${this._url}/newfishingcode`, data)
      .catch(this.errorHandler);
  }

  changedTime(data) {
    return this.http
      .post(`${this._url}/changedtimeexpired`, data)
      .catch(this.errorHandler);
  }

  changeFishing(data) {
    return this.http
      .post(`${this._url}/changedfishingcode`, data)
      .catch(this.errorHandler);
  }

  verifyCode(data, endPoint) {
    return this.http
      .post(`${this._url}/${endPoint}`, data)
      .catch(this.errorHandler);
  }

  verifyCodeSeq(data) {
    return this.http
      .post(`${this._url}/verifygoogle`, data)
      .catch(this.errorHandler);
  }

  codeAgainRT(data, apiAddresSendAgain) {
    return this.http
      .post(`${this._url}/${apiAddresSendAgain}`, data)
      .catch(this.errorHandler);
  }

  verifyOTP(data) {
    return this.http
      .post(`${this._url}/verify_phone`, data)
      .catch(this.errorHandler);
  }

  verifyDevice(data) {
    return this.http
      .post(`${this._url}/validate_device`, data)
      .catch(this.errorHandler);
  }

  verifyOTPemail(data) {
    return this.http
      .post(`${this._url}/verify_email`, data)
      .catch(this.errorHandler);
  }


  docUpload_multi(data) {
    return this.http
      .post(`${this._url}/user_document_upload_multi`, data)
      .catch(this.errorHandler);
  }

  docUpload_one(data) {
    return this.http
      .post(`${this._url}/user_document_upload`, data)
      .catch(this.errorHandler);
  }

  survey(data) {
    return this.http
      .post(`${this._url}/survey/answerall`, data)
      .catch(this.errorHandler);
  }

  ticari(data) {
    return this.http
      .post(`${this._url}/agreement_set_sms_email`, data)
      .catch(this.errorHandler);
  }

  refreshtfa() {
    return this.http
      .post(`${this._url}/refreshdata`, [])
      .catch(this.errorHandler);
  }

  getwhitelistUser() {
    return this.http.get(`${this._url}/getwhitelist`).catch(this.errorHandler);
  }

  createWhiteAdd(data) {
    return this.http
      .post(`${this._url}/whitelistcreateaddress`, data)
      .catch(this.errorHandler);
  }

  actioncreateApiAddress(data) {
    return this.http
      .post(`${this._url}/actioncreate`, data)
      .catch(this.errorHandler);
  }

  updateWhiteAddress(data) {
    return this.http
      .post(`${this._url}/whitelistupdateaddress`, data)
      .catch(this.errorHandler);
  }

  deleteWhiteAddress(id: number) {
    return this.http
      .post(this._url + "/whitelistdeleteaddress?id=" + id, {
        id: id,
      })
      .catch(this.errorHandler);
  }

  twofa_auth_multi(data) {
    return this.http
      .post(`${this._url}/change_twofa_auth_multi`, data)
      .catch(this.errorHandler);
  }

  user_twofa_list_Login() {
    return this.http
      .get(`${this._url}/user_twofa_list`)
      .catch(this.errorHandler);
  }

  device_session_close(id: number) {
    const url = id
      ? `${this._url}/session_close/${id}`
      : `${this._url}/session_close`;
    return this.http.get(url).catch(this.errorHandler);
  }

  device_session_delete(id: number) {
    const url = id
      ? `${this._url}/device_delete/${id}`
      : `${this._url}/device_delete`;
    return this.http.get(url).catch(this.errorHandler);
  }

  accountSuspend() {
    return this.http.post(`${this._tradeURL}/suspend`, []).catch(this.errorHandler);
  }

  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error.message || "Server error");
  }
}

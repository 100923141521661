import { Component, OnInit, HostListener, ElementRef } from "@angular/core";
import { ThemeService } from "./Services/theme/theme.service";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { WebSocketIOService } from "./Services/web-socket-io.service";
import { AlertifyService } from "./Services/alertify.service";
import {
  faLightbulb as faSolidLightbulb,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { Subscription, BehaviorSubject } from "rxjs";
//import { faLightbulb as faRegularLightbulb } from "@fortawesome/free-regular-svg-icons";
//import { IntermediateService } from "./Services/intermediate.service";
//import { TokenService } from "./Services/token.service";
//import { AuthService } from "./Services/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "Bitistanbul Exchange";
  closeResult: string;
  faLightbulb: IconDefinition;
  private subscription: Subscription;
  public walletDataSubject = new BehaviorSubject<any>(null);
  private tryBalanceInitialReloaded = false; // İlk reload işlemi yapıldı mı?
  private tryBalance = 0; // Son TRY bakiyesini saklar
  private isFirstLoad = true; // İlk kez sayfa yüklendi mi?

  constructor(
    private themeService: ThemeService,
    private modalService: NgbModal,
    private alertifyService: AlertifyService,
    private webSocketService: WebSocketIOService // private elementRef: ElementRef, // private myservice: IntermediateService,
  ) {
    this.themeService.LoadCurrentTheme();
  }

  ngOnInit(): void {
    this.subscribeToWalletsData();
  }


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.webSocketService.leaveAllPairRooms();
  }


  // Socket'ten gelen veriyi dinleyip memory'e kaydetme
  subscribeToWalletsData(): void {
    this.subscription = this.webSocketService.getData().subscribe((data) => {
      
      if (data && data.alertText) {
        this.alertifyService.success(data.alertText);
      }

      if (data && data.wallets) {
        // TRY bakiyesini alıyoruz
        const newTryBalance = Number(data.wallets.find(wallet => wallet.coinsymbol === 'TRY')?.total || 0);

        // İlk yüklemede TRY bakiyesi kontrolü yapıyoruz
        if (this.isFirstLoad) {
          this.tryBalance = newTryBalance;
          this.isFirstLoad = false;

          // Eğer TRY bakiyesi ilk yüklemede zaten 0 değilse, reload etme
          if (this.tryBalance > 0) {
            this.tryBalanceInitialReloaded = true; // Reload işlemi olmaması için işaretliyoruz
          }
        }

        // Eğer TRY bakiyesi sıfırdan büyükse ve daha önce reload yapılmadıysa
        if (newTryBalance > 0 && this.tryBalance === 0 && !this.tryBalanceInitialReloaded) {
          this.tryBalanceInitialReloaded = true; // İlk reload yapıldı işaretliyoruz
          setTimeout(() => {
            window.location.reload(); // Sayfayı reload ediyoruz
          }, 3000); // Küçük bir gecikme ekleyebiliriz
        }

        // TRY bakiyesini güncelle
        this.tryBalance = newTryBalance;

        // Yeni wallet datasını memory'e kaydet
        this.walletDataSubject.next(data);
      }
    });
  }

  // Memory'den wallet verisini almak için method
  getWalletData() {
    return this.walletDataSubject.asObservable();
  }


  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}

// Çerez politikası bildirimi
let cookieMessage =
  "KVKK’nın Veri Sorumlusunun Aydınlatma Yükümlülüğü başlıklı 10. maddesi uyarınca, Çerez Politikamızda belirtilen amaçlarla sınırlı olarak, mevzuata uygun çerezler (cookies) kullanıyoruz";
let cookieDismiss = "Çerezleri Kabul Et";
let cookieLinkText = "Daha Fazla Bilgi";
let cc = window as any;
cc.cookieconsent.initialise({
  palette: {
    popup: {
      background: "white",
      text: "#121c4r",
    },
    button: {
      background: "#ec6e00",
      text: "white",
    },
  },
  theme: "classic",
  content: {
    message: cookieMessage,
    dismiss: cookieDismiss,
    link: cookieLinkText,
    href: "/cerez-politikasi",
  },
});

<!-- Çekme Pages -->
<app-header></app-header>

<div class="overlay" *ngIf="isLoading"></div>
<article>
  <section class="innerbannerpage">
    <div class="container">
      <!-- modal alert -->
      <app-twofa-kripto
        *ngIf="display"
        (close)="display = false"
        [tfaFormData]="userdata.cekim"
      ></app-twofa-kripto>

      <!-- app-alert-modal-success -->
      <app-alert-modal-success
        *ngIf="success_try"
        [success]="success_try"
        (close)="success_try = null"
      >
        {{ success_try }}</app-alert-modal-success
      >

      <!-- withdrawHeader -->
      <div class="row">
        <table id="withdrawHeader">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: -15px;"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  alt=""
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                />
                &nbsp;&nbsp;
                <small> {{ "withdraw" | translate }}</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row mt-40">
        <!-- card header -->
        <ul class="nav nav-tabs text-center ul-center">
          <li>
            <a
              class="a-style"
              data-toggle="tab"
              href="#TRYWithdraw"
              (click)="getTRYClick()"
              >TRY Çekme</a
            >
          </li>

          <li>
            <a
              class="a-style"
              data-toggle="tab"
              href="#KRIPTOWithdraw"
              [class.disabledCoinWallet]="!isLoadingCyypto"
              (click)="
                isLoadingCyypto ? getKriptoClick() : $event.preventDefault()
              "
              >KRİPTO Çekme</a
            >
          </li>
        </ul>

        <div class="tab-content">
          <!--TRY -->
          <div id="TRYWithdraw" class="tab-pane">
            <div class="size-style">
              <div class="form-group">
                <select
                  aria-label="accountNumber"
                  class="form-control"
                  name="accountNumber"
                  #accountNumberList
                  #accountNumber="ngModel"
                  [(ngModel)]="formTRY.accountNumber"
                  (change)="onNavigateAccountAdd($event.target.value)"
                  required
                >
                  <option [ngValue]="null" disabled>
                    {{ "SelectAccountNumber" | translate }}
                  </option>
                  <option>Hesap Ekle</option>
                  <option
                    *ngFor="let BankDetail of banklist; let i = index"
                    value="{{ BankDetail.id }}"
                  >
                    TR{{ BankDetail.account_no }} ({{
                      BankDetail.account_name
                    }})
                  </option>
                </select>
              </div>

              <!-- form -->
              <div
                class="centerwhitebox"
                *ngIf="accountNumberList.selectedIndex"
              >
                <form
                  class="innerformbg"
                  autocomplete="off"
                  #fiatwithdrawFormTRY="ngForm"
                  id="fiatwithdrawFormTRY"
                  (ngSubmit)="onSubmitFiatTRY()"
                >
                  <!-- amountTRY -->
                  <div class="form-group">
                    <input
                      type="text"
                      name="amountTRY"
                      class="form-control"
                      (keyup)="calculateTRY()"
                      placeholder="Çekilecek tutarı giriniz"
                      [class.is-invalid]="
                        amountTRY.invalid && amountTRY.touched
                      "
                      #amountTRY="ngModel"
                      [(ngModel)]="formTRY.amountTRY"
                      oninput="this.value = this.value.replace(/[^0-9.,]/g, '');"
                      step="0.01"
                      pattern="^[0-9.,]*$"
                      min="2"
                      minlength="2"
                      maxlength="13"
                      required
                    />
                    <span
                      *ngIf="
                        amountTRY.errors &&
                        amountTRY.invalid &&
                        amountTRY.touched
                      "
                    >
                      <!-- <small
                            class="text-danger"
                            *ngIf="amount.errors.required"
                            >Amount required</small
                          > -->
                      <small
                        class="text-danger-input"
                        *ngIf="amountTRY.errors.pattern"
                        >{{ "Amountvaild" | translate }}</small
                      >
                      <!-- 
                      <small
                        class="text-danger-input"
                        *ngIf="amountTRY.invalid"
                      >
                        {{ "Amountminvaild" | translate }} {{ minwithdraw }} TRY
                      </small> -->
                    </span>
                  </div>

                  <!-- messages -->
                  <div class="form-group">
                    <div class="text-danger text-center" [hidden]="!error_try">
                      {{ error_try }}
                    </div>
                    <!-- <div
                      class="text-success text-center"
                      [hidden]="!success_try"
                    >
                      {{ success_try }}
                    </div> -->
                  </div>

                  <!-- button -->
                  <div class="form-group text-right">
                    <input
                      type="submit"
                      name="submit"
                      value="TRY {{ 'SendRequest' | translate }}"
                      class="btn orange-btn mt-9"
                      *ngIf="!isDisabledTRY"
                      [disabled]="
                        !fiatwithdrawFormTRY.form.valid ||
                        this.gamtTry > this.balanceTry ||
                        this.gamtTry > this.dayLimitTry ||
                        this.gamtTry > this.monthTry
                      "
                    />
                    <input
                      *ngIf="isDisabledTRY"
                      type="submit"
                      name="submit"
                      class="btn orange-btn mt-9"
                      value="{{ 'Pleasewait' | translate }}..."
                      disabled
                    />
                  </div>

                  <!-- değerler -->
                  <div class="form-group mt-40">
                    <table id="infoTable">
                      <tbody>
                        <tr>
                          <td>{{ "TransferFee" | translate }}</td>
                          <td class="text-center pdd-text">:</td>
                          <td>
                            <span id="w_com">
                              {{
                                formattingService.formatNumberTRY(
                                  admincomissionTRY
                                )
                              }}
                            </span>
                            <b>TRY</b>
                          </td>
                        </tr>
                        <tr>
                          <td>Kullanılabilir Bakiye</td>
                          <td class="text-center">:</td>
                          <td>
                            <span id="w_com">
                              {{ formattingService.formatNumberTRY(balance) }}
                            </span>
                            <b>TRY</b>
                          </td>
                        </tr>
                        <tr
                          class="lessons-list"
                          *ngIf="
                            limitFiat?.h24wres == undefined ||
                              limitFiat?.h24wres == 0;
                            else limitFiatTRY
                          "
                        >
                          <td>Günlük Kalan Çekme Limiti</td>
                          <td class="text-center">:</td>
                          <td>
                            <span id="withdraw_total">
                              {{
                                formattingService.formatNumberTRY(
                                  limitFiat?.h24wres
                                )
                              }}
                            </span>
                          </td>
                        </tr>

                        <ng-template #limitFiatTRY>
                          <tr>
                            <td>Günlük Kalan Çekme Limiti</td>
                            <td class="text-center">:</td>
                            <td>
                              <span id="withdraw_total">
                                {{
                                  formattingService.formatNumberTRY(
                                    limitFiat?.h24wres
                                  )
                                }}
                              </span>
                              <b>TRY</b>
                            </td>
                          </tr>
                        </ng-template>

                        <tr
                          class="lessons-list"
                          *ngIf="
                            limitFiat?.m30wres == undefined ||
                              limitFiat?.m30wres == 0;
                            else limitFiatTRY30
                          "
                        >
                          <td>Aylık Kalan Çekme Limiti</td>
                          <td class="text-center">:</td>
                          <td>
                            <span id="withdraw_total">
                              {{
                                formattingService.formatNumberTRY(
                                  limitFiat?.m30wres
                                )
                              }}
                            </span>
                          </td>
                        </tr>

                        <ng-template #limitFiatTRY30>
                          <tr>
                            <td>Aylık Kalan Çekme Limiti</td>
                            <td class="text-center">:</td>
                            <td>
                              <span id="withdraw_total">
                                {{
                                  formattingService.formatNumberTRY(
                                    limitFiat?.m30wres
                                  )
                                }}
                              </span>
                              <b> TRY</b>
                            </td>
                          </tr>
                        </ng-template>
                      </tbody>
                    </table>
                  </div>
                </form>
              </div>
            </div>

            <!-- iptal table -->
            <div
              class="row mt-30 mb-63"
              *ngIf="
                pendingWithdrawTRY &&
                pendingWithdrawTRY.length > 0 &&
                accountNumberList.selectedIndex
              "
            >
              <div class="box-section">
                <!-- tables -->
                <div class="content-bar">
                  <table class="walletHistoryView">
                    <thead>
                      <tr>
                        <th class="text-center">{{ "Coin" | translate }}</th>
                        <th class="text-center">
                          {{ "Quantity" | translate }}
                        </th>
                        <th class="text-center">{{ "Date" | translate }}</th>
                        <th class="text-center">{{ "Status" | translate }}</th>
                        <th class="text-center">{{ "Process" | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let pWithdraw of pendingWithdrawTRY">
                        <td class="text-center"> {{ pWithdraw.coin }}</td>
                        <td class="text-center">
                          {{
                            formattingService.formatNumberTRY(pWithdraw.amount)
                          }}
                        </td>

                        <td class="text-center">{{ pWithdraw.date }}</td>

                        <td class="text-center">{{ pWithdraw.status }}</td>
                        <td class="text-center">
                          <button
                            title="Çekme talebi iptal edilir!"
                            class="btn pWithdrawCancel"
                            (click)="cancelWithdrawal(pWithdraw)"
                            [disabled]="
                              pWithdraw.status === 'Cancelled' ||
                              pWithdraw.status === 'Completed'
                            "
                          >
                            iptal
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="text-center mt-10">
                <small class="form-text text-muted"
                  >Tüm TRY çekim talepleriniz listelenmiştir.</small
                >
              </div>
            </div>

            <!-- Bilgilendirme -->
            <div class="float-left" id="infoTRY">
              <h5>Bilgilendirme :</h5>
              <ul>
                <li>
                  <img alt="fast" src="../../../assets/images/fast.webp" />
                  <b> &nbsp;100,000.00 TL</b>'ye kadar olan Türk Lirası çekme
                  işlemlerinizi Fast ile
                  <b>hiç bir ücret ödemeden 7/24</b> yapabilirsiniz.
                </li>
                <li>
                  Bitistanbul'dan sadece kendi adınıza açılmış Türk Lirası (TRY)
                  banka hesabınıza para çekebilirsiniz.
                </li>

                <li>
                  Bitistanbul'dan Vakıfbank ve Vakıfkatılım banka
                  hesaplarınıza 7/24 havale yolu ile para çekebilirsiniz.
                </li>
                <li>
                  Çekim talebiniz 100,000.00 TL'yi aşıyorsa EFT işlem saatlari
                  içinde gerçekleştirilecektir.
                </li>
                <li>
                  EFT işlem saati dışında, hafta sonu veya resmi tatillerde
                  çekme talebinde bulunduysanız, talebiniz takip eden ilk iş
                  gününde işleme alınır.
                </li>
                <li>
                  Çekme işlemleriniz için minimum tutar <b>10.00 TL</b>'dir.
                </li>
              </ul>
            </div>
          </div>

          <!-- KRIPTOWithdraw -->
          <div
            id="KRIPTOWithdraw"
            class="tab-pane fade in active"
            [class.disabledCoinWallet]="!isLoadingCyypto"
          >
            <div class="size-style">
              <!-- cointListCripto -->
              <div class="form-group">
                <select
                  aria-label="Kripto Varlık Seçiniz"
                  class="form-control"
                  #cointListCripto
                  [(ngModel)]="favoriteCoin"
                  (change)="setFilterAddr(cointListCripto.value)"
                  name="favCoin"
                  required
                >
                  <option [ngValue]="undefined" disabled selected>
                    Kripto Varlık Seçiniz
                  </option>
                  <option
                    *ngFor="let c of lstCoins; let index = index"
                    [class.active]="index == 1"
                    [value]="c"
                  >
                    {{ c }}
                  </option>
                </select>
              </div>

              <!-- form ve ağlar -->
              <div *ngIf="filterAddr && filterAddr?.length > 0">
                <!-- ağlar -->
                <div class="form-group">
                  <ul class="nav nav-tabs text-center ul-center">
                    <li
                      class="li-borderedBTC"
                      data-toggle="tab"
                      *ngFor="let addr of filterAddr; let index = index"
                      [class.active]="index == 0"
                    >
                      <a class="a-styleBTC" (click)="showAddrType(addr)">
                        {{ addr.type }}</a
                      >
                      <!-- <a class="a-styleBTC"> {{ addr.type }}</a> -->
                    </li>
                  </ul>
                </div>

                <!-- fiatwithdrawFormCoin form -->
                <form
                  class="innerformbg"
                  autocomplete="off"
                  #fiatwithdrawFormCoin="ngForm"
                  id="fiatwithdrawFormCoin"
                  (ngSubmit)="onSubmitKripto()"
                >
                  <!-- toaddress -->
                  <div class="form-group">
                    <input
                      type="search"
                      list="toaddress"
                      name="toaddress"
                      title="{{ cointListCripto.value }} {{
                        networkType
                      }} çekme adresi"
                      class="form-control custom-select custom-select-sm"
                      [(ngModel)]="form.toaddress"
                      #toaddress="ngModel"
                      placeholder="Adres giriniz ya da kayıtlı adresten seçiniz."
                      required
                    />

                    <datalist id="toaddress">
                      <option
                        *ngFor="let toaddress of filterAddrNetwork"
                        [value]="toaddress.address"
                      >
                        {{ toaddress?.title }}
                      </option>
                    </datalist>

                    <span *ngIf="toaddress.invalid && toaddress.touched">
                      <small class="text-danger-input" *ngIf="toaddress.invalid"
                        >Adres alanı boş bırakılamaz!
                      </small>
                    </span>
                  </div>

                  <!-- amount -->
                  <div class="form-group">
                    <input
                      type="text"
                      name="amount"
                      class="form-control"
                      (keyup)="calculateCoin()"
                      (ngModelChange)="calculateCoin()"
                      [class.is-invalid]="amount.invalid && amount.touched"
                      oninput="this.value = this.value.replace(/,/g, '.').replace(/[^0-9.]/g, '');"
                      #amount="ngModel"
                      [(ngModel)]="form.amount"
                      placeholder="Miktar giriniz"
                      required
                    />

                    <span *ngIf="amount.invalid && amount.touched">
                      <small class="text-danger-input" *ngIf="amount.invalid"
                        >Miktar alanı boş bırakılamaz!
                      </small>
                    </span>
                  </div>

                  <!-- messages -->
                  <div class="form-group">
                    <div class="text-danger text-center" [hidden]="!error">
                      {{ error }}
                    </div>
                    <div class="text-success text-center" [hidden]="!success">
                      {{ success }}
                    </div>
                  </div>

                  <!-- buttons -->
                  <div class="form-group text-right">
                    <input
                      type="submit"
                      name="submit"
                      value=" {{ cointListCripto.value }} {{
                        'SendRequest' | translate
                      }}"
                      class="btn orange-btn mt-9"
                      *ngIf="!isDisabled"
                      [disabled]="
                        !fiatwithdrawFormCoin.form.valid ||
                        this.gamtCoin > this.balanceCoin ||
                        this.gamtCoin < this.minwithdraw ||
                        (this.gamtCoin > this.dayLimitCoin &&
                          this.dayLimitCoin != 0) ||
                        (this.gamtCoin > this.monthCoin && this.monthCoin != 0)
                      "
                    />
                    <input
                      type="submit"
                      name="submit"
                      value="{{ 'Pleasewait' | translate }}..."
                      class="btn orange-btn mt-9"
                      *ngIf="isDisabled"
                      disabled
                    />
                  </div>

                  <!-- değerler -->
                  <div class="form-group mt-40">
                    <table id="infoTable">
                      <tbody>
                        <tr>
                          <td>Kullanılabilir Bakiye</td>
                          <td class="text-center pdd-text">:</td>
                          <td>
                            <span id="w_com">
                              {{ formattingService.formatCrypto(balance) }}
                            </span>
                            <b> {{ cointListCripto.value }}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>Transfer Ücreti</td>
                          <td class="text-center">:</td>
                          <td>
                            <span id="w_com">
                              {{ formattingService.formatCrypto(netfee) }}
                            </span>
                            <b> {{ cointListCripto.value }}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>Gönderilecek Toplam Miktar</td>
                          <td class="text-center">:</td>
                          <td>
                            <span id="withdraw_total">
                              {{ formattingService.formatCrypto(total) }}
                            </span>
                            <b> {{ cointListCripto.value }}</b>
                          </td>
                        </tr>

                        <tr
                          class="lessons-list"
                          *ngIf="
                            limitSelected?.h24wres == undefined ||
                              limitSelected?.h24wres == 0;
                            else limit
                          "
                        >
                          <td>Günlük Kalan Çekme Limiti</td>
                          <td class="text-center">:</td>
                          <td>
                            <span id="withdraw_total"> Limitsiz</span>
                          </td>
                        </tr>

                        <ng-template #limit>
                          <tr>
                            <td>Günlük Kalan Çekme Limiti</td>
                            <td class="text-center">:</td>
                            <td>
                              <span id="withdraw_total">
                                {{
                                  formattingService.formatCrypto(
                                    limitSelected?.h24wres
                                  )
                                }}
                              </span>
                              <b> {{ cointListCripto.value }}</b>
                            </td>
                          </tr>
                        </ng-template>

                        <tr
                          class="lessons-list"
                          *ngIf="
                            limitSelected?.m30wres == undefined ||
                              limitSelected?.m30wres == 0;
                            else limitFiatTRY30
                          "
                        >
                          <td>Aylık Kalan Çekme Limiti</td>
                          <td class="text-center">:</td>
                          <td>
                            <span id="withdraw_total"> Limitsiz</span>
                          </td>
                        </tr>

                        <ng-template #limitFiatTRY30>
                          <tr>
                            <td>Aylık Kalan Çekme Limiti</td>
                            <td class="text-center">:</td>
                            <td>
                              <span id="withdraw_total">
                                {{
                                  formattingService.formatCrypto(
                                    limitSelected?.m30wres
                                  )
                                }}
                              </span>
                              <b> {{ cointListCripto.value }}</b>
                            </td>
                          </tr>
                        </ng-template>
                      </tbody>
                    </table>
                  </div>
                </form>
              </div>
            </div>

            <!-- iptal table -->
            <div
              class="row mt-30 mb-63"
              *ngIf="pendingWithdrawKRIPTO && pendingWithdrawKRIPTO.length > 0"
            >
              <div class="box-section">
                <div class="content-bar">
                  <table class="walletHistoryView">
                    <thead>
                      <tr>
                        <th class="text-center">{{ "Coin" | translate }}</th>
                        <th class="text-center">
                          {{ "Quantity" | translate }}
                        </th>
                        <th class="text-center">{{ "Date" | translate }}</th>
                        <th class="text-center">{{ "Status" | translate }}</th>
                        <th class="text-center">{{ "Process" | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let pWithdrawCoin of pendingWithdrawKRIPTO">
                        <td class="text-center">{{ pWithdrawCoin.coin }}</td>
                        <td class="text-center">
                          {{
                            formattingService.formatCrypto(pWithdrawCoin.amount)
                          }}
                        </td>

                        <td class="text-center">{{ pWithdrawCoin.date }}</td>

                        <td class="text-center">{{ pWithdrawCoin.status }}</td>
                        <td class="text-center">
                          <button
                            title="Çekme talebi iptal edilir!"
                            class="btn pWithdrawCancel"
                            (click)="cancelWithdrawal(pWithdrawCoin)"
                            [disabled]="
                              pWithdrawCoin.status === 'Cancelled' ||
                              pWithdrawCoin.status === 'Completed'
                            "
                          >
                            iptal
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="text-center mt-10">
                <small class="form-text text-muted"
                  >Tüm KRİPTO çekim talepleriniz listelenmiştir.</small
                >
              </div>
            </div>

            <!-- CoinHistory TABLE -->
            <div *ngIf="cointListCripto.selectedIndex != 0">
              <div class="float-left mt-30" id="infoTRY">
                <h5>Bilgilendirme :</h5>

                <ul>
                  <li>
                    Hesabınızdan en az
                    <b>{{ minwithdraw }} {{ favoriteCoin }}</b> çekebilirsiniz.
                    Çekme talimatınız onaylandıktan sonra otomatik olarak
                    gerçekleştirilecektir.
                  </li>

                  <li *ngIf="favoriteCoin == 'BTC'">
                    {{ favoriteCoin }} çekme işlemleriniz için işlem ücreti
                    <b> {{ netfee }} {{ favoriteCoin }}</b
                    >'dir.
                  </li>

                  <li *ngIf="favoriteCoin == 'ETH'">
                    {{ favoriteCoin }} çekme işlemleriniz için işlem ücreti
                    <b> {{ netfee }} {{ favoriteCoin }}</b
                    >'dir.
                  </li>
                  <li *ngIf="favoriteCoin == 'USDT'">
                    {{ favoriteCoin }} çekme işlemleriniz için işlem ücreti
                    <b> {{ netfee }} {{ favoriteCoin }}</b
                    >'dir.
                  </li>
                  <li>
                    Alıcı adrese ulaşma süresi seçtiğiniz ağa ve yoğunluğu bağlı
                    olarak değişiklik gösterebilir.
                  </li>
                  <li>
                    Başlatılmış bir transfer teknik olarak durdurulamaz ve iptal
                    edilemez.
                  </li>
                </ul>
              </div>
            </div>

            <!-- Bilgilendirme default -->
            <div *ngIf="cointListCripto.selectedIndex === 0">
              <div class="float-left mt-30" id="infoTRY">
                <h5>Bilgilendirme</h5>

                <ul>
                  <li>
                    Çekme talimatınız onaylandıktan sonra otomatik olarak
                    gerçekleştirilecektir.
                  </li>
                  <li>
                    Alıcı adrese ulaşma süresi seçtiğiniz ağa ve yoğunluğu bağlı
                    olarak değişiklik gösterebilir.
                  </li>
                  <li>
                    Başlatılmış bir transfer teknik olarak durdurulamaz ve iptal
                    edilemez.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

<div
  class="modal fade"
  id="exampleModal"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  data-keyboard="false"
  data-backdrop="static"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          #closebutton
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()"
          class="close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="lessons-list" *ngIf="!withdrawSuccess; else withdrawOk">
          <div class="row" *ngFor="let question of comp1Val; let i = index">
            <div
              id="twofa_form_{{ question.twofa_id }}"
              [ngStyle]="{ display: TWODFA[i] }"
            >
              <form autocomplete="off" class="twoFactorsLogin">
                <div class="mt-20">
                  <div
                    class="form-group text-center"
                    *ngIf="
                      question.twofa_id == 1 ||
                      question.twofa_id == 4 ||
                      question.twofa_id == 7
                    "
                  >
                    <b
                      >**{{
                        twofauserdataDetail?.phone_no | slice : 6 : 10
                      }} </b
                    >numaralı telefonunuza gönderilen 6 haneli kodu giriniz
                  </div>

                  <div
                    class="form-group text-center"
                    *ngIf="
                      question.twofa_id == 2 ||
                      question.twofa_id == 5 ||
                      question.twofa_id == 8
                    "
                  >
                    <b> {{ twofauserdataDetail?.email }}</b>
                    adresine gönderilen kodu giriniz.
                  </div>

                  <div
                    class="form-group text-center"
                    *ngIf="question.twofa_id == 6 || question.twofa_id == 9"
                  >
                    6 haneli<b> 2FA Google </b> kodunu giriniz.
                  </div>
                </div>

                <div class="form-group">
                  <input
                    type="text"
                    data-role="input"
                    name="validationCode"
                    class="form-control"
                    id="google_code"
                    aria-describedby="smsCode"
                    placeholder="6 haneli {{
                      question.twofa_name
                    }} kodunu giriniz"
                    [ngModelOptions]="{ standalone: true }"
                    #validationCode="ngModel"
                    [(ngModel)]="newvalidationCode"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                    onkeyup="if(this.value.length == 6) this.blur()"
                    (keydown.enter)="
                      allAction(newvalidationCode, question.twofa_name, i)
                    "
                    ng-init="$element.focus()"
                    maxlength="6"
                    minlength="6"
                    [disabled]="allActionDisabled[question.twofa_id]"
                    required
                    autofocus
                  />

                  <input
                    type="button"
                    class="verify-button"
                    value=" Onayla"
                    (click)="
                      allAction(newvalidationCode, question.twofa_name, i)
                    "
                    [disabled]="
                      (newvalidationCode && newvalidationCode.length < 6) ||
                      newvalidationCode === undefined ||
                      newvalidationCode == ''
                    "
                  />

                  <div class="mt-30">
                    <div
                      class="alert-danger text-center;"
                      [hidden]="!errorText"
                    >
                      {{ errorText }}
                    </div>
                    <div
                      class="alert-success text-center"
                      [hidden]="!successText[question.twofa_id]"
                    >
                      {{ successText[question.twofa_id] }}
                    </div>
                  </div>

                  <div class="form-row buttonsFooter">
                    <div class="form-group col-md-7 text-center">
                      <button
                        type="button"
                        class="btn btn-link"
                        (click)="allActionRT(question.twofa_id, 'resentCode')"
                        *ngIf="question.twofa_id != 9"
                      >
                        Tekrar Kod Gönder
                      </button>
                    </div>
                    <div class="form-group col-md-5 mt-5">
                      <button
                        #closebutton
                        type="button"
                        class="btn modalClose"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="closeModal()"
                      >
                        Kapat
                      </button>
                    </div>
                  </div>
                  <!-- <div
                    class="form-group text-right mt-20"
                    style="border-style: dashed"
                  >
                  
                  </div> -->
                </div>
              </form>
            </div>
          </div>
        </div>

        <ng-template #withdrawOk>
          <div class="success-checkmark">
            <div class="check-icon">
              <span class="icon-line line-tip"></span>
              <span class="icon-line line-long"></span>
              <div class="icon-circle"></div>
              <div class="icon-fix"></div>
            </div>
          </div>
          <p class="text-center fw-bold">{{ successText }}</p>
          <div class="">
            <button
              type="submit"
              class="btn orange-btn-lgn"
              (click)="onCloseClick()"
            >
              Çıkış
            </button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from "@angular/core";
import { IntermediateService } from "../../Services/intermediate.service";
import { TokenService } from "../../Services/token.service";
import { environment } from "src/environments/environment";
import { Meta, Title } from "@angular/platform-browser";




declare var $: any;
declare function phoneLeftZeroReplace(): any;
@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  constructor(
    private myservice: IntermediateService,
    private Token: TokenService,private metaService: Meta, private titleService: Title 
  ) {}

  // public version = VERSION.full;
  isDisabled: boolean = false;
  public error = null;
  public errorModal = null;
  public success = null;
  public data = null;
  userdata: any = [];
  hidepassword = true;
  hidepassword_confirmation = true;
  identityDataPlaceholder: any;

  identityData: any = [
    {
      id: 1,
      name: "T.C. Kimlik No",
    },
    {
      id: 2,
      name: "Pasaport No",
    },
    {
      id: 3,
      name: "Yabancı T.C. No",
    },
  ];

  public registerDataForm = {
    firstname: null,
    lastname: null,
    identity_type: null,
    tc: null,
    datebirth: null,
    phone: null,
    email: null,
    password: null,
    password_confirmation: null,
    recaptcha: null,
    terms: false,
    confirmInformation: null,
    kvkk: null,
    messageStatus: null,
    commercialMessage: null,
    agreement: {},
    firstnameupper: null,
    lastnameupper: null,
    device_key: null,
  };

  ngOnInit():void  {
    // Meta etiketlerini ayarla
    this.metaService.addTags([
      {
        name: "description",
        content:
          "Dakikalar içinde hesap aç, kripto fırsatlarını keşfet.",
      },
      {
        name: "keywords",
        content: "Üye ol, Bitistanbul, kripto borsa, sigorta, Bitcoin",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);
    const pageTitle = "Bitistanbul | Hesap Aç";
    this.titleService.setTitle(pageTitle);
    phoneLeftZeroReplace();
  }

  //#region registerDataForm Post

  onSubmit() {
    this.isDisabled = true;
    this.error = null;
    this.success = null;
    this.registerDataForm.recaptcha = null;
    this.registerDataForm.agreement = {
      "1": this.registerDataForm.confirmInformation,
      "2": this.registerDataForm.kvkk,
      "3": this.registerDataForm.messageStatus,
      "4": this.registerDataForm.commercialMessage,
    };

    // 'device_key' değerini local storage'a kaydet
    localStorage.setItem("device_key", this.Token.getDevice());
    // 'device_key' değerini form'a ekle
    this.registerDataForm.device_key = localStorage.getItem("device_key");
    return this.myservice.register(this.registerDataForm).subscribe(
      (data) => this.handleResponse(data),
      (error) => this.handleError(error)
    );
  }

  handleResponse(data) {
    if (!data.success) {
      this.isDisabled = false;
      this.error = data.message;
    } else {
      const deviceKey = data.device_key;
      // 'device_key' değerini local storage'a kaydet
      localStorage.setItem("device_key", deviceKey);
      // 'device_key' değerini form'a ekle
      this.registerDataForm.device_key = deviceKey;
      const token = data.result.access_token;
      this.Token.handle(token);
      window.location.href = environment.siteUrl + "/sms-dogrula";
      //this.router.navigateByUrl('/sms-dogrula');
    }
  }

  onChange(event: any) {
    this.registerDataForm.tc = null;
    this.identityDataPlaceholder =
      event.target.options[event.target.options.selectedIndex].text;
  }
  //#endregion registerDataForm Post

  //#region Modal okudum anladım
  ValuemessageStatus() {
    try {
      this.errorModal = null;
      if (
        this.registerDataForm.firstname == null ||
        this.registerDataForm.lastname == null
      ) {
        this.errorModal = "Lütfen önce Hesap Oluştur formunu doldurunuz! ";
        return false;
      } else {
        let resultfirstname = this.registerDataForm.firstname
          .match(/(?<!\p{L}\p{M}*)\p{L}\p{M}*/gu)
          .join("");
        let resultlastnameupper = this.registerDataForm.lastname
          .match(/(?<!\p{L}\p{M}*)\p{L}\p{M}*/gu)
          .join("");

        if (
          this.registerDataForm.firstnameupper !== resultfirstname &&
          this.registerDataForm.lastnameupper !== resultlastnameupper
        ) {
          this.errorModal = "Adınızın ve Soyadınızın baş harfleri uyuşmuyor!";
        } else if (this.registerDataForm.firstnameupper !== resultfirstname) {
          this.errorModal = "Adınızın baş harfi uyuşmuyor!";
        } else if (
          this.registerDataForm.lastnameupper !== resultlastnameupper
        ) {
          this.errorModal = "Soyadınızın baş harfi uyuşmuyor!";
        } else {
          $(".modal").modal("hide");
          this.registerDataForm.messageStatus = 1;
        }
      }
    } catch (error) {
      this.errorModal = error;
    }
  }

  cancelModal() {
    this.errorModal = null;
    $(".modal").modal("hide");
    this.registerDataForm.lastnameupper = null;
    this.registerDataForm.firstnameupper = null;
  }
  //#endregion okudum anladım

  handleError(error) {
    this.error = error.message;
    this.isDisabled = false;
  }
}

import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/Services/profile.service';
import { TokenService } from '../../Services/token.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: 'app-verifyemail',
  templateUrl: './verifyemail.component.html',
  styleUrls: ['./verifyemail.css'],
})
export class verifyemailComponent implements OnInit {
  private profileSubscription: Subscription;
  public success = null;
  public error = null;
  public currenttfa = null;
  userdata: any = [];
  isDisabled  = false;
  isDisabled2 = false;
  isDisabled5 = false;

  constructor(
    private profile: ProfileService,
    private Token: TokenService,
    private router: Router,
    private metaService: Meta, private titleService: Title
  ) {}

  ngOnInit() {

    const pageTitle =
    "Bitistanbul | Hesap Aç";
  this.titleService.setTitle(pageTitle);
  
    this.profileSubscription = this.profile.getprofileDetailsInfo().subscribe(
      (data) => this.handleProfileDetails(data),
      (error) => this.handleError(error)
    );
  }

  ngOnDestroy() {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
  }
  handleProfileDetails(data) {
    if (data.success) {
      this.userdata = data?.result;
      this.sendEmailAgainRT('');
    }
  }

  //#region  verifyOTPemail Doğrula


  inputValue: string;
  onInputChange(event: any) {
    this.inputValue = event.target.value;
    if (this.inputValue.length === 6) {
      setTimeout(() => {
        this.onSubmit();
      }, 450);
    }
  }


  public emailVerificationDataForm = {
    verify_code: null,
    type_name: 'twofa_email',
  };


  onSubmit() {
    this.isDisabled = true;

    return this.profile
      .verifyOTPemail(this.emailVerificationDataForm)
      .subscribe(
        (data) => this.handleResponse(data),
        (error) => this.handleError(error)
      );
  }

  handleResponse(res) {
    if (res.success) {
      setTimeout(() => {
        this.router.navigateByUrl('/dosya-yukle');
      }, 0);
    } else {
      this.success = '';
      this.error =  res.message;
      this.isDisabled = false;
      this.emailVerificationDataForm.verify_code = "";
    }
  }

  //#endregion  //#region  verifyOTPemail Doğrula

  //#region  tekrar mail kod gönder
  
  sendEmailAgainRT(resentCode: string) {
    this.isDisabled2 = true;
    this.isDisabled5 = true;
    const tokenData = this.Token.get();
    return this.profile.sendEmailAgain(tokenData).subscribe(
      (data) => this.handleResponseEmailAgain(data, resentCode),
      (error) => this.handleError(error)
    );
  }

  handleResponseEmailAgain(res, resentCode) {
    this.isDisabled2 = false;
    this.isDisabled5 = false;
    if (res.success && resentCode !== '') {
      this.emailVerificationDataForm.verify_code = null;
      this.success = res.message;
      this.error = null;
    } else if (res.error) {
      this.error =  res.message;
      this.success = null;
    }
  }

  //#endregion tekrar mail gönder

  handleError(error) {
    this.error = error.message;
    this.isDisabled2 = false;
    this.isDisabled5 = false;
  }
}

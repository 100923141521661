<ngx-ui-loader></ngx-ui-loader>
<header class="innerheader">
  <nav class="navbar navbar-default navbar-fixed-top headbg">
    <div class="container">
      <div class="navbar-header" *ngIf="!loggedIn">
        <a class="navbar-brand navbar-link lg" routerLink="/"
          ><img
            width=""
            height=""
            src="../../../assets/images/logo.svg"
            alt="Bitistanbul Logo"
            class="img-responsive logo"
        /></a>
        <button
          title="button"
          class="navbar-toggle collapsed"
          data-toggle="collapse"
          data-target="#navcol-1"
        >
          <span class="sr-only"></span><span class="icon-bar"></span
          ><span class="icon-bar"></span><span class="icon-bar"></span>
        </button>
      </div>
      
      <div class="navbar-header" *ngIf="loggedIn">
        <a class="navbar-brand navbar-link lg"  routerLink="/"
          ><img
            width=""
            height=""
            src="../../../assets/images/logo.svg"
            alt="Bitistanbul Logo"
            class="img-responsive logo"
        /></a>
        <button
          title="button"
          class="navbar-toggle collapsed"
          data-toggle="collapse"
          data-target="#navcol-1"
        >
          <span class="sr-only"></span><span class="icon-bar"></span
          ><span class="icon-bar"></span><span class="icon-bar"></span>
        </button>
      </div>

      <div class="collapse navbar-collapse" id="navcol-1">
        <ul class="nav navbar-nav navbar-right logmenus" *ngIf="!loggedIn">
          <li>
            <a
              routerLink="/giris"
              routerLinkActive="active"
              class="blue-outline-btn"
              ><span class="ss-icon"
                ><img
                  src="assets/images/user-s.png"
                  alt="account"
                  width=""
                  height="" /></span
              >{{ "Signin" | translate }}</a
            >
          </li>
          <li>
            <a
              routerLink="/hesap-olustur"
              routerLinkActive="active"
              class="orange-outline-btn"
            >
              <span class="ss-icon"
                ><img
                  src="assets/images/user-plus.png"
                  alt="account"
                  width=""
                  height=""
              /></span>
              {{ "Signup" | translate }}</a
            >
          </li>
          <li></li>
          <li *ngIf="!loggedIn">
            <img
              src="{{ 'assets/icons/' + currentTheme.icon + '.png' }}"
              id="btnDark"
              width="25"
              height="25"
              title="{{ currentTheme.name }}"
              (click)="toggleTheme()"
              style="
                cursor: pointer;
                color: black;
                margin-top: 15px;
                margin-left: 18px;
              "
            />
          </li>
        </ul>
        <ul class="nav navbar-nav navbar-right" *ngIf="loggedIn">
          <li class="dropdown user user-menu">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
              <div class="user-div">
                <div class="container-user">
                  <span class="ss-icon">
                    <img src="assets/images/097.svg" alt="" style="width: 19px"
                  /></span>
                  <!-- <div *ngIf="image; then showimage else notshowimage">
                                    </div> -->
                  <div class="usertt capitalize">
                    &nbsp;&nbsp;{{ informationdata.name }}
                  </div>

                  <ul class="dropdown-menu dropdown-menu-style">
                    <li class="text-center">
                      <a routerLink="/hesabim">{{ "MyAccount" | translate }}</a>
                    </li>
                    <li class="text-center">
                      <a routerLink="/hesap-guvenlik" routerLinkActive="active"
                        >Güvenlik</a
                      >
                    </li>

                    <li class="text-center">
                      <a routerLink="/islem-gecmisi" routerLinkActive="active"
                        >İşlem Geçmişi</a
                      >
                    </li>

                    <li class="text-center">
                      <a routerLink="/bankalar">{{ "Banks" | translate }}</a>
                    </li>
                    <!-- <li><a routerLink="/security">Security</a></li>
                                            <li><a routerLink="/securityrequest">Security Request</a></li>
                                            <li><a routerLink="/kyc">kyc</a></li> -->
                    <!-- <li class="text-center">
                      <a routerLink="/sifre-degistir">{{
                        "ChangePassword" | translate
                      }}</a>
                    </li> -->
                    <li class="text-center">
                      <a href="#" (click)="logout($event)">{{
                        "Logout" | translate
                      }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </a>
          </li>
          <li class="dropdown notifydrop">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
              <!-- <i class="fa fa-bell"></i> -->
              <img src="assets/icons/fa-bell.svg" alt="" class="fa-bell" />
              <span class="countlist" [ngClass]="{'red-color': count > 0}">{{ count }}</span>
            </a>
            <ul class="dropdown-menu">
              <li *ngFor="let ticket of tickets">
                <a routerLink="/chat/{{ ticket.ticketid }}">
                  <p>{{ ticket.reply }}</p>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <img
              src="{{ 'assets/icons/' + currentTheme.icon + '.png' }}"
              id="btnDark"
              width="25"
              height="25"
              title="{{ currentTheme.name }}"
              (click)="toggleTheme()"
              style="
                cursor: pointer;
                color: black;
                margin-top: 21px;
                margin-right: 30px;
              "
            />
          </li>
        </ul>
        <ul class="nav navbar-nav navbar-right default-menus">
          <!-- <li><a routerLink="/exchange/BTC/USD" routerLinkActive='active' *ngIf="!loggedIn">{{ 'Exchange' | translate }}</a></li>
                    <li><a routerLink="/exchange/BTC/USD" routerLinkActive='active' *ngIf="loggedIn">{{ 'Exchange' | translate }}</a></li> -->
          <li>
            <a
              routerLink="/borsa/BTC/TRY"
              aria-label="Borsa-bitistanbul"
              routerLinkActive="active"
              >Borsa</a
            >
          </li>

          <li *ngIf="!loggedIn; else wallet">
            <a routerLink="/giris" routerLinkActive="active">Cüzdan</a>
          </li>
          <ng-template #wallet>
            <li *ngIf="loggedIn">
              <a routerLink="/cuzdan" routerLinkActive="active">Cüzdan</a>
            </li>
          </ng-template>

          <!-- <li><a routerLink="/exchange" routerLinkActive='active' *ngIf="!loggedIn">Alım-Satım</a></li> -->
          <li *ngIf="!loggedIn; else deposit">
            <a routerLink="/giris" routerLinkActive="active">Yatırma</a>
          </li>
          <ng-template #deposit>
            <li *ngIf="loggedIn">
              <a
                routerLink="/kripto-yatirma/TRY"
                aria-label="Yatırma-bitistanbul"
                routerLinkActive="active"
                >Yatırma</a
              >
            </li>
          </ng-template>

          <li *ngIf="!loggedIn; else withdraw">
            <a routerLink="/giris" routerLinkActive="active">Çekme</a>
          </li>

          <ng-template #withdraw>
            <li *ngIf="loggedIn">
              <a routerLink="/kripto-cekme/TRY" routerLinkActive="active"
                >Çekme</a
              >
            </li>
          </ng-template>
          <!-- <li>
            <a routerLink="/islem-gecmisi" routerLinkActive="active"
              >İşlem Geçmişi</a
            >
          </li> -->
          <li *ngIf="!loggedIn; else support">
            <a routerLink="/giris" routerLinkActive="active">Destek</a>
          </li>
          <ng-template #support>
            <li *ngIf="loggedIn">
              <a routerLink="/destek" routerLinkActive="active">Destek</a>
            </li>
          </ng-template>
        </ul>
      </div>
    </div>
  </nav>
</header>

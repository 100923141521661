import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";


@Component({
  selector: 'app-company-legal-regulation',
  templateUrl: './company-legal-regulation.component.html',
  styleUrls: ['./company-legal-regulation.component.css']
})
export class CompanyLegalRegulationComponent implements OnInit {

  constructor(private metaService: Meta, private titleService: Title) {}

  ngOnInit() {
    this.metaService.addTags([
      {
        name: "description",
        content:
          "Yasal sorumluluklar ve düzenlemeler hakkında bilgi alın.",
      },
      {
        name: "keywords",
        content: "Yasal Düzenlemeler",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);

    const pageTitle = 'Yasal Düzenlemeler';
    this.titleService.setTitle(pageTitle);

  }

}

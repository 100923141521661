import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HomeService } from 'src/app/Services/home.service';
import { AuthService } from 'src/app/Services/auth.service';
import { Subscription } from 'rxjs';
import { ContactScript } from './contact.customjs';
import { Meta, Title } from "@angular/platform-browser";
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit, OnDestroy {
  private authSubscription: Subscription | undefined;
  title = 'BITISTANBUL - İletişim';
  siteUrl = environment.siteUrl;
  loggedIn: boolean;
  isDisabled = false;
  error: string | null = null;
  success: string | null = null;

  form = {
    type: 0,
    isUser: false,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    msg: null,
    title: null,
    formDate: null,
    submitDate: null,
  };

  constructor(private home: HomeService, private auth: AuthService, private metaService: Meta, private titleService: Title) {}

  ngOnInit(): void {
    this.metaService.addTags([
      {
        name: "description",
        content:
          "Her türlü soru ve görüşleriniz için bizimle iletişime geçin.",
      },
      {
        name: "keywords",
        content: "iletisim,iletişim, Bitistanbul, kripto borsa, sigorta, Bitcoin",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);
    const pageTitle = "İletişim";
    this.titleService.setTitle(pageTitle);

    this.authSubscription = this.auth.authStatus.subscribe(
      (value) => (this.loggedIn = value)
    );
    this.form.isUser = this.loggedIn;
    this.form.formDate = new Date();

    // ContactScript.forEach(function (value, key) {
    ContactScript.forEach((value) => {
      const body = document.body;
      const script = document.createElement('script');
      script.innerHTML = '';
      script.src = value.src;
      script.async = false;
      script.defer = true;
      body.appendChild(script);
    });
  }

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  onSubmit() {
    this.isDisabled = true;
    this.error = null;
    this.success = null;
    this.form.submitDate = new Date();
    const formData = new FormData();
    formData.append("type", "1");
    formData.append("title", "Bitistanbul İletişim Formu");
    formData.append("firstname", this.form.firstName);
    formData.append("lastname", this.form.lastName);
    formData.append("email", this.form.email);
    formData.append("phone", this.form.phone);
    formData.append("message", this.form.msg);
    formData.append("formDate", this.form.formDate);
    formData.append("submitDate", this.form.submitDate);
    this.home.msg(formData).subscribe(
      (data) => this.handleResponse(data),
      (error) => this.handleError(error)
    );
  }

  handleResponse(res) {
    if (res.success) {
      this.success = res?.message;

      // HTMLFormElement olarak tipini belirt
      const contactForm = document.getElementById(
        'ContactForm'
      ) as HTMLFormElement | null;

      // Form varsa ve HTMLFormElement ise reset fonksiyonunu çağır
      if (contactForm) {
        contactForm.reset();
      }
    } else {
      this.error = res.message;
    }
    this.isDisabled = false;
  }

  handleError(error) {
    this.isDisabled = false;
    this.error = error.message;
    this.isDisabled = false;
  }
}

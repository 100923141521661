import { Component, OnInit } from "@angular/core";
import { ProfileService } from "src/app/Services/profile.service";
import { TokenService } from "../../Services/token.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";

//import { environment } from 'src/environments/environment';

@Component({
  selector: "app-smsVerification",
  templateUrl: "./smsVerification.component.html",
  styleUrls: ["./smsVerification.css"],
})
export class smsVerificationComponent implements OnInit {
  private profileSubscription: Subscription;
  public success = null;
  public error = null;
  userdata: any = [];
  resentCode: string;
  isDisabled: boolean = false;
  isDisabled5: boolean = false;

  constructor(
    private profile: ProfileService,
    private Token: TokenService,
    private router: Router,
    private metaService: Meta,
    private titleService: Title
  ) {}

  ngOnInit() {
    const pageTitle =
      "Bitistanbul | Hesap Aç";
    this.titleService.setTitle(pageTitle);

    this.profileSubscription = this.profile.getprofileDetailsInfo().subscribe(
      (data) => this.handleProfileDetailsInfo(data),
      (error) => this.handleError(error)
    );
  }

  ngOnDestroy() {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
  }

  handleProfileDetailsInfo(data) {
    if (data.success) {
      this.userdata = data?.result;
      this.sendSmsAgainRT("");
    }
  }

  //#region kod doğrula

  inputValue: string;
  onInputChange(event: any) {
    this.inputValue = event.target.value;
    if (this.inputValue.length === 6) {
      setTimeout(() => {
        this.onSubmit();
      }, 450);
    }
  }

  public smsVerificationDataForm = {
    verify_code: null,
    type_name: "twofa_sms",
  };

  onSubmit() {
    this.isDisabled = true;
    return this.profile.verifyOTP(this.smsVerificationDataForm).subscribe(
      (data) => this.handleResponseverifyOTP(data),
      (error) => this.handleError(error)
    );
  }

  handleResponseverifyOTP(res) {
    if (res.success) {
      setTimeout(() => {
        this.router.navigateByUrl("/email-dogrula");
      }, 0);
    } else {
      this.success = "";
      this.error = res.message;
      this.isDisabled = false;
      this.smsVerificationDataForm.verify_code = "";
    }
  }

  //#endregion kod doğrula

  //#region tekrar kod gönder

  sendSmsAgainRT(resentCode: string) {
    this.isDisabled5 = true;
    return this.profile.sendSmsAgain(this.Token.get()).subscribe(
      (data) => this.handleResponseSmsAgain(data, resentCode),
      (error) => this.handleError(error)
    );
  }

  handleResponseSmsAgain(res, resentCode) {
    this.isDisabled5 = false;
    if (res.success && resentCode !== "") {
      this.smsVerificationDataForm.verify_code = null;
      this.success = res.message;
      this.error = null;
    } else if (res.error) {
      //this.error = res.message;
      this.error = res.message;
      this.success = null;
    }
  }
  //#endregion kod tekrar gönder

  handleError(error) {
    this.error = error.message;
    this.isDisabled5 = false;
    this.isDisabled = false;
  }
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class TradeService {
  private _url: string = environment.baseUrl;
  private _tradeUrl: string = environment.tradeUrl;
  constructor(private http: HttpClient) {}


  getcommissionsCalculate(data) {
    return this.http
      .post(`${this._tradeUrl}/commissionscalculate`, data)
      .catch(this.errorHandler);
  }



  getcommissionsCalculateMarket(data) {
    return this.http
      .post(`${this._tradeUrl}/commissionscalculatemarket`, data)
      .catch(this.errorHandler);
  }


  getcommissionsCalculateBuy(data) {
    return this.http
      .post(`${this._tradeUrl}/commissionscalculate2`, data)
      .catch(this.errorHandler);
  }
  

  // gettradebalance(data) {
  //   return this.http
  //     .post(`${this._url}/gettradebalance`, data)
  //     .catch(this.errorHandler);
  // }


  postBuylimitorder(data) {
    return this.http
      .post(`${this._tradeUrl}/postbuylimit`, data)
      .catch(this.errorHandler);
  }
  
  postSelllimitorder(data) {
    return this.http
      .post(`${this._tradeUrl}/postselllimit`, data)
      .catch(this.errorHandler);
  }
  
  postBuymarketorder(data) {
    return this.http
      .post(`${this._tradeUrl}/postbuymarket`, data)
      .catch(this.errorHandler);
  }

  postSellmarketorder(data) {
    return this.http
      .post(`${this._tradeUrl}/postsellmarket`, data)
      .catch(this.errorHandler);
  }

  gettradehistroy(data) {
    return this.http
      .post(`${this._url}/tradehistroy`, data)
      .catch(this.errorHandler);
  }

  // getpendMyTradehistory(data) {
  //   return this.http
  //     .post(`${this._url}/mypendingtradehistroy`, data)
  //     .catch(this.errorHandler);
  // }

  completeHistory(data) {
    return this.http
      .post(`${this._url}/completehistory`, data)
      .catch(this.errorHandler);
  }

  canceltrade(data) {
    return this.http
      .post(`${this._tradeUrl}/cancelorder`, data)
      .catch(this.errorHandler);
  }

  allCancelTrade(data) {
    return this.http
      .post(`${this._tradeUrl}/cancelallorder`, data)
      .catch(this.errorHandler);
  }

  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error.message || "Server error");
  }
}

import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: 'app-company-announcement',
  templateUrl: './company-announcement.component.html',
  styleUrls: ['./company-announcement.component.css']
})
export class CompanyAnnouncementComponent implements OnInit {

  constructor( private metaService: Meta, private titleService: Title ) { }

  ngOnInit():void {
    // Meta etiketlerini ayarla
    this.metaService.addTags([
      {
        name: "description",
        content:
          "Tüm duyurular ve güncellemeler için bizi takip edin.",
      },
      {
        name: "keywords",
        content: "Duyurular, Bitistanbul, kripto borsa, sigorta, Bitcoin",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);

    const pageTitle = "Duyurular";
    this.titleService.setTitle(pageTitle);
  }

}

<app-header-empty></app-header-empty>
<article class="innerpagearticle">
  <section class="innerbannerpage">
    <div class="container">
      <div class="row">
        
        <!-- smsVerification -->
        <table id="smsVerification">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 17px; margin-right: 77px"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  alt=""
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                />
                &nbsp;&nbsp;
                <small>Mail Doğrulama</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row">
        <div id="parent" class="mt-20">
          <div id="child">
            <div
              *ngIf="
                userdata?.email_verified_at != null;
                then successValEmail;
                else notsuccessEmailVal
              "
            ></div>
            <ng-template #successValEmail>
              <div class="title-header text-center">
                Email doğrulama işlemini başarılı bir şekilde gerçekleştirdiniz.
              </div>
            </ng-template>
            <ng-template #notsuccessEmailVal>
              <div class="centerwhitebox">
                <div class="title-header text-center">
                  <b>{{ userdata?.email }}</b>
                  {{ "EnterDigitCodeEmail" | translate }}
                </div>
                <br />

                <form
                  class="innerformbg"
                  #verifyFormEmail="ngForm"
                  id="verifyFormEmail"
                  autocomplete="off"
                >
                  <!-- verify_code -->
                  <div class="form-group">
                    <input
                      type="text"
                      name="verify_code"
                      maxlength="6"
                      minlength="6"
                      class="form-control"
                      [class.is-invalid]="
                        verify_code.invalid && verify_code.touched
                      "
                      #verify_code="ngModel"
                      [(ngModel)]="emailVerificationDataForm.verify_code"
                      pattern="[0-9]+"
                      placeholder="{{ 'EnterDigitCodeEmail6' | translate }}"
                      onkeyup="if(this.value.length == 6) this.blur()"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      required
                      autofocus

                      (input)="
                      onInputChange(
                        $event 
                      )
                    "
                    />
                    <span
                      *ngIf="
                        verify_code.errors &&
                        verify_code.invalid &&
                        verify_code.touched
                      "
                      class="text-center"
                    >
                    </span>
                  </div>

                  <!-- alert-danger -->
                  <div class="text-center mt-30">
                    <div class="alert-danger" [hidden]="!error">
                      {{ error }}
                    </div>

                    <div class="alert-success" [hidden]="!success">
                      {{ success }}
                    </div>
                  </div>

                  <!-- button template başlangıç -->
                  <div class="text-center mt-30">
                    <input
                      [disabled]="!verifyFormEmail.valid"
                      type="submit"
                      name="submit"
                      class="btn code-verify"
                      value="{{ 'Verify' | translate }}"
                      *ngIf="!isDisabled"
                    />
                    <input
                      type="button"
                      name=""
                      value="{{ 'Confirms' | translate }}..."
                      class="btn code-verify"
                      disabled
                      *ngIf="isDisabled"
                    />
                  </div>

                  <!-- sendEmailAgainRT -->
                  <div class="text-center mt-30">
                    <input
                      type="submit"
                      name="submit"
                      class="btn btn-sendSmsAgainRT"
                      value="{{ 'SubmitCodeAgain' | translate }}"
                      *ngIf="!isDisabled5"
                      (click)="sendEmailAgainRT('resentCode')"
                    />
                    <input
                      type="button"
                      name=""
                      value="{{ 'Pleasewait' | translate }}..."
                      class="btn btn-sendSmsAgainRT"
                      disabled
                      *ngIf="isDisabled5"
                    />
                  </div>
                </form>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

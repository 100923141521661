<!-- Hakkımızda Pages -->
<app-header></app-header>
<article class="innerpagearticle">
  <section class="innerbannerpage">
    <div class="container">
      <div class="row">
        <table id="company-info-header">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: 35px"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                  alt=""
                />
                &nbsp;&nbsp;
                <small> {{ "Aboutus" | translate }}</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            <b>Bitistanbul</b> Mart 2020 tarihinde kripto varlık piyasalarında
            hizmet vermek üzere finans piyasalarında
            <b>25 yılı aşkın tecrübeye sahip yönetim kadrosu</b>
            tarafından İstanbul'da kurulmuştur.
          </li>
        </ul>
      </div>

      <div class="row row-style">
        <ul>
          <li>
            <b>Bitistanbul</b> güçlü <b>teknolojik</b>altyapısı,
            <b>kalifiye</b> personeli, <b>etkin</b> risk yönetimi ve
            <b>güvenlik</b> tedbirleri ile yatırımcılarına kripto varlık
            piyasalarında <b>7/24 en üst düzeyde</b> hizmetlerini sunmaktadır.
          </li>
        </ul>
      </div>

      <div class="row row-style">
        <ul>
          <li>
            <b>Bitistanbul</b> Kasım 2024 tarihi itibari ile sermayesini 70,000,000 TL’ye yükseltmiş; Kripto Varlık Hizmet Sağlayıcılığı’na  ilişkin yürürlüğe giren düzenlemeler çerçevesinde  bu alanda faaliyette bulunduğunu beyan ederek, Sermaye Piyasası Kurulu listelerinde yer almıştır.
          </li>
        </ul>
      </div>

      <div>
        <table id="center">
          <tr>
            <td class="text-center">
              <img src="assets/images/053.svg" alt="" />
              <br />
              <h4>25 Yılı Aşkın Tecrübe</h4>
            </td>
            <td class="text-center">
              <img src="assets/images/054.svg" alt="" />
              <br />
              <h4>Güçlü Sermaye Altyapısı</h4>
            </td>
            <td class="text-center">
              <img src="assets/images/055.svg" alt="" />
              <br />
              <h4>Kesintisiz Hizmet</h4>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

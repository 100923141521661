import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: 'app-bitcoin-wallet',
  templateUrl: './bitcoin-wallet.component.html',
  styleUrls: ['./bitcoin-wallet.component.css'],
})
export class BitcoinWalletComponent implements OnInit {

  
  constructor(private metaService: Meta, private titleService: Title) {}

  ngOnInit() {

    this.metaService.addTags([
      {
        name: "description",
        content:
          "Bitcoin Cüzdanı: Güvenli ve Gelişmiş Teknoloji ile Varlık Koruma",
      },
      {
        name: "keywords",
        content: "Bitcoin Cüzdanı",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);

    const pageTitle = 'Bitcoin Cüzdanı';
    this.titleService.setTitle(pageTitle);
 
  }
}

<!-- Bitcoin Cüzdanı Pages -->
<app-header></app-header>
<article>
  <section class="innerbannerpage">
    <div class="container">
      <div class="row">
        <table id="bitcoin-wallet-header">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: 86px"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                  alt=""
                />
                &nbsp;&nbsp;
                <small> {{ "BitcoinWallet" | translate }}</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <div class="text-center">
        <strong>Cüzdan Nedir?</strong>
      </div>

      <div class="row row-style">
        <p>
          Bir cüzdan, bitcoinlerle ve diğer kripto varlıklarla işlem yapmak için
          gerekli bilgileri saklayan dijital ortamdır. Cüzdanlar genellikle
          bitcoinleri tutacak veya depolayacak bir yer olarak tanımlanırken,
          sistemin doğası gereği, bitcoinler blockchain işlem defterinden
          ayrılamaz. Bitcoin, biri genel diğeri özel olmak üzere iki
          kriptografik anahtarın üretildiği açık anahtarlı kriptografi kullanır.
          Cüzdan en basit haliyle bu anahtarların bir koleksiyonudur.
        </p>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

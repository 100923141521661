import { Component, OnInit } from '@angular/core';

import { Meta, Title } from "@angular/platform-browser";
@Component({
  selector: 'app-security',
  templateUrl: './security-page.component.html',
  styleUrls: ['./security-page.component.css']
})
export class SecurityPageComponent implements OnInit {

  constructor(private metaService: Meta, private titleService: Title) {}

  ngOnInit() {
    // Meta etiketlerini ayarla
    this.metaService.addTags([
      {
        name: "description",
        content:
          "Gelişmiş teknoloji ve güvenli cüzdan altyapımız ile kripto varlıklarınızı koruyun.",
      },
      {
        name: "keywords",
        content: "Güvenlik",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);

    const pageTitle = 'Güvenlik';
    this.titleService.setTitle(pageTitle);
  }

}

import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import { environment } from "src/environments/environment";
import { HomeService } from "src/app/Services/home.service";
import { AuthService } from "src/app/Services/auth.service";
import { WebSocketIOService } from "src/app/Services/web-socket-io.service";
import { Subscription } from "rxjs";
import { FormattingService } from "src/app/Services/numberFormat.services";
import { Meta, Title } from "@angular/platform-browser";
declare var $;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  @ViewChild("myModal", { static: true }) myModal: ElementRef;
  public title = "Home|orderbook";
  public error = null;
  public success = null;
  public loggedIn;
  public data = null;
  public data1 = null;
  public orderbook_market = {};
  homeliveprice: any = [];
  isDisabled = false;
  sub: Subscription;
  siteUrl = environment.siteUrl;
  apiURL = environment.baseUrl;

  constructor(
    private home: HomeService,
    private Auth: AuthService,
    private WebSocketServiceIO: WebSocketIOService,
    public formattingService: FormattingService,
    private metaService: Meta, private titleService: Title
  ) {}

  ngOnInit() {


// Meta etiketlerini ayarla
this.metaService.addTags([
  {
    name: "description",
    content:
      "Kolay kullanım, yüksek likidite ve hızlı kripto işlemleri. 7/24 para yatırma çekme. Rekabetçi fiyatlar, düşük komisyon oranlarıyla güvenle kripto al, sat, sakla!",
  },
  {
    name: "keywords",
    content: "Bitistanbul | Türkiye'nin Güvenilir Kripto Para Platformu ",
  },
  { name: "robots", content: "index, follow" }, // Arama motorları için
]);

const pageTitle = "Bitistanbul | Türkiye'nin Güvenilir Kripto Para Platformu";

this.titleService.setTitle(pageTitle);




    // $('#myModal').modal('show');
    this.Auth.authStatus.subscribe((value) => (this.loggedIn = value));
    // this.homepage1();
    this.homepagelive_price();
    this.getSocketIODataBoard();
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("login-bg");
    this.home.homelivePrice().subscribe(
      (data) => this.handleLivepriceDetails(data),
      (error) => this.handleError(error)
    );
  }

  ngOnDestroy() {
    // Component yok edildiğinde bu metod çağrılır
    // Abonelikleri temizle
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  getSocketIODataBoard(): void {
    this.sub = this.WebSocketServiceIO.getData().subscribe(
      (data) => this.handleGetSocketDataBoard(data),
      (error) => this.handleError(error)
    );
  }

  handleGetSocketDataBoard(data) {
    if (data) {
      let json = data;
      if (json.orderbook_market) {
        this.orderbook_market = json.orderbook_market;
      }
    }
  }

  handleLivepriceDetails(data) {
    if (data.success) {
      this.homeliveprice = data.result;
    }
  }

  public form = {
    search: null,
  };
  public formsub = {
    email: null,
  };

  homepagelive_price() {
    this.home.homelivePrice().subscribe(
      (data) => this.handleorderDetails1(data),
      (error) => this.handleError1(error)
    );
  }

  handleorderDetails1(res) {
    if (res.success) {
      this.data1 = res.result;
    }
  }

  onSubmit() {
    this.isDisabled = true;
    return this.home.getsubscribe(this.formsub).subscribe(
      (data) => this.handleResponse(data),
      (error) => this.handleError2(error)
    );
  }

  handleResponse(error) {
    this.isDisabled = false;
    if (!error.success) {
      this.error = error.message;
    } else {
      this.success = error.message;
    }
  }

  isShow;
  topPosToStartShowing = 450;
  @HostListener("window:scroll")
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  handleError(error) {
    this.error = error.message;
  }

  handleError1(error) {
    this.error = error.message;
  }

  handleError2(error) {
    this.error = error.message;
  }
}

<app-header></app-header>

<article>
  <div class="pagecontent">
    <section class="innerpagecontent">
      <div class="container">
        <!-- app-twofa-kripto -->
        <app-twofa-kripto
          *ngIf="display"
          (close)="display = false"
          [tfaFormData]="userdata.guvenlik"
        ></app-twofa-kripto>

        <!-- app-alert-modal-success -->
        <app-alert-modal-success
          *ngIf="success"
          [success]="success"
          (close)="success = null"
          [WhiteListsForm]="whitelist"
        >
          {{ success }}</app-alert-modal-success
        >

        <!-- app-alert-modal -->
        <app-alert-modal
          *ngIf="error"
          [error]="error"
          (close)="error = null"
        ></app-alert-modal>

        <!-- Modal -->
        <div
          class="modal fade"
          id="exampleModalCenter"
          data-keyboard="false"
          data-backdrop="static"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  (click)="cancelModal()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h5
                  class="modal-title text-center mt-10 t-black"
                  id="exampleModalLongTitle"
                >
                  <b> Çekim Adresi Ekle </b>
                </h5>
              </div>
              <div class="modal-body">
                <form
                  #widrwAddressform="ngForm"
                  autocomplete="off"
                  id="widrwAddressform"
                >
                  <div class="form-group">
                    <label for="addressTitle">Adres Başlığı</label>
                    <input
                      type="text"
                      name="addressTitle"
                      id="addressTitle"
                      class="form-control"
                      aria-describedby="addressTitle"
                      placeholder="Adres Başlığı Giriniz. (Örnek: Çağtay Bitistanbul Hesabıdır)"
                      [(ngModel)]="formAddressTitle.addressTitle"
                      #addressTitle="ngModel"
                      required
                      autofocus
                    />
                  </div>

                  <div class="form-group">
                    <label for="criptoSelect">Varlık Seçimi</label>
                    <select
                      aria-label="coin"
                      class="form-control"
                      name="coin"
                      #coin="ngModel"
                      [(ngModel)]="formAddressTitle.coin"
                      (change)="setFilterAddr(coin.value)"
                      required
                    >
                      <option [ngValue]="null" selected disabled>
                        Kripto Varlık Seçiniz
                      </option>
                      <option
                        *ngFor="let c of lstCoins; let index = index"
                        [class.active]="index == 1"
                        [value]="c"
                      >
                        {{ c }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="criptoSelect">Ağ Seçimi</label>

                    <select
                      aria-label="coinNetwork"
                      class="form-control"
                      name="coinNetwork"
                      #coinNetwork="ngModel"
                      (change)="onChangedAddress($event)"
                      [(ngModel)]="formAddressTitle.coinNetwork"
                      required
                    >
                      <option [ngValue]="null" selected disabled>
                        Ağ Seçiniz
                      </option>
                      <option *ngFor="let cn of filterAddr" [value]="cn.type">
                        {{ cn.type }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="address">Adres</label>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      class="form-control"
                      aria-describedby="address"
                      placeholder="Çekim Adresi Giriniz"
                      [(ngModel)]="formAddressTitle.address"
                      #address="ngModel"
                      minlength="6"
                      required
                    />
                  </div>
                </form>
              </div>

              <div class="modal-footer">
                <div class="mb-20 alert-danger-min" *ngIf="error">
                  <div class="alert-danger text-center" [hidden]="!error">
                    {{ error }}
                  </div>
                </div>
                <button
                  type="button"
                  class="btn secondary-close"
                  (click)="cancelModal()"
                >
                  İptal
                </button> &nbsp;

                <input
                  type="submit"
                  value="Kaydet"
                  [disabled]="!widrwAddressform.form.valid"
                  class="btn orange-btn"
                  (click)="widrwAddressTitleAdd()"
                />
              </div>
              <br />
            </div>
          </div>
        </div>

        <!-- Update Modal -->
        <div
          class="modal fade"
          id="exampleModalCenterUpdate"
          role="dialog"
          aria-labelledby="exampleModalCenterUpdate"
          aria-hidden="true"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h5
                  class="modal-title text-center t-black"
                  id="exampleModalLongTitle"
                >
                  <b> Çekim Adresi Düzenle </b>
                </h5>
              </div>
              <div class="modal-body">
                <form
                  #widrwAddressUpdate="ngForm"
                  autocomplete="off"
                  id="widrwAddressUpdate"
                >
                  <div class="form-group">
                    <label for="addressTitle">Adres Başlığı</label>
                    <input
                      type="text"
                      name="addressTitle"
                      class="form-control"
                      aria-describedby="addressTitle"
                      placeholder="Adres Başlığı Giriniz. (Örnek: Çağtay Bitistanbul Hesabıdır)"
                      [(ngModel)]="formAddressTitle.addressTitleUpdate"
                      #addressTitle="ngModel"
                      required
                    />
                  </div>

                  <div class="form-group">
                    <label for="address">Adres</label>
                    <input
                      type="text"
                      name="address"
                      class="form-control"
                      aria-describedby="address"
                      placeholder="Çekim Adresi Giriniz"
                      [(ngModel)]="formAddressTitle.addressUpdate"
                      #address="ngModel"
                      minlength="6"
                      required
                    />
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <div class="mb-20 alert-danger-min" *ngIf="error">
                  <div class="alert-danger text-center" [hidden]="!error">
                    {{ error }}
                  </div>
                </div>
                <button
                  #closebutton
                  type="button"
                  class="btn secondary-close"
                  (click)="cancelModal()"
                >
                  İptal Et
                </button> &nbsp;

                <input
                  type="submit"
                  value="Güncelle"
                  [disabled]="!widrwAddressUpdate.form.valid"
                  class="btn orange-btn"
                  (click)="widrwAddressTitleUpdate()"
                />
              </div>
              <br />
            </div>
          </div>
        </div>

        <!-- suspend Metod -->
        <ng-container *ngIf="accountSuspendSuccess; else loggedOutTemplate">
          <div
            class="modal fade"
            id="suspend"
            data-keyboard="false"
            data-backdrop="static"
            role="dialog"
            aria-labelledby="suspendTitle"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="text-center">
                    <img src="../../../assets/icons/172_1.png" alt="" />
                    <h1 class="f42153">Hesabınızı askıya almak üzeresiniz.</h1>
                    <br />
                    <p>
                      Askıya alınan hesaplar ancak destek ekibimiz ile
                      <br />iletişime geçilerektekrar aktive edebilmektedir.
                    </p>
                    <br />
                    <p>Hesabı askıya aldığınızda:</p>
                    <p class="text-center">
                      <li>
                        <b
                          >Eğer varsa tüm bekleyen çekim işlemleriniz iptal
                          edilir.</b
                        >
                      </li>
                    </p>

                    <p>
                      <li>
                        <b
                          >Eğer varsa tüm bekleyen limit emirleriniz iptal
                          edilir.</b
                        >
                      </li>
                    </p>
                    <p>
                      <li>
                        <b>Borsa ekranınız işleme kapatılır.</b>
                      </li>
                    </p>
                    <p>
                      <li>
                        <b>Hesabınıza ait tüm cihazlardan çıkış yapılır ve</b>
                      </li>
                    </p>
                    <p>
                      <b>tekrar girişe izin verilmez.</b>
                    </p>
                    <p>
                      <li>
                        <b
                          >Sistem tarafından blokzincire iletilmiş çekme
                          işlemleri geri alınamamakta ve iptal
                          edilememektedir.</b
                        >
                      </li>
                    </p>
                    <p>
                      <li>
                        <b>Doğrulanmış kişisel bilgileriniz silinmez.</b>
                      </li>
                    </p>
                  </div>
                </div>
                <div class="text-center"><p>Onay veriyor musunuz?</p></div>
                <div
                  class="modal-footer"
                  style="display: flex; justify-content: center"
                >
                  <button
                    type="button"
                    class="btn secondary-close"
                    (click)="cancelModal()"
                  >
                    Hayır
                  </button> &nbsp;&nbsp;

                  <button
                    type="button"
                    class="btn orange-btn"
                    (click)="accountSuspendMethod()"
                  >
                    Evet
                  </button>
                </div>
                <br />
                <br />
              </div>
            </div>
          </div>
        </ng-container>

        <ng-template #loggedOutTemplate>
          <div
            id="suspend"
            class="modal fade"
            data-keyboard="false"
            data-backdrop="static"
            role="dialog"
            aria-labelledby="suspendTitle"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    (click)="cancelModalloggedOut($event)"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="text-center">
                    <img src="../../../assets/icons/173.png" alt="" />
                    <h1 class="f21254a">
                      <b> Hesabınızı askıya alma talebinizi aldık.</b>
                    </h1>
                    <br />
                    <p>Tekrar aktive etmek için lütfen</p>
                    <p>
                      <b class="f21254a"> destek ekibimiz</b> ile iletişime
                      geçiniz.
                    </p>
                  </div>
                </div>
                <div
                  class="modal-footer"
                  style="display: flex; justify-content: center"
                >
                  <button
                    type="button"
                    class="btn secondary-close"
                    (click)="cancelModalloggedOut($event)"
                  >
                  Çıkış
                  </button>
                </div>
                <br />
                <br />
              </div>
            </div>
          </div>
        </ng-template>

        <!-- adres sil -->
        <div
          class="modal fade"
          id="addressDelete"
          data-keyboard="false"
          data-backdrop="static"
          role="dialog"
          aria-labelledby="addressDeleteTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="text-center">
                  <b>{{ whitelistDeleteItem?.title }}</b> başlıklı çekim adresi
                  silinecektir.
                </div>
              </div>
              <div class="text-center"><p>Onay veriyor musunuz?</p></div>
              <div
                class="modal-footer"
                style="display: flex; justify-content: center; padding: 50"
              >
                <button
                  type="button"
                  class="btn secondary-close"
                  (click)="cancelModal()"
                >
                  Hayır
                </button>

                <button
                  type="button"
                  class="btn orange-btn"
                  (click)="deleteWhiteLists(whitelistDeleteItem)"
                >
                  Evet
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>

        <!-- sequrityAccount table -->
        <table id="sequrityAccount">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 17px"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  alt=""
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                />
                &nbsp;&nbsp;
                <small> {{ "Security" | translate }}</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>

        <!-- anamenu -->
        <ul class="nav nav-tabs">
          <li class="active"><a data-toggle="tab" href="#home">Home</a></li>
          <li><a data-toggle="tab" href="#FA2">Menu 1</a>FA2</li>
          <li>
            <a data-toggle="tab" href="#loginAndVarlik">loginAndVarlik</a>
          </li>
          <li>
            <a data-toggle="tab" href="#accountPasschange">accountPasschange</a>
          </li>
          <li><a data-toggle="tab" href="#widAddressSeq">widAddressSeq</a></li>
          <li><a data-toggle="tab" href="#oltalama">oltalama</a></li>
          <li>
            <a data-toggle="tab" href="#deviceManagement">deviceManagement</a>
          </li>

          <li>
            <a data-toggle="tab" href="#systemManagement">systemManagement</a>
          </li>

          <li>
            <a data-toggle="tab" href="#timeout_Period">timeout_Period</a>
          </li>
        </ul>

        <div class="tab-content">
          <!-- home -->
          <div id="home" class="tab-pane fade in active">
            <!-- 2FA -->
            <div class="row">
              <br />
              <div class="col-sm-8">
                <h1>2FA Google Doğrulama</h1>
                <p>
                  Güvenlik ayarlarınızı ve varlık çekme işlemlerinizi güvenle
                  yapabilmeniz için tavsiye ediyoruz.
                </p>
              </div>

              <div class="col-sm-4">
                <button
                  type="submit"
                  class="btn editButton"
                  aria-label="Left Align"
                  onclick="activateTabAcSecurity('FA2')"
                  name="activateUser"
                >
                  Düzenle
                </button>
                <!-- <label class="switch">
                  <input
                    type="checkbox"
                    placeholder="checkbox"
                    [checked]="userdata.google2fa_verify == 1"
                    [(ngModel)]="isCheckedGoogle"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="isCheckedGoogleMethod()"
                  />
                  <span class="slider round"></span>
                </label> -->
              </div>
            </div>
            <div class="line"></div>

            <!-- loginAndVarlik -->
            <div class="row">
              <div class="col-sm-8">
                <h1>Güvenlik ve Doğrulama Ayarları</h1>
                <p>
                  Giriş, doğrulama ve varlık çekme güvenlik ayarlarını buradan
                  yönetebilirsiniz.
                </p>
              </div>

              <div class="col-sm-4">
                <button
                  type="submit"
                  class="btn editButton"
                  aria-label="Left Align"
                  (click)="loginAndVarlikG()"
                  name="activateUser"
                >
                  Düzenle
                </button>
              </div>
            </div>
            <div class="line"></div>

            <!-- Giriş Şifresi Güncelleme -->
            <div class="row">
              <div class="col-sm-8">
                <h1>Giriş Şifresi Güncelleme</h1>
                <p>Giriş şifrenizi buradan değiştirebilirsiniz.</p>
              </div>
              <div class="col-sm-4">
                <button
                  type="submit"
                  class="btn editButton"
                  aria-label="Left Align"
                  (click)="isCheckedPasschangeMethod()"
                  name="activateUser"
                >
                  Düzenle
                </button>
              </div>
            </div>
            <div class="line"></div>

            <!-- Adres Yönetimi -->
            <div class="row">
              <div class="col-sm-8">
                <h1>Adres Yönetimi</h1>
                <p>
                  Varlık çekme işlemleri için kullanmak istediğiniz adresleri
                  buradan yönetebilirsiniz.
                </p>
              </div>

              <div class="col-sm-4">
                <button
                  type="submit"
                  class="btn editButton"
                  aria-label="Left Align"
                  onclick="activateTabAcSecurity('widAddressSeq')"
                  name="activateUser"
                >
                  Düzenle
                </button>
              </div>
            </div>
            <div class="line"></div>

            <!-- oltalama -->
            <div class="row">
              <div class="col-sm-8">
                <h1>Oltalama Engelleme Kodu</h1>
                <p>
                  Kod oluşturarak, size ulaşan maillerin Bitistanbul’dan
                  geldiğini doğrulayabilirsiniz.
                </p>
              </div>

              <div class="col-sm-4">
                <button
                  type="submit"
                  class="btn editButton"
                  aria-label="Left Align"
                  (click)="oltalamaResponse()"
                  name="activateUser"
                >
                  Düzenle
                </button>
              </div>
            </div>
            <div class="line"></div>

            <!-- Cihaz Yönetimi -->
            <div class="row">
              <div class="col-sm-8">
                <h1>Cihaz Yönetimi</h1>
                <p>Hesabınıza erişim izni olan cihazlar burada listelenir.</p>
              </div>

              <div class="col-sm-4">
                <button
                  type="submit"
                  class="btn editButton"
                  aria-label="Left Align"
                  onclick="activateTabAcSecurity('deviceManagement')"
                  name="activateUser"
                >
                  Düzenle
                </button>
              </div>
            </div>
            <div class="line"></div>

            <!-- Sistem Kullanım Bilgileri -->
            <div class="row">
              <div class="col-sm-8">
                <h1>Sistem Kullanım Bilgileri</h1>
                <p>
                  Hesabınıza ne zaman giriş yaptığınızı ve işlem geçmişinizi
                  detaylarıyla görebilirsiniz.
                </p>
              </div>

              <div class="col-sm-4">
                <button
                  type="submit"
                  class="btn editButton"
                  aria-label="Left Align"
                  onclick="activateTabAcSecurity('systemManagement')"
                  name="activateUser"
                >
                  Düzenle
                </button>
              </div>
            </div>
            <div class="line"></div>

            <!-- Zaman Aşımı Yönetimi -->
            <div class="row">
              <div class="col-sm-9">
                <h1>Zaman Aşımı Yönetimi</h1>
                <p>
                  Hesabınızda belli bir süre hareket olmadığında devreye giren
                  otomatik çıkış için süre belirleyebilirsiniz.
                </p>
              </div>

              <div class="col-sm-3">
                <button
                  type="submit"
                  class="btn editButton"
                  aria-label="Left Align"
                  (click)="isCheckedtimeout_Period()"
                >
                  Düzenle
                </button>
              </div>

              <!-- <div class="col-sm-3">
                <button
                  type="submit"
                  class="btn editButton"
                  aria-label="Left Align"
                  onclick="activateTabAcSecurity('timeout_Period')"
                  name="activateUser"
                >
                  Düzenle
                </button>
              </div> -->
            </div>
            <div class="line"></div>

            <!-- Askıya Al -->
            <div class="row" id="suspend">
              <div class="col-sm-9">
                <h1 class="f42153">Hesabımı Askıya Al</h1>
                <p>
                  Hesabınızda şüpheli bir hareket olduğunu düşünüyorsanız
                  buradan askıya alabilirsiniz.
                </p>
              </div>

              <div class="col-sm-3">
                <button
                  type="submit"
                  class="btn editButton suspend"
                  aria-label="Askıya Al"
                  (click)="suspendMetod()"
                >
                  Askıya Al
                </button>
              </div>
            </div>
            <br />
          </div>

          <!------------------------------------------------ içerik content ------------------------------------------------------------------->
          <!-- 2FA Google Doğrulama -->
          <div id="FA2" class="tab-pane fade">
            <form class="google_code_form">
              <div class="form-group text-center">
                <div class="row text-center">
                  <h4>2FA Google Doğrulama</h4>
                  <div class="line mt-10"></div>
                </div>
              </div>

              <div class="form-group text-center mt-20">
                <p>
                  Güvenlik ayarlarınızı ve varlık çekme işlemlerinizi güvenle
                  yapabilmeniz için 2FA Google Doğrulama kullanmanızı tavsiye
                  ediyoruz.
                </p>
                <br />
                <p>2FA Google Doğrulamayı Etkinleştirmek için:</p>
                <p>
                  Mobil cihazınıza indirebileceğiniz Google Authenticator
                  uygulamasını kullanarak
                </p>
                <p>
                  aşağıdaki <b>QR kodunu okutun</b> veya aşağıdaki
                  <b>kurulum anahtarını </b>Google Authenticator uygulamasına
                  tanımlayın.
                </p>

                <p>
                  Google Authenticator tarafından size verilen kodu aşağıdaki
                  alana girerek <b>"Kodu Onayla"</b> butonuna tıklayınız.
                </p>
              </div>

              <div class="form-group text-center mt-40">
                <img
                  alt=""
                  src="data:image/png;base64,{{ userdata.google2fa_qr }}"
                  class="qrcode-bg"
                />
              </div>

              <div class="form-group text-center mt-30">
                <label for="smsValidation">KURULUM ANAHTARI : </label>
                <strong> {{ userdata.google2fa_secret }}</strong>
              </div>

              <div class="form-group mt-20">
                <div class="FA2 center col-sm-6">
                  <input
                    type="text"
                    class="form-control"
                    id="google_code"
                    aria-describedby="google_code"
                    placeholder="Authenticator kodunu giriniz"
                    [(ngModel)]="frm_google_code.google_code"
                    [ngModelOptions]="{ standalone: true }"
                    onkeyup="if(this.value.length == 6) this.blur()"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                    [maxLength]="6"
                  />

                  <input
                    type="button"
                    class="googleCode-button"
                    value=" Kodu Onayla"
                    [disabled]="frm_google_code.google_code?.length < 6"
                    (click)="FA2Buttonfunc()"
                  />
                </div>
              </div>

              <!-- <div class="text-center mt-40">
                <button
                  type="button"
                  type="generalSituation"
                  class="editButton"
                  (click)="backHome()"
                >
                  Geri
                </button>
              </div> -->
              <div class="form-group mt-40 text-center">
                <button
                  type="button"
                  class="editButton"
                  [disabled]="isDisabledBackButton"
                  (click)="backHome()"
                >
                  <span [ngClass]="{ 'fadedBackButton-text': isDisabledBackButton }">
                    {{ isDisabledBackButton ? ('Lütfen Bekleyin' | translate) + '...' : ('Geri' | translate) }}
                  </span>
                </button>
              </div>

            </form>
            <br />
          </div>

          <!-- Güvenlik ve Doğrulama Ayarları -->
          <div id="loginAndVarlik" class="tab-pane fade">
            <form
              class="changePass"
              autocomplete="off"
              #loginAndVarlik="ngForm"
              id="loginAndVarlik"
            >
              <div class="form-group">
                <div class="row text-center">
                  <h4>Güvenlik ve Doğrulama Ayarları</h4>
                  <div class="line mt-10"></div>
                </div>
              </div>

              <div class="form-group text-center mt-20">
                <p>
                  Hesabınıza giriş yapmak, kripto varlık çekme işlemlerinizi
                  gerçekleştirmek
                </p>
                <p>
                  ve güvenlik ayarlarınızı yönetmek için kullanacağınız
                  doğrulama yöntemlerini seçebilirsiniz.
                </p>
              </div>
              <div class="form-group text-center mt-60">
                <table class="table" id="LoginAndVarlik">
                  <thead>
                    <tr>
                      <th class="text-center"></th>
                      <th class="text-center">Sms Doğrulama</th>
                      <th class="text-center">Email Doğrulama</th>
                      <th class="text-center">2FA Google Doğrulama</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- Giriş Güvenliği -->
                    <tr>
                      <td class="text-left">Giriş Güvenliği</td>
                      <td>
                        <input
                          [(ngModel)]="girisObjActive"
                          [checked]="girisObjActive == 1"
                          [value]="girisObjActive"
                          type="checkbox"
                          name="smsSequrityGiris"
                          aria-label="smsSequrityGiris"
                          class="form-check-input"
                          (ngModelChange)="girisObjActive = $event ? 1 : 0"
                          [(ngModel)]="input1Value"
                          (ngModelChange)="onFormChange()"
                        />
                      </td>
                      <td>
                        <input
                          [(ngModel)]="girisObjActiveEmail"
                          [checked]="girisObjActiveEmail == 1"
                          [value]="girisObjActiveEmail"
                          type="checkbox"
                          name="girisObjActiveEmail"
                          aria-label="girisObjActiveEmail"
                          class="form-check-input"
                          (ngModelChange)="girisObjActiveEmail = $event ? 1 : 0"
                          [(ngModel)]="input2Value"
                          (ngModelChange)="onFormChange()"
                        />
                      </td>

                      <td>
                        <input
                          [(ngModel)]="girisObjActiveGoogle"
                          [checked]="girisObjActiveGoogle == 1"
                          [value]="girisObjActiveGoogle"
                          type="checkbox"
                          name="girisObjActiveGoogle"
                          aria-label="girisObjActiveGoogle"
                          class="form-check-input"
                          (ngModelChange)="
                            girisObjActiveGoogle = $event ? 1 : 0
                          "
                          [disabled]="userdata.google_isactive == 0"
                          title="Google Aktif Etmek için 2FA Google Doğrulama Sayfasındaki İşlemleri Gerçekleştiriniz."
                          [(ngModel)]="input3Value"
                          (ngModelChange)="onFormChange()"
                        />
                      </td>
                    </tr>

                    <!-- Kripto Çekme -->
                    <tr>
                      <td class="text-left">Kripto Çekme</td>
                      <td>
                        <input
                          [(ngModel)]="cekmeObjActive"
                          [checked]="cekmeObjActive == 1"
                          [value]="cekmeObjActive"
                          type="checkbox"
                          name="cekmeObjActive"
                          aria-label="cekmeObjActive"
                          class="form-check-input"
                          (ngModelChange)="cekmeObjActive = $event ? 1 : 0"
                          [(ngModel)]="input4Value"
                          (ngModelChange)="onFormChange()"
                        />
                      </td>

                      <td>
                        <input
                          [(ngModel)]="cekmeObjActiveEmail"
                          [checked]="cekmeObjActiveEmail == 1"
                          [value]="cekmeObjActiveEmail"
                          type="checkbox"
                          name="cekmeObjActiveEmail"
                          aria-label="cekmeObjActiveEmail"
                          class="form-check-input"
                          (ngModelChange)="cekmeObjActiveEmail = $event ? 1 : 0"
                          [(ngModel)]="input5Value"
                          (ngModelChange)="onFormChange()"
                        />
                      </td>

                      <td>
                        <input
                          [(ngModel)]="cekmeObjActiveGoogle"
                          [checked]="cekmeObjActiveGoogle == 1"
                          [value]="cekmeObjActiveGoogle"
                          type="checkbox"
                          name="cekmeObjActiveGoogle"
                          aria-label="cekmeObjActiveGoogle"
                          class="form-check-input"
                          (ngModelChange)="
                            cekmeObjActiveGoogle = $event ? 1 : 0
                          "
                          [disabled]="userdata.google_isactive == 0"
                          title="Google Aktif Etmek için 2FA Google Doğrulama Sayfasındaki İşlemleri Gerçekleştiriniz."
                          [(ngModel)]="input6Value"
                          (ngModelChange)="onFormChange()"
                        />
                      </td>
                    </tr>

                    <!-- Güvenlik Ayarları Değişimi -->
                    <tr>
                      <td class="text-left">Güvenlik Ayarları Değişimi</td>
                      <td>
                        <input
                          [(ngModel)]="guvenlikObjActive"
                          [checked]="guvenlikObjActive == 1"
                          [value]="guvenlikObjActive"
                          type="checkbox"
                          name="guvenlikObjActive"
                          aria-label="guvenlikObjActive"
                          class="form-check-input"
                          (ngModelChange)="guvenlikObjActive = $event ? 1 : 0"
                          [(ngModel)]="input7Value"
                          (ngModelChange)="onFormChange()"
                        />
                      </td>
                      <td>
                        <input
                          [(ngModel)]="guvenlikObjActiveEmail"
                          [checked]="guvenlikObjActiveEmail == 1"
                          [value]="guvenlikObjActiveEmail"
                          type="checkbox"
                          name="guvenlikObjActiveEmail"
                          aria-label="guvenlikObjActiveEmail"
                          class="form-check-input"
                          (ngModelChange)="
                            guvenlikObjActiveEmail = $event ? 1 : 0
                          "
                          [(ngModel)]="input8Value"
                          (ngModelChange)="onFormChange()"
                        />
                      </td>

                      <td>
                        <input
                          [(ngModel)]="guvenlikObjActiveGoogle"
                          [checked]="guvenlikObjActiveGoogle == 1"
                          [value]="guvenlikObjActiveGoogle"
                          type="checkbox"
                          name="guvenlikObjActiveGoogle"
                          aria-label="guvenlikObjActiveGoogle"
                          class="form-check-input"
                          (ngModelChange)="
                            guvenlikObjActiveGoogle = $event ? 1 : 0
                          "
                          [disabled]="userdata.google_isactive == 0"
                          title="Google Aktif Etmek için 2FA Google Doğrulama Sayfasındaki İşlemleri Gerçekleştiriniz."
                          [(ngModel)]="input9Value"
                          (ngModelChange)="onFormChange()"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="text-right">
                  <button
                    type="button"
                    class="btn orange-btn"
                    (click)="onSubmitLoginAndVarlik()"
                    [disabled]="!isFormChanged"
                  >
                    Tercihleri Kaydet
                  </button>
                </div>
              </div>
              <div class="form-group mt-40 text-center">
                <button
                  type="button"
                  class="editButton"
                  [disabled]="isDisabledBackButton"
                  (click)="backHome()"
                >
                  <span [ngClass]="{ 'fadedBackButton-text': isDisabledBackButton }">
                    {{ isDisabledBackButton ? ('Lütfen Bekleyin' | translate) + '...' : ('Geri' | translate) }}
                  </span>
                </button>
              </div>
            </form>
          </div>

          <!-- accountPasschange -->
          <div id="accountPasschange" class="tab-pane fade">
            <form
              class="changePass"
              autocomplete="off"
              #changePasswordForm="ngForm"
              id="changePasswordForm"
            >
              <div class="form-group">
                <div class="row text-center">
                  <h4>Giriş Şifresi Güncelleme</h4>
                  <div class="line mt-10"></div>
                </div>
              </div>

              <div class="form-group text-center mt-20">
                <p>
                  Şifreniz en az 8 karakterden oluşmalı ve en az bir rakam, bir
                  büyük harf, bir küçük harf ve bir özel karakter içermelidir.
                </p>
                <p>
                  Şifrenizi değiştirdikten sonra 24 saat süre ile kripto varlık
                  ve Türk Lirası transferleri gerçekleştiremezsiniz.
                </p>
              </div>

              <!-- old_password -->
              <div class="form-group mt-40">
                <div class="center col-sm-6">
                  <label for="old_password"> Mevcut Şifrenizi Giriniz:</label>
                  <input
                    type="password"
                    name="old_password"
                    id="old_password"
                    class="form-control"
                    autofocus
                    placeholder="{{ 'CurrentPassword' | translate }}*"
                    [class.is-invalid]="
                      old_password.invalid && old_password.touched
                    "
                    [(ngModel)]="form.old_password"
                    #old_password="ngModel"
                    [type]="hideold_password ? 'password' : 'text'"
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    required
                  />

                  <span class="p-viewer">
                    <i
                      class="material-icons"
                      matSuffix
                      (click)="hideold_password = !hideold_password"
                      >{{
                        hideold_password ? "visibility_off" : "visibility"
                      }}</i
                    >
                  </span>

                  <span
                    *ngIf="
                      old_password.errors &&
                      old_password.invalid &&
                      old_password.touched
                    "
                  >
                    <!-- <small
                      class="text-danger-input"
                      *ngIf="old_password.errors.required"
                      >{{ "CurrentPassword" | translate }}
                      {{ "required" | translate }}.</small
                    > -->
                    <small
                      class="text-danger-input"
                      *ngIf="old_password.errors.pattern"
                      >Kurallara uygun şifre giriniz</small
                    >
                  </span>
                </div>
              </div>

              <!-- new_password -->
              <div class="form-group mt-40">
                <div class="center col-sm-6">
                  <label for="new_password"> Yeni Şifrenizi Giriniz:</label>
                  <input
                    type="password"
                    name="new_password"
                    id="new_password"
                    class="form-control"
                    placeholder="{{ 'NewPassword' | translate }}*"
                    autocomplete="off"
                    [class.is-invalid]="
                      new_password.invalid && new_password.touched
                    "
                    [(ngModel)]="form.new_password"
                    #new_password="ngModel"
                    [type]="hidenew_password ? 'password' : 'text'"
                    pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$"
                    required
                  />

                  <span class="p-viewer">
                    <i
                      class="material-icons"
                      matSuffix
                      (click)="hidenew_password = !hidenew_password"
                      >{{
                        hidenew_password ? "visibility_off" : "visibility"
                      }}</i
                    >
                  </span>
                  <span
                    *ngIf="
                      new_password.errors &&
                      new_password.invalid &&
                      new_password.touched
                    "
                  >
                    <!-- <small
                      class="text-danger-input"
                      *ngIf="new_password.errors.required"
                      >{{ "NewPassword" | translate }}
                      {{ "required" | translate }}.</small
                    > -->
                    <small
                      class="text-danger-input"
                      *ngIf="new_password.errors.pattern"
                      >Kurallara uygun şifre giriniz</small
                    >
                  </span>
                </div>
              </div>

              <!-- confirm_password -->
              <div class="form-group mt-40">
                <div class="center col-sm-6">
                  <label for="confirm_password">
                    Yeni Şifrenizi Tekrar Giriniz:</label
                  >
                  <input
                    type="password"
                    name="confirm_password"
                    id="confirm_password"
                    placeholder="{{ 'Confirmpassword' | translate }}*"
                    class="form-control"
                    autocomplete="off"
                    [(ngModel)]="form.confirm_password"
                    #confirm_password="ngModel"
                    [type]="hideconfirm_password ? 'password' : 'text'"
                    required
                  />

                  <span class="p-viewer">
                    <i
                      class="material-icons"
                      matSuffix
                      (click)="hideconfirm_password = !hideconfirm_password"
                      >{{
                        hideconfirm_password ? "visibility_off" : "visibility"
                      }}</i
                    >
                  </span>
                  <span
                    *ngIf="
                      confirm_password.errors &&
                      confirm_password.invalid &&
                      confirm_password.touched
                    "
                  >
                    <!-- <small
                      class="text-danger-input"
                      *ngIf="confirm_password.errors.required"
                      >Şifrenizi Doğrulayın Gerekli.
                    </small> -->
                    <small
                      class="text-danger-input"
                      *ngIf="confirm_password.errors.pattern"
                      >Şifrenizi Doğrulayın Gerekli.</small
                    >
                  </span>
                </div>
              </div>

              <!-- buttons -->
              <div class="mt-50">
                <div class="center col-sm-6">
                  <input
                    type="submit"
                    name=""
                    value="Şifremi Yenile"
                    *ngIf="!isDisabled"
                    [disabled]="!changePasswordForm.form.valid"
                    class="btn orange-btn-lgn"
                    (click)="onSubmitChangePassw()"
                  />
                  <input
                    type="submit"
                    name=""
                    value="{{ 'Pleasewait' | translate }}..."
                    class="btn orange-btn-lgn"
                    disabled
                    *ngIf="isDisabled"
                  />
                </div>
              </div>
             
              <div class="form-group mt-40 text-center">
                <button
                  type="button"
                  class="editButton"
                  [disabled]="isDisabledBackButton"
                  (click)="backHome()"
                >
                  <span [ngClass]="{ 'fadedBackButton-text': isDisabledBackButton }">
                    {{ isDisabledBackButton ? ('Lütfen Bekleyin' | translate) + '...' : ('Geri' | translate) }}
                  </span>
                </button>
              </div>
              
            </form>
            <!-- Geri -->

            <br />
          </div>

          <!-- widAddressSeq Çekim Adresi Güvenliği -->
          <div id="widAddressSeq" class="tab-pane fade">
            <div class="template">
              <form
                class=" "
                autocomplete="off"
                #fishingForm="ngForm"
                id="fishingForm"
              >
                <div class="form-group text-center">
                  <div class="row text-center">
                    <h4>Adres Yönetimi</h4>
                    <div class="line mt-10"></div>
                  </div>
                </div>

                <div class="form-group text-center mt-20">
                  <p>
                    Çekme işlemleriniz için kullanmak istediğiniz adresleri
                    kaydedebilir ve düzenleyebilirsiniz.
                  </p>
                </div>

                <div class="form-group text-center mt-50">
                  <input
                    type="submit"
                    name=""
                    value="Çekim Adresi Ekle"
                    class="btn orange-btn"
                    (click)="AddressAdd()"
                  />
                </div>

                <div
                  class="text-center mt-30"
                  *ngIf="whitelist.length > 0; else loadingWhitelist"
                >
                  <div class="row mt-60">
                    <div class="content-bar">
                      <div class="table-responsive sitescroll" data-simplebar>
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Adres Başlığı</th>
                              <th scope="col">Varlık</th>
                              <th scope="col">Ağ</th>
                              <th scope="col">Adres</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody class="text-left">
                            <tr *ngFor="let item of whitelist; let i = index">
                              <td>{{ item?.title }}</td>
                              <td>{{ item?.pair }}</td>
                              <td>{{ item?.network }}</td>
                              <td>{{ item?.address }}</td>

                              <td class="text-right">
                                <button
                                  type="submit"
                                  class="btn editButton-update"
                                  aria-label="update"
                                  name="update"
                                  title="Çekim Adresi Güncelle"
                                  data-toggle="modal"
                                  data-target="#exampleModalCenterUpdate"
                                  (click)="updateWhiteLists(i)"
                                >
                                  Düzenle
                                </button>
                              </td>

                              <td>
                                <button
                                  type="submit"
                                  class="btn editButton-delete"
                                  aria-label="delete"
                                  name="delete"
                                  title="Çekim Adresi Sil"
                                  (click)="deleteWhiteListsID(item)"
                                >
                                  Sil
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <ng-template #loadingWhitelist>
                  <h1 class="text-center mt-50">
                    Henüz hesabınıza kayıtlı çekim adresiniz bulunmuyor.
                  </h1>
                </ng-template>
                <div class="form-group mt-40 text-center">
                  <button
                    type="button"
                    class="editButton"
                    [disabled]="isDisabledBackButton"
                    (click)="backHome()"
                  >
                    <span [ngClass]="{ 'fadedBackButton-text': isDisabledBackButton }">
                      {{ isDisabledBackButton ? ('Lütfen Bekleyin' | translate) + '...' : ('Geri' | translate) }}
                    </span>
                  </button>
                </div>
              </form>
            </div>
            <br />
          </div>

          <!-- oltalama -->
          <div id="oltalama" class="tab-pane fade">
            <div class="template">
              <ng-template
                [ngIf]="userdata.fishing_status == 0"
                [ngIfElse]="updateFishingCode"
              >
                <div>
                  <form
                    class=" "
                    autocomplete="off"
                    #fishingForm="ngForm"
                    id="fishingForm"
                  >
                    <div class="form-group text-center">
                      <div class="row text-center">
                        <h4>Oltalama Engelleme Kodu</h4>
                        <div class="line mt-10"></div>
                      </div>
                    </div>

                    <div class="form-group text-center mt-20">
                      <p>
                        Oltalama Engelleme Kodu, sahte e-postalardan gelen kötü
                        niyetli girişimleri engellemeye yardımcı olan bir
                        koddur.
                      </p>
                      <p>
                        Oltalama Engelleme Kodunuzu belirlediğinizde, kod tüm
                        Bitistanbul e-postalarında yer alacaktır.
                      </p>
                    </div>

                    <div class="form-group mt-40">
                      <div class="FA2 center col-sm-6">
                        <input
                          type="text"
                          name="fishing_code"
                          id="fishing_code"
                          class="form-control"
                          placeholder="Oltalama Kodunuzu 6 Haneli Rakam Olarak Giriniz"
                          [(ngModel)]="frm_fishing_code.fishing_code"
                          #fishing_code="ngModel"
                          onkeyup="if(this.value.length == 6) this.blur()"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          [maxLength]="6"
                          minlength="6"
                          pattern="[0-9]+"
                          required
                        />
                      </div>
                    </div>

                    <!-- buttons -->

                    <div class="form-group text-center mt-40">
                      <input
                        type="submit"
                        name=""
                        value="Oltalama Kodunu Kaydet"
                        *ngIf="!isDisabledFishing"
                        [disabled]="!fishingForm.form.valid"
                        class="btn orange-btn"
                        (click)="FishingButtonfunc()"
                      />
                      <input
                        type="submit"
                        name=""
                        value="{{ 'Pleasewait' | translate }}..."
                        class="btn orange-btn"
                        disabled
                        *ngIf="isDisabledFishing"
                      />
                    </div>
                  </form>
                </div>
              </ng-template>

              <ng-template #updateFishingCode>
                <div>
                  <form
                    class=" "
                    autocomplete="off"
                    #fishingUpdateForm="ngForm"
                    id="fishingUpdateForm"
                  >
                    <div class="form-group text-center">
                      <div class="row text-center">
                        <h4>Oltalama Engelleme Kodu</h4>
                        <div class="line mt-10"></div>
                      </div>
                    </div>

                    <div class="form-group text-center mt-20">
                      <p>
                        Oltalama Engelleme Kodu, sahte e-postalardan gelen kötü
                        niyetli girişimleri engellemeye yardımcı olan bir
                        koddur.
                      </p>
                      <p>
                        Oltalama Engelleme Kodunuzu belirlediğinizde, kod tüm
                        Bitistanbul e-postalarında yer alacaktır.
                      </p>
                    </div>

                    <div class="form-group mt-40">
                      <div class="FA2 center col-sm-6">
                        <label for="fishing_code_old" class="text-center">
                          Mevcut Oltalama Engelleme Kodu :</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="test"
                          placeholder="Oltalama Kodunuzu 6 Haneli Rakam Olarak Giriniz..."
                          value="{{ userdata.fishing_code }}"
                          disabled="disabled"
                        />
                      </div>
                    </div>

                    <div class="form-group mt-30">
                      <div class="FA2 center col-sm-6">
                        <label for="fishing_code_new" class="text-center">
                          Yeni Oltalama Engelleme Kodu :</label
                        >

                        <input
                          type="text"
                          name="fishing_code_new"
                          id="fishing_code_new"
                          class="form-control"
                          placeholder="Yeni Oltalama Kodunuzu 6 Haneli Rakam Olarak Giriniz"
                          [(ngModel)]="frm_fishing_code.fishing_code_new"
                          #fishing_code_new="ngModel"
                          onkeyup="if(this.value.length == 6) this.blur()"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          [maxLength]="6"
                          minlength="6"
                          pattern="[0-9]+"
                          required
                        />
                      </div>
                    </div>

                    <!-- buttons -->

                    <div class="mt-40">
                      <div class="center col-sm-6">
                        <input
                          type="submit"
                          name=""
                          value="Yeni Oltalama Kodunu Kaydet"
                          *ngIf="!isDisabledFishingUpd"
                          [disabled]="!fishingUpdateForm.form.valid"
                          class="btn orange-btn-lgn"
                          (click)="FishingButtonfuncUpdate()"
                        />
                        <input
                          type="submit"
                          name=""
                          value="{{ 'Pleasewait' | translate }}..."
                          class="btn orange-btn-lgn"
                          disabled
                          *ngIf="isDisabledFishingUpd"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </ng-template>

              <div class="form-group mt-40 text-center">
                <button
                  type="button"
                  class="editButton"
                  [disabled]="isDisabledBackButton"
                  (click)="backHome()"
                >
                  <span [ngClass]="{ 'fadedBackButton-text': isDisabledBackButton }">
                    {{ isDisabledBackButton ? ('Lütfen Bekleyin' | translate) + '...' : ('Geri' | translate) }}
                  </span>
                </button>
              </div>
            </div>
            <br />
          </div>

          <!-- Cihaz Yönetimi -->
          <div
            id="deviceManagement"
            class="tab-pane fade"
            style="min-height: 450px"
          >
            <div class="systemManagement">
              <div class="form-group">
                <div class="row text-center">
                  <h4>Cihaz Yönetimi</h4>
                  <div class="line mt-10"></div>
                </div>
              </div>

              <div class="form-group text-center mt-20">
                <p>
                  Hangi cihazların hesabınıza erişebileceğini bu alandan
                  yönetebilirsiniz.
                </p>
              </div>

              <div class="row mt-60">
                <div class="content-bar">
                  <div class="table-responsive sitescroll" data-simplebar>
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Cihaz</th>
                          <th scope="col">IP Adresi</th>

                          <th scope="col">Tarih & Saat</th>
                          <th scope="col" class="text-right">
                            <button
                              type="button"
                              class="btn btn-primaryClose"
                              (click)="allDeviceClose()"
                            >
                              Tümünü Kapat
                            </button>
                          </th>
                          <th scope="col" class="text-right">
                            <button
                              type="button"
                              class="btn btn-primaryDelete"
                              (click)="allDeviceDelete()"
                            >
                              Tümünü Sil
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of userLoginDevice">
                          <td>{{ item.header }}</td>
                          <td
                            *ngIf="item.ip != null; then ip; else emptyip"
                          ></td>
                          <ng-template #ip>
                            <td>{{ item.ip }}</td>
                          </ng-template>
                          <ng-template #emptyip>
                            <td>-</td>
                          </ng-template>

                          <td>{{ item.created_at | localizedDate }}</td>

                          <td class="text-right">
                            <button
                              type="button"
                              class="btn btn-primaryCloseSesion"
                              (click)="selectedDeviceClose(item.cihaz_id)"
                              [disabled]="[0, 2].includes(item.oturum_status)"
                            >
                              Oturumu Kapat
                            </button>
                          </td>
                          <td class="text-right">
                            <button
                              type="button"
                              class="btn btn-primaryDeleteSesion"
                              (click)="selectedDeviceDelete(item.cihaz_id)"
                            >
                              Oturumu Sil
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="form-group mt-40 text-center">
                <button
                  type="button"
                  class="editButton"
                  [disabled]="isDisabledBackButton"
                  (click)="backHome()"
                >
                  <span [ngClass]="{ 'fadedBackButton-text': isDisabledBackButton }">
                    {{ isDisabledBackButton ? ('Lütfen Bekleyin' | translate) + '...' : ('Geri' | translate) }}
                  </span>
                </button>
              </div>
            </div>
            <br />
          </div>

          <!-- Sistem Kullanım Bilgileri -->
          <div
            id="systemManagement"
            class="tab-pane fade"
            style="min-height: 450px"
          >
            <div class="systemManagement">
              <div class="form-group">
                <div class="row text-center">
                  <h4>Sistem Kullanım Bilgileri</h4>
                  <div class="line mt-10"></div>
                </div>
              </div>

              <div class="form-group text-center mt-20">
                <p>
                  Hesabınıza ne zaman giriş yaptığınızı ve işlem geçmişinizi
                  detaylarıyla görebilirsiniz.
                </p>
              </div>

              <div class="row mt-60">
                <div class="content-bar">
                  <div class="table-responsive sitescroll" data-simplebar>
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Cihaz</th>
                          <th scope="col">IP Adresi</th>
                          <th scope="col">Tarih & Saat</th>
                          <th scope="col">İşlem</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let users of userLoginBrowser
                              | slice : (page - 1) * pageSize : page * pageSize
                          "
                        >
                          <td>{{ users.header }}</td>
                          <td
                            *ngIf="users.ip != null; then ip; else emptyip"
                          ></td>
                          <ng-template #ip>
                            <td>{{ users.ip }}</td>
                          </ng-template>
                          <ng-template #emptyip>
                            <td>-</td>
                          </ng-template>
                          <td>
                            {{ users.created_at | localizedDate }}
                          </td>
                          <td
                            *ngIf="
                              users.durum !== undefined && users.durum !== '';
                              else emptystatus;
                              then: active;
                              else: emptystatus
                            "
                          ></td>
                          <ng-template #active>
                            <td>{{ users.durum }}</td>
                          </ng-template>
                          <ng-template #emptystatus>
                            <td>-</td>
                          </ng-template>
                        </tr>
                      </tbody>
                    </table>
                    <!-- pagination-table -->
                    <table
                      class="pagination-table"
                      *ngIf="userLoginBrowser?.length > 10"
                    >
                      <tr>
                        <td class="text-center">
                          <ngb-pagination
                            [collectionSize]="collectionSize"
                            [(page)]="page"
                            [pageSize]="pageSize"
                            [directionLinks]="false"
                            (pageChange)="refreshHistorys()"
                            [boundaryLinks]="true"
                            [rotate]="true"
                          >
                          </ngb-pagination>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <select
                            title="Sayfa Listele"
                            class="form-control"
                            [(ngModel)]="pageSize"
                            (ngModelChange)="refreshHistorys()"
                          >
                            <option [ngValue]="10">10</option>
                            <option [ngValue]="20">20</option>
                            <option [ngValue]="30">30</option>
                            <option [ngValue]="userLoginBrowser?.length">
                              Tüm
                            </option>
                          </select>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

              <div class="form-group mt-20 text-center">
                <button
                  type="button"
                  class="editButton"
                  [disabled]="isDisabledBackButton"
                  (click)="backHome()"
                >
                  <span [ngClass]="{ 'fadedBackButton-text': isDisabledBackButton }">
                    {{ isDisabledBackButton ? ('Lütfen Bekleyin' | translate) + '...' : ('Geri' | translate) }}
                  </span>
                </button>
              </div>
            </div>
          </div>

          <!-- timeout_Period -->
          <div id="timeout_Period" class="tab-pane fade">
            <form
              class="changePass"
              autocomplete="off"
              #timePeriodForm="ngForm"
              id="timePeriodForm"
            >
              <div class="form-group">
                <div class="row text-center">
                  <h4>Zaman Aşımı Yönetimi</h4>
                  <div class="line mt-10"></div>
                </div>
              </div>

              <div class="form-group">
                <div class="row text-center">
                  <img
                    src="assets/images/144.svg"
                    alt=""
                    style="width: 7%; height: 7%"
                  />
                </div>
              </div>

              <div class="form-group text-center mt-20">
                <p>
                  Zaman aşımı sürenizi ihtiyaçlarınıza göre yönetebilirsiniz.
                </p>
                <p>
                  Hesabınıza giriş yaptıktan sonra sizin belirlediğiniz süre
                  içinde sistem üzerinde hareket algılanmazsa
                </p>
                <p>
                  güvenliğiniz için otomatik olarak hesabınızdan çıkış yapılır.
                </p>
              </div>

              <div class="form-group mt-40">
                <div class="center col-sm-4">
                  <div class="form-group">
                    <select
                      aria-label="timePeriod"
                      class="form-control selectpicker"
                      name="timePeriod"
                      #timePeriod="ngModel"
                      [(ngModel)]="formTimePeriod.timePeriod"
                      required
                      [value]="userdata?.expired_time"
                    >
                      <option [ngValue]="null" disabled selected>
                        Zaman Aşımı Süresi Seçiniz
                      </option>
                      <option id="1" value="5">5 Dakika</option>
                      <option id="2" value="15">15 Dakika</option>
                      <option id="3" value="30">30 Dakika</option>
                      <option id="4" value="120">2 Saat</option>
                      <option id="5" value="240">4 Saat</option>
                    </select>
                  </div>
                </div>
              </div>

              <br />
              <!-- buttons -->
              <div class="center col-sm-4">
                <input
                  type="submit"
                  name=""
                  value="Zaman Aşımı Süresini Kaydet"
                  *ngIf="!isDisabledTimePeriod"
                  [disabled]="!timePeriodForm.form.valid"
                  class="btn orange-btn-lgn"
                  (click)="onSubmitTimePeriod()"
                />
                <input
                  type="submit"
                  name=""
                  value="{{ 'Pleasewait' | translate }}..."
                  class="btn orange-btn-lgn"
                  disabled
                  *ngIf="isDisabledTimePeriod"
                />
              </div>
              <div class="form-group mt-40 text-center">
                <button
                  type="button"
                  class="editButton"
                  [disabled]="isDisabledBackButton"
                  (click)="backHome()"
                >
                  <span [ngClass]="{ 'fadedBackButton-text': isDisabledBackButton }">
                    {{ isDisabledBackButton ? ('Lütfen Bekleyin' | translate) + '...' : ('Geri' | translate) }}
                  </span>
                </button>
              </div>
            </form>

            <br />
            <br />
          </div>
          <br />
        </div>
      </div>
    </section>
  </div>
</article>
<app-footer></app-footer>

import { Component, OnInit } from "@angular/core";
import { ProfileService } from "src/app/Services/profile.service";
import { TokenService } from "../../Services/token.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { IntermediateService } from "../../Services/intermediate.service";
@Component({
  selector: "app-two-factors-login",
  templateUrl: "./two-factors-login.component.html",
  styleUrls: ["./two-factors-login.component.css"],
})
export class TwoFactorsLoginComponent implements OnInit {
  twofauserdata: any = [];
  userdata: any = [];
  twofaDetail: any = [];
  public errorText = [];
  public successText = [];
  public allActionDisabled = [];
  public tw_idRT: any;
  public twofaID: number;
  public valCode: number;
  public newvalidationCode: any;
  public Twodfa = {
    "0": "block",
    "1": "none",
    "2": "none",
    "3": "none",
    "4": "none",
    "5": "none",
    "6": "none",
  };

  constructor(
    private profile: ProfileService,
    private Token: TokenService,
    private ngxService: NgxUiLoaderService,
    private myservice: IntermediateService
  ) {}

  ngOnInit(): void {
    this.profile.user_twofa_list_Login().subscribe(
      (data) => this.handleuser_twofa_list_Details(data),
      (error) => this.handleError(error)
    );

    this.profile.getprofileDetailsInfo().subscribe(
      (data) => this.handleProfileDetailsInfo(data),
      (error) => this.handleError(error)
    );
  }

  handleuser_twofa_list_Details(data) {
    if (data.success) {
      this.twofauserdata = data.result;
      this.twofaDetail = data.detail;
      this.allActionRT(this.twofauserdata[0].twofa_id, "");
    }
  }
  handleProfileDetailsInfo(data) {
    if (data.success) {
      this.userdata = data.result;
    }
  }

  //#region dogrula

  inputValue: string;
  onInputChange(event: any, twofa_id: any, validationCode: any, a) {
    this.inputValue = event.target.value;
    if (this.inputValue.length === 6) {
      setTimeout(() => {
        this.allAction(twofa_id, validationCode, a);
      }, 450); // saniye gecikme
    }
  }

  allAction(twofa_id: any, validationCode: any, a) {
    this.valCode = validationCode.value;
    this.twofaID = twofa_id;
    this.errorText = [];
    //this.successText = [];
    if (this.twofaID) {
      let formData;
      let apiAddressValidation;

      if (this.twofaID === 1) {
        formData = new FormData();
        formData.append("verify_code", this.valCode);
        formData.append("type_name", "twofa_sms");
        apiAddressValidation = "verify_phone";
      } else if (this.twofaID === 2) {
        formData = new FormData();
        formData.append("verify_code", this.valCode);
        formData.append("type_name", "twofa_email");
        apiAddressValidation = "verify_email";
      } else if (this.twofaID === 3) {
        formData = new FormData();
        formData.append("google_code", this.valCode);
        formData.append("type_name", "twofa_google");
        apiAddressValidation = "verifygoogle";
      }
      return this.profile.verifyCode(formData, apiAddressValidation).subscribe(
        (data) => this.handleResponse(data, this.twofaID, a),
        (error) => this.handleError(error)
      );
    } else {
      alert("Kod Doğrulanırken Hata Oluştu");
    }
  }

  handleResponse(res, twofaID, a) {
    if (res.success) {
      let adet = this.twofauserdata.length - 1;
      let a1 = a;
      let a2 = a + 1;
      if (adet == a1) {
        //tüm doğrulamalar yapıldı
        this.profile.refreshtfa().subscribe(
          (data) => this.handleRefeshtoken(data),
          (error) => this.handleError(error)
        );
      } else {
        setTimeout(async () => {
          const verification_skip_email = await localStorage.getItem(
            "verification_skip_email"
          );

          this.ngxService.start();
          this.Twodfa[a1] = "none";
          this.Twodfa[a2] = "block";

          // 3 google login, 6 google withdraw, 9 google secure
          // 2 email login, 5 email withdraw, 8 email secure
          let z = this.twofauserdata[a2].twofa_id;

          //if (z === 3 || z === 6 || z === 9) {
          if (z !== 3 && z !== 6 && z !== 9) {
            //google olmadığı için gönder işlemini çağrıyoruz.

            if (z === 2 || z === 5 || z === 8) {
              //email olduğu için buraya geldik

              if (verification_skip_email !== "true") {
                this.allActionRT(z, "");
              } else {
                this.Twodfa[1] = "none";
                this.Twodfa[2] = "block";

                if (adet > 0) {
                  //tüm doğrulamalar yapıldı

                  localStorage.removeItem("verification_skip_email");

                  setTimeout(async () => {
                    try {
                      const data = await this.profile.refreshtfa().toPromise();
                      this.handleRefeshtoken(data);
                    } catch (error) {
                      this.handleError(error);
                    }
                  }, 800);
                }
              }
            }
          }
          this.ngxService.stop();
        }, 0);

        this.newvalidationCode = "";
        //this.successText[twofaID] = res.message;
        this.allActionDisabled[twofaID] = true;
      }
    } else {
      this.errorText[twofaID] = this.formatMessage(res.message);
      this.successText = [];
      this.newvalidationCode = "";
    }
  }

  async handleRefeshtoken(token) {
    this.Token.handle(token.result.access_token);
    await this.profile.getprofileDetailsInfo().subscribe(
      (dataGetprofile) => this.handleProfileDetails11(dataGetprofile),
      (error) => this.handleError(error)
    );
  }

  handleProfileDetails11(data) {
    let goURL = "/borsa/BTC/TRY";
    const session = data.result.session;
    const u = data.result;

    if (session.is_2faauth === 1) {
      setTimeout(async () => {
        if (u.is_suspend === 1) {
          this.apiLogout();
          goURL = "/giris";
        } else if (u.all_files_uploaded === 0 || u.survey_been_answered === 0) {
          goURL = "/dosya-yukle";
        } else if (u.are_there_unconfirmed_files === 1 || u.is_customer === 0) {
          goURL = "/durum";
        }
        //this.router.navigate([goURL]);
        document.location.href = goURL;
      }, 10);
    }
  }

  //#endregion dogrula

  //#region  tekrar gonder
  allActionRT(allActionRTid: any, resentCode: string) {
    this.tw_idRT = allActionRTid;
    this.errorText = [];
    this.successText = [];
    //this.successText = [];
    let formData;
    let apiAddresSendAgain;
    let userToken = this.Token.get();

    if (this.tw_idRT === 1) {
      formData = new FormData();
      formData.append("token", userToken);
      apiAddresSendAgain = "sms_send";
    } else if (this.tw_idRT === 2) {
      formData = new FormData();
      formData.append("token", userToken);
      formData.append("type_name", "twofa_email");
      apiAddresSendAgain = "mail_send";
    } else {
      alert("Tekrar Gönderme İşlem Yapılırken Hata Oluştu");
    }

    return this.profile.codeAgainRT(formData, apiAddresSendAgain).subscribe(
      (data) => this.handleResponseAgain(data, this.tw_idRT, resentCode),
      (error) => this.handleError(error)
    );
  }

  handleResponseAgain(res, tw_idRT, resentCode) {
    if (res.success && resentCode !== "") {
      this.successText[tw_idRT] = res.message;
      this.newvalidationCode = "";
    } else if (res.error) {
      this.errorText[tw_idRT] = this.formatMessage(res.message);
    }
  }

  //#endregion  tekrar gonder

  formatMessage(message: string): string {
    // Noktadan sonra yeni satır ekleyerek düzenleme yapar
    const formattedMessage = message.replace(/\. /g, ".\n");
    return formattedMessage;
  }

  apiLogout() {
    this.myservice.userlogout().subscribe();
  }

  handleError(error) {
    this.errorText = error.message;
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  private baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) {}

  homelivePrice() {
    return this.http.get(`${this.baseUrl}/livedatapairs`);
  }

  getsubscribe(data) {
    return this.http.post(`${this.baseUrl}/getsubscribe`, data);
  }

   msg(data) {
     return this.http.post(`${this.baseUrl}/msg`, data);
   }
}

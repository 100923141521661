import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { TokenService } from "./token.service";
import { ProfileService } from "src/app/Services/profile.service";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PageRedirectionService implements CanActivate {
  constructor(
    private Token: TokenService,
    private router: Router,
    private profile: ProfileService
  ) {}

  canActivate(
    route: import("@angular/router").ActivatedRouteSnapshot,
    state: import("@angular/router").RouterStateSnapshot
  ):
    | boolean
    | import("@angular/router").UrlTree
    | Observable<boolean | import("@angular/router").UrlTree>
    | Promise<boolean | import("@angular/router").UrlTree> {
    
    if (!this.Token.loggedIn()) {
      //this.router.navigateByUrl("/giris");  
      document.location.href ='/giris';
      return false;
    } else {
      this.Token.refreshToken();
      return this.profile.getprofileDetailsInfo().pipe(
        map((dataGetprofile: any) => {
          const result: any = dataGetprofile?.result;
          const isPhoneVerified = result?.is_phone_verified;
          const isEmailVerified = result?.email_verify;
          const all_Files_Uploaded = result?.all_files_uploaded;
          const survey_Been_Answered = result?.survey_been_answered;
          const is_Customer = result?.is_customer;
          const is_2Faauth = result?.session.is_2faauth;
          const is_Device_Verified = result?.session.is_devices_defined;
          const customer_Form_Upload = result?.customerFormUpload; 

          if (
            is_Device_Verified === 0 &&
            isPhoneVerified === 1 &&
            isEmailVerified === 1
          ) {
            if (state.url !== "/cihaz-dogrula") {
              this.router.navigate(["cihaz-dogrula"]).then(() => {
                this.lockNavigation();
              });
              return false;
            }
          } else if (isPhoneVerified === 0) {
            
            if (state.url !== "/sms-dogrula") {
              this.router.navigate(["sms-dogrula"]).then(() => {
                this.lockNavigation();
              });
              return false;
            }
          } else if (isPhoneVerified === 1 && isEmailVerified === 0) {
            if (state.url !== "/email-dogrula") {
              // İlk önce kullanıcıyı yönlendirme işlemi yapılır
              this.router.navigate(["email-dogrula"]).then(() => {
                // Yönlendirme işlemi tamamlandığında geri tuşunu kilitleriz
                this.lockNavigation();
              });
              return false;
            }
          } else if (
            isPhoneVerified === 1 &&
            isEmailVerified === 1 &&
            all_Files_Uploaded === 0 &&
            survey_Been_Answered === 0 &&
            is_2Faauth === 1 
          ) {
            if (state.url !== "/dosya-yukle") {
              this.router.navigate(["dosya-yukle"]).then(() => {
                this.lockNavigation();
              });
              return false;
            }
          } else if (
            isPhoneVerified === 1 &&
            isEmailVerified === 1 &&
            is_2Faauth === 0 &&
            is_Customer === 1
          ) {
            if (state.url !== "/ikili-dogrulama") {
              this.router.navigate(["ikili-dogrulama"]).then(() => {
                this.lockNavigation();
              });
              return false;
            }
          } else if (is_2Faauth === 1 && is_Customer === 0 && customer_Form_Upload ===1) {
            if (state.url !== "/durum") {
              this.router.navigate(["durum"]).then(() => {
                this.lockNavigation();
              });
              return false;
            }
          }

          switch (state.url) {
            case "/sms-dogrula":
            case "/ikili-dogrulama":
            case "/email-dogrula":
            case "/dosya-yukle":
            case "/durum":
            case "/cihaz-dogrula":
              if (
                is_2Faauth === 1 &&
                isPhoneVerified === 1 &&
                isEmailVerified === 1 &&
                survey_Been_Answered === 1 &&
                all_Files_Uploaded === 1 &&
                is_Customer === 1 &&
                is_Device_Verified === 1
              ) {
                return this.router.parseUrl("/borsa/BTC/TRY");
              }
              break;
          }
          this.unlockNavigation();
          return true;
        }),
        catchError((error) => {
          this.handleError(error);
          return of(false);
        })
      );
    }
  }

  private lockNavigation(): void {
    history.pushState(null, '', location.href);
    window.onpopstate = () => {
      history.pushState(null, '', location.href);
    };
  }

  private unlockNavigation(): void {
    window.onpopstate = null;
  }

  handleError(error: any) {
    alert('Sayfa Yönlendirilemedi!');
  }
}

import { Component, OnInit, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { SupportTicketService } from "src/app/Services/support-ticket.service";
import { environment } from "src/environments/environment";
import { Subscription } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-support",
  templateUrl: "./support.component.html",
  styleUrls: ["./support.component.css"],
})
export class SupportComponent implements OnInit, OnDestroy {
  public tickets = null;
  public error = null;
  private queryParamsSubscription: Subscription;
  private ticketListSubscription: Subscription;
  public collectionSize: any;
  config: any;
  p: any = 1;
  limit: any = environment.pagination;
  offset: any = 0;
  queryParams: Params;
  page = 1;
  pageSize = 10;
  isProcessing: boolean = false;

  constructor(
    private http: HttpClient,
    private support: SupportTicketService,
    private route: ActivatedRoute,
    private router: Router,
    private metaService: Meta,
    private titleService: Title
  ) {
    this.getRouteParams();
  }

  ngOnInit() {
    this.metaService.addTags([
      {
        name: "description",
        content: "Profesyonel destek ekibimiz ile her an yanınızdayız.",
      },
      {
        name: "keywords",
        content: "destek",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);

    const pageTitle = "Destek";
    this.titleService.setTitle(pageTitle);
    this.refreshTicked();
  }

  ngOnDestroy() {
    // Unsubscribe from any subscriptions to prevent memory leaks
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }

    if (this.ticketListSubscription) {
      this.ticketListSubscription.unsubscribe();
    }
  }

  getRouteParams() {
    // URL query parameters
    this.queryParamsSubscription = this.route.queryParams.subscribe(
      (params) => {
        this.queryParams = params["page"] ? params["page"] : 1;
        this.p = this.queryParams;
        this.getTicketlist(this.p);
      }
    );
  }

  refreshTicked() {
    if (this.tickets) {
      this.tickets
        .map((tickets, i) => ({ id: i + 1, ...tickets }))
        .slice(
          (this.page - 1) * this.pageSize,
          (this.page - 1) * this.pageSize + this.pageSize
        );
    }
  }

  getTicketlist(page: number = 1) {
    const perPage = environment.pagination;
    const start: any = (page - 1) * perPage;
    const end: any = perPage;
    var myFormData = new FormData();
    myFormData.append("offset", start);
    myFormData.append("limit", end);

    this.ticketListSubscription = this.support
      .getTicketList(myFormData)
      .subscribe(
        (data) => this.handleTicketsDetails(data, page),
        (error) => this.handleError(error)
      );
  }

  handleTicketsDetails(data, page) {
    if (data.success) {
      let result = data?.result;
      this.tickets = result?.list;
      this.collectionSize = this.tickets?.length;
      this.config = {
        currentPage: page,
        itemsPerPage: environment.pagination,
        totalItems: result.count,
      };
    } else {
      this.router.navigateByUrl("/destek");
    }
  }

  NewCreateTicket() {
    this.isProcessing = true;
    // Gerekli işlemler burada yapılabilir, örneğin sunucuya bir istek gönderme
    setTimeout(() => {
      // İşlem tamamlandıktan sonra isProcessing'i false yaparak metni tekrar "Yeni Ticket" yap
      this.router.navigateByUrl("/destek-talebi-olustur");
      this.isProcessing = false;
    }, 500); // Örnek olarak 2 saniye bekleme süresi
  }

  key: string = "created_at";
  reverse: boolean = false;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  handleError(error) {
    this.error = error.message;
  }
}

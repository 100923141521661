import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/Services/auth.service";
import { TokenService } from "../../Services/token.service";
import { IntermediateService } from "../../Services/intermediate.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { GlobalConstants } from "src/common/global-constants";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ProfileService } from "src/app/Services/profile.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  baseUrl = environment.tradeUrl;
  public loggedIn: boolean;
  public username: null;
  public image: null;
  public error = null;
  informationdata: any = [];

  constructor(
    private profile: ProfileService,
    private router: Router,
    private Auth: AuthService,
    private Token: TokenService,
    private myservice: IntermediateService,
    public translate: TranslateService,
    private ngxService: NgxUiLoaderService
  ) {
    translate.addLangs(["English", "Arabic", "Armenian"]);
    translate.setDefaultLang("English");
    if (localStorage.getItem("locale")) {
      const browserLang = localStorage.getItem("locale");
      translate.use(
        browserLang.match(/English|Arabic|Armenian/) ? browserLang : "English"
      );
    } else {
      localStorage.setItem("locale", "English");
      translate.setDefaultLang("English");
    }
  }

  changeLang(language: string) {
    localStorage.setItem("locale", language);
    this.translate.use(language);
  }

  ngOnInit() {
    this.Auth.authStatus.subscribe((value) => (this.loggedIn = value));
    if (this.loggedIn) {
      this.Token.getUserDetails();
      GlobalConstants.token = this.Token;
      this.profile.getprofileDetails().subscribe(
        (data) => this.handleInformationsDetails(data),
        (error) => this.handleError(error)
      );
    }

    // loader
    this.ngxService.start();
    setTimeout(() => {
      this.ngxService.stop();
    }, 500);
  }

  handleInformationsDetails(data) {
    if (data.success) {
      this.informationdata = data.result;
    } else {
      this.informationdata = [];
    }
  }

  // logout(event: MouseEvent) {
  //   event.preventDefault();
  //   this.apiLogout();
  //   this.Token.remove();
  //   this.Auth.changeAuthStatus(false);
  //   this.router.navigateByUrl('/giris');
  // }
  // apiLogout() {
  //   this.myservice.userlogout().subscribe();
  // }
  logout(event: MouseEvent) {
    this.ngxService.start();
    event.preventDefault(); // Bunu setTimeout dışına çıkardık

    this.myservice.userlogout().subscribe({
        next: () => {
            this.Token.remove();
            this.Auth.changeAuthStatus(false);
            document.location.href = "/giris"; // Yönlendirmeyi burada yapıyoruz
            this.ngxService.stop();
        },
        error: () => {
            console.error('Logout işlemi sırasında hata oluştu');
            this.ngxService.stop();
        }
    });
}

  handleError(error) {
    this.error = error.message;
  }

  reloadPage() {
    this.router.navigateByUrl("/hesap-guvenlik");
  }
}

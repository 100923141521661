<!-- cüzdan Pages -->
<app-header></app-header>
<article>
  <section class="innerbannerpage">
    <div class="container">
      <!-- walletHeader -->
      <div class="row">
        <table id="walletHeader">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: -6px;"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  alt=""
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                />
                &nbsp;&nbsp;
                <small> {{ "Wallet" | translate }}</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div style="height: 11px"></div>
      <div class="row">
        <div class="page-title-section t-black"></div>
        <div class="content-bar-order-histry">
          <div class="table-head table-head-wallet">
            <div>{{ "Coin" | translate }}</div>
            <div>{{ "TotalBalance" | translate }}</div>
            <div>{{ "AvailableBalance" | translate }}</div>
            <div>{{ "InTheProcess" | translate }}</div>
            <!-- <div>TX/ID</div> -->
            <div>{{ "Action" | translate }}</div>
          </div>
          <div class="scroll-content" data-simplebar>
            <div class="table-div">
              <div class="div-tr div-tr-wallet" *ngFor="let coin of walletData">
                <div>
                  <span class="text-uppercase"> {{ coin.coinsymbol }}</span>
                </div>

                <div>
                  {{
                    formattingService.formatBasedOnCoinSymbol(
                      coin.coinsymbol,
                      coin.total
                    )
                  }}

                  {{ coin.coinsymbol }}
                </div>

                <div>
                  {{
                    formattingService.formatBasedOnCoinSymbol(
                      coin.coinsymbol,
                      coin.balance
                    )
                  }}

                  {{ coin.coinsymbol }}
                </div>

                <div>
                  {{
                    formattingService.formatBasedOnCoinSymbol(
                      coin.coinsymbol,
                      coin.escrow
                    )
                  }}

                  {{ coin.coinsymbol }}
                </div>

                <div>
                  <div
                    *ngIf="coin.cointype == 'fiat' || 'coin'; then fiatblock"
                  ></div>
                  <ng-template #fiatblock
                    ><a
                      routerLink="/kripto-yatirma/{{ coin.coinsymbol }}"
                      class="btn viewbtn green-btn mr-10"
                      >{{ "Deposit" | translate }}</a
                    ><a
                      routerLink="/kripto-cekme/{{ coin.coinsymbol }}"
                      class="btn viewbtn red-btn"
                      >{{ "Withdraw" | translate }}</a
                    >
                  </ng-template>
                </div>
              </div>

              <div *ngIf="walletData == undefined || walletData.length == 0">
                <div class="order-messages text-center">
                  <br />
                  Cüzdan verileri yükleniyor. Lütfen bekleyiniz.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-53">
        <div class="box-section">
          <table id="walletHeaderHistory">
            <tbody>
              <tr>
                <td class="text-right" style="width: 58%">
                  <small>{{ "TransactionsHistory" | translate }}</small>
                </td>
                <td class="text-right" style="width: 100%"></td>
              </tr>
              <tr>
                <td style="height: 16px" colspan="2">
                  <hr />
                </td>
              </tr>
            </tbody>
          </table>

          <!-- tables -->
          <div class="content-bar">
            <table class="walletHistoryView" id="excel-table">
              <thead>
                <tr>
                  <th>
                    {{ "Coin" | translate }}
                  </th>

                  <th>
                    {{ "Action" | translate }}
                  </th>
                  <th>
                    {{ "Quantity" | translate }}
                  </th>
                  <th>{{ "Date" | translate }}</th>
                  <th>
                    {{ "Status" | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let coin of lstTxs?.slice(0, 8)">
                  <td>
                    <span class="text-uppercase"> {{ coin.coin }} </span>
                  </td>
                  <td *ngIf="coin.type == 'withdraw'">Çekme</td>
                  <td *ngIf="coin.type == 'deposit'">Yatırma</td>
                  <td *ngIf="coin.type == 'selltrade'">Satım</td>
                  <td *ngIf="coin.type == 'buytrade'">Alım</td>
                  <td>
                    {{
                      coin.coin === "TRY" || coin.coin === "USDT"
                        ? formattingService.formatCrypto(coin.debit, 2)
                        : formattingService.formatCrypto(coin.debit)
                    }}
                  </td>

                  <td>{{ coin.created_at | localizedDate }}</td>
                  <td>{{ coin.remark }}</td>
                </tr>
              </tbody>

              <tbody
                *ngIf="lstTxs == undefined || lstTxs.length == 0"
                class="record"
              >
                <tr>
                  <td colspan="5" class="text-center">
                    <br />
                    Cüzdan işlem geçmişi bulunamadı.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

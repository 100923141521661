interface Scripts {
  name: string;
  src: string;
}
export const ContactScript: Scripts[] = [
  {
    name: 'jquery-3.2.1.min',
    src: '/assets/contact/vendor/jquery/jquery-3.2.1.min.js',
  },
  {
    name: 'animsition.min.js',
    src: '/assets/contact/vendor/animsition/js/animsition.min.js',
  },
  { name: 'popper.js', src: '/assets/contact/vendor/bootstrap/js/popper.js' },
  {
    name: 'bootstrap.min.js',
    src: '/assets/contact/vendor/bootstrap/js/bootstrap.min.js',
  },
  {
    name: 'select2.min.js',
    src: '/assets/contact/vendor/select2/select2.min.js',
  },
  {
    name: 'moment.min.js',
    src: '/assets/contact/vendor/daterangepicker/moment.min.js',
  },
  {
    name: 'daterangepicker.js',
    src: '/assets/contact/vendor/daterangepicker/daterangepicker.js',
  },
  {
    name: 'countdowntime.js',
    src: '/assets/contact/vendor/countdowntime/countdowntime.js',
  },
  { name: 'daterangepicker.js', src: '/assets/contact/js/main.js' },
];

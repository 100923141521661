import {
  Component,
  OnInit,
  Injectable,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { ProfileService } from "src/app/Services/profile.service";
import { TokenService } from "../../../Services/token.service";
import { Router } from "@angular/router";
import { SharedService } from "src/app/Services/shared.service";
import { WalletService } from "src/app/Services/wallet.service";
declare var $;

@Injectable({
  providedIn: "root",
})
@Component({
  selector: "app-twofa-kripto",
  templateUrl: "./twofa-kripto-seq.component.html",
  styleUrls: ["./twofa-kripto-seq.component.scss"],
})
export class TwofaKriptoComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  @Input() tfaFormData;
  modal: TwofaKriptoComponent;
  twofauserdataDetail: any = [];
  twofaDetail: any = [];
  public TwodfaNew = [];
  public errorText = "";
  public successText = [];
  public allActionDisabled = [];
  public tw_idRT: any;
  public twofaID: number;
  public valCode: number;
  public newvalidationCode: any;

  //const actionKey = this.withdrawFormDataObj.value.action_key;
  public TWODFA = {
    "0": "block",
    "1": "none",
    "2": "none",
    "3": "none",
    "4": "none",
    "5": "none",
    "6": "none",
    "7": "none",
    "8": "none",
    "9": "none",
  };
  comp1Val: any;
  withdrawFormDataObj: any;
  tfaFormDataObj: any;
  withdrawSuccess: boolean = false;

  constructor(
    private profile: ProfileService,
    private Token: TokenService,
    private router: Router,
    private sharedService: SharedService,
    private wallet: WalletService
  ) {}

  ngOnInit(): void {
    this.profile.getprofileDetails().subscribe(
      (data) => this.handleProfileDetails(data),
      (error) => this.handleError(error)
    );
  }

  handleProfileDetails(data) {
    let twofauserdataNew = [];
    this.withdrawFormDataObj = this.sharedService._withdrawValue;
    this.tfaFormDataObj = this.tfaFormData;
    if (data.success) {
      this.openModal();
      this.twofauserdataDetail = data.result;
      this.tfaFormData.forEach(function (data) {
        if (data.is_actived) {
          twofauserdataNew.push(data);
        }
      });
    }

    this.allActionRT(twofauserdataNew[0].twofa_id, "");

    this.comp1Val = twofauserdataNew;
  }

  // #region dogrula
  allAction(valCode, twofa_name, i) {
    var _valCode = valCode;
    let data = this.withdrawFormDataObj._value;
    data["verify_code"] = _valCode;
    data["action_type"] = twofa_name;
    data["type_name"] = "twofa_" + twofa_name;

    if (this.withdrawFormDataObj._value.pageroot === "withdrawsendfiat") {
      return this.wallet.sendwithdrawfiat(data).subscribe(
        (data) => this.handleResponsecoin(data, 0, i),
        (error) => this.handleError(error)
      );
    } else if (this.withdrawFormDataObj._value.pageroot === "withdrawrequest") {
      return this.wallet.sendwithdrawrequest(data).subscribe(
        (data) => this.handleResponsecoin(data, 0, i),
        (error) => this.handleError(error)
      );
    } else if (
      this.withdrawFormDataObj._value.pageroot === "change_twofa_auth_multi"
    ) {
      return this.profile.twofa_auth_multi(data).subscribe(
        (data) => this.handleResponsecoin(data, 0, i),
        (error) => this.handleError(error)
      );
    } else if (this.withdrawFormDataObj._value.pageroot === "changepassword") {
      return this.profile.updatePassword(data).subscribe(
        (data) => this.handleResponsecoin(data, 0, i),
        (error) => this.handleError(error)
      );
    } else if (
      this.withdrawFormDataObj._value.pageroot === "whitelistcreateaddress"
    ) {
      return this.profile.createWhiteAdd(data).subscribe(
        (data) => this.handleResponsecoin(data, 0, i),
        (error) => this.handleError(error)
      );
    } else if (this.withdrawFormDataObj._value.pageroot === "newfishingcode") {
      return this.profile.fishing(data).subscribe(
        (data) => this.handleResponsecoin(data, 0, i),
        (error) => this.handleError(error)
      );
    } else if (
      this.withdrawFormDataObj._value.pageroot === "changedfishingcode"
    ) {
      return this.profile.changeFishing(data).subscribe(
        (data) => this.handleResponsecoin(data, 0, i),
        (error) => this.handleError(error)
      );
    } else if (
      this.withdrawFormDataObj._value.pageroot === "whitelistupdateaddress"
    ) {
      return this.profile.updateWhiteAddress(data).subscribe(
        (data) => this.handleResponsecoin(data, 0, i),
        (error) => this.handleError(error)
      );
    } else if (
      this.withdrawFormDataObj._value.pageroot === "whitelistdeleteaddress"
    ) {
      return this.profile.deleteWhiteAddress(data.id).subscribe(
        (data) => this.handleResponsecoin(data, 0, i),
        (error) => this.handleError(error)
      );
    } else if (
      this.withdrawFormDataObj._value.pageroot === "changedtimeexpired"
    ) {
      return this.profile.changedTime(data).subscribe(
        (data) => this.handleResponsecoin(data, 0, i),
        (error) => this.handleError(error)
      );
    }
  }

  handleResponsecoin(res, twofaID, a) {
    if (res.success) {
      this.errorText = "";
      if (res.is_tfa === true) {
        this.withdrawSuccess = true;
        this.successText = res.message;
      } else {
        this.TWODFA[a] = "none";
        $("#exampleModal").modal("hide");

        setTimeout(() => {
          let p = a + 1;
          $("#exampleModal").modal("show");
          this.TWODFA[p] = "block";

          let z = this.comp1Val[p].twofa_id;
          if (!(z === 3 || z === 6 || z === 9)) {
            this.allActionRT(z, "");
          }
        }, 550);

        this.newvalidationCode = "";
        this.successText[twofaID] = res.message;
        this.allActionDisabled[twofaID] = true;
      }
    } else {
      this.errorText = res.message;
      this.successText = [];
      
    }
  }

  // #endregion dogrula

  // #region  tekrar gonder
  allActionRT(allActionRTid: any, resentCode: string) {
    this.tw_idRT = allActionRTid;
    this.errorText = "";
    let allActionRTFormData: any = this.sharedService._withdrawValue.value;
    let formData;
    let apiAddresSendAgain;
    let userToken = this.Token.get();
    let idrt = this.tw_idRT;
    if (idrt === 1 || idrt === 4 || idrt === 7) {
      formData = new FormData();
      formData.append("token", userToken);
      formData.append("token", userToken);
      apiAddresSendAgain = "sms_send";
    } else if (idrt === 2 || idrt === 5 || idrt === 8) {
      formData = new FormData();
      formData.append("token", userToken);
      formData.append("type_name", "twofa_email");
      formData.append("action_mail", allActionRTFormData.action_mail);
      apiAddresSendAgain = "mail_send";
      if (allActionRTFormData.pageroot === "withdrawrequest") {
        formData.append("coin", allActionRTFormData.coin);
        formData.append("toaddress", allActionRTFormData.fromaddress);
        formData.append("amount", allActionRTFormData.amount);
      }
    } else {
      this.errorText = "Tekrar Gönderme İşlem Yapılırken Hata Oluştu";
    }

    return this.profile.codeAgainRT(formData, apiAddresSendAgain).subscribe(
      (data) => this.handleResponseAgain(data, this.tw_idRT, resentCode),
      (error) => this.handleError(error)
    );
  }

  handleResponseAgain(res, tw_idRT, resentCode) {
    if (res.success && resentCode !== "") {
      this.errorText = "";
      this.successText[tw_idRT] = res.message;
    } else if (res.error) {
      this.errorText = res.message;
      this.successText = [];
    }
  }

  // #endregion  tekrar gonder

  // #region  open modal

  openModal() {
    $("#exampleModal").modal({
      backdrop: "static",
      keyboard: false,
      show: true,
    });

    $("#exampleModal").on("shown.bs.modal", function () {
      $("#google_code").focus();
    });
  }
  // #endregion open modal

  // #region CloseModal
  onCloseClick() {

    if (
      this.withdrawFormDataObj._value.pageroot === "withdrawsendfiat" ||
      this.withdrawFormDataObj._value.pageroot === "withdrawrequest"
    ) {
      this.close.emit();
      $("#exampleModal").modal("hide");
      this.router.navigateByUrl("/cuzdan");
    } else if (
      this.withdrawFormDataObj._value.pageroot === "changedfishingcode" ||
      this.withdrawFormDataObj._value.pageroot === "changedtimeexpired"
    ) {
      this.close.emit();
      $("#exampleModal").modal("hide");
    } else {
      this.close.emit();
      $("#exampleModal").modal("hide");
      location.reload();
    }
  }

  // x
  async closeModal() {
    const formdata = new FormData();
    formdata.append("action_key", this.withdrawFormDataObj.value.action_key);

    try {
      // Await the response from canceleDaction
      await this.profile.canceleDaction(formdata).toPromise();
    } catch (error) {
      // Handle the error if the request fails
      this.handleError(error);
      return; // Exit the function if an error occurs
    }

    // Proceed with the rest of the logic if no error occurred
    if (
      this.withdrawFormDataObj._value.pageroot === "change_twofa_auth_multi" ||
      this.withdrawFormDataObj._value.pageroot === "changedfishingcode"
    ) {
      this.close.emit();
      $("#exampleModal").modal("hide");
    } else if (
      this.withdrawFormDataObj._value.pageroot === "changepassword" ||
      this.withdrawFormDataObj._value.pageroot === "whitelistupdateaddress"
    ) {
      this.close.emit();
      $("#exampleModal").modal("hide");
      location.reload();
    } else {
      this.close.emit();
      $("#exampleModal").modal("hide");
    }
  }

  // #endregion CloseModal

  handleError(error) {
    this.errorText = error.error.message;
  }
}

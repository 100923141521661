import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { AppComponent } from "src/app/app.component";
import { FormattingService } from "src/app/Services/numberFormat.services";
 
import { Meta, Title } from "@angular/platform-browser";


@Component({
  selector: "app-wallets",
  templateUrl: "./wallets.component.html",
  styleUrls: ["./wallets.component.css"],
})
export class WalletsComponent implements OnInit {
  public error = null;
  public coins = null;
  public success = null;
  public lstTxs = [];
  public walletData: any = null;
  private subscription: Subscription;

  constructor(
    private appComponent: AppComponent, public formattingService: FormattingService, private metaService: Meta, private titleService: Title 
  ) {}

  ngOnInit(): void {
    // Meta etiketlerini ayarla
    this.metaService.addTags([
      {
        name: "description",
        content:
          "Üstün teknoloji ve altyapımız ile kripto paralarınız güvende",
      },
      {
        name: "keywords",
        content: "Bitcoin Cüzdanı, cüzdan,Bitistanbul, kripto, sigorta, Bitcoin, ETH,USDT",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);
    const pageTitle = 'Cüzdan';
    this.titleService.setTitle(pageTitle);
    this.subscribeToWalletData();
  }

  subscribeToWalletData(): void {
    // AppComponent'teki veriyi dinle
    this.subscription = this.appComponent.getWalletData().subscribe((data) => {
      this.walletData = data?.wallets; // Gelen veriyi walletData'ya ata
      this.lstTxs = data?.history;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  handleError(error) {
    this.error = error.message;
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
@Component({
  selector: 'app-alert-modal-success',
  templateUrl: './alert-modal-success.component.html',
  styleUrls: ['./alert-modal-success.component.scss'],
})
export class AlertModalSuccessComponent {
  @Input() success;
  @Input() WhiteListsForm;
  @Input() delete;
  @Output() close = new EventEmitter<void>();

  public WhiteListsFormData: any;
  public succesData: any;

  constructor(private router: Router, private ngxService: NgxUiLoaderService) {}

  ngOnInit(): void {
    this.WhiteListsFormData = this.WhiteListsForm;
    this.succesData = this.success;
  }

  onCloseClick() {
    if (
      this.WhiteListsFormData?.pair === 'BTC' ||
      this.WhiteListsFormData?.pair === 'ETH' ||
      this.WhiteListsFormData?.pair === 'USDT' ||
      this.WhiteListsFormData?.pair !== undefined
    ) {
      location.reload();
      this.close.emit();
    }



    if (this.succesData?.includes('Parola sıfırlama linki')) {
      this.router.navigate(['/giris']);
    }



    if (this.succesData?.includes('Destek talebiniz oluşturulmuştur')) {
      this.router.navigate(['/destek']);
    }

    if (this.succesData?.includes('Google 2FA doğrulama kodu başarılı')) {
      this.close.emit();

      window.location.reload();
    } else {
      this.close.emit();
    }
  }
}

<!-- Güvenlik Pages -->
<app-header></app-header>
<article>
  <section class="innerbannerpage">
    <div class="container mt-15">
      <div class="row">
        <!-- supportHeader -->
        <table id="supportHeader">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: -15px;"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  alt=""
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                />
                &nbsp;&nbsp;
                <small> {{ "Support" | translate }}</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>

        <!-- NewCreateTicket -->
        <div class="mt-100">
          <button
            type="button"
            class="blue-btn-ticked pull-right mt-10 mb-10"
            (click)="NewCreateTicket()"
          >
          {{ isProcessing ? 'Lütfen Bekleyin...' : 'Yeni Destek Talebi Oluştur' }}
          </button>
        </div>

        <div class="tab-content">
          <!-- TicketTable -->
          <div class="mt-201">
            <div class="table-head">
              <div (click)="sort('id')" class="dataHover none" id="none">
                Destek No <i class="fa fa-sort"></i>
              </div>

              <div (click)="sort('subject')" class="dataHover">
                {{ "Subject" | translate }} <i class="fa fa-sort"></i>
              </div>

              <div (click)="sort('created_at')" class="dataHover" id="noneDate">
                {{ "Date" | translate }} <i class="fa fa-sort"></i>
              </div>

              <div class=""></div>
            </div>
            <br />
            <div class="scroll-content" data-simplebar>
              <div class="table-div">
                <div
                  class="div-tr"
                  *ngFor="
                    let ticket of tickets
                      | slice : (page - 1) * pageSize : page * pageSize
                  "
                >
                  <div id="none">{{ ticket.id }}</div>
                  <div>{{ ticket.subject }}</div>
                  <div id="noneDate">
                    {{ ticket.created_at | localizedDate }}
                  </div>
                  <div>
                    <a
                      routerLink="/chat/{{ ticket.ticket_id }}"
                      class="btn viewbtn"
                      >{{ "view" | translate }}</a
                    >
                  </div>
                </div>
                <div *ngIf="tickets == undefined || tickets.length == 0">
                  <div class="order-messages text-center">
                    Henüz destek talebinde bulunmadınız!
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- collectionSize -->
          <table class="pagination-table mt-20" *ngIf="tickets?.length > 10">
            <tr>
              <td class="text-center">
                <ngb-pagination
                  [collectionSize]="collectionSize"
                  [(page)]="page"
                  [pageSize]="pageSize"
                  [directionLinks]="false"
                  (pageChange)="refreshTicked()"
                  [boundaryLinks]="true"
                  [rotate]="true"
                >
                </ngb-pagination>
              </td>
            </tr>
            <tr>
              <td class="text-center">
                <select
                  title="Sayfa Listele"
                  class="form-control"
                  [(ngModel)]="pageSize"
                  (ngModelChange)="refreshTicked()"
                >
                  <option [ngValue]="10">10</option>
                  <option [ngValue]="20">20</option>
                  <option [ngValue]="30">30</option>
                  <option [ngValue]="tickets?.length">Tüm</option>
                </select>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

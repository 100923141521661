import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.test';
import { AuthService } from 'src/app/Services/auth.service';
import { TokenService } from '../../Services/token.service';
import { GlobalConstants } from 'src/common/global-constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  date = new Date();
  public loggedIn: boolean;
  public username: null;
  public error = null;
  public success = null;
  public data = null;
  siteUrl = environment.siteUrl;
  currentApplicationVersion = environment.appVersion;
  selectedLanguage: string; // Varsayılan dil

  public form = {
    search: null,
  };

  constructor(private Auth: AuthService, private Token: TokenService, public translate: TranslateService) {
    // Desteklenen diller
    translate.addLangs(["en", "tr"]); // Desteklenen diller arasında İngilizce ve Türkçe var

    // Varsayılan dil ayarını al
    const savedLanguage = localStorage.getItem("locale") || "tr"; // localStorage'dan dil ayarını al ya da Türkçe kullan
    this.selectedLanguage = savedLanguage; // Seçilen dili ayarla
    this.translate.setDefaultLang("tr"); // Varsayılan dil Türkçe
    this.translate.use(this.selectedLanguage); // Uygulama açıldığında kaydedilmiş dil ile ayarlanır
  }

  ngOnInit() {
    this.Auth.authStatus.subscribe((value) => (this.loggedIn = value));
    if (this.loggedIn) {
      const useDetails = this.Token.getUserDetails();
      GlobalConstants.token = this.Token;
      this.username = useDetails.name;
    }
  }

  // Dil değiştirme fonksiyonu
  changeLang(language: string) {
    localStorage.setItem("locale", language); // Seçilen dilin kaydedilmesi
    this.translate.use(language); // Seçilen dile geç
    this.selectedLanguage = language; // Seçilen dili güncelle
  }
}

import { Injectable } from "@angular/core";

declare let alertify: any;
@Injectable({
  providedIn: "root",
})
export class AlertifyService {
  constructor() {
    alertify.set('notifier', 'delay', 6);
  }

  success(message: string) {
    alertify?.success(message);
    this.setPosition();
  }

  error(message: string) {
    alertify?.error(message);
    this.setPosition();
  }

  warning(message: string) {
    alertify?.warning(message);
    this.setPosition();
  }

  setPosition() {
    alertify?.set("notifier", "position", "top-right");

    //     var delay = alertify.get('notifier','delay');
    //  alertify.set('notifier','delay', 10);
    //  alertify.success('Current delay : ' + alertify.get('notifier','delay') + ' seconds');
    //  alertify.set('notifier','delay', delay);
  }
}

import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: 'app-user-notification',
  templateUrl: './user-notification.component.html',
  styleUrls: ['./user-notification.component.css']
})
export class UserNotificationComponent implements OnInit {

  constructor(private metaService: Meta, private titleService: Title) {}

  ngOnInit() {
     // Meta etiketlerini ayarla
     this.metaService.addTags([
      {
        name: "description",
        content:
          "Hizmetlerimizle ilgili bilgileri ve şartları inceleyin.",
      },
      {
        name: "keywords",
        content: "Kullanıcı Bilgilendirme",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);

    const pageTitle = 'Kullanıcı Bilgilendirme';
    this.titleService.setTitle(pageTitle);
 
  }

}

import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { WalletService } from "src/app/Services/wallet.service";
import { ProfileService } from "src/app/Services/profile.service";
import { AlertifyService } from "src/app/Services/alertify.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { SharedService } from "src/app/Services/shared.service";
import { FormattingService } from "src/app/Services/numberFormat.services";
import { Meta, Title } from "@angular/platform-browser";
declare var $: any;




@Component({
  selector: "app-withdraw",
  templateUrl: "./withdraw.component.html",
  styleUrls: ["./withdraw.component.css"],
})
export class WithdrawComponent implements OnInit {
  public coins = null;
  public lstTxs = null;
  public pendingWithdrawTRY: any;
  public pendingWithdrawKRIPTO: any;
  public lstCoins: any = ["BTC", "ETH", "USDT"];
  public filterAddr: any;
  public filterAddrNetwork: any;
  public selectedAddrcoin;
  public lstAddress = null;
  public selectedCoin = null;
  public error = null;
  public success = null;
  public error_try = null;
  public success_try = null;
  public data = null;
  public getcoin = null;
  public selectcoin = null;
  public commissionpercentage = null;
  public netfee = null;
  public fromaddress = null;
  public bank = null;
  public balance = null;
  public amount = null;
  public admincomission = 0.0;
  public admincomissionTRY = 0.0;
  public withdrawcomission = null;
  public total = 0.0;
  public totalTRY = 0.0;
  public transfer_amount = 0.0;
  public minwithdraw = 1;
  public iserror = false;
  public limitFiat: any;
  public limitList: any;
  public limitSelected: any;
  public banklist: object = [];
  public gweifee = 0.0;
  public BankDetails: object = [];
  public networkType = null;
  public gamtCoin;
  public balanceCoin;
  public dayLimitCoin;
  public monthCoin;
  public gamtTry;
  public balanceTry;
  public dayLimitTry;
  public monthTry;
  public actionCreateCoin;
  display = false;
  favoriteCoin: string;
  isDisabled = false;
  isDisabledTRY = false;
  userdata: any = [];
  whitelist: any = [];
  modalData: any;
  cekmeObjActive: any = [];
  cekmeObjActiveEmail: any = [];
  cekmeObjActiveGoogle: any = [];
  action_key: string = "";
  isLoadingCyypto: boolean = true;
  isLoading: boolean = true;

  constructor(
    private wallet: WalletService,
    private route: ActivatedRoute,
    private router: Router,
    private profile: ProfileService,
    private alertifyService: AlertifyService,
    private sharedService: SharedService,
    public formattingService: FormattingService,
    private metaService: Meta, 
    private titleService: Title
  ) {}

  async ngOnInit() {

    this.metaService.addTags([
      {
        name: "description",
        content:
          "Para çekme işlemlerinizi kolayca 7/24 gerçekleştirin.",
      },
      {
        name: "keywords",
        content: "çekme, Para Çekme",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);

    const pageTitle = 'Para Çekme';
    this.titleService.setTitle(pageTitle);






    try {
      await this.fetchBankData();
      await this.fetchCoinDetails();
      await this.fetchWhiteListData();
      await this.fetchAddressData();
      await this.fetchProfileData();
      await this.fetchLimitData();
      await this.fetcPendingWithdrawalOrders();
      this.handleFavoriteCoin();
    } catch (error) {
      this.alertifyService.error("Çekme ekranı yüklenirken hata oluştu! ");
    }
  }

  private async fetchBankData(): Promise<void> {
    try {
      const bankData = await this.wallet.getuserbanklists().toPromise();
      this.handleGetBank(bankData);
    } catch (error) {
      this.alertifyService.error("Banka verileri yüklenemedi! ");
    }
  }

  handleGetBank(data: any): void {
    if (data.success) {
      this.banklist = data.result;
    }
  }

  public async fetchCoinDetails(): Promise<void> {
    this.getcoin = this.route.snapshot.paramMap.get("coin").toUpperCase();
    this.getcoindetails(this.getcoin);
  }

  getcoindetails(coin: string): void {
    const dataCoin = { coin };
    this.wallet.getwithdrawrequest(dataCoin).subscribe(
      (data) => this.handleCoinDetails(data),
      (error) => this.handleError(error)
    );
  }

  handleCoinDetails(res: any): void {
    if (res.success) {
      const coindetails = res.result;
      this.selectcoin = coindetails.coin;
      this.bank = coindetails.bankdetails;
      this.balance = coindetails.balance;
      this.commissionpercentage = coindetails.commission_percentage;
      this.netfee = coindetails.netfee;
      this.minwithdraw = coindetails.minwithdraw;
      this.withdrawcomission = coindetails.commission;
    } else {
      this.error = res.message;
      this.router.navigateByUrl("/cuzdan");
    }
  }

  private async fetchWhiteListData(): Promise<void> {
    try {
      const whiteData = await this.profile.getwhitelistUser().toPromise();
      this.handlegetprofileWhiteAddres(whiteData);
    } catch (error) {
      console.error("Whitelist Verisi Çekilirken Hata Oluştu! ");
    }
  }

  handlegetprofileWhiteAddres(data: any): void {
    if (data.success) {
      this.whitelist = data.result;
    }
  }

  private async fetchAddressData(): Promise<void> {
    try {
      const data = await this.wallet.getAddress().toPromise();
      this.handleGetAddress(data);
    } catch (error) {
      console.error("Adres Verisi Çekilirken Hata Oluştu!");
    } finally {
      this.isLoading = false; // Veri yüklendikten sonra isLoading'i false yap
    }
  }

  handleGetAddress(data: any): void {
    if (!this.isLoadingCyypto) {
      return;
    }

    if (data.success) {
      this.lstAddress = data.result;
    } else {
      // Veri başarısız ise hata mesajını göster
      this.alertifyService.error(data.message);
      this.isLoadingCyypto = false; // İşlem tamamlandığında isLoadingCyypto'yu false yap
    }
  }

  private async fetchProfileData(): Promise<void> {
    try {
      const profileData = await this.profile.getprofileDetails().toPromise();
      this.handleProfileDetails(profileData);
    } catch (error) {
      console.error("Profil Verisi Çekilirken Hata Oluştu! ");
    }
  }

  handleProfileDetails(data: any): void {
    if (data.success) {
      this.userdata = data.result;
      this.cekmeObjActive = data.result.cekim[0].is_actived;
      this.cekmeObjActiveEmail = data.result.cekim[1].is_actived;
      this.cekmeObjActiveGoogle = data.result.cekim[2].is_actived;
    }
  }

  private async fetchLimitData(): Promise<void> {
    try {
      const limitData = await this.wallet.getLimitList().toPromise();
      this.handleLimitList(limitData);
    } catch (error) {
      console.error("Limit Verisi Çekilirken Hata Oluştu!");
    }
  }

  handleLimitList(data: any): void {
    if (data.success) {
      this.limitList = data.result;
      this.limitFiat = this.getLimit("TRY");
    } else {
      this.limitList = null;
    }
  }

  private handleFavoriteCoin(): void {
    if (this.getcoin) {
      this.favoriteCoin = this.getcoin.toUpperCase();

      if (this.isLoadingCyypto === false && this.getcoin !== "TRY") {
        $('[href="#TRYWithdraw"]').tab("show");
        this.getcoindetails("TRY");
        this.router.navigate(["kripto-cekme/TRY"]);
        return;
      }

      if (this.getcoin === "TRY") {
        $('[href="#TRYWithdraw"]').tab("show");
        return;
      } else {
        $('[href="#KRIPTOWithdraw"]').tab("show");
      }
      this.setFilterAddr(this.favoriteCoin);
    }
  }

  setFilterAddr(filterName: string): void {
    this.router.navigate(["kripto-cekme", filterName]);
    if (this.lstAddress === null) {
      this.alertifyService.error(this.lstAddress.message);
    } else {
      this.actionCreateCoin = filterName;
      const sv = this.lstAddress.filter((t) => t.currency === filterName);
      this.filterAddr = sv;
      this.networkType = sv[0]?.type;
      const sv2 = this.whitelist.filter(
        (t) => t.pair === filterName && t.network === this.networkType
      );
      this.filterAddrNetwork = sv2;
      const selectedCoin = this.limitList.filter(
        (t) => t.symbol === filterName
      );
      this.selectedCoin = this.filterAddr[0]?.currency;
      this.getcoindetails(this.selectedCoin);
      this.limitSelected = selectedCoin[0];
      (<HTMLFormElement>(
        document.getElementById("fiatwithdrawFormCoin")
      ))?.reset();
      this.error = null;
      this.success = null;
      this.total = 0.0;
    }
  }

  private async fetcPendingWithdrawalOrders(): Promise<void> {
    try {
      const pendingWithdraw = await this.wallet
        .getPendingWithdrawalOrders()
        .toPromise();
      this.handleGetWithdrawalOrders(pendingWithdraw);
    } catch (error) {
      this.alertifyService.error("Çekim talepleri yüklenemedi! ");
    }
  }

  handleGetWithdrawalOrders(data: any): void {
    if (data.success) {
      // Filter data for TRYWithdraw (coin is TRY)
      this.pendingWithdrawTRY = data.result.histroys.filter(
        (withdrawal: any) => withdrawal.coin === "TRY"
      );

      // Filter data for KRIPTOWithdraw (coin is not TRY)
      this.pendingWithdrawKRIPTO = data.result.histroys.filter(
        (withdrawal: any) => withdrawal.coin !== "TRY"
      );
    }
  }

  cancelWithdrawal(data: any) {
    alert(data.amount + " " +   data.coin + " iptal edildi");
  }

  // #region onSubmitFiatTRY
  public formTRY = {
    accountNumber: null,
    amountTRY: null,
  };

  async onSubmitFiatTRY() {
    this.isDisabledTRY = true;
    this.error_try = null;
    this.success_try = null;

    const formData = {
      bank_id: this.formTRY.accountNumber,
      amount: this.formTRY.amountTRY,
    };

    try {
      const data = await this.wallet.sendwithdrawfiat(formData).toPromise();
      this.handleResponse(data);
    } catch (error) {
      this.handleError(error);
    }
    this.getcoindetails("TRY");
  }

  handleResponse(res) {
    this.isDisabledTRY = false;
    if (res.success) {
      this.success_try = res.message;
      (<HTMLFormElement>document.getElementById("fiatwithdrawFormTRY")).reset();
      this.fetchLimitData();
      this.fetcPendingWithdrawalOrders();
    } else {
      this.isDisabledTRY = false;
      this.error_try = res.message;
    }
  }

  calculateTRY() {
    this.gamtTry = parseFloat(this.formTRY.amountTRY);
    this.balanceTry = parseFloat(this.balance);
    this.dayLimitTry = parseFloat(this.limitFiat.h24wres);
    this.monthTry = parseFloat(this.limitFiat.m30wres);
    this.success_try = null;
    this.error_try = null;

    if (isNaN(this.gamtTry)) {
      this.success_try = null;
    } else if (this.gamtTry > this.balanceTry) {
      this.success_try = null;
      this.error_try = "Yetersiz bakiye!";
    } else if (this.gamtTry < this.minwithdraw) {
      this.error_try =
        "Çekme işlemleriniz için minimum tutar " + this.minwithdraw + " TL dir";
    } else if (this.gamtTry > this.dayLimitTry) {
      this.success_try = null;
      this.error_try = "Günlük çekim limitiniz yetersiz!";
    } else if (this.gamtTry > this.monthTry) {
      this.success_try = null;
      this.error_try = "Aylık çekim limitiniz yetersiz!";
    } else {
      this.error_try = "";
      if (this.gamtTry > 0) {
        var comm = this.withdrawcomission;
        var afee = this.gamtTry * parseFloat(comm);
        this.admincomissionTRY = afee;
        this.totalTRY = this.gamtTry - afee;
      } else {
        this.admincomissionTRY = 0.0;
        this.totalTRY = 0.0;
      }
    }
  }

  onNavigateAccountAdd(value: string) {
    this.error_try = null;
    this.success_try = null;
    //this.fetcPendingWithdrawalOrders()
    if (value === "Hesap Ekle") this.router.navigate(["/bankalar"]);
  }

  getTRYClick() {
    this.getcoindetails("TRY");
    this.router.navigate(["kripto-cekme/TRY"]);
  }

  // #endregion onSubmitFiatTRY

  // #region  onSubmitKripto

  public form = {
    fromaddress: null,
    toaddress: null,
    amount: null,
    coin: null,
    otp: null,
    frontimage: null,
    proofimage: null,
  };

  // asenkron
  async onSubmitKripto() {
    try {
      this.isDisabled = true;
      this.error = null;
      this.success = null;
      let formData = {
        fromaddress: this.form.toaddress,
        toaddress: this.form.toaddress,
        coin: this.selectedCoin,
        amount: this.form.amount,
        pageroot: "withdrawrequest",
        action_type: "withdraw",
        action_mail: "withdraw",
      };

      const data = await this.wallet.sendwithdrawrequest(formData).toPromise();
      this.handleResponseChangeFishing(data, formData);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.isDisabled = false;
    }
  }

  handleResponseChangeFishing(res, postData) {
    this.isDisabled = false;
    if (res?.form_error === true) {
      this.error = res.message;
      return;
    } else {
      if (this.action_key === "") {
        this.action_key = res.result.action_key;
      }
      postData["action_key"] = this.action_key;
      this.sharedService.withdrawFormData(postData);
      this.display = !this.display;
      this.isDisabled = false;
      this.fetchCoinDetails();
      return false;
    }
  }

  calculateCoin() {
    this.gamtCoin = parseFloat(this.form.amount);
    this.balanceCoin = parseFloat(this.balance);
    this.dayLimitCoin = parseFloat(this.limitSelected.h24wres);
    this.monthCoin = parseFloat(this.limitSelected.m30wres);
    this.iserror = false;

    if (isNaN(this.gamtCoin)) {
      this.success = null;
    } else if (this.gamtCoin > this.balanceCoin) {
      this.success = null;
      this.error = "Yetersiz bakiye!";
    } else if (this.gamtCoin < this.minwithdraw) {
      this.error = "Minimum çekme miktarı " + this.minwithdraw;
    } else if (this.gamtCoin > this.dayLimitCoin && this.dayLimitCoin !== 0) {
      this.error = "Günlük çekim limitiniz yetersiz!";
    } else if (this.gamtCoin > this.monthCoin && this.monthCoin !== 0) {
      this.error = "Aylık çekim limitiniz yetersiz!";
    } else {
      this.error = "";
      if (!isNaN(this.gamtCoin) && this.gamtCoin > 0) {
        var comm = parseFloat(this.withdrawcomission);
        var netfee = parseFloat(this.netfee);

        if (isNaN(comm) || isNaN(netfee)) {
          this.success = null;
          this.error = "Geçersiz komisyon veya net ücret değeri!";
          return;
        }

        var afee = this.gamtCoin * comm;

        if (this.balanceCoin > this.gamtCoin) {
          this.getGWEI();
        }

        if (!isNaN(afee) && afee > 0) {
          this.admincomission = afee;
          this.total = this.gamtCoin - (afee + netfee);
        } else {
          this.admincomission = 0.0;
          this.total = this.gamtCoin - netfee;
        }
      } else {
        this.admincomission = 0.0;
        this.total = 0.0;
        this.gweifee = 0.0;
      }

      this.transfer_amount = netfee + this.admincomission;
    }
  }

  getGWEI() {
    this.wallet
      .getEstimatedFee(
        this.selectedCoin,
        this.form.amount,
        this.selectedAddrcoin
      )
      .subscribe(
        (res) => {
          this.gweifee = Number(res["fee"]);
        },
        (err) => {
          this.handleError(err);
        }
      );
  }

  getKriptoClick() {
    if (!this.selectedCoin) {
      this.selectedCoin = "BTC";
    }
    this.favoriteCoin = this.selectedCoin; // favoriteCoin'i güncelle
    this.setFilterAddr(this.favoriteCoin);
    this.router.navigate(["kripto-cekme", this.selectedCoin]);
  }

  showAddrType(clickNetwork) {
    var coinNetworks = clickNetwork.type;
    var sv2 = this.whitelist.filter((t) => t.network === coinNetworks);
    this.filterAddrNetwork = sv2;
    this.networkType = clickNetwork.type;
  }

  // #endregion onSubmitKripto

  // #region action_key

  getActionCreate(): Observable<string> {
    const formData = new FormData();
    formData.append("action_type", "withdraw");
    formData.append("toaddress", this.form.toaddress);
    formData.append("amount", this.form.amount);
    formData.append("coin", this.actionCreateCoin);
    return this.profile.actioncreateApiAddress(formData).pipe(
      map((data: any) => {
        if (data.success) {
          return data.result.action_key;
        } else {
          return "";
        }
      }),
      catchError((error) => {
        return of("");
      })
    );
  }

  getActionCreateTRY(): Observable<string> {
    const formData = new FormData();
    formData.append("action_type", "withdraw");
    formData.append("amountTRY", this.formTRY.amountTRY);
    return this.profile.actioncreateApiAddress(formData).pipe(
      map((data: any) => {
        if (data.success) {
          return data.result.action_key;
        } else {
          return "";
        }
      }),
      catchError((error) => {
        return of("");
      })
    );
  }

  // #endregion action_key

  getLimit(symbol) {
    var selectedCoin = this.limitList.filter((t) => t.symbol === symbol);
    return selectedCoin[0];
  }

  handleError(error) {
    this.error = error.message;
  }
}

import { Component, OnInit, VERSION } from "@angular/core";
import { AuthService } from "../../Services/auth.service";
import { IntermediateService } from "../../Services/intermediate.service";
import { TokenService } from "../../Services/token.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ProfileService } from "src/app/Services/profile.service";
import { Meta, Title } from "@angular/platform-browser";


@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  // public version = VERSION.full;
  public error = null;
  public success = null;
  public link = null;
  public company = null;
  userdata: any = [];
  profileData: any;
  profile_Information_Data: any;
  myMsg: any;
  hide = true;
  isDisabled: boolean;

  public loginForm = {
    email: "",
    password: "",
    recaptcha: "",
    device_key: "",
  };

  constructor(
    private myservice: IntermediateService,
    private Token: TokenService,
    private route: ActivatedRoute,
    private Auth: AuthService,
    private router: Router,
    private profile: ProfileService,private metaService: Meta, private titleService: Title 
  ) {
    this.getRouteParams();
  }

  ngOnInit():void {
    // Meta etiketlerini ayarla
    this.metaService.addTags([
      {
        name: "description",
        content:
          "Güvenle Bitcoin Alıp Satmak İçin Giriş Yap",
      },
      {
        name: "keywords",
        content: "Giriş,Giris, Bitistanbul, sigorta",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);

    const pageTitle = "Bitistanbul | Giriş";
    this.titleService.setTitle(pageTitle);
  }

  getRouteParams() {
    this.route.params.subscribe((params) => {
      this.myMsg = this.route.snapshot.paramMap.get("status");
    });
    if (this.myMsg) {
      if (this.myMsg === "success") {
        this.success = "Doğrulama Başarili!. Giriş yapabilirsiniz.";
      } else if (this.myMsg === "failed") {
        this.error = "Hata! Bağlantinin süresi doldu lütfen tekrar deneyin.";
      } else {
        this.error = null;
        this.success = null;
      }
    }
  }

  //#region Giriş yap
  async onSubmit() {
    this.isDisabled = true;
    this.error = null;
    this.success = null;
    this.link = null;
    try {
      this.loginForm.device_key = localStorage.getItem("device_key");
      const data = await this.myservice.login(this.loginForm).toPromise();
      this.handleResponse(data);
    } catch (error) {
      this.handleError(error);
    }
  }

  async handleResponse(data) {
    this.isDisabled = false;
    if (!data.success) {
      this.error = data.message;
      this.link = data.email;
    } else {
      const token = data.result.access_token;
      this.Token.handle(token);

      await this.profile.getprofileDetailsInfo().subscribe(
        (dataGetprofile) => this.handleProfileDetails(dataGetprofile),
        (error) => this.handleError(error)
      );

      const deviceKey = data.device_key;

      await localStorage.setItem("device_key", deviceKey);

      await localStorage.setItem(
        "verification_skip_email",
        data.verification_skip_email
      );
      // 'device_key' değerini form'a ekle
      this.loginForm.device_key = deviceKey;
      this.Auth.changeAuthStatus(true);
      if (data.is_phone_verified === 0) {
        this.router.navigateByUrl("/sms-dogrula");
      } else if (data.is_email_verified === 0) {
        this.router.navigateByUrl("/email-dogrula");
      } else if (data.user_sessions.is_2faauth === 0) {
        this.router.navigateByUrl("/ikili-dogrulama");
      } else if (
        data.all_files_uploaded === 0 ||
        data.survey_been_answered === 0
      ) {
        this.router.navigateByUrl("/dosya-yukle");
        return false;
      } else if (
        data?.is_customer === 0 &&
        data.all_files_uploaded === 1 &&
        data.survey_been_answered === 1
      ) {
        this.router.navigateByUrl("/durum");
      } else if (data.is_device_verified === 0) {
        this.router.navigateByUrl("/cihaz-dogrula");
      } else {
        this.Auth.changeAuthStatus(true);
        //this.router.navigateByUrl('/borsa/BTC/TRY');
        document.location.href = "/borsa/BTC/TRY";
      }
    }
  }

  handleProfileDetails(dataGetprofile) {
    if (dataGetprofile.success) {
      this.profile_Information_Data = dataGetprofile.result;
    } else {
      this.error = "Giriş Yapılamadı!";
    }
  }

  //#endregion giriş yap

  //#region handleError

  handleError(error) {
    this.error = error.message;
    this.isDisabled = false;
  }

  //#endregion handleError
}

<app-header></app-header>
<article>
  <section class="innerbannerpage">
    <div class="container">
      <!-- modal -->
      <div
        class="modal fade"
        id="BankAddModal"
        data-keyboard="false"
        data-backdrop="static"
        role="dialog"
        aria-labelledby="BankAddModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                (click)="cancelModal()"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h5
                class="modal-title text-center mt-10 t-black"
                id="exampleModalLongTitle"
              >
                <b>
                  {{
                    updateMode ? "Banka Güncelle" : ("AddBank" | translate)
                  }}</b
                >
              </h5>
            </div>
            <div class="modal-body">
              <form class="innerformbg" id="AddbankForm" #AddbankForm="ngForm">
                <div class="form-group cpybtnbg">
                  <label for="accountName">
                    {{ "AccountName" | translate }}</label
                  >
                  <input
                    type="text"
                    name="account_name"
                    class="form-control"
                    placeholder="{{ 'AccountNameEnter' | translate }}"
                    [class.is-invalid]="
                      account_name.invalid && account_name.touched
                    "
                    #account_name="ngModel"
                    [(ngModel)]="form.account_name"
                    required
                  />
                  <span
                    *ngIf="
                      account_name.errors &&
                      account_name.invalid &&
                      account_name.touched
                    "
                  >
                    <small
                      class="text-danger-input"
                      *ngIf="account_name.errors.required"
                      >{{ "AccountName" | translate }}
                      {{ "required" | translate }}</small
                    >
                    <small
                      class="text-danger-input"
                      *ngIf="account_name.errors.pattern"
                      >{{ "AccountNamevaild" | translate }}</small
                    >
                  </span>
                </div>

                <div class="form-group cpybtnbg">
                  <label for="IBAN">IBAN</label>
                  <div class="input-group">
                    <div class="input-group-addon">TR</div>
                    <input
                      type="text"
                      name="account_no"
                      (change)="IbanOnChange()"
                      (keypress)="onKeyPress($event)"
                      class="form-control"
                      placeholder="{{ 'IBANNoinformation' | translate }}"
                      #account_no="ngModel"
                      [(ngModel)]="form.account_no"
                      required
                      minlength="26"
                      maxlength="26"
                    />
                  </div>
                  <span
                    *ngIf="
                      account_no.errors &&
                      account_no.invalid &&
                      account_no.touched
                    "
                  >
                    <small
                      class="text-danger-input"
                      *ngIf="account_no.errors.required"
                      >{{ "IBAN" | translate }} {{ "required" | translate }}
                    </small>
                    <small
                      class="text-danger-input"
                      *ngIf="account_no.errors.pattern"
                      >{{ "IBANNovaild" | translate }}</small
                    >
                  </span>
                  <span *ngIf="error2">
                    <br />

                    <small class="text-danger">
                      1- Yalnızca TÜRK IBAN Numarası Kabul edilmektedir.
                    </small>
                    <br />
                    <small class="text-danger">
                      2- IBAN Numarasını Başında 'TR' Olmadan Giriniz.
                    </small>
                    <br />
                    <small class="text-danger">
                      3- IBAN Numaranız 24 RAKAMDAN Oluşmalıdır.
                    </small>
                    <br />
                  </span>
                </div>

                <div class="form-group cpybtnbg">
                  <table id="bankAddModeltable">
                    <tbody>
                      <tr>
                        <td style="width: 50px; vertical-align: top">
                          <span> Not :</span>
                        </td>
                        <td>
                          <span
                            style="
                              color: black;
                              font-weight: 500;
                              font-size: 10pt;
                            "
                          >
                            Tanımlayacağınız IBAN bilgileri sadece size ait bir
                            banka hesabına ait olmalıdır.
                            <br />
                            Aksi taktirde transfer talebiniz
                            gerçekleştirilmeyecektir.
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </form>
            </div>
            <div>
              <div class="form-group cpybtnbg">
                <div class="alert alert-danger text-center" [hidden]="!error">
                  {{ error }}
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn secondary-close"
                (click)="cancelModal()"
              >
                Kapat
              </button> &nbsp;

              <input
                type="submit"
                value="{{ updateMode ? 'Güncelle' : 'Kaydet' }}"
                class="btn orange-btn"
                [disabled]="
                  !(updateMode
                    ? form.account_name && form.account_no
                    : form.account_name &&
                      form.account_no &&
                      AddbankForm.form.valid)
                "
                (click)="updateMode ? bankUpdate() : onSubmit()"
              />
            </div>
            <br />
          </div>
        </div>
      </div>

      <!-- header -->
      <div class="row">
        <table id="bankList">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px; padding-left: 20%">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: 10px;"
                />
              </td>
            </tr>
            <tr>
              <td style="padding-left: 20%">
                <img
                  alt=""
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                />
                &nbsp;&nbsp;
                <small> {{ "BankList" | translate }}</small>
              </td>

              <td class="text-right" style="width: 20%">
                <button
                  type="button"
                  class="btn orange-btn"
                  (click)="addBanks()"
                >
                  {{ "AddBank" | translate }}
                </button>
              </td>
            </tr>
            <tr>
              <td colspan="2" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- listtable -->
      <div class="row mt-10">
        <table width="100%" class="table" id="bankdatatable">
          <thead>
            <tr>
              <th class="account-name-label-SNo">{{ "SNo" | translate }}</th>
              <th class="account-name-label-AccountType">
                {{ "AccountType" | translate }}
              </th>
              <th class="account-name-label-AccountName">
                {{ "AccountName" | translate }}
              </th>
              <th class="account-name-label-iban">i̇ban numarası</th>
              <th class="account-name-label"></th>
              <th class="account-name-label"></th>
            </tr>
          </thead>
          <tbody *ngIf="banks && banks.length > 0">
            <tr *ngFor="let accountBank of banks; let i = index">
              <td class="account-name-label-SNo">{{ i + 1 }}</td>
              <td class="account-name-label-AccountType">
                {{ accountBank.currency }}
              </td>
              <td>{{ accountBank.account_name }}</td>
              <td class="account-name-label-iban">
                TR{{ accountBank.account_no }}
              </td>

              <td class="text-right">
                <button
                  type="button"
                  class="btn editButton"
                  (click)="editBank(accountBank)"
                >
                  {{ "Edit" | translate }}
                </button>
              </td>
              <td class="text-center">
                <button
                  type="button"
                  class="btn viewbtn-style-delete"
                  title="Banka Kaydını Sil"
                  (click)="delete(accountBank)"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
            <tr>
              <td colspan="6"></td>
            </tr>
          </tbody>
          <tbody
            *ngIf="banks == undefined || banks.length == 0"
            style="border: none"
          >
            <tr>
              <td colspan="6" class="text-center">
                <br />
                <br />
                <br />
                {{ "NoRecordFound" | translate }}!
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

import { Component, OnInit } from "@angular/core";
import { ProfileService } from "src/app/Services/profile.service";
import { TokenService } from "../../Services/token.service";
import { Router } from "@angular/router";
import { AlertifyService } from "src/app/Services/alertify.service";
import { Subscription } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
declare var $: any;

@Component({
  selector: "app-fileuploadStatus",
  templateUrl: "./fileuploadStatus.component.html",
  styleUrls: ["./fileuploadStatus.css"],
})
export class fileuploadStatusComponent implements OnInit {
  public success: string | null = null;
  public error: string | null = null;
  private fileUploadSubscription: Subscription;
  userdata: any = [];
  userdataPersonStatus: any = [];
  userdataPersonStatus1: any = {};
  identityFront: any = [];
  PasaportFront: any = [];
  identityBackFace: any = [];
  invoice: any = [];
  petition: any = [];
  files1: any;
  files2: any;
  files3: any;
  files4: any;
  files5: any;

  constructor(
    private profile: ProfileService,
    private Token: TokenService,
    private router: Router,
    private alertifyService: AlertifyService,
    private metaService: Meta, private titleService: Title
  ) {}

  ngOnInit() {
    const pageTitle =
    "Bitistanbul | Hesap Aç";
  this.titleService.setTitle(pageTitle);

    // scrollTopMethod();
    this.Token.getUserDetails();
    this.fileUploadSubscription = this.profile.getprofileDetails().subscribe(
      (data) => this.handleProfileDetails(data),
      (error) => this.handleError(error)
    );
    this.uploadFile();
  }

  ngOnDestroy() {
    if (this.fileUploadSubscription) {
      this.fileUploadSubscription.unsubscribe();
    }
  }

  handleProfileDetails(data) {
    if (data.success) {
      this.userdata = data.result;
      if (
        this.userdata.is_customer === 1 &&
        this.userdata.are_there_unconfirmed_files === 0
      ) {
        setTimeout(() => {
          // this.router.navigateByUrl('/borsa/BTC/TRY');
          document.location.href = "/borsa/BTC/TRY";
        }, 1000);
      }
    }
  }

  uploadFile() {
    this.profile.getPersonDataStatus().subscribe(
      (data) => this.getPersonData(data),
      (error) => this.handleError(error)
    );
  }

  Object = Object;

  getPersonData(data) {
    if (data.success) {
      this.userdataPersonStatus = data?.documents;
      this.identityFront = data.documents[1];
      this.identityBackFace = data.documents[2];
      this.invoice = data.documents[3];
      this.petition = data.documents[4];
      this.PasaportFront = data.documents[5];
    }
  }

  // #region kimlik ön yüz

  public identityFrontModalInput = null;
  folderUpload1(event) {
    this.files1 = event.target.files[0];
  }

  openIdentityFrontModal() {
    this.error = null;
    this.success = null;
    this.identityFrontModalInput = null;
    $("#identityFrontModalCenter").modal("show");
  }

  onSaveidentityFront() {
    this.error = null;
    this.success = null;
    if (this.files1 === undefined || this.files1 === null) {
      this.alertifyService.error("Kimlik Ön Yüz Seçiniz!");
    } else {
      const formData = new FormData();
      formData.append("document", this.files1, this.files1.name);
      formData.append("document_type", "1");
      return this.profile.docUpload_one(formData).subscribe(
        (data) => this.handleResponseFront(data),
        (error) => this.handleError(error)
      );
    }
  }

  handleResponseFront(res) {
    if (res.success) {
      this.uploadFile();
      $("#identityFrontModalCenter").modal("hide");
    } else {
      this.alertifyService.error(res.message);
      this.uploadFile();
    }
  }
  // #endregion kimlik ön yüz

  // #region kimlik arka yüz

  public identityBackFaceModalInput = null;
  folderUpload2(event) {
    this.files2 = event.target.files[0];
  }

  openBackFaceModal() {
    this.error = null;
    this.success = null;
    this.identityBackFaceModalInput = null;
    $("#identityBackFaceModalCenter").modal("show");
  }

  onSaveidentityBackFace() {
    this.error = null;
    this.success = null;

    if (this.files2 === undefined || this.files2 === null) {
      this.alertifyService.error("Kimlik Arka Yüz Seçiniz!");
    } else {
      const formData = new FormData();
      formData.append("document", this.files2, this.files2.name);
      formData.append("document_type", "2");
      return this.profile.docUpload_one(formData).subscribe(
        (data) => this.handleResponseBackFace(data),
        (error) => this.handleError(error)
      );
    }
  }

  handleResponseBackFace(res) {
    if (res.success) {
      this.uploadFile();
      $("#identityBackFaceModalCenter").modal("hide");
    } else {
      this.alertifyService.error(res.message);
      this.uploadFile();
    }
  }
  // #endregion kimlik arka yüz

  // #region İkametgah veya fatura

  public identityInvoiceModalInput = null;
  folderUpload3(event) {
    this.files3 = event.target.files[0];
  }
  openInvoiceModal() {
    this.error = null;
    this.success = null;
    this.identityInvoiceModalInput = null;
    $("#identityInvoiceModalCenter").modal("show");
  }

  onSaveInvoice() {
    this.error = null;
    this.success = null;
    if (this.files3 === undefined || this.files3 === null) {
      this.alertifyService.error("Fatura/İkametgah Seçiniz!");
    } else {
      const formData = new FormData();
      formData.append("document", this.files3, this.files3.name);
      formData.append("document_type", "3");
      return this.profile.docUpload_one(formData).subscribe(
        (data) => this.handleResponseInvoice(data),
        (error) => this.handleError(error)
      );
    }
  }

  handleResponseInvoice(res) {
    if (res.success) {
      // this.success = res.message;
      this.uploadFile();
      $("#identityInvoiceModalCenter").modal("hide");
    } else {
      // this.error = res.message;
      this.alertifyService.error(res.message);
      this.uploadFile();
    }
  }

  // #endregion dilekçeli foto

  // #region  Dilekçeli Fotoğraf

  public identityPetitionModalInput = null;
  folderUpload4(event) {
    this.files4 = event.target.files[0];
  }
  openPetitionModal() {
    this.error = null;
    this.success = null;
    this.identityPetitionModalInput = null;
    $("#identityPetitionModalCenter").modal("show");
  }

  onSavePetition() {
    this.error = null;
    this.success = null;
    if (this.files4 === undefined) {
      this.alertifyService.error("Dilekçeli Fotoğraf Seçiniz!");
    } else {
      const formData = new FormData();
      formData.append("document", this.files4, this.files4.name);
      formData.append("document_type", "4");
      return this.profile.docUpload_one(formData).subscribe(
        (data) => this.handleResponsePetition(data),
        (error) => this.handleError(error)
      );
    }
  }

  handleResponsePetition(res) {
    if (res.success) {
      // this.success = res.message;
      this.uploadFile();
      $("#identityPetitionModalCenter").modal("hide");
    } else {
      // this.error = res.message;
      this.alertifyService.error(res.message);
      this.uploadFile();
    }
  }

  // #endregion İkametgah veya fatura

  // #region  pasaport
  public PasaportFrontModalInput = null;
  folderUpload5(event) {
    this.files5 = event.target.files[0];
  }
  openPasaportFrontModal() {
    this.error = null;
    this.success = null;
    this.PasaportFrontModalInput = null;
    $("#PasaportFrontModalCenter").modal("show");
  }

  onSavePasaportFront() {
    this.error = null;
    this.success = null;
    if (this.files5 === undefined) {
      this.alertifyService.error("Pasaportunuzun ilk Sayfasını Seçiniz!");
    } else {
      const formData = new FormData();
      formData.append("document", this.files5, this.files5.name);
      formData.append("document_type", "5");
      return this.profile.docUpload_one(formData).subscribe(
        (data) => this.handleResponsePasaportFront(data),
        (error) => this.handleError(error)
      );
    }
  }

  handleResponsePasaportFront(res) {
    if (res.success) {
      // this.success = res.message;
      this.uploadFile();
      $("#PasaportFrontModalCenter").modal("hide");
    } else {
      // this.error = res.message;
      this.alertifyService.error(res.message);

      this.uploadFile();
    }
  }
  // #endregion pasaport

  cancelModal()
  {
    $(".modal").modal("hide");
  }

  handleError(error) {
    this.error = error.message;
  }
}

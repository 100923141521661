import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: 'app-what-is-bitcoin',
  templateUrl: './what-is-bitcoin.component.html',
  styleUrls: ['./what-is-bitcoin.component.css']
})
export class WhatIsBitcoinComponent implements OnInit {

  constructor(private metaService: Meta, private titleService: Title) {}

  ngOnInit() {
     // Meta etiketlerini ayarla
     this.metaService.addTags([
      {
        name: "description",
        content:
          "Bitcoin ve blockchain teknolojisi hakkında bilgi edinin.",
      },
      {
        name: "keywords",
        content: "Bitcoin Nedir?",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);

    const pageTitle = 'Bitcoin Nedir?';
    this.titleService.setTitle(pageTitle);

  }

}

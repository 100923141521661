<footer class="footerbottom">
  <!--	<section class="footer-gray-bg">
		<div class="container">
			<div class="col-md-3 col-sm-3 col-xs-12">
            <h3 class="h3">Company</h3>
				<ul class="foot-list">
                    <li><a  href="#aboutus">About us</a></li>
                    <li><a routerLink="/contactus">Contact us</a></li>
                    <li><a href="#features">Features</a></li>
				</ul>
			</div>			
			<div class="col-md-3 col-sm-3 col-xs-12">
            <h3 class="h3">Products</h3>
				<ul class="foot-list">
                     <li><a routerLink="/exchange/BTC/USD">Exchange</a></li>
                    <li><a routerLink="/wallet">Wallet</a></li> 
                    <li><a routerLink="/support">Support</a></li> 
				</ul>
			</div>
			<div class="col-md-3 col-sm-3 col-xs-12">
            <h3 class="h3">Support</h3>
				<ul class="foot-list">
                         <li><a routerLink="/privary-policy">Privacy Policy</a></li>
                         <li><a routerLink="/terms-conditions">Terms and Conditions</a></li>
				</ul>
			</div>
			<div class="col-md-3 col-sm-3 col-xs-12 listrightbox">
                    <img src="assets/images/logo.svg" class="flogo" />
                    <div class="social-bg">
                    <ul class="sociallist" *ngFor="let result of data">
                        <li><a href="{{ result.fb }}"><i class="fa fa-facebook"></i></a></li>
                        <li><a href="{{ result.twitter }}" target="_blank"><i class="fa fa-twitter"></i></a></li>
                        <li><a href="{{ result.instagram }}" target="_blank"><i class="fa fa-instagram"></i></a></li>
                        <li><a href="{{ result.telegram }}"><i class="fa fa-telegram"></i></a></li>
                    </ul>
				</div>	
			</div>		
		</div>
  </section>!-->
  <section class="footerbottomsect">
    <div class="container">
      <div class="col-md-12 col-sm-12 col-xs-12 text-center">
        <p class="ftext">
          {{ date | date: "yyyy" }} {{ "Hotto Kripto Varlık Alım Satım Platformu A.Ş. / Tüm Hakları Saklıdır." | translate }}
        </p>
      </div>
    </div>
  </section>
</footer>

import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: 'app-how-to-buy-bitcoin',
  templateUrl: './how-to-buy-bitcoin.component.html',
  styleUrls: ['./how-to-buy-bitcoin.component.css']
})
export class HowToBuyBitcoinComponent implements OnInit {

  constructor(private metaService: Meta, private titleService: Title) {}

  ngOnInit() {
     // Meta etiketlerini ayarla
     this.metaService.addTags([
      {
        name: "description",
        content:
          "Bitcoinlerinizi kolay hızlı ve güvenle almak için rehberimize göz atın",
      },
      {
        name: "keywords",
        content: "Bitcoin Nasıl Alınır?",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);

    const pageTitle = 'Bitcoin Nasıl Alınır?';
    this.titleService.setTitle(pageTitle);
  }

}

import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { TokenService } from "./token.service";

@Injectable({
  providedIn: "root",
})
export class BeforeLoginService implements CanActivate {
  constructor(private Token: TokenService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (this.Token.loggedIn()) {
      return this.router.parseUrl("/"); // Kullanıcı oturum açıkken ana sayfaya yönlendir
    }
    return true; // Kullanıcı oturum açık değilse mevcut sayfada kalmasına izin ver
  }
}

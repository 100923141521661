<!-- Komisyon Oranları Pages -->

<app-header> </app-header>
<article class="innerpagearticle">
  <section class="innerbannerpage">
    <div class="container">
      <div class="row">
        <table id="commissionRatesHeader">
          <tbody>
            <tr>
              <td style="height: 31px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: 110px"
                />
              </td>
            </tr>
            <tr>
              <td class="text-center">
                <img
                  src="assets/images/070.svg"
                  alt=""
                  style="width: 24px; vertical-align: sub"
                />
                &nbsp;&nbsp;
                <small>{{ "CommissionRates" | translate }}</small>
              </td>
            </tr>
            <tr>
              <td style="height: 30px">
                <hr />
              </td>
            </tr>
            <!-- <tr>
              <td id="commission">Alış Satış Komisyon Oranları</td>
            </tr> -->
          </tbody>
        </table>
      </div>

      <div class="row mt-50">
        <div class="tabs">
          <div
            *ngFor="let category of categories"
            (click)="selectCategory(category)"
            [class.active]="selectedCategory === category"
          >
            {{ category }}
          </div>
        </div>

        <div class="coins-table">
          <div *ngIf="result[selectedCategory]; else noData">
            <div *ngFor="let coin of getCoinsByCategory(selectedCategory)">
              <h2>{{ coin }}</h2>
              <table class="data-table">
                <thead>
                  <tr>
                    <th>Aylık Alış - Satış Hacmi (TRY)</th>
                    <th class="special-td">Piyasa Yapıcı</th>
                    <th class="special-td">Piyasa Alıcı</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of result[selectedCategory][coin]">
                    <td>{{ data.volume_name }}</td>
                    <td class="special-td">{{ data.maker }}</td>
                    <td class="special-td">{{ data.taker }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <ng-template #noData>
            <p>Seçilen kategori için veri mevcut değil.</p>
          </ng-template>
        </div>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

import { Local } from "protractor/built/driverProviders";

   export const environment = {
     production: true,
     appVersion: require("../../package.json").version,
     baseUrl: "https://testapiv3.bitistanbul.com/api",
     tradeUrl: "https://testtradev3.bitistanbul.com/api",
     socketIOurlBoard: "https://testsocketv3.bitistanbul.com",
     siteUrl: "https://testv3.bitistanbul.com",
     pagination: 100,
   };


    //  export const environment = {
    //       production: true,
    //       appVersion: require("../../package.json").version,
    //       baseUrl: "https://testapi.bitistanbul.com/api",
    //       tradeUrl: "https://testtradev3.bitistanbul.com/api",
    //       socketIOurlBoard: "https://testsocketv3.bitistanbul.com",
    //       siteUrl: "https://bitistanbul.com",
    //       pagination: 100,
    //     };
   

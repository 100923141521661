								

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IntermediateService } from '../Services/intermediate.service';
import { TokenService } from './token.service';
import { AuthService } from 'src/app/Services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
 

  constructor(
    private myservice: IntermediateService,
    private Token: TokenService,
    private Auth: AuthService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body?.is_logout == 1) {
            this.apiLogout();
            this.Token.remove();
            this.Auth.changeAuthStatus(false);
		   
            window.location.href = '/giris'; // Kullanıcıyı giriş sayfasına yönlendir
          }
        }
      }),
      catchError((error) => {
        
        return throwError(error);
      })
    );
  }

  apiLogout() {
    return this.myservice.userlogout().subscribe();
  }
}

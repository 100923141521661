import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: 'app-user-agreement',
  templateUrl: './user-agreement.component.html',
  styleUrls: ['./user-agreement.component.css']
})
export class UserAgreementComponent implements OnInit {

  constructor(private metaService: Meta, private titleService: Title) {}

  ngOnInit() {
     // Meta etiketlerini ayarla
     this.metaService.addTags([
      {
        name: "description",
        content:
          "Kullanıcı sözleşmesi ve ilgili koşullar için tıklayın.",
      },
      {
        name: "keywords",
        content: "Kullanıcı Sözleşmesi",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);

    const pageTitle = 'Kullanıcı Sözleşmesi';
    this.titleService.setTitle(pageTitle);
  }

}

<!-- Bitcoin Nedir? Pages -->
<app-header></app-header>
<article class="innerpagearticle">
  <section class="innerbannerpage">
    <div class="container">
      <div class="row">
        <table id="what-is-bitcoin-header">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: 67px"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                  alt=""
                />
                &nbsp;&nbsp;
                <small> {{ "WhatIsBitcoin" | translate }}?</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row row-style">
        <p>
          Bitcoin ilk olarak 2008 yılında Satoshi Nakamoto adını kullanan
          bilinmeyen bir kişi veya grup tarafından icat edilen ve 2009'da açık
          kaynaklı yazılım olarak uygulamasının yayınlanmasıyla piyasaya çıkan
          bir kripto varlık birimidir.
        </p>
        <br />
        <p>
          Taraflar arasında başka bir aracıya ihtiyaç duymadan bitcoin ağında
          kullanıcıdan kullanıcıya gönderilebilen, bir merkez bankası veya
          merkezi bir sistem tarafından yönetilmeyen bir dijital para birimidir.
          Yapılan işlemler, kriptografi aracılığıyla ağ düğümleri (nodlar)
          tarafından doğrulanır ve blok zinciri adı verilen halka açık bir
          deftere (ledger) kaydedilir.
        </p>
        <br />
        <p>
          Bitcoin, madencilik olarak bilinen bir sürecin ödülü olarak yaratılır.
          Diğer para birimleri, ürünler ve hizmetler ile değiştirilebilirler.
          Cambridge Üniversitesi tarafından yapılan bir araştırmaya göre 2017
          yılı itibariyle Bitcoin bağlantılı kripto varlık cüzdanı kullanan 5,8
          milyon tekil kullanıcı olduğunu tahmin edilmektedir.
        </p>
        <br />
        <p>
          Bitcoin sisteminin hesap birimi yine aynı isimle anılır. Tüm dünyada
          Bitcoin'i temsil etmek için kullanılan piyasa sembolleri BTC ve
          XBT'dir. Alternatif birimler olarak kullanılan küçük miktarlarda
          bitcoin, millibitcoin (mBTC) ve satoshi (sat) olarak isimlendirilir.
          Satoshi bir bitcoin'in yüz milyonda biri olan 0.00000001 bitcoin'i
          temsil eden bitcoin içindeki en küçük miktardır. Bir millibitcoin,
          0.001 bitcoin'e eşittir; bir bitcoin'in binde biri veya 100.000
          satoshi’ye eşedeğerdir.
        </p>
      </div>
      <div class="row row-style">
        <p>
          <strong>Merkeziyet ve Bitcoin</strong>
        </p>
      </div>
      <div class="row row-style">
        <p>
          Bitcoin’in merkeziyetçi yapıdan uzak bir sistemi vardır. Bu özelliğini
          belirli başlıklarda özetlemek gerekirse;
        </p>
      </div>

      <div class="row row-style">
        <ul>
          <li>
            Bitcoin'in belirli bir merkezi yoktur. Genel olarak kurulu borsalar
            üzerinden alınıp satılır.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            Sistemde merkezi bir sunucu yoktur; Bitcoin ağı taraflar arası
            çalışmaktadır.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            Merkezi bir bitcoin depolaması yoktur; bunun yerine Bitcoin defteri
            dağıtılır. Defter halka açıktır; herkes kendi bilgisayarında
            saklayabilir.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            Tek bir yönetici yoktur; defter eşit derecede ayrıcalıklı
            madencilerden oluşan bir ağ tarafından yönetilir.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            Herkes madenci olabilir. Deftere yapılan eklemeler rekabet yoluyla
            sürdürülür.Deftere yeni bir blok eklenene kadar, bloğu hangi
            madencinin oluşturacağı bilinmemektedir.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            Bitcoin ihraçları merkezi olmayan bir şekilde yapılır. Yeni bir
            bloğun oluşturulması için ödül olarak verilirler. Herhangi biri,
            herhangi bir onaya ihtiyaç duymadan yeni bir bitcoin adresi (bir
            banka hesabının bitcoin dünyasındaki karşılığı) oluşturabilir.
            Herhangi bir onay gerektirmeden ağa herhangi bir işlem
            gönderilebilir.
          </li>
        </ul>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

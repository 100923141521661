<!-- Gizlilik Politikası Pages -->
<app-header></app-header>
<article class="innerpagearticle">
  <section class="innerbannerpage">
    <div class="container">
      <div class="row">
        <table id="privacy-policy-header">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: 91px"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                  alt=""
                />
                &nbsp;&nbsp;
                <small> {{ "PrivacyPolicy" | translate }}</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            <b>Bitistanbul</b>
            yasal yükümlülükleri çerçevesinde
            <b>müşteri işlemlerinin</b>
            yerine getirilebilmesi amacı ile alınan bilgilerin gizliliğinin
            korunması için aşağıdaki
            <b>temel kuralları</b>
            benimsemektedir.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            Müşteriler kişisel bilgilerini <b>Bitistanbul</b> ile paylaşmak
            konusunda gönüllü olduklarını kabul ederler. Bu bilgilerin toplanma
            amacı 'Müşteri Tanı' standartını yakalamak ve müşterilerin yaptığı
            işlemlerde güvenliğini sağlamaktır. Müşterilerimiz bize kimlikleri
            hakkında gerçek, güncel ve doğru bilgi vermek ile yükümlüdürler.
            Hileli veya sahte davranış ve tutum içinde bulunmayacakların, bir
            başka şahsın kimliğine bürünmeyeceklerini veya bir başka şahsı
            taklit veya belgesiz temsil etme amacı gütmeyeceklerini beyan etmek
            zorundadırlar.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            Müşteriler, hesap açtığında ve/veya internet sitesi ile
            <b>Bitistanbul</b>
            reklamlarının yer aldığı internet sitelerini/mecralarını
            kullandığında kişisel bilgilerinin toplanmasını ve kullanılması
            şartlarını kabul etmiş olur.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            Müşterilerin kişisel bilgileri, yasal yükümlülük altında
            bulunulmadığı sürece herhangi bir üçüncü şahıs veya kurum ile
            paylaşılmamaktadır.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            Müşterilerimizden işlemlerini yerine getirebilmek için kişisel
            bilgileri talep edilmekte; ve bu bilgiler müşterilerin güvenliği,
            gizliliği ile mahremiyetinin korunması adına alınması gereken en üst
            düzeyde alınarak saklanmaktadır.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            Müşterilerinin şirket ile yapmış olduğu tüm işlemler, ileride
            müşteriler ile
            <b>Bitistanbul</b> arasında herhangi bir anlaşmazlığın meydana
            geldiği durumlarda şirket tarafından kullanılmak üzere şirket
            tarafından kaydedilir.
          </li>
        </ul>
      </div>

      <div class="row row-style">
        <ul>
          <li>
            <b>Bitistanbul</b> tarafından oluşturulan bilgilerin (hesap
            numarası, platform şifreleri vb) gizliliğinin korunması aslen
            müşterilerin sorumluluğundadır ve kimseyle paylaşılmamalıdır.
          </li>
        </ul>
      </div>

      <div class="row row-style">
        <ul>
          <li>
            Müşteriler <b>Bitistanbul</b> 'a üye olup <b>Bitistanbul</b> ürün ve
            hizmetleri ile gönüllü işlemlerde bulunarak,
            <b>Bitistanbul</b> hesapları ile ilgili olarak vermiş oldukları
            bilgilerin bir kısmının veya tümünün
            <b>Bitistanbul</b> hesaplarında, bu hesaplarla ilgili işlemlerde ve
            şirket adına şirket ile yaptıkları işlemlerde kullanılmasına razı
            olurlar.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            <b>Bitistanbul</b> personeli tarafından müşteri bilgilerine
            erişimler işin yapılabilmesi için gereken en kısıtlı yetki seviyesi
            ve görevler ayrılığı prensibi esas alınarak düzenlenmektedir. Şirket
            personeli müşterilere ait kişisel bilgilerin gizliliğini korumak
            yükümlülüğü altındadır.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            <b>Bitistanbul</b> finansal işlemler veya promosyonlar hakkında
            bilgi vermek amacıyla müşterileri ile telefon veya e-mail yolu ile
            irtibata geçebilir. <b>Bitistanbul</b> 'a üye olurken onayladıkları
            şart ve koşullarımız ile kendileriyle bu tarz iletişimde
            bulunulmasına razı olduklarını onaylamış olurlar. Bu tarz iletişime
            geçilmesini istemeyen müşterilerimiz <b>Bitistanbul</b> ile irtibata
            geçerek bu taleplerini dile getirme hakkına sahiptirler.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            <b>Bitistanbul</b> müşterilerinin gizli bilgilerinin sürekli olarak
            güvende tutulmasını sağlamak amacıyla, bilgi koruma ve gizlilik
            politikasını sürekli güncellemekle yükümlü olduğunu taahhüt eder.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            Müşterinin <b>Bitistanbul</b> veri tabanında gerçekleştirdiği
            işlemlerin ve kişisel bilgilerin güvenliği amacıyla müşterinin
            bilgisayarına tanımlama bilgisi (cookie) yerleştirilmesi
            yapılmaktadır. Müşteri kişisel bilgilerinin güvenliğini sağlamak
            amacıyla yapılmaktadır. Kullanılan tanımlama bilgisi (cookie) hiçbir
            kişisel bilgi ve tanımlama içermez. Müşteri sistemden çıkınca
            otomatik devreden çıkar.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            Müşteri bilgileri <b>Bitistanbul</b> 'un bağlı olduğu düzenleyici
            kurumlar, yargı ve yürütme mercileri tarafından istendiği takdirde
            yasal düzenlemeler çerçevesinde açıklanabilecektir.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            Hizmet desteği almak amacıyla farklı kuruluşlarla çalışılması
            durumunda, bu firmaların<b> bitistanbul.com </b>
            gizlilik politikasına ve şartlarına uymaları sağlanmaktadır.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            <b>Bitistanbul</b> ile ilişkisini kesmek isteyen müşterilerimiz
            <b>Bitistanbul</b> bünyesinde derlenen bilgilerinin kaldırılmasını
            talep eden müşterilerimiz, irtibata geçerek taleplerini dile
            getirebilme hakkına sahiptirler. Müşterilerimiz faks veya e-mail ile
            bu taleplerini belirterek bilgilerini kalıcı olarak ve tamamen
            sildirebilirler.
          </li>
        </ul>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

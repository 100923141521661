import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/Services/home.service';

@Component({
  selector: 'app-userfooter',
  templateUrl: './userfooter.component.html',
  styleUrls: ['./userfooter.component.css'],
})
export class UserfooterComponent implements OnInit {
  date = new Date();
  public error = null;
  public success = null;
  public data = null;

  constructor(private home: HomeService) {}

  ngOnInit() {
   
  }
}

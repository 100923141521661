<!-- profil Pages -->
<app-header></app-header>
<article>
  <section class="innerbannerpage">
    <div class="container mt-47">
      <!-- profilHeader -->
      <div class="row">
        <table id="profilHeader">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: 4px;"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  alt=""
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                />
                &nbsp;&nbsp;
                <small> {{ "MyAccount" | translate }}</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Hesap Tipi -->
      <div class="row mt-30">
        <div class="accountStatus text-center">
          <span>Hesap Tipi : </span> {{ userdata?.rank }}
        </div>
      </div>

      <div class="row mt-30">
        <div class="flex-container">
          <!-- Ad Soyad -->
          <div class="col-md-3">
            <h4>Ad Soyad</h4>
            <h5 class="link">{{ userdata?.name }}</h5>
            <h5>
              <i class="fa fa-check" aria-hidden="true"></i>
            </h5>
          </div>

          <!-- E-Mail -->
          <div class="col-md-3">
            <h4>E-Mail Doğrulama</h4>
            <h5>
              {{ userdata.email }}
              <div class="status-info">
                <div
                  *ngIf="
                    userdata.email_verify == 1;
                    then thenBlock;
                    else elseBlock
                  "
                ></div>
                <ng-template #thenBlock>
                  <i class="fa fa-check" aria-hidden="true"></i>
                </ng-template>
                <ng-template #elseBlock>
                  <span class="t-red">
                    <i class="fa fa-times-circle-o" aria-hidden="true"></i
                  ></span>
                </ng-template>
              </div>
            </h5>
          </div>

          <!-- SMS Doğrulama -->
          <div class="col-md-3">
            <h4>SMS Doğrulama</h4>
            <h5>
              {{ userdata.phone_no }}
              <div class="status-info">
                <div
                  *ngIf="
                    userdata.is_phone_verified == 1;
                    then thenBlock;
                    else elseBlock
                  "
                ></div>
                <ng-template #thenBlock>
                  <span class="t-green">
                    <i class="fa fa-check-circle-o" aria-hidden="true"></i
                  ></span>
                </ng-template>
                <ng-template #elseBlock>
                  <span class="t-red">
                    <i class="fa fa-times-circle-o" aria-hidden="true"></i
                  ></span>
                </ng-template>
              </div>
            </h5>
          </div>

          <!-- 2FA Doğrulama -->
          <div class="col-md-2">
            <h4>2FA Doğrulama</h4>

            <div *ngIf="user_twofa_list.length > 0; else notTwoFA">
              <h5 *ngFor="let twofa of user_twofa_list">
                {{ twofa.twofa_name }}
              </h5>
            </div>

            <ng-template #notTwoFA>
              <span class="t-red">
                <i class="fa fa-times-circle-o" aria-hidden="true"></i></span
            ></ng-template>
          </div>
        </div>
      </div>

      <!-- Hesap bilgileri -->
      <div class="row mt-30">
        <div class="float-child">
          <div class="text-center">
            <span>30 Günlük İşlem Hacminiz</span>
            <br />
            <br />
            <div class="d-flex justify-content-center">
              <table>
                <tr *ngFor="let currency of trading_commission | keyvalue">
                  <td>
                    <h4>{{ currency.key }}</h4>
                  </td>
                  <td class="text-right">
                    {{ formattingService.formatCrypto(currency.value) }}
                  </td>
                </tr>

                <tr>
                  <td style="width: 200px">
                    <h4 style="color: #33b887 !important">TRY TOPLAM</h4>
                  </td>
                  <td class="text-right">
                    {{ formattingService.formatNumberTRY(totalTRY) }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div class="float-child1">
          <div class="text-center">
            <span>Cari Komisyon Oranınız</span>
            <br />
            <br />
            <table style="width: 100%">
              <thead>
                <tr>
                  <th></th>
                  <th class="text-center"><h4>Piyasa Yapıcı</h4></th>
                  <th class="text-center"><h4>Piyasa Alıcı</h4></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let cari of cari_commission | keyvalue">
                  <td class="text-left">
                    <h4>{{ cari.key }}</h4>
                  </td>
                  <td class="text-center">{{ cari.value.maker }}</td>
                  <td class="text-center">{{ cari.value.taker }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- YATIRMA - ÇEKME LİMİTLERİ -->
      <div class="row mt-30" id="info">
        <div class="flex-container flex-container-yatir-cek">
          <div class="col-md-12">
            <span>YATIRMA - ÇEKME LİMİTLERİ</span>
            <br />
            <table id="commissionRatesTable">
              <thead *ngIf="limits?.length > 0">
                <tr>
                  <th class="text-center"><h4>Sembol</h4></th>
                  <th class="text-center"><h4>Günlük Çekme Limiti</h4></th>
                  <th class="text-center"><h4>Günlük Yatırma Limiti</h4></th>
                  <th class="text-center"><h4>Aylık Çekme Limiti</h4></th>
                  <th class="text-center"><h4>Aylık Yatırma Limiti</h4></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let limit of limits">
                  <td>
                    <h5>{{ limit.symbol }}</h5>
                  </td>
                  <td>
                    <h5>
                      <span>{{ limit.h24wres }}</span> /{{ limit.d24Withdraw }}
                    </h5>
                  </td>
                  <td>
                    <h5>
                      <span>{{ limit.h24dres }}</span> /{{ limit.d24Deposit }}
                    </h5>
                  </td>
                  <td>
                    <h5>
                      <span>{{ limit.m30wres }}</span> /{{ limit.m30Withdraw }}
                    </h5>
                  </td>
                  <td>
                    <h5>
                      <span>{{ limit.m30dres }}</span> /{{ limit.m30Deposit }}
                    </h5>
                  </td>
                </tr>
                <tr *ngIf="!limits || limits.length === 0">
                  <td colspan="5"><h5>Limit tanımlaması bulunamadı.</h5></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Komisyon Oranları -->
      <div class="row mt-30" id="info">
        <div class="flex-container flex-container-komisyon">
          <div class="col-md-12">
            <span>Komisyon Oranları</span>
            <ul class="nav-pills d-flex justify-content-center centered-list">
              <li class="nav-item" *ngFor="let key of coinKeys">
                <a
                  class="nav-link"
                  data-toggle="pill"
                  [href]="'#' + key"
                  [class.active]="activeKey === key"
                  (click)="activeKey = key"
                  >{{ key }}</a
                >
              </li>
            </ul>

            <table id="commissionRatesTable">
              <thead>
                <tr>
                  <th style="width: 32%" class="text-center">
                    <h4>Aylık Alış-Satış Hacmi (TRY)</h4>
                  </th>
                  <th style="width: 32%" class="text-center">
                    <h4>Piyasa Yapıcı</h4>
                  </th>
                  <th style="width: 32%" class="text-center">
                    <h4>Piyasa Alıcı</h4>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of son[activeKey]">
                  <td>
                    <h5>{{ data.volume }}</h5>
                  </td>
                  <td>
                    <h5>{{ data.maker }}</h5>
                  </td>
                  <td>
                    <h5>{{ data.taker }}</h5>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- dosya durumları -->
      <div class="row mt-30" id="info">
        <div class="flex-container flex-container-fol-status">
          <div class="col-md-12">
            <span>Hesap Başvuru Hareketleri</span>
            <br />
            <table id="commissionRatesTable">
              <thead>
                <tr>
                  <th style="width: 32%" class="text-center">
                    <h4>Başvuru Tarihi</h4>
                  </th>
                  <th style="width: 32%" class="text-center">
                    <h4>Başvuru Tipi</h4>
                  </th>
                  <th style="width: 32%" class="text-center">
                    <h4>Durum</h4>
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- identityFront -->
                <tr *ngIf="identityFront?.status">
                  <td>
                    <h5>{{ identityFront?.created_at | localizedDate }}</h5>
                  </td>
                  <td>
                    <h5>{{ identityFront?.document_type }}</h5>
                  </td>

                  <td>
                    <!-- Eğer status_id 5 veya 4 ise sadece buton gösterilecek -->
                    <h5
                      *ngIf="
                        identityFront?.status_id !== 5 &&
                        identityFront?.status_id !== 4
                      "
                    >
                      {{ identityFront?.status }}
                    </h5>

                    <!-- status_id 5 veya 4 ise buton görünecek -->
                    <button
                      *ngIf="
                        identityFront?.status_id === 5 ||
                        identityFront?.status_id === 4
                      "
                      class="btn btn-sendFile"
                      data-toggle="modal"
                      data-target="#editModal"
                      (click)="editIdentityBackFace(identityFront)"
                    >
                      Düzenle
                    </button>
                  </td>
                </tr>

                <!-- identityBackFace -->
                <tr *ngIf="identityBackFace?.status">
                  <td>
                    <h5>{{ identityBackFace?.created_at | localizedDate }}</h5>
                  </td>
                  <td>
                    <h5>{{ identityBackFace?.document_type }}</h5>
                  </td>
                  <td>
                    <!-- Eğer status_id 5 veya 4 ise sadece buton gösterilecek -->
                    <h5
                      *ngIf="
                        identityBackFace?.status_id !== 5 &&
                        identityBackFace?.status_id !== 4
                      "
                    >
                      {{ identityBackFace?.status }}
                    </h5>

                    <!-- status_id 5 veya 4 ise buton görünecek -->
                    <button
                      *ngIf="
                        identityBackFace?.status_id === 5 ||
                        identityBackFace?.status_id === 4
                      "
                      class="btn btn-sendFile"
                      data-toggle="modal"
                      data-target="#editModal"
                      (click)="editIdentityBackFace(identityBackFace)"
                    >
                      Düzenle
                    </button>
                  </td>
                </tr>

                <!-- invoice -->
                <tr *ngIf="invoice?.status">
                  <td>
                    <h5>{{ invoice?.created_at | localizedDate }}</h5>
                  </td>
                  <td>
                    <h5>{{ invoice?.document_type }}</h5>
                  </td>

                  <td>
                    <!-- Eğer status_id 5 veya 4 ise sadece buton gösterilecek -->
                    <h5
                      *ngIf="
                        invoice?.status_id !== 5 && invoice?.status_id !== 4
                      "
                    >
                      {{ invoice?.status }}
                    </h5>

                    <!-- status_id 5 veya 4 ise buton görünecek -->
                    <button
                      *ngIf="
                        invoice?.status_id === 5 || invoice?.status_id === 4
                      "
                      class="btn btn-sendFile"
                      data-toggle="modal"
                      data-target="#editModal"
                      (click)="editIdentityBackFace(invoice)"
                    >
                      Düzenle
                    </button>
                  </td>
                </tr>

                <!-- petition -->
                <tr *ngIf="petition?.status">
                  <td>
                    <h5>{{ petition?.created_at | localizedDate }}</h5>
                  </td>
                  <td>
                    <h5>{{ petition?.document_type }}</h5>
                  </td>

                  <td>
                    <!-- Eğer status_id 5 veya 4 ise sadece buton gösterilecek -->
                    <h5
                      *ngIf="
                        petition?.status_id !== 5 && petition?.status_id !== 4
                      "
                    >
                      {{ petition?.status }}
                    </h5>

                    <!-- status_id 5 veya 4 ise buton görünecek -->
                    <button
                      *ngIf="
                        petition?.status_id === 5 || petition?.status_id === 4
                      "
                      class="btn btn-sendFile"
                      data-toggle="modal"
                      data-target="#editModal"
                      (click)="editIdentityBackFace(petition)"
                    >
                      Düzenle
                    </button>
                  </td>
                </tr>

                <!-- PasaportFront -->
                <tr *ngIf="PasaportFront?.status">
                  <td>
                    <h5>{{ PasaportFront?.created_at | localizedDate }}</h5>
                  </td>
                  <td>
                    <h5>{{ PasaportFront?.document_type }}</h5>
                  </td>

                  <td>
                    <!-- Eğer status_id 5 veya 4 ise sadece buton gösterilecek -->
                    <h5
                      *ngIf="
                        PasaportFront?.status_id !== 5 &&
                        PasaportFront?.status_id !== 4
                      "
                    >
                      {{ PasaportFront?.status }}
                    </h5>

                    <!-- status_id 5 veya 4 ise buton görünecek -->
                    <button
                      *ngIf="
                        PasaportFront?.status_id === 5 ||
                        PasaportFront?.status_id === 4
                      "
                      class="btn btn-sendFile"
                      data-toggle="modal"
                      data-target="#editModal"
                      (click)="editIdentityBackFace(PasaportFront)"
                    >
                      Düzenle
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Dosya gonderme modal -->
      <div
        class="modal fade"
        id="editModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="editModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h5 class="modal-title text-center t-black">
                <b>{{ selectedDocument?.document_type }} Düzenle</b>
              </h5>
            </div>
            <div class="modal-body">
              <form autocomplete="off">
                <div class="form-group">
                  <label for="editDocumentType">Başvuru Durumu</label>
                  <input
                    type="text"
                    id="editDocumentType"
                    class="form-control"
                    [value]="selectedDocument?.document_type"
                    disabled
                    readonly
                  />
                </div>
                <div class="form-group">
                  <label for="editDescription">Red Nedeni</label>
                  <textarea
                    class="form-control"
                    id="editDescription"
                    rows="3"
                    [value]="selectedDocument?.rejection_description || 'Arşiv'"
                    disabled
                    readonly
                  ></textarea>
                </div>
                <div class="form-group">
                  <input
                    type="file"
                    class="form-control"
                    (change)="fileUpload($event)"
                    accept=".jpg,.jpeg,.png,.pdf,.svg"
                  />
                  <small class="form-text text-muted"
                    >{{ selectedDocument?.document_type }} yükleyiniz.</small
                  >
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <!-- Kapat butonu -->
              <button
                type="button"
                class="btn secondary-close"
                data-dismiss="modal"
              >
                Çıkış
              </button> &nbsp;
              <button
                type="button"
                class="btn orange-btn"
                (click)="submitEdit()"
              >
                Tekrar Gönder
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- sözleşmelerim -->
      <div class="row mt-30" id="info">
        <div class="flex-container flex-container-komisyon">
          <div class="col-md-12">
            <span>Sözleşmelerim</span>
            <br />
            <table id="commissionRatesTable">
              <thead>
                <tr>
                  <th style="width: 32%" class="text-center"></th>
                  <th style="width: 32%" class="text-center">
                    <h4>Onayladığı Tarih</h4>
                  </th>
                  <th style="width: 32%" class="text-center">
                    <h4>IP No</h4>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let _agreements of agreements">
                  <!-- <td>
                    <h4>{{ _agreements?.title }}</h4>
                  </td> -->
                  <td>
                    <h4>
                      <ng-container *ngIf="_agreements.slug">
                        <a [routerLink]="[_agreements.slug]" target="_blank">{{
                          _agreements.title
                        }}</a>
                      </ng-container>
                      <ng-container *ngIf="!_agreements.slug">
                        {{ _agreements.title }}
                      </ng-container>
                    </h4>
                  </td>
                  <td>
                    <h5>{{ _agreements?.updated_at | localizedDate }}</h5>
                  </td>
                  <td>
                    <h5>{{ _agreements?.user_ip }}</h5>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- ticari ileti metni -->
      <div class="row mt-30" id="info">
        <div class="flex-container flex-container-komisyon">
          <div class="col-md-12">
            <span>Ticari Elektronik İleti İzni</span>
            <h5>
              Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metni kapsamında
              Bitistanbul’dan e-mail ve <br />
              sms yoluyla bilgi, duyuru ve kampanyaları içeren ticari elektronik
              ileti almayı seçebilirsiniz.
            </h5>
          </div>

          <table class="messagePermission">
            <tr>
              <td class="text-center"><h4>e-Mail</h4></td>
              <td class="text-center"><h4>Sms</h4></td>
            </tr>
            <tr>
              <td class="text-center">
                <input
                  type="checkbox"
                  title="e-Mail"
                  name="email"
                  #email
                  [(ngModel)]="isCheckedEmail"
                  (change)="onCheckboxChange($event)"
                />
              </td>
              <td class="text-center">
                <input
                  type="checkbox"
                  name="sms"
                  #sms
                  [(ngModel)]="isCheckedSms"
                  title="Sms"
                  (change)="onCheckboxChange($event)"
                />
              </td>
            </tr>
          </table>

          <div class="col-md-12" id="successM">
            {{ success }}
          </div>
          <div class="col-md-12" id="successM">
            {{ error }}
          </div>
        </div>
      </div>

      <!-- ticari ileti metni modal -->
      <div
        class="modal fade"
        id="commercialMessage"
        data-keyboard="false"
        data-backdrop="static"
        role="dialog"
        aria-labelledby="commercialMessageLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title text-center t-black"
                id="exampleModalLongTitle"
              >
                <b>Ticari Elektronik İleti Bilgilendirmesi </b>
              </h5>
            </div>

            <div class="modal-body" style="text-align: justify">
              <a
                href="https://bitistanbul.com/"
                target="_blank"
                rel="noopener"
                style="text-decoration: underline; color: black"
                ><b>www.bitsitanbul.com</b></a
              >
              internet sitemizde hesap oluşturma sekmesinde yer alan bilgilerin
              doldurulması sırasında kendi takdirinizle Hotto Kripto Varlık Alım Satım Platformu A.Ş. (Bitistanbul) tarafından tarafınıza elektronik posta ve SMS
              yoluyla ticari elektronik ileti gönderilmesine izin vermiş
              bulunmaktasınız. Bu izin kapsamında Bitistanbul tarafından
              elektronik posta adresiniz ile cep telefonunuza işlem gören
              pariteler, ekonomik gelişmeler, kripto varlıklar, kripto varlık
              ekosistemi, ürün ve hizmetlerimiz hakkında bilgilendirme,
              kampanya, promosyon, yeni ürün ve hizmet tanıtımı, bayramlar,
              yılbaşı, doğum gününüzde yapılacak kutlama paylaşımları gibi
              Bitistanbul’un tanınırlığını, müşteri memnuniyetini ya da müşteri
              bağlılığını artırıcı pazarlama, tanıtım faaliyetlerine ilişkin
              ticari elektronik ileti gönderilecektir. Bitistanbul’a vermiş
              olduğunuz ticari elektronik ileti iznini

              <a
                href="https://vatandas.iys.org.tr/giris"
                target="_blank"
                rel="noopener"
                style="text-decoration: underline; color: black"
                ><b>vatandas.iys.org.tr</b></a
              >
              adresinden kontrol edebilir ve vermiş olduğunuz izne ilişkin
              tercihlerinizi değiştirebilir ya da
              <a
                href="https://bitistanbul.com/hesabim"
                target="_blank"
                rel="noopener"
                style="text-decoration: underline; color: black"
                ><b> www.bitsitanbul.com/hesabim</b></a
              >
              linkine tıklayarak elektronik posta ve SMS listemizden istediğiniz
              zaman tekrar çıkabilirsiniz.
            </div>
            <div class="modal-footer modal-footer-style">
              <button
                type="button"
                class="btn secondary-close"
                data-dismiss="modal"
                (click)="closeTicModal()"
              >
                Kabul Etmiyorum
              </button>
              <button
                type="button"
                class="btn orange-btn"
                (click)="sendToAPI()"
              >
                Kabul Ediyorum
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

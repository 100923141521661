import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localizedDate'
})
export class LocalizedDatePipe implements PipeTransform {

  transform(value: any): string {
    if (!value) return '';

    let date: Date;

    // 09.11.2024 16:17:24 formatı için ayrıştırma
    if (typeof value === 'string' && value.match(/\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}:\d{2}/)) {
      const [day, month, year, hours, minutes, seconds] = value.match(/\d+/g);
      const normalizedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
      date = new Date(normalizedDate);
    }
    // 19-09-2024, 17:52:01 formatı için ayrıştırma
    else if (typeof value === 'string' && value.match(/\d{2}-\d{2}-\d{4}, \d{2}:\d{2}:\d{2}/)) {
      const [day, month, year, hours, minutes, seconds] = value.match(/\d+/g);
      const normalizedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
      date = new Date(normalizedDate);
    }
    // 19/09/2024 13:45:27 formatı için ayrıştırma
    else if (typeof value === 'string' && value.match(/\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2}/)) {
      const [day, month, year, hours, minutes, seconds] = value.match(/\d+/g);
      const normalizedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
      date = new Date(normalizedDate);
    } else {
      // Diğer formatlar için, new Date() ile normalize etme
      date = new Date(value);
    }

    // Eğer geçersiz tarihse, boş string döndürüyoruz
    if (isNaN(date.getTime())) {
      return ''; // Hatalı tarih verisi varsa boş string döndür
    }

    // Sabit format: Tarayıcı diline göre tarih ve saat formatlama, 24 saat formatında ve PM/AM olmadan
    return new Intl.DateTimeFormat(navigator.language, {
      year: 'numeric',
      month: '2-digit',  // Sayısal ay (01-12)
      day: '2-digit',    // Sayısal gün (01-31)
      hour: '2-digit',   // Sayısal saat (00-23)
      minute: '2-digit', // Sayısal dakika (00-59)
      second: '2-digit', // Sayısal saniye (00-59)
      hour12: false      // 24 saat formatı, PM/AM olmadan
    }).format(date);
  }
}

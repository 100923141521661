<app-header-empty></app-header-empty>
<article class="innerpagearticle">
  <section class="innerbannerpage">
    <div class="container">
      <div class="row">
        <!-- AccountFolderVerification -->
        <table id="AccountFolderVerification">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 17px; margin-right: 110px"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  alt=""
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                />
                &nbsp;&nbsp;
                <small> Hesap Doğrulama</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Modal Kimlik Ön Yüz-->
      <div
        class="modal fade"
        id="identityFrontModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="identityFrontModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h5
                class="modal-title text-center t-black"
                id="exampleModalLongTitle"
              >
                <b>Kimlik Ön Yüz Yükle </b>
              </h5>
            </div>
            <div class="modal-body">
              <form autocomplete="off">
                <div class="form-group">
                  <label for="identityFrontDocument_type">
                    Başvuru Durumu</label
                  >
                  <input
                    type="text"
                    name="identityFrontStatus"
                    id="identityFrontStatus"
                    class="form-control"
                    value="{{ identityFront?.document_type }} - {{
                      identityFront?.status
                    }}"
                    disabled
                    readonly
                  />
                </div>

                <div class="form-group">
                  <label for="identityFrontrejection_description">
                    Red Nedeni</label
                  >
                  <textarea
                    class="form-control"
                    id="identityFrontrejection_description"
                    rows="3"
                    value="{{ identityFront?.rejection_description }}"
                    disabled
                    readonly
                  ></textarea>
                </div>

                <div class="form-group">
                  <input
                    type="file"
                    title="Kimlik Ön Yüz Seçiniz"
                    name="identityFront"
                    class="form-control"
                    accept=".jpg,.jpeg,.png,.pdf,.SVG"
                    (change)="folderUpload1($event)"
                    [(ngModel)]="identityFrontModalInput"
                    required
                  />
                  <small id="emailHelp" class="form-text text-muted"
                    >Kimlik Ön Yüz dosyasını seçerek tekrar yükleyiniz.
                  </small>
                </div>
              </form>
            </div>

            <div class="modal-footer">
               <button
              type="button"
              class="btn secondary-close"
              data-dismiss="modal"
              (click)="cancelModal()"
            >
              {{ "Close" | translate }}
            </button> &nbsp;
              <button
                type="button"
                class="btn orange-btn"
                (click)="onSaveidentityFront()"
              >
                Tekrar Gönder
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Kimlik Arka Yüz-->
      <div
        class="modal fade"
        id="identityBackFaceModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="identityBackFaceModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h5
                class="modal-title text-center t-black"
                id="exampleModalLongTitle"
              >
                <b>Kimlik Arka Yüz Yükle </b>
              </h5>
            </div>
            <div class="modal-body">
              <form autocomplete="off">
                <div class="form-group">
                  <label for="identityBackFace"> Başvuru Durumu</label>
                  <input
                    type="text"
                    name="identityBackFace"
                    id="identityBackFace"
                    class="form-control"
                    value="{{ identityBackFace?.document_type }} - {{
                      identityBackFace?.status
                    }}"
                    disabled
                    readonly
                  />
                </div>

                <div class="form-group">
                  <label for="identityBackFaceRejection_description">
                    Red Nedeni</label
                  >
                  <textarea
                    class="form-control"
                    id="identityBackFaceRejection_description"
                    rows="3"
                    value="{{ identityBackFace?.rejection_description }}"
                    disabled
                    readonly
                  ></textarea>
                </div>

                <div class="form-group">
                  <input
                    type="file"
                    title="Kimlik Arka Yüz"
                    name="identityBackFace"
                    class="form-control"
                    accept=".jpg,.jpeg,.png,.pdf,.SVG"
                    (change)="folderUpload2($event)"
                    [(ngModel)]="identityBackFaceModalInput"
                    required
                  />
                  <small id="emailHelp" class="form-text text-muted"
                    >Kimlik Arka Yüz dosyasını seçerek tekrar yükleyiniz.
                  </small>
                </div>
              </form>
            </div>

            <div class="modal-footer">
              <button
              type="button"
              class="btn secondary-close"
              data-dismiss="modal"
              (click)="cancelModal()"
            >
              {{ "Close" | translate }}
            </button> &nbsp;
              <button
                type="button"
                class="btn orange-btn"
                (click)="onSaveidentityBackFace()"
              >
                Tekrar Gönder
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal İkametgah veya Fatura -->
      <div
        class="modal fade"
        id="identityInvoiceModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="identityInvoiceModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h5
                class="modal-title text-center t-black"
                id="exampleModalLongTitle"
              >
                <b>İkametgah veya Fatura Yükle </b>
              </h5>
            </div>
            <div class="modal-body">
              <form autocomplete="off">
                <div class="form-group">
                  <label for="invoice"> Başvuru Durumu</label>
                  <input
                    type="text"
                    name="invoice"
                    id="invoice"
                    class="form-control"
                    value="{{ invoice?.document_type }} - {{ invoice?.status }}"
                    disabled
                    readonly
                  />
                </div>

                <div class="form-group">
                  <label for="invoice_description"> Red Nedeni</label>
                  <textarea
                    class="form-control"
                    id="invoice_description"
                    rows="3"
                    value="{{ invoice?.rejection_description }}"
                    disabled
                    readonly
                  ></textarea>
                </div>

                <div class="form-group">
                  <input
                    type="file"
                    title="Fatura/İkametgah"
                    name="invoice"
                    class="form-control"
                    accept=".jpg,.jpeg,.png,.pdf,.SVG"
                    [(ngModel)]="identityInvoiceModalInput"
                    (change)="folderUpload3($event)"
                    required
                  />

                  <small id="emailHelp" class="form-text text-muted"
                    >İkametgah veya Fatura dosyanızı seçerek tekrar yükleyiniz.
                  </small>
                </div>
              </form>
            </div>

            <div class="modal-footer">
              <button
              type="button"
              class="btn secondary-close"
              data-dismiss="modal"
              (click)="cancelModal()"
            >
              {{ "Close" | translate }}
            </button> &nbsp;
              <button
                type="button"
                class="btn orange-btn"
                (click)="onSaveInvoice()"
              >
                Tekrar Gönder
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Selfi-->
      <div
        class="modal fade"
        id="identityPetitionModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="identityPetitionModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h5
                class="modal-title text-center t-black"
                id="exampleModalLongTitle"
              >
                <b>Dilekçeli Fotoğraf Yükle </b>
              </h5>
            </div>
            <div class="modal-body">
              <form autocomplete="off">
                <div class="form-group">
                  <label for="petition"> Başvuru Durumu</label>
                  <input
                    type="text"
                    name="petition"
                    id="petition"
                    class="form-control"
                    value="{{ petition?.document_type }} - {{
                      petition?.status
                    }}"
                    disabled
                    readonly
                  />
                </div>

                <div class="form-group">
                  <label for="petition_description"> Red Nedeni</label>
                  <textarea
                    class="form-control"
                    id="petition_description"
                    rows="3"
                    value="{{ petition?.rejection_description }}"
                    disabled
                    readonly
                  ></textarea>
                </div>

                <div class="form-group">
                  <input
                    type="file"
                    title="Fotoğraf"
                    name="petition"
                    class="form-control"
                    accept=".jpg,.jpeg,.png,.pdf,.SVG"
                    (change)="folderUpload4($event)"
                    [(ngModel)]="identityPetitionModalInput"
                    required
                  />

                  <small id="emailHelp" class="form-text text-muted"
                    >Dilekçeli Fotoğrafınızı seçerek tekrar yükleyiniz.
                  </small>
                </div>
              </form>
            </div>

            <div class="modal-footer">
              <button
              type="button"
              class="btn secondary-close"
              data-dismiss="modal"
              (click)="cancelModal()"
            >
              {{ "Close" | translate }}
            </button> &nbsp;
              <button
                type="button"
                class="btn orange-btn"
                (click)="onSavePetition()"
              >
                Tekrar Gönder
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Pasaport Ön Yüz -->
      <div
        class="modal fade"
        id="PasaportFrontModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="PasaportFrontModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h5
                class="modal-title text-center t-black"
                id="exampleModalLongTitle"
              >
                <b>Pasaport Ön Yüz Yükle </b>
              </h5>
            </div>
            <div class="modal-body">
              <form autocomplete="off">
                <div class="form-group">
                  <label for="PasaportFront"> Başvuru Durumu</label>
                  <input
                    type="text"
                    name="PasaportFront"
                    id="PasaportFront"
                    class="form-control"
                    value="{{ PasaportFront?.document_type }} - {{
                      PasaportFront?.status
                    }}"
                    disabled
                    readonly
                  />
                </div>

                <div class="form-group">
                  <label for="PasaportFront_description"> Red Nedeni</label>
                  <textarea
                    class="form-control"
                    id="PasaportFront_description"
                    rows="3"
                    value="{{ PasaportFront?.rejection_description }}"
                    disabled
                    readonly
                  ></textarea>
                </div>

                <div class="form-group">
                  <input
                    type="file"
                    title="Pasaport Ön Yüz"
                    name="PasaportFront"
                    class="form-control"
                    accept=".jpg,.jpeg,.png,.pdf,.SVG"
                    (change)="folderUpload5($event)"
                    required
                    [(ngModel)]="PasaportFrontModalInput"
                    required
                  />

                  <small id="emailHelp" class="form-text text-muted"
                    >Pasaport Ön Yüz dosyanızı seçerek tekrar yükleyiniz.
                  </small>
                </div>
              </form>
            </div>
            

            <div class="modal-footer">
              <button
              type="button"
              class="btn secondary-close"
              data-dismiss="modal"
              (click)="cancelModal()"
            >
              {{ "Close" | translate }}
            </button> &nbsp;
              <button
                type="button"
                class="btn orange-btn"
                (click)="onSavePasaportFront()"
              >
                Tekrar Gönder
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group mt-10">
        <div class="row text-center">
          <img
            src="../.././../assets/images/124.svg"
            alt=""
            style="width: 4%; height: 4%"
          />
        </div>
      </div>

      <!-- Başvuru Hareketleri -->
      <div
        class="row text-center mt-20"
        style="color: white; font-weight: 600; font-size: 15px"
      >
        Başvurunuz değerlendiriliyor, sizi başvuru süreciniz ile ilgili e-posta
        yoluyla bilgilendireceğiz.
      </div>

      <div class="row">
        <div id="parent" class="mt-30">
          <div id="child">
            <div
              class="text-center"
              style="color: #eb6e04; font-weight: 600; font-size: 17px"
            >
              Başvuru Hareketleri
            </div>

            <div
              class="mt-10"
              *ngIf="
                userdata?.identity_type == 1 || userdata?.identity_type == 3;
                else pasaport
              "
            >
              <!-- kimlik, adres vb -->
              <div class="row">
                <div class="col-md-12">
                  <table class="identityFront">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          class="text-left mediaClass mediaClassDate"
                          style="width: 42.3%"
                        >
                          Başvuru Tarihi
                        </th>
                        <th
                          scope="col"
                          class="text-left mediaClass"
                          style="width: 40%"
                        >
                          Belge Tipi
                        </th>
                        <th scope="col" class="text-left" style="width: 40%">
                          Başvuru Durumu
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-left mediaClass mediaClassDate">
                          {{
                            identityFront?.created_at | localizedDate
                          }}
                        </td>
                        <td class="text-left mediaClass">
                          {{ identityFront?.document_type }}
                        </td>
                        <td>
                          <table class="mediaClassDirection">
                            <tr class="typeView">
                              <td colspan="3" class="text-left">
                                <span
                                  >{{ identityFront?.document_type }} -</span
                                >

                                <span
                                  [ngClass]="
                                    identityFront?.status == 'Red'
                                      ? 'folder-red'
                                      : identityFront?.status == 'Onaylandı' ||
                                        identityFront?.status == 'Onaylandi'
                                      ? 'folder-success'
                                      : ''
                                  "
                                >
                                  {{ identityFront?.status }}</span
                                >
                              </td>
                              <td>
                                <i
                                  title="{{
                                    identityFront?.rejection_description
                                  }}"
                                  *ngIf="identityFront?.is_uploadable == true"
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span
                                  class="typeView2"
                                  [ngClass]="
                                    identityFront?.status == 'Red'
                                      ? 'folder-red'
                                      : identityFront?.status == 'Onaylandı' ||
                                        identityFront?.status == 'Onaylandi'
                                      ? 'folder-success'
                                      : ''
                                  "
                                >
                                  {{ identityFront?.status }}</span
                                >
                              </td>
                              <td style="width: 50px">
                                <!-- <i
                                  title="{{
                                    identityFront?.rejection_description
                                  }}"
                                  *ngIf="identityFront?.is_uploadable == true"
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i> -->
                              </td>
                              <td
                                class="text-center"
                                *ngIf="
                                  !identityFront ||
                                  identityFront?.is_uploadable == true
                                "
                              >
                                <button
                                  class="btn btn-sendFile"
                                  (click)="openIdentityFrontModal()"
                                >
                                  Düzenle
                                </button>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td class="text-left mediaClass mediaClassDate">
                          {{
                            identityBackFace?.created_at
                              | localizedDate
                          }}
                        </td>
                        <td class="text-left mediaClass">
                          {{ identityBackFace?.document_type }}
                        </td>
                        <td>
                          <table class="mediaClassDirection">
                            <tr class="typeView">
                              <td colspan="3" class="text-left">
                                <span
                                  >{{ identityBackFace?.document_type }} -</span
                                >

                                <span
                                  [ngClass]="
                                    identityBackFace?.status == 'Red'
                                      ? 'folder-red'
                                      : identityBackFace?.status ==
                                          'Onaylandı' ||
                                        identityBackFace?.status == 'Onaylandi'
                                      ? 'folder-success'
                                      : ''
                                  "
                                >
                                  {{ identityBackFace?.status }}</span
                                >
                              </td>
                              <td>
                                <i
                                  title="{{
                                    identityBackFace?.rejection_description
                                  }}"
                                  *ngIf="
                                    identityBackFace?.is_uploadable == true
                                  "
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span
                                  class="typeView2"
                                  [ngClass]="
                                    identityBackFace?.status == 'Red'
                                      ? 'folder-red'
                                      : identityBackFace?.status ==
                                          'Onaylandı' ||
                                        identityBackFace?.status == 'Onaylandi'
                                      ? 'folder-success'
                                      : ''
                                  "
                                >
                                  {{ identityBackFace?.status }}</span
                                >
                              </td>
                              <td style="width: 50px">
                                <!-- <i
                                  title="{{
                                    identityBackFace?.rejection_description
                                  }}"
                                  *ngIf="
                                    identityBackFace?.is_uploadable == true
                                  "
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i> -->
                              </td>
                              <td
                                class="text-center"
                                *ngIf="
                                  !identityBackFace ||
                                  identityBackFace?.is_uploadable == true
                                "
                              >
                                <button
                                  class="btn btn-sendFile"
                                  (click)="openBackFaceModal()"
                                >
                                  Düzenle
                                </button>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td class="text-left mediaClass mediaClassDate">
                          {{ invoice?.created_at | localizedDate }}
                        </td>
                        <td class="text-left mediaClass">
                          {{ invoice?.document_type }}
                        </td>
                        <td>
                          <table class="mediaClassDirection">
                            <tr class="typeView">
                              <td colspan="3" class="text-left">
                                <span>{{ invoice?.document_type }} - </span>

                                <span
                                  [ngClass]="
                                    invoice?.status == 'Red'
                                      ? 'folder-red'
                                      : invoice?.status == 'Onaylandı' ||
                                        invoice?.status == 'Onaylandi'
                                      ? 'folder-success'
                                      : ''
                                  "
                                >
                                  {{ invoice?.status }}</span
                                >
                              </td>
                              <td>
                                <i
                                  title="{{ invoice?.rejection_description }}"
                                  *ngIf="invoice?.is_uploadable == true"
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span
                                  class="typeView2"
                                  [ngClass]="
                                    invoice?.status == 'Red'
                                      ? 'folder-red'
                                      : invoice?.status == 'Onaylandı' ||
                                        invoice?.status == 'Onaylandi'
                                      ? 'folder-success'
                                      : ''
                                  "
                                >
                                  {{ invoice?.status }}</span
                                >
                              </td>
                              <td style="width: 50px">
                                <!-- <i
                                  title="{{ invoice?.rejection_description }}"
                                  *ngIf="invoice?.is_uploadable == true"
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i> -->
                              </td>
                              <td
                                class="text-center"
                                *ngIf="
                                  !invoice || invoice?.is_uploadable == true
                                "
                              >
                                <button
                                  class="btn btn-sendFile"
                                  (click)="openInvoiceModal()"
                                >
                                  Düzenle
                                </button>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td class="text-left mediaClass mediaClassDate">
                          {{ petition?.created_at | localizedDate }}
                        </td>
                        <td class="text-left mediaClass">
                          {{ petition?.document_type }}
                        </td>
                        <td>
                          <table class="mediaClassDirection">
                            <tr class="typeView">
                              <td colspan="3" class="text-left">
                                <span>{{ petition?.document_type }} - </span>
                                <span
                                  [ngClass]="
                                    petition?.status == 'Red'
                                      ? 'folder-red'
                                      : petition?.status == 'Onaylandı' ||
                                        petition?.status == 'Onaylandi'
                                      ? 'folder-success'
                                      : ''
                                  "
                                >
                                  {{ petition?.status }}</span
                                >
                              </td>
                              <td>
                                <i
                                  title="{{ petition?.rejection_description }}"
                                  *ngIf="petition?.is_uploadable == true"
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span
                                  class="typeView2"
                                  [ngClass]="
                                    petition?.status == 'Red'
                                      ? 'folder-red'
                                      : petition?.status == 'Onaylandı' ||
                                        petition?.status == 'Onaylandi'
                                      ? 'folder-success'
                                      : ''
                                  "
                                >
                                  {{ petition?.status }}</span
                                >
                              </td>
                              <td style="width: 50px">
                                <!-- <i
                                  title="{{ petition?.rejection_description }}"
                                  *ngIf="petition?.is_uploadable == true"
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i> -->
                              </td>
                              <td
                                class="text-center"
                                *ngIf="
                                  !petition || petition?.is_uploadable == true
                                "
                              >
                                <button
                                  class="btn btn-sendFile"
                                  (click)="openPetitionModal()"
                                >
                                  Düzenle
                                </button>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <!-- template pasaport -->
            <ng-template #pasaport>
              <div class="row">
                <div class="col-md-12">
                  <table class="identityFront">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          class="text-left mediaClass mediaClassDate"
                          style="width: 42.3%"
                        >
                          Başvuru Tarihi
                        </th>
                        <th
                          scope="col"
                          class="text-left mediaClass"
                          style="width: 40%"
                        >
                          Belge Tipi
                        </th>
                        <th scope="col" class="text-left" style="width: 40%">
                          Başvuru Durumu
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-left mediaClass mediaClassDate">
                          {{
                            PasaportFront?.created_at | localizedDate
                          }}
                        </td>
                        <td class="text-left mediaClass">
                          {{ PasaportFront?.document_type }}
                        </td>
                        <td>
                          <table class="mediaClassDirection">
                            <tr class="typeView">
                              <td colspan="3" class="text-left">
                                <span
                                  >{{ PasaportFront?.document_type }} -
                                </span>
                                <span
                                  [ngClass]="
                                    PasaportFront?.status == 'Red'
                                      ? 'folder-red'
                                      : PasaportFront?.status == 'Onaylandı' ||
                                        PasaportFront?.status == 'Onaylandi'
                                      ? 'folder-success'
                                      : ''
                                  "
                                >
                                  {{ PasaportFront?.status }}</span
                                >
                              </td>
                              <td>
                                <i
                                  title="{{
                                    PasaportFront?.rejection_description
                                  }}"
                                  *ngIf="PasaportFront?.is_uploadable == true"
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span
                                  [ngClass]="
                                    PasaportFront?.status == 'Red'
                                      ? 'folder-red'
                                      : PasaportFront?.status == 'Onaylandı' ||
                                        PasaportFront?.status == 'Onaylandi'
                                      ? 'folder-success'
                                      : ''
                                  "
                                  class="typeView2"
                                  >{{ PasaportFront?.status }}</span
                                >
                              </td>
                              <td style="width: 50px">
                                <!-- <i
                                  title="{{
                                    PasaportFront?.rejection_description
                                  }}"
                                  *ngIf="PasaportFront?.is_uploadable == true"
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i> -->
                              </td>
                              <td
                                class="text-center"
                                *ngIf="
                                  !PasaportFront ||
                                  PasaportFront?.is_uploadable == true
                                "
                              >
                                <button
                                  class="btn btn-sendFile"
                                  (click)="openPasaportFrontModal()"
                                >
                                  Düzenle
                                </button>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td class="text-left mediaClass mediaClassDate">
                          {{ invoice?.created_at | localizedDate }}
                        </td>
                        <td class="text-left mediaClass">
                          {{ invoice?.document_type }}
                        </td>
                        <td>
                          <table class="mediaClassDirection">
                            <tr class="typeView">
                              <td colspan="3" class="text-left">
                                <span>{{ invoice?.document_type }} - </span>

                                <span
                                  [ngClass]="
                                    invoice?.status == 'Red'
                                      ? 'folder-red'
                                      : invoice?.status == 'Onaylandı'
                                      ? 'folder-success'
                                      : ''
                                  "
                                >
                                  {{ invoice?.status }}</span
                                >
                              </td>
                              <td>
                                <i
                                  title="{{ invoice?.rejection_description }}"
                                  *ngIf="invoice?.is_uploadable == true"
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span
                                  [ngClass]="
                                    invoice?.status == 'Red'
                                      ? 'folder-red'
                                      : invoice?.status == 'Onaylandı'
                                      ? 'folder-success'
                                      : ''
                                  "
                                  class="typeView2"
                                  >{{ invoice?.status }}</span
                                >
                              </td>
                              <td style="width: 50px">
                                <!-- <i
                                  title="{{ invoice?.rejection_description }}"
                                  *ngIf="invoice?.is_uploadable == true"
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i> -->
                              </td>
                              <td
                                class="text-center"
                                *ngIf="
                                  !invoice || invoice?.is_uploadable == true
                                "
                              >
                                <button
                                  class="btn btn-sendFile"
                                  (click)="openInvoiceModal()"
                                >
                                  Düzenle
                                </button>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td class="text-left mediaClass mediaClassDate">
                          {{ petition?.created_at | localizedDate }}
                        </td>
                        <td class="text-left mediaClass">
                          {{ petition?.document_type }}
                        </td>
                        <td>
                          <table class="mediaClassDirection">
                            <tr class="typeView">
                              <td colspan="3" class="text-left">
                                <span>{{ petition?.document_type }} -</span>

                                <span
                                  [ngClass]="
                                    petition?.status == 'Red'
                                      ? 'folder-red'
                                      : petition?.status == 'Onaylandı' ||
                                        petition?.status == 'Onaylandi'
                                      ? 'folder-success'
                                      : ''
                                  "
                                >
                                  {{ petition?.status }}</span
                                >
                              </td>
                              <td>
                                <i
                                  title="{{ petition?.rejection_description }}"
                                  *ngIf="petition?.is_uploadable == true"
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span
                                  class="typeView2"
                                  [ngClass]="
                                    petition?.status == 'Red'
                                      ? 'folder-red'
                                      : petition?.status == 'Onaylandı' ||
                                        petition?.status == 'Onaylandi'
                                      ? 'folder-success'
                                      : ''
                                  "
                                >
                                  {{ petition?.status }}</span
                                >
                              </td>
                              <td style="width: 50px">
                                <!-- <i
                                  title="{{ petition?.rejection_description }}"
                                  *ngIf="petition?.is_uploadable == true"
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i> -->
                              </td>
                              <td
                                class="text-center"
                                *ngIf="
                                  !petition || petition?.is_uploadable == true
                                "
                              >
                                <button
                                  class="btn btn-sendFile"
                                  (click)="openPetitionModal()"
                                >
                                  Düzenle
                                </button>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SupportTicketService } from 'src/app/Services/support-ticket.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent implements OnInit {
  public ticketid = null;
  public success = null;
  public error = null;
  public data = null;
  public subject = null;
  public ticketmessage = null;
  public chats = [];
  private ticketChatSubscription: Subscription | undefined;
  isDisabled = false;
  routeParams: Params;
  queryParams: Params;

  constructor(
    private support: SupportTicketService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.getRouteParams();
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    if (this.ticketChatSubscription) {
      this.ticketChatSubscription.unsubscribe();
    }
  }

  getRouteParams() {
    // Route parameters
    this.route.params.subscribe((params) => {
      this.ticketid = this.route.snapshot.paramMap.get('ticketid');
      this.getticketmessage(this.ticketid);
    });
  }

  getticketmessage(ticketid) {
    const myFormData = new FormData();
    myFormData.append('ticketid', ticketid);
    this.ticketChatSubscription = this.support
      .viewTicketChat(myFormData)
      .subscribe(
        (data) => this.handleTicketDetails(data),
        (error) => this.handleError(error)
      );
  }

  handleTicketDetails(res) {
    if (res.success) {
      this.data = res.result;
      this.subject = this.data.subject;
      this.ticketmessage = this.data.message;
      this.chats = this.data.chat;
    } else {
      this.error = res.message;
      this.router.navigateByUrl('/destek');
    }
  }


  public form = {
    message: null,
  };

  
  onSubmit() {
    this.isDisabled = true;
    this.error = null;
    this.success = null;
    const myFormData = new FormData();
    myFormData.append('ticketid', this.ticketid);
    myFormData.append('message', this.form.message);
    return this.support.replyTicketChat(myFormData).subscribe(
      (data) => this.handleResponse(data),
      (error) => this.handleError(error)
    );
  }

  handleResponse(res) {
    this.isDisabled = false;
    if (res.success) {
      this.success = res.message;
      this.getRouteParams();
      (<HTMLFormElement> document.getElementById('chatbox')).reset();
    } else {
      this.error = res.message;
    }
  }

  handleError(error) {
    this.error = error.message;
  }
}

<!-- Login pages -->
<app-header> </app-header>
<article class="innerpagearticle">
  <section class="innerbannerpage">
    <div class="container mt-15">
      <!-- hidden  success error -->
      <div class="form-group">
        <app-alert-modal
          *ngIf="error"
          [error]="error"
          (close)="error = null"
        ></app-alert-modal>

        <!-- <div class="text-danger text-center" [hidden]="!success">
          {{ success }}
        </div> -->
      </div>

      <!-- loginHeader -->
      <table id="loginHeader">
        <tbody>
          <tr>
            <td style="text-align: center; height: 35px">
              <img
                src="assets/images/071.svg"
                alt=""
                class="text-center"
                style="width: 17px; margin-right: 5px;"

              />
            </td>
          </tr>
          <tr>
            <td style="text-align: center">
              <img
                alt=""
                src="assets/images/070.svg"
                class="text-center"
                style="width: 24px; vertical-align: sub"
              />
              &nbsp;&nbsp;
              <small> {{ "Giriş Yap" | translate }}</small>
            </td>
          </tr>
          <tr>
            <td colspan="3" style="height: 25px">
              <hr />
            </td>
          </tr>
        </tbody>
      </table>

      <div class="no-gutters mt-130">
        <!-- güvenle bitcoin -->
        <div class="col-6 col-md-6 line">
          <h3 class="fnt-reg h3 order-2 banner-bg-h3">
            TÜRKİYE'NİN 
          </h3>
          <h2 class="h2 fnt-reg t-blue order-3 banner-bg-h2">
            {{ "KRİPTO VARLIK ALIM SATIM PLATFORMU" | translate }}
          </h2>
          <h1
            class="fnt-bld h1 t-white mb-2x order-4 margin"
            style="font-size: 58px; line-height: 1.1"
          >
            {{ "GÜVENLE BITCOIN" | translate }}

            <br />

            {{ "ALIN SATIN!" | translate }}
          </h1>
          <div class="row">
            <div class="col-md-6">
              <ul>
                <li>{{ "Güçlü platform altyapısı" | translate }} &nbsp;</li>
              </ul>
            </div>
            <div class="col-md-6">
              <ul>
                <li>Gelişmiş analiz araçları</li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <ul>
                <li>{{ "Gerçek zamanlı grafikler" | translate }}</li>
              </ul>
            </div>
            <div class="col-md-5">
              <ul>
                <li>{{ "Güvenli, kesintisiz erişim" | translate }}</li>
              </ul>
            </div>
          </div>
          <div class="row" style="height: 40px">
            <div class="col-md-4"></div>
          </div>
          <br />

          <div style="height: 60px" class="row-style t-white">
            <b
              >&nbsp;{{
                "Profesyoneller için çok hızlı bir alım satım deneyimi sunuyoruz."
                  | translate
              }}</b
            >
          </div>
        </div>

        <!-- login form -->
        <div class="col-6 col-md-5 style-col-md-5">
          <form class="" autocomplete="off" (ngSubmit)="onSubmit()">
            <!-- email -->
            <div class="form-group mt-20">
              <input
                type="email"
                id="email"
                name="email"
                #email="ngModel"
                [(ngModel)]="loginForm.email"
                placeholder="Email Adresiniz / TC Kimlik Numaranız*"
                class="form-control"
                [class.is-invalid]="email.invalid && email.touched"
                pattern=""
                [email]="loginForm.email !== ''"
                required
              />
              <span *ngIf="email.errors && email.invalid && email.touched">
                <small
                  class="text-danger-input"
                  *ngIf="email.errors?.required && email.touched"
                >
                  {{ "Email adresinizi giriniz!" | translate }}
                </small>
              </span>
            </div>

            <!-- password -->
            <div class="form-group mt-20">
              <input
                type="password"
                [type]="hide ? 'password' : 'text'"
                id="upassword"
                name="password"
                #password="ngModel"
                [(ngModel)]="loginForm.password"
                placeholder="Şifreniz*"
                class="form-control"
                [class.is-invalid]="password.invalid && password.touched"
                required
              />

              <span class="p-viewer">
                <i class="material-icons" matSuffix (click)="hide = !hide">{{
                  hide ? "visibility_off" : "visibility"
                }}</i>
              </span>

              <span
                *ngIf="password.errors && password.invalid && password.touched"
              >
                <small
                  class="text-danger-input"
                  *ngIf="password.errors.required"
                >
                  {{ "Şifrenizi giriniz!" | translate }}</small
                >
              </span>
            </div>

            <!-- recaptcha -->
            <div class="form-group text-center mt-30">
              <re-captcha
                [(ngModel)]="loginForm.recaptcha"
                name="recaptcha"
                #recaptcha="ngModel"
                required
              ></re-captcha>
              <span
                class="text-center"
                *ngIf="
                  recaptcha.errors && recaptcha.invalid && recaptcha.touched
                "
              >
                <small *ngIf="recaptcha.errors.required"
                  >Recaptcha {{ "required" | translate }}.</small
                >
              </span>
            </div>

            <!-- buttons -->
            <div class="form-group mt-30">
              <div class="text-center">
                <input
                  type="submit"
                  name="submit"
                  value="{{ 'Giriş Yap' | translate }}"
                  class="btn orange-btn"
                  *ngIf="!isDisabled"
                />
                <input
                  type="submit"
                  name="submit"
                  value="{{ 'Lütfen Bekleyin' | translate }}..."
                  class="btn orange-btn"
                  disabled
                  *ngIf="isDisabled"
                />
              </div>
            </div>

            <!-- ForgotPassword -->
            <div class="mt-20 btnsnfg text-center">
              <a routerLink="/sifremi-unuttum">{{
                "Şifremi Unuttum!" | translate
              }}</a>

              <h6 class="t-white mt-30">
                {{ "Hesabınız Yok mu?" | translate }} <br />
                <u class="t-blue">
                  <a routerLink="/hesap-olustur">{{
                    "Hesap Oluştur" | translate
                  }}</a></u
                >
              </h6>
            </div>
          </form>
        </div>
      </div>
    </div>
    <br />
    <br />
  </section>
</article>
<app-footer></app-footer>

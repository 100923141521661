<app-header-empty></app-header-empty>
<article class="innerpagearticle">
  <section class="innerbannerpage">
    <div class="container">
      <div class="row">
        <!-- smsVerification -->
        <table id="smsVerification">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 17px; margin-right: 100px"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  alt=""
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                />
                &nbsp;&nbsp;
                <small> ikili Doğrulama</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div id="parent" class="mt-30">
        <div id="child">
          <div
            class="row"
            *ngFor="let question of twofauserdata; let i = index"
          >
            <div
              id="twofa_form_{{ question.twofa_id }}"
              [ngStyle]="{ display: Twodfa[i] }"
              class="mt-5"
            >
              <!-- form -->
              <form autocomplete="off" class="twoFactorsLogin">
                <div class="mb-25">
                  <div
                    class="form-group text-center"
                    *ngIf="question.twofa_id == 1"
                  >
                    <!-- <b>**{{ twofaDetail?.phone | slice : 6 : 10 }} </b>numaralı
                    telefonunuza gönderilen 6 haneli kodu giriniz. -->

                    <b>{{ userdata?.phone_no }} </b>numaralı telefonunuza
                    gönderilen 6 haneli kodu giriniz
                  </div>

                  <div
                    class="form-group text-center"
                    *ngIf="question.twofa_id == 2"
                  >
                    <b> {{ userdata?.email }}</b> adresine gönderilen 6 haneli
                    kodu giriniz
                  </div>

                  <div
                    class="form-group text-center"
                    *ngIf="question.twofa_id == 3"
                  >
                    6 haneli<b> 2FA Google </b> kodunu giriniz
                  </div>
                </div>

                <!-- input -->
                <div class="form-group">
                  <input
                    type="text"
                    data-role="input"
                    name="validationCode"
                    class="form-control"
                    id="google_code"
                    aria-describedby="smsCode"
                    placeholder="6 haneli {{
                      question.twofa_name
                    }} kodunu giriniz"
                    [ngModelOptions]="{ standalone: true }"
                    onkeyup="if(this.value.length == 6) this.blur()"
                    #validationCode="ngModel"
                    [(ngModel)]="newvalidationCode"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                    (input)="
                      onInputChange(
                        $event,
                        question.twofa_id,
                        validationCode,
                        i
                      )
                    "
                    (keydown.enter)="
                      allAction(question.twofa_id, validationCode, i)
                    "
                    maxlength="6"
                    minlength="6"
                    [disabled]="allActionDisabled[question.twofa_id]"
                    required
                    autofocus
                  />

                  <input
                    type="button"
                    class="verify-button"
                    value=" Onayla"
                    [disabled]="
                      (newvalidationCode && newvalidationCode.length < 6) ||
                      newvalidationCode === undefined ||
                      newvalidationCode == ''
                    "
                  />
                  <!--                 
                  <input
                    type="button"
                    class="verify-button"
                    value=" Onayla"
                    (click)="allAction(question.twofa_id, validationCode, i)"
                    [disabled]="
                      (newvalidationCode && newvalidationCode.length < 6) ||
                      newvalidationCode === undefined ||
                      newvalidationCode == ''
                    "
                  /> -->

                  <div
                    class="form-group text-center mt-30"
                    *ngIf="question.twofa_id != 3"
                  >
                    <button
                      type="button"
                      class="btn btn-link text-right"
                      (click)="allActionRT(question.twofa_id, 'resentCode')"
                    >
                      Kodu Tekrar Gönder
                    </button>
                  </div>
                </div>

                <!-- messages -->
                <div class="mt-21 text-center">
                  <div
                    class="alert-danger"
                    [hidden]="!errorText[question.twofa_id]"
                  >
                    {{ errorText[question.twofa_id] }}
                  </div>
                  <div
                    class="alert-success"
                    [hidden]="!successText[question.twofa_id]"
                  >
                    {{ successText[question.twofa_id] }}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

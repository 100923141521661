import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: 'app-fsq',
  templateUrl: './fsq.component.html',
  styleUrls: ['./fsq.component.css']
})
export class FSQComponent implements OnInit {

  constructor(private metaService: Meta, private titleService: Title) {}

  ngOnInit() {
    // Meta etiketlerini ayarla
    this.metaService.addTags([
      {
        name: "description",
        content:
          "Kripto Paralar hakkında sıkça sorulan sorular ve yanıtları",
      },
      {
        name: "keywords",
        content: "Sıkça Sorulan Sorular",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);

    const pageTitle = 'Bitistanbul | Sıkça Sorulan Sorular';
    this.titleService.setTitle(pageTitle);
    
  }

}
<!-- Bitcoin Nasıl Alınır? Pages -->
<app-header></app-header>
<article>
  <section class="innerbannerpage">
    <div class="container">
      <div class="row">
        <table id="how-to-buy-bitcoin-header">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: 130px"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                  alt=""
                />
                &nbsp;&nbsp;
                <small> {{ "HowToBuyBitcoin" | translate }}?</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row row-style">
        <ul>
          <li>
            Bitcoin alım satımını Bitistanbul üzerinden kolayca ve hızlı bir
            şekilde yapabilirsiniz. Bitcoin alımı yapabilmek için öncelikle bir
            Bitistanbul hesabına sahip olmanız gerekir. Hesabınız yoksa
            Bitistanbul anasayfasındaki <b>“Hesap Oluştur”</b> alanından
            istenilen bilgileri sağlayarak Bitistanbul’da hesap açabilirsiniz.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            Kullanıcı adınız, şifreniz ve 2 aşamalı doğrulama kodunu girdikten
            sonra Bitistanbul’daki alım satım ekranına ulaşırsınız. Bu ekranda
            en rekabetçi fiyatlarla ve derinlikli işlem hacimleriyle kripto
            varlıklarınızı gerçek zamanlı olarak uluslararası piyasalarla uyumlu
            bir şekilde direkt veya limit emir kullanarak kolayca alabilir
            satabilirsiniz.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            Kimlik bilgileri doğrulanmış tüm Bitistanbul kullanıcıları cüzdanlarına kolaylıkla Türk Lirası ve kripto yatırabilir. FAST ile 100.000 TL’ye kadar olan Türk Lirası yatırma işlemlerinizi dilediğiniz zaman, dilediğiniz bankadan gerçekleştirebilirsiniz.
            Bitistanbul cüzdanınıza Vakıfbank ve Vakıfkatılım üzerinden 7/24 Türk Lirası yatırabilirsiniz.
            <br> Diğer bankalardan yapacağınız,
            100,000.00 TL’nin üzerinde Türk Lirası yatırma işlemleri iş günlerinde 09:00 -17:00 saatleri arasında Bitistanbul cüzdanınıza yansıyacaktır.
          </li>
        </ul>
      </div>
      <div class="row row-style">
        <ul>
          <li>
            Türk lirası transferleriniz hesabınıza kısa süre içinde
            yansıtıldıktan sonra, Bitistanbul alım satım ekranından kolayca ve
            hızlıca Bitcoin ve diğer kripto varlıkları alabilirsiniz.
          </li>
        </ul>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

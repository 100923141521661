import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
@Injectable({
  providedIn: "root",
})
export class SharedService {
  constructor() {}

  withdraw: any;
  _withdrawValue = new BehaviorSubject<[]>([]);
  withdrawFormData(val) {
    this.withdraw = val;
    this._withdrawValue.next(val);
  }

  shared_action_keys: any = {};
  setSharedActionKeys(endpoint, val) {
    this.shared_action_keys[endpoint] = val;
    //this.shared_action_keys = val;
  }
}

<!-- Bitcoin Nasıl Alınır? Pages -->
<app-header></app-header>

<article>
  <section class="innerbannerpage">
    <div class="container">
      <div class="row">
        <table id="how-to-buy-bitcoin-header">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: 515px"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                  alt=""
                />
                &nbsp;&nbsp;
                <small>
                  Hotto Kripto Varlık Alım Satım Platformu A.Ş. Bitistanbul Çerez Politikası
                </small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row row-style">
        <p>
          İnternet sitemizden verimli şekilde faydalanabilmeniz ve kullanıcı
          deneyiminizi geliştirebilmek için Çerez kullanıyoruz. Çerez
          kullanılmasını tercih etmemeniz halinde tarayıcınızın ayarlarından
          Çerezleri silebilir ya da engelleyebilirsiniz. Ancak bunun internet
          sitemizi kullanımınızı etkileyebileceğini lütfen aklınızda
          bulundurunuz. Tarayıcınızdan Çerez ayarlarınızı değiştirmediğiniz
          sürece bu sitede çerez kullanımını kabul ettiğinizi varsayacağız.
        </p>
        <br />
        <h5>Çerez Nedir Ve Neden Kullanılır ?</h5>

        <p>
          Çerezler, ziyaret ettiğiniz internet siteleri tarafından tarayıcılar
          aracılığıyla cihazınıza veya ağ sunucusuna depolanan küçük metin
          dosyalarıdır. Çerezler konusundan daha detaylı bilgi için
          <b></b>
          <a href="https://www.aboutcookies.org/" target="_blank" rel="noopener"
            ><b>www.aboutcookies.org</b></a
          >

          veya

          <a href="https://allaboutcookies.org/" target="_blank" rel="noopener"
            ><b>www.allaboutcookies.org</b></a
          >
          adreslerini ziyaret edebilirisiniz. İnternet Sitemizde çerez
          kullanılmasının başlıca amaçları aşağıda sıralanmaktadır:
        </p>
        <br />
        <ul>
          <li>
            İnternet sitesinin işlevselliğini ve performansını arttırmak yoluyla
            sizlere sunulan hizmetleri geliştirmek,
          </li>
        </ul>
        <br />
        <ul>
          <li>
            İnternet Sitesini iyileştirmek ve İnternet Sitesi üzerinden yeni
            özellikler sunmak ve sunulan özellikleri sizlerin tercihlerine göre
            kişiselleştirmek;
          </li>
        </ul>
        <br />
        <ul>
          <li>
            İnternet Sitesinin, sizin ve Bitİstanbul’un hukuki ve ticari
            güvenliğini sağlamak.
          </li>
        </ul>
        <br />
        <h5>İnternet Sitemizde Kullanılan Çerez Türleri</h5>
        <h6>Zorunlu Çerezler (Mandatory Cookies)</h6>
        <p>
          Bu çerezler, İnternet Sitesi’nin düzgün şekilde çalışması için mutlaka
          gerekli olan çerezlerdir. Bu çerezlere, sistemin yönetilebilmesi,
          sahte işlemlerin önlenmesi için ihtiyaç vardır ve engellenmesi halinde
          internet Sitesi çalışamayacaktır.
        </p>
        <br />
        <h6>Oturum Çerezleri (Session Cookies)</h6>
        <p>
          Oturum çerezleri ziyaretçilerimizin İnternet Sitemizi ziyaretleri
          süresince kullanılan tarayıcı kapatıldıktan sonra silinen geçici
          çerezlerdir. Bu tür çerezlerin kullanılmasının temel amacı ziyaretiniz
          süresince <b>Bitİstanbul</b> İnternet Sitesinin düzgün bir biçimde
          çalışmasını sağlamaktır. Örneğin; birden fazla sayfadan oluşan
          çevrimiçi formları doldurmanız sağlanmaktadır.
        </p>
        <br />
        <h6>Kalıcı Çerezler (Persistent Cookies)</h6>
        <p>
          Kalıcı çerezler <b>Bitİstanbul</b> İnternet Sitesinin işlevselliğini
          artırmak, ziyaretçilerimize daha hızlı ve iyi bir hizmet sunmak
          amacıyla kullanılan çerez türleridir. Bu tür çerezler tercihlerinizi
          hatırlamak için kullanılır ve tarayıcılar vasıtasıyla cihazınızda
          depolanır.
        </p>
        <br />
        <p>
          Kalıcı çerezlerin bazı türleri; internet sitesi kullanım amacınız gibi
          hususlar göz önünde bulundurarak sizlere özel öneriler sunulması için
          de kullanılabilmektedir. Tarayıcınızda saklanan ve tarafınızdan
          silininceye dek geçerliliğini koruyan çerezlerdir.
        </p>
        <br />
        <p>
          Kalıcı çerezler sayesinde İnternet Sitemizi aynı cihazla tekrar
          ziyaret etmeniz durumunda, cihazınızda <b>Bitİstanbul</b> İnternet
          Sitesi tarafından oluşturulmuş bir çerez olup olmadığı kontrol edilir
          ve var ise, sizin siteyi daha önce ziyaret ettiğiniz anlaşılır ve size
          iletilecek içerik bu doğrultuda belirlenir ve böylelikle sizlere daha
          iyi bir hizmet sunulur.
        </p>
        <br />
        <h6>Kişiselleştirme Çerezleri (Customization Cookies)</h6>
        <p>
          Kullanıcıların tercihlerini farklı internet sitesinin farklı
          sayfalarını ziyarette de hatırlamak için kullanılan çerezlerdir.
          Örneğin, seçmiş olduğunuz dil tercihinizin hatırlanması.
        </p>
        <br />
        <h6>Analitik Çerezler (Analytical Cookies)</h6>
        <p>
          Analitik çerezler ile İnternet Sitesini ziyaret edenlerin sayıları,
          Sitede görüntülenen sayfaların tespiti, ziyaret saatleri, sayfaları
          kaydırma hareketleri gibi analitik sonuçların üretimini sağlayan
          çerezlerdir.
        </p>
        <br />
        <h5>Çerezlerin Kullanımı engellenebilir mi?</h5>
        <p>
          Tarayıcınızın ayarlarını değiştirerek çerezlere ilişkin tercihlerinizi
          kişiselleştirme imkânına sahipsiniz.
        </p>
        <br />
        <h5>Çerezlerin Kullanılmasını Nasıl Engelleyebilirsiniz?</h5>
        <p>
          Aşağıdaki yöntemleri kullanarak çerezlere izin verebilir ya da
          reddedebilirsiniz. Çerezlerin kullanılmasını engellemek için
          kullandığınız internet tarayıcınızın ayarlarınızı değiştirmeniz
          gerekmektedir.
        </p>
        <br />
        <p>
          Bu değişiklikler kullandığınız cihaz ve internet tarayıcısına göre
          değişiklik göstermektedir. Aşağıda farklı internet tarayıcıları
          üzerinden çerezlerin kullanılmasını engellemek için hangi adımların
          izlenmesi gerektiğine ilişkin bilgiler yer almaktadır:
        </p>
        <br />
        <h6>Google Chrome</h6>
        <p>
          Bilgisayarınızda Chrome tarayıcısını açın.
          <br />
          Sağ üstte Diğer Ayarlar'ı tıklayın.
          <br />
          Altta Gelişmiş'i tıklayın.
          <br />
          "Gizlilik ve Güvenlik"in altında İçerik Ayarları'nı tıklayın.
          <br />
          Çerezler'i tıklayın. "Tüm çerezler ve site verileri"nin altında Web
          Sitesi'nin adını arayın. Sitenin sağındaki Kaldır simgesini tıklayın.
          <br />
          Chrome'daki çerezleri silme, etkinleştirme ve yönetme gibi detaylı
          bilgiler için
          <a
            href="https://support.google.com/chrome/answer/95647"
            target="_blank"
            rel="noopener"
          >
            <b> https://support.google.com/chrome/answer/95647 </b>
          </a>
          adresini ziyaret edebilirsiniz.
        </p>
        <br />
        <h6>Mozilla Firefox</h6>
        <p>
          Firefox Menü düğmesine tıklayın ve Seçenekler'i seçin.
          <br />
          Gizlilik ve Güvenlik panelini seçin ve Geçmiş bölümüne gidin.
          <br />
          Firefox ayarını geçmiş için özel ayarları kullansın olarak değiştirin.
          <br />
          "Çerezleri göster" düğmesine tıklayın. Çerezler penceresi
          görünecektir. Arama alanına, çerezlerini silmek istediğiniz sitenin
          adını yazın.
          <br />
          Aramanızla eşleşen çerezler görüntülenecektir.
          <br />
          Silmek istediğiniz çerez(ler)i seçip Seçilenleri sil düğmesine
          tıklayın. Kapat düğmesine tıklayarak Çerezler penceresini kapatın.
          Sonra da about: preferences sayfasını kapatın.
          <br />
          Mozilla Firefox yardım dökümanlarında "Çerezler - Web sitelerinin
          bilgisayarınızda depoladığı bilgiler" başlığını incelemek için <br />
          <a
            href="https://support.mozilla.org/tr/kb/cerezler-web-sitelerinin-bilgisayarinizda-depoladi"
            target="_blank"
            rel="noopener"
          >
            <b>
              https://support.mozilla.org/tr/kb/cerezler-web-sitelerinin-bilgisayarinizda-depoladi</b
            ></a
          >
          adresini ziyaret edebilirsiniz.
        </p>

        <br />
        <h6>Safari</h6>
        <p>
          Safari > Tercihler'i seçin. Gizlilik öğesini tıklayın.<br />
          Web Sitesi Verilerini tıklayın. <br />Bir veya daha fazla web sitesi
          seçin ve sonra Sil veya Tümünü Sil'e tıklayın.
          <br />
          Mac'teki Safari'de çerezleri ve web sitesi verilerini yönetme için
          <a
            href="https://support.apple.com/tr-tr/guide/safari/sfri11471/mac"
            target="_blank"
            rel="noopener"
            ><b
              >https://support.apple.com/tr-tr/guide/safari/sfri11471/mac</b
            ></a
          >
          adresini ziyaret edebilirsiniz.
        </p>

        <br />
        <h6>Microsoft Edge</h6>
        <p>
          Microsoft Edge tarayıcınızın sağ üst köşesinden üç nokta işareti olan
          bölüme tıklayın ve Ayarlar bölümüne gelin.
          <br />
          Karşınıza gelen yeni pencereden Temizlenecek Öğeleri Seç dedikten
          sonra karşınıza gelen pencereden temizlemek istediğiniz bölümleri
          seçin.
          <br />
          Burada birçok bölüm bulunmaktadır. İstediğinizi seçerek temizleme
          işlemine başlayabilirsiniz.
          <br />
          <br />
          Microsoft Edge, göz atma verileri ve gizlilik hakkında daha detaylı
          bilgi için
          <a
            href="https://support.microsoft.com/tr-tr/help/4468242/microsoft-edge-browsing-data-and-privacy"
            target="_blank"
            rel="noopener"
          >
            <b>
              https://support.microsoft.com/tr-tr/help/4468242/microsoft-edge-browsing-data-and-privacy</b
            >
          </a>
          adresini ziyaret edebilirsiniz.
        </p>
        <br />

        <p>
          Kişisel verilerinizin işlenmesine ilişkin detaylı bilgilere
          <a href="" target="_blank"><b>buradan</b></a>
          ulaşabilirsiniz.
        </p>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

<div class="backdrop"></div>
<div class="alert-box">
  <div class="modal-body tex-center">
    <div class="success-checkmark">
      <div class="check-icon">
        <span class="icon-line line-tip"></span>
        <span class="icon-line line-long"></span>
        <div class="icon-circle"></div>
        <div class="icon-fix"></div>
      </div>
    </div>
    <p>{{ success }}</p>
  </div>

  <div class="modal-footer tex-center">
    <button type="submit" class="btn orange-btn-lgn" (click)="onCloseClick()">
      Kapat
    </button>
  </div>
</div>

import { Output, EventEmitter, OnInit, Directive } from '@angular/core';
import { environment } from 'src/environments/environment';

@Directive()
export class GlobalConstants {
  static changeModeEvent: EventEmitter<any> = new EventEmitter();

  public static Company;
  public static token;
  public static email;

  public static WalletInfo = {
    email: null,
    balance: '0.00000000',
    pending: '0.00000000',
    balanceStr: '0.00000000',
    pendingStr: '0.00000000',
    address: [],
  };

  public static setWalletInfo(obj) {
    this.WalletInfo.email = obj.email;
    this.WalletInfo.balance = obj.balance;
    this.WalletInfo.pending = obj.pending;
    this.WalletInfo.balanceStr = obj.balanceStr;
    this.WalletInfo.pendingStr = obj.pendingStr;
    this.WalletInfo.address = obj.address;
  }

  public static TransferDetail = {
    minTransfer: null,
    maxTransfer: null,
    commissionRate: null,
    commissionAmount: null,
    feeAmount: null,
    commissionType: null,
    feeType: null,
    currentCommissionValue: null,
    currentFeeValue: null,
    label: null,
  };

  public static setCompany(obj) {
    this.Company = obj;

    this.TransferDetail.minTransfer = GlobalConstants.fixCoin(
      this.Company.minTransfer
    );
    this.TransferDetail.maxTransfer = GlobalConstants.fixCoin(
      this.Company.maxTransfer
    );
    this.TransferDetail.commissionRate = this.Company.commissionRate;
    this.TransferDetail.commissionAmount = GlobalConstants.fixCoin(
      this.Company.commissionAmount
    );
    this.TransferDetail.feeAmount = this.Company.feeAmount;
    this.TransferDetail.commissionType = this.Company.commissionType;
    this.TransferDetail.feeType = this.Company.feeType;

    let txtCommType = null;
    let txtFeeType = null;

    if (this.Company.commissionType === 1) {
      txtCommType = 'Percent Commission';
      this.TransferDetail.currentCommissionValue =
        this.Company.commissionRate + '%';
    } else if (this.Company.commissionType === 2) {
      txtCommType = 'Fixxed Commission';
      this.TransferDetail.currentCommissionValue =
        this.Company.commissionAmount + ' Coin)';
    } else {
      txtCommType = 'Commission Type Unknow';
      this.TransferDetail.currentCommissionValue = '0.00000000 Coin';
    }

    if (this.Company.feeType === 1) {
      txtFeeType = 'System Fee';
      this.TransferDetail.currentFeeValue = this.Company.feeAmount + ' Coin)';
    } else if (this.Company.feeType === 2) {
      txtFeeType = 'Network Fee';
      this.TransferDetail.currentFeeValue = this.Company.feeAmount + ' Coin)';
    } else {
      txtFeeType = 'Fee Type Unknow';
      this.TransferDetail.currentCommissionValue = '0.00000000 Coin';
    }

    this.TransferDetail.label = txtCommType + '/' + txtFeeType;
  }

  public static getCommission(amount) {
    if (this.Company.commissionType === 1) {
      this.TransferDetail.currentCommissionValue =
        this.Company.commissionRate + '%';

      const commsv = GlobalConstants.fixCoin(
        (amount / 100) * this.Company.commissionRate
      );
      return commsv;
    } else if (this.Company.commissionType === 2) {
      return GlobalConstants.fixCoin(this.Company.commissionAmount);
    } else {
      return 0.0;
    }
  }

  public static getFeel(amount) {
    if (this.Company.feeType === 1) {
      return GlobalConstants.fixCoin(this.Company.feeAmount);
    } else if (this.Company.feeType === 2) {
      return GlobalConstants.fixCoin(this.Company.feeAmount);
    } else {
      return GlobalConstants.fixCoin(0.0);
    }
  }

  public static getTransferDetail() {
    return this.TransferDetail;
  }

  public static getEmail() {
    if (this.email === null) {
      window.location.href = environment.siteUrl + '/giris';
    } else {
      return this.email;
    }
  }

  public static getWalletInfo = function () {
    return this.WalletInfo;
  };

  public static fixCoin(value) {
    const num = parseFloat(value).toFixed(8);

    return num;
  }

  goLogin() {
    window.location.href = environment.siteUrl + '/giris';
  }

  public static _bitistanbul_Theme_Background: string = '#161C28';
  public static _bitistanbul_Theme_Font: string = '#FFFF';
  public static isDark: string = '1';

  public static ChangeMode = function () {
    this.changeModeEvent.emit(null);

    this._bitistanbul_Theme_Background = null;
    this._bitistanbul_Theme_Font = null;

    if (this.isDark === '0') {
      this._bitistanbul_Theme_Background = '#161C28';
      this._bitistanbul_Theme_Font = '#FFFF';

      this.isDark = '1';
    } else {
      this._bitistanbul_Theme_Background = '';
      this._bitistanbul_Theme_Font = '';

      this.isDark = '0';
    }
  };
}

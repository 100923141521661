<ngx-ui-loader></ngx-ui-loader>

<header>
  <nav class="navbar navbar-default navbar-fixed-top headbg">
    <div class="container" style="width: 1200px">
      <div class="navbar-header">
        <a class="navbar-brand navbar-link lg"
          ><img
            width=""
            height=""
            src="../../../assets/images/logo.svg"
            alt="Bitistanbul Logo"
            class="img-responsive logo"
        /></a>
        <button
          title="button"
          class="navbar-toggle collapsed"
          data-toggle="collapse"
          data-target="#navcol-1"
        >
          <span class="sr-only"></span><span class="icon-bar"></span
          ><span class="icon-bar"></span><span class="icon-bar"></span>
        </button>
      </div>
      <div class="collapse navbar-collapse" id="navcol-1">
        <ul class="nav navbar-nav navbar-right logmenus" *ngIf="!loggedIn">
          <li>
            <a class="blue-outline-btn"
              ><span class="ss-icon"
                ><img
                  src="assets/images/user-s.png"
                  alt="account"
                  width=""
                  height="" /></span
              >{{ "Signin" | translate }}</a
            >
          </li>
          <li>
            <a class="orange-outline-btn">
              <span class="ss-icon"
                ><img
                  src="assets/images/user-plus.png"
                  alt="account"
                  width=""
                  height=""
              /></span>
              {{ "Signup" | translate }}</a
            >
          </li>
        </ul>
        <ul class="nav navbar-nav navbar-right" *ngIf="loggedIn">
          <li class="dropdown user user-menu">
            <a class="dropdown-toggle" data-toggle="dropdown">
              <div class="user-div">
                <div class="container-user">
                  <span class="ss-icon">
                    <img src="../../../assets/images/097.svg" alt="" style="width: 19px"
                  /></span>

                  <div class="usertt capitalize">
                    &nbsp;&nbsp;{{ informationData.name }}
                  </div>
                  <ul class="dropdown-menu dropdown-menu-style">
                    <li class="text-center">
                      <a routerLink="/giris" (click)="logout($event)">{{
                        "Logout" | translate
                      }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>

<app-header></app-header>
<article>
  <div class="pagecontent">
    <section class="innerpagecontent">
      <div class="container">
        <div class="row">
          <!-- tradeHistoryHeader -->
          <table id="tradeHistoryHeader">
            <tbody>
              <tr>
                <td style="text-align: center; height: 35px">
                  <img
                    src="assets/images/071.svg"
                    alt=""
                    class="text-center"
                    style="width: 15px; margin-right: 195px"
                  />
                </td>
              </tr>
              <tr>
                <td style="text-align: center">
                  <img
                    alt=""
                    src="assets/images/070.svg"
                    class="text-center"
                    style="width: 24px; vertical-align: sub"
                  />
                  &nbsp;&nbsp;
                  <small
                    >{{ "Trade" | translate }}
                    {{ "History" | translate }}</small
                  >
                </td>
              </tr>
              <tr>
                <td colspan="3" style="height: 25px">
                  <hr />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Dışa Aktar bölümü -->
      <div class="container" id="notcontainer">
        <div class="row">
          <div class="col-md-12 text-right top-style">
            <div class="form-group tg-select text-right margin-top-style">
              <button type="button" class="btn" id="tradeHistory-purchase-sale">
                {{ "Trade" | translate }}
              </button>
              <button type="button" class="btn" id="tradeHistory-deposit">
                <a routerLink="/yatirma-gecmisi">{{ "Deposit" | translate }}</a>
              </button>
              <button type="button" class="btn" id="tradeHistory-withdraw">
                <a routerLink="/cekme-gecmisi">{{ "Withdraw" | translate }}</a>
              </button>

              <button
                [disabled]="histroys == undefined || histroys?.length == 0"
                type="button"
                (click)="downloadExcel()"
                class="btn"
                id="tradeHistory-export"
              >
                Dışa Aktar
              </button>
            </div>
          </div>
        </div>

        <!-- innerformbg form -->
        <div class="row">
          <form
            class="innerformbg"
            [formGroup]="inputForm"
            (ngSubmit)="logInputChange()"
          >
            <!-- startdate -->
            <div class="col-md-3 col-sm-4">
              <div class="form-group">
                <label for="startdate" class="t-white">{{
                  "StartDate" | translate
                }}</label>
                <input
                  type="text"
                  name="startdate"
                  id="startdate"
                  class="form-control"
                  formControlName="startdate"
                  #start="bsDatepicker"
                  bsDatepicker
                  [(ngModel)]="form.startdate"
                  [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
                  [outsideClick]="true"
                />
                <span class="input-group-btn date-input">
                  <button
                    type="button"
                    class="btn btn-default stbtn"
                    (click)="start.toggle()"
                    [attr.aria-expanded]="start.isOpen"
                    title="button"
                  >
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                  </button>
                </span>
              </div>
            </div>

            <!-- enddate -->
            <div class="col-md-3 col-sm-4">
              <div class="form-group">
                <label for="enddate">{{ "EndDate" | translate }}</label>
                <input
                  type="text"
                  id="enddate"
                  name="enddate"
                  class="form-control"
                  formControlName="enddate"
                  #end="bsDatepicker"
                  bsDatepicker
                  [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
                  [minDate]="form.startdate"
                  [outsideClick]="true"
                  [(ngModel)]="form.enddate"
                />
                <span class="input-group-btn date-input">
                  <button
                    type="button"
                    class="btn btn-default stbtn"
                    (click)="end.toggle()"
                    [attr.aria-expanded]="end.isOpen"
                    title="button"
                  >
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                  </button>
                </span>
              </div>
            </div>

            <!-- Pair -->
            <div class="col-md-2 col-sm-4">
              <div class="form-group tg-select">
                <label for="pair">{{ "Pair" | translate }}</label>
                <select
                  id="pair"
                  name="pair"
                  class="form-control"
                  formControlName="pair"
                  (change)="logInputChange()"
                >
                  <ng-container *ngFor="let market of markets">
                    <option
                      [ngValue]="market.pair"
                      [selected]="market.pair == pair"
                    >
                      <ng-container *ngIf="market.pair !== 'TUMU'">
                        {{ market.pairone }}/{{ market.pairtwo }}
                      </ng-container>
                      <ng-container *ngIf="market.pair === 'TUMU'">
                        {{ market.pairone }}  
                      </ng-container>
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>

            <!-- ordertype -->
            <div class="col-md-2 col-sm-4">
              <div class="form-group tg-select">
                <label for="type">{{ "Type" | translate }}</label>
                <select
                  id="type"
                  name="type"
                  class="form-control"
                  formControlName="ordertype"
                >
                  <option value="buy" [selected]="'buy' == ordertype">
                    {{ "Buy" | translate }}
                  </option>
                  <option value="sell">{{ "Sell" | translate }}</option>

                  <option value="all">
                   TUMU
                  </option>
                </select>
              </div>
            </div>

            <!-- limit -->
            <div class="col-md-2 col-sm-4">
              <div class="form-group tg-select">
                <label for="order">{{ "Order" | translate }}</label>
                <select
                  id="order"
                  name="order"
                  class="form-control"
                  formControlName="tradetype"
                >
                  <option value="limit" [selected]="'limit' == tradetype">
                    {{ "Limit" | translate }}
                  </option>
                  <option value="market">
                    {{ "Market" | translate }}
                  </option>

                  <option value="all">
                    TUMU
                   </option>
 
                </select>
              </div>
            </div>

            <!-- clearData -->
            <div class="col-md-12 text-right mt-5">
              <div class="form-group tg-select">
                <button
                  type="button"
                  class="btn"
                  id="tradeHistory-clear"
                  (click)="clearData()"
                >
                  {{ "Clear" | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>

        <!-- table -->
        <table
          class="table site-table dataTable-tradehistroy mt-50"
          id="dataTable"
        >
          <thead>
            <tr>
              <th class="histroy-date">{{ "Date" | translate }}</th>
              <th class="histroy-pair">{{ "Pair" | translate }}</th>
              <th class="histroy-type">{{ "Type" | translate }}</th>
              <th class="histroy-order">{{ "Order" | translate }}</th>
              <th class="histroy-price">{{ "Price" | translate }}</th>
              <th class="histroy-quantity">{{ "Quantity" | translate }}</th>
              <th class="histroy-price">Gerçekleşen Fiyat</th>
              <th class="histroy-pair">Masraf</th>
              <th class="histroy-total">{{ "Total" | translate }}</th>
              <th class="text-right">{{ "Status" | translate }}</th>
            </tr>
          </thead>
          <tbody *ngIf="histroys && histroys?.length > 0">
            <tr
              *ngFor="
                let histroy of histroys
                  | slice : (page - 1) * pageSize : page * pageSize
              "
            >
              <td>{{ histroy.date | localizedDate }}</td>
              <td class="histroy-pair">{{ histroy.symbol }}</td>
              <td class="histroy-type">{{ histroy.ordertype }}</td>
              <td class="histroy-order" *ngIf="histroy.type == 'buy'">Alış</td>
              <td class="histroy-order" *ngIf="histroy.type == 'sell'">
                Satış
              </td>

              <td>
                {{ formattingService.formatNumberTRY(histroy.price) }}
              </td>
              <td>
                {{ formattingService.formatCrypto(histroy.quantity) }}
              </td>
              <td>
                {{ formattingService.formatNumberTRY(histroy.real_price) }}
              </td>
              <td class="histroy-pair">
                {{ formattingService.formatNumberTRY(histroy.fee) }}
              </td>
              <td class="histroy-total">
                {{ formattingService.formatNumberTRY(histroy.total) }}
              </td>
              <td *ngIf="histroy.status == 'Cancelled'" class="text-right">
                İptal Edildi
              </td>
              <td
                *ngIf="histroy.status == 'PartialCancelled'"
                class="text-right"
              >
                Kısmi İptal
              </td>
              <td *ngIf="histroy.status == 'Completed'" class="text-right">
                Gerçekleşti
              </td>
              <td *ngIf="histroy.status == 'Pending'" class="text-right">
                Bekliyor
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="histroys == undefined || histroys?.length == 0">
            <tr>
              <td colspan="10" class="text-center">
                <p class="mt-30">Alım satım geçmişi bulunamadı!</p>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- pagination-table -->
        <table class="pagination-table" *ngIf="histroys?.length > 10">
          <tr>
            <td class="text-center">
              <ngb-pagination
                [collectionSize]="collectionSize"
                [(page)]="page"
                [pageSize]="pageSize"
                [directionLinks]="false"
                (pageChange)="refreshHistorys()"
                [boundaryLinks]="true"
                [rotate]="true"
              >
              </ngb-pagination>
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <select
                title="Sayfa Listele"
                class="form-control"
                [(ngModel)]="pageSize"
                (ngModelChange)="refreshHistorys()"
              >
                <option [ngValue]="10">10</option>
                <option [ngValue]="20">20</option>
                <option [ngValue]="30">30</option>
                <option [ngValue]="histroys?.length">Tüm</option>
              </select>
            </td>
          </tr>
        </table>
      </div>
    </section>
  </div>
</article>
<app-footer></app-footer>

import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { WalletService } from "src/app/Services/wallet.service";
import { environment } from "src/environments/environment";
import { DatePipe } from "@angular/common";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { FormattingService } from "src/app/Services/numberFormat.services";

@Component({
  selector: "app-withdrawhistroy",
  templateUrl: "./withdrawhistroy.component.html",
  styleUrls: ["./withdrawhistroy.component.css"],
})
export class WithdrawhistroyComponent implements OnInit {
  public error = null;
  public data = null;
  public getcoin = null;
  public histroys = null;
  public coinlists = null;
  public cointype = null;
  public success = null;
  public collectionSize: any;
  page = 1;
  pageSize = 10;
  config: any;
  p: any = 1;
  limit: any = environment.pagination;
  offset: any = 0;
  queryParams: Params;
  startdate: any = null;
  enddate: any = null;
  public form = {
    startdate: null,
    enddate: null,
  };

  constructor(
    private wallet: WalletService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    public formattingService: FormattingService
  ) {
    this.getRouteParams();
  }

  ngOnInit() {
    this.refreshHistorys();
  }

  refreshHistorys() {
    if (this.histroys) {
      this.histroys
        .map((histroys, i) => ({ id: i + 1, ...histroys }))
        .slice(
          (this.page - 1) * this.pageSize,
          (this.page - 1) * this.pageSize + this.pageSize
        );
    }
  }
  getRouteParams() {
    // URL query parameters
    this.route.queryParams.subscribe((params) => {
      this.getcoin = params["coin"] ? params["coin"] : "BTC";
      this.queryParams = params["page"] ? params["page"] : 1;
      this.startdate = params["startdate"] ? params["startdate"] : null;
      this.enddate = params["enddate"] ? params["enddate"] : null;
      if (this.startdate && this.enddate) {
        this.form.startdate = new Date(this.startdate);
        this.form.enddate = new Date(this.enddate);
      } else {
        this.form.startdate = null;
        this.form.enddate = null;
      }
      this.p = this.queryParams;
      this.gethistorydetails(
        this.getcoin,
        this.p,
        this.startdate,
        this.enddate
      );
    });
  }

  gethistorydetails(coin, page: number, startdate, enddate) {
    const perPage = environment.pagination;
    const start: any = (page - 1) * perPage;
    //const end:any = start + perPage;
    const end: any = perPage;
    var myFormData = new FormData();
    myFormData.append("coin", coin);
    myFormData.append("offset", start);
    myFormData.append("limit", end);
    if (startdate && enddate) {
      myFormData.append("startdate", startdate);
      myFormData.append("enddate", enddate);
    }
    this.wallet.getwithdrawhistroy(myFormData).subscribe(
      (data) => this.handleCoinDetails(data, page),
      (error) => this.handleError(error)
    );
  }

  handleCoinDetails(res, page) {
    if (res.success) {
      this.data = res?.result;
      this.cointype = this.data?.cointype;
      this.coinlists = this.data?.coinlist;
      this.coinlists![this.coinlists.length] = {
        coinsymbol: "TUMU",
        coinname: "TUMU",
      };
      this.histroys = this.data?.histroys;
      this.collectionSize = this.histroys?.length;
      this.config = {
        currentPage: page,
        itemsPerPage: environment.pagination,
        totalItems: this.data.count,
      };
    } else {
      this.error = res.message;
      this.router.navigateByUrl("/cuzdan");
    }
  }

  onSubmit() {
    var startdate = this.datePipe.transform(this.form.startdate, "yyyy-MM-dd");
    var enddate = this.datePipe.transform(this.form.enddate, "yyyy-MM-dd");
    this.router.navigate(["cekme-gecmisi"], {
      queryParams: {
        coin: this.getcoin,
        startdate: startdate,
        enddate: enddate,
      },
    });
  }

  pageChange(newPage: number) {
    this.router.navigate(["cekme-gecmisi"], {
      queryParams: {
        coin: this.getcoin,
        startdate: this.startdate,
        enddate: this.enddate,
        page: newPage,
      },
    });
  }

  navigateTo(value) {
    if (value) {
      this.router.navigate(["cekme-gecmisi"], { queryParams: { coin: value } });
    }
    return false;
  }

  clearData(value) {
    this.form.startdate = null;
    this.form.enddate = null;
    this.router.navigate(["cekme-gecmisi"], { queryParams: { coin: value } });
  }

  copyMsg(val: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  downloadExcel() {
    try {
      this.wallet.getwithdrawhistroy(this.gethistorydetails).subscribe(() => {
        const worksheet = XLSX.utils.json_to_sheet(this.data.histroys);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
        const excelBuffer: any = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        this.saveExcelFile(excelBuffer, "Cekme-gecmisi.xlsx");
      });
    } catch (error) {
      this.error = "Excel indirilirken bir hata oluştu!";
    }
  }

  saveExcelFile(buffer: any, fileName: string) {
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    saveAs(blob, fileName);
  }

  handleError(error) {
    this.error = error.message;
  }
}

<app-header></app-header>
<article>
  <div class="banner-bg banner-bgImage">
    <div class="container">
      <div class="site-bannercontent">
        <div
          class="col-lg-8 col-md-10 col-sm-12 col-xs-12 center-content text-center"
          style="width: 80%"
        >
          <h3 class="fnt-reg h3 order-2 banner-bg-h3">
            TÜRKİYE'NİN 
          </h3>
          <h2 class="h2 fnt-reg t-blue order-3 banner-bg-h2">
            {{ "KRİPTO VARLIK ALIM SATIM PLATFORMU" | translate }}
          </h2>
          <br />
          <div class="bitcoin-pic order-1 bitcoin-pic001">
            <img
              width=""
              height=""
              src="assets/images/001.png"
              alt="BitcoinLogo"
            />
          </div>
          <h1
            class="fnt-bld h1 t-white mb-2x order-4"
            style="font-size: 61px; line-height: 1.3"
          >
            {{ "GÜVENLE BITCOIN" | translate }}
          </h1>
          <h1 class="fnt-bld h1 t-white mb-2x order-5" style="font-size: 61px">
            {{ "ALIN SATIN" | translate }}
          </h1>
          <br />
          <a routerLink="/giris" *ngIf="!loggedIn">
            <div
              class="fnt-bld center-btn t-white border-btn order-6 mob-none"
              style="font-size: 20px"
            >
              {{ "Hemen Başlayın" | translate }}
            </div>
          </a>
          <a
            routerLink="/borsa/BTC/TRY"
            aria-label="Hemen Başlayın"
            *ngIf="loggedIn"
            target="_blank"
          >
            <div
              class="fnt-bld center-btn t-white border-btn order-6 mob-none text-center-style"
              style="font-size: 20px"
            >
              {{ "Hemen Başlayın" | translate }}
            </div>
          </a>
        </div>
        <div class="table-content h5 fnt-reg text-uppercase coin-value">
          <div class="table-row" *ngFor="let histroy of homeliveprice">
            <div class="t-blue fnt-bld" style="font-size: 16px">
              {{ histroy.pairone }}/{{ histroy.pairtwo }}  
            </div>
            <div
              class="t-white fnt-bld h4 fnt-reg fnt-bld"
              style="font-size: 15px"
            >
              {{ formattingService.formatNumberTRY(histroy.price) }}
              {{ histroy.pairtwo }}
            </div>
          </div>
          <div class="table-row mob-btn">
            <a routerLink="/giris" class="btn m-blue-btn">
              {{ "Hemen Başlayın" | translate }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="grey-bg grey-bgnew sectionbgnew2">
    <div class="container box3-whl grey-bgnew2">
      <a routerLink="/hesap-olustur">
        <div class="col-md-4 col-sm-4 col-xs-12" [hidden]="loggedIn">
          <div class="box3 dark-blue h3 fnt-bld" style="margin-top: 30px">
            <span>
              {{ "KOLAYCA" | translate }}<br />
              {{ "ÜYE OLUN" | translate }} &nbsp;</span
            >
          </div>
          <img width="" height="" src="assets/images/003.png" alt="monitor" />
        </div>
      </a>
      <a routerLink="/borsa/BTC/TRY " aria-label="Kolayca uye olun">
        <div class="col-md-4 col-sm-4 col-xs-12" [hidden]="!loggedIn">
          <div class="box3 dark-blue h3 fnt-bld" style="margin-top: 30px">
            <span>
              {{ "KOLAYCA" | translate }}<br />
              {{ "ÜYE OLUN" | translate }}</span
            >
          </div>
          <img width="" height="" src="assets/images/003.png" alt="monitor" />
        </div>
      </a>
      <a routerLink="/giris">
        <div class="col-md-4 col-sm-4 col-xs-12" [hidden]="loggedIn">
          <div class="box3 dark-blue h3 fnt-bld">
            <span style="font-size: 24">
              {{ "HEMEN" | translate }}<br />
              {{ "PARA YATIRIN" | translate }}</span
            >
          </div>
          <img
            src="assets/images/004.png"
            alt="Para Yatır"
            width=""
            height=""
          />
        </div>
      </a>
      <a routerLink="/kripto-yatirma/TRY" aria-label="Hemen para yatirin">
        <div class="col-md-4 col-sm-4 col-xs-12" [hidden]="!loggedIn">
          <div class="box3 dark-blue h3 fnt-bld">
            <span style="font-size: 24">
              {{ "HEMEN" | translate }}<br />
              {{ "PARA YATIRIN" | translate }}</span
            >
          </div>
          <img
            src="assets/images/004.png"
            alt="Para Yatır"
            width=""
            height=""
          />
        </div>
      </a>
      <a routerLink="/giris">
        <div class="col-md-4 col-sm-4 col-xs-12" [hidden]="loggedIn">
          <div class="box3 dark-blue h3 fnt-bld">
            <span>
              {{ "GÜVENLE" | translate }}
              <br />
              {{ "BITCOIN ALIN" | translate }}</span
            >
          </div>
          <img width="" height="" src="assets/images/005.png" alt="bitcoin" />
        </div>
      </a>
      <a
        routerLink="/borsa/BTC/TRY"
        target="_blank"
        aria-label="güvenle bitcoin alin"
      >
        <div class="col-md-4 col-sm-4 col-xs-12" [hidden]="!loggedIn">
          <div class="box3 dark-blue h3 fnt-bld">
            <span>
              {{ "GÜVENLE" | translate }}
              <br />
              {{ "BITCOIN ALIN" | translate }}</span
            >
          </div>
          <img width="" height="" src="assets/images/005.png" alt="bitcoin" />
        </div>
      </a>
    </div>
  </section>
  <section class="dark-bg dark-bgImage sectionScreen">
    <div class="container containertop">
      <div class="col-md-10 col-sm-12 col-xs-12 blog center-content">
        <div class="table-content">
          <div class="col-md-5">
            <img
              width=""
              height=""
              class="img-responsive"
              src="assets/images/006.png"
              alt="Borsa"
            />
          </div>
          <div class="col-md-7">
            <h3 class="fnt-bld t-white mb-0 sectionh3">
              {{ "GÜVENLİ VE HIZLI BİR ŞEKİLDE" | translate }}
            </h3>
            <h3 class="t-blue fnt-bld mt-0 sectionh3coin">
              {{ " ANINDA BITCOIN ALIN SATIN" | translate }}
            </h3>
            <div class="h4 lh-n t-white section-div-text">
              Kolay kullanım, yüksek likidite ve hızlı kripto işlemleri. 7/24 para yatırma çekme. Rekabetçi fiyatlar, düşük komisyon oranlarıyla güvenle kripto al, sat, sakla!
            </div>

            <div class="text-center-style" *ngIf="!loggedIn">
              <a
                routerLink="/giris"
                class="fnt-bld center-btn t-white m-border-btn mt-10 text-decoration"
              >
                <b style="font-size: 20px">
                  {{ "Hemen Başlayın" | translate }}</b
                >
              </a>
            </div>
            <div class="text-center-style" *ngIf="loggedIn">
              <a
                routerLink="/borsa/BTC/TRY"
                aria-label="hemen baslayin bitistanbul"
                target="_blank"
                class="fnt-bld center-btn t-white m-border-btn mt-10 text-decoration"
              >
                <b style="font-size: 20px"> {{ "Hemen Başlayın" | translate }} </b>
              </a>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="grey-bg sectionScreen1">
    <div class="container containertop1">
      <h1 class="text-center sectionScreen1-h1"> {{ "YANINIZDAYIZ" | translate }}</h1>
      <br />
      <br />
      <div class="table-content auto-content">
        <div class="div-f h3 fnt-bld">
          <br />
          <div class="div-f1 blue-arrow"> {{ "Kolay kullanılabilir arayüz" | translate }}</div>
          <div class="div-f2 orange-arrow">
            {{ "Anlaşmalı bankalarla 7/24 işlem" | translate }}
          </div>
        </div>
        <div class="div-c text-center">
          <img
            width=""
            height=""
            src="assets/images/013.png"
            alt="BitcoinLogo"
          />
        </div>
        <div class="div-l h3 fnt-bld">
          <div class="div-l1 orange-arrow"> {{ "Hızlı ve güvenli altyapı" | translate }}</div>
          <div class="div-l2">
            <img
              src="assets/images/012.png"
              alt="bitistanbul"
              width=""
              height=""
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bluedark-bg sectionBitCoin">
    <div class="container containerTopBitCoin">
      <div class="text-center col-md-9 col-sm-12 center-content">
        <div class="t-blue fnt-bld h1 bluedark-bg-div"> {{ "BITCOIN İLE İLGİLİ" | translate }}</div>
        <div class="t-white fnt-bld h1 mt-0 bluedark-bg-div">
           {{ "MERAK ETTİĞİNİZ HER ŞEY" | translate }}
        </div>
        <div class="table-3 center-table fnt-bld t-blue" id="coinTable">
          <table>
            <tbody>
              <tr>
                <td class="vr1">
                  <a href="{{ siteUrl }}/bitcoin-nedir">
                    <span>{{ "Bitcoin Nedir?" | translate }}</span>
                  </a>
                </td>
                <td class="vr">
                  <a href="{{ siteUrl }}/bitcoin-nasil-alinir">
                    <span
                      >{{ "Bitcoin nasıl alınır?" | translate }}</span
                    >
                  </a>
                </td>
                <td class="vr">
                  <a href="{{ siteUrl }}/bitcoin-nasil-saklanir">
                    <span> {{ "Bitcoin nasıl saklanır?" | translate }}</span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="center-pic">
          <br />
          <img width="" height="" src="assets/images/014.png" alt="tablet" />
        </div>
        <div class="dark-blue h2 fnt-bld mb-0" style="font-size: 32px">
          {{ "HER PLATFORMDAN TAKİP EDİN" | translate }}
        </div>
        <div
          class="t-white h2 fnt-bld mt-0"
          style="font-size: 32px; letter-spacing: 1px"
        >
          
          {{ "GÜVENLE BITCOIN ALIN SATIN" | translate }}
        </div>
        <div class="h4 dark-blue fnt-bld" style="font-size: 17px">

          {{ "Güçlü platform altyapımız, gerçek zamanlı grafikler ve gelişmiş analiz araçları ile profesyoneller için güvenli, kesintisiz, çok hızlı bir alım satım deneyimi sunuyoruz." | translate }}
          
        </div>
      </div>
      <div class="text-center app-icon-whl">
        <table style="text-align: center; width: 100%">
          <tbody>
            <tr>
              <td style="text-align: right">
                <a href="https://play.google.com/" class="app-icon"
                  ><img
                    src="assets/images/015.png"
                    alt="play.google"
                    width=""
                    height=""
                /></a>
              </td>
              <td style="width: 20px"></td>
              <td style="text-align: left">
                <a href="https://www.apple.com/tr/app-store/" class="app-icon"
                  ><img
                    src="assets/images/016.png"
                    alt="apple.com"
                    width=""
                    height=""
                /></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <button
      type="button"
      title="button"
      (click)="gotoTop()"
      class="btn btn-primary btn-circle"
      *ngIf="isShow"
    >
      <i class="fa fa-arrow-up" aria-hidden="true"></i>
    </button>
  </section>
</article>
<app-footer></app-footer>

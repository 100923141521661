import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { TokenService } from "./token.service";
@Injectable({
  providedIn: "root",
})
export class WalletService {
  private _url: string = environment.baseUrl;
  private _tradeUrl: string = environment.tradeUrl;

  constructor(private http: HttpClient, private Token: TokenService) {}

  // getCoinlist() {
  //   return this.http.get(`${this._url} / lcoinlist`).catch(this.errorHandler);
  // }

  getPendingWithdrawalOrders() {
    return this.http
      .get(`${this._url}/withdrawpending`)
      .catch(this.errorHandler);
  }

  getWalletbalance() {
    return this.http
      .post(`${this._url}/walletbalance`, [])
      .catch(this.errorHandler);
  }


  getAddress() {
    return this.http
      .post(`${this._url}/getaddress`, [])
      .catch(this.errorHandler);
  }

  getCryptodeposit(data) {
    return this.http
      .post(`${this._url}/coindeposit`, data)
      .catch(this.errorHandler);
  }

  getLimitList() {
    return this.http
      .post(`${this._url}/getlimitlist`, [])
      .catch(this.errorHandler);
  }

 
  getwithdrawrequest(data) {
    return this.http
      .post(`${this._url}/withdrawdetails`, data)
      .catch(this.errorHandler);
  }

  sendwithdrawfiat(data) {
    return (
      this.http
        // .post(`${this._url}/withdrawrequest`, data)
        .post(`${this._tradeUrl}/withdrawsendfiat`, data)
        .catch(this.errorHandler)
    );
  }

  sendwithdrawrequest(data) {
    return this.http
      .post(`${this._url}/withdrawrequest`, data)

      .catch(this.errorHandler);
  }

  getdeposithistroy(data) {
    return this.http
      .post(`${this._url}/deposithistroy`, data)
      .catch(this.errorHandler);
  }

  getwithdrawhistroy(data) {
    return this.http
      .post(`${this._url}/withdrawhistroy`, data)
      .catch(this.errorHandler);
  }
  
  getuserbanklists() {
    return this.http
      .post(`${this._url}/getbanklist`, [])
      .catch(this.errorHandler);
  }

  adminbanklists() {
    return this.http
      .get(`${this._url}/admin_bank_list`)
      .catch(this.errorHandler);
  }

  getCommissionData() {
    return this.http.get(`${this._url}/commissions`).catch(this.errorHandler);
  }
  
  adduserbankdetails(data) {
    return this.http
      .post(`${this._url}/addbankdetails`, data)
      .catch(this.errorHandler);
  }

  edituserbankdetails(data) {
    return this.http
      .post(`${this._url}/editbankdetails`, data)
      .catch(this.errorHandler);
  }

  deleteuserbankdetails(bankid: number) {
    return this.http
      .post(this._url + "/deletebankdetails?bankid=" + bankid, {
        bankid: bankid,
      })
      .catch(this.errorHandler);
  }

 
  getEstimatedFee(coin, amount, destination) {
    return this.http
      .post(`${this._url}/getEstimatedFee`, { coin, amount, destination })
      .catch(this.errorHandler);
  }

 
  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error.message || "Server error");
  }

  getToken() {
    return this.Token.get();
  }
}

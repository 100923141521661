import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FormattingService {
  constructor() {}

  // TRY Formatlama fonksiyonu
  formatNumberTRY(value: number | string): string {
    if (isNaN(value as number) || value === null || value === undefined) {
      return "";
    }

    const numericValue = typeof value === "string" ? parseFloat(value) : value;
    const stringValue = numericValue.toFixed(2); // 2 ondalık basamak

    const [integerPart, decimalPart] = stringValue.split(".");
    const formattedIntegerPart = integerPart
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") // Binlik ayracı virgül
      .replace(/^(-)?\./, "$10");

    const formattedDecimalPart = decimalPart
      ? `.${decimalPart.padEnd(2, "0")}` // Ondalık ayracı nokta
      : ".00";

    return formattedIntegerPart + formattedDecimalPart;
  }

  // Crypto Formatlama fonksiyonu
  formatCrypto(value: string | number, decimals: number = 8): string {
    if (value === null || value === undefined || isNaN(Number(value))) {
      return "0." + "0".repeat(decimals); // Geçersiz değer için sıfır
    }

    const stringValue = value.toString();
    const [integerPart, decimalPart = ""] = stringValue.split(".");

    const formattedInteger = parseInt(integerPart, 10)
      .toLocaleString("en-US")
      .replace(/\./g, ",");

    const formattedDecimal = (decimalPart + "0".repeat(decimals)).substring(
      0,
      decimals
    );

    return `${formattedInteger}.${formattedDecimal}`; // Ondalık basamak
  }

  // Mevcut formatlama metodları bozulmadan kalıyor
  formatMarketPrice(
    pairone: string,
    pairtwo: string,
    price: number | string
  ): string {
    if (pairone === "USDT" && pairtwo === "TRY") {
      return this.formatCrypto(price, 2); // USDT/TRY için 3 ondalık basamak
    } else {
      return this.formatNumberTRY(price); // Diğer çiftler için TRY formatlama (2 ondalık basamak)
    }
  }

  formatBasedOnCoinSymbol(coinsymbol: string, value: number | string): string {
    if (coinsymbol === "TRY") {
      return this.formatNumberTRY(value); // TRY için formatNumberTRY
    } else if (coinsymbol === "USDT" || coinsymbol === "TRY") {
      return this.formatCrypto(value, 3); // USDT ve TRY için 2 ondalık basamaklı formatlama
    } else {
      return this.formatCrypto(value); // Diğer tüm coinler için varsayılan formatCrypto
    }
  }

  // Mevcut formatBasedOnCoinPair metodu, bozulmadı.
  formatBasedOnCoinPair(
    coinone: string,
    cointwo: string,
    value: number | string
  ): string {
    if (coinone === "USDT" && cointwo === "TRY") {
      return this.formatCrypto(value, 2); // USDT/TRY için 3 ondalık basamak
    } else {
      return this.formatNumberTRY(value); // Diğer tüm durumlar için TRY formatlama
    }
  }

  formatCryptoWithCondition(
    coinone: string,
    cointwo: string,
    value: number | string
  ): string {
    if (coinone === "USDT" && cointwo === "TRY") {
      return this.formatCrypto(value, 3); // USDT/TRY için 3 ondalık basamak
    } else if (coinone === "USDT" || coinone === "TRY") {
      return this.formatCrypto(value, 2); // USDT veya TRY için 2 ondalık basamak
    } else {
      return this.formatCrypto(value); // Diğer tüm coinler için varsayılan formatlama
    }
  }

  // Yeni eklenen kontrol metodu: coinone ve cointwo'ya göre quantity formatlama
  formatBasedOnQuantity(
    coinone: string,
    cointwo: string,
    quantity: number | string
  ): string {
    if (coinone === "USDT" && cointwo === "TRY") {
      return this.formatCrypto(quantity, 2); // USDT/TRY için 2 ondalık basamak
    } else {
      return this.formatCrypto(quantity); // Diğer durumlar için default ondalık basamak
    }
  }


  limitDecimals(coinone: string, cointwo: string, value: string): string {
    let decimalLimit = 8;
  
    // Eğer USDT/TRY çifti ise, ondalık kısım için 2 hane sınırlaması getir
    if (coinone === "USDT" && cointwo === "TRY") {
      decimalLimit = 2;
    }
  
    // Tam sayı ve ondalık kısmı ayır
    const [integerPart, decimalPart] = value.split('.');
  
    // Eğer ondalık kısmı varsa ve limitten büyükse onu kes
    if (decimalPart && decimalPart.length > decimalLimit) {
      return `${integerPart}.${decimalPart.substring(0, decimalLimit)}`;
    }
  
    // Eğer ondalık kısım yoksa veya sınırı aşmıyorsa orijinal değeri döndür
    return value;
  }
  

}

<app-userheader></app-userheader>
<article>
  <div class="pagecontent">
    <section class="coinlistpairbg">
      <!-- hidden success error -->
      <div class="form-group">
        <app-alert-modal *ngIf="error" [error]="error" (close)="error = null">
          {{ error }}</app-alert-modal
        >
        <app-alert-modal-success
          *ngIf="success"
          [success]="success"
          (close)="success = null"
        >
          {{ success }}</app-alert-modal-success
        >
      </div>

      <!-- Piyasalar, son fiyat, hacim -->
      <div class="container">
        <div class="coinlistprice">
          <div class="coinlistf">
            <div class="dropdown" *ngIf="marketpair && marketpair.length > 0">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
              >
                <span class="valueprice">
                  {{ "SelectMarket" | translate }}</span
                >
                <span class="caret valueprice"></span>
              </button>
              <ul class="dropdown-menu">
                <li *ngFor="let marketpairs of marketpair">
                  <a
                    (click)="
                      navigateTo(
                        marketpairs.pairone,
                        marketpairs.pairtwo,
                        marketpairs
                      )
                    "
                  >
                    <div class="coin-pairs-link" style="cursor: pointer">
                      <!-- <img
                        class="coinicon"
                        src="assets/images/color/{{
                          marketpairs.pairone | lowercase
                        }}.svg"
                        alt=""
                      />&nbsp; -->
                      <span class="valueprice text-uppercase"
                        >{{ marketpairs.pairone }} /
                        {{ marketpairs.pairtwo }}</span
                      >&nbsp;
                      <span class="valueprice"
                        >{{ marketpairs.volume }}<br /><small class="valueprice"
                          >24h {{ "Volume" | translate }}</small
                        ></span
                      >
                      &nbsp;
                      <span class="valueprice"
                        >{{ marketpairs.price }}&nbsp;<small
                          class="valueprice"
                          >{{ "ClosedPriceTrade" | translate }}</small
                        ></span
                      >
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="coinlistf">
            <h4 class="h5">
              {{ "LastPrice" | translate }} <br /><span class="valueprice">
                {{ formattingService.formatNumberTRY(marketpairsDataPrice) }}
                {{ cointwo }}
              </span>
            </h4>
          </div>
          <div class="coinlistf">
            <h4 class="h5">
              24S {{ "Volume" | translate }} <br /><span class="valueprice">
                {{ formattingService.formatCrypto(marketpairsData24Hvolume) }}

                {{ coinone }}</span
              >
            </h4>
          </div>
          <div class="coinlistf">
            <h4 class="h5">
              24S {{ "Change" | translate }} <br /><span class="valueprice"
                >{{ marketpairsDataExchange?.toFixed(1) }} %</span
              >
            </h4>
          </div>
        </div>
      </div>

      <div class="container sitecontainer">
        <div class="whl-market-frame order">
          <div class="col-md-3 col-sm-6 col-xs-12 order-1">
            <!-- PİYASA -->
            <div class="market-list box-section">
              <div lang="tr" class="header-bar text-uppercase">
                &nbsp;{{ "Market" | translate }}
              </div>
              <div class="content-bar">
                <div class="table-responsive" data-simplebar>
                  <table
                    width="100%"
                    class="table tbg keywords tb-gray site-table piyasa"
                  >
                    <thead>
                      <tr>
                        <th>
                          &nbsp;&nbsp;<span class="process-header">
                            İŞLEM ÇİFTİ</span
                          >
                        </th>
                        <th>
                          <span class="process-header"> &nbsp; FİYAT</span>
                        </th>
                        <th>
                          &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                          <span class="process-header">24S</span>
                          <span class="site-table-nobold"> DEĞİŞİM</span>
                        </th>
                        <th>
                          &nbsp;&nbsp;<span class="process-header">24S</span>
                          <span class="site-table-nobold"> HACİM</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let marketpairs of marketpair"
                        (click)="
                          navigateTo(
                            marketpairs.pairone,
                            marketpairs.pairtwo,
                            marketpairs
                          )
                        "
                      >
                        <td>
                          <span
                            style="font-size: 12px"
                            class="text-uppercase td1"
                            >{{ marketpairs.pairone }} /
                            {{ marketpairs.pairtwo }}</span
                          >
                        </td>
                        <td style="font-size: 12px" class="td2">
                          &nbsp;
                          {{
                            formattingService.formatMarketPrice(
                              marketpairs.pairone,
                              marketpairs.pairtwo,
                              marketpairs.price
                            )
                          }}
                        </td>
                        <td>
                          &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;
                          <span
                            class="green-up"
                            style="font-size: 12px"
                            *ngIf="marketpairs.exchange >= 0"
                            >{{ marketpairs.exchange.toFixed(1) }} %</span
                          >
                          <span
                            class="red-up"
                            style="font-size: 12px"
                            *ngIf="marketpairs.exchange < 0"
                            >{{ marketpairs.exchange.toFixed(1) }} %</span
                          >
                        </td>
                        <td style="font-size: 12px">
                          <span class="td4">
                            {{
                              formattingService.formatCrypto(marketpairs.volume)
                            }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                    <tbody
                      *ngIf="marketpair == undefined || marketpair.length == 0"
                    >
                      <tr>
                        <td colspan="4">
                          <!-- {{ "NoRecordFound" | translate }} -->
                          <div class="NoRecordFound text-danger">
                            Lütfen bekleyiniz
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <!-- ALIM SATIM İŞLEM GEÇMİŞİ -->
            <div class="trad-his box-section">
              <div lang="tr" class="header-bar">
                {{ "Trade" | translate }} {{ "History" | translate }}
              </div>
              <div class="content-bar">
                <div class="table-head">
                  <div>
                    FİYAT <br />
                    <span class="site-table-nobold2">({{ cointwo }})</span>
                  </div>
                  <div>
                    MİKTAR<br />
                    <span class="site-table-nobold2">({{ coinone }})</span>
                  </div>
                  <div>
                    {{ "Total" | translate }}<br />
                    <span class="site-table-nobold2">({{ cointwo }})</span>
                  </div>
                  <div>{{ "Time" | translate }}</div>
                </div>
                <div class="scroll-content" data-simplebar>
                  <div class="table-div">
                    <div
                      class="table-body"
                      *ngIf="completes && completes.length > 0"
                    >
                      <div class="div-tr" *ngFor="let complete of completes">
                        <div
                          *ngIf="
                            complete.Type == 'BUY' || complete.Type == 'ALIŞ'
                          "
                          style="font-size: 13px"
                        >
                          <span class="t-green">
                            {{
                              formattingService.formatBasedOnCoinPair(
                                coinone,
                                cointwo,
                                complete.Rate
                              )
                            }}
                          </span>
                        </div>
                        <div
                          *ngIf="
                            complete.Type == 'SELL' || complete.Type == 'SATIŞ'
                          "
                          style="font-size: 13px"
                        >
                          <span class="red-up">
                            {{
                              formattingService.formatBasedOnCoinPair(
                                coinone,
                                cointwo,
                                complete.Rate
                              )
                            }}
                          </span>
                        </div>

                        <div style="font-size: 14px">
                          {{
                            formattingService.formatBasedOnQuantity(
                              coinone,
                              cointwo,
                              complete.Quantity
                            )
                          }}
                        </div>
                        <div style="font-size: 14px">
                          &nbsp;
                          {{
                            formattingService.formatNumberTRY(complete.Total)
                          }}
                        </div>
                        <div style="font-size: 14px">
                          {{ complete.Date | localizedDate }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="table-body"
                      *ngIf="completes == undefined || completes.length == 0"
                    >
                      <div class="NoRecordFound text-danger">
                        &nbsp;&nbsp;Lütfen bekleyiniz
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-12 col-xs-12 order-3">
            <!-- chart -->
            <div class="box-section chart" style="height: 400px; width: 850px">
              <div lang="tr" class="header-bar">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ "graphic" | translate }}
                <span class="tabrightbox">
                  <ul class="nav nav-tabs ordertabs">
                    <li class="active">
                      <a
                        data-toggle="tab"
                        href="#tradechart"
                        aria-expanded="true"
                      >
                        <img
                          src="assets/images/tradechart1.png"
                          class="hidef"
                          alt="" /><img
                          src="assets/images/tradechart.png"
                          class="showf"
                          alt=""
                      /></a>
                    </li>

                    <li class="">
                      <a
                        data-toggle="tab"
                        href="#marketdepth"
                        aria-expanded="false"
                        ><img
                          src="assets/images/marketdepth.png"
                          class="hidef"
                          alt=""
                        /><img
                          src="assets/images/marketchart.png"
                          class="showf"
                          alt=""
                        />
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div class="tab-content">
                <div id="tradechart" class="tab-pane fade">
                  <div class="content-bar">
                    <div
                      id="tv_chart_container"
                      style="height: 350px; background-color: black"
                      class="tradechartlist"
                    ></div>
                  </div>
                </div>
                <div id="marketdepth" class="tab-pane fade in active">
                  <div class="content-bar">
                    <div class="tradechartlist">
                      <div class="tradingview-widget-container">
                        <div id="tradingview_16a6e"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- EMİR FORMU -->
            <div class="box-section order-forms">
              <div class="header-bar">
                &nbsp;&nbsp;&nbsp; {{ "OrderForm" | translate }}
                <span class="orderfrmtab">
                  <ul class="nav nav-tabs ordertabs">
                    <li class="active">
                      <a data-toggle="tab" href="#limit">{{
                        "Limit" | translate
                      }}</a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#market">{{
                        "Market" | translate
                      }}</a>
                    </li>
                  </ul>
                </span>
              </div>
              <div class="content-bar">
                <div class="tab-content">
                  <!-- LİMİT -->
                  <div id="limit" class="tab-pane fade in active">
                    <div class="row">
                      <!-- alış -->
                      <div class="col-md-6 col-sm-6 col-xs-12 frmright">
                        <div class="table-box">
                          <div class="pull-left subtitle">
                            <span style="margin-left: 18px">
                              {{ "Buy" | translate }}
                              {{ coinone }}
                            </span>
                          </div>
                          <div class="deposit-whl">
                            <div class="deposit-balance">
                              <a
                                routerLink="/cuzdan"
                                class="text-uppercase"
                                style="padding-right: 6px"
                              >
                                {{ cointwo }} {{ "Deposit" | translate }}
                              </a>
                              <table class="deposit-table-s" *ngIf="loggedIn">
                                <tbody>
                                  <tr>
                                    <td>{{ "Youhave" | translate }} :&nbsp;</td>
                                    <td>
                                      <span class="balance-color">
                                        {{
                                          formattingService.formatNumberTRY(
                                            balances[cointwo]
                                          )
                                        }}
                                      </span>

                                      {{ cointwo }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <form
                          autocomplete="off"
                          #buylimitForm="ngForm"
                          (ngSubmit)="onBuyLimitSubmit()"
                          id="buylimitForm"
                        >
                          <div class="form-box">
                            <div class="form-group">
                              <label
                                class="control-label text-uppercase"
                                lang="tr"
                              >
                                {{ "Price" | translate }}
                              </label>

                              <!-- Fiyat Giriniz -->
                              <div class="input-group">
                                <input
                                  type="text"
                                  placeholder="Fiyat Giriniz"
                                  class="form-control"
                                  name="buyprice"
                                  (keyup)="onKeyupcalculateBuy($event)"
                                  oninput="this.value = this.value.replace(/,/g, '.').replace(/[^0-9.]/g, '');"
                                  [class.is-invalid]="
                                    buyprice.invalid && buyprice.touched
                                  "
                                  #buyprice="ngModel"
                                  [(ngModel)]="form.buyprice"
                                  pattern="[0-9.]+"
                                  [attr.readonly]="!loggedIn ? true : null"
                                  [disabled]="!loggedIn"
                                  tabindex="1"
                                  required
                                />
                                <div class="input-group-btn">
                                  <span class="btn btn-default">
                                    {{ cointwo }}
                                  </span>
                                </div>
                              </div>
                              <span
                                *ngIf="
                                  buyprice.errors &&
                                  buyprice.invalid &&
                                  buyprice.touched
                                "
                                style="margin-left: 100px"
                              >
                                <small
                                  class="text-danger-input"
                                  *ngIf="buyprice.errors.required"
                                >
                                  {{ "EnterPrice" | translate }}
                                </small>
                                <small
                                  class="text-danger-input"
                                  *ngIf="buyprice.errors.pattern"
                                  >{{ "Pricevaild" | translate }}</small
                                >
                              </span>
                            </div>

                            <!-- Miktar Giriniz -->
                            <div class="form-group">
                              <label
                                lang="tr"
                                class="control-label text-uppercase"
                              >
                                {{ "Quantity" | translate }}</label
                              >

                              <!-- Miktar Giriniz -->
                              <div class="input-group">
                                <input
                                  type="text"
                                  placeholder="Miktar Giriniz"
                                  class="form-control"
                                  name="buyvolume"
                                  oninput="this.value = this.value.replace(/,/g, '.').replace(/[^0-9.]/g, '');"
                                  (input)="onQuantityInput($event)"
                                  (keyup)="onKeyupcalculateBuy($event)"
                                  [class.is-invalid]="
                                    buyvolume.invalid && buyvolume.touched
                                  "
                                  #buyvolume="ngModel"
                                  [(ngModel)]="form.buyvolume"
                                  [attr.readonly]="!loggedIn ? true : null"
                                  [disabled]="!loggedIn"
                                  pattern="[0-9.]+"
                                  tabindex="2"
                                  required
                                />
                                <div class="input-group-btn">
                                  <span class="btn btn-default">
                                    {{ coinone }}
                                  </span>
                                </div>
                              </div>

                              <span
                                *ngIf="
                                  buyvolume.errors &&
                                  buyvolume.invalid &&
                                  buyvolume.touched
                                "
                                style="margin-left: 100px"
                              >
                                <small
                                  class="text-danger-input"
                                  *ngIf="buyvolume.errors.required"
                                >
                                  {{ "Amount" | translate }}
                                  {{ "enter" | translate }}
                                </small>
                                <small
                                  class="text-danger-input"
                                  *ngIf="buyvolume.errors.pattern"
                                  >{{ "Amountvaild" | translate }}</small
                                >
                              </span>
                            </div>

                            <!-- yüzdelik butonlar -->
                            <div class="form-group" *ngIf="loggedIn">
                              <div class="btn-group" style="width: 100%">
                                <button
                                  class="btn-note"
                                  style="
                                    width: 19%;
                                    border-right: none !important;
                                    border: none !important;
                                  "
                                  type="button"
                                  (click)="onPartialBuy('100')"
                                  tabindex="6"
                                >
                                  %100
                                </button>
                                <button
                                  type="button"
                                  style="width: 19%"
                                  (click)="onPartialBuy('75')"
                                  tabindex="5"
                                >
                                  %75
                                </button>
                                <button
                                  type="button"
                                  style="width: 19%"
                                  (click)="onPartialBuy('50')"
                                  tabindex="4"
                                >
                                  %50
                                </button>

                                <button
                                  type="button"
                                  style="width: 19%"
                                  (click)="onPartialBuy('25')"
                                  tabindex="3"
                                >
                                  %25
                                </button>
                              </div>
                            </div>

                            <!-- Toplam -->
                            <div class="form-group">
                              <label class="control-label text-uppercase">
                                {{ "Total" | translate }}
                              </label>
                              <div
                                class="control-input"
                                style="display: flex; flex-direction: column"
                              >
                                <div class="value-field">
                                  <span class="control-label-coin">
                                    {{
                                      formattingService.formatNumberTRY(
                                        form.buytotal
                                      )
                                    }}
                                    &nbsp;{{ cointwo }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            
                            <!-- Masraf -->
                            <div class="form-group">
                              <label class="control-label text-uppercase">
                                {{ "Fee" | translate }}
                              </label>
                              <div
                                class="control-input"
                                style="display: flex; flex-direction: column"
                              >
                                <div class="value-field">
                                  <span class="control-label-coin">
                                    {{
                                      formattingService.formatNumberTRY(buyfee)
                                    }}
                                    &nbsp;{{ cointwo }}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div class="control-group" *ngIf="loggedIn">
                              <input
                                [disabled]="!buylimitForm.valid"
                                type="submit"
                                class="btn site-btn green-btn btn-block"
                                value="{{ 'Buy' | translate }} {{ coinone }}"
                                *ngIf="!buylimitisDisabled"
                                data-toggle="modal"
                                data-target="#MessagesModalbuylimit"
                                [hidden]="true"
                                tabindex="7"
                              />

                              <input
                                type="submit"
                                name=""
                                value="{{ 'PleaseWait' | translate }}..."
                                class="btn site-btn green-btn btn-block"
                                disabled
                                *ngIf="buylimitisDisabled"
                              />
                            </div>

                            <div class="control-group" *ngIf="!loggedIn">
                              <a
                                class="btn site-btn green-btn btn-block"
                                routerLink="/giris"
                                >{{ "Signin" | translate }}</a
                              >
                            </div>
                          </div>
                        </form>
                      </div>

                      <!-- satış -->
                      <div
                        class="col-md-6 col-sm-6 col-xs-12 border-left frmleft"
                      >
                        <div class="table-box">
                          <div class="pull-left subtitle">
                            <span style="margin-left: 18px">
                              {{ "Sell" | translate }}
                              {{ coinone }}
                            </span>
                          </div>
                          <div class="deposit-whl">
                            <div class="deposit-balance">
                              <a
                                routerLink="/cuzdan"
                                class="text-uppercase"
                                style="padding-right: 8px"
                              >
                                {{ coinone }} {{ "Deposit" | translate }}</a
                              >
                              <table class="deposit-table-s" *ngIf="loggedIn">
                                <tbody>
                                  <tr>
                                    <td>{{ "Youhave" | translate }} :&nbsp;</td>
                                    <td>
                                      <span class="balance-color">
                                        {{
                                          formattingService.formatCryptoWithCondition(
                                            coinone,
                                            cointwo,
                                            balances[coinone]
                                              ? balances[coinone]
                                              : balance.coinone
                                          )
                                        }}
                                      </span>
                                      {{ coinone }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <form
                          autocomplete="off"
                          #selllimitForm="ngForm"
                          (ngSubmit)="onSellLimitSubmit()"
                          id="selllimitForm"
                        >
                          <div class="form-box">
                            <div class="form-group">
                              <label
                                class="control-label text-uppercase"
                                lang="tr"
                              >
                                {{ "Price" | translate }}
                              </label>

                              <!-- Fiyat Giriniz -->
                              <div class="input-group">
                                <input
                                  type="text"
                                  placeholder="Fiyat Giriniz"
                                  class="form-control"
                                  name="sellprice"
                                  (keyup)="onKeyupcalculateSell($event)"
                                  oninput="this.value = this.value.replace(/,/g, '.').replace(/[^0-9.]/g, '');"
                                  [class.is-invalid]="sellprice.invalid && sellprice.touched"
                                  #sellprice="ngModel"
                                  [(ngModel)]="form.sellprice"
                                  pattern="[0-9.]+"
                                  [attr.readonly]="!loggedIn ? true : null"
                                  [disabled]="!loggedIn"
                                  tabindex="8"
                                  required
                                />
                                <div class="input-group-btn">
                                  <span class="btn btn-default">
                                    {{ cointwo }}
                                  </span>
                                </div>
                              </div>
                              

                              <span
                                *ngIf="
                                  sellprice.errors &&
                                  sellprice.invalid &&
                                  sellprice.touched
                                "
                                style="margin-left: 100px"
                              >
                                <small
                                  class="text-danger-input"
                                  *ngIf="sellprice.errors.required"
                                >
                                  {{ "EnterPrice" | translate }}
                                </small>
                                <small
                                  class="text-danger-input"
                                  *ngIf="sellprice.errors.pattern"
                                  >{{ "Pricevaild" | translate }}</small
                                >
                              </span>
                            </div>

                            <!-- Miktar Giriniz -->
                            <div class="form-group">
                              <label
                                lang="tr"
                                class="control-label text-uppercase"
                                >{{ "Quantity" | translate }}</label
                              >
                              <div class="input-group">
                                <input
                                  type="text"
                                  placeholder="Miktar Giriniz"
                                  class="form-control"
                                  name="sellvolume"
                                  (input)="onQuantityInput($event)"
                                  (keyup)="onKeyupcalculateSell($event)"
                                  oninput="this.value = this.value.replace(/,/g, '.').replace(/[^0-9.]/g, '');"
                                  [class.is-invalid]="
                                    sellvolume.invalid && sellvolume.touched
                                  "
                                  #sellvolume="ngModel"
                                  [(ngModel)]="form.sellvolume"
                                  pattern="[0-9.]+"
                                  [attr.readonly]="!loggedIn ? true : null"
                                  [disabled]="!loggedIn"
                                  tabindex="9"
                                  required
                                />
                                <div class="input-group-btn">
                                  <span class="btn btn-default">
                                    {{ coinone }}
                                  </span>
                                </div>
                              </div>
                              <span
                                *ngIf="
                                  sellvolume.errors &&
                                  sellvolume.invalid &&
                                  sellvolume.touched
                                "
                                style="margin-left: 100px"
                              >
                                <small
                                  class="text-danger-input"
                                  *ngIf="sellvolume.errors.required"
                                >
                                  {{ "Amount" | translate }}
                                  {{ "enter" | translate }}
                                </small>
                                <small
                                  class="text-danger-input"
                                  *ngIf="sellvolume.errors.pattern"
                                  >{{ "Amountvaild" | translate }}</small
                                >
                              </span>
                            </div>

                            <!-- yüzdelik oranlar -->
                            <div class="form-group" *ngIf="loggedIn">
                              <div id="sellformlimit">
                                <div class="btn-group" style="width: 100%">
                                  <button
                                    class="btn-note"
                                    style="
                                      width: 19%;
                                      border-right: none !important;
                                      border: none !important;
                                    "
                                    type="button"
                                    (click)="onPartialSell('100')"
                                    tabindex="13"
                                  >
                                    %100
                                  </button>
                                  <button
                                    type="button"
                                    style="width: 19%"
                                    (click)="onPartialSell('75')"
                                    tabindex="12"
                                  >
                                    %75
                                  </button>
                                  <button
                                    type="button"
                                    style="width: 19%"
                                    (click)="onPartialSell('50')"
                                    tabindex="11"
                                  >
                                    %50
                                  </button>

                                  <button
                                    type="button"
                                    style="width: 19%"
                                    (click)="onPartialSell('25')"
                                    tabindex="10"
                                  >
                                    %25
                                  </button>
                                </div>
                              </div>
                            </div>

                            <!-- toplam -->
                            <div class="form-group">
                              <label class="control-label text-uppercase">
                                {{ "Total" | translate }}
                              </label>
                              <div
                                class="control-input"
                                style="display: flex; flex-direction: column"
                              >
                                <div class="value-field">
                                  <span class="control-label-coin">
                                    {{
                                      formattingService.formatNumberTRY(
                                        form.selltotal
                                      )
                                    }}
                                    &nbsp;{{ cointwo }}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <!-- Masraf -->
                            <div class="form-group">
                              <label class="control-label text-uppercase">
                                {{ "Fee" | translate }}
                              </label>
                              <div
                                class="control-input"
                                style="display: flex; flex-direction: column"
                              >
                                <div class="value-field">
                                  <span class="control-label-coin">
                                    {{
                                      formattingService.formatNumberTRY(sellfee)
                                    }}
                                    &nbsp;{{ cointwo }}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div class="control-group" *ngIf="loggedIn">
                              <input
                                [disabled]="!selllimitForm.valid"
                                type="submit"
                                class="btn site-btn red-btn btn-block"
                                value="{{ 'Sell' | translate }} {{ coinone }}"
                                *ngIf="!selllimitisDisabled"
                                data-toggle="modal"
                                data-target="#MessagesModalselllimit"
                                [hidden]="true"
                                tabindex="14"
                              />

                              <input
                                type="submit"
                                name=""
                                value="{{ 'PleaseWait' | translate }}..."
                                class="btn site-btn red-btn btn-block"
                                disabled
                                *ngIf="selllimitisDisabled"
                              />
                            </div>

                            <div class="control-group" *ngIf="!loggedIn">
                              <a
                                class="btn site-btn red-btn btn-block"
                                routerLink="/giris"
                                >{{ "Signin" | translate }}</a
                              >
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <!-- PİYASA -->
                  <div id="market" class="tab-pane fade in">
                    <div class="row">
                      <!-- alış -->
                      <div class="col-md-6 col-sm-6 col-xs-12 frmright">
                        <div class="table-box">
                          <div class="pull-left subtitle">
                            <span style="margin-left: 18px">
                              {{ "Buy" | translate }} {{ coinone }}
                            </span>
                          </div>
                          <div class="deposit-whl">
                            <div class="deposit-balance">
                              <a routerLink="/cuzdan" class="text-uppercase"
                                >{{ cointwo }} {{ "Deposit" | translate }}
                              </a>
                              <table class="deposit-table-s" *ngIf="loggedIn">
                                <tbody>
                                  <tr>
                                    <td>{{ "Youhave" | translate }} :&nbsp;</td>
                                    <td>
                                      <span class="balance-color">
                                        {{
                                          formattingService.formatNumberTRY(
                                            balances[cointwo]
                                          )
                                        }}
                                      </span>
                                      {{ cointwo }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <form
                          autocomplete="off"
                          #buymarketForm="ngForm"
                          (ngSubmit)="onBuyMarketSubmit()"
                          id="buymarketForm"
                        >
                          <div class="form-box">
                            <!-- Fiyat  -->
                            <div class="form-group">
                              <label
                                for="priceex"
                                lang="tr"
                                class="control-label text-uppercase"
                                >{{ "Price" | translate }}</label
                              >
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control not-allowed"
                                  name="priceex"
                                  id="priceex"
                                  value="{{ 'MarketPrice' | translate }}"
                                  (keydown)="preventChanges($event)"
                                  (paste)="preventChanges($event)"
                                  (drop)="preventChanges($event)"
                                />
                                <div class="input-group-btn">
                                  <span class="btn btn-default">{{
                                    cointwo
                                  }}</span>
                                </div>
                              </div>
                            </div>

                            <!-- Miktar Giriniz -->
                            <div class="form-group">
                              <label
                                lang="tr"
                                class="control-label text-uppercase"
                                >{{ "Quantity" | translate }}</label
                              >
                              <div class="input-group">
                                <input
                                  type="text"
                                  placeholder="Miktar Giriniz"
                                  class="form-control"
                                  name="buymarketvolume"
                                  [class.is-invalid]="
                                    buymarketvolume.invalid &&
                                    buymarketvolume.touched
                                  "
                                  #buymarketvolume="ngModel"
                                  [(ngModel)]="form.buymarketvolume"
                                  pattern="[0-9.]+"
                                  [attr.readonly]="!loggedIn ? true : null"
                                  [disabled]="!loggedIn"
                                  (input)="onQuantityInput($event)"
                                  (keyup)="onKeyupBuyMarket($event)"
                                  oninput="this.value = this.value.replace(/,/g, '.').replace(/[^0-9.]/g, '');"
                                />
                                <div class="input-group-btn">
                                  <span class="btn btn-default"
                                    >{{ coinone }}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <!-- masraf -->
                            <div class="form-group">
                              <label class="control-label text-uppercase">
                                {{ "Fee" | translate }}
                              </label>

                              <div class="control-input">
                                <div class="value-field">
                                  <span class="control-label-coin">
                                    {{
                                      formattingService.formatNumberTRY(
                                        maliyet1234
                                      )
                                    }}
                                  </span>
                                </div>

                                <div class="coin-name">&nbsp;{{ cointwo }}</div>
                              </div>
                            </div>

                            <div class="control-group" *ngIf="loggedIn">
                              <input
                                [disabled]="!buymarketForm.valid"
                                type="submit"
                                class="btn site-btn green-btn btn-block"
                                value="{{ 'Buy' | translate }} {{ coinone }}"
                                *ngIf="!buymarketisDisabled"
                                data-toggle="modal"
                                data-target="#MessagesModalbymarket"
                                [hidden]="true"
                              />

                              <input
                                type="submit"
                                name=""
                                value="{{ 'PleaseWait' | translate }}..."
                                class="btn site-btn green-btn btn-block"
                                disabled
                                *ngIf="buymarketisDisabled"
                              />
                            </div>

                            <div class="control-group" *ngIf="!loggedIn">
                              <a
                                class="btn site-btn green-btn btn-block"
                                routerLink="/giris"
                                >{{ "Signin" | translate }}</a
                              >
                            </div>
                          </div>
                        </form>
                      </div>

                      <!-- satış -->
                      <div
                        class="col-md-6 col-sm-6 col-xs-12 border-left frmleft"
                      >
                        <div class="table-box">
                          <div class="pull-left subtitle">
                            <span style="margin-left: 18px">
                              {{ "Sell" | translate }} {{ coinone }}
                            </span>
                          </div>
                          <div class="deposit-whl">
                            <div class="deposit-balance">
                              <a
                                routerLink="/cuzdan"
                                class="text-uppercase"
                                style="margin-right: 8px"
                                >{{ coinone }} {{ "Deposit" | translate }}
                              </a>
                              <table class="deposit-table-s" *ngIf="loggedIn">
                                <tbody>
                                  <tr>
                                    <td>{{ "Youhave" | translate }} :&nbsp;</td>
                                    <td>
                                      <span class="balance-color">
                                        {{
                                          formattingService.formatCryptoWithCondition(
                                            coinone,
                                            cointwo,
                                            balances[coinone]
                                              ? balances[coinone]
                                              : balance.coinone
                                          )
                                        }}
                                      </span>
                                      {{ coinone }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <form
                          autocomplete="off"
                          #sellmarketForm="ngForm"
                          (ngSubmit)="onSellMarketSubmit()"
                          id="sellmarketForm"
                        >
                          <div class="form-box">
                            <!-- Fiyat -->
                            <div class="form-group">
                              <label
                                for="cointwoPrice"
                                lang="tr"
                                class="control-label text-uppercase"
                                >{{ "Price" | translate }}</label
                              >
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control not-allowed"
                                  name="cointwoPrice"
                                  id="cointwoPrice"
                                  value="{{ 'MarketPrice' | translate }}"
                                  (keydown)="preventChanges($event)"
                                  (paste)="preventChanges($event)"
                                  (drop)="preventChanges($event)"
                                />
                                <div class="input-group-btn">
                                  <span class="btn btn-default">{{
                                    cointwo
                                  }}</span>
                                </div>
                              </div>
                            </div>

                            <!-- Miktar Giriniz -->
                            <div class="form-group">
                              <label
                                lang="tr"
                                class="control-label text-uppercase"
                                >{{ "Quantity" | translate }}</label
                              >
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Miktar Giriniz"
                                  name="sellmarketvolume"
                                  [class.is-invalid]="
                                    sellmarketvolume.invalid &&
                                    sellmarketvolume.touched
                                  "
                                  #sellmarketvolume="ngModel"
                                  [(ngModel)]="form.sellmarketvolume"
                                  (input)="onQuantityInput($event)"
                                  (keyup)="onKeyupSellMarket($event)"
                                  oninput="this.value = this.value.replace(/,/g, '.').replace(/[^0-9.]/g, '');"
                                  pattern="[0-9.]+"
                                  [attr.readonly]="!loggedIn ? true : null"
                                  [disabled]="!loggedIn"
                                  required
                                />
                                <div class="input-group-btn">
                                  <span class="btn btn-default">{{
                                    coinone
                                  }}</span>
                                </div>
                              </div>
                            </div>

                            <div class="form-group">
                              <label class="control-label text-uppercase">
                                {{ "Fee" | translate }}
                              </label>

                              <div class="control-input">
                                <div class="value-field">
                                  <span class="control-label-coin">
                                    {{
                                      formattingService.formatNumberTRY(
                                        preBidMaliyet
                                      )
                                    }}
                                  </span>
                                </div>

                                <div class="coin-name">&nbsp;{{ cointwo }}</div>
                              </div>
                            </div>

                            <div class="control-group" *ngIf="loggedIn">
                              <input
                                [disabled]="!sellmarketForm.valid"
                                type="submit"
                                class="btn site-btn red-btn btn-block"
                                value="{{ 'Sell' | translate }} {{ coinone }}"
                                *ngIf="!sellmarketisDisabled"
                                data-toggle="modal"
                                data-target="#MessagesModalsellmarketvolume"
                                [hidden]="true"
                              />

                              <input
                                type="submit"
                                name=""
                                value="{{ 'PleaseWait' | translate }}..."
                                class="btn site-btn red-btn btn-block"
                                disabled
                                *ngIf="sellmarketisDisabled"
                              />
                            </div>

                            <div class="control-group" *ngIf="!loggedIn">
                              <a
                                class="btn site-btn red-btn btn-block"
                                routerLink="/giris"
                                >{{ "Signin" | translate }}</a
                              >
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- EMİR DEFTERİ -->
          <div class="col-md-3 col-sm-6 col-xs-12 order-2">
            <div class="order-book-whl">
              <div class="header-bar">{{ "OrderBook" | translate }}</div>
              <div class="content-bar">
                <div class="table-head">
                  <div class="text-left">
                    FİYAT
                    <!-- {{ "Price" | translate }} -->
                    <span class="site-table-nobold2">({{ cointwo }})</span>
                  </div>
                  <div class="text-center">
                    MİKTAR<span class="site-table-nobold2 text-right">
                      ({{ coinone }})
                    </span>
                  </div>
                  <div class="text-right">
                    {{ "Total" | translate }}
                    <span class="site-table-nobold2">({{ cointwo }})</span>
                  </div>
                </div>

                <div
                  class="scroll-content-sell"
                  data-simplebar
                  style="display: flex; flex-direction: column-reverse"
                >
                  <div class="table-div">
                    <div
                      class="table-body sell-order"
                      *ngIf="askBook && askBook.length > 0"
                    >
                      <div
                        class="div-tr"
                        *ngFor="let selltrade of askBook.slice(); let i = index"
                        (click)="
                          loggedIn
                            ? pickBuyorder(selltrade[0], selltrade[1], i)
                            : null
                        "
                      >
                        <div style="font-size: 13px">
                          <span class="sell-price-red">
                            <!-- {{
                              selltrade[0] | numberFormat : coinone : cointwo
                            }} -->
                            {{
                              formattingService.formatNumberTRY(selltrade[0])
                            }}
                          </span>
                        </div>
                        <div
                          style="font-size: 14px; text-align: center !important"
                        >
                          {{
                            formattingService.formatBasedOnQuantity(
                              coinone,
                              cointwo,
                              selltrade[1]
                            )
                          }}
                        </div>
                        <div
                          style="font-size: 14px; text-align: right !important"
                        >
                          {{
                            formattingService.formatNumberTRY(
                              selltrade[0] * usdtryPrice * selltrade[1]
                            )
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="scroll-content-live" data-simplebar>
                  <div class="table-div">
                    <div class="lveprice-wh1">
                      <div class="pt-0">
                        <div class="table-responsive table site-table">
                          <table width="100%">
                            <thead>
                              <tr>
                                <th style="width: 140px !important">
                                  <span class="text-left wight">
                                    <!-- &nbsp; {{ dataget.Last }} -->

                                    <!-- {{
                                      orderbookMarket[coinone + cointwo] &&
                                        orderbookMarket[coinone + cointwo]
                                          ?.price
                                        | numberFormat : coinone : cointwo
                                    }} -->
                                    {{
                                      orderbookMarket[coinone + cointwo] &&
                                        formattingService.formatNumberTRY(
                                          orderbookMarket[coinone + cointwo]
                                            ?.price
                                        )
                                    }}
                                  </span>
                                </th>
                                <th
                                  class="text-left"
                                  style="
                                    width: 140px !important;
                                    padding-left: 30px !important;
                                  "
                                >
                                  <!-- {{ dataget.Exchange }} % -->
                                </th>
                                <th
                                  style="
                                    width: 138px !important;
                                    text-align: right !important;
                                  "
                                >
                                  <b class="text-left wight">
                                    {{
                                      orderbookMarket[coinone + cointwo] &&
                                        orderbookMarket[
                                          coinone + cointwo
                                        ]?.exchange.toFixed(1) + " %"
                                    }}</b
                                  >

                                  <!-- <i class="fa fa-signal"></i> -->
                                </th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="scroll-content-buy" data-simplebar>
                  <div class="table-div">
                    <div
                      class="table-body buy-order"
                      *ngIf="bidBook && bidBook.length > 0"
                    >
                      <div
                        class="div-tr"
                        *ngFor="
                          let buytrade of bidBook.slice(0, 10);
                          let i = index
                        "
                        (click)="
                          loggedIn
                            ? pickSellorder(
                                buytrade[0].toFixed(3),
                                buytrade[1],
                                i
                              )
                            : null
                        "
                      >
                        <div style="font-size: 13px">
                          <!-- <span class="buy-price-green">
                            {{ buytrade[0] | numberFormat: coinone : cointwo }} &nbsp; (   {{ buytrade[2] }}) </span > -->

                          <span class="buy-price-green">
                            {{ formattingService.formatNumberTRY(buytrade[0]) }}
                          </span>
                        </div>
                        <div
                          style="font-size: 14px; text-align: center !important"
                        >
                          {{
                            formattingService.formatBasedOnQuantity(
                              coinone,
                              cointwo,
                              buytrade[1]
                            )
                          }}
                        </div>
                        <div
                          style="font-size: 14px; text-align: right !important"
                        >
                          {{
                            formattingService.formatNumberTRY(
                              buytrade[0] * usdtryPrice * buytrade[1]
                            )
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- EMİR GEÇMİŞİ -->
        <div class="order-table" *ngIf="loggedIn">
          <ul class="nav nav-tabs ordertabs">
            <li class="active">
              <a data-toggle="tab" style="margin-left: -10px" href="#menu1"
                >AÇIK EMİRLERİM</a
              >
            </li>
            <li>
              <a data-toggle="tab" href="#menu2">GERÇEKLEŞEN EMİRLERİM</a>
            </li>
          </ul>
        </div>

        <!-- menu1 menu2 -->
        <div class="tab-content" *ngIf="loggedIn">
          <div id="menu1" class="tab-pane fade in active">
            <h3></h3>
            <div class="">
              <div class="table-responive">
                <table
                  width="100%"
                  cellpadding="5"
                  cellspacing="0"
                  class="table tbg keywords tb-gray site-table"
                >
                  <thead>
                    <tr>
                      <th class="menu1Date">TARİH</th>
                      <th class="menu1Pair">SEMBOL</th>
                      <th class="menu1Tip">TİP</th>
                      <th class="menu1OrderType">EMİR</th>
                      <th class="menu1Price">FİYAT</th>
                      <th class="menu1quantity">MİKTAR</th>
                      <!-- <th class="menu1Volume">HACİM</th> -->
                      <th class="menu1Total">KALAN TOPLAM (TRY)</th>
                      <th class="menu1Filled">GERÇEKLEŞEN</th>
                      <th class="menu1Remainder">KALAN</th>
                      <th class="menu1Ratio">ORAN</th>
                      <th class="menu1Status text-center">DURUM</th>
                    </tr>

                    <tr *ngFor="let myopentrade of mypendingtrade">
                      <td class="menu1Date">
                        {{ myopentrade.date | localizedDate }}
                      </td>
                      <td class="menu1Pair">{{ myopentrade.pair }}</td>
                      <td class="menu1Tip">
                        <span
                          class="t-green"
                          [ngClass]="
                            myopentrade.type == 'sell' ||
                            myopentrade.type == 'Satış'
                              ? 't-red'
                              : 't-green'
                          "
                        >
                          <div
                            class="t-green"
                            *ngIf="
                              myopentrade.type == 'buy' ||
                              myopentrade.type == 'Alış'
                            "
                          >
                            ALIŞ
                          </div>
                          <div
                            *ngIf="
                              myopentrade.type == 'sell' ||
                              myopentrade.type == 'Satış'
                            "
                            class="t-red"
                          >
                            SATIŞ
                          </div>
                        </span>
                      </td>
                      <td class="menu1OrderType">
                        {{ myopentrade.ordertype }}
                      </td>
                      <td class="menu1Price">
                        {{
                          formattingService.formatNumberTRY(myopentrade.price)
                        }}
                      </td>
                      <td class="menu1quantity">
                        {{
                          formattingService.formatBasedOnQuantity(
                            coinone,
                            cointwo,
                            myopentrade.quantity
                          )
                        }}
                      </td>
                      <td class="menu1Total">
                        {{
                          formattingService.formatNumberTRY(myopentrade.total)
                        }}
                      </td>
                      <td class="menu1Filled">
                        {{
                          formattingService.formatCryptoWithCondition(
                            coinone,
                            cointwo,
                            myopentrade.filled
                          )
                        }}
                      </td>
                      <td class="menu1Remainder">
                        {{
                          formattingService.formatCryptoWithCondition(
                            coinone,
                            cointwo,
                            myopentrade.quantity - myopentrade.filled
                          )
                        }}
                      </td>

                      <td class="menu1Ratio">
                        %{{ getFormattedPercentage(myopentrade) }}
                      </td>
                      <td class="menu1Status text-center">
                        <div
                          *ngIf="
                            myopentrade.status == 'Bekliyor';
                            then thenBlock;
                            else elseBlock
                          "
                        ></div>
                        <ng-template #thenBlock>
                          <button
                            [hidden]="true"
                            class="btn btn-sm viewbtn red-btn"
                            (click)="
                              cancelorder(myopentrade.tid, myopentrade.type)
                            "
                          >
                            {{ "Cancel" | translate }}
                          </button>
                        </ng-template>

                        <ng-template #elseBlock>-</ng-template>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-right" colspan="12">
                        <button
                          *ngIf="mypendingtrade && mypendingtrade.length > 1"
                          [hidden]="true"
                          class="btn btn-sm viewbtn red-btn-all"
                          (click)="allCancelOrder()"
                        >
                          Tümünü İptal Et
                        </button>
                      </td>
                    </tr>
                    <tr
                      *ngIf="
                        mypendingtrade == undefined ||
                        mypendingtrade.length == 0
                      "
                    >
                      <td colspan="12">
                        <div class="order-messages text-center">
                          Kayıtlı açık emriniz bulunamadı!
                        </div>
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>

          <div id="menu2" class="tab-pane fade">
            <h3></h3>

            <div class="box-section">
              <div class="content-bar-order-histry">
                <div class="table-head">
                  <div class="dataHover">İŞLEM</div>
                  <div id="menu2Symbol">SEMBOL</div>
                  <div class="dataHover" id="menu2Date">TARİH</div>
                  <div id="menu2Quantity">MİKTAR</div>
                  <div id="menu2Price">FİYAT</div>
                  <div id="menu2RealPrice">GERÇEKLEŞEN FİYAT</div>
                  <div id="menu2TotalValue">MASRAF</div>
                  <div id="menu2TotalValue">TOPLAM</div>
                </div>

                <div class="scroll-content" data-simplebar>
                  <div class="table-div">
                    <div
                      class="div-tr"
                      *ngFor="let myopentrade of completeHistory"
                    >
                      <div id="menu2Symbol">
                        <span
                          *ngIf="
                            myopentrade.type == 'sell' ||
                            myopentrade.type == 'Satış' ||
                            myopentrade.type == 'Sat'
                          "
                          [ngClass]="
                            myopentrade.type == 'sell' ||
                            myopentrade.type == 'Satış' ||
                            myopentrade.type == 'Sat'
                              ? 't-red'
                              : 't-red'
                          "
                          class="t-red"
                          >SATIŞ</span
                        >
                        <span
                          *ngIf="
                            myopentrade.type == 'buy' ||
                            myopentrade.type == 'Alış' ||
                            myopentrade.type == 'Al'
                          "
                          [ngClass]="
                            myopentrade.type == 'sell' ||
                            myopentrade.type == 'Satış' ||
                            myopentrade.type == 'Sat'
                              ? 't-red'
                              : 't-green'
                          "
                          class="t-green"
                          >ALIŞ</span
                        >
                      </div>

                      <div>
                        {{ myopentrade.coinone }}/{{ myopentrade.cointwo }}
                      </div>

                      <div id="menu2Date">
                        {{ myopentrade.updated_at | localizedDate }}
                      </div>
                      <div id="menu2Quantity">
                        {{
                          formattingService.formatBasedOnQuantity(
                            coinone,
                            cointwo,
                            myopentrade.volume
                          )
                        }}
                      </div>
                      <div id="menu2Price">
                        {{
                          formattingService.formatNumberTRY(myopentrade.price)
                        }}
                      </div>
                      <div id="menu2RealPrice">
                        {{
                          formattingService.formatNumberTRY(
                            myopentrade.real_price
                          )
                        }}
                      </div>
                      <div id="menu2TotalValue">
                        {{
                          formattingService.formatNumberTRY(myopentrade.fees)
                        }}
                      </div>
                      <div id="menu2TotalValue">
                        {{
                          formattingService.formatNumberTRY(
                            myopentrade.real_total
                          )
                        }}
                      </div>
                    </div>

                    <div
                      *ngIf="
                        completeHistory == undefined ||
                        completeHistory.length == 0
                      "
                    >
                      <div class="order-messages" style="padding-top: 12px">
                        Gerçekleşen emriniz bulunamadı!
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</article>
<app-userfooter></app-userfooter>
<script>
  am4core.ready(function () {
    // chart code
  });
</script>

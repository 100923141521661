import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})

export class SupportTicketService {
  private _url: string = environment.baseUrl;
  constructor(private http: HttpClient) {}

  getTicketList(data) {
    return this.http
      .post(`${this._url}/ticketlist`, data)
      .catch(this.errorHandler);
  }

  getpusherticketlist() {
    return this.http
      .post(`${this._url}/pusherticketlist`, [])
      .catch(this.errorHandler);
  }

  createTicket(data) {
    return this.http
      .post(`${this._url}/createticket`, data)
      .catch(this.errorHandler);
  }

  viewTicketChat(data) {
    return this.http
      .post(`${this._url}/viewchats`, data)
      .catch(this.errorHandler);
  }

  replyTicketChat(data) {
    return this.http
      .post(`${this._url}/replychat`, data)
      .catch(this.errorHandler);
  }
  
  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error.message || "Server error");
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { BeforeLoginService } from './Services/before-login.service';
import { PageNotFoundComponent } from './Components/page-not-found/page-not-found.component';
import { HomeComponent } from './Components/home/home.component';
import { ContactComponent } from './Components/contact/contact.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ProfileComponent } from './Components/profile/profile.component';
import { WalletsComponent } from './Components/wallets/wallets.component';
import { SupportComponent } from './Components/support/support.component';
import { ChatComponent } from './Components/chat/chat.component';
import { CreateticketComponent } from './Components/createticket/createticket.component';
import { ExchangeComponent } from './Components/exchange/exchange.component';
import { smsVerificationComponent } from './Components/smsVerification/smsVerification.component';
import { sequrityAccountComponent } from './Components/sequrityAccount/sequrityAccount.component';
import { verifyemailComponent } from './Components/emailVerification/verifyemail.component';
import { fileuploadComponent } from './Components/fileupload/fileupload.component';
import { fileuploadStatusComponent } from './Components/fileuploadStatus/fileuploadStatus.component';
import { WithdrawComponent } from './Components/withdraw/withdraw.component';
import { DepositComponent } from './Components/deposit/deposit.component';
import { DeposithistroyComponent } from './Components/deposithistroy/deposithistroy.component';
import { WithdrawhistroyComponent } from './Components/withdrawhistroy/withdrawhistroy.component';
import { TradehistroyComponent } from './Components/tradehistroy/tradehistroy.component';
import { BanklistComponent } from './Components/banklist/banklist.component';
import { KvkkComponent } from './StaticPages/kvkk/kvkk.component';
import { CompanyInfoComponent } from './StaticPages/company-info/company-info.component';
import { CompanyAnnouncementComponent } from './StaticPages/company-announcement/company-announcement.component';
import { CompanyLegalRegulationComponent } from './StaticPages/company-legal-regulation/company-legal-regulation.component';
import { UserAgreementComponent } from './StaticPages/user-agreement/user-agreement.component';
import { PrivacyPolicyComponent } from './StaticPages/privacy-policy/privacy-policy.component';
import { UserNotificationComponent } from './StaticPages/user-notification/user-notification.component';
import { WhatIsBitcoinComponent } from './StaticPages/what-is-bitcoin/what-is-bitcoin.component';
import { HowToStoreBitcoinComponent } from './StaticPages/how-to-store-bitcoin/how-to-store-bitcoin.component';
import { HowToBuyBitcoinComponent } from './StaticPages/how-to-buy-bitcoin/how-to-buy-bitcoin.component';
import { SecurityPageComponent } from './StaticPages/security-page/security-page.component';
import { BitcoinWalletComponent } from './StaticPages/bitcoin-wallet/bitcoin-wallet.component';
import { DepositAndWithdrawalComponent } from './StaticPages/deposit-and-withdrawal/deposit-and-withdrawal.component';
import { FSQComponent } from './StaticPages/fsq/fsq.component';
import { LimitsAndRulesComponent } from './StaticPages/limits-and-rules/limits-and-rules.component';
import { CookiesPolicyComponent } from './StaticPages/cookies-policy/cookies-policy.component';
import { TwoFactorsLoginComponent } from './Components/two-factors-login/two-factors-login.component';
import { TwofaKriptoComponent } from './Components/modals/twofa-kripto/twofa-kripto-seq.component';
import { DeviceVerifyComponent } from './Components/deviceVerification/device-verify.component';
import { PageRedirectionService } from './Services/page-redirection.service';

const routes: Routes = [
  { path: 'hakkimizda', component: CompanyInfoComponent },
  { path: 'duyurular', component: CompanyAnnouncementComponent },
  { path: 'yasal-duzenlemeler', component: CompanyLegalRegulationComponent },
  { path: 'kullanici-sozlesmesi', component: UserAgreementComponent },
  { path: 'gizlilik-politikasi', component: PrivacyPolicyComponent },
  { path: 'kullanici-bilgilendirme', component: UserNotificationComponent },
  { path: 'bitcoin-nedir', component: WhatIsBitcoinComponent },
  { path: 'bitcoin-nasil-saklanir', component: HowToStoreBitcoinComponent },
  { path: 'bitcoin-nasil-alinir', component: HowToBuyBitcoinComponent },
  { path: 'guvenlik', component: SecurityPageComponent },
  { path: 'bitcoin-cuzdani', component: BitcoinWalletComponent },
  { path: 'yatirma-ve-cekme', component: DepositAndWithdrawalComponent },
  { path: 'sikca-sorulan-sorular', component: FSQComponent },
  { path: 'sss', component: FSQComponent },
  { path: 'komisyon-oranlari', component: LimitsAndRulesComponent },
  { path: 'home', component: HomeComponent },
  { path: 'iletisim', component: ContactComponent },
  { path: 'cerez-politikasi', component: CookiesPolicyComponent },
  { path: '', component: HomeComponent, pathMatch: 'full' },

  {
    path: 'kvkk',
    component: KvkkComponent,
  },

  {
    path: 'giris',
    component: LoginComponent,
    canActivate: [BeforeLoginService],
  },
  {
    path: 'verifyemail/:status',
    component: LoginComponent,
    canActivate: [BeforeLoginService],
  },
  {
    path: 'hesap-olustur',
    component: RegisterComponent,
    canActivate: [BeforeLoginService],
  },
  {
    path: 'sifremi-unuttum',
    component: ForgotPasswordComponent,
    canActivate: [BeforeLoginService],
  },
  {
    path: 'sifre-yenile/:token',
    component: ResetPasswordComponent,
    canActivate: [BeforeLoginService],
  },

  {
    path: 'ikili-dogrulama',
    component: TwoFactorsLoginComponent,
    canActivate: [PageRedirectionService],
  },

  {
    path: 'modals',
    component: TwofaKriptoComponent,
    canActivate: [PageRedirectionService],
  },

  {
    path: 'hesabim',
    component: ProfileComponent,
    canActivate: [PageRedirectionService],
  },
  {
    path: 'bankalar',
    component: BanklistComponent,
    canActivate: [PageRedirectionService],
  },

  {
    path: 'hesap-guvenlik',
    component: sequrityAccountComponent,
    canActivate: [PageRedirectionService],
  },

  {
    path: 'sms-dogrula',
    component: smsVerificationComponent,
    canActivate: [PageRedirectionService],
  },

  {
    path: 'email-dogrula',
    component: verifyemailComponent,
    canActivate: [PageRedirectionService],
  },

  {
    path: 'cihaz-dogrula',
    component: DeviceVerifyComponent,
    canActivate: [PageRedirectionService],
  },

  {
    path: 'dosya-yukle',
    component: fileuploadComponent,
    canActivate: [PageRedirectionService],
  },

  {
    path: 'durum',
    component: fileuploadStatusComponent,
    canActivate: [PageRedirectionService],
  },

  {
    path: 'cuzdan',
    component: WalletsComponent,
    canActivate: [PageRedirectionService],
  },

  {
    path: 'kripto-yatirma/:coin',
    component: DepositComponent,
    canActivate: [PageRedirectionService],
  },

  {
    path: 'kripto-cekme/:coin',
    component: WithdrawComponent,
    canActivate: [PageRedirectionService],
  },

  {
    path: 'yatirma-gecmisi',
    component: DeposithistroyComponent,
    canActivate: [PageRedirectionService],
  },
  {
    path: 'cekme-gecmisi',
    component: WithdrawhistroyComponent,
    canActivate: [PageRedirectionService],
  },
  {
    path: 'islem-gecmisi',
    component: TradehistroyComponent,
    canActivate: [PageRedirectionService],
  },
  {
    path: 'destek',
    component: SupportComponent,
    canActivate: [PageRedirectionService],
  },
  {
    path: 'chat/:ticketid',
    component: ChatComponent,
    canActivate: [PageRedirectionService],
  },
  {
    path: 'destek-talebi-olustur',
    component: CreateticketComponent,
    canActivate: [PageRedirectionService],
  },
  {
    path: 'borsa',
    component: ExchangeComponent,
    //canActivate: [PageRedirectionService],
  },
  {
    path: 'borsa/:coinone/:cointwo',
    component: ExchangeComponent,
    //canActivate: [PageRedirectionService],
  },
  {
    path: 'borsa/:coinone',
    component: ExchangeComponent,
    //canActivate: [PageRedirectionService],
  },

  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<!-- Bitcoin Nasıl Saklanır? Pages -->
<app-header></app-header>
<article>
  <section class="innerbannerpage">
    <div class="container">
      <div class="row">
        <table id="how-to-store-bitcoin-header">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: 162px"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                  alt=""
                />
                &nbsp;&nbsp;
                <small> {{ "HowToStoreBitcoin" | translate }}?</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row row-style">
        <p>
          Alım yapılmış kripto varlıklar çevrim içi veya çevrim dışı cüzdanlar
          vasıtasıyla saklanmaktadır. Bitistanbul’un kullandığı teknoloji ve
          varlık saklama mimarisi en güvenli metodları içeren soğuk saklama
          metodunu da içerir dolayısı ile varlıklarınız için maksimum güvence
          içermeyi amaçlar.
        </p>
      </div>

      <div class="row row-style">
        <p>
          Bununla beraber bireysel olarak ta Bitcoinlerinizi bir donanım cüzdanı
          (hardware wallet), çok imzalı bir cüzdan veya soğuk depolama cüzdan
          (cold wallet)larında depolayabilirsiniz. Bitcoinleriniz dijital
          varlıklar olup fiziki olarak el değiştiremezler, dolayısı ile kripto
          varlık sahipliği özünde fiziki bir sahiplik değil, bu dijital
          varlıklara erişim izni anlamına gelmektedir. Bu sebeple maksimum
          güvenlik için kripto varlıklarınızın bulunduğu cüzdanınızın bir şifre
          yaratıp, kağıda yazılmasını ve güvenli bir yerde (veya yedek olarak
          birkaç güvenli yerde) saklanmasını sağlayabilirsiniz.
        </p>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

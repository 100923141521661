import { Component, NgModule, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { WalletService } from "src/app/Services/wallet.service";
import { AlertifyService } from "src/app/Services/alertify.service";
import { Subscription } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
declare var $: any;
@Component({
  selector: "app-deposit",
  templateUrl: "./deposit.component.html",
  styleUrls: ["./deposit.component.css"],
})
export class DepositComponent implements OnInit {
  favoriteCoin: string;
  public accountno: string;
  public coins = null;
  public selectedAddr;
  public selectedAddrType;
  public selectedCoin: string = "";
  public selectedAddrBarcode;
  public selectedAccount_Name;
  public selectedBankIban;
  public filterAddr: any;
  public filterAddrBank: any;
  public error: any;
  public success = null;
  public data = null;
  public getcoin = null;
  //public lstTxs = null;
  public lstAddress = null;
  public banklist = null;
  public lstCoins: any = ["BTC", "ETH", "USDT"];
  public walletBalanceSubscription: Subscription | undefined;
  public cryptoDepositSubscription: Subscription | undefined;
  isLoading: boolean = true;
  isLoadingCyypto: boolean = true;
  copyButtonTextAccountNo = "kopyala";
  copyButtonTextCompany = "kopyala";
  copyButtonCoinAddress = "kopyala";

  public depositdetails = {
    name: null,
    address: null,
    qrcode: null,
    symbol: null,
  };

  constructor(
    private wallet: WalletService,
    private route: ActivatedRoute,
    private router: Router,
    private alertifyService: AlertifyService,
    private metaService: Meta,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.metaService.addTags([
      {
        name: "description",
        content: "Para yatırma işlemlerinizi hemen 7/24 gerçekleştirin.",
      },
      {
        name: "keywords",
        content: "cüzdan,Para Yatırma, yatirma",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);

    const pageTitle = "Para Yatırma";
    this.titleService.setTitle(pageTitle);

    await this.fetchAddressData();
    await this.fetchCoinDetails();
    await this.wallet
      .adminbanklists()
      .subscribe((data) => this.handleGetBank(data));
    this.walletBalanceSubscription = this.wallet.getWalletbalance().subscribe(
      (data) => this.handleCountryList(data),
      (error) => this.handleError(error)
    );

    this.handleFavoriteCoin();
  }

  private async fetchAddressData(): Promise<void> {
    try {
      const data = await this.wallet.getAddress().toPromise();
      this.handleGetAddress(data);
    } catch (error) {
      console.error("Adres Verisi Çekilirken Hata Oluştu!");
    } finally {
      this.isLoading = false;
    }
  }

  handleGetAddress(data: any): void {
    if (!this.isLoadingCyypto) {
      return;
    }

    if (data.success) {
      this.lstAddress = data.result;
    } else {
      this.alertifyService.error(data.message);
      this.isLoadingCyypto = false;
    }
  }

  private async fetchCoinDetails(): Promise<void> {
    this.getcoin = this.route.snapshot.paramMap.get("coin").toUpperCase();
    this.getcoindetails(this.getcoin);
  }

  getcoindetails(coin) {
    const myFormData = new FormData();
    myFormData.append("coin", coin);
    this.cryptoDepositSubscription = this.wallet
      .getCryptodeposit(myFormData)
      .subscribe(
        (data) => this.handleCoinDetails(data),
        (error) => this.handleError(error)
      );
  }

  handleCoinDetails(res) {
    if (res.success) {
      this.depositdetails = res.result;
    } else {
      this.error = res.message;
      // $('[href="#TRY"]').tab("show");
      if (this.getcoin === "TRY") {
        $('[href="#TRY"]').tab("show");
      } else {
        $('[href="#KRIPTO"]').tab("show");
      }
    }
  }

  handleCountryList(data) {
    if (data.success) {
      this.coins = data.result.wallets;
      this.coins.forEach((el) => {
        el.total = Number(el.total) + Number(el.escrow);
      });
      //this.lstTxs = data.result.history;
    } else {
      this.coins = [];
    }
  }

  private handleFavoriteCoin(): void {
    if (this.getcoin) {
      this.favoriteCoin = this.getcoin.toUpperCase();

      if (this.getcoin !== "TRY" && this.isLoadingCyypto === false) {
        $('[href="#TRY"]').tab("show");
        this.router.navigate(["kripto-yatirma/TRY"]);
        return;
      }

      if (this.getcoin === "TRY") {
        $('[href="#TRY"]').tab("show");
      } else {
        $('[href="#KRIPTO"]').tab("show");
      }

      this.setFilterAddr(this.favoriteCoin);
    }
  }

  handleGetBank(data) {
    if (data.success) {
      this.banklist = data?.result;
    } else {
      this.alertifyService.error("Banka kayıtları yüklenirken hata oluştu!");
    }
  }

  //#region TRY

  getTRYClick() {
    this.router.navigate(["kripto-yatirma/TRY"]);

    if (!this.banklist)
      this.alertifyService.error("Banka bilgileri yüklenemedi!");
  }

  copyInputTRYAccountNo(inputElement: HTMLInputElement) {
    // Input değerini seç ve kopyala
    inputElement.select();
    document.execCommand("copy");
    // Buton metnini geçici olarak değiştir
    this.copyButtonTextAccountNo = "kopyalandı!";
    // 2 saniye sonra eski haline döndür
    setTimeout(() => {
      this.copyButtonTextAccountNo = "kopyala";
    }, 2500);
  }

  copyInputTRYCompany(inputElement: HTMLInputElement) {
    // Input değerini seç ve kopyala
    inputElement.select();
    document.execCommand("copy");
    // Buton metnini geçici olarak değiştir
    this.copyButtonTextCompany = "kopyalandı!";
    // 2 saniye sonra eski haline döndür
    setTimeout(() => {
      this.copyButtonTextCompany = "kopyala";
    }, 2500);
  }

  setFilterAddrBank(e) {
    {
      const svBank = this.banklist.filter((t) => t.bank_id == e.target.value);
      this.filterAddrBank = svBank;
      if (svBank.length > 0) {
        this.selectedAccount_Name = svBank[0].account_name;
        this.selectedBankIban = svBank[0].bank_iban;
      } else {
        this.selectedAccount_Name = "";
        this.selectedBankIban = "";
      }
    }
  }

  //#endregion TRY

  //#region Coin

  public setFilterAddr(filterName: string): void {
    if (this.lstAddress === null) {
      // this.alertifyService.error(
      //   "Adres yüklenirken hata oluştu. Destek ekibi ile iletişime geçiniz."
      // );
    } else {
      // Burada `filterName` ile `kripto-yatirma` sayfasına yönlendirme yapıyoruz
      this.router.navigate(["kripto-yatirma", filterName]);

      // filterName ile `lstAddress` içinde filtreleme yapıyoruz
      const sv = this.lstAddress.filter((t) => t.currency === filterName);
      this.filterAddr = sv;

      this.selectedCoin = this.filterAddr[0]?.currency;
      this.getcoindetails(this.selectedCoin);

      // Seçili adresteki bilgileri güncelle
      if (sv.length > 0) {
        this.selectedAddr = sv[0].address;
        this.selectedAddrType = sv[0].type;
        this.selectedAddrBarcode = sv[0].qr;
      } else {
        this.selectedAddr = "";
        this.selectedAddrType = "";
        this.selectedAddrBarcode = "";
      }
    }
  }

  public getKriptoClick(): void {
    // Eğer selectedCoin tanımlı değilse, varsayılan olarak 'BTC' ayarla
    if (!this.selectedCoin) {
      this.selectedCoin = "BTC";
    }

    // favoriteCoin'i selectedCoin ile güncelle
    this.favoriteCoin = this.selectedCoin;

    // setFilterAddr metodunu favoriteCoin ile çağır
    this.setFilterAddr(this.favoriteCoin);

    // selectedCoin ile yönlendirme yap
    this.router.navigate(["kripto-yatirma", this.selectedCoin]);
  }

  showAddrAndQR(addr) {
    this.selectedAddr = addr.address;
    this.selectedAddrBarcode = addr.qr;
    this.selectedAddrType = addr.type;
  }

  copyInputAddress(inputElement: HTMLInputElement) {
    inputElement.select();
    document.execCommand("copy");

    // Buton metnini geçici olarak "Kopyalandı!" yap
    this.copyButtonCoinAddress = "kopyalandı!";

    // 2.5 saniye sonra tekrar eski haline döndür
    setTimeout(() => {
      this.copyButtonCoinAddress = "kopyala";
    }, 2500);
  }
  //#endregion Coin

  handleError(error) {
    this.error = error.message;
  }
}

<app-header-empty></app-header-empty>

<article class="innerpagearticle">
  <section class="innerbannerpage">
    <div class="container">
      <!-- app-alert-modal -->
      <app-alert-modal
        *ngIf="error"
        [error]="error"
        (close)="error = null"
      ></app-alert-modal>

      <!-- modal img -->
      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body text-center">
              <img
                src="assets/images/119.png"
                style="width: 100%; height: 100%"
                alt="ornek resim"
              />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                class="btn secondary-close"
                title="çıkış"
              >
                Çıkış
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- accountVerification header -->
      <div class="row">
        <table id="accountVerification">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 17px; margin-right: 105px"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  alt=""
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                />
                &nbsp;&nbsp;
                <small> Hesap Doğrulama</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- stepper  -->
      <div class="row">
        <h5 class="text-center mt-30">
          Hesabınızın doğrulanması ve üyelik işlemlerinizin tamamlanması için
          aşağıda istenen belgeleri tarafımıza iletiniz.
        </h5>

        <div id="stepper1" class="bs-stepper mt-20">
          <div class="bs-stepper-header">
            <div class="step" data-target="#test-l-1">
              <button class="step-trigger">
                <span class="bs-stepper-circle">1</span>
                <span class="bs-stepper-label">Kimlik Bilgileri</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-2">
              <button class="step-trigger">
                <span class="bs-stepper-circle">2</span>
                <span class="bs-stepper-label">Adres Bilgileri</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-3">
              <button class="step-trigger">
                <span class="bs-stepper-circle">3</span>
                <span class="bs-stepper-label">Fotoğraf/Özçekim Yükleme</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-4">
              <button class="step-trigger">
                <span class="bs-stepper-circle">4</span>
                <span class="bs-stepper-label">Müşteri Tanıma Formu</span>
              </button>
            </div>
          </div>

          <div class="bs-stepper-content mt-20">
            <div id="test-l-1" class="content">
              <!-- Kimlik Ön Arka Yüz -->
              <form #identityForm="ngForm" id="identityForm" autocomplete="off">
                <div *ngIf="userdata?.identity_type == '1'">
                  <div class="form-group">
                    <label for="document[1]">Kimlik Ön Yüz</label>
                    <span style="font-size: 14px">
                      <i> ( T.C. Kimlik No: {{ userdata?.tc }} )</i></span
                    >
                    <input
                      id="formpadding"
                      type="file"
                      name="document[1]"
                      class="form-control"
                      accept=".jpg,.jpeg,.png,.pdf,.SVG"
                      (change)="folderUpload($event)"
                      aria-label="document[1]"
                      required
                    />
                  </div>
                  <br />
                  <div class="form-group">
                    <label for="document[2]">Kimlik Arka Yüz</label>
                    <input
                      id="formpadding"
                      type="file"
                      name="document[2]"
                      class="form-control"
                      accept=".jpg,.jpeg,.png,.pdf,.SVG"
                      (change)="folderUpload2($event)"
                      aria-label="Search"
                      required
                    />
                  </div>
                  <div class="mt-20 contentDesc">
                    <ul>
                      <li>
                        Değerli kullanıcımız kimlik doğrulama işlemi Bitistanbul
                        hesabınızın emniyet ve güvenliğini sağlamaya yardımcı
                        olur.
                      </li>
                      <li>
                        Hesabınız ile işlem yapmaya başlamak için gerekli
                        belgeleri yüklemeniz gerekmektedir.
                      </li>
                      <li>
                        Belgeleri yüklerken okunabilir olmalarına dikkat edin.
                        Belgeler yalnızca <b>jpg, png ve pdf </b>uzantılarına
                        sahip ve <b>maksimum 10 MB</b> büyüklüğünde olmalıdır.
                      </li>

                      <li>
                        T.C. kimlik kartınızın ön ve arka yüzünü sisteme
                        yüklemelisiniz. Lütfen T.C. kimlik kartınızı kontrol
                        ederek geçerlilik süresinin bitmediğinden emin olunuz.
                      </li>
                    </ul>
                  </div>
                  <div class="text-center mt-30">
                    <input
                      type="submit"
                      name=""
                      value="İleri"
                      *ngIf="!isDisabledIdentity"
                      [disabled]="!identityForm.form.valid"
                      class="btn editButton"
                      (click)="onSaveIdentity()"
                    />
                    <input
                      type="submit"
                      name=""
                      value="{{ 'Pleasewait' | translate }}..."
                      class="btn editButton"
                      disabled
                      *ngIf="isDisabledIdentity"
                    />
                  </div>
                </div>
              </form>

              <!-- Pasaport Ön Yüz -->
              <form
                #identityFormPassport="ngForm"
                id="identityFormPassport"
                autocomplete="off"
              >
                <div *ngIf="userdata?.identity_type == '2'">
                  <div class="form-group">
                    <label for="document[5]">Pasaport Ön Yüz</label
                    ><span style="font-size: 14px">
                      <i>
                        ( Pasasort No:{{ userdata?.identity_number }} )</i
                      ></span
                    >

                    <input
                      id="formpadding"
                      type="file"
                      name="document[5]"
                      class="form-control file-selector-button"
                      accept=".jpg,.jpeg,.png,.pdf,.SVG"
                      (change)="folderUpload5($event)"
                      aria-label="Pasasort"
                      required
                    />

                    <div class="mt-20 contentDesc">
                      <ul>
                        <li>
                          Değerli kullanıcımız kimlik doğrulama işlemi
                          Bitistanbul hesabınızın emniyet ve güvenliğini
                          sağlamaya yardımcı olur.
                        </li>
                        <li>
                          Hesabınız ile işlem yapmaya başlamak için gerekli
                          belgeleri yüklemeniz gerekmektedir.
                        </li>
                        <li>
                          Belgeleri yüklerken okunabilir olmalarına dikkat edin.
                          Belgeler yalnızca <b>jpg, png ve pdf </b>uzantılarına
                          sahip ve <b>maksimum 10 MB</b> büyüklüğünde olmalıdır.
                        </li>

                        <li>
                          T.C. vatandaşı değilseniz pasaportunuzun ilk sayfasını
                          sisteme yüklemelisiniz. Lütfen gerekli belgeleri
                          kontrol ederek geçerlilik sürelerinin bitmediğinden
                          emin olun.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="text-center mt-40">
                    <input
                      type="submit"
                      name=""
                      value="İleri"
                      *ngIf="!isDisabledPassport"
                      [disabled]="!identityFormPassport.form.valid"
                      class="btn editButton"
                      (click)="onSaveIdentityPasaport()"
                    />
                    <input
                      type="submit"
                      name=""
                      value="{{ 'Pleasewait' | translate }}..."
                      class="btn editButton"
                      disabled
                      *ngIf="isDisabledPassport"
                    />
                  </div>
                </div>
              </form>

              <!-- Yabancı Tc Kimlik No -->
              <form
                #identityFormforeign="ngForm"
                id="identityFormforeign"
                autocomplete="off"
              >
                <div *ngIf="userdata?.identity_type == '3'">
                  <div class="form-group">
                    <label for="document[1]">Kimlik Ön Yüz</label>
                    <span style="font-size: 14px">
                      <i>
                        ( Yabancı T.C. Kimlik No:
                        {{ userdata.identity_number }} )</i
                      ></span
                    >
                    <input
                      id="formpadding"
                      type="file"
                      name="document[1]"
                      class="form-control"
                      accept=".jpg,.jpeg,.png,.pdf,.SVG"
                      (change)="folderUpload($event)"
                      aria-label="Search"
                      required
                    />
                  </div>
                  <br />
                  <div class="form-group">
                    <label for="document[2]">Kimlik Arka Yüz</label>
                    <input
                      type="file"
                      name="document[2]"
                      class="form-control"
                      accept=".jpg,.jpeg,.png,.pdf,.SVG"
                      (change)="folderUpload2($event)"
                      aria-label="Search"
                      required
                    />
                  </div>
                  <div class="mt-20 contentDesc">
                    <ul>
                      <li>
                        Değerli kullanıcımız kimlik doğrulama işlemi Bitistanbul
                        hesabınızın emniyet ve güvenliğini sağlamaya yardımcı
                        olur.
                      </li>
                      <li>
                        Hesabınız ile işlem yapmaya başlamak için gerekli
                        belgeleri yüklemeniz gerekmektedir.
                      </li>
                      <li>
                        Belgeleri yüklerken okunabilir olmalarına dikkat edin.
                        Belgeler yalnızca <b>jpg, png ve pdf </b>uzantılarına
                        sahip ve <b>maksimum 10 MB</b> büyüklüğünde olmalıdır.
                      </li>

                      <li>
                        T.C. vatandaşı değilseniz T.C. ikamet izni kartınızın ön
                        ve arka yüzünü sisteme yüklemelisiniz. Lütfen gerekli
                        belgeleri kontrol ederek geçerlilik sürelerinin
                        bitmediğinden emin olun.
                      </li>
                    </ul>
                  </div>

                  <div class="text-center mt-30">
                    <input
                      type="submit"
                      name=""
                      value="İleri"
                      *ngIf="!isDisabledIdentity"
                      [disabled]="!identityFormforeign.form.valid"
                      class="btn editButton"
                      (click)="onSaveIdentity()"
                    />
                    <input
                      type="submit"
                      name=""
                      value="{{ 'Pleasewait' | translate }}..."
                      class="btn editButton"
                      disabled
                      *ngIf="isDisabledIdentity"
                    />
                  </div>
                </div>
              </form>
            </div>

            <!-- Fatura / İkametgah -->
            <div id="test-l-2" class="content">
              <form #invoiceForm="ngForm" id="invoiceForm" autocomplete="off">
                <div class="form-group">
                  <label for="document[3]">Fatura / İkametgah Yükleme</label>

                  <input
                    type="file"
                    name="document[3]"
                    id="address"
                    class="form-control"
                    accept=".jpg,.jpeg,.png,.pdf,.SVG"
                    (change)="folderUpload3($event)"
                    aria-label="Search"
                    required
                  />
                </div>
                <div class="mt-20 contentDesc">
                  <ul>
                    <li>
                      Son 1 ay içinde e-devlet üzerinden adınıza düzenlenmiş
                      Barkodlu Yerleşim Yeri Belgesi veya elektrik, su,
                      doğalgaz, telefon gibi abonelik gerektiren hizmetlere dair
                      faturalar kabul edilmektedir. Belgeler üzerindeki
                      bilgilerin net ve okunaklı olmasına özen gösteriniz.
                      Belgeler yalnızca
                      <b>jpg, png ve pdf</b>
                      uzantılarına sahip ve
                      <b>maksimum 10 MB </b>büyüklüğünde olmalıdır.
                    </li>
                  </ul>
                </div>
                <div class="text-center mt-40">
                  <div class="d-flex justify-content-center">
                    <input
                      type="submit"
                      name=""
                      value="Geri"
                      *ngIf="!isDisabledAddress"
                      [disabled]="!invoiceForm.form.valid"
                      class="btn editButton"
                      (click)="back()"
                    />

                    &nbsp; &nbsp; &nbsp;

                    <input
                      type="submit"
                      name=""
                      value="İleri"
                      *ngIf="!isDisabledAddress"
                      [disabled]="!invoiceForm.form.valid"
                      class="btn editButton "
                      (click)="onSaveAddress()"
                    />
                    <input
                      type="submit"
                      name=""
                      value="{{ 'Pleasewait' | translate }}..."
                      class="btn editButton"
                      disabled
                      *ngIf="isDisabledAddress"
                    />
                  </div>
                </div>
              </form>
            </div>

            <!-- Fotoğraf / Selfie -->
            <div id="test-l-3" class="content">
              <form #photoForm="ngForm" id="photoForm" autocomplete="off">
                <div class="form-group">
                  <label for="document[4]">Fotoğraf Yükleme</label>
                  <span
                    >&nbsp;&nbsp; Örnek&nbsp;
                    <span id="backgroundOrnekResim"></span>
                  </span>
                  <input
                    type="file"
                    name="document[4]"
                    class="form-control"
                    accept=".jpg,.jpeg,.png,.pdf,.SVG"
                    (change)="folderUpload4($event)"
                    aria-label="Search"
                    required
                  />
                </div>

                <div class="mt-20 contentDesc">
                  <ul>
                    <li>
                      Beyaz bir kağıt üzerine; Tırnak işaretleri olmadan
                      <b>"Bitistanbul hesap açılışımı onaylıyorum" </b> yazın,
                      ad soyad ve ilgili günün tarihini de yazarak kağıdı
                      imzalayın. Bu kağıdı ve TC kimlik kartınızı elinizde
                      tutarak yüzünüzün de göründüğü bir fotoğraf çekin.
                      Fotoğrafınızı Fotoğraf yükleme alnına yükleyin.
                      Fotoğrafınızda ad, soyad, tarih ve imzanın net ve okunaklı
                      olduğuna emin olun. Anlaşılır olmayan fotoğraflar kabul
                      edilmeyecektir.
                    </li>
                  </ul>
                </div>

                <div class="text-center mt-40">
                  <div class="d-flex justify-content-center">
                    <input
                      type="submit"
                      name=""
                      value="Geri"
                      *ngIf="!isDisabledPhoto"
                      [disabled]="!photoForm.form.valid"
                      class="btn editButton"
                      (click)="back()"
                    />

                    &nbsp; &nbsp; &nbsp;

                    <input
                      type="submit"
                      name=""
                      value="İleri"
                      *ngIf="!isDisabledPhoto"
                      [disabled]="!photoForm.form.valid"
                      class="btn editButton"
                      (click)="onSavePetitionPhoto()"
                    />
                    <input
                      type="submit"
                      name=""
                      value="{{ 'Pleasewait' | translate }}..."
                      class="btn editButton"
                      disabled
                      *ngIf="isDisabledPhoto"
                    />
                  </div>
                </div>
              </form>
            </div>

            <!-- surveyForm (Müşteri Tanıma Formu) -->
            <div id="test-l-4" class="content">
              <form
                autocomplete="off"
                class="Survey"
                #surveyForm="ngForm"
                id="surveyForm"
              >
                <!-- Adınız -->
                <div class="form-row mt-30">
                  <div class="form-group col-md-4">
                    <label for="firstName">Adınız</label>
                    <br />
                    <span style="color: #1f73b3; font-weight: 600"
                      >{{ userdata?.firstname }}
                      <img
                        src="../../../assets/icons/122.svg"
                        alt="firstname"
                        width="15"
                        height="15"
                      />
                    </span>
                  </div>

                  <!-- Soyadınız -->
                  <div class="form-group col-md-4">
                    <label for="lastname">Soyadınız</label>
                    <br />
                    <span style="color: #1f73b3; font-weight: 600"
                      >{{ userdata?.lastname }}
                      <img
                        src="../../../assets/icons/122.svg"
                        alt="lastname"
                        width="15"
                        height="15"
                      />
                    </span>
                  </div>

                  <div class="" *ngIf="userdata?.identity_type == '1'">
                    <div class="form-group col-md-4">
                      <label for="lastname">TC</label>
                      <br />
                      <span style="color: #1f73b3; font-weight: 600"
                        >{{ userdata?.tc }}
                        <img
                          src="../../../assets/icons/122.svg"
                          alt="TC"
                          width="15"
                          height="15"
                        />
                      </span>
                    </div>
                  </div>

                  <div class="" *ngIf="userdata?.identity_type == '2'">
                    <div class="form-group col-md-4">
                      <label for="lastname">Pasaport</label>

                      <br />
                      <span style="color: #1f73b3; font-weight: 600">
                        {{ userdata?.identity_number }}
                        <img
                          src="../../../assets/icons/122.svg"
                          alt="Pasaport"
                          width="15"
                          height="15"
                        />
                      </span>
                    </div>
                  </div>

                  <div class="" *ngIf="userdata?.identity_type == '3'">
                    <div class="form-group col-md-4">
                      <label for="lastname">Yabancı TC Kimlik No</label>
                      <br />
                      <span style="color: #1f73b3; font-weight: 600">
                        {{ userdata?.identity_number }}
                        <img
                          src="../../../assets/icons/122.svg"
                          alt="Yabancı TC"
                          width="15"
                          height="15"
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <div class="form-row">
                  <!-- Doğum Tarihi -->
                  <div class="form-group col-md-4">
                    <label for="datebirth">Doğum Tarihi</label>
                    <br />
                    <span style="color: #1f73b3; font-weight: 600">
                      {{ userdata?.datebirth }}
                      <img
                        src="../../../assets/icons/122.svg"
                        alt="Doğum Tarihi"
                        width="15"
                        height="15"
                      />
                    </span>
                  </div>

                  <!-- Telefon Numaranız -->
                  <div class="form-group col-md-4">
                    <label for="phone_no">Telefon Numaranız</label>

                    <br />
                    <span style="color: #1f73b3; font-weight: 600">
                      {{ userdata?.phone_no }}
                      <img
                        src="../../../assets/icons/122.svg"
                        alt="Telefon"
                        width="15"
                        height="15"
                      />
                    </span>
                  </div>

                  <!-- Email Adresiniz -->
                  <div class="form-group col-md-4">
                    <label for="email">Email Adresiniz</label>
                    <br />
                    <span style="color: #1f73b3; font-weight: 600">
                      {{ userdata?.email }}
                      <img
                        src="../../../assets/icons/122.svg"
                        alt="Email"
                        width="15"
                        height="15"
                      />
                    </span>
                  </div>
                </div>

                <!-- Ülkeniz -->
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="country">Ülkeniz</label>
                    <strong> *</strong>
                    <select
                      aria-label="country"
                      class="form-control"
                      name="country"
                      [class.is-invalid]="country.invalid && country.touched"
                      #country
                      [(ngModel)]="form.country"
                      required
                    >
                      <option [ngValue]="null" disabled>
                        {{ "SelectCountry" | translate }}
                      </option>
                      <option
                        *ngFor="let countrylist of countrlist"
                        value="{{ countrylist.id }}"
                      >
                        {{ countrylist.option_text }}
                      </option>
                    </select>
                  </div>

                  <div
                    *ngIf="
                      country?.selectedIndex == 216 ||
                        country?.selectedIndex == 0;
                      else loading
                    "
                  >
                    <div class="form-group col-md-4">
                      <label for="city">Şehriniz</label>
                      <strong> *</strong>
                      <select
                        aria-label="city"
                        class="form-control"
                        name="city"
                        [class.is-invalid]="city.invalid && city.touched"
                        #city="ngModel"
                        [(ngModel)]="form.city"
                        required
                      >
                        <option [ngValue]="null" disabled>
                          {{ "SelectCity" | translate }}
                        </option>
                        <option
                          *ngFor="let city of countrCity"
                          value="{{ city.id }}"
                        >
                          {{ city.option_text }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <ng-template #loading>
                    <div class="form-group col-md-4">
                      <label for="city_other">Şehriniz</label>
                      <strong> *</strong>
                      <input
                        type="text"
                        class="form-control"
                        name="city_other"
                        #city_other="ngModel"
                        [(ngModel)]="form.city_other"
                        placeholder="&nbsp;Şehir giriniz "
                        required
                      />
                    </div>
                  </ng-template>

                  <div class="form-group col-md-4">
                    <label for="nationality">Uyruğunuz</label>
                    <strong> *</strong>
                    <input
                      type="text"
                      name="nationality"
                      placeholder="Uyruğunuzu giriniz"
                      class="form-control"
                      #nationality="ngModel"
                      [(ngModel)]="form.nationality"
                      required
                    />
                  </div>
                </div>

                <!-- Mesleğiniz -->
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="jobOccupation">Mesleğiniz</label>
                    <strong> *</strong>
                    <select
                      aria-label="jobOccupation"
                      class="form-control"
                      name="jobOccupation"
                      [class.is-invalid]="
                        jobOccupation.invalid && jobOccupation.touched
                      "
                      [(ngModel)]="form.jobOccupation"
                      #jobOccupation
                      required
                    >
                      <option [ngValue]="null" disabled>
                        {{ "SelectjobOccupation" | translate }}
                      </option>
                      <option *ngFor="let job of jobs" value="{{ job.id }}">
                        {{ job.option_text }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="income">Gelir Kaynağınız</label>
                    <strong> *</strong>
                    <select
                      aria-label="income"
                      class="form-control"
                      name="income"
                      [class.is-invalid]="income.invalid && income.touched"
                      #income
                      [(ngModel)]="form.income"
                      required
                    >
                      <option [ngValue]="null" disabled>
                        {{ "Selectincome" | translate }}
                      </option>
                      <option
                        *ngFor="let income of incomes"
                        value="{{ income.id }}"
                      >
                        {{ income.option_text }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="netIncome">Aylık Net Geliriniz</label>
                    <strong> *</strong>
                    <select
                      aria-label="netIncome"
                      class="form-control"
                      name="netIncome"
                      [class.is-invalid]="
                        netIncome.invalid && netIncome.touched
                      "
                      #netIncome="ngModel"
                      [(ngModel)]="form.netIncome"
                      required
                    >
                      <option [ngValue]="null" disabled>
                        {{ "SelectnetIncome" | translate }}
                      </option>
                      <option
                        *ngFor="let netInc of netIncomes"
                        value="{{ netInc.id }}"
                      >
                        {{ netInc.option_text }}
                      </option>
                    </select>
                  </div>
                </div>

                <!-- İş ve Meslek Diğer * -->
                <div class="form-row" *ngIf="jobOccupation?.selectedIndex == 6">
                  <div class="form-group col-md-12">
                    <label for="jobOccupation_other"
                      >İş ve Meslek Diğer *</label
                    >

                    <input
                      type="text"
                      class="form-control"
                      name="jobOccupation_other"
                      #jobOccupation_other="ngModel"
                      [(ngModel)]="form.jobOccupation_other"
                      placeholder="İş ve meslek belirtiniz"
                      required
                    />
                  </div>
                </div>

                <!-- Gelir Kaynağınız Diğer -->
                <div class="form-row" *ngIf="income?.selectedIndex == 7">
                  <div class="form-group col-md-12">
                    <label for="income_other">Gelir Kaynağınız Diğer *</label>

                    <input
                      type="text"
                      class="form-control"
                      #income_other="ngModel"
                      name="income_other"
                      [(ngModel)]="form.income_other"
                      placeholder="Gelir kaynağınızı belirtiniz"
                      required
                    />
                  </div>
                </div>

                <!-- Kripto Varlık Yatırım Kaynağınız -->
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="sourceInvestment"
                      >Kripto Varlık Yatırım Kaynağınız
                    </label>
                    <strong> *</strong>
                    <select
                      aria-label="sourceInvestment"
                      class="form-control"
                      name="sourceInvestment"
                      [class.is-invalid]="
                        sourceInvestment.invalid && sourceInvestment.touched
                      "
                      #sourceInvestment
                      [(ngModel)]="form.sourceInvestment"
                      required
                    >
                      <option [ngValue]="null" disabled>
                        {{ "SelectsourceInvestment" | translate }}
                      </option>
                      <option
                        *ngFor="let sourceIn of sourceInvestments"
                        value="{{ sourceIn.id }}"
                      >
                        {{ sourceIn.option_text }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="cryptoPurpose"
                      >Kriptovarlık İşlemleri Yapma Amacınız
                    </label>
                    <strong> *</strong>
                    <select
                      aria-label="cryptoPurpose"
                      class="form-control"
                      name="cryptoPurpose"
                      [class.is-invalid]="
                        cryptoPurpose.invalid && cryptoPurpose.touched
                      "
                      #cryptoPurpose="ngModel"
                      [(ngModel)]="form.cryptoPurpose"
                      required
                    >
                      <option [ngValue]="null" disabled>
                        {{ "SelectcryptoPurpose" | translate }}
                      </option>
                      <option
                        *ngFor="let cryptoP of cryptoPurposes"
                        value="{{ cryptoP.id }}"
                      >
                        {{ cryptoP.option_text }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="tradingVolume"
                      >Aylık Tahmini Hesap İşlem Hacminiz
                    </label>
                    <strong> *</strong>
                    <select
                      aria-label="tradingVolume"
                      class="form-control"
                      name="tradingVolume"
                      [class.is-invalid]="
                        tradingVolume.invalid && tradingVolume.touched
                      "
                      #tradingVolume="ngModel"
                      [(ngModel)]="form.tradingVolume"
                      required
                    >
                      <option [ngValue]="null" disabled>
                        {{ "SelecttradingVolume" | translate }}
                      </option>
                      <option
                        *ngFor="let trad of tradingVolumes"
                        value="{{ trad.id }}"
                      >
                        {{ trad.option_text }}
                      </option>
                    </select>
                  </div>
                </div>

                <!-- Kriptopara Yatırım Kaynağınız Diğer -->
                <div
                  class="form-row"
                  *ngIf="sourceInvestment?.selectedIndex == 9"
                >
                  <div class="form-group col-md-12">
                    <label for="sourceInvestment_other"
                      >Kriptopara Yatırım Kaynağınız Diğer *</label
                    >

                    <input
                      type="text"
                      class="form-control"
                      #sourceInvestment_other="ngModel"
                      name="sourceInvestment_other"
                      [(ngModel)]="form.sourceInvestment_other"
                      placeholder="Kriptopara yatırım kaynağınızı belirtiniz"
                      required
                    />
                  </div>
                </div>

                <!-- Gelirinizin Ne Kadarı İle  -->
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="cryptoPlan"
                      >Gelirinizin Ne Kadarı İle Kriptovarlık Yatırımı Yapmayı
                      Planlıyorsunuz?
                    </label>
                    <strong> *</strong>
                    <select
                      class="form-control"
                      aria-label="cryptoPlan"
                      name="cryptoPlan"
                      [class.is-invalid]="
                        cryptoPlan.invalid && cryptoPlan.touched
                      "
                      #cryptoPlan="ngModel"
                      [(ngModel)]="form.cryptoPlan"
                      required
                    >
                      <option [ngValue]="null" disabled>
                        {{ "SelectcryptoPlan" | translate }}
                      </option>
                      <option
                        *ngFor="let cryptoP of cryptoPlans"
                        value="{{ cryptoP.id }}"
                      >
                        {{ cryptoP.option_text }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-12"></div>
                </div>

                <!-- back -->
                <div class="text-center">
                  <div class="d-flex justify-content-center">
                    <input
                      type="submit"
                      name=""
                      value="Geri"
                      *ngIf="!isDisabledSurvey"
                      class="btn editButton"
                      (click)="back()"
                    />

                    &nbsp; &nbsp; &nbsp;

                    <input
                      type="submit"
                      name=""
                      value="Üyeliği Tamamla"
                      *ngIf="!isDisabledSurvey"
                      [disabled]="!surveyForm.form.valid"
                      class="btn btn-primarySurvey"
                      (click)="onSubmitSurvey()"
                    />
                    <input
                      type="submit"
                      name=""
                      value="{{ 'Pleasewait' | translate }}..."
                      class="btn btn-primarySurvey"
                      disabled
                      *ngIf="isDisabledSurvey"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

import { Component, OnInit } from "@angular/core";
import { ProfileService } from "src/app/Services/profile.service";
import { TokenService } from "../../Services/token.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
@Component({
  selector: "app-device-verify",
  templateUrl: "./device-verify.component.html",
  styleUrls: ["./device-verify.component.css"],
})
export class DeviceVerifyComponent implements OnInit {
  private profileSubscription: Subscription;
  public success = null;
  public error = null;
  public currenttfa = null;
  public resResult;
  public deviceDetails;
  userdata: any = [];
  isDisabled = false;
  isDisabled2 = false;
  isDisabled5 = false;
  devicesDefined: any;

  constructor(
    private profile: ProfileService,
    private Token: TokenService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.profileSubscription = this.profile.getprofileDetailsInfo().subscribe(
      (data) => this.handleProfileDetails(data),
      (error) => this.handleError(error)
    );
  }

  ngOnDestroy(): void {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
  }
  handleProfileDetails(data) {
    if (data.success) {
      this.userdata = data.result;
      this.devicesDefined = data?.result.session.is_devices_defined;
      this.success = null;
    }
  }

  // #region kod doğrula

  inputValue: string;
  onInputChange(event: any) {
    this.inputValue = event.target.value;
    if (this.inputValue.length === 6) {
      setTimeout(() => {
        this.onSubmit();
      }, 450);
    }
  }

  public deviceDataform = {
    validation_code: null,
    device_key: null,
  };

  onSubmit() {
    this.isDisabled = true;
    // this.error = null;
    // this.success = null;
    this.deviceDetails = this.Token.getDevice();
    this.deviceDataform.device_key = this.deviceDetails;
    return this.profile.verifyDevice(this.deviceDataform).subscribe(
      (data) => this.handleResponseverifyDevice(data),
      (error) => this.handleError(error)
    );
  }

  async handleResponseverifyDevice(res) {
    this.resResult = res;
    if (res.success) {
      await localStorage.setItem(
        "verification_skip_email",
        res.verification_skip_email
      );
      // 'device_key' değerini local storage'a kaydet
      setTimeout(() => {
        this.router.navigate(["/ikili-dogrulama"]);
        this.isDisabled = false;
      }, 1000);
    } 
    
    else {
      this.success = "";
      this.error = res.message;
      //this.error = this.formatMessage(res.message);
      this.isDisabled = false;
      this.deviceDataform.validation_code = "";
    }
  }
  //#endregion kod doğrula

  // #region tekrar kod gönder

  public deviceDataFormResend = {
    device_key: null,
  };

  sendEmailRT(buttonData: string) {
    this.isDisabled2 = true;
    this.isDisabled5 = true;
    this.deviceDetails = this.Token.getDevice();
    this.deviceDataFormResend.device_key = this.deviceDetails;
    return this.profile.sendEmailDeviceKey(this.deviceDataFormResend).subscribe(
      (data) => this.handleResponseResend(data, buttonData),
      (error) => this.handleError(error)
    );
  }

  handleResponseResend(res, buttonData) {
    this.isDisabled2 = false;
    this.isDisabled5 = false;
    if (res.success) {
      if (buttonData === "notMessage") {
        this.success = res.message;
      }
      this.error = null;
      // resten gelen deviceKey bilgisi
      const deviceKey = res.device_key;
      // 'device_key' değerini local storage'a kaydet
      localStorage.setItem("device_key", deviceKey);
    } else {
      this.error = res.message;
      this.success = null;
    }
  }

  // #endregion kod tekrar gönder

  handleError(error) {
    this.error = error.message;
    this.isDisabled2 = false;
    this.isDisabled5 = false;
    this.isDisabled = false;
  }
}

import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/Services/profile.service';
import { Meta, Title } from "@angular/platform-browser";

interface ResultData {
  [key: string]: {
    [key: string]: any[];
  };
}

@Component({
  selector: 'app-limits-and-rules',
  templateUrl: './limits-and-rules.component.html',
  styleUrls: ['./limits-and-rules.component.css'],
})
export class LimitsAndRulesComponent implements OnInit {
  userdata: any = {};
  public error = null;
  result: ResultData = {};
  categories: string[] = [];
  selectedCategory: string = 'Seviye 1';
  selectedCoins: string[] = [];

  constructor(private profile: ProfileService, private metaService: Meta, private titleService: Title) {}

  ngOnInit() {


  // Meta etiketlerini ayarla
  this.metaService.addTags([
    {
      name: "description",
      content:
        "Düşük komisyon oranlarıyla hızlı ve güvenli kripto işlemleri",
    },
    {
      name: "keywords",
      content: "Bitistanbul | Avantajlı Komisyon Oranları",
    },
    { name: "robots", content: "index, follow" }, // Arama motorları için
  ]);

  const pageTitle = 'Bitistanbul | Avantajlı Komisyon Oranları';
  this.titleService.setTitle(pageTitle);





    this.profile.getCommissions().subscribe(
      (data) => this.handleProfileDetails(data),
      (error) => this.handleError(error)
    );
  }

  handleProfileDetails(data) {
    if (data.success) {
      this.userdata = data.result;
      this.result = data.result || {};
      this.categories = this.getCategories();
      this.selectedCoins = this.getCoinsByCategory(this.selectedCategory);
    }
  }

  handleError(error) {
    this.error = error.message;
  }

  getCategories(): string[] {
    return Object.keys(this.result);
  }

  getCoinsByCategory(category: string): string[] {
    return this.result[category] ? Object.keys(this.result[category]) : [];
  }

  groupResultByCategory(result: ResultData): ResultData {
    const groupedResult: ResultData = {};

    for (const category in result) {
      for (const coin in result[category]) {
        if (!groupedResult[coin]) {
          groupedResult[coin] = {};
        }
        groupedResult[coin][category] = result[category][coin];
      }
    }

    return groupedResult;
  }

  selectCategory(category: string): void {
    this.selectedCategory = category;
    this.selectedCoins = this.getCoinsByCategory(category);
  }
}

<!-- deposit Pages -->
<app-header></app-header>
<article>
  <div class="overlay" *ngIf="isLoading"></div>
  <section class="innerbannerpage">
    <div class="container">
      <!-- DepositHeader -->
      <div class="row">
        <table id="DepositHeader">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  alt=""
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                />
                &nbsp;&nbsp;
                <small> {{ "Deposit" | translate }}</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row mt-40">
        <!-- card header -->
        <ul class="nav nav-tabs text-center ul-center">
          <li>
            <a
              class="a-style"
              data-toggle="tab"
              href="#TRY"
              (click)="getTRYClick()"
              >TRY Yatırma</a
            >
          </li>
          <li></li>
          <li>
            <a
              class="a-style"
              data-toggle="tab"
              href="#KRIPTO"
              [class.disabledCoinWallet]="!isLoadingCyypto"
              (click)="
                isLoadingCyypto ? getKriptoClick() : $event.preventDefault()
              "
              >KRİPTO Yatırma</a
            >
          </li>
        </ul>

        <div class="tab-content">
          <!--TRY  -->
          <div id="TRY" class="tab-pane fade in active">
            <div class="size-style">
              <div class="form-group">
                <select
                  title="Banka Seçiniz"
                  class="form-control"
                  #bnkListBitIst
                  [(ngModel)]="accountno"
                  (change)="setFilterAddrBank($event)"
                  required
                >
                  <option [ngValue]="undefined" disabled selected>
                    Banka Seçiniz
                  </option>
                  <option *ngFor="let b of banklist" [value]="b.bank_id">
                    {{ b.bank_name }}
                  </option>
                </select>
              </div>

              <div *ngIf="bnkListBitIst.selectedIndex != 0">
                <div class="form-group cpybtnbg">
                  <input
                    title="Hesap Adı"
                    type="text"
                    name="txtAccountNo"
                    class="form-control"
                    [value]="this.selectedAccount_Name"
                    #TRYaccountno
                    readonly
                  />
                  <span class="cpytop">
                    <button
                      (click)="copyInputTRYAccountNo(TRYaccountno)"
                      class="copy-button"
                      id="copyInputTRYAccountNo"
                      title="Ünvan kopyala"
                    >
                      {{ copyButtonTextAccountNo }}
                    </button>
                  </span>
                </div>

                <div class="form-group cpybtnbg">
                  <input
                    title="iban"
                    type="text"
                    name="txtcompany"
                    class="form-control"
                    [value]="this.selectedBankIban"
                    #TRYcompany
                    readonly
                  />
                  <span class="cpytop">
                    <button
                      (click)="copyInputTRYCompany(TRYcompany)"
                      class="copy-button"
                      id="copyInputTRYCompany"
                      title="iban kopyala"
                      type="button"
                    >
                      {{ copyButtonTextCompany }}
                    </button>
                  </span>
                </div>
              </div>
            </div>

            <!-- Bilgilendirme -->
            <div class="float-left" id="infoTRY">
              <h5>Bilgilendirme :</h5>
              <ul>
                <li>
                  Bitistanbul'a sadece kendi adınıza açılmış <b>TRY</b> banka
                  hesaplarınızdan para yatırabilirsiniz.
                </li>
                <li>
                  Üçüncü şahıslara ait hesaplardan yapılan para transferleri
                  kabul edilmeyecektir.
                </li>
                <li>
                  <b>Vakıfkatılım</b> hesaplarınızdan
                  <b>7/24 TRY</b> yatırma işlemi gerçekleştirebilirsiniz.
                </li>
                <li>
                  Diğer bankalara vereceğiniz <b>100,000.00 TL</b>'ye kadar olan
                  yatırma taleplerinizi <b>7/24 FAST</b> ile kolayca
                  gerçekleştirebilirsiniz.
                </li>
                <li>
                  Yatırma talebiniz <b>100,000.00 TL</b>'yi aşıyorsa EFT işlem
                  gün ve saatleri içerisinde yapabilirsiniz.
                </li>
                <li>
                  Eğer EFT işlemi mesai saati dışında yapıldıysa, ilgili tutar
                  takip eden ilk iş gününde hesabınıza geçecektir.
                </li>
                <!-- <li>
                  ATM kullanarak yatırdığınız paralar işleme alınmayacaktır ve
                  iade edilecektir.
                </li> -->
              </ul>
            </div>
          </div>

          <!-- KRIPTO -->
          <div id="KRIPTO" class="tab-pane">
            <div class="size-style">
              <div class="form-group cpybtnbg">
                <select
                  title="Kripto Varlık"
                  class="form-control"
                  #cointList
                  [(ngModel)]="favoriteCoin"
                  (change)="setFilterAddr(cointList.value)"
                  name="favCoin"
                  required
                >
                  <option [ngValue]="undefined" disabled selected>
                    Kripto Varlık Seçiniz
                  </option>
                  <option *ngFor="let c of lstCoins" [value]="c">
                    {{ c }}
                  </option>
                </select>
              </div>

              <div *ngIf="filterAddr && filterAddr?.length > 0">
                <div class="form-group cpybtnbg">
                  <ul class="nav nav-tabs text-center ul-center">
                    <li
                      class="li-borderedBTC"
                      data-toggle="tab"
                      *ngFor="let addr of filterAddr; let index = index"
                      [class.active]="index == 0"
                    >
                      <a class="a-styleBTC" (click)="showAddrAndQR(addr)">
                        {{ addr.type }}
                      </a>
                    </li>
                  </ul>
                </div>

                <form
                  class="innerformbg"
                  *ngIf="selectedAddr && selectedAddr?.length > 0"
                >
                  <div class="form-group cpybtnbg">
                    <input
                      type="text"
                      name="txtAddressType"
                      title="{{ cointList.value }} {{
                        selectedAddrType
                      }} yatırma adresi"
                      class="form-control form-control-coin"
                      value="{{ this.selectedAddr }}"
                      #coinaddress
                      readonly=""
                    />
                    <span class="cpytop">
                      <button
                        (click)="copyInputAddress(coinaddress)"
                        class="copy-button"
                        id="myTooltip1"
                        title="Adres kopyala"
                      >
                        {{ copyButtonCoinAddress }}
                      </button>
                    </span>
                  </div>
                  <div class="col-md-12 text-center"></div>
                  <div class="form-group text-center mt-20">
                    <img
                      alt=""
                      src="data:image/png;base64,{{ this.selectedAddrBarcode }}"
                      class="qrcode-bg"
                    />
                  </div>

                  <div style="height: 13px"></div>
                  <div class="col-md-12 text-center">
                    <a
                      href="data:image/png;base64,{{
                        this.selectedAddrBarcode
                      }}"
                      target="_blank"
                      class="download-button"
                      download="{{ cointList.value }}-{{
                        selectedAddrType
                      }} QR.png"
                    >
                      <img
                        alt=""
                        src="assets/images/082.svg"
                        style="width: 13px"
                      />
                      <span>&nbsp;{{ "DownloadQRCode" | translate }}</span>
                    </a>
                  </div>
                </form>
              </div>
            </div>

            <!-- CoinHistory TABLE -->
            <div *ngIf="cointList.selectedIndex != 0">
              <div class="float-left mt-70" id="infoTRY">
                <h5>Bilgilendirme :</h5>

                <ul>
                  <li>
                    Seçtiğiniz adrese sadece <b>{{ cointList.value }}</b>
                    yatırabilirsiniz.
                  </li>

                  <li>
                    <b>{{ cointList.value }}</b
                    >'den farklı bir varlık transfer etmek istediğinizde;
                    işleminiz gerçekleşemeyecektir.
                  </li>
                  <li>Bu durumda yatırdığınız varlıklar kaybolabilir.</li>
                  <li *ngIf="cointList.value == 'BTC'">
                    Hesabınıza en az
                    <b>0.0001 {{ cointList.value }}</b> yatırabilirsiniz.
                  </li>
                  <li *ngIf="cointList.value == 'ETH'">
                    Hesabınıza en az
                    <b>0.01 {{ cointList.value }}</b> yatırabilirsiniz.
                  </li>

                  <li *ngIf="cointList.value == 'USDT'">
                    Hesabınıza en az
                    <b>5 {{ cointList.value }}</b> yatırabilirsiniz.
                  </li>

                  <li *ngIf="cointList.value == 'BTC'">
                    <b>{{ cointList.value }}</b> yatırma işlemlerinizi
                    <b>BECH32</b> veya
                    <b>LEGACY</b>
                    protokolleri üzerinden yapabilirsiniz.
                  </li>
                  <li
                    *ngIf="
                      cointList.value == 'ETH' || cointList.value == 'USDT'
                    "
                  >
                    <b>{{ cointList.value }}</b> yatırma işlemlerinizi
                    <b>ERC20</b> protokolü üzerinden yapabilirsiniz.
                  </li>

                  <li *ngIf="cointList.value == 'BTC'">
                    <b>BECH32</b> yatırma adresleri <b>bc1</b> ile
                    <b>LEGACY</b> yatırma adresleri <b>1</b> ile başlamaktadır.
                  </li>

                  <li
                    *ngIf="
                      cointList.value == 'ETH' || cointList.value == 'USDT'
                    "
                  >
                    <b>ERC20</b> yatırma adresleri <b>0x</b> ile başlamaktadır.
                  </li>
                  <li>
                    Yatırma işleminiz sırasında doğru adres ve ağa gönderim
                    yaptığınıza emin olunuz.
                  </li>
                  <li>
                    Yatırma işleminiz ağ üzerinde onaylandıktan sonra mevcut
                    bakiyenize otomatik olarak yansıtılacaktır.
                  </li>
                </ul>
              </div>
            </div>

            <!-- Bilgilendirme default -->
            <div *ngIf="cointList.selectedIndex === 0">
              <div class="float-left mt-10" id="infoTRY">
                <h5>Bilgilendirme</h5>
                <ul>
                  <li>
                    Yatırma adresleriniz size özel olarak tanımlanmış kişisel
                    adreslerdir.
                  </li>
                  <li>
                    Yatırma işlemleriniz sırasında doğru adres ve ağa gönderim
                    yaptığınıza emin olunuz.
                  </li>
                  <li>
                    Yatırma işleminiz ağ üzerinde onaylandıktan sonra mevcut
                    bakiyenize otomatik olarak yansıtılacaktır.
                  </li>
                </ul>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

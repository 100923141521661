<!-- Kullanıcı Bilgilendirme Pages -->
<app-header></app-header>
<article class="innerpagearticle">
  <section class="innerbannerpage">
    <div class="container">
      <div class="row">
        <table id="user-notification-header">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: 165px"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                  alt=""
                />
                &nbsp;&nbsp;
                <small> {{ "UserNotification" | translate }}</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row row-style">
        <p>
          <strong>Şirkete Ait Bilgiler</strong>
          <br />
          <br />
          Unvan : <b>Hotto Kripto Varlık Alım Satım Platformu A.Ş.</b>
          <br />
          <br />
          Adres : Esentepe Mah. Büyükdere Cad. No: 102 Maya Akar Center 16. Kat
          Daire: 60 Şişli / İSTANBUL
          <br />
          <br />
          Telefon :<b>+90 ( 212 ) 288 52 88</b>&nbsp;&nbsp; E-posta :
          <b>info&#64;bitistanbul.com</b> &nbsp;&nbsp;Müşteri Hizmetleri :
          <b>+90 ( 212 ) 288 52 88</b>
        </p>
      </div>
      <div class="row row-style">
        <p>
          <strong>Hizmetin Temel Nitelikleri</strong>
        </p>
        <p>
          Hotto Kripto Varlık Alım Satım Platformu A.Ş. (ŞİRKET) tarafından oluşturulan
          www.bitistanbul.com bir online kripto varlık çiftleri alım – satım
          platformudur. Platform, özetle, kullanıcıların kripto varlık
          çiftlerinde gerçekleştirecekleri alım / satım işlemleri, nakit veya
          kripto varlık transfer/ödeme işlemleri, gerçekleşen işlemlerin takibi
          ve raporlanmasına yönelik online hizmet sunan bir yazılımdır.
        </p>
      </div>
      <div class="row row-style">
        <p>
          <strong>Hizmet Masraflarına İlişkin Bilgiler</strong>
        </p>
        <p>
          Platformda gerçekleştirilecek kripto varlık alım satım işlemleri
          nedeniyle ortaya çıkan ve ilgili mevzuat uyarınca doğrudan Hotto Kripto Varlık Alım Satım Platformu A.Ş. tarafından ödenmesi gerekenler hariç, her türlü
          vergi (BSMV, KDV, damga vergisi vb.) ve harçlar ile komisyon, faiz
          veya her ne nam altında olursa olsun diğer tüm masraflar KULLANICI
          tarafından ödenir.
          <br />
          <br />
          KULLANICI tarafından Platformda gerçekleştirilen kripto varlık
          alım/satım işlemleri ile kripto varlık ve/veya nakit transfer
          işlemleri nedeniyle ödenecek komisyon, faiz ve masraflar
          www.bitistanbul.com sitesinde ilan edilir. KULLANICI söz konusu
          internet adresinde yayımlanan komisyon, faiz, masraf veya her ne nam
          altında olursa olsun her türlü ödemeyi kabul eder.
        </p>
      </div>
      <div class="row row-style">
        <p>
          <strong
            >Riskler, Fiyatların Oluşumu, Garanti Fonu veya Tazminat
            Sistemi</strong
          >
        </p>
        <p>
          Platformda fiyatlar alıcı ve satıcı tarafından belirlenir. ŞİRKET
          Platforma iletilen alım ve satım emirlerinin gerçekleşmesini ve
          gerçekleşen işlemlere ilişkin karşılıklı yükümlülüklerin yerine
          getirilmesini hiçbir şekilde garanti etmez. Platformda yer alan
          fiyatlar alıcı ve satıcının fiyat tercihleri ile kripto varlık
          piyasalarındaki dalgalanmalara göre değişir. Platformda gerçekleşen
          emirlere ilişkin yükümlülüklerin karşılıklı olarak yerine getirilmesi
          amacıyla oluşturulan bir garanti fonu veya benzeri mekanizma yoktur.
          ŞİRKET, kendine atfı kabil durumlar hariç, alıcı ve satıcının
          karşılıklı yükümlülüklerinin yerine getirilmesinde hiçbir sorumluluk
          almaz.
        </p>
      </div>
      <div class="row row-style">
        <p>
          <strong>Platform İçeriği ve Sağlanan Bilgiler</strong>
        </p>
        <p>
          PPlatformda yer alan tüm içerik, üçüncü kişilerden, internet ve kamuya
          açık diğer kaynaklardan toplanan verilerden oluşmaktadır. Platformda
          yer alan data, bülten, rapor, analiz, istatistik veya bu nitelikteki
          her türlü dokümana ilişkin olarak ŞİRKET, objektif kriterler dışında
          sorumluluk kabul etmez. ŞİRKET bu bilgilerin doğruluğunda en iyi
          gayreti gösterir. ŞİRKET bu dokümanları üretirken kullanmış olduğu
          kaynaklardaki verilerin yalan, yanlış ve yanıltıcı olması durumunda,
          kendine atfı kabil kusur ve objektif olarak tespit edebileceği hatalar
          dışında sorumlu tutulamaz.
          <br />
          <br />
          Kullanıcıların Platformda yer alan bilgi, bülten, doküman, broşür,
          analiz veya her ne nam altında olursa olsun kripto varlıklar konusunda
          tavsiye olarak değerlendirilebilecek yorumlar sadece bilgilendirme
          amaçlı olup bu doküman/bilgi/değerlendirmelere dayalı olarak işlem
          gerçekleştirmesi her zaman beklentilerine uygun sonuç doğurmayabilir.
          Platformda yer alan bu nitelikteki değerlendirmeler hiçbir şekilde
          yatırım danışmanlığı amacını taşımamaktadır ve KULANICI tarafından
          yatırım danışmanlığı olarak kabul edilemez. Verilerin birbiriyle
          çelişkili veyahut tutarsız olma durumu olabilir. Bu dokümanlara dayalı
          olarak gerçekleştirilen işlemler sonucunda KULLANICI’nın karşı karşıya
          kalacağı zarardan, kendine yüklenebilecek durumlar hariç, ŞİRKET
          sorumlu tutulamaz.
        </p>
      </div>
      <div class="row row-style">
        <p>
          <strong>Gerçekleşen İşlemlerin İfası</strong>
        </p>
        <p>
          Para yatırma ve çekme işlemlerinde ŞİRKET’e ait banka hesapları ile
          KULLANICI ismine açılmış banka hesapları kullanılır. Banka üzerinden
          yapılacak transferler hariç nakit işlemi kabul edilmez.
          <br />
          <br />
          Kripto varlık çiftlerinin transferinde KULLANICI tarafından sisteme
          tanıtılan kripto para cüzdanları kullanılır.
        </p>
      </div>
      <div class="row row-style">
        <p>
          <strong>Cayma Hakkı</strong>
        </p>
        <p>Gerçekleşen işlemlerden KULLANICI’nın cayma hakkı yoktur.</p>
      </div>
      <div class="row row-style">
        <p>
          <strong>Sözleşmenin Süresi ve Feshine İlişkin Bilgiler</strong>
        </p>
        <p>
          KULLANICI ile ŞİRKET arasında imzalanacak KULLANICI SÖZLEŞMESİ
          süresizdir. 10 işgünü önceden ihbar etmek kaydıyla TARAFLAR sözleşmeyi
          tek taraflı olarak fesih hakkına sahiptir.
          <br />
          <br />
          ŞİRKET, KULLANICI’nın ödemede temerrüde düşmesi, sözleşme hükümleri
          ile sözleşmeden kaynaklanan dürüstlük ve özen yükümlülüklerine
          aykırılık, yanlış beyan, iflası ya da ödemelerinde acze düşmesi
          durumunda, KULLANICI’nın otomatik yazılımlar vasıtası ile Platforma
          emir ilettiğinin tespiti, Platformda ortaya çıkabilecek teknik
          aksaklık veya zafiyetlerin KULLANICI tarafından dürüstlüğe aykırı
          olarak kullanılarak haksız ve hukuka aykırı kazanç elde edildiğinin,
          Platformdaki işlemlerin mevzuat aykırılık veya mevzuatı dolanma
          amacını taşıdığı veya suç gelirlerinin aklanması ve terörizmin
          finansmanı amacıyla kullanıldığı yönünde makul şüphenin varlığı
          durumlarında herhangi bir süre şartı olmaksızın Sözleşmeyi tek taraflı
          olarak fesih hakkında sahiptir.
        </p>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

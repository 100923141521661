<app-header></app-header>
<article class="innerpagearticle">
  <section class="innerbannerpage">
    <div class="container">
      <!-- reset-password-header -->
      <table id="reset-password-header">
        <tbody>
          <tr>
            <td style="text-align: center; height: 35px">
              <img
                src="assets/images/071.svg"
                alt=""
                class="text-center"
                style="width: 17px; margin-right: 25px"
              />
            </td>
          </tr>
          <tr>
            <td style="text-align: center">
              <img
                src="assets/images/070.svg"
                class="text-center"
                style="width: 24px; vertical-align: sub"
                alt=""
              />
              &nbsp;&nbsp;
              <small> Şifre Yenile</small>
            </td>
          </tr>
          <tr>
            <td colspan="3" style="height: 25px">
              <hr />
            </td>
          </tr>
        </tbody>
      </table>

      <div class="mt-140 col-lg-5 col-md-6 col-sm-8 col-xs-12 center-content">
        <form
          class="innerformbg"
          autocomplete="off"
          #resetpasswordForm="ngForm"
          (ngSubmit)="onSubmit()"
        >
          <!-- EmailID -->
          <div class="form-group">
            <!-- <label>{{ "EmailID" | translate }}</label> -->
            <input
              type="email"
              name="email"
              class="form-control"
              [class.is-invalid]="email.invalid && email.touched"
              #email="ngModel"
              [(ngModel)]="form.email"
              pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              required
              placeholder="E-Mail Adresiniz"
            />
            <span *ngIf="email.errors && email.invalid && email.touched">
              <small class="text-danger-input" *ngIf="email.errors.required"
                >{{ "EmailID" | translate }} {{ "required" | translate }}</small
              >
              <small class="text-danger-input" *ngIf="email.errors.pattern">{{
                "Pleaseemailaddress" | translate
              }}</small>
            </span>
          </div>

          <!-- password -->
          <div class="form-group">
            <!-- <label for="password">{{ "Password" | translate }}</label> -->
            <input
              [type]="hidePassword ? 'password' : 'text'"
              type="password"
              name="password"
              id="password"
              class="form-control"
              [class.is-invalid]="password.invalid && password.touched"
              [(ngModel)]="form.password"
              #password="ngModel"
              pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$"
              required
              placeholder="Yeni Şifreniz"
            />

            <span class="p-viewer">
              <i
                class="material-icons"
                matSuffix
                (click)="hidePassword = !hidePassword"
                >{{ hidePassword ? "visibility_off" : "visibility" }}</i
              >
            </span>
            <span
              *ngIf="password.errors && password.invalid && password.touched"
            >
              <small class="text-danger-input" *ngIf="password.errors.required"
                >{{ "Password" | translate }}
                {{ "required" | translate }}.</small
              >
              <small class="text-danger-input" *ngIf="password.errors.pattern"
                >{{ "validpassword" | translate }}.</small
              >
            </span>
          </div>

          <!-- Confirmpassword -->
          <div class="form-group">
            <!-- <label for="passwordconform">{{
                "Confirmpassword" | translate
              }}</label> -->
            <input
              [type]="hidePasswordConfirmation ? 'password' : 'text'"
              type="password"
              name="password_confirmation"
              id="passwordconform"
              class="form-control"
              [(ngModel)]="form.password_confirmation"
              #password_confirmation="ngModel"
              required
              placeholder="{{ 'Confirmpassword' | translate }}"
            />
            <span class="p-viewer">
              <i
                class="material-icons"
                matSuffix
                (click)="hidePasswordConfirmation = !hidePasswordConfirmation"
                >{{
                  hidePasswordConfirmation ? "visibility_off" : "visibility"
                }}</i
              >
            </span>
            <span
              *ngIf="
                password_confirmation.errors &&
                password_confirmation.invalid &&
                password_confirmation.touched
              "
            >
              <small
                class="text-danger-input"
                *ngIf="password_confirmation.errors.required"
                >{{ "Confirmpassword" | translate }}
                {{ "required" | translate }}.</small
              >
              <small
                class="text-danger-input"
                *ngIf="password_confirmation.errors.pattern"
                >{{ "samepassword" | translate }}.</small
              >
            </span>
          </div>
          <br />

          <!-- captcha -->
          <div class="form-group">
            <re-captcha
              [(ngModel)]="form.recaptcha"
              name="recaptcha"
              #recaptcha="ngModel"
            ></re-captcha>
          </div>

          <!-- messages -->
          <div class="alert text-danger text-center" [hidden]="!error">
            {{ error }}
          </div>

          <div class="alert text-danger text-center" [hidden]="!success">
            {{ success }}
          </div>

          <!-- buttons -->
          <div class="form-group text-center">
            <div>
              <input
                type="submit"
                name=""
                value="Şifre Yenile"
                class="btn orange-btn mt-10"
                [disabled]="!resetpasswordForm.valid"
                *ngIf="!isDisabled"
              />
              <input
                type="submit"
                name=""
                value="Lütfen bekleyiniz..."
                class="btn orange-btn mt-10"
                disabled
                *ngIf="isDisabled"
              />
            </div>
          </div>

          <!-- Signin -->
          <div class="mt-10 btnsnfg text-center">
            <h5 class="t-white">
              {{ "Alreadyhaveaccount" | translate }}? &nbsp;
              <a routerLink="/giris" class="t-blue">{{
                "Signin" | translate
              }}</a>
            </h5>
          </div>
        </form>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

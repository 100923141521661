import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/Services/auth.service';
import { TokenService } from '../../Services/token.service';
import { IntermediateService } from '../../Services/intermediate.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SupportTicketService } from 'src/app/Services/support-ticket.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConstants } from 'src/common/global-constants';
import { ThemeService } from 'src/app/Services/theme/theme.service';
import { ProfileService } from 'src/app/Services/profile.service';

@Component({
  selector: 'app-userheader',
  templateUrl: './userheader.component.html',
  styleUrls: ['./userheader.component.css'],
})
export class UserheaderComponent implements OnInit {
  _bitistanbul_Theme_Background = GlobalConstants._bitistanbul_Theme_Background;

  baseUrl = environment.tradeUrl;
  public loggedIn: boolean;
  public username: null;
  public image: null;
  public company = null;
  public companyTitle = '';
  public tickets = null;
  public error = null;
  public count = null;
  public currentTheme = null;
  informationdata: any = [];

  constructor(
    private profile: ProfileService,
    private ngxService: NgxUiLoaderService,
    private router: Router,
    private Auth: AuthService,
    private Token: TokenService,
    private myservice: IntermediateService,
    private support: SupportTicketService,
    // private walletService: WalletService,
    private themeService: ThemeService,

    public translate: TranslateService
  ) {
    translate.addLangs(['English', 'Arabic', 'Armenian']);
    translate.setDefaultLang('English');

    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(
        browserLang.match(/English|Arabic|Armenian/) ? browserLang : 'English'
      );
    } else {
      localStorage.setItem('locale', 'English');
      translate.setDefaultLang('English');
    }

    this.themeService.LoadCurrentTheme();
    this.currentTheme = this.themeService.getActiveTheme();
  }

  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
  }
  
  handleWalletInfo(data) {
    if (data.success) {
      const waData = data.result.data;
      GlobalConstants.setWalletInfo(waData);
    } else {
      // Handle the case where data.success is false
    }
  }  

  toggleTheme() {
    this.themeService.toggleTheme();
    this.currentTheme = this.themeService.getActiveTheme();
  }

  ngOnInit() {
    this.Auth.authStatus.subscribe((value) => (this.loggedIn = value));
    if (this.loggedIn) {
      this.Token.getUserDetails();
      GlobalConstants.token = this.Token;
      this.support.getpusherticketlist().subscribe(
        (data) => this.handleTicketsDetails(data),
        (error) => this.handleError(error)
      );

      this.profile.getprofileDetails().subscribe(
        (data) => this.handleInformationsDetails(data),
        (error) => this.handleError(error)
      );
    }

    // loader
    this.ngxService.start();
    setTimeout(() => {
      this.ngxService.stop();
    }, 500);
  }

  handleInformationsDetails(data) {
    if (data.success) {
      this.informationdata = data.result;
    } else {
      this.informationdata = [];
    }
  }

  handleTicketsDetails(data) {
    if (data.success) {
      const result = data.result;
      this.tickets = result.list;
      this.count = result.count;
    } else {
      this.router.navigateByUrl('/borsa/BTC/TRY');
    }
  }

  handleError(error) {
    this.error = error.message;
  }

  
  logout(event: MouseEvent) {
    this.ngxService.start();
    event.preventDefault(); // Bunu setTimeout dışına çıkardık

    this.myservice.userlogout().subscribe({
        next: () => {
            this.Token.remove();
            this.Auth.changeAuthStatus(false);
            document.location.href = "/giris"; // Yönlendirmeyi burada yapıyoruz
            this.ngxService.stop();
        },
        error: () => {
            console.error('Logout işlemi sırasında hata oluştu');
            this.ngxService.stop();
        }
    });
}

   
}

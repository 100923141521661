<app-header-empty></app-header-empty>
<article class="innerpagearticle">
  <section class="innerbannerpage">
    <div class="container">
      <!-- deviceVerification -->
      <div class="row">
        <table id="deviceVerification">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 17px; margin-right: 55px"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  alt=""
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                />
                &nbsp;&nbsp;
                <small> Cihaz Onayla</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row">
        <div id="parent" class="mt-20">
          <div id="child">
            <form
              class="innerformbg"
              #verifyDeviceForm="ngForm"
              id="verifyDeviceForm"
              autocomplete="off"
              
            >
              <div class="title-header text-center">
                <b> {{ userdata?.email }}</b> adresine gönderilen 6 haneli kodu
                giriniz
              </div>
              <br />

              <!-- validation_code -->
              <div class="form-group">
                <input
                  type="text"
                  data-role="input"
                  name="validation_code"
                  maxlength="6"
                  minlength="6"
                  class="form-control"
                  [class.is-invalid]="
                    validation_code.invalid && validation_code.touched
                  "
                  #validation_code="ngModel"
                  [(ngModel)]="deviceDataform.validation_code"
                  pattern="[0-9]+"
                  placeholder="{{ 'EnterDigitCodeEmail6' | translate }}"
                  onkeyup="if(this.value.length == 6) this.blur()"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  required
                  autofocus
                  (input)="
                  onInputChange(
                    $event 
                  )
                "
                />

                <span
                  *ngIf="
                    validation_code.errors &&
                    validation_code.invalid &&
                    validation_code.touched
                  "
                  class="text-center"
                >
                </span>
              </div>

              <!-- alert-danger -->
              <div class="text-center mt-30">
                <div class="alert-danger" [hidden]="!error">
                  {{ error }}
                </div>

                <div class="alert-success" [hidden]="!success">
                  {{ success }}
                </div>
              </div>

              <!-- buttons -->
              <div class="">
                <div class="text-center mt-30">
                  <input
                    [disabled]="!verifyDeviceForm.valid"
                    type="submit"
                    name="submit"
                    class="btn code-verify"
                    value="{{ 'Verify' | translate }}"
                    *ngIf="!isDisabled"
                  />
                  <input
                    type="button"
                    name=""
                    value="{{ 'Pleasewait' | translate }}..."
                    class="btn code-verify"
                    disabled
                    *ngIf="isDisabled"
                  />
                </div>
              </div>

              <!-- tekrar gönder button -->
              <div class="text-center mt-30">
                <input
                  type="submit"
                  name="submit"
                  class="btn btn-sendSmsAgainRT"
                  value="{{ 'SubmitCodeAgain' | translate }}"
                  *ngIf="!isDisabled5"
                  (click)="sendEmailRT('notMessage')"
                />
                <input
                  type="button"
                  name=""
                  value="{{ 'Pleasewait' | translate }}..."
                  class="btn btn-sendSmsAgainRT"
                  *ngIf="isDisabled5"
                  disabled
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

<app-header></app-header>
<article class="innerpagearticle">
  <section class="innerbannerpage">
    <div class="container mt-15">
      <!-- app-alert-modal error -->
      <app-alert-modal
        *ngIf="error"
        [error]="error"
        (close)="error = null"
      ></app-alert-modal>

      <!-- Kullanıcı Sözleşmesi modal -->
      <div
        class="modal fade bd-example-modal-lg"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header mt-10">
              <h3 class="modal-title text-center" id="exampleModalLabel">
                <b> {{ "UserAgreement" | translate }}</b>
              </h3>
            </div>
            <div class="modal-body">
              <h4>Şirkete Ait Bilgiler</h4>
              <p>
                Unvan : Hotto Kripto Varlık Alım Satım Platformu A.Ş.
                <br />
                Adres : Esentepe Mah. Büyükdere Cad. No: 102 Maya Akar Center
                Kat:16 Daire: 60 Şişli / İSTANBUL
                <br />
                Telefon : +90 ( 212 ) 288 52 88
                <br />
                E-posta : info&#64;bitistanbul.com
                <br />
                Müşteri Hizmetleri : +90 ( 850 ) 677 77 70
              </p>
              <br />
              <h4>Hizmetin Temel Nitelikleri</h4>
              <p>
                Hotto Kripto Varlık Alım Satım Platformu A.Ş.(ŞİRKET) tarafından oluşturulan
                www.bitistanbul.com bir online kripto varlık çiftleri alım–satım
                platformudur. Platform, özetle, kullanıcıların kripto varlık
                çiftlerinde gerçekleştirecekleri alım / satım işlemleri, nakit
                veya kripto varlık transfer/ödeme işlemleri, gerçekleşen
                işlemlerin takibi ve raporlanmasına yönelik online hizmet sunan
                bir yazılımdır.
              </p>

              <br />
              <h4>Hizmet Masraflarına İlişkin Bilgiler</h4>
              <p>
                Platformda gerçekleştirilecek kripto varlık alım satım işlemleri
                nedeniyle ortaya çıkan ve ilgili mevzuat uyarınca doğrudan Hotto Kripto Varlık Alım Satım Platformu A.Ş. tarafından ödenmesi gerekenler hariç, her
                türlü vergi (BSMV, KDV, damga vergisi vb.) ve harçlar ile
                komisyon, faiz veya her ne nam altında olursa olsun diğer tüm
                masraflar KULLANICI tarafından ödenir.
                <br />
                KULLANICI tarafından Platformda gerçekleştirilen kripto varlık
                alım/satım işlemleri ile kirpto varlık ve/veya nakit transfer
                işlemleri nedeniyle ödenecek komisyon, faiz ve masraflar
                www.bitistanbul.com sitesinde ilan edilir. KULLANICI söz konusu
                internet adresinde yayımlanan komisyon, faiz, masraf veya her ne
                nam altında olursa olsun her türlü ödemeyi kabul eder.
              </p>
              <br />
              <h4>
                Riskler, Fiyatların Oluşumu, Garanti Fonu veya Tazminat Sistemi
              </h4>
              <p>
                Platformda fiyatlar alıcı ve satıcı tarafından belirlenir.
                ŞİRKET Platforma iletilen alım ve satım emirlerinin
                gerçekleşmesini ve gerçekleşen işlemlere ilişkin karşılıklı
                yükümlülüklerin yerine getirilmesini hiçbir şekilde garanti
                etmez. Platformda yer alan fiyatlar alıcı ve satıcının fiyat
                tercihleri ile kripto varlık piyasalarındaki dalgalanmalara göre
                değişir. Platformda gerçekleşen emirlere ilişkin yükümlülüklerin
                karşılıklı olarak yerine getirilmesi amacıyla oluşturulan bir
                garanti fonu veya benzeri mekanizma yoktur. ŞİRKET, alıcı ve
                satıcının karşılıklı yükümlülüklerinin yerine getirilmesinde
                hiçbir sorumluluk almaz.
              </p>
              <br />
              <h4>Platform İçeriği ve Sağlanan Bilgiler</h4>
              <p>
                Platformda yer alan tüm içerik, üçüncü kişilerden, internet ve
                kamuya açık diğer kaynaklardan toplanan verilerden oluşmaktadır.
                Platformda yer alan data, bülten, rapor, analiz, istatistik veya
                bu nitelikteki her türlü dokümana ilişkin olarak ŞİRKET,
                objektif kriterler dışında sorumluluk kabul etmez. ŞİRKET bu
                bilgilerin doğruluğunda en iyi gayreti gösterir. ŞİRKET bu
                dokümanları üretirken kullanmış olduğu kaynaklardaki verilerin
                yalan, yanlış ve yanıltıcı olması durumunda kastı dışında
                sorumlu tutulamaz.
              </p>
              <br />
              <h4>Gerçekleşen İşlemlerin İfası</h4>
              <p>
                Para yatırma ve çekme işlemlerinde ŞİRKET’e ait banka hesapları
                ile KULLANICI ismine açılmış banka hesapları kullanılır. Banka
                üzerinden yapılacak transferler hariç nakit işlemi kabul
                edilmez.
                <br />
                Kripto varlık çiftlerinin transferinde KULLANICI tarafından
                sisteme tanıtılan kripto para cüzdanları kullanılır.
              </p>

              <br />
              <h4>Sözleşmenin Süresi ve Feshine İlişkin Bilgiler</h4>
              <p>
                KULLANICI ile ŞİRKET arasında imzalanacak KULLANICI SÖZLEŞMESİ
                süresizdir. 10 iş günü önceden ihbar etmek kaydıyla TARAFLAR
                sözleşmeyi tek taraflı olarak fesih hakkına sahiptir.
                <br />
                <br />
                ŞİRKET, KULLANICI'nın ödemede temerrüde düşmesi, sözleşme
                hükümleri ile sözleşmeden kaynaklanan dürüstlük ve özen
                yükümlülüklerine aykırılık, yanlış beyan, iflası ya da
                ödemelerinde acze düşmesi durumunda, KULLANICI'nın otomatik
                yazılımlar vasıtası ile Platforma emir ilettiğinin tespiti,
                Platformda ortaya çıkabilecek teknik aksaklık veya zafiyetlerin
                KULLANICI tarafından dürüstlüğe aykırı olarak kullanılarak
                haksız ve hukuka aykırı kazanç elde edildiğinin, Platformdaki
                işlemlerin mevzuat aykırılık veya mevzuatı dolanma amacını
                taşıdığı veya suç gelirlerinin aklanması ve terörizmin
                finansmanı amacıyla kullanıldığı yönünde makul şüphenin varlığı
                durumlarında herhangi bir süre şartı olmaksızın Sözleşmeyi tek
                taraflı olarak fesih hakkına sahiptir. KULLANICI bu gerekçelerle
                Sözleşmenin feshi nedeniyle karşı karşıya kalacağı zararı
                ŞİRKET’ten talep edemeyecektir.
              </p>

              <br />
              <h4>Taraflar</h4>
              <p>
                İşbu Kullanıcı Sözleşmesi bir tarafta _______________________
                adresinde mukim ___________ (KULLANICI) ile Esentepe Mahallesi,
                Büyükdere Caddesi, Maya Akar Center B, Blok, No:102 Kat:16,
                No:60 Şişli – İstanbul adresinde mukim Hotto Kripto Varlık Alım Satım Platformu A.Ş. (ŞİRKET) arasında imzalanmıştır. ŞİRKET ve KULLANICI
                birlikte TARAFLAR olarak anılacaktır.
              </p>

              <br />
              <h4>Amaç</h4>
              <p>
                2.1. Sözleşme ŞİRKET ile kripto varlık çiftlerinin alım satımına
                amacına yönelik oluşturulan Platforma üye olan KULLANICI
                arasında, Platformda ticari amaçla gerçekleştirilerek alım/atım
                işlemleri, kripto varlık veya nakit transfer işlemleri, cüzden
                kullanım esasları ile bunlarla sınırlı olmamak üzere diğer tüm
                karşılıklı hak ve yükümlülüklerin belirlenmesi amacıyla
                imzalanmıştır.
                <br />
                2.2. Sözleşme KULLANICI’nın Platforma üye olması ile hüküm ifade
                eder. KULLANICI'nın Platforma üye olması Sözleşme’nin tüm
                hükümlerini okunarak KULLANICI tarafından gayri kabili rücu
                hukuken kabul ve taahhüt edildiği anlamına gelir.
              </p>

              <br />
              <h4>Tanımlar</h4>
              <p>
                3.1. Kripto Para Çiftleri: Platformda kripto varlıklar ile fiili
                para veya kripto varlıkların kendi aralarındaki oran ile temsil
                edilen ve Platform vasıtası ile alım satıma konu edilebilen
                kripto varlıklar,
                <br />
                <br />
                3.2. Cüzdan: Platformda işlem gerçekleştirilen kripto
                varlıkların adresleri ile bu adreslere ait şifreleri tutan
                bilgisayar dosyası,
                <br />
                <br />
                3.3. Platform: Hotto Kripto Varlık Alım Satım Platformu A.Ş. mülkiyetinde, kripto
                varlık alım satım işlemlerinin gerçekleştirilmesi amacıyla
                hazırlanan, Platformda işleme açılan kripto varlık çiftlerinde
                alım/satım emir iletimi, cüzdan oluşturulması, kripto varlık ve
                nakit transferine imkân sağlayan, internet üzerinden
                erişilebilen veya mobil cihazlara yüklenebilen yazılım
              </p>

              <br />
              <h4>Gerçek kullanıcı</h4>
              <p>
                4.1. KULLANICI, Platformda kendi adına açılan hesap ve
                oluşturulacak cüzdanlarda kendi adına hesabına hareket ettiğini,
                başkası hesabına hareket etmediğini, gerçekleştirdiği işlemlerin
                kendi nam ve hesabına olduğu, bir başkasının emir ve talimatıyla
                ya da başka biri nam veya hesabına işlem gerçekleştirmediğini;
                hesap ve cüzdandaki varlıklarının hukuka uygun yollardan temin
                edildiği; hiçbir şekilde suçtan elde edilen gelir niteliği
                taşımadığı ve bu nitelikteki gelirin aklanması ya da terörizmin
                finansmanı amacıyla kullanılmadığı ve kullanılmayacağını kabul,
                beyan ve taahhüt eder.
              </p>

              <br />
              <h4>Risklerin Bildirimi</h4>
              <p>
                5.1. KULLANICI, Platformda gerçekleştirmiş olduğu işlemler
                sonucunda kar elde edebileceği gibi zarar riski bulunduğunu
                kabul eder. KULLANICI, Platformda işlem yapmaya karar vermeden
                önce, karşılaşabileceği riskleri tahlil ettiğini,
                gerçekleştireceği işlemlere mali durumu ve kısıtlarını dikkate
                alarak karar verdiğini beyan eder. KULLANICI, işbu KULLANICI
                SÖZLEŞMESİ’nin ayrılmaz bir parçası olan Risk Bildirim’ini
                okuduğunu ve kabul ettiğini beyan eder.
                <br />
                <br />
                5.2. KULLANICI;
                <br />
                a. Piyasada oluşacak fiyat hareketleri sonucunda hesabında
                bulunan paranın tümünü kaybedebileceğini, işlemin türüne göre
                hesabında bulunan para tutarını dahi aşabileceğini;
                <br />
                <br />
                b. Teminat karşılığı gerçekleştirilen işlemlerde piyasadaki
                fiyat hareketlerinin beklentilerine uygun gelişmesi sonucunda
                yüksek kazançlar sağlayabileceği gibi beklentileri aksine
                gelişmesi durumunda ciddi zararlara da yol açabileceğini,
                <br />
                <br />
                c. Platformda yer alan bülten, belge, rapor, analiz veya benzeri
                nitelikteki dokümanlarda yer alan bilgi ve değerlendirmelerin
                eksik veya doğrulanmaya muhtaç olabileceğini,
                <br />
                <br />
                d. ŞİRKET personeli tarafından yapılacak analiz ve yorumların
                kişiden kişiye farklılık arz edebileceği ve bu analizlerde
                yapılan öngörülerin gerçekleşmeme riskinin bulunduğunu,
                <br />
                <br />
                e. Platformda yabancı para cinsinden yapılan işlemlerde kur
                riskinin olduğunu, kur dalgalanmaları nedeniyle Türk Lirası
                bazında değer kaybı olabileceği, yabancı sermaye ve döviz
                hareketlerinin kısıtlanabileceğini, ek ve/veya yeni vergiler
                getirebileceğini, alım/satım işlemlerinin zamanında
                gerçekleşmeyebileceğini, kabul ve beyan eder.

                <br />
                <br />
                5.3. KULLANICI kripto varlık çiftlerindeki önemli fiyat
                hareketlerinin olabileceğini, piyasalardaki bu hareketliliğe
                bağlı olarak Platformdaki fiyatların oynaklık gösterebileceğini;
                kripto varlık yatırımlarının riskler içerdiğini ve bu riskleri
                anladığını; işlemler sonucunda oluşan kar ve zararlardan
                kendisinin sorumlu olduğunu, Platformdaki fiyatların kendi
                beklentileri doğrultusunda gerçekleşmemesi ve/veya fiyat
                hareketleri nedeniyle karşı karşıya kaldığı herhangi bir kayıp
                ya da zarardan ŞİRKET’in hiçbir sorumluluğu olmadığını,
                ŞİRKET’ten kasıtlı davranışı dışında hiçbir hak talep
                etmeyeceğini ve bu nedenle maddi/manevi tazminat ya da başka bir
                adla herhangi bir talepte bulunmayacağını kabul, beyan ve
                taahhüt eder.
                <br />
                <br />
                5.4. KULLANICI, kripto para alım-satım işlemleri yapmak için
                akli melekelere, gerekli bilgi ve deneyime sahip olduğunu,
                gerçekleştirmiş olduğu işlemler sonucunda zarar edebileceğini
                bildiğini ve bu zararları göze aldığını ve bu zararları
                kaldırabilecek finansal güce sahip olduğunu beyan ve taahhüt
                eder. KULLANICI kendi kararlarını alabilecek yaş ve özellikte
                olup hakkında veli veya vasi tayin edilmemiş olduğunu, kısıtlı
                olmadığını beyan eder.
                <br />
                <br />
                5.5. KULLANICI işbu Sözleşmenin yürürlük tarihi itibariyle
                kripto varlık alım satımı işlemlerine yönelik herhangi bir yasal
                düzenleme bulunmadığını bildiğini; bir uyuşmazlık durumunda bu
                uyuşmazlığın genel hukuk hükümlerine tabi olduğunu; ancak işbu
                Sözleşmenin yürürlük tarihinden sonra kripto varlıkların
                alım/satım işlemlerine ilişkin yasal düzenlemelerin yürürlüğe
                girmesi durumunda getirilen bu yükümlülüklere tabi olacağını;
                yükümlülüklere uyum amacıyla ŞİRKET tarafından talep edilen her
                türlü bilgi ve belgenin ŞİRKET tarafından belirlenen süre
                içerisinde tevdi edeceğini; ilgili düzenlemelere uygun
                davranacağını; aksi bir uygulamada ŞİRKET’in karşı karşıya
                kalacağı maddi ve manevi tazminat başta olmak üzere zarar,
                ziyan, idari para cezası, adli para cezası ya da her nam altında
                olursa olsun maddi kayıpları ŞİRKET’in yazılı talebi üzerine
                başkaca bir işleme gerek olmaksızın defaten ödeyeceğini;
                ŞİRKET’in ihbara gerek olmaksızın işbu Sözleşmeyi fesih hakkının
                bulunduğunu kabul, beyan ve taahhüt eder.
              </p>

              <br />
              <h4>Platform</h4>
              <p>
                6.1. Platform, tüm fikri mülkiyet hakkı  Hotto Kripto Varlık Alım Satım Platformu A.Ş. ‘ne ait, kripto varlık çiftlerinin online olarak alım ya da
                satımı amacıyla emir iletme, bu emirlerin eşleşerek işlem
                gerçekleştirmelerine, kullanıcılar adına cüzdan açılabilmesine,
                bu cüzdanlardaki hareketlerin ilgili blok–zincirler üzerinde
                kaydedilmesine imkân sağlayan bir yazılımdır.
                <br />
                <br />
                6.2. Platformda kullanıcılar birbirleri ile kripto para
                birimlerini alır veya satarlar. Platformda yer alan fiyatlar
                alıcı ve satıcının vermiş olduğu fiyatlardır. ŞİRKET’in
                fiyatların değişmesinden dolayı hiçbir sorumluluğu yoktur.
                KULLANICI’nın emri Platforma girdiği an ile işlemin
                gerçekleştiği an arasında fiyatlarda meydana gelebilecek
                değişikliklerden, bu değişiklikler nedeniyle ortaya çıkabilecek
                zarar ve kayıplardan ŞİRKET sorumlu tutulamaz.
                <br />
                <br />
                6.3. ŞİRKET Platformda işlemlerin gerçekleşmesine ilişkin
                garanti vermez. İşlemler alıcı ve satıcının aynı yöndeki
                iradesinin eşleşmesi ile gerçekleşir. KULLANICI’nın girmiş
                olduğu emrin eşleşmemiş olması, kısmen veya tamamen
                gerçekleşmemesi nedeniyle, ŞİRKET’in sorumluluğuna gidilemez.
                ŞİRKET hiçbir şekilde işlemlerin sonuçlanmasını ya da girilen
                emirlerin gerçekleşmesini garanti etmez.
                <br />
                <br />
                6.4. Platformda yer alan tüm servisler, domain ve diğer her
                türlü alan adları, yazılım kodları, ara yüzler ve bunların
                içerikleri, kripto çiftlere ilişkin analiz, bülten, video,
                çizimler, tasarımlar, modeller ve diğer her türlü içeriğe
                ilişkin fikri ve sinai mülkiyet hakları ŞİRKET’e aittir.
                KULLANICI söz konusu içeriği ŞİRKET’in önceden izni olmaksızın
                kopyalamayacağını, çoğaltmayacağını ve yayınlamayacağını, ters
                mühendislik işlemlerine tabi tutmayacağını kabul, beyan ve
                taahhüt eder. KULLANICI bu hükümlere aykırı davranması nedeniyle
                ŞİRKET’in karşı karşıya kalacağı ceza, tazminat veya her ne nam
                altında olursa olsun zarar ve ziyanı ŞİRKET’in yazılı talebi
                halinde başkaca bir işleme ihtiyaç duymaksızın defaten ödemeyi
                kabul ve taahhüt eder. Bu hükme aykırılık Sözleşmenin tek
                taraflı, haklı ve ihbarsız feshi nedeni olup tüm hukuki ve cezai
                sorumluluk KULLANICI'ya aittir.
                <br />
                <br />
                6.5. Platformda yer alan tüm veriler, raporlar, analizler,
                istatistikler sadece bilgilendirmeye yönelik olup doğruluğu
                ŞİRKET tarafından garanti edilmemektedir. Verilerin birbiriyle
                çelişkili veyahut tutarsız olması halinde doğabilecek
                zararlardan ŞİRKET sorumlu değildir.
                <br />
                <br />
                6.6. ŞİRKET, Platformun altyapısını, uygulamalarını, şeklini ve
                içeriğini KULLANICI’nın gerçekleştirmiş olduğu işlemlerden
                kaynaklanan haklarına zarar vermemek koşuluyla değiştirmekte,
                daraltmakta veya genişletmekte, yeni ürünler / hizmetler ekleyip
                mevcut ürünlerin/hizmetlerin bir veya birkaçını çıkartmakta
                serbesttir.
              </p>

              <br />
              <h4>İşlemlerin Gerçekleştirilmesi</h4>
              <p>
                7.1. KULLANICI Platformdaki alım satım işlemlerini ŞİRKET’in
                belirleyeceği şartlar ve şekilde gerçekleştireceğini kabul,
                beyan ve taahhüt eder.
                <br />
                <br />
                7.2. ŞİRKET, KULLANICI’nın vereceği emri, herhangi bir gerekçe
                göstermeksizin kısmen veya tamamen kabul etmemek hakkına
                sahiptir. Şu kadar ki ŞİRKET emirin kısmen veya tamamen kabul
                edilmediğini KULLANICI’ya bildirmek zorundadır.
                <br />
                <br />
                7.3. ŞİRKET, KULLANICI’nın açabileceği pozisyon miktarına veya
                belli ürünler bazında sınırlama ve/veya işlem limitleri getirme
                hakkına sahiptir.
                <br />
                <br />
                7.4. KULLANICI tarafından işlemlerin Platform üzerinden
                gerçekleştirmesi esastır. Ancak Platforma bağlanamaması ya da
                emirlerin iletiminde teknik aksaklık ile karşılaşılması
                durumunda ŞİRKET kendi takdirine bağlı olarak telefon ile emir
                alabilir. ŞİRKET kendisine telefonla iletilen emirleri kabul
                etmek zorunda değildir. KULLANICI, ŞİRKET’in kastı dışında
                Platformda veya telefon dahil diğer iletişim sistemlerinde
                yaşanan problemler nedeniyle emir ve talimatlarının geç veya hiç
                gerçekleşmediğini ileri sürerek herhangi bir tazminat talebinde
                bulunamayacağını kabul, beyan ve taahhüt eder. ŞİRKET kendisine
                telefon üzerinden iletilen emirlere ilişkin görüşmeleri kayıt
                altına alabilir.
                <br />
                <br />
                7.5. KULLANICI Platform üzerinden veya telefon ile vermiş olduğu
                emirlere, ŞİRKET yetkilileri veya çalışanları ile
                gerçekleştirmiş olduğu görüşmelere veya elektronik posta ve
                diğer yöntemler sağlanan iletişime ilişkin kayıtların uygun
                ortamlarda kaydedilmesini, saklanmasını ve gerekmesi durumunda
                dinlenmesine muvafakat eder.
                <br />
                <br />
                7.6. Platforma erişim, mobil uygulamanın kullanımı, şifre
                tahsisi ve benzeri işlemlere ilişkin bilgisayar, modem ve
                telefon hattına veya benzeri özelliklere sahip teknik imkanlara
                sahip olması, Platform şartlarına uygun yazılımı ve donanımı
                bulundurması ve bu yazılımı ve donanımı kullanabilecek teknik
                bilgiye sahip olunması KULLANICI’nın sorumluluğundadır.
                Platformda üyelik için başvurulması ve kendisine şifre verilmesi
                talebinde bulunması hâlinde KULLANICI’nın söz konusu teknik
                şartlara ve bilgiye sahip olduğu kabul edilir. Teknik şartların
                sağlanamaması veya eksik veya yetersiz olması veya gerekli
                teknik bilgiye sahip olunmaması durumunda Platformdan
                yararlanılamamasının ya da alım – satım ya da nakit ve kripto
                varlık transfer emirlerinin sorumluluğu tamamen KULLANICI'ya
                aittir.
                <br />
                <br />
                7.7. KULLANICI, Platforma emir iletebilmek için
                www.bitistanbul.com adresi üzerinden veya mobil uygulama
                vasıtasıyla ŞİRKET tarafından kendisine tanınan kullanıcı adı ve
                şifre ile Platforma erişir. KULLANICI ilgili yazılımı kişisel
                bilgisayarına veya mobil cihazlarına yüklemeli ve çalışır hâle
                getirmelidir. KULLANICI, kendisi tarafından belirtilen cep
                telefonuna veya elektronik posta adresine ŞİRKET tarafından
                gönderilen şifresini kullanarak Platform vasıtasıyla işlem
                yaparak gerçekleştirir.
                <br />
                <br />
                7.8. ŞİRKET tarafından tahsis edilen kullanıcı kodu ile Platform
                tarafından oluşturulmakla birlikte daha sonra kendisi tarafından
                değiştirilen ya da dilediği zaman değiştirebileceği şifre
                kullanarak ilettiği tüm emirler KULLANICI’nın yazılı imzası
                olmadan işleme alınacak olup bu şekilde verilecek emirlerin
                sadece KULLANICI'nın bildiği, bilmesi gerektiği şifre ile
                gerçekleştirilmesi nedeniyle yazılı talimat sayılması hususunda
                KULLANICI ile ŞİRKET karşılıklı mutabakata varmışlardır.
                <br />
                <br />
                7.9. KULLANICI, gerekli gördüğü takdirde ŞİRKET’in teyit isteme
                ve kimlik doğrulama hakkını saklı tuttuğunu kabul eder.
                KULLANICI'dan teyit veya doğrulama alınamaması nedeniyle emir
                veya işlemin yerine getirmemesinden ya da teyit veya doğrulama
                alması süreci nedeniyle yaşanacak gecikmelerden doğabilecek
                zararlardan dolayı kasıtlı davranışı dışında ŞİRKET’e hiçbir
                sorumluluk yüklenemez.
                <br />
                <br />
                7.10. KULLANICI’nın Platforma emir iletebilmesi için hesabında
                alım emrini karşılayacak tutarda nakit ve/veya satım emrini
                karşılayacak tutarda kripto varlık bulunması gerekir. İşlemlerin
                kısmen veya tamamen borçlanma suretiyle gerçekleştirilmesi bu
                tür emirlerin ŞİRKET tarafından kabulü halinde mümkündür. ŞİRKET
                nakit veya kripto varlık borçlanmak suretiyle işlem
                gerçekleştirilmesine ilişkin kuralları Platform vasıtasıyla
                duyurur. ŞİRKET bu işlemler için ayrı bir Sözleşme imzalanmasına
                karar verebilir.
                <br />
                <br />
                7.11. KULLANICI, kendisine tahsis edilen şifrenin gizli
                tutulması, üçüncü kişiler ile paylaşılmaması, üçüncü kişiler
                tarafından ele geçirildiğini öğrendiğinde bu hususu iş saatleri
                içerisinde ivedilikle ŞİRKET’e bildirilmesine ilişkin tüm
                sorumluluk KULLANICI’ya aittir. KULLANICI kendisine tahsis
                edilen şifre ile gerçekleştirilen tüm işlemlerin sorumluluğunun
                kendisinde olacağını, şifre girilerek gerçekleştirilen tüm
                işlemlerin hesabına borç kaydedileceği ve bu yönde hiçbir
                itirazının bulunmayacağını kabul, beyan ve taahhüt eder.
                KULLANICI, şifrenin üçüncü kişiler tarafından kullanılması
                neticesinde yapılan işlemlerden, internet işlemleri ile ilgili
                kendisinden kaynaklanmayan hile ve sahteciliklerin
                sonuçlarından, Platforma veya mobil uygulamaya bağlantı
                sağladığı genel veya özel iletişim araç ve sistemlerinin
                işlememesinden veya arızalanmasından, bu nedenle ŞİRKET
                tarafından gönderilen bildirimlerin KULLANICI tarafından
                alınmamasından, internet vasıtası ile gelen veya ŞİRKET
                tarafından KULLANICI’ya gönderilen bilgi, talimat ve
                bildirimlerin değişik veya eksik iletilmiş olmasından doğacak
                sonuçlardan ŞİRKET’in sorumlu olmayacağını kabul ve beyan eder.
                <br />
                <br />
                7.12. KULLANICI, güvenlik amacıyla ve şüpheli durumlarda
                şifrenin yeniden belirlenmesi amacıyla hesaba erişimin
                engellenebileceğini kabul eder. ŞİRKET bu durumda en seri
                iletişim aracıyla KULLANICI’yı bilgilendirecektir. KULLANICI
                böyle bir blokenin hesabının güvenliği için yapılabileceğini
                önceden bildiğini, böyle bir durumun gerçekleşmesi halinde
                hesabına ilişkin emir iletememekten dolayı doğabilecek zararlar
                nedeniyle ŞİRKET’i sorumlu tutmayacağını gayrı kabili rücu
                kabul, beyan ve taahhüt eder.
                <br />
                <br />
                7.13. KULLANICI Platformda ilan edilen süreler içerisinde emir
                iletebilir. Platformda, genel prensip olarak 7/24 hizmet
                verilecektir. Şu kadar ki, KULLANICI ŞİRKET’in kararına bağlı
                olarak ulusal ve/veya uluslararası piyasaların tatil olduğu
                günlerde emir kabul etmeyebileceğini, yaz saati uygulaması,
                bakım hizmetleri ve benzeri değişiklikler nedeniyle emir iletim
                saatlerinde değişikliğe gidebileceğini kabul eder. Emir iletim
                saatlerinde değişikliğe gidilmesi durumunda bu husus uygun ortam
                ile KULLANICI’ya bildirilir. KULLANICI Platformda yaşanabilecek
                teknik aksaklıklar sonucundaki kesintiler, planlı veya plansız
                bakım çalışmaları gibi nedenlerle Platformdaki hizmetlerin
                sağlanamaması durumunda ŞİRKET’in sorumlu olmadığını, bu
                nedenlerle ŞİRKET’i kar mahrumiyeti dahil hiçbir zarar, ziyan ya
                da her ne nam altında olursa olsun kayıp nedeniyle sorumlu
                tutmayacağını, talepte bulunmayacağını beyan ve taahhüt eder.
                <br />
                <br />
                7.14. ŞİRKET, kendisinden kaynaklanmayan teknik nedenlerden
                ve/veya mücbir sebeplerden ötürü KULLANICI tarafından Platforma
                iletilen emir ve talimatların yerine getirilmemiş olmasından
                veya eksik ya da yanlış yerine getirilmesinden sorumlu değildir.
                KULLANICI, Platform üzerinden gerçekleştirilecek işlemlere
                ilişkin her türlü riski bildiğini ve bu risklerin gerçekleşmesi
                neticesinde doğabilecek tüm zararın ŞİRKET’in kastı hariç
                kendisine ait olduğunu kabul, beyan ve taahhüt eder.
                <br />
                <br />
                7.15. KULLANICI, Platformun internet dahil, elektronik ortamlar
                üzerinden emir iletebilen bir uygulama olduğunu bildiğini ve
                kabul ettiğini beyan eder. Bu çerçevede, KULLANICI kendisine
                iletişim hizmeti sağlayan internet servis sağlayıcılardan, mobil
                iletişim hizmetlerinden, ulusal ve/veya uluslararası internet
                bağlantılarından kaynaklanabilecek sorunlardan, kendisi ve/veya
                ŞİRKET merkezli olarak internet hizmetlerinin kesintiye
                uğramasından ve bunlara bağlı performans kayıplarından kastı
                dışında ŞİRKET’i sorumlu tutmayacağını kabul, beyan ve taahhüt
                eder.
              </p>

              <br />
              <h4>Emir ve İşlemlerin İptali</h4>
              <p>
                8.1. ŞİRKET, KULLANICI’nın herhangi bir yazılım aracılığıyla ya
                da ŞİRKET tarafından sağlanmayan, desteklenmeyen ya da kabul
                edilmeyen üçüncü parti uygulamalar aracılığıyla ya da Platforma
                müdahale edilerek oluşturulmuş fiyatlar üzerinden işlem yapması,
                emir iletmesi, iletmiş olduğu emirlerdeki fiyat ve miktarları
                değiştirmesi ve söz konusu durumun ŞİRKET tarafından tespit
                edilmesi halinde söz konusu emir veya işlemlerin ŞİRKET
                tarafından iptal edilebileceğini, bu pozisyonlardan oluşan
                karların tarafından ödenmesinden imtina edilebileceğini, bu
                karların silinebileceğini ve Platform nezdindeki hesabının
                işleme kapatılabileceğini gayri kabili rücu kabul eder.
                <br />
                <br />
                8.2. KULLANICI, elektronik işlem platformuna yansıtılan
                fiyatların temin edildiği sistemlerde, likidite sağlayıcılarında
                ya da Platformlarda yaşanan problemler nedeniyle kripto varlık
                çiftleri alım satım fiyatlarının farklılıklar yaşanması, o anda
                diğer Platformlardaki aynı kripto varlık çiftlerinde geçerli
                fiyatlara göre bariz derecede farklı fiyatların yer alması
                durumunda bilerek veya bilmeyerek, bu durumu avantaj haline
                getirerek, aslında var olmayan fiyatlar üzerinden kar sağlaması
                halinde ilgili tüm işlemlerinin ŞİRKET tarafından iptal
                edilebileceğini ve bu işlemler nedeniyle elde etmiş olduğu karın
                silineceğini ya da ödenmekten imtina edilebileceğini kabul ve
                beyan eder.
                <br />
                <br />
                8.3. KULLANICI, iletişimde yaşanabilecek güçlükler, olağandışı
                piyasa koşulları veya belirli bir kripto varlık çifti ile ilgili
                likidite sorunları nedeniyle Platformda yer alan fiyat
                kotasyonları ile aynı anda diğer Platformlarda oluşan fiyatlar
                arasında farklılıklar oluştuğu takdirde; ŞİRKET’in bekleyen
                emirleri iptal edebileceğini, fiyatlarını değiştirebileceğini,
                emirleri gerçekleştirmeyebileceğini veya müşteri hesaplarını
                işleme kapatabileceğini kabul ve beyan eder.
                <br />
                <br />
                8.4. ŞİRKET, işlemlerin ve/veya işlemlerin iletildiği Platformu,
                platformun altyapısı özelliğinin, kripto varlık çiftlerinin
                özelliklerinin, fiyat verilerinin istismarına yönelik işlem
                yapan KULLANICI’nın işlemlerini iptal etme, bu eylemler
                nedeniyle elde edilen karı silme ya da ödenmekten imtina etme
                ve/veya KULLANICI hesabını kapatma hakkını saklı tutar.
                KULLANICI’nın bu tarz işlemlerinden dolayı ŞİRKET’in doğrudan
                veya dolaylı olarak zarara uğraması hâlinde; uğrayacağı her
                türlü zararın tazminini KULLANICI’dan talep etme hakkına
                sahiptir.
                <br />
                <br />
                Platformda, emirlerin gerçekleşmesini ve/veya fiyat akışını
                yavaşlatan, geciktiren ve engelleyen ya da bunlarla sınırlı
                olmamak kaydıyla sebebi ne olursa olsun yaşanan teknik
                sorunlardan haksız kazanç sağlayan veya yatırımcılar arasında
                haksız rekabete yol açan her türlü faaliyet işlem istismarı
                olarak kabul edilir. KULLANICI’nın doğrudan işlem istismarı
                faaliyetinde bulunmadığı ancak bundan fayda sağladığı durumlarda
                da ŞİRKET işlem istismarı kapsamında KULLANICI’nın işlemlerini
                iptal etme, bu eylemler sonucunda elde edilen karı silme,
                ödemekten imtina etme ve/veya hesabını kapatma hakkını saklı
                tutar.
                <br />
                <br />
                8.5. KULLANICI tarafından Platforma iletilen emirlerin veya
                nakit ya da kripto varlık transfer talimatlarının gerçekleşip
                gerçekleşmediğinin, hesabında oluşan kar veya zararın takibi
                KULLANICI sorumluluğundadır.
              </p>

              <br />
              <h4>Kullanıcının Yükümlülükleri</h4>
              <p>
                9.1. KULLANICI hesap açılışı sırasında ve iş ilişkisinin devamı
                süresince ŞİRKET’e vereceği bilgilerin doğru, kesin, güncel ve
                eksiksiz olduğunu, kimliğini herhangi bir amaçla gizlemediğini,
                bilgilerde değişiklik olması halinde bilgileri ivedilikle ancak
                en geç 5 işgünü içerisinde güncelleyeceğini, aksi takdirde
                yaptığı işlemlerde değişiklik öncesi bilgilerin esas alacağını
                kabul, beyan ve taahhüt eder.
                <br />
                <br />
                9.2. KULLANICI’nın hesap açılışı sırasında kimlik ve adres
                teyidi amacıyla tevdi etmiş olduğu evrakın, bilgi ve belgelerin
                sahte olması ya da üzerinde tahrifat yapılmış olması, hile
                yapması, yanlış, yanlış ve yanıltıcı bilgiler vermesi gibi
                durumlarda, bu yöndeki kasıtlı davranışı dışında ŞİRKET sorumlu
                tutulamaz. KULLANICI bu konulara ilişkin oluşabilecek maddi ve
                manevi her türlü sorumluluk ve zararın kendisine ait olduğunu
                kabul eder.
                <br />
                <br />
                9.3. Müşterek hesaplar hariç, KULLANICI kendi adına açılmış olan
                hesaplarda kendi adı ve hesabına işlem gerçekleştireceğini,
                hesabın gerçek faydalanıcısının kendisi olduğunu, hesabında
                kendi malvarlığı dışında başkaları adına da ya da borçlanma
                dışında başkalarından temin etmiş olduğu nakit veya kripto
                varlıklar ile portföy yöneticiliği olarak nitelendirebileceği
                işlemlerde bulunmayacağını, hesabında gerçekleştirdiği işlemlere
                dayalı olarak başkalarından doğrudan veya dolaylı olarak
                komisyon, ücret veya benzeri ad altında herhangi bir tutarı
                tahsil etmeyeceğini, ŞİRKET tarafından sunulan veya benzeri
                nitelikteki hizmetlerde bulunmak amacıyla mekan, teknik donanım,
                personel ve benzeri organizasyonlar oluşturmayacağını;
                yasaklanan bu eylemlerde bulunması halinde ortaya çıkabilecek
                tüm cezai ve hukuki sorumluluğun kendisine ait olacağını;
                ŞİRKET’in bu eylemler nedeniyle idari veya adli para cezası,
                tazminat veya her ne nam altında olursa olsun herhangi bir
                şekilde zarar veya ziyan görmesi halinde ŞİRKET’in ödemekle
                yükümlü kalacağı tutarı ŞİRKET’in yazılı talebi üzerine başkaca
                bir işleme ya da mahkeme kararına gerek olmaksızın defaetle
                karşılayacağını beyan ve taahhüt eder.
                <br />
                <br />
                9.4. KULLANICI kendi veya kontrolünde bulunan başka hesaplar
                üzerinden sosyal medya, yazılı veya görsel basın veya herhangi
                bir mecrada ŞİRKET’in adını içerecek şekilde ya da Platformda
                yer alan bilgi, belge, analiz, broşür, bülten veya her ne ad
                altında olursa olsun içeriği, kripto varlıklara ilişkin
                paylaşımda bulunmayacağını; ŞİRKET ile ilgisi, acentesi,
                çalışanı olduğu şeklinde veya bu şekilde anlaşılabilecek
                ifadelere yer vermeyeceğini; bu içeriği kullanarak sosyal medya
                dahil her türlü yazılı, görsel ve işitsel mecrada kripto
                varlıkların alınması, satılması ya da mevcut pozisyonların
                korunması veya bu anlama gelebilecek öneriler, stratejiler ya da
                her ne ad altında ve şekilde olursa olsun yönlendirici nitelikte
                tavsiyelerde, yorum ve değerlendirmelerde bulunmayacağını beyan
                ve taahhüt eder. Aksi yöndeki davranışı nedeniyle ortaya
                çıkabilecek tüm cezai ve hukuki sorumluluğun kendisine ait
                olacağını; ŞİRKET’in bu eylemler nedeniyle idari veya adli para
                cezası, tazminat veya her ne nam altında olursa olsun herhangi
                bir şekilde zarar veya ziyan görmesi halinde ŞİRKET’in ödemekle
                yükümlü kalacağı tutarı ŞİRKET’in yazılı talebi üzerine başkaca
                bir işleme ya da mahkeme kararına gerek olmaksızın defaetle
                karşılayacağını beyan ve taahhüt eder.
                <br />
                <br />
                9.5. KULLANICI ŞİRKET’in yazılı onayı olmaksızın hesabında
                bulunan varlıkları üzerinde herhangi bir kişiye teminat hakkı
                tanımayacaktır. Bu yönde bir teminat hakkının tanınmış olması
                hukuken geçerli olmayacaktır.
                <br />
                <br />
                9.6. Hesaplarının güvenliği KULLANICI sorumluluğundadır. ŞİRKET
                tarafından önerilen güvenlik önlemleri tavsiye niteliğindedir.
                Güvenlik önemleri alınmış olmasına rağmen, KULLANICI’nın doğmuş
                veya doğacak olan zararlarından ŞİRKET hiçbir şekilde sorumlu
                değildir. TARAFLAR, KULLANICI hesaplarının güvenliği için
                kendilerine düşen azami özeni göstermekle yükümlüdür. ŞİRKET,
                tüm çabalarına rağmen oluşabilecek hırsızlık, dolandırıcılık ya
                da hesabın/şifrelerin ele geçirilmesi veya benzeri şekilde
                hukuki aykırı ve/veya suç niteliğindeki eylem ve olaylardan
                dolayı hiçbir şekilde sorumluluk kabul etmez.
                <br />
                <br />
                9.7. KULLANICI hesap açılışı sırasında beyan etmiş olduğu
                telefon ve elektronik posta adresini ulaşılabilir şekilde
                tutmakla yükümlüdür. ŞİRKET’in, bu telefon ve elektronik posta
                adresine yapacağı bildirimlerin KULLANICI’ya tebliğ edildiği
                kabul edilir. KULLANICI mesajın kendisine ulaşmadığını iddiası
                ile ŞİRKET’e herhangi bir sorumluluk yükleyemez. Hesabın bütün
                kontrolü KULLANICI’nın sorumluluğundadır. ŞİRKET hesapta oluşan
                kar / zarar ve gerçekleşen işlemlerin takibi amacıyla gerekli
                önlemleri alır.
                <br />
                <br />
                9.8. KULLANICI, hesabını üçüncü şahıslara devredemez, satamaz,
                bağışlayamaz veya her ne gerekçe ile olursa olsun üçüncü
                kişilere kullandıramaz. Bu yasağa aykırı hareketlerin tespiti
                halinde KULLANICI hesabının önceden hiçbir bildirime ihtiyaç
                olmaksızın işlemlerin iptal edilmesi, durdurulması veya askıya
                alınmasını, hesabında kar oluştu ise bu karın silinmesini,
                hesabının tüm işlemlere kapatılmasını, hesabına erişimin geçici
                veya sürekli olarak engellenmesini kabul ettiğini beyan ve
                taahhüt eder. Bu nedenle meydana gelen tüm zararlardan KULLANICI
                sorumlu olup tüm cezai müeyyideler de KULLANICI’ya aittir. Bu
                maddeye aykırı eylemleri nedeniyle KULLANICI hesabının iptali,
                durdurulması veya askıya alınması, erişimin engellenmesi
                durumunda kullanıcının kripto para varlıklarının satışına izin
                verilmez, bu varlıklar KULLANICI tarafından bildirilecek cüzdana
                gönderilir.
                <br />
                <br />
                9.9. KULLANICI kendisine fazla yapılan ödeme ve/veya
                transferleri 2 (iki) iş günü içerisinde iade eder. İade
                edilmemesi durumunda herhangi bir ihtara veya başkaca bir hukuki
                işleme gerek olmaksızın ŞİRKET yapılan sebepsiz ödeme ve/veya
                transferleri geri alma, KULLANICI’nın bu yöndeki taleplerini
                reddetme hakkına sahiptir.
                <br />
                <br />
                9.10. KULLANICI, Platformda yer alan, şifreli veya şifresiz
                giriş yapılabilen sayfalardaki duyuru, bilgi, ücret, değişiklik,
                kampanya ve tarifeler gibi bilcümle bildirimleri takip
                edeceğini, bunları bilmemesi nedeniyle uğrayabileceği
                zararlardan dolayı ŞİRKET’in sorumlu olmayacağını kabul ve beyan
                eder.
                <br />
                <br />
                9.11. KULLANICI, Platformun kendisi veya kendisi ile
                ilişkilendirilebilecek üçüncü kişiler tarafından kötüye
                kullanılmasından dolayı ortaya çıkan bütün zarar ve ziyandan
                sorumlu olduğunu ve tazmin edeceğini; bu yöndeki bütün itiraz ve
                def’ilerden feragat ettiğini gayrikabili rücu kabul, beyan ve
                taahhüt eder.
                <br />
                <br />
                KULLANICI, işbu Sözleşmedeki iş ve işlemlerden kaynaklanan
                borçlarını zamanında ve tamamen ödemediği takdirde ŞİRKET’in
                uğrayacağı her türlü kayıptan, hasardan, masraftan, harcamadan,
                zarardan veya ziyandan sorumlu olacağını kabul ve taahhüt eder.
              </p>

              <br />
              <h4>Nakit Yatırma / Çekme İşlemleri</h4>
              <p>
                10.1. KULLANICI hesabına ancak kendi adına açılmış olan bir
                banka hesabından nakit transferi yapabilir. KULLANICI hesabına
                üçüncü kişilerden gelen nakit yatırma talepleri kabul edilmez.
                Nakit yatırma işlemlerinde bankacılık sisteminden kaynaklanan
                gecikmeler nedeniyle ŞİRKET sorumluluk kabul etmez.
                <br />
                <br />
                10.2. KULLANICI’nın nakit çekme talebinde bulunabilmesi için
                kendi kullanıcı kodu ve şifresi ile giriş yapacağı Platformda
                yer alan nakit çekme talebinin oluşturulmuş veya talebin yazılı
                olarak ŞİRKET’e iletilmiş olması gerekir. ŞİRKET para çekme
                taleplerini en geç 2 (iki) işgünü içerisinde tam ve nakden
                yerine getirir. Nakit transfer talepleri ancak KULLANICI adına
                açılmış olan Türkiye’de yerleşik banka hesaplarına yapılır,
                üçüncü bir kişi hesabına yapılmaz. Nakit çekme taleplerine
                ilişkin usul ve esaslar Platformda yer alır. ŞİRKET bu usul ve
                esaslar değişiklik yapma hakkını saklı tutar. KULLANICI hesap
                açılışı ile Platformda yer alan usul ve esaslar ile bunlarda
                yapılacak değişiklikleri kabul etmiş sayılır.
                <br />
                <br />
                KULLANICI’nın TL’sı dışında ABD doları veya diğer para cinsleri
                üzerinden nakit çekme talebi oluşturması durumunda, hesapta
                bulunan tutar bankalar arası satış kuru üzerinden ilgili para
                birimi cinsine çevrilerek ödeme yapılır. Yabancı para cinsleri
                üzerinden yapılacak nakit çekme işlemlerinde bankacılık
                sisteminden kaynaklanan gecikmeler nedeniyle ŞİRKET hiçbir
                sorumluluk kabul etmez. ŞİRKET gerçekleşen işlemlere ilişkin
                olarak nakit yükümlülüklerinin kredi veya banka kartı ile
                ödenmesi konusunda karar verebilir. ŞİRKET, Platformda yer alan
                nakit ya da kripto varlık yatırma ve nakit çekme işlemlerine
                ilişkin usul ve esaslara aykırı ya da şüphelenilen işlemleri
                reddetme; aykırılığın veya şüphenin transfer / ödeme
                işlemlerinin gerçekleşmesinden sonra tespit edilmesi veya ortaya
                çıkması halinde ise transfer/ödemeleri iade etme veya söz konusu
                tutarlar ile iletilecek emirleri kabul etmeme hakkına sahiptir.
                KULLANICI, ŞİRKET tarafından reddedilen veya gerçekleştirilmeyen
                transfer/ödemelerin iadesi için kimlik ibrazı ve transfer/ödeme
                gerçekleştiren kişinin banka hesabı bilgisini isteyebileceğini,
                bu bilgilerin tevdi edilmemesi durumunda işlemin
                gerçekleştirilmeyeceğini kabul eder.
              </p>

              <br />
              <h4>Ücret ve Komisyonlar</h4>
              <p>
                11.1. Platforma gerçekleştirilen işlemler karşılığında tahsil
                edilecek komisyon, ücret, faiz, maliyet ya da her ne nam altında
                olursa olsun KULLANICI’nın ödemekle yükümlü olacağı tüm gider ve
                masraflar Platforma vasıtası ile ilan edilecektir. KULLANICI
                işbu Sözleşmeyi kabul ile Platform nezdinde hesap açması
                durumunda tüm gider ve masrafları kabul ettiğini beyan eder.
                <br />
                <br />
                11.2. ŞİRKET Platform vasıtası ile ilan etmek kaydıyla gider ve
                masraflarda değişiklik yapabilir. Değişiklikler Platformda ilan
                edildikleri an itibariyle geçerli olur ve uygulanır.
                KULLANICI’nın ücret, gider ve masrafa tarifelerinde yapılan
                değişiklik nedeniyle Sözleşmeyi feshetme hakkı saklıdır.
                <br />
                <br />
                11.3. Sözleşme kapsamında yer alan iş ve işlemler nedeniyle
                ortaya çıkan her türlü vergi, harç, masraf ve hizmet bedelleri,
                hesap işletim gideri, hesap ekstresi ve diğer bildirimlere
                ilişkin posta ve noter ücretleri, EFT ve diğer transfer
                masrafları ile diğer ücretler, her ne ad altında olursa olsun
                kamuya ödenen tutarlar ile üçüncü kişilere ödenen diğer gider ve
                hizmet bedelleri KULLANICI tarafından ödenir.
                <br />
                <br />
                11.4. Mahiyeti ne olursa olsun masraf, vergi, fon ve yükümlerin
                ve sair her türlü yükümlülüklerin ödenmesi ve sorumluluğu ister
                Türkiye içinde ister Türkiye dışında olsun KULLANICI’ya aittir.
                KULLANICI, ŞİRKET’in vergi, resim, harç, fon, damga vergisi,
                komisyon, posta masrafı, kendisine ait olan muamele ücreti ve
                kendi namına yapılan bütün masraflar için hesabını
                borçlandırmaya veya tahsile yetkili olduğunu kabul eder.
                <br />
                <br />
                11.5. ŞİRKET bu madde kapsamındaki ücret, gider ve bilumum
                masrafları KULLANICI’nın hesabından ihbara gerek olmaksızın
                mahsup etme veya hesabını borçlandırmaya yetkilidir.
              </p>

              <br />
              <h4>Gizlilik</h4>
              <p>
                12.1. KULLANICI, ŞİRKET’in ürünlerini ve hizmetlerini Sözleşmede
                yazılan amaçlar dışında kullanamaz.
                <br />
                <br />
                12.2. KULLANICI, Platform ve diğer ürünler ile hizmetlerin
                ticari sır niteliğinde olduğunu ve bunların profesyonel emek,
                zaman ve para harcanarak geliştirilmiş olduklarını bildiğini
                beyan eder. KULLANICI, ŞİRKET'in fikri mülkiyetinde bulunan bu
                ticari sırları yayınlamayacak, dağıtmayacak veya böyle bir
                bilgiyi üçüncü kişilere sağlamayacaktır. KULLANICI, hiçbir
                şekilde Platform ve diğer ürün ve hizmetlerini kopya etmeyecek,
                ayrıştırmayacak bu amaçlara hizmet edecek çalışmalar
                yapmayacaktır.
                <br />
                <br />
                12.3. KULLANICI, yasal olarak zorunlu olan ya da yetkili idari
                merciler veya yargı makamları tarafından talep edildiği haller
                dışında; işbu Sözleşme çerçevesinde gerçekleştirilecek olan
                işlemler ile ilgili olarak ŞİRKET’in işleri, diğer kullanıcılar,
                iç işleyişi ve diğer her türlü faaliyetleri konusunda her ne
                şekilde olursa olsun öğreneceği her türlü bilgi ve belgeleri
                gizli tutacak ve söz konusu bilgi ve belgelere ilişkin olarak
                kanunen görevli ve yetkili personel dışında hiçbir kimseye
                herhangi bir açıklamada bulunmayacaktır. Aksi halde, ŞİRKET’in
                bu nedenle uğrayacağı doğrudan veya dolaylı zararları tazminle
                yükümlüdür.
              </p>

              <br />
              <h4>Kişisel Verilen Korunması</h4>
              <p>
                13.1. ŞİRKET, elde edeceği KULLANICI’ya ait kişisel verileri,
                kişisel verilerin işleme amacına ve hukuka aykırı şekilde
                işlemeyeceğini, yasal istisnalar dışında KULLANICI’nın rızası
                olmaksızın üçüncü kişiler ile paylaşmayacağını, kişisel
                verilerin hukuka aykırı işlenmesi ile erişilmesinin engellenmesi
                amacıyla gerekli idari ve teknik önlemleri alacağını ve bu
                verileri güvenli ortamda saklayacağını kabul ve taahhüt eder.
              </p>

              <br />
              <h4>Bildirimler</h4>
              <p>
                14.1. TARAFLAR, işbu Sözleşmede yer verilen adreslerinin yasal
                tebligat adresleri olduğunu ve belirtilen telefon ve/veya faks
                numaraları ile elektronik posta adreslerinin hâlen kullanımda
                olan geçerli ve güncel numara ve adresler olduğunu; söz konusu
                adres veya numaralarda meydana gelecek bir değişikliği yazılı
                olarak diğer tarafa bildirmeyi ve değişiklik diğer tarafa
                bildirilmediği takdirde burada belirtilen adres, telefon/faks
                numaraları veya elektronik posta adreslerine yapılacak
                tebligatların hukuken geçerli bir tebligatın tüm hüküm ve
                sonuçlarını doğuracağını kabul ve taahhüt ederler.
                <br />
                <br />
                14.2. İşbu Sözleşmenin diğer bir maddesine konu olmayan ihbar,
                tebligat ve diğer bildirimler TARAFLAR’ın iş bu Sözleşmede
                belirtilen adreslerinden herhangi birine iadeli taahhütlü
                mektupla veya kurye ile elden teslim yoluyla veya KULLANICI
                tarafından bildirilen elektronik posta adresine elektronik posta
                gönderilmesi suretiyle yapılacaktır.
                <br />
                <br />
                14.3. Bu madde hükmü, Kullanım Sözleşmesi’nin herhangi bir
                şekilde sona ermesinden sonra da uygulanmaya devam eder. Bu
                hükme aykırılık nedeniyle ortaya çıkacak zarar ve kayıplardan ya
                da maddi ve manevi tazminattan, aykırılığı gerçekleştirilen
                TARAF sorumludur.
              </p>

              <br />
              <h4>Sözleşmenin Tadili</h4>
              <p>
                15.1. Sözleşme hükümleri, değişikliklerin KULLANICI’ya
                tebliğinden itibaren 2 iş günü sonra yürürlüğe girmek ve
                KULLANICI’nın bu nedenle Sözleşmeyi feshetme hakkı saklı kalmak
                kaydıyla; ŞİRKET tarafından tek taraflı olarak değiştirilebilir.
                KULLANICI tarafından bildirimin alındığı tarihten itibaren 2 iş
                günü içerisinde yazılı olarak itiraz edilmediği takdirde;
                değişiklik TARAFLAR arasındaki işlemlerde geçerli olur.
                KULLANICI, sözleşmede yapılacak değişikliklerin elektronik
                ortamda kendisine bildirilebileceğini ve değişikliğin kendisi
                tarafından elektronik ortam üzerinden kabul edilmesi ya da
                bildirimi takiben 2 işgünü içerisinde işbu Sözleşmeyi
                feshetmemesi ve işlemlere devam etmesi ile ortaya koyacağı zımni
                kabul ile yürürlüğe gireceğini kabul ve beyan eder.
                <br />
                <br />
                15.2. KULLANICI, ŞİRKET tarafından yapılan değişikliklere itiraz
                ettiği takdirde işbu Sözleşme sona erer. Bu durumda,
                KULLANICI’nın nakdi kendi adına açılmış bir banka hesabına
                kripto varlıkları ise beyan edeceği cüzdan adresine transfer
                edilir.
              </p>

              <br />
              <h4>Sözleşmenin Feshi</h4>
              <p>
                16.1. Sözleşme süresizdir.
                <br />
                <br />
                16.2. TARAFLAR'dan her biri, 3 (üç) işgünü önceden (Noter
                vasıtasıyla veya iadeli taahhütlü mektupla) ihbarda bulunmak
                kaydıyla; işbu Sözleşmeyi tek taraflı olarak feshetmeye
                yetkilidir. KULLANICI’nın ŞİRKET’in kayıtlı telefonları
                üzerinden ya da hesap açılışı sırasında beyan etmiş olduğu ve
                sürekli iş ilişkisi boyunca kullanılan elektronik posta ile
                sözleşmenin feshi veya hesabın kapatılmasına ilişkin yapacağı
                talepler üzerine işlem tesisine ŞİRKET yetkilidir. Bu durumda
                feshin noter marifeti ya da iadeli taahhütlü mektupla yapılması
                gerekmez.
                <br />
                <br />
                16.3. Sözleşmenin TARAFLAR’dan herhangi birince feshedilmesi
                durumunda ŞİRKET’in işbu Sözleşmeden doğmuş ve doğacak her nevi
                alacağı, KULLANICI tarafından işbu sözleşme hükümleri
                çerçevesinde ödenecektir.
                <br />
                <br />
                16.4. Sözleşmenin feshi halinde fesih tarihinden itibaren mümkün
                olan en kısa süre içinde KULLANICI’nın nakdi kendi adına açılmış
                bir banka hesabına kripto varlıkları ise beyan edeceği cüzdan
                adresine transfer edilir.
                <br />
                <br />
                16.5. ŞİRKET, aşağıdaki haller dahil sözleşmeyi tek taraflı
                olarak feshetme yetkisine sahiptir:
                <br />
                <br />
                a.Ödemede temerrüt hali: KULLANICI’nın, Sözleşme çerçevesinde
                girmiş olduğu herhangi bir işlemle ilgili olarak ödemekle
                yükümlü olduğu borçlarından herhangi birisini ifada temerrüde
                düşmesi ve bu temerrüt halinin, kendisine gönderilen ödeme
                ihtarında yer alan süre içerisinde ortadan kaldırılmaması,
                <br />
                <br />
                b. Sözleşmeye aykırılık: KULLANICI’nın, bu Sözleşme’de ayrıca
                belirlenmiş ve özel olarak düzenlenmiş durumlar saklı kalmak
                üzere, Sözleşme ile üstlenmiş olduğu yükümlülüklere ve beyan
                etmiş olduğu taahhütlerine aykırı davranması halinde, kendisine
                ihtar edilmesi ve ihtarın ulaşmasından itibaren 2 (iki) iş günü
                içerisinde aykırılığın giderilmemesi,
                <br />
                <br />
                c. ŞİRKET’in yasal hakları saklı kalmak kaydıyla, KULLANICI’nın
                Sözleşme çerçevesinde vermiş olduğu bilgi ve yapmış olduğu
                beyanlardan herhangi birisinin gerçeğe aykırı olduğunun tespit
                edilmesi ve bu aykırılığın Sözleşme’nin devamına imkân
                vermeyecek bir aykırılık olması,
                <br />
                <br />
                d. İflas veya aciz hali: KULLANICI aleyhinde iflas başvurusunda
                bulunulması, kayyum idaresine girmesi, konkordato ilanı, iflas
                kararı verilmesi gibi KULLANICI’nın aciz haline girmesini
                doğuran durumlar ile KULLANICI’nın aciz halinde olduğuna veya
                yakın bir gelecekte olabileceğine ilişkin başkaca ciddi
                emarelerin ve duyumların olması,
                <br />
                <br />
                e. KULLANICI hesabında başkası adına işlem
                gerçekleştirildiğinin, portföy yöneticiliği veya yatırım
                danışmanlığı faaliyetlerinde bulunulduğunun, suçtan elde edilen
                gelirlerinin aklanması ve terörizmin finansmanının önlenmesi
                düzenlemelerine aykırı davranıldığının ve bu nitelikteki
                eylemlerde bulunulduğunun, Platformun etkin işlemlerini
                engelleyecek veya sekteye uğratacak işlemlerde bulunulduğunun,
                diğer kullanıcıların emirlerinin ve işlem gerçekleştirmelerinin
                engellenmesi veya buna neden olabilecek fiillerde
                bulunulduğunun; ŞİRKET çalışanlarına karşı kaba, ahlaksızlık
                olarak nitelendirilebilecek davranış ve söylemlerde
                bulunulduğunun, bu sayılanlarla sınırlı olmamak üzere işbu
                Sözleşme ile yasaklanan iş, işlem ve eylemlerde bulunulduğunun
                tespiti veya bulunulduğu yönünde makul şüphenin varlığı,
              </p>

              <br />
              <h4>Kayıtların Delil Niteliği</h4>
              <p>
                17.1. Bu sözleşmeden ve sözleşme çerçevesinde gerçekleştirilen
                işlemlerden doğabilecek anlaşmazlık ve uyuşmazlıklarda ŞİRKET
                defter, kayıt ve belgeleri Hukuk Muhakemeleri Kanunu’nun 193
                üncü maddesi anlamında muteber ve geçerli delil niteliğindedir.
                KULLANICI, ŞİRKET yetkilileri ve personeli ile yapacağı telefon
                konuşmalarının kaydedilebileceğini ve bu kayıtların “Kullanıcı
                mutabakatını içeren kayıt” niteliğinde olduğunu ve münhasır
                delil niteliği taşıyacağını kabul ve beyan eder.
              </p>

              <br />
              <h4>Hapis Hakkı</h4>
              <p>
                18.1. ŞİRKET, Sözleşmeden kaynaklanan doğmuş ve doğacak
                alacakları ile gerçekleşmiş ya da muhtemel her tür zararlarının
                teminatı olarak, borcun ya da zararın doğum tarihinden itibaren
                KULLANICI hesabında bulunan varlıklar üzerinde, borç ödeninceye
                ya da zarar nakit olarak karşılanıncaya kadar Medeni Kanun’un
                950 ve devamındaki maddeler çerçevesinde hapis hakkına sahiptir.
              </p>

              <br />
              <h4>Hukuki ve Cezai Sorumluluk</h4>
              <p>
                19.1. ŞİRKET, ŞİRKET ortakları, yönetim kurulu üyeleri,
                yöneticileri, müdürleri ve çalışanları ile Platformda yer alan
                bilgi, belge ve dokümanları hazırlayanlar hakkında, KULLANICI
                hatasından doğan veya doğacak olan her türlü zarar, ziyan
                ve/veya kayıplardan dolayı hiçbir şekilde hukuki ve cezai
                sorumluluğu ileri sürülemez.
                <br />
                <br />
                19.2. KULLANICI’ya kullanıcı kodu ve şifre ile gerçekleştirilen
                işlem ve eylemler nedeniyle ortaya çıkacak hukuki ve cezai
                sorumluluk tamamen ve sadece KULLANICI’ya aittir. Bu işlemlerde
                ŞİRKET’in hiçbir hukuki ya da cezai sorumluluğu yoktur.
                KULLANICI tarafından Platformda gerçekleştirilen işlemlerin
                hukuka uygun olduğu kabul edilir. KULLANICI’nın hukuka aykırı
                işlem yaptığının tespit edilmesi ya da bu konuda makul şüphenin
                varlığı halinde, ŞİRKET her türlü hukuki yola başvurma ve
                hesabın dondurulması, iptal edilmesi ya da sözleşmenin
                feshedilmesi gibi gerekli her türlü idari ve güvenlik
                tedbirlerini alabilir. Bu tedbirleri almamış olması vazgeçtiği
                anlamına gelmez.
                <br />
                <br />
                19.3. Kripto varlıkların kullanımıyla ilgili yasal sorumluluklar
                ile vergi mükellefiyetinden KULLANICI bizzat sorumludur.
                KULLANICI’nın kazanç ve kayıplarından oluşan vergisel
                mükellefiyetlerinden ŞİRKET sorumlu tutulamaz.
                <br />
                <br />
                19.4. Kripto varlıkların hangi amaçla alım / satıma konu
                edildikleri, nereye ve ne amaçla transfer edildiği, bir suçta
                veya suçun örtülmesi için kullanılıp kullanılmadığı konularının
                takibi teknik olarak mümkün olmayabilir. Kripto varlıkların
                hukuka ve bu Sözleşmeye aykırı olarak, kötü amaçlarda
                kullanılmasından doğan ya da doğacak her türlü hukuki ve cezai
                sorumluluk KULLANICI’ya aittir. Bu nitelikteki eylemler
                nedeniyle KULLANICI veya üçüncü kişilerin zarar ve kayıpları ile
                menfi ya da müspet zararlarından ŞİRKET sorumlu değildir.
                <br />
                <br />
                19.5. ŞİRKET, KULLANICI hesaplarında bulunan kripto varlıkların
                güvenliğinin temininde azami özeni gösterir. Platform nezdindeki
                kripto varlıklar herhangi bir siber saldırıya karşı, kısmen veya
                tamamen internet bağlantısı olmayan ortamlarda soğuk depolama
                yöntemiyle saklanır. ŞİRKET, kasıtlı davranışı dışında,
                dolandırıcılık, hırsızlık ve siber saldırı olaylarından ve bu
                olaylar nedeniyle oluşabilecek zarar ve ziyandan sorumlu
                değildir.
                <br />
                <br />
                19.6. ŞİRKET kontrolü dışındaki teknik arıza ve hatalardan
                kaynaklanabilecek KULLANICI zararlarından bu yöndeki kasıtlı
                davranışı dışında hiçbir şekilde sorumlu tutulamaz ve kendisine
                yönelik hak talebinde bulunulamaz. ŞİRKET teknik arıza veya hata
                durumunda sistemi düzeltmek ve doğru çalışmasını sağlamak için
                yapılan işlemleri iptal edebilir veya geri alabilir; iptal ve
                geri alma sırasında KULLANICI hesaplarını dondurabilir; herhangi
                haksız bir ödeme yapılmış olması halinde bu ödemenin iadesini
                isteyebilir; iade edilmemesi halinde hapis hakkını kullanabilir,
                bunlarla sınırlı olmaksızın uygun göreceği diğer her türlü
                tedbire ve her türlü hukuki yola başvurabilir.
              </p>

              <br />
              <h4>Mücbir Sebep</h4>
              <p>
                20.1. KULLANICI, ŞİRKET’in Türkiye’de ve/veya işlemlerin
                gerçekleştirildiği ülkelerde meydana gelebilecek ekonomik,
                sosyal veya politik karışıklıklar, ŞİRKET faaliyetlerinin önemli
                ölçüde etkileyebilecek bölgesel ve/veya küresel savaşlar,
                ulusal, bölgesel veya global ekonomik krizler ve dalgalanmalar,
                Türkiye’nin veya diğer ülkelerin kripto varlık ya da kambiyo
                rejimlerine, emtia ve kıymetli maden piyasalarına
                getirebilecekleri kısıtlamalar, doğal afetler ve olağandışı
                piyasa koşulları gibi nedenlerle veya Türkiye’de ve diğer
                ülkelerde yapılabilecek yasal düzenlemeler, kamu otoritelerinin
                tasarrufları ya da bunlardan farklı olarak önceden öngörülmesi
                ve objektif kriterler çerçevesinde üstesinden gelinmesi mümkün
                olmayan diğer olaylar ya da sebepler nedeniyle, işbu Sözleşmeden
                kaynaklanan yükümlülüklerini yerine getiremeyebileceğini,
                yükümlülüklerini bu gerekçelerle yerine getirememesi durumunda
                KULLANICI’ya karşı herhangi bir sorumluluğunun olmayacağını
                kabul ve beyan eder.
                <br />
                <br />
                20.2. Mücbir sebebin varlığı halinde ŞİRKET herhangi bir
                bildirimde bulunmasına gerek olmaksızın; KULLANICI’nın
                Platformda bulunan gerçekleşmemiş emirlerini iptal edebilir;
                Sözleşmede yer alan yükümlülüklerin yerine getirilmesini askıya
                alabilir veya değiştirebilir, kripto varlık bazından veya tüm
                varlıklar için Platformdaki işlem koşullarını değiştirebilir,
                emir kabulünü durdurabilir, nakit veya kripto varlık transfer ve
                ödemelerini reddedebilir ya da uygun göreceği diğer tedbirleri
                alabilir. KULLANICI bu madde kapsamında mücbir sebebe dayalı
                olarak tedbir alınması nedeniyle işlemlerin iptali ve emir
                kabulünün durdurulması başta olmak üzere ortaya çıkacak hiçbir
                zarar, kayıp, masraf ve gider nedeniyle ŞİRKET’e sorumluluk
                atfetmeyeceğini, tazminat veya her ne nam altında olursa olsun
                talepte bulunmayacağını kabul, beyan ve taahhüt eder.
              </p>

              <br />
              <h4>Muacceliyet</h4>
              <p>
                21.1. Aşağıda belirtilen hallerin varlığı halinde KULLANICI’nın
                Platformda gerçekleştirilen işlemler nedeniyle varsa oluşan
                borcu muaccel hale gelir ve KULLANICI başka bir ihbara gerek
                kalmaksızın kendiliğinden temerrüde düşmüş sayılır:
                <br />
                <br />
                a. Müşteri'nin komisyon, faiz ve sair her türlü borcu zamanında
                ödeyememesi veya işbu Sözleşmede belirtilen diğer
                yükümlülüklerini yerine getirememesi,
                <br />
                <br />
                b. Yetkili otoriteler, mahkemeler ve icra daireleri tarafından
                KULLANICI aleyhine hesap hareketlerini kısıtlayıcı nitelikte bir
                karar verilmesi,
                <br />
                <br />
                c. KULLANICI’nın iflas etmesi veya konkordatoya gitmesi,
                <br />
                <br />
                d.Sözleşmenin feshedilmesine neden olacak fiillerde bulunulması
              </p>

              <br />
              <h4>Bölünebilirlik</h4>
              <p>
                22.1. İşbu Sözleşme’nin herhangi bir hükmü, maddesi veya
                koşulunun herhangi bir zamanda ve herhangi bir sebeple tamamen
                veya kısmen geçersiz, yasadışı veya ifa edilemez olması veya
                sayılması halinde, bu durum, diğer hükümlerin, maddelerin veya
                koşulların geçerliliğini ve ifa kabiliyetini kesinlikle
                etkilemeyecek veya bozmayacak ve diğer hükümler, maddeler veya
                koşullar tam olarak yürürlükte kalacaktır. Bu durumda, TARAFLAR,
                kısmen veya tamamen geçersiz, yasadışı veya ifa edilemez olan
                veya sayılan hükümleri, TARAFLAR üzerinde aynı ekonomik ve
                hukuki etkilere sahip yeni, geçerli ve ifa edilebilir hükümlerle
                değiştirmek için iyi niyetle müzakerede bulunacaklardır.
              </p>

              <br />
              <h4>Uygulanacak Hukuk ve Uyuşmazlıkların Çözümü</h4>
              <p>
                23.1. Bu Sözleşme, Türkiye Cumhuriyeti kanunlarına tâbidir.
                <br />
                <br />
                23.2. Bu Sözleşmesinin uygulanması veya yorumlanmasından
                kaynaklanan uyuşmazlıkların çözümlenmesinde İstanbul Çağlayan
                Adliyesi Mahkemeleri ve İcra Daireleri yetkilidir.
                <br />
                <br />
                23.3. Bu Sözleşmede hüküm bulunmayan hallerde genel hükümler
                uygulanır.
              </p>
              <br />
              <h4>Yürürlük</h4>
              <p>
                24.1. KULLANICI'nın Platforma üye olmak amacıyla
                www.bitistanbul.com adresinde bulunan “Kullanıcı Sözleşmesi ve
                Şartlarını” elektronik ortamda onaylaması ile Sözleşme
                imzalanmış ve yürürlüğe girmiş sayılır. KULLANICI, internet
                üzerinden yapacağı onaylama ile Sözleşme’nin tamamını okuduğu,
                anladığını ve tüm maddeleri kabul ettiğini beyan ve taahhüt
                eder.
              </p>
              <br />
              <h4>HOTTO KRİPTO VARLIK ALIM SATIM PLATFORMU A.Ş.</h4>

              <h4>(Bitİstanbul)</h4>

              <h4>RİSK BİLDİRİMİ</h4>
              <p>
                Sayın Müşterimiz, Finans piyasalarında tecrübe sahibi
                profesyoneller tarafından kurulan ve yönetilen Hotto Kripto Varlık Alım Satım Platformu A.Ş. (Bitİstanbul) nezdinde, uluslararası en iyi
                uygulamalar ve güvenlik standartları kapsamında tamamen kendi
                yazılımcıları ile oluşturduğu kripto varlık alım satım
                platformunda (Platform) işlemlere başlamadan önce bazı risklerin
                sizlerle paylaşılmasının yatırım kararlarınız açısından önemli
                olduğu düşünülmektedir.
                <br />
                <br />
                Önemli gördüğümüz riskler aşağıda verilmekle birlikte sayılan
                hususların tüm riskleri içermeyebileceğini, burada sayılan
                risklerden başka risklerle de karşılaşma ihtimalinizin
                bulunduğunu hatırlatmak isteriz. Platformda alım – satım
                işlemlerine başlamadan önce bu metni dikkatle okumanız, muhtemel
                riskler konusunda bilgi sahibi olmanız ve özellikle anlamanız,
                gerekmesi halinde Bitİstanbul, avukatınız, yatırım danışmanınız
                ya da başka bir profesyonelden destek ve detaylı bilgi almanız
                uygun olacaktır.
                <br />
                <br />
                1 - Kripto varlıklar çok ciddi fiyat ve piyasa riski içerir.
                Fiyat değişiklikleri önemli seviyede, ani, önceden tahmin
                edilemeyecek şekilde ve öngörüde bulunmaya imkân sağlayacak
                herhangi bir belirti göstermeksizin gerçekleşebilir. Fiyatların
                beklendiğiniz şekilde gelişmemesi durumunda, tüm malvarlığınızın
                kaybı dahil ciddi mali kayıplarla karşı karşıya kalabilirsiniz.
                Fiyatların geçmişte göstermiş oldukları performans ve getiriler,
                gelecekteki fiyatlar ya da getirilerin güvenilir bir göstergesi
                olmayabilir.
                <br />
                <br />
                2 - Kripto varlık işlemleri 7 gün 24 saat esasına bağlı olarak
                gerçekleştirilir. Ayrıca uluslararası piyasalarda işlemlerin
                yoğunlaştığı saatler ile ülkemiz arasındaki saat farkları
                bulunmaktadır. Bu nedenle fiyatlarda görülen ani ve önemli
                dalgalanmalar mesai saatleri dışında, gece geç ve fiyat
                hareketlerini takip edemeyeceğiniz saatlerde gerçekleşebilir.
                <br />
                <br />
                3 - Kripto varlıklara ilişkin fiyat tahminlerinde klasik
                finansal araçlara özgü model ve analiz teknikleri doğru sonuçlar
                vermeyebilir. Bitİstanbul tarafından hazırlanan ve Platform ya
                da internet sitesinde yayımlanan veya sizlerle doğrudan
                paylaşılan bülten, analiz raporları ile benzeri dokümanlar
                hiçbir şekilde yatırım danışmanlığı, fiyat garantisi veya
                fiyatlara ilişkin kesin bir öngörü olarak kabul edilemez. Bu
                bültenlere dayalı olarak alacağınız yatırım kararları tamamen
                şahsınıza ait olup bu bülten, rapor ve benzeri diğer dokümanlara
                ya da paylaşımlara dayalı yatırım yapılması sonucunda karşı
                karşıya kalınacak herhangi bir zarar, ziyan, kar mahrumiyeti
                veya mal varlığı değer düşüklüğünden Bitİstanbul sorumlu
                tutulamaz.
                <br />
                <br />
                4 - Sosyal medyada kripto varlıklara ilişkin çok fazla yorum,
                analiz, değerlendirme ve hatta yönlendirme yer almaktadır. Bu
                paylaşımlara dayalı olarak alınacak yatırım kararları,
                paylaşımlar ve beklentileriniz çerçevesinde sonuç
                doğurmayabilir. Özellikle gerçek kişiler tarafından yapılan
                sosyal medya paylaşımlarına dayalı işlem gerçekleştirmemenizi
                öneririz. Bu paylaşımlara dayalı işlem gerçekleştirmeden önce
                mutlaka bir ikinci görüş almaya çalışın.
                <br />
                <br />
                5 - Platformda fiyatlar alıcı ve satıcı tarafından belirlenir.
                Bitİstanbul sadece alıcı ve satıcıyı bir araya getiren
                platformun işleticisidir. Bitİstanbul emirlerin kısmen veya
                tamamen gerçekleşeceğini hiçbir şekilde garanti etmez. Piyasada
                yeterli derinliğin olmadığı durumlarda emirleriniz
                gerçekleşmeyebilir.
                <br />
                <br />
                6 - Bitİstanbul Platformda listelemiş olduğu kripto varlıklara
                ilişkin hiçbir fiyat garantisi veya taahhüdü vermez.
                Bitİstanbul, Platformda listelenen kripto varlıkları kendi
                takdirine bağlı olarak önceden herhangi bir bildirimde
                bulunmaksızın listeden çıkarılabilir, işleme kapatabilir.
                Bitİstanbul Platformda listelediği kripto varlıkların her zaman
                piyasada işlem görmesi konusunda garanti ve taahhüt vermez.
                <br />
                <br />
                7 - Kripto varlıklar likidite riski içerir. Likidite sıkışıklığı
                satış yapmanızı veya pozisyonlarınızı istediğiniz zaman
                kapatmanızı zorlaştırabilir veya imkânsız hale getirebilir.
                Likidite sıkışıklığı sadece hızlı fiyat hareketlerinin olduğu
                zamanlarda değil herhangi bir zamanda ortaya çıkabilir.
                <br />
                <br />
                8 - Herhangi bir fiyat belirtmeden girmiş olduğunuz piyasa
                emirlerinde, yaşanan fiyat hareketleri nedeniyle emir iletirken
                ekranda görmüş olduğunuz fiyat ile emrin gerçekleştiği fiyat
                arasında fark olabilir. Bitİstanbul piyasaya ileteceğiniz piyasa
                emirlerinin, zarar – durdur veya kar – al gibi şarta bağlı limit
                emirlerin gerek miktar gerekse de fiyat olarak gerçekleşmesini
                garanti etmez. Bu şekilde şarta bağlı emirlerin kullanılmış
                olması durumunda dahi kayıplarınızı sınırlama imkânınız
                olmayabilir.
                <br />
                <br />
                9 - Yabancı para cinslerine bağlı kripto varlıklarda, diğer
                risklere ek olarak başa kur riski olmak üzere, ilgili para
                birimine veya kripto varlığa yönelik kısıtlamalar, engellemeler
                ya da kripto varlıklar genelinde ek veya yeni vergiler
                getirilmesi gibi riskler söz konusu olabilir.
                <br />
                <br />
                10 - Bitİstanbul hiçbir şekilde fon veya varlık yönetmez.
                Nakdinize ya da kripto varlıklarınıza erişim ve alım – satım
                sorumluluğu ile bu işlemler sonucunda oluşacak kar – zarar
                tamamen sizin inisiyatifinizde ve sorumluluğunuzdadır.
                <br />
                <br />
                11 - Bitİstanbul gerekli gördüğü hallerde, listelediği kripto
                varlıklara yönelik dilediği zaman ve süreyle piyasa
                performansını kısmen veya tamamen engelleyecek nitelikte tavan
                ya da taban fiyat uygulaması getirebilir. Buna ek olarak
                Bitİstanbul zorunlu limit emir uygulaması, piyasa veya limit
                emirlerin işlemlere kapatılması, maksimum emir değeri sınırı
                gibi bunlarla sınırlı olmamak üzere uygun göreceği her türlü
                tedbiri alma ve uygulama hakkına sahiptir.
                <br />
                <br />
                12 - Bitİstanbul Platformun uygun ve doğru bir şekilde çalışması
                için en iyi gayreti gösterir. Bitİstanbul Platformun her zaman
                çalışacağını garanti etmez. Bilgi sistemleri, yazılım ya teknik
                alt yapıda yaşanacak kesintiler ya da problemler nedeniyle
                Bitİstanbul tarafından sunulan hizmetler aksayabilir, donabilir,
                kesintiye uğrayabilir, emir iletimi, fiyat oluşumu, alım – satım
                işlemlerinin gerçekleşmesi, gerçekleşen emirlerin tasfiyesi gibi
                hizmetler sağlanamayabilir. İşlemlerin kesintiye uğraması
                durumunda oluşabilecek zararlar ile hesap açma, Platformun
                kullanımı ve bu kullanıma ilişkim sonuçların doğurduğu risklerin
                her türlü sorumluluğu tamamen müşteriye aittir. Bitİstanbul
                hiçbir şekilde sorumluluk kabul etmez.
                <br />
                <br />
                13 - Bitİstanbul, sunulacak hizmetlere ilişkin olarak blok –
                zincir ağları ve protokolleri üzerinde erişim ve yazma, saklama
                ve transfer hizmetleri, ödeme sağlayıcıları, teknoloji hizmet
                sağlayıcıları, güvenlik hizmet sağlayıcıları, cüzdan altyapı
                sağlayıcıları, varlık mutabakat hizmetleri, likidite
                sağlayıcıları, kripto varlık saklayıcıları, bankalar ve sigorta
                şirketleri gibi üçüncü taraflardan dolaylı veya doğrudan hizmet
                veya destek alınabilir. Bitİstanbul hiçbir şekilde bu
                şirketlerin ve bu şirketlerin personelleri ile bu hizmetlere
                müdahale eden kişilerin eylemleri ve nedeniyle oluşacak zarar,
                ziyan, tazminat, değer düşüklüğü, kar mahrumiyeti ya da her ne
                ad altında olursa olsun maddi kayıptan, ilgili kişi ya da
                kurumun seçiminden hiçbir şekilde sorumlu tutulamaz.
                <br />
                <br />
                14 - Platformda gerçekleşen tüm işlemlere ilişkin her türlü
                zarar, ziyan, değer kaybı, çalınma, bilgisayar korsanlığı, bilgi
                sistemlerine veya cüzdan altyapılarına dışarıdan veya içeriden
                sızma gibi nedenlerle ortaya çıkabilecek her türlü kayıp ve bu
                kayba ilişkin bütün riskler müşteriye aittir.
                <br />
                <br />
                15 - Bitİstanbul Platforma, bu Platformda gerçekleştirilen
                işlemlerle varlıklarına ilişkin tüm güvenlik risklerini ortadan
                kaldıramaz. Platforma bağlantı sırasında kullanılan kullanıcı
                adı ve şifrenizin güvenliği tarafınıza aittir. Hesabınıza tahsis
                edilen kullanıcı adı ve şifre ile gerçekleştirilen tüm
                işlemlerden şahsınız sorumlu kabul edilecektir. Kripto
                varlıkların doğası gereği hesabınızda gerçekleştirilecek
                işlemler geri alınamaz; hileli veya yetkisiz işlemlerden
                kaynaklanan kayıpların telafisi mümkün olamayabilir. Bu şekilde
                oluşacak tüm zarar, ziyan, tazminat, değer düşüklüğü, kar
                mahrumiyeti veya her ne ad altında olursa olsun maddi kaybın
                sorumluluğu müşteriye aittir.
                <br />
                <br />
                16 - Kripto varlıkların teknolojik özellikleri göz önüne
                alındığında, aşağıdakiler dahil ancak bunlarla sınırlı olmamak
                üzere bir dizi ek içsel riski barındırır; bu riskler
                <br />
                <br />
                a. İlgili kripto varlığın dayandığı teknolojiler veya ekonomik
                sistemlerle ilgili olarak meydana gelen arızalar, kusurlar,
                bilgisayar korsanlığı, hatalar, protokol arızaları, ağ ve
                protokol sonlanmaları, onaylayıcıların ağlardan çekilmeleri ya
                da erişememeleri sonucu doğabilecek zararlar, kripto
                varlıkların, ağların, protokollerin ve işlemlerin genel olarak
                veya herhangi bir resmi kurum, merci veya devlet tarafından
                yasaklanması ya da engellenmesi ile öngörülemeyen diğer
                durumlar;
                <br />
                <br />
                b. İlgili kripto varlığa ilişkin işlemlerin ait olduğu blok
                zincire kazınmasının, blok zincir yoğunluğu, kesintisi ya da
                başkaca bir şekilde gecikmesi ya da hiç gerçekleştirilememesi,
                <br />
                <br />
                c. Blok zincirin doğası gereği kripto varlıklarda
                gerçekleştirilen hileli ya da kazara yapılan işlemlerin geri
                alınamaması, bu işlemlerden kaynaklanan kayıpların tazmin
                edilememesi,
                <br />
                <br />
                d. Kripto varlığın ve üzerinde işlem gördüğü blok zincirin
                eskimesi, teknolojik gelişmeler karşısında kullanılamaz,
                ekonomisinin anlamsız ya da gerçekleştirilemez hale gelmesi,
                <br />
                <br />
                e. Sayılanlarla sınırlı olmamak üzere, DDos atakları, siber
                saldırılar, e- dolandırıcılık, oltalama, sosyal mühendislik,
                bilgisayar korsanlığı, kötü amaçlı yazılımlar, içeriden veya
                dışarıdan kripto varlık cüzdanlarının şifrelerinin kırılması,
                ele geçirilmesi, hesap ya da cüzdanlara yetkisiz erişim, bu
                cüzdanlara bağlı özel anahtarların (private – key) kopyalanması
                ya da çalınması, Bitİstanbul çalışanlarının tedbirsizlik ve
                ihmali sonucunda meydana gelebilecek zararlar ile çift harcama,
                platform işlem ve transfer hataları, fikir birliğine dayalı veya
                diğer her türlü madencilik saldırıları, çatallar ve sahtekarlık
                eylemleri

                <br />
                <br />
                Bu risklerin gerçekleşmesinden dolayı müşteri hesabında
                oluşabilecek tüm zarar ve ziyan ile her türlü sorumluluk
                müşteriye aittir, bu risklerin gerçekleşmesinden dolayı
                Bitİstanbul, paydaşları ve yönetimi hiçbir şekilde sorumlu
                tutulamaz.
                <br />
                <br />
                17 - Kripto varlıklar, kripto varlık platformları ve kripto
                varlık hizmet sunucuları mevcut durum itibariyle lisanslama, iş
                ve işlem kuralları özelinde herhangi bir düzenlemeye tabi
                olmadığı gibi SPK, BDDK ya da başkaca bir kamu otoritesi gözetim
                ve denetimine tabi de değildir. Uyuşmazlıklar genel hukuk
                hükümlerine göre sonuçlandırılır. Kripto varlıklar ve
                platformları önemli ölçüde düzenleme riski içerir.

                <br />
                <br />
                18 - Kripto varlıkların alım satımına, platformlarda
                listelenmesinde, yurtiçinde veya yurtdışına transferine ya da
                Bitİstanbul tarafından sunulan hizmetlerden herhangi birine,
                kripto varlık platformları ile dolaylı veya doğrudan hizmet
                sağlayıcılarına yasak getirilmesi, işlemlerin durdurulması, alım
                – satım kazancı ve/veya değer artış kazançlarına vergi
                getirilmesi gibi nedenlerle kripto varlıklar değerini kısmen
                veya tamamen kaybedebilir, müşteri varlıklarına ulaşılamaz veya
                herhangi bir şekilde kullanılamaz, transfer veya alım – satıma
                konu edilemez hale gelebilir. Bitİstanbul bu durumlar nedeniyle
                müşteri nezdinde ortaya çıkacak zarar, ziyan, değer düşüklüğü,
                kar mahrumiyeti ya da her ne ad altında olursa olsun herhangi
                bir maddi kayıptan hiçbir şekilde sorumlu tutulamaz, tüm risk ve
                sorumluluk müşteriye aittir.
                <br />
                <br />
                19 - Bitistanbul, uhdesinde açılmış hesaplarda şüpheli bir
                faaliyette bulunulduğu, yasalara veya Kullanım Sözleşmesi'ne
                aykırı davranıldığı yönünde makul bir şüphe duyması halinde ya
                da diğer meşru nedenlerle belirli bir süreyle veya süresiz
                şekilde kısmen veya tamamen müşteri hesaplarını dondurabilir,
                hesapta bulunan nakit veya kripto varlıkların transferlerini
                kısmen veya tamamen engelleyebilir, hesabı kapatabilir, hesapta
                bulunan nakit ya da kripto varlıklara tedbir uygulayabilir,
                kullanımını kısıtlayabilir. Bu durumda müşteri hesabında işlem
                gerçekleştirilemeyebilir veya transfer yapılamayabilir.
                <br />
                <br />
                20 - Bitİstanbul nezdinde bulunan nakit ve kripto varlıklar,
                menkul kıymet yatırımcı tazmin fonu, mevduat sigorta fonu veya
                benzeri yasal veya özel, herhangi bir kamu, kamu teşekkülü, kamu
                iştiraki, düzenleyici otorite veya sektör destekli sigorta veya
                koruma programı kapsamında değildir. Bu varlıkların sorumluluğu
                müşteriye ait olup içeriden veya dışarıdan kripto varlık
                cüzdanlarının şifrelerinin kırılması ve ele geçirilmesi, hesap
                ve cüzdanlara yetkisiz erişim, bu cüzdanlara bağlı özel
                anahtarların (private – key) kopyalanması veya çalınması,
                Bitİstanbul çalışanlarının tedbirsizlik ve ihmali sonucunda
                meydana gelebilecek zararlar nedeniyle Bitİstanbul’a herhangi
                bir sorumluluk yüklenemez.
                <br />
                <br />
                21 - Kripto varlıklar ve ilgili ağları ve protokolleri
                kullanmanın ciddi riskler içerir. Kripto varlıklar ve ilgili tüm
                protokoller ve ağlarla ilgili tüm riskler hakkında bilgi edinmek
                müşterinin görevidir. Bitİstanbul, kripto varlıkların değeri
                veya herhangi bir ilgili ağ veya protokolün güvenliği veya
                performansı ile ilgili hiçbir beyanda bulunmaz veya garanti
                vermez.
                <br />
                <br />
                22 - Bitİstanbul, kripto varlıkların kullanılabilirliği veya
                erişilebilirliği ile ilgili hiçbir beyanda bulunmaz veya garanti
                vermez. Bitİstanbul, müşteri tarafından başlatılan transferlerin
                blok zincire zamanında işlenmesini sağlamak için makul çabayı
                gösterir. Bitİstanbul kendi kontrolü dışındaki faktörlere bağlı
                olan transferlerin işlenmesi ya da tamamlanması için gereken
                süreye ilişkin hiçbir beyanda bulunmaz veya garanti vermez.
                Zincir yoğunluğu veya benzeri nedenlerle yaşanacak gecikmeler
                nedeniyle ortaya çıkacak her türlü zarar, ziyan, tazminat, değer
                düşüklüğü, kar mahrumiyeti veya her ne ad altında olursa olsun
                maddi ve manevi kayıp nedeniyle Bitİstanbul’a herhangi bir
                sorumluluk yüklenemez.
              </p>
              <p>
                Müşteri tüm bu Risk Bildirimi’nde yer alan maddeleri ve
                bilgilendirmeleri okuduğunu, anladığını ve sözleşmenin ayrılmaz
                bir parçası olarak kabul ettiğini; kripto varlıkların bu Risk
                Bildirimi’nde sayılan risklerle sınırlı olmayabileceğini; işbu
                risklerin gerçekleşmesi durumunda tüm sorumluluğun kendisinde
                olduğunu bilerek ve kabul ederek Bitİstanbul hizmetlerinden
                yararlanmak istediğini ve bu sebeplerle oluşabilecek zarar,
                ziyan, değer düşüklüğü, tazminat, kar mahrumiyeti ya da her ne
                ad altında olursa olsun maddi ve manevi zararlardan
                Bitİstanbul’u hiçbir şekilde sorumlu tutmayacağını beyan, kabul
                ve taahhüt eder.
              </p>
            </div>

            <div class="modal-footer">
              <form
                class=""
                autocomplete="off"
                #nameControlForm="ngForm"
                id="nameControlForm"
              >
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <input
                      class="form-control"
                      type="text"
                      name="firstnameupper"
                      placeholder="{{ 'EnterYourFirstLetter' | translate }}*"
                      #firstnameupper="ngModel"
                      [(ngModel)]="registerDataForm.firstnameupper"
                      oninput="this.value = this.value.toLocaleUpperCase('TR')"
                      onkeyup="this.value=this.value.replace(/[^A-zÜüÇçŞşĞğıIİÖö]/g,'')"
                      pattern=""
                      autofocus
                      required
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <input
                      class="form-control"
                      type="text"
                      name="lastnameupper"
                      placeholder="{{
                        'EnterTheFirstLetterSurname' | translate
                      }}*"
                      #lastnameupper="ngModel"
                      [(ngModel)]="registerDataForm.lastnameupper"
                      oninput="this.value = this.value.toLocaleUpperCase('TR')"
                      onkeyup="this.value=this.value.replace(/[^A-zÜüÇçŞşĞğıIİÖö]/g,'')"
                      pattern=""
                      required
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <button
                      type="submit"
                      class="btn orange-btn"
                      [disabled]="!nameControlForm.form.valid"
                      (click)="ValuemessageStatus()"
                    >
                      {{ "ReadUnderstand" | translate }}
                    </button>
                  </div>
                  <div class="form-group col-md-2">
                    <button
                      type="button"
                      class="btn secondary-close"
                      (click)="cancelModal()"
                    >
                      {{ "Close" | translate }}
                    </button>
                  </div>
                  <div
                    class="text-danger-modal text-left"
                    [hidden]="!errorModal"
                  >
                    {{ errorModal }}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- registerHeader -->
      <table id="registerHeader">
        <tbody>
          <tr>
            <td style="text-align: center; height: 35px">
              <img
                src="assets/images/071.svg"
                alt=""
                class="text-center"
                style="width: 17px; margin-right: 65px"
              />
            </td>
          </tr>
          <tr>
            <td style="text-align: center">
              <img
                alt=""
                src="assets/images/070.svg"
                class="text-center"
                style="width: 24px; vertical-align: sub"
              />
              &nbsp;&nbsp;
              <small> {{ "Hesap Oluştur" | translate }}</small>
            </td>
          </tr>
          <tr>
            <td colspan="3" style="height: 25px">
              <hr />
            </td>
          </tr>
        </tbody>
      </table>

      <div class="no-gutters mt-130">
        <!-- Güvenle bitcoin -->
        <div class="col-6 col-md-6 line">
          <h3 class="fnt-reg h3 order-2 banner-bg-h3"> TÜRKİYE'NİN </h3>
          <h2 class="h2 fnt-reg t-blue order-3 banner-bg-h2">
            {{ "KRİPTO VARLIK ALIM SATIM PLATFORMU" | translate }}
          </h2>
          <h1
            class="fnt-bld h1 t-white mb-2x order-4 margin"
            style="font-size: 58px; line-height: 1.1"
          >
          {{ "GÜVENLE BITCOIN" | translate }}
            <br />
            {{ "ALIN SATIN!" | translate }}
          </h1>
          <div class="row">
            <div class="col-md-6">
              <ul>
                <li>{{ "Güçlü platform altyapısı" | translate }} &nbsp;</li>
              </ul>
            </div>
            <div class="col-md-6">
              <ul>
                <li>Gelişmiş analiz araçları</li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <ul>
                <li>{{ "Gerçek zamanlı grafikler" | translate }}</li>
              </ul>
            </div>
            <div class="col-md-6">
              <ul>
                <li>Güvenli, kesintisiz erişim</li>
              </ul>
            </div>
          </div>
          <div class="row" style="height: 40px">
            <div class="col-md-4"></div>
          </div>
          <br />

          <div style="height: 60px" class="row-style t-white">
            <b
              >&nbsp;{{
                "Profesyoneller için çok hızlı bir alım satım deneyimi sunuyoruz."
                  | translate
              }}</b
            >
          </div>
        </div>

        <!-- onSubmit Form -->
        <div class="col-6 col-md-5 style-col-md-5">
          <br />
          <form
            class=""
            autocomplete="off"
            #registerForm="ngForm"
            (ngSubmit)="onSubmit()"
            id="RegisterForm"
          >
            <!-- firstname -->
            <div class="form-group">
              <input
                type="text"
                name="firstname"
                placeholder="{{ 'FirstName' | translate }}*"
                class="form-control"
                [class.is-invalid]="firstname.invalid && firstname.touched"
                #firstname="ngModel"
                [(ngModel)]="registerDataForm.firstname"
                pattern=""
                oninput="this.value = this.value.toLocaleUpperCase('TR')"
                required
              />
              <span
                *ngIf="
                  firstname.errors && firstname.invalid && firstname.touched
                "
              >
                <small
                  class="text-danger-input"
                  *ngIf="firstname.errors.required"
                >
                  {{ "EnterYourName" | translate }}</small
                >
              </span>
            </div>

            <!-- lastname -->
            <div class="form-group">
              <input
                type="text"
                name="lastname"
                placeholder="{{ 'LastName' | translate }}*"
                class="form-control"
                [class.is-invalid]="lastname.invalid && lastname.touched"
                #lastname="ngModel"
                [(ngModel)]="registerDataForm.lastname"
                pattern=""
                oninput="this.value = this.value.toLocaleUpperCase('TR')"
                ng-repeat="personel in personeller | filter:searchKey || searchKey.toLocaleUpperCase('tr')"
                required
              />
              <span
                *ngIf="lastname.errors && lastname.invalid && lastname.touched"
              >
                <small
                  class="text-danger-input"
                  *ngIf="lastname.errors.required"
                  >{{ "EnterYourLastName" | translate }}
                </small>
              </span>
            </div>

            <!-- Kimlik Bilgisi -->
            <div class="form-group">
              <select
                aria-label="Kimlik Bilgisi"
                class="form-control"
                name="identity_type"
                #identity_type="ngModel"
                [(ngModel)]="registerDataForm.identity_type"
                (change)="onChange($event)"
                [class.is-invalid]="
                  identity_type.invalid && identity_type.touched
                "
                required
              >
                <option [ngValue]="null" disabled selected>
                  {{ "SelectIdentityInformation" | translate }}*
                </option>
                <option *ngFor="let emp of identityData" [value]="emp.id">
                  {{ emp.name }}
                </option>
              </select>
              <span
                *ngIf="
                  identity_type.errors &&
                  identity_type.invalid &&
                  identity_type.touched
                "
              >
                <small
                  class="text-danger-input"
                  *ngIf="identity_type.errors.required"
                  >{{ "SelectIdentityInformation" | translate }}</small
                >
              </span>
            </div>

            <!-- Kimlik Bilgisi input -->
            <div class="form-group" *ngIf="registerDataForm.identity_type">
              <input
                type="text"
                name="tc"
                class="form-control"
                placeholder="{{ identityDataPlaceholder }} {{
                  'enter' | translate
                }} *"
                [class.is-invalid]="tc.invalid && tc.touched"
                #tc="ngModel"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                [(ngModel)]="registerDataForm.tc"
                maxlength="11"
                required
              />
              <span *ngIf="tc.errors && tc.invalid && tc.touched">
                <small class="text-danger-input" *ngIf="tc.errors.required">
                  {{ identityDataPlaceholder }} {{ "enter" | translate }}</small
                >
              </span>
            </div>

            <!-- Doğum Tarihi -->
            <div class="form-group">
              <input
                placeholder="Doğum Tarihiniz*"
                class="form-control"
                type="text"
                onfocus="(this.type='date')"
                onblur="(this.type='date')"
                id="date"
                name="datebirth"
                [class.is-invalid]="datebirth.invalid && datebirth.touched"
                #datebirth="ngModel"
                [(ngModel)]="registerDataForm.datebirth"
              />
              <span
                *ngIf="
                  datebirth.errors && datebirth.invalid && datebirth.touched
                "
              >
                <small
                  class="text-danger-input"
                  *ngIf="datebirth.errors.required"
                  >{{ "EnterDateofBirth" | translate }}</small
                >
              </span>
            </div>

            <!-- phone -->
            <div class="form-group">
              <input
                type="tel"
                name="phone"
                id="phone"
                placeholder="Telefon Numaranız*"
                class="form-control"
                [class.is-invalid]="phone.invalid && phone.touched"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                #phone="ngModel"
                [(ngModel)]="registerDataForm.phone"
                maxlength="10"
                required
              />
              <span *ngIf="phone.errors && phone.invalid && phone.touched">
                <small class="text-danger-input" *ngIf="phone.errors.required"
                  >{{ "EnterTelephoneNumber" | translate }}
                </small>
              </span>
            </div>

            <!-- email -->
            <div class="form-group">
              <input
                type="email"
                name="email"
                class="form-control"
                placeholder="{{ 'EmailID' | translate }}*"
                [class.is-invalid]="email.invalid && email.touched"
                #email="ngModel"
                [(ngModel)]="registerDataForm.email"
                [email]="registerDataForm.email !== ''"
                autocomplete="off"
                required
              />
              <span *ngIf="email.errors && email.invalid && email.touched">
                <small
                  class="text-danger-input"
                  *ngIf="email.errors?.required && email.touched"
                  >{{ "EnterEmail" | translate }}</small
                >
                <small
                  class="text-danger-input"
                  *ngIf="email.errors?.email && email.touched"
                >
                  {{ "EnterValidEmail" | translate }}</small
                >
              </span>
            </div>

            <!-- password -->
            <div class="form-group">
              <input
                type="password"
                name="password"
                id="password"
                class="form-control"
                [type]="hidepassword ? 'password' : 'text'"
                placeholder="{{ 'Password' | translate }}*"
                [class.is-invalid]="password.invalid && password.touched"
                [(ngModel)]="registerDataForm.password"
                #password="ngModel"
                pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$"
                autocomplete="off"
                required
              />
              <span class="p-viewer">
                <i
                  class="material-icons"
                  matSuffix
                  (click)="hidepassword = !hidepassword"
                  >{{ hidepassword ? "visibility_off" : "visibility" }}</i
                >
              </span>
              <span
                *ngIf="password.errors && password.invalid && password.touched"
              >
                <small
                  class="text-danger-input"
                  *ngIf="password.errors.required"
                  >{{ "EnterPassword" | translate }}</small
                >
                <small class="text-danger-input" *ngIf="password.errors.pattern"
                  >{{ "validpassword" | translate }}.</small
                >
              </span>
            </div>

            <!-- password_confirmation -->
            <div class="form-group">
              <input
                type="password"
                name="password_confirmation"
                id="password_confirmation"
                [type]="hidepassword_confirmation ? 'password' : 'text'"
                placeholder="{{ 'Confirmpassword' | translate }}*"
                class="form-control"
                [(ngModel)]="registerDataForm.password_confirmation"
                #password_confirmation="ngModel"
                required
              />
              <span class="p-viewer">
                <i
                  class="material-icons"
                  matSuffix
                  (click)="
                    hidepassword_confirmation = !hidepassword_confirmation
                  "
                  >{{
                    hidepassword_confirmation ? "visibility_off" : "visibility"
                  }}</i
                >
              </span>
              <span
                *ngIf="
                  password_confirmation.errors &&
                  password_confirmation.invalid &&
                  password_confirmation.touched
                "
              >
                <small
                  class="text-danger-input"
                  *ngIf="password_confirmation.errors.required"
                  >{{ "EnterPasswordVerify" | translate }}</small
                >
                <small
                  class="text-danger-input"
                  *ngIf="password_confirmation.errors.pattern"
                >
                  {{ "samepassword" | translate }}</small
                >
              </span>
            </div>

            <!-- Bilgilerimi onaylıyorum -->
            <div class="form-group" class="text-right">
              <label class="form-check-label" for="ileti">
                <u style="text-decoration: none">
                  {{ "ConfirmMyInformation" | translate }}
                </u>
              </label>
              &nbsp;
              <input
                aria-label="confirmInformation"
                #confirmInformation="ngModel"
                [(ngModel)]="registerDataForm.confirmInformation"
                type="checkbox"
                name="confirmInformation"
                id="confirmInformation"
                class="form-check-input"
                (ngModelChange)="
                  registerDataForm.confirmInformation = $event ? 1 : 0
                "
              />
            </div>

            <!-- kvkk -->
            <div class="form-group" class="text-right">
              <label class="form-check-label" for="kvk">
                <a
                  class="t-white decoration"
                  routerLink="/kvkk"
                  target="_blank"
                >
                  <u> {{ "KVKKText" | translate }}</u></a
                >
              </label>
              &nbsp;
              <input
                aria-label="kvkk"
                #kvkk="ngModel"
                [(ngModel)]="registerDataForm.kvkk"
                type="checkbox"
                name="kvkk"
                id="kvkk"
                class="form-check-input"
                (ngModelChange)="registerDataForm.kvkk = $event ? 1 : 0"
              />
            </div>

            <!-- Kullanıcı sözleşme ve bilgilendirme -->
            <div class="form-group" class="text-right">
              <label class="form-check-label" for="Useragreement">
                <a
                  class="t-white decoration"
                  data-toggle="modal"
                  data-target=".bd-example-modal-lg"
                >
                  <u> {{ "UserAgreementAndInformation" | translate }}</u></a
                >
              </label>
              &nbsp;

              <input
                #messageStatus="ngModel"
                [(ngModel)]="registerDataForm.messageStatus"
                type="checkbox"
                name="messageStatus"
                aria-label="messageStatus"
                id="messageStatus"
                class="form-check-inputModal"
                (ngModelChange)="
                  registerDataForm.messageStatus = $event ? 1 : 0
                "
                data-toggle="modal"
                data-target=".bd-example-modal-lg"
                onclick="return false;"
              />
            </div>

            <!-- Elektronik ticari ileti -->
            <div class="form-group" class="text-right">
              <label class="form-check-label" for="ileti">
                <u style="text-decoration: none">
                  {{ "ElectronicCommercialMessage" | translate }}</u
                >
              </label>
              &nbsp;
              <input
                #commercialMessage="ngModel"
                [(ngModel)]="registerDataForm.commercialMessage"
                type="checkbox"
                name="commercialMessage"
                aria-label="commercialMessage"
                id="commercialMessage"
                class="form-check-input"
                (ngModelChange)="
                  registerDataForm.commercialMessage = $event ? 1 : 0
                "
              />
            </div>

            <!-- buttons -->
            <div class="form-group">
              <div class="float-right text-right">
                <input
                  type="submit"
                  name=""
                  value="{{ 'membership' | translate }}"
                  *ngIf="!isDisabled"
                  [disabled]="!registerForm.form.valid"
                  class="btn orange-btn mt-10"
                />
                <input
                  type="submit"
                  name=""
                  value="{{ 'Pleasewait' | translate }}..."
                  class="btn orange-btn mt-10"
                  disabled
                  *ngIf="isDisabled"
                />
              </div>
            </div>

            <!-- Kayıt Ol -->
            <div class="form-group">
              <div class="float-right text-right">
                <h6 class="t-white">
                  {{ "Alreadyhaveaccount" | translate }}
                  <a routerLink="/giris" class="t-blue">{{
                    "Signin" | translate
                  }}</a>
                </h6>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

<div class="backdrop"></div>
<div class="alert-box">
  <div class="modal-header">
    <h5 class="modal-title text-center">
      <i
        class="fa fa-warning tex-center"
        style="font-size: 48px; color: red"
      ></i>
    </h5>
  </div>

  <div class="modal-body tex-center">
    <p>{{ error }}</p>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn orange-btn-lgn" (click)="onCloseClick()">
      Kapat
    </button>
  </div>
</div>

<!-- Kişisel Verilerin Korunması Pages-->

<app-header></app-header>
<article class="innerpagearticle">

  <section class="innerbannerpage">
    <div class="container">
      
        <ng-template #addBankTemplate>
          <div class="modal-dialog modal-xl" id="printable">
            <div class="modal-body">
              <div class="container container-width">
                <table id="KvkkHeader-modal">
                  <tbody>
                    <tr>
                      <td class="text-center">
                        <img
                          src="assets/images/071.svg"
                          alt=""
                          style="
                            width: 16px;
                            margin-right: 405px;
                            position: relative !important;
                          "
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center">
                        <img
                          src="assets/images/070.svg"
                          alt=""
                          style="width: 24px; vertical-align: sub"
                        />
                        &nbsp;&nbsp;
                        <small
                          >6698 Sayılı Kanun Uyarınca KVKK Bilgi Talep Formu</small
                        >
                      </td>
                    </tr>
                    <tr>
                      <td style="height: 25px">
                        <hr />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table id="content-table-modal">
                  <tr>
                    <td>
                      <span>1. Bilgi Talep Edilen İlgili Kişiye İlişkin Bilgiler</span
                      ><br />
                      Bilgiler eksiksiz doldurulmak zorundadır. Aksi takdirde
                      talepleriniz şirketimiz tarafından karşılanamayacaktır.
                      Bilgilerin yanlış ya da eksik olması durumunda
                      <b>bitistanbul.com</b> talebin cevaplanmadığından bahisle
                      herhangi bir sorumluluk kabul etmemektedir.
                    </td>
                  </tr>
                </table>
      
                <div class="row row-kvkk text-right">
                  <div class="col-md-3 t-black">Adınız Soyadınız</div>
                  <div class="col-md-9">
                    <input
                      type="text"
                      name="fullname"
                      #fullName="ngModel"
                      [(ngModel)]="form.fullName"
                      class="form-control fullname"
                      title="Adınız Soyadınız"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="row row-kvkk text-right">
                  <div class="col-md-3 t-black">Kimlik Numaranız</div>
                  <div class="col-md-9">
                    <input
                      type="text"
                      name="tc"
                      #tc="ngModel"
                      class="form-control tc"
                      [(ngModel)]="form.tc"
                      onKeyPress="if(this.value.length==11) return false;"
                      min="0"
                      placeholder=""
                      title="Kimlik Numaranız"
                    />
                  </div>
                </div>
                <div class="row row-kvkk text-right">
                  <div class="col-md-3 t-black">Uyruğunuz</div>
                  <div class="col-md-9">
                    <input
                      type="text"
                      name="nat"
                      #nat="ngModel"
                      class="form-control nat"
                      [(ngModel)]="form.nat"
                      placeholder=""
                      title="Uyruğunuz"
                    />
                  </div>
                </div>
                <div class="row row-kvkk text-right">
                  <div class="col-md-3 t-black">Adresiniz</div>
                  <div class="col-md-9">
                    <textarea
                      name="address"
                      #address="ngModel"
                      [(ngModel)]="form.address"
                      class="form-control address"
                      title="Adresiniz"
                    ></textarea>
                  </div>
                </div>
                <div class="row row-kvkk text-right">
                  <div class="col-md-3 t-black">Cep Telefonunuz</div>
                  <div class="col-md-9">
                    <input
                      type="text"
                      name="phone"
                      #phone="ngModel"
                      [(ngModel)]="form.phone"
                      (input)="validatePhoneNo($event.target)"
                      class="form-control phone"
                      onKeyPress="if(this.value.length==14) return false;"
                      title="Cep Telefonunuz"
                    />
                  </div>
                </div>
                <div class="row row-kvkk text-right">
                  <div class="col-md-3 t-black">E-Posta Adresiniz</div>
                  <div class="col-md-9">
                    <input
                      type="text"
                      name="mail"
                      #mail="ngModel"
                      [(ngModel)]="form.mail"
                      class="form-control mail"
                      title="E-Posta Adresiniz"
                    />
                  </div>
                </div>
                <div class="row row-kvkk text-right">
                  <div class="col-md-3 t-black">Şirketimizle Olan İlişkiniz</div>
                  <div class="col-md-9">
                    <input
                      type="text"
                      name="comprel"
                      #comprel="ngModel"
                      [(ngModel)]="form.comprel"
                      class="form-control comprel"
                      title="Şirketimizle Olan İlişkiniz"
                    />
                  </div>
                </div>
                <div class="row row-kvkk text-left">
                  <div class="col-md-3"></div>
                  <div class="col-md-8 t-black">
                    Şirketimizle Olan İlişkiniz Sona Erdi mi?
                    <input
                      type="radio"
                      name="comprel2"
                      value="1"
                      id="comprel21"
                      #comprel21="ngModel"
                      [(ngModel)]="form.comprel21"
                      class="comprel21"
                      style="margin: 10px"
                      name="JobState"
                      title="Şirketimizle Olan İlişkiniz Sona Erdi mi?"
                    />
                    <b for="male">Evet</b>
      
                    <input
                      type="radio"
                      value="0"
                      name="comprel2"
                      id="comprel20"
                      style="margin: 10px"
                      name="JobState"
                      #comprel20="ngModel"
                      [(ngModel)]="form.comprel20"
                      class="comprel20"
                      title="radio"
                    />
                    <b for="male">Hayır</b>
                  </div>
                </div>
                <div class="row row-kvkk text-left">
                  <div class="col-md-3 t-black">
                    Şirketimiz ile Ne Kadar Süredir Çalışmaktasınız/Çalıştınız?
                  </div>
                  <div class="col-md-9">
                    <input
                      type="text"
                      name="comprel3"
                      #comprel3="ngModel"
                      [(ngModel)]="form.comprel3"
                      class="form-control comprel3"
                      title="Şirketimizle Ne Süredir Çalışmaktasınız/Çalıştınız?"
                    />
                  </div>
                </div>
                <div class="row row-kvkk text-right">
                  <div class="col-md-3 t-black">Bilgi Talep Etme Amacınız</div>
                  <div class="col-md-9">
                  
                    <textarea
                    name="inforeq"
                    #inforeq="ngModel"
                    [(ngModel)]="form.inforeq"
                    class="form-control inforeq"
                    title="Bilgi Talep Etme Amacınız"
                  ></textarea>
                
                  </div>
                </div>
                <br />
                <table id="content-table-modal">
                  <tr>
                    <td>
                      <span>2. İlgilinin Talepleri</span><br />
                      Yukarıda izah olunan kanundan doğan haklarınıza ilişkin
                      taleplerinizi işbu form vasıtasıyla şirketimize iletebilirsiniz.
                      Söz konusu talepleriniz bitistanbul.com’a ulaştığı tarihten
                      itibaren en geç 30 (otuz) gün içerisinde cevaplanacaktır. Bilgi
                      talebinizin cevabı yukarıda verdiğiniz iletişim kanalları
                      kullanılarak yazılı veya elektronik ortamdan sizlere
                      iletilecektir.
                    </td>
                  </tr>
                </table>
                <br />
                <table id="content-table-modal">
                  <tr>
                    <td>
                      <span>3. İlgilinin Beyanı</span><br />
                      Yukarıda izah ettiğim taleplerim doğrultusunda başvurumun
                      değerlendirilmesini ve tarafıma cevap verilmesini rica
                      etmekteyim. Şirketinize başvurum esnasında vermiş olduğum
                      bilgilerin gerçek ve güncel olduğunu, şahsıma ait olduğunu
                      kabul, beyan ve taahhüt ederim. Şirketinizden talep ettiğim
                      bilgi için paylaşmış olduğum kişisel verilerimin ve/veya özel
                      nitelikli kişisel verilerimin bitistanbul.com tarafından
                      amacıyla bağlantılı olarak işlenmesine izin veriyorum.
                    </td>
                  </tr>
                </table>
                <br />
                <table>
                  <tr>
                    <td style="vertical-align: top !important">
                      <input
                        type="checkbox"
                        #opt1="ngModel"
                        [(ngModel)]="form.opt1"
                        class="opt1"
                        style="cursor: pointer; height: 15px; width: 20px"
                        title="checkbox"
                      />
                    </td>
                    <td>
                      <p style="color: #000 !important">
                        Başvurumun cevabını bizzat elden teslim almak istiyorum.
                        (Başvuruyu yapan ilgili kişi dışında kimseyle başvuruyla
                        ilgili bilgi paylaşımı yapılmamaktadır. Vekaleten teslim
                        alınacaksa noter tasdikli vekaletnamenin varlığı şarttır.)
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top !important">
                      <input
                        type="checkbox"
                        #opt2="ngModel"
                        [(ngModel)]="form.opt2"
                        class="opt2"
                        style="cursor: pointer; height: 15px; width: 20px"
                        title="checkbox"
                      />
                    </td>
                    <td style="padding: 5px 0">
                      <p style="color: #000 !important">
                        Başvurumun cevabının Başvuru Formunda belirtilen e-posta
                        adresime gönderilmesi istiyorum.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top !important">
                      <input
                        type="checkbox"
                        #opt3="ngModel"
                        [(ngModel)]="form.opt3"
                        class="opt3"
                        style="cursor: pointer; height: 15px; width: 20px"
                        title="checkbox"
                      />
                    </td>
                    <td style="padding: 5px 0">
                      <p style="color: #000 !important">
                        Başvurumun cevabının Başvuru Formunda belirtilen adresime
                        gönderilmesini istiyorum.
                      </p>
                    </td>
                  </tr>
                </table>
                <table class="application-form">
                  <tr>
                    <td>Başvuruyu Yapan İlgili Kişinin Adı Soyadı :</td>
                  </tr>
                  <tr>
                    <td>Başvuru Tarihi :<br /></td>
                  </tr>
                  <tr>
                    <td>İmza :</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <table style="width: 100%">
            <tr>
              <td>
                <div class="text-right" style="margin: 10px">
                  <button class="orange-btn-print" (click)="print()">
                    Talep formunu Yazdır
                  </button>
                </div>
              </td>
            </tr>
          </table>
        </ng-template>
       
      <div class="row">
        <table id="kvkk-header">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: 520px !important"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  alt=""
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                />
                &nbsp;&nbsp;
                <small
                  >Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metni</small
                >
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row row-style">
        <h4>
          Hotto Kripto Varlık Alım Satım Platformu A.Ş.
          <br />
          <br />
          www.bitistanbul.com
        </h4>
      </div>
      <div class="row row-style">
        <!-- <strong>Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metni</strong>
        <br /> -->
        <p>
          Hotto Kripto Varlık Alım Satım Platformu A.Ş. (Bitİstanbul) olarak kişisel verilerinizin
          güvenliği hususuna azami hassasiyet göstermekteyiz. Kişisel
          verilerinizin güvenliğinin anayasal bir hak olduğu bilinciyle bu
          konuya en üst düzeyde önem vererek, kişisel verilerinizin güvenliğini
          en ön planda tuttuğumuzu ve bu amaçla gerekli her türlü idari ve
          teknik önlemin alındığını belirtmek isteriz.
        </p>
        <p>
          Kimlik, iletişim, işlem, mali ve finansal bilgileriniz ile IP
          bilgilerinizi www.bitistanbul.com internet sitesi üzerinden doldurmuş
          olduğunuz formlar, onay ve/veya imzanızla tanzim edilen tüm
          sözleşmeler, bu sözleşmelerin ekinde yer alan formlar, internet
          sitemize yapmış olduğunuz bağlantılar, sitemizde yapmış olduğunuz
          işlemler, çağrı merkezimizle yapmış olduğunuz görüşmeler, mobil
          uygulamalarımız, sosyal medya ve diğer kamuya açık alanlar, kullanıcı
          ziyaretleri veya görüşmeleri, satış ekiplerine yapılan yazılı ya da
          elektronik başvurular, çağrı merkezi şirketleri, piyasa istihbaratı,
          SMSler üzerinden sözlü, yazılı, görüntülü, ses kaydı veya elektronik
          kanallar yoluyla temin edilmektedir.
        </p>
        <p>
          Kişisel verilerinizi Bitİstanbul tarafından size karşı olan yasal ve
          idari yükümlülüklerin yerine getirilmesi, sizlerle sözleşmelerin
          kurulması ve bu sözleşmelerden kaynaklanan yükümlülükleri yerine
          getirilmesi, platform üzerinde alım satım işlemlerinin
          gerçekleştirilmesi, gerçekleşen işlemlerin hesaplarınıza kaydedilmesi,
          hesaplar arasında transferi, benzer şekilde nakdin banka hesaplarına
          ya da banka hesaplarından platform nezdindeki hesaplarınıza transferi,
          gerçekleşen işlemlerin raporlanması, takibi ve gözetimi, iç kontrol ve
          hazine birimleri tarafından yürütülecek gözetim ve risk yönetim
          süreçlerinin temini, ürün ve hizmetlerimize ilişkin iletişim
          kurulabilmesi, sunulan hizmetlerin etkinliğinin artırılması, izninize
          bağlı olarak tarafınıza pazarlama faaliyetinde bulunulabilmesi, yeni
          ürün ve hizmetlerin tanıtımı ve size uygun olduğu düşünülen teklif ve
          hizmetlerin teklifi, istihbarat, mevcut veya yeni ürün çalışmaları ve
          potansiyel kullanıcı tespiti, dışarıdan hizmet aldığımız kişi ve
          kurumlarla olan iş ilişkisinin temini ile hukuki ve ticari
          güvenliğinin temini, iş stratejilerinin oluşturulması, koordinasyon,
          iş birliği ve verimliliğin sağlanması ve geliştirilmesi, mevcut ve
          ileride çıkabilecek hukuki uyuşmazlıkların çözümlenmesi, sizden
          gelecek talep ve soruların cevaplanması, Bitİstanbul internet sitesi,
          diğer elektronik sistemler ile fiziki ortamların güvenliğinin
          sağlanması, mevzuatta ya da tabi olduğumuz veya uyguladığımız kural ve
          politikalarda ortaya çıkacak değişikliklerin tarafınıza bildirilmesi,
          sözleşmeye ve kanuna aykırılıkların soruşturulması, tespiti, önlenmesi
          ve ilgili idari veya adli makamlara bildirilmesi gibi amaçlar
          kapsamında işlenmektedir. Kişisel verilerinizin açık izniniz
          olmaksızın, ticari amaçla, yurtiçinde veya yurtdışında yerleşik bir
          başka kişi ve/veya kuruluşla paylaşılması söz konusu değildir.
          <br />
          <br />
          Bitİstanbul yukarıda sayılan amaçlar çerçevesinde veri işleme
          faaliyetini esas dayanağı açık rızanızın varlığıdır. Ancak ilgili
          mevzuatta da ifade edildiği üzere, işleme faaliyetinin kanunlarda
          açıkça öngörülmüş olması, rıza alınmasının fiilen imkânsız olması,
          Bitİstanbul’un taraf olduğu sözleşmelerin kurulması veya ifasıyla
          doğrudan doğruya ilgili olması, Bitİstanbul’un hukuki
          yükümlülüklerinin yerine getirebilmesi için zorunlu olması, ilgili
          kişisel verinin bizzat tarafınızca alenileştirilmiş olması,
          Bitİstanbul’a ait bir hakkın tesisi, kullanılması veya korunması,
          anayasal güvence altında olan temel hak ve özgürlüklerine zarar
          vermemek kaydıyla, Bitİstanbul’un meşru menfaatleri için veri
          işlenmesinin zorunlu olması durumlarında rızanızın alınmasına gerek ve
          ihtiyaç olmaksızın gerçekleştirebilmektedir.
          <br />
          <br />
          Kişisel verileriniz, Bitİstanbul müşterisi olduğunuz sürece
          işlenecektir. Müşteri ilişkimizin sona ermesi sonrasında ise, hukuken
          başkaca bir süre öngörülmediği ve tarafınızca aksi talep edilmediği
          takdirde, genel zamanaşımı süresi olan 10 yıl boyunca verileriniz
          saklanmaya devam edilecektir. Bu sürenin dolması durumunda ilgili
          kişisel verileriniz silinecek ve imha edilecektir. Tarafınızla bir
          uyuşmazlığımızın ya da davanın varlığı durumunda ise ilgili uyuşmazlık
          ya da dava süresince ilgili kişisel verileriniz saklanacak ve
          gerekmesi halinde işlenecektir
        </p>
        <p>
          Kişisel verileriniz; Mali Suçlar Araştırma Kurulu başta olmak üzere
          tabi olduğumuz yasal mevzuat kapsamında aktarmakla yükümlü olduğumuz
          kolluk kuvvetleri, adli ve yargı mercileri, kamu otoriteleri ya da
          düzenleyici ve denetleyici kurumlar, vergi daireleri gibi idari ve
          resmi makamlara, faaliyetlerimizi yürütmek üzere dışarıdan hizmet
          alınan üçüncü taraflara, işbirliği yaptığımız, program ortağı kurum ve
          kuruluşlar ile güvenlik, çağrı merkezi, danışmanlık veya benzeri
          nitelikte hizmet almış olduğumuz yurtiçi ve yurtdışında yerleşik
          kuruluşlara, alt yüklenicilere ve işbirliği içerisinde olan üçüncü
          tarafların hukuki ve ticari güvenliği ve/veya elektronik ve fiziksel
          ortamların güvenliğinin sağlanması ile hukuki zorunluluklar nedeniyle,
          sadece bu amaçlarla işlenmek üzere, gerekli güvenlik önlemlerinin
          alınması şartı ve kaydıyla yasal sınırlamalar çerçevesinde
          aktarılmaktadır.
          <br />
          <br />
          Kişisel verileriniz, gerekli güvenlik önlemlerin alınması ve hukuki
          şartların sağlanması kaydıyla Türkiye içinde veya AB üye ülkeleri,
          Amerika ve İngiltere ya da diğer ülkelerde yerleşik depolama,
          arşivleme, bilişim teknolojileri desteği alınan sunucular, hosting
          şirketleri, program, bulut bilişim vb diğer elektronik ortamlara
          aktarabilir, işleyebilir ve saklayabiliriz. Yurtdışına yapılacak bu
          tür aktarımlarda rızanızın alınacağı tabidir.
        </p>
        <p>
          Bitİstanbul olarak muhtemel ihlal ve bu ihlal nedeniyle karşı karşıya
          kalınabilecek zarar risklerini önlemek amacıyla, risk bazlı bir
          yaklaşımla, alınan önlemlerin maliyet ve etkinlik kriterlerini de göz
          önüne alarak, personel ile gizlilik sözleşmesi imzalanması, yetki
          hiyerarşisinin kurgulanması, ilgili iç politika ve süreçlerin
          oluşturulması, şifreleme, virüs veya trojan yazılımları ile STP veya
          benzeri güvenli veri aktarım mekanizmalarının kullanılması, güvelik
          duvarı oluşturulması gibi ancak bunlarla sınırlı olmamak üzere her
          türlü idari ve teknik tedbir alınmıştır.
        </p>
        <p>
          Bitİstanbul’a başvurmak suretiyle kişisel verilerinizin işlenip
          işlenmediğini öğrenme, kişisel verileriniz işlenmişse bilgi talep
          etme, işlenme amacını ve amacına uygun kullanılıp kullanılmadığını
          öğrenme, üçüncü kişilere aktarım yapılıp yapılmadığı, eksik veya
          yanlış işlenmişse düzeltilmesini isteme, işlemeye ilişkin sebeplerin
          ortadan kalkması halinde kişisel verilerin silinmesini veya yok
          edilmesini isteme, kişisel verilerin üçüncü kişilere aktarılmış olması
          verilerin aktarıldığı kişilerden düzeltme veya verilerin silinmesini
          talep etme, kişisel verilerinizin analiz edilmesi nedeniyle aleyhinize
          bir sonucun ortaya çıkmasına itiraz etme, kanuna aykırı olarak
          işlenmesi sebebiyle zarara uğramanız halinde zararın giderilmesini
          talep etme hakkına sahipsiniz.
          <br />
          <br />
          <b>Bu hakların kullanılması için</b> aşağıda yer alan Hotto Kripto Varlık Alım Satım Platformu A.Ş. (Bitİstanbul) adresine mektup göndermeniz veya hesap
          açılışı sırasında bildirdiğiniz ve Bitİstanbul sisteminde kayıtlı
          elektronik posta adresinizi kullanmak suretiyle elektronik ortamda
          yapacağınız başvurunuz yeterli olacaktır.<br />
          Başvurulara ilişkin "Bilgi Talep Formunu"<button
            (click)="openModalWithClass(addBankTemplate)"
            class=""
            id="fromHere"
          >
            <u>buradan</u></button
          >temin edebilirsiniz.
          <br />
          <br />
          Bilgi alma hakkınızın kullanımı kural olarak ücretsizdir. Ancak
          cevabın 10 sayfayı aşması halinde aşan her sayfa başına 1 TL olmak
          üzere; ilgili bilgi ve dokümanların bir örneğinin verilmesini talep
          etmeniz durumunda da ilgili maliyet tarafınızdan talep
          edilebilecektir.
        </p>
      </div>

      <div class="row row-style">
        <strong>Yazılı başvurular için; </strong>
      </div>
      <div class="row row-style">
        <span style="font-weight: 700">Hotto Kripto Varlık Alım Satım Platformu A.Ş.</span>
      </div>
      <div class="row row-style">
        <p>
          Esentepe Mah. Büyükdere Cad. No: 102 Maya Akar Center 16. Kat Daire:
          60 <br />
          Şişli / İSTANBUL
        </p>
      </div>
      <div class="row row-style">
        <strong>Elektronik posta yoluyla yapılacak başvurular için; </strong>
      </div>
      <div class="row row-style">
        <a href="mailto:kisiselveri@bitistanbul.com"
          ><u id="unew"> kisiselveri&#64;bitistanbul.com</u>
        </a>
      </div>
      <div class="row row-style">
        <button
          (click)="openModalWithClass(addBankTemplate)"
          class="formButton"
        >
          Bilgi Talep Formu
        </button>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

<!-- Sıkça Sorulan Sorular Pages -->
<app-header></app-header>
<article class="innerpagearticle">
  <section class="innerbannerpage">
    <div class="container">
      <div class="row">
        <table id="SSSPageHeader">
          <tbody>
            <tr>
              <td style="height: 31px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: 155px"
                />
              </td>
            </tr>
            <tr>
              <td class="text-center">
                <img
                  src="assets/images/070.svg"
                  alt=""
                  style="width: 24px; vertical-align: sub"
                />
                &nbsp;&nbsp;
                <small>{{ "FrequentlyAskedQuestions" | translate }}</small>
              </td>
            </tr>
            <tr>
              <td style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="container" style="margin-top: 30px">
      <div class="row">
        <div class="container-collabsible">
          <div class="wrap-collabsible">
            <input
              id="collapsible2"
              class="toggle"
              type="checkbox"
              checked=""
            />
            <label for="collapsible2" class="lbl-toggle"
              >Bitistanbul'da Nasıl Hesap Açarım?</label
            >
            <div class="collapsible-content">
              <div class="content-inner">
                <p>
                  Bitistanbul’un hizmetlerine ulaşabilmek için ilk adım bir
                  <span>Bitistanbul hesabı</span>
                  oluşturmaktır.
                </p>
                <ul>
                  <li>
                    <a
                      href="https://bitistanbul.com"
                      target="_blank"
                      rel="noopener"
                      ><span>https://bitistanbul.com</span>
                    </a>
                    adresini ziyaret ettiğinize emin olduktan sonra site
                    üzerinde sağ üstte yer alan
                    <a
                      href="https://bitistanbul.com"
                      target="_blank"
                      rel="noopener"
                      ><span>"Hesap Oluştur"</span>
                    </a>
                    linkini tıklayın.
                  </li>
                </ul>
                <ul>
                  <li>
                    Gelen form sayfasında kullanıcı isminizi, emailinizi
                    girdikten sonra
                    <span>hesap güvenliğinizi sağlayacak</span> güçlü bir şifre
                    oluşturun.
                  </li>
                </ul>
                <ul>
                  <li>
                    Bu form sayfasında gelen
                    <span> "Kullanıcı Sözleşmesi ve Gizlilik sözleşmesi"</span>
                    bilgileri okuduğunuzdan emin olun ve bu bilgileri
                    okuduğunuza dair kutucuğu işaretleyin.<br />
                    Formun en altındaki
                    <span>"Hesap Oluştur"</span>
                    kutusunu tıklayın.
                  </li>
                </ul>
                <ul>
                  <li>
                    Bildirmiş olduğunuz email adresinize bir
                    <span>aktivasyon emaili</span> gelecektir. Gelen emaildeki
                    aktivasyonu onaylamanız durumunda hesabınız aktif hale
                    gelecek ve giriş sayfasına yönlendirileceksiniz.
                  </li>
                </ul>
                <p>
                  Bu aşamalarda bir sorun yaşamanız durumunda
                  <a href="mailto:destek@bitistanbul.com"
                    ><u><span>destek&#64;bitistanbul.com</span> </u></a
                  >
                  adresinden bize ulaşın.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="container-collabsible">
          <div class="wrap-collabsible">
            <input id="collapsible3" class="toggle" type="checkbox" />
            <label for="collapsible3" class="lbl-toggle"
              >Bitistanbul Kimdir?</label
            >
            <div class="collapsible-content">
              <div class="content-inner">
                <p>
                  <span>Bitistanbul</span> 2020 yılında  
                  sektör profesyonelleri tarafından kurulmuş olan
                  <span>Hotto Kripto Varlık Alım Satım Platformu A.Ş.</span> ’nin kripto para alım
                  satım markasıdır. <br />
                  Kripto para alanında yatırımcılarına hızlı ve güvenli hizmet
                  vermek üzere, müşteri memnuniyeti hedefli yurtiçi, yurtdışı
                  müşterilerine hizmet vermektedir.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="container-collabsible">
          <div class="wrap-collabsible">
            <input id="collapsible4" class="toggle" type="checkbox" />
            <label for="collapsible4" class="lbl-toggle"
              >Bitistanbul'a Nasıl Ulaşırım?</label
            >
            <div class="collapsible-content">
              <div class="content-inner">
                <p>
                  Hertürlü sorunuz için Bitistanbul destek hattına 7/24 (0850)
                  677 77 70 numaralı telefonundan ulaşabilirsiniz.<br /><br />
                  Bitistanbul adresi : Esentepe Mahallesi, Büyükdere Caddesi
                  Maya Akar Center B-Blok Kat:16/60 Şişli - İstanbul
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="container-collabsible">
          <div class="wrap-collabsible">
            <input id="collapsible5" class="toggle" type="checkbox" />
            <label for="collapsible5" class="lbl-toggle"
              >Bitistanbul’da Hangi Kripto Varlıklarda İşlem Yapabilirim?</label
            >
            <div class="collapsible-content">
              <div class="content-inner">
                <ul>
                  <li>BTCTRY</li>
                </ul>
                <!-- <ul>
                  <li>BTCUSDT</li>
                </ul> -->
                <ul>
                  <li>ETHTRY</li>
                </ul>
                <!-- <ul>
                  <li>ETHUSDT</li>
                </ul> -->
                <ul>
                  <li>USDTTRY</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="container-collabsible">
          <div class="wrap-collabsible">
            <input id="collapsible6" class="toggle" type="checkbox" />
            <label for="collapsible6" class="lbl-toggle"
              >Bitistanbul Hesabıma Nasıl Kripto Varlık Yatırırım?</label
            >
            <div class="collapsible-content">
              <div class="content-inner">
                <ul>
                  <li>
                    Bitistanbul hesabınıza Kriptopara yatırmak için öncelikle
                    hesabınızdaki
                    <span>'Cüzdan'</span> sekmesine geliniz.
                  </li>
                </ul>
                <ul>
                  <li>
                    Burada yatırmak istediğiniz Kripto parayı seçerek
                    <span>'Yatırma'</span> butonuna tıklayınız.
                  </li>
                </ul>
                <ul>
                  <li>
                    Seçmiş olduğunuz Kripto para ile ilgili Cüzdan adresi ve
                    bununla ilgili QR code’u göreceksiniz. Cüzdan adresini
                    kopyalayarak ya da QR code’u okutarak transfer yapacağınız
                    borsa platformunda ilgili yere yapıştırabilirsiniz.
                  </li>
                </ul>
                <ul>
                  <li>
                    Transfer yapıldığında otomatik olarak hesabınıza yansıyacak
                    ve buna ilişkin <span>email ve SMS </span>yolu ile bildirim
                    yapılacaktır.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="container-collabsible">
          <div class="wrap-collabsible">
            <input id="collapsible7" class="toggle" type="checkbox" />
            <label for="collapsible7" class="lbl-toggle"
              >Bitistanbul Hesabımdan Nasıl Kripto Varlık Çekerim?</label
            >
            <div class="collapsible-content">
              <div class="content-inner">
                <ul>
                  <li>
                    Bitistanbul hesabınızdan Kriptopara çekmek için öncelikle
                    hesabınızdaki
                    <span>'Cüzdan'</span> sekmesine geliniz.
                  </li>
                </ul>
                <ul>
                  <li>
                    Burada çekmek istediğiniz Kripto parayı seçerek
                    <span>'Çekme'</span> butonuna tıklayınız.
                  </li>
                </ul>
                <ul>
                  <li>
                    Bu sayfada gelen formda
                    <span>'Gönderim Adresi'</span> bölümüne gönderim yapacağınız
                    adresi yazınız.
                  </li>
                </ul>
                <ul>
                  <li>
                    Çekim yapacağınız tutarı da yazdıktan sonra çekim işlemini
                    <span>'Gönder'</span>
                    butonuna basarak onaylayabilirsiniz.
                  </li>
                </ul>
                <ul>
                  <li>
                    Hesabınızdan çekim yapabilmeniz için 2FA güvenlik seçeneğini
                    aktif etmelisiniz. ( 2FA güvenlik ayarlarına ilişkin
                    adımları Güvenlik- 2 FA linkinden bulabilirsiniz)
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

import { Component, OnInit } from '@angular/core';
import { SupportTicketService } from 'src/app/Services/support-ticket.service';

@Component({
  selector: 'app-createticket',
  templateUrl: './createticket.component.html',
  styleUrls: ['./createticket.component.css'],
})
export class CreateticketComponent implements OnInit {

  isDisabled = false;
  public error = null;
  public success = null;
  public form = {
    title: null,
    message: null,
    direction: '',
  };
  
  constructor(private support: SupportTicketService) {}

  ngOnInit() {}

  onSubmit() {
    this.isDisabled = true;
    this.error = null;
    this.success = null;
    return this.support.createTicket(this.form).subscribe(
      (data) => this.handleResponse(data),
      (error) => this.handleError(error)
    );
  }

  handleResponse(res) {
    this.isDisabled = false;
    if (res.success) {
      this.success = res.message;
      // HTMLFormElement olarak tipini belirt
      const createTicketForm = document.getElementById(
        'createticketForm'
      ) as HTMLFormElement | null;
      // Form varsa ve HTMLFormElement ise reset fonksiyonunu çağır
      if (createTicketForm) {
        createTicketForm.reset();
      }
    } else {
      this.error = res.message;
    }
  }

  handleError(error) {
    this.error = error.message;
  }
}

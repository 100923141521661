import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { throwError } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class TokenService {
  private baseUrl = environment.baseUrl;
  private iss = {
    login: environment.baseUrl + "/login",
    register: environment.baseUrl + "/register",
    refreshdata: environment.baseUrl + "/refreshdata",
  };

  constructor(private http: HttpClient) {}

  handle(token) {
    this.set(token);
  }

  handledevice(device) {
    this.setDevice(device);
  }

  setDevice(data) {
    localStorage.setItem("device_key", data);
  }

  set(data) {
    localStorage.setItem("token", data);
  }

  customset(name, token) {
    localStorage.setItem(name, token);
  }

  customget(name) {
    return localStorage.getItem(name);
  }

  get() {
    return localStorage.getItem("token");
  }

  getDevice() {
    return localStorage.getItem("device_key");
  }

  remove() {
    localStorage.removeItem("token");
  }

  isValid() {
    const token = this.get();

    if (token) {
      const payload = this.payload(token);

      if (payload) {
        const ValidUrl = true;

        if (ValidUrl) {
          if (Date.now() > payload.exp * 1000) {
            this.remove();
            return false;
          }

          return true;
        }
      }

      return false;
    }

    return false;
  }

  isValidtwofa() {
    const token = this.get();

    if (token) {
      const payload = this.payload(token);

      if (payload) {
        const ValidUrl =
          Object.values(this.iss).indexOf(payload.iss) > -1 ? true : false;

        if (ValidUrl) {
          if (payload.tfa == "sari") {
            return false;
          }

          return true;
        }
      }
    }

    return false;
  }

  payload(token) {
    const payload = token.split(".")[1];
    return this.decode(payload);
  }

  getUserDetails() {
    const token = this.get();

    if (token) {
      const payload = token.split(".")[1];
      return this.decode(payload);
    } else {
      return false;
    }
  }

  decode(payload) {
    if (!payload) {
      return null; // veya başka bir değer
    }
    const fixedBase64 = payload.replace(/-/g, "+").replace(/_/g, "/");
    return JSON.parse(atob(fixedBase64));
  }
  loggedIn() {
    return this.isValid();
  }

  currentUserValue() {
    return localStorage.getItem("currentUser");
  }

  // refreshToken() {
  //   return this.http.post(`${this.baseUrl}/refreshdata`, []).pipe(
  //     catchError((error) => {
  //       return this.handlerefreshError(error);
  //     })
  //   );
  // }

  refreshToken() {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.baseUrl}/refreshdata`, []).subscribe(
        (response: any) => {
          if (response && response.result && response.result.access_token) {
            this.set(response.result.access_token);
          }
          resolve(response);
        },
        (error: HttpErrorResponse) => {
          reject(this.handlerefreshError(error));
        }
      );
    });
  }

  handleRefeshtoken(token) {
    this.handle(token.result.access_token);
  }

  handlerefreshError(error) {
    return throwError("Server error");
  }

  errorHandler(error: HttpErrorResponse) {
    return "Server error";
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TradeService } from 'src/app/Services/trade.service';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { FormattingService } from 'src/app/Services/numberFormat.services';
import { Meta, Title } from "@angular/platform-browser";


@Component({
  selector: 'app-tradehistroy',
  templateUrl: './tradehistroy.component.html',
  styleUrls: ['./tradehistroy.component.css'],
})
export class TradehistroyComponent implements OnInit {
  public error = null;
  public success = null;
  public data = null;
  public histroys = [];
  public collectionSize: any;
  public markets = [];
  public pair = 'BTC_TRY';
  public cointwo = 'TRY';
  public tradetype = 'limit';
  public ordertype = 'buy';

  public form = {
    startdate: null,
    enddate: null,
  };

  inputForm: FormGroup;
  page = 1;
  pageSize = 10;
  config: any;
  p: any = 1;
  limit: any = environment.pagination;
  offset: any = 0;
  baseUrl = environment.baseUrl;
  routeParams: Params;
  queryParams: Params;
  startdate: any = null;
  enddate: any = null;

  constructor(
    private trades: TradeService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe, 
    public formattingService: FormattingService,
    
 private metaService: Meta, private titleService: Title
  ) {
    this.getRouteParams();
  }

  getRouteParams() {
    // URL query parameters
    this.route.queryParams.subscribe((params) => {
      this.pair = params['pair'] ? params['pair'] : 'BTC_TRY';
      this.ordertype = params['ordertype'] ? params['ordertype'] : 'buy';
      this.tradetype = params['tradetype'] ? params['tradetype'] : 'limit';
      this.queryParams = params['page'] ? params['page'] : 1;
      this.startdate = params['startdate'] ? params['startdate'] : null;
      this.enddate = params['enddate'] ? params['enddate'] : null;
      this.p = this.queryParams;

      this.gethistroydetails(
        this.pair,
        this.ordertype,
        this.tradetype,
        this.p,
        this.startdate,
        this.enddate
      );
    });
  }

  gethistroydetails(
    pair,
    ordertype,
    tradetype,
    page: number = 1,
    startdate: any = null,
    enddate: any = null
  ) {
    const perPage = environment.pagination;
    const start: any = (page - 1) * perPage;
    const end: any = perPage;

    var myFormData = new FormData();
    if (pair != null) {
      myFormData.append('pair', pair);
    }
    myFormData.append('type', ordertype);
    myFormData.append('status', tradetype);
    myFormData.append('offset', start);
    myFormData.append('limit', end);
    if (startdate && enddate) {
      myFormData.append('startdate', startdate);
      myFormData.append('enddate', enddate);
    }
    this.trades.gettradehistroy(myFormData).subscribe(
      (data) => this.handleorderDetails(data, page),
      (error) => this.handleError(error)
    );
  }

  handleorderDetails(res, page) {
    if (res.success) {
      this.data = res.result;
      this.histroys = this.data.histroy;
      this.collectionSize = this.histroys.length;

      this.markets = this.data.market;
      // TUMU en son satıra taşı
      this.markets.push(
        this.markets.splice(
          this.markets.findIndex(
            (market) =>
              market['pairone'] === 'TUMU' && market['pairtwo'] === 'TUMU'
          ),
          1
        )[0]
      );
      this.config = {
        currentPage: page,
        itemsPerPage: environment.pagination,
        totalItems: this.data.count,
      };
    }
  }

  ngOnInit() {
     // Meta etiketlerini ayarla
     this.metaService.addTags([
      {
        name: "description",
        content:
          "Kolay kullanım, yüksek likidite ve hızlı kripto işlemleri. 7/24 para yatırma çekme. Rekabetçi fiyatlar, düşük komisyon oranlarıyla güvenle kripto al, sat, sakla!",
      },
      {
        name: "keywords",
        content: "hesap",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);

    const pageTitle = "Bitistanbul | Türkiye'nin Güvenilir Kripto Para Platformu";
    this.titleService.setTitle(pageTitle);
    
    this.refreshHistorys();
    let docDate = this.startdate;
    this.inputForm = new FormGroup({
      pair: new FormControl(this.pair),
      tradetype: new FormControl(this.tradetype),
      ordertype: new FormControl(this.ordertype),
      startdate: new FormControl(
        this.startdate ? new Date(this.startdate) : null
      ),
      enddate: new FormControl(this.enddate ? new Date(this.enddate) : null),
    });
    this.logInputChange();
  }

  refreshHistorys() {
    if (this.histroys) {
      this.histroys
        .map((histroys, i) => ({ id: i + 1, ...histroys }))
        .slice(
          (this.page - 1) * this.pageSize,
          (this.page - 1) * this.pageSize + this.pageSize
        );
    }
  }

  logInputChange() {
    this.inputForm.valueChanges.forEach((value: any) => {
      if (value.startdate && value.enddate) {
        var startdate = this.datePipe.transform(value.startdate, 'yyyy-MM-dd');
        var enddate = this.datePipe.transform(value.enddate, 'yyyy-MM-dd');
        this.router.navigate(['islem-gecmisi'], {
          queryParams: {
            pair: value.pair,
            ordertype: value.ordertype,
            tradetype: value.tradetype,
            startdate: startdate,
            enddate: enddate,
          },
        });
      } else {
        this.router.navigate(['islem-gecmisi'], {
          queryParams: {
            pair: value.pair,
            ordertype: value.ordertype,
            tradetype: value.tradetype,
          },
        });
      }
    });
  }

  cancelorder(id, type) {
    var myFormData = new FormData();
    myFormData.append('type', type);
    myFormData.append('tradeid', id);
    this.trades.canceltrade(myFormData).subscribe(
      (data) => this.handlecancelDetials(data),
      (error) => this.handleError(error)
    );
  }

  handlecancelDetials(res) {
    if (res.success) {
      this.success = res.message;
      this.getRouteParams();
    } else {
      this.error = res.message;
    }
  }

  pageChange(newPage: number) {
    this.router.navigate(['islem-gecmisi'], {
      queryParams: {
        pair: this.pair,
        ordertype: this.ordertype,
        tradetype: this.tradetype,
        startdate: this.startdate,
        enddate: this.enddate,
        page: newPage,
      },
    });
  }

  clearData() {
    this.inputForm = new FormGroup({
      pair: new FormControl(this.pair),
      tradetype: new FormControl(this.tradetype),
      ordertype: new FormControl(this.ordertype),
      startdate: new FormControl(null),
      enddate: new FormControl(null),
    });
    this.router.navigate(['islem-gecmisi'], {
      queryParams: {
        pair: this.pair,
        ordertype: this.ordertype,
        tradetype: this.tradetype,
      },
    });
  }

  downloadExcel() {
    try {
      this.trades.gettradehistroy(this.gethistroydetails).subscribe(() => {
        const worksheet = XLSX.utils.json_to_sheet(this.data.histroy);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        const excelBuffer: any = XLSX.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        this.saveExcelFile(excelBuffer, 'Alim-Satim-islem-gecmisi.xlsx');
      });
    } catch (error) {
       
    }
  }

  saveExcelFile(buffer: any, fileName: string) {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  }

  handleError(error) {
    this.error = error.message;
  }
}

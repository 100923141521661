<app-header></app-header>

<article class="innerpagearticle">
  <section class="innerbannerpage">
    <div class="container mt-15">
      <!-- app-alert-modal -->
      <app-alert-modal
        *ngIf="error"
        [error]="error"
        (close)="error = null"
      ></app-alert-modal>

      <!-- app-alert-modal-success -->
      <app-alert-modal-success
        *ngIf="success"
        [success]="success"
        (close)="success = null"
      ></app-alert-modal-success>

      <!-- forgotHeader -->
      <table id="forgotHeader">
        <tbody>
          <tr>
            <td style="text-align: center; height: 35px">
              <img
                src="assets/images/071.svg"
                alt=""
                class="text-center"
                style="width: 17px; margin-right: 105px"
              />
            </td>
          </tr>
          <tr>
            <td style="text-align: center">
              <img
                src="assets/images/070.svg"
                class="text-center"
                style="width: 24px; vertical-align: sub"
                alt=""
              />
              &nbsp;&nbsp;
              <small>{{ "ForgotPassword" | translate }}</small>
            </td>
          </tr>
          <tr>
            <td colspan="3" style="height: 25px">
              <hr />
            </td>
          </tr>
        </tbody>
      </table>

      <!-- forgotpasswordForm -->
      <div class="mt-135 col-lg-5 col-md-6 col-sm-8 col-xs-12 center-content">
        <form
          class="innerformbg"
          autocomplete="off"
          #forgotpasswordForm="ngForm"
          (ngSubmit)="onSubmit()"
        >
          <!-- email -->
          <div class="form-group">
            <input
              type="email"
              name="email"
              class="form-control"
              placeholder="{{ 'EmailID' | translate }}*"
              (change)="onChangeEvent($event)"
              [class.is-invalid]="email.invalid && email.touched"
              #email="ngModel"
              [(ngModel)]="forgotPasswordDataform.email"
              pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              required
            />
            <span *ngIf="email.errors && email.invalid && email.touched">
              <small class="text-danger-input" *ngIf="email.errors.required">
                {{ "EmailID" | translate }} {{ "required" | translate }}
              </small>
              <small class="text-danger-input" *ngIf="email.errors.pattern">
                {{ "Pleaseemailaddress" | translate }}
              </small>
            </span>
          </div>
          <br />

          <!-- recaptcha -->
          <div class="form-group">
            <re-captcha
              [(ngModel)]="forgotPasswordDataform.recaptcha"
              name="recaptcha"
              #recaptcha="ngModel"
              required
            ></re-captcha>
          </div>

          <!-- buttons -->
          <div class="form-group mt-30">
            <div class="text-center">
              <input
                type="submit"
                name=""
                value="{{ 'Submit' | translate }}"
                class="btn orange-btn"
                [disabled]="!forgotpasswordForm.valid"
                *ngIf="!isDisabled"
              />
              <input
                type="submit"
                name=""
                value="{{ 'Pleasewait' | translate }}..."
                class="btn orange-btn"
                disabled
                *ngIf="isDisabled"
              />
            </div>
          </div>

          <!-- Alreadyhaveaccount & Signin -->
          <div class="mt-20 btnsnfg text-center">
            <h5 class="t-white">
              {{ "Alreadyhaveaccount" | translate }}<br />
              <u class="t-blue">
                <a routerLink="/giris" class="t-blue">{{
                  "Signin" | translate
                }}</a>
              </u>
            </h5>
          </div>
        </form>
      </div>
    </div>
  </section>
</article>

<app-footer></app-footer>

<!-- Kullanıcı Sözleşmesi Pages -->
<app-header></app-header>
<article class="innerpagearticle">
  <section class="innerbannerpage">
    <div class="container">
      <div class="row">
        <table id="user-agreement-header">
          <tbody>
            <tr>
              <td style="text-align: center; height: 35px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: 135px"
                />
              </td>
            </tr>
            <tr>
              <td style="text-align: center">
                <img
                  src="assets/images/070.svg"
                  class="text-center"
                  style="width: 24px; vertical-align: sub"
                  alt=""
                />
                &nbsp;&nbsp;
                <small> {{ "UserAgreement" | translate }}</small>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row header-row">
        <strong>Şirkete Ait Bilgiler</strong>
        <br />
        <br />
        Unvan : <b>Hotto Kripto Varlık Alım Satım Platformu A.Ş.</b>
        <br />
        <br />
        Adres : Esentepe Mah. Büyükdere Cad. No: 102 Maya Akar Center 16. Kat
        Daire: 60 Şişli / İSTANBUL
        <br />
        <br />
        Telefon :<b> +90 ( 212 ) 288 52 88</b>&nbsp;&nbsp; E-posta :
        <b>info&#64;bitistanbul.com</b> &nbsp;&nbsp;Müşteri Hizmetleri :
        <b>+90 ( 850 ) 677 77 70</b>
      </div>

      <div class="row style-content">
        <br />
        <p>
          <strong>Hizmetin Temel Nitelikleri</strong>
        </p>
        <p>
          <b> Hotto Kripto Varlık Alım Satım Platformu A.Ş.</b>(ŞİRKET) tarafından oluşturulan
          <b>www.bitistanbul.com</b> bir online kripto varlık çiftleri
          alım–satım platformudur. Platform, özetle, kullanıcıların kripto
          varlık çiftlerinde gerçekleştirecekleri alım / satım işlemleri, nakit
          veya kripto varlık transfer/ödeme işlemleri, gerçekleşen işlemlerin
          takibi ve raporlanmasına yönelik online hizmet sunan bir yazılımdır.
        </p>
      </div>

      <div class="row style-content">
        <p>
          <strong>Hizmet Masraflarına İlişkin Bilgiler</strong>
        </p>
        <p>
          Platformda gerçekleştirilecek kripto varlık alım satım işlemleri
          nedeniyle ortaya çıkan ve ilgili mevzuat uyarınca doğrudan Hotto Kripto Varlık Alım Satım Platformu A.Ş. tarafından ödenmesi gerekenler hariç, her türlü
          vergi (BSMV, KDV, damga vergisi vb.) ve harçlar ile komisyon, faiz
          veya her ne nam altında olursa olsun diğer tüm masraflar KULLANICI
          tarafından ödenir.
          <br />
          <br />
          KULLANICI tarafından Platformda gerçekleştirilen kripto varlık
          alım/satım işlemleri ile kirpto varlık ve/veya nakit transfer
          işlemleri nedeniyle ödenecek komisyon, faiz ve masraflar
          <b>www.bitistanbul.com</b>
          sitesinde ilan edilir. KULLANICI söz konusu internet adresinde
          yayımlanan komisyon, faiz, masraf veya her ne nam altında olursa olsun
          her türlü ödemeyi kabul eder.
        </p>
      </div>

      <div class="row style-content">
        <p>
          <strong
            >Riskler, Fiyatların Oluşumu, Garanti Fonu veya Tazminat
            Sistemi</strong
          >
        </p>
        <p>
          Platformda fiyatlar alıcı ve satıcı tarafından belirlenir. ŞİRKET
          Platforma iletilen alım ve satım emirlerinin gerçekleşmesini ve
          gerçekleşen işlemlere ilişkin karşılıklı yükümlülüklerin yerine
          getirilmesini hiçbir şekilde garanti etmez. Platformda yer alan
          fiyatlar alıcı ve satıcının fiyat tercihleri ile kripto varlık
          piyasalarındaki dalgalanmalara göre değişir. Platformda gerçekleşen
          emirlere ilişkin yükümlülüklerin karşılıklı olarak yerine getirilmesi
          amacıyla oluşturulan bir garanti fonu veya benzeri mekanizma yoktur.
          ŞİRKET, kendine atfı kabil durumlar hariç, alıcı ve satıcının
          karşılıklı yükümlülüklerinin yerine getirilmesinde hiçbir sorumluluk
          almaz.
        </p>
      </div>

      <div class="row style-content">
        <p>
          <strong>Platform İçeriği ve Sağlanan Bilgiler</strong>
        </p>
        <p>
          Platformda yer alan tüm içerik, üçüncü kişilerden, internet ve kamuya
          açık diğer kaynaklardan toplanan verilerden oluşmaktadır. Platformda
          yer alan data, bülten, rapor, analiz, istatistik veya bu nitelikteki
          her türlü dokümana ilişkin olarak ŞİRKET, objektif kriterler dışında
          sorumluluk kabul etmez. ŞİRKET bu bilgilerin doğruluğunda en iyi
          gayreti gösterir. ŞİRKET bu dokümanları üretirken kullanmış olduğu
          kaynaklardaki verilerin yalan, yanlış ve yanıltıcı olması durumunda,
          endine atfı kabil kusur ve objektif olarak tespit edebileceği hatalar
          dışında sorumlu tutulamaz.
          <br />
          <br />
          Platformda yer alan tüm içerik, üçüncü kişilerden, internet ve kamuya
          açık diğer kaynaklardan toplanan verilerden oluşmaktadır. Platformda
          yer alan data, bülten, rapor, analiz, istatistik veya bu nitelikteki
          her türlü dokümana ilişkin olarak ŞİRKET, objektif kriterler dışında
          sorumluluk kabul etmez. ŞİRKET bu bilgilerin doğruluğunda en iyi
          gayreti gösterir. ŞİRKET bu dokümanları üretirken kullanmış olduğu
          kaynaklardaki verilerin yalan, yanlış ve yanıltıcı olması durumunda,
          endine atfı kabil kusur ve objektif olarak tespit edebileceği hatalar
          dışında sorumlu tutulamaz.
        </p>
      </div>

      <div class="row style-content">
        <p>
          <strong>Gerçekleşen İşlemlerin İfası</strong>
        </p>
        <p>
          Para yatırma ve çekme işlemlerinde ŞİRKET’e ait banka hesapları ile
          KULLANICI ismine açılmış banka hesapları kullanılır. Banka üzerinden
          yapılacak transferler hariç nakit işlemi kabul edilmez.
          <br />
          <br />
          Kripto varlık çiftlerinin transferinde KULLANICI tarafından sisteme
          tanıtılan kripto para cüzdanları kullanılır.
        </p>
      </div>

      <div class="row style-content">
        <p>
          <strong>Cayma Hakkı</strong>
        </p>
        <p>Gerçekleşen işlemlerden KULLANICI'nın cayma hakkı yoktur.</p>
      </div>

      <div class="row style-content">
        <p>
          <strong>Sözleşmenin Süresi ve Feshine İlişkin Bilgiler</strong>
        </p>
        <p>
          KULLANICI ile ŞİRKET arasında imzalanacak KULLANICI SÖZLEŞMESİ
          süresizdir. 10 iş günü önceden ihbar etmek kaydıyla TARAFLAR
          sözleşmeyi tek taraflı olarak fesih hakkına sahiptir.
          <br />
          <br />
          ŞİRKET, KULLANICI'nın ödemede temerrüde düşmesi, sözleşme hükümleri
          ile sözleşmeden kaynaklanan dürüstlük ve özen yükümlülüklerine
          aykırılık, yanlış beyan, iflası ya da ödemelerinde acze düşmesi
          durumunda, KULLANICI'nın otomatik yazılımlar vasıtası ile Platforma
          emir ilettiğinin tespiti, Platformda ortaya çıkabilecek teknik
          aksaklık veya zafiyetlerin KULLANICI tarafından dürüstlüğe aykırı
          olarak kullanılarak haksız ve hukuka aykırı kazanç elde edildiğinin,
          Platformdaki işlemlerin mevzuat aykırılık veya mevzuatı dolanma
          amacını taşıdığı veya suç gelirlerinin aklanması ve terörizmin
          finansmanı amacıyla kullanıldığı yönünde makul şüphenin varlığı
          durumlarında herhangi bir süre şartı olmaksızın Sözleşmeyi tek taraflı
          olarak fesih hakkında sahiptir. KULLANICI bu gerekçelerle Sözleşmenin
          feshedilmesi nedeniyle karşı karşıya kalacağı zararı ŞİRKET’ten talep
          edemeyecektir.
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Taraflar</strong>
        </p>
        <p>
          <b>1.1.</b> İşbu Kullanıcı Sözleşmesi bir tarafta
          _______________________ adresinde mukim ___________ (KULLANICI) ile
          Esentepe Mahallesi, Büyükdere Caddesi, Maya Akar Center B,Blok,
          No:102-1 Kapı No:13 Şişli-İstanbul adresinde mukim
          <b>Hotto Kripto Varlık Alım Satım Platformu A.Ş.</b>
          A.Ş. (ŞİRKET) arasında imzalanmıştır. ŞİRKET ve KULLANICI birlikte
          TARAFLAR olarak anılacaktır.
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Amaç</strong>
        </p>
        <p></p>
        <p>
          <b>2.1.</b> Sözleşme ŞİRKET ile kripto varlık çiftlerinin alım
          satımına amacına yönelik oluşturulan Platforma üye olan KULLANICI
          arasında, Platformda gerçekleştirilerek alım/atım işlemleri, kripto
          varlık veya nakit transfer işlemleri, cüzden kullanım esasları ile
          bunlarla sınırlı olmamak üzere diğer tüm karşılıklı hak ve
          yükümlülüklerin belirlenmesi amacıyla imzalanmıştır.
          <br />
          <br />
          <b>2.2.</b> Sözleşme KULLANICI’nın Platforma üye olması ile hüküm
          ifade eder. KULLANICI’nın Platforma üye olması Sözleşme’nin tüm
          hükümlerini okunarak KULLANICI tarafından gayri kabili rücu hukuken
          kabul ve taahhüt edildiği anlamına gelir.
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Tanımlar</strong>
        </p>
        <p>
          <b>3.1.</b> Kripto Para Çiftleri: Platformda kripto varlıklar ile
          fiili para veya kripto varlıkların kendi aralarındaki oran ile temsil
          edilen ve Platform vasıtası ile alım satıma konu edilebilen kripto
          varlıklar
          <br />
          <br />
          <b>3.2.</b> Cüzdan: Platformda işlem gerçekleştirilen kripto
          varlıkların adresleri ile bu adreslere ait şifreleri tutan bilgisayar
          dosyasıdır.
          <br />
          <br />
          <b>3.3.</b>
          Platform: <b>Hotto Kripto Varlık Alım Satım Platformu A.Ş.</b> mülkiyetinde, kripto
          varlık alım satım işlemlerinin gerçekleştirilmesi amacıyla hazırlanan,
          Platformda işleme açılan kripto varlık çiftlerinde alım/satım emir
          iletimi, cüzdan oluşturulması, kripto varlık ve nakit transferine
          imkân sağlayan, internet üzerinden erişilebilen veya mobil cihazlara
          yüklenebilen yazılım
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Gerçek kullanıcı</strong>
        </p>
        <p>
          <b>4.1. </b>KULLANICI, Platformda kendi adına açılan hesap ve
          oluşturulacak cüzdanlarda kendi adına hesabına hareket ettiğini,
          başkası hesabına hareket etmediğini, gerçekleştirdiği işlemlerin kendi
          nam ve hesabına olduğu, bir başkasının emir ve talimatı ile işlem
          gerçekleştirmediğini; hesap ve cüzdandaki varlıklarının hukuka uygun
          yollardan temin edildiği; hiçbir şekilde suçtan elde edilen gelir
          niteliği taşımadığı ve bu nitelikteki gelirin aklanması ya da
          terörizmin finansmanı amacıyla kullanılmadığı ve kullanılmayacağını
          kabul ve taahhüt eder.
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Risklerin Bildirimi</strong>
        </p>
        <p>
          <b> 5.1.</b> kullanıcı, Platformda gerçekleştirmiş olduğu işlemler
          sonucunda kar elde edebileceğiniz gibi zarar riski bulunduğunu kabul
          eder. KULLANICI, Platformda işlem yapmaya karar vermeden önce,
          karşılaşabileceği riskleri tahlil ettiğini, gerçekleştireceği
          işlemlere mali durumu ve kısıtlarını dikkate alarak karar verdiğini
          beyan eder.
          <br />
          <br />
          <b>5.2.</b> KULLANICI;
          <br />
          <br />
          <b>a.</b> Piyasada oluşacak fiyat hareketleri sonucunda hesabında
          bulunan paranın tümünü kaybedebileceğini, işlemin türüne göre
          hesabında bulunan para tutarını dahi aşabileceğini;
          <br />
          <br />
          <b>b.</b> Teminat karşılığı gerçekleştirilen işlemlerde piyasadaki
          fiyat hareketlerinin beklentilerine uygun gelişmesi sonucunda yüksek
          kazançlar sağlayabileceği gibi beklentileri aksine gelişmesi durumunda
          ciddi zararlara da yol açabileceğini;
          <br />
          <br />
          <b>c.</b> Platformda yer alan bülten, doküman, rapor, analiz veya
          benzeri nitelikteki dokümanlarda yer alan bilgi ve değerlendirmelerin
          eksik veya doğrulanmaya muhtaç olabileceğini;
          <br />
          <br />
          <b>d.</b> ŞİRKET personeli tarafından yapılacak analiz ve yorumların
          kişiden kişiye farklılık arz edebileceği ve bu analizlerde yapılan
          öngörülerin kesin olarak gerçekleşmeme olasılığının bulunduğunu;
          <br />
          <br />
          <b>e.</b> Platformda yabancı para cinsinden yapılan işlemlerde, kur
          riskinin olduğunu, kur dalgalanmaları nedeniyle Türk Lirası bazında
          değer kaybı olabileceği, yabancı sermaye ve döviz hareketlerinin
          kısıtlanabileceğini, ek ve/veya yeni vergiler getirebileceğini, alım /
          satım işlemlerinin zamanında gerçekleşmeyebileceğinigayri kabili rücu
          kabul eder.
          <br />
          <br />
          <b>5.3.</b> KULLANICI kripto varlık çiftlerindeki önemli fiyat
          hareketlerinin olabileceğini, piyasalardaki bu hareketliliğe bağlı
          olarak Platformdaki fiyatların oynaklık gösterebileceğini; kripto
          varlık yatırımlarının riskler içerdiğini ve bu riskleri anladığını;
          işlemler sonucunda oluşan kar ve zararlardan kendisinin sorumlu
          olduğunu, herhangi bir kayıp ya da zarardan ŞİRKET’in hiçbir
          sorumluluğu olmadığını, ŞİRKET’e atfı kabil kusur dışında hiçbir hak
          talep edemeyeceğini ve bu nedenle maddi / manevi tazminat ya da başka
          bir adla herhangi bir talepte bulunmayacağını kabul, beyan ve taahhüt
          eder.
          <br />
          <br />
          <b>5.4.</b> KULLANICI, kripto para alım-satım işlemleri yapmak için
          akli melekelere, gerekli bilgi ve deneyime sahip olduğunu,
          gerçekleştirmiş olduğu işlemler sonucunda zarar edebileceğini
          bildiğini ve bu zararları göze aldığını ve bu zararları kaldırabilecek
          finansal güce sahip olduğunu taahhüt eder. KULLANICI kendi kararlarını
          alabilecek yaş ve özellikte olup hakkında veli veya vasi tayin
          edilmemiş olduğunu, kısıtlı olmadığını beyan eder.
          <br />
          <br />
          <b>5.5.</b> KULLANICI işbu Sözleşmenin yürürlük tarihi itibariyle
          kripto varlık alım satımı işlemlerine yönelik herhangi bir yasal
          düzenleme bulunmadığını bildiğini; bir uyuşmazlık durumunda bu
          uyuşmazlığın genel hukuk hükümlerine tabi olduğunu; ancak işbu
          Sözleşmenin yürürlük tarihinden sonra kripto varlıkların alım / satım
          işlemlerine ilişkin yasal düzenlemelerin yürürlüğe girmesi durumunda
          getirilen bu yükümlülüklere tabi olacağını; yükümlülüklere uyum
          amacıyla ŞİRKET tarafından talep edilen her türlü bilgi ve belgenin
          ŞİRKET tarafından belirlenen süre içerisinde tevdi edeceğini; ilgili
          düzenlemelere uygun davranacağını; aksi bir uygulamada ŞİRKET’in
          ihbara gerek olmaksızın işbu Sözleşmeyi fesih hakkının bulunduğunu
          kabul, beyan ve taahhüt eder.
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Platform</strong>
        </p>
        <p>
          <b>6.1. </b>Platform, tüm fikri mülkiyet hakkı
          <b>Hotto Kripto Varlık Alım Satım Platformu A.Ş.</b> 'ne ait, kripto varlık çiftlerinin
          online olarak alım–satımına ilişkin emir iletme, bu emirlerin
          karşılaşarak işlem gerçekleştirmelerine, kullanıcılar adına cüzdan
          açılabilmesine, bu cüzdanlardaki hareketlerin ilgili blok–zincirler
          üzerinde kaydedilmesine imkân sağlayan bir yazılımdır.
        </p>
        <p>
          <b>6.2. </b> Platformda kullanıcılar birbirleri ile kripto para
          birimlerini alır veya satarlar. Platformda yer alan fiyatlar alıcı ve
          satıcının vermiş olduğu fiyatlardır. ŞİRKET fiyatların değişmesinden
          dolayı hiçbir sorumluluğu yoktur. KULLANICI’nın emri Platforma girdiği
          an ile işlemin gerçekleştiği an arasında fiyatlarda meydana
          gelebilecek değişikliklerden, bu değişiklikler nedeniyle ortaya
          çıkabilecek zarar ve kayıplardan ŞİRKET sorumlu tutulamaz.
        </p>
        <p>
          <b>6.3. </b> ŞİRKET Platformda işlemlerin gerçekleşmesine ilişkin
          garanti vermez. İşlemler alıcı ve satıcının aynı yöndeki iradesinin
          eşleşmesi ile gerçekleşir. KULLANICI’nın girmiş olduğu emrin
          eşleşmemiş olması, kısmen veya tamamen gerçekleşmemesi nedeniyle,
          ŞİRKET’in sorumluluğuna gidilemez. ŞİRKET hiçbir şekilde işlemlerin
          sonuçlanmasını ya da girilen emirlerin gerçekleşmesini garanti etmez.
        </p>
        <p>
          <b>6.4. </b> Platformda yer alan tüm servisler, domain ve diğer her
          türlü alan adları, yazılım kodları, ara yüzler ve bunların içerikleri,
          kripto çiftlere ilişkin analiz, video, çizimler, tasarımlar, modeller
          ve diğer her türlü içeriğe ilişkin fikri ve sinai mülkiyet hakları
          ŞİRKET’e aittir. KULLANICI söz konusu içeriği ŞİRKET’in önceden izni
          olmaksızın kopyalamayacağını, çoğaltmayacağını ve yayınlamayacağını,
          ters mühendislik işlemlerine tabi tutmayacağını kabul, beyan ve
          taahhüt eder. KULLANICI bu hükümlere aykırı davranması nedeniyle
          ŞİRKET’in karşı karşıya kalacağı ceza, tazminat veya her ne nam
          altında olursa olsun zarar ve ziyanı ŞİRKET’in yazılı talebi halinde
          başkaca bir işleme ihtiyaç duymaksızın defaten ödemeyi kabul ve
          taahhüt eder. Bu hükme aykırılık Sözleşmenin tek taraflı, haklı ve
          ihbarsız feshi nedeni olup tüm hukuki ve cezai sorumluluk KULLANICI'ya
          aittir.
        </p>
        <p>
          <b>6.5. </b> Platformda yer alan tüm veriler, raporlar, analizler,
          istatistikler sadece bilgilendirmeye yönelik olup doğruluğu ŞİRKET
          tarafından garanti edilmemektedir. Verilerin birbiriyle çelişkili
          veyahut tutarsız olması halinde doğabilecek zararlardan ŞİRKET sorumlu
          değildir.
        </p>
        <p>
          <b>6.6. </b> ŞİRKET, Platformun altyapısını, uygulamalarını, şeklini
          ve içeriğini KULLANICI’nın gerçekleştirmiş olduğu işlemlerden
          kaynaklanan haklarına zarar vermemek koşuluyla değiştirmekte,
          daraltmakta veya genişletmekte, yeni ürünler / hizmetler ekleyip
          mevcut ürünlerin/hizmetlerin bir veya birkaçını çıkartmakta
          serbesttir.
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>İşlemlerin Gerçekleştirilmesi</strong>
        </p>
        <p>
          <b>7.1. </b>KULLANICI Platformdaki alım satım işlemlerini ŞİRKET’in
          belirleyeceği şartlar ve şekilde gerçekleştireceğini kabul, beyan ve
          taahhüt eder.
        </p>
        <p>
          <b>7.2. </b>ŞİRKET, KULLANICI’nın vereceği emri, herhangi bir gerekçe
          göstermeksizin kısmen veya tamamen kabul etmemek hakkına sahiptir. Şu
          kadar ki ŞİRKET emirin kısmen veya tamamen kabul edilmediğini
          KULLANICI’ya bildirmek zorundadır.
        </p>
        <p>
          <b>7.3. </b>ŞİRKET, KULLANICI’nın açabileceği pozisyon miktarına veya
          belli ürünler bazında sınırlama ve/veya işlem limitleri getirme
          hakkına sahiptir.
        </p>
        <p>
          <b>7.4. </b> KULLANICI tarafından işlemlerin Platform üzerinden
          gerçekleştirmesi esastır. Ancak Platforma bağlanamaması ya da
          emirlerin iletiminde teknik aksaklık ile karşılaşılması durumunda
          ŞİRKET kendi takdirine bağlı olarak telefon ile emir alabilir. ŞİRKET
          kendisine telefonla iletilen emirleri kabul etmek zorunda değildir.
          KULLANICI, ŞİRKET'e atfı kabil objektif kusur dışında, Platformda veya
          telefon dahil diğer iletişim sistemlerinde yaşanan problemler
          nedeniyle emir ve talimatlarının geç veya hiç gerçekleşmediğini ileri
          sürerek herhangi bir tazminat talebinde bulunamayacağını kabul, beyan
          ve taahhüt eder.
        </p>
        <p>
          <b>7.5. </b> KULLANICI Platform üzerinden veya telefon ile vermiş
          olduğu emirlere, ŞİRKET yetkilileri veya çalışanları ile
          gerçekleştirmiş olduğu görüşmelere veya elektronik posta ve diğer
          yöntemler sağlanan iletişime ilişkin kayıtların uygun ortamlarda
          kaydedilmesini, saklanmasını ve gerekmesi durumunda dinlenmesine
          muvafakat eder.
        </p>
        <p>
          <b>7.6. </b> Platforma erişim, mobil uygulamanın kullanımı, şifre
          tahsisi ve benzeri işlemlere ilişkin bilgisayar, modem ve telefon
          hattına veya benzeri özelliklere sahip teknik imkanlara sahip
          bulunulması, Platform şartlarına uygun yazılımı ve donanımı
          bulundurması ve bu yazılımı ve donanımı kullanabilecek teknik bilgiye
          sahip olunması KULLANICI’nın sorumluluğundadır. Platformda üyelik için
          başvurulması ve kendisine şifre verilmesi talebinde bulunması hâlinde
          KULLANICI’nın söz konusu teknik şartlara ve bilgiye sahip olduğu kabul
          edilir. Teknik şartların sağlanamaması veya eksik veya yetersiz olması
          veya gerekli teknik bilgiye sahip olunmaması durumunda Platformdan
          yararlanılamamasının sorumluluğu tamamen KULLANICI'ya aittir.
        </p>
        <p>
          <b>7.7. </b> KULLANICI, Platforma emir iletebilmek için
          www.bitistanbul.com adresi üzerinden veya mobil uygulama vasıtasıyla
          ŞİRKET tarafından kendisine tanınan kullanıcı adı ve şifre ile
          Platforma erişir. KULLANICI ilgili yazılımı kişisel bilgisayarına veya
          mobil cihazlarına yüklemeli ve çalışır hâle getirmelidir. KULLANICI,
          kendisi tarafından belirtilen cep telefonuna veya elektronik posta
          adresine ŞİRKET tarafından gönderilen şifresini kullanarak Platform
          vasıtasıyla işlem yaparak gerçekleştirir.
        </p>
        <p>
          <b>7.8. </b> ŞİRKET tarafından tahsis edilen kullanıcı kodu ile
          Platform tarafından oluşturulmakla birlikte daha sonra kendisi
          tarafından değiştirilen ya da dilediği zaman değiştirebileceği şifre
          kullanarak ilettiği tüm emirler KULLANICI’nın yazılı imzası olmadan
          işleme alınacak olup bu şekilde verilecek emirlerin sadece
          KULLANICI'nın bildiği, bilmesi gerektiği şifre ile gerçekleştirilmesi
          nedeniyle yazılı talimat sayılması hususunda KULLANICI ile ŞİRKET
          karşılıklı mutabakata varmışlardır.
        </p>
        <p>
          <b>7.9. </b> KULLANICI, gerekli gördüğü takdirde ŞİRKET’in teyit
          isteme ve kimlik doğrulama hakkını saklı tuttuğunu kabul eder.
          Herhangi bir gerekçe nedeniyle dahi olsa KULLANICI'dan teyit veya
          doğrulama alınamaması nedeniyle emir veya işlemi yerine
          getirmemesinden ya da teyit veya doğrulama alması süreci nedeniyle
          yaşanacak gecikmelerden doğabilecek zararlardan dolayı atfı kabil
          kusur dışında ŞİRKET’e hiçbir sorumluluk yüklenemez.
        </p>
        <p>
          <b>7.10. </b> KULLANICI’nın Platforma emir iletebilmesi için hesabında
          alım emrini karşılayacak tutarda nakit ve/veya satım emrini
          karşılayacak tutarda kripto varlık bulunması gerekir. İşlemlerin
          kısmen veya tamamen borçlanma suretiyle gerçekleştirilmesi bu tür
          emirlerin ŞİRKET tarafından kabulü halinde mümkündür. ŞİRKET nakit
          veya kripto varlık borçlanmak suretiyle işlem gerçekleştirilmesine
          ilişkin kuralları Platform vasıtasıyla duyurur. ŞİRKET bu işlemler
          için ayrı bir Sözleşme imzalanmasına karar verebilir.
        </p>
        <p>
          <b>7.11. </b> KULLANICI, kendisine tahsis edilen şifrenin gizli
          tutulması, üçüncü kişiler ile paylaşılmaması, üçünü kişiler tarafından
          ele geçirildiğini öğrendiğinde bu hususu iş saatleri içerisinde
          ivedilikle ŞİRKET’e bildirilmesine ilişkin tüm sorumluluk KULLANICI’ya
          aittir. KULLANICI kendisine tahsis edilen şifre ile gerçekleştirilen
          tüm işlemlerin sorumluluğunun kendisinde olacağını, şifre girilerek
          gerçekleştirilen tüm işlemlerin hesabına borç kaydedileceği ve bu
          yönde hiçbir itirazının bulunmayacağını kabul, beyan ve taahhüt eder.
          KULLANICI, şifrenin üçüncü kişiler tarafından kullanılması neticesinde
          yapılan işlemlerden, internet işlemleri ile ilgili kendisinden
          kaynaklanmayan hile ve sahteciliklerin sonuçlarından, Platforma veya
          mobil uygulamaya bağlantı sağladığı genel veya özel iletişim araç ve
          sistemlerinin işlememesinden veya arızalanmasından, bu nedenle ŞİRKET
          tarafından gönderilen bildirimlerin KULLANICI tarafından
          alınmamasından, internet vasıtası ile gelen veya ŞİRKET tarafından
          KULLANICI’ya gönderilen bilgi, talimat ve bildirimlerin değişik veya
          eksik iletilmiş olmasından doğacak sonuçlardan sorumlu olmayacağını
          kabul ve beyan eder.
        </p>
        <p>
          <b>7.12. </b> KULLANICI, güvenlik amacıyla ve şüpheli durumlarda
          şifrenin yeniden belirlenmesi amacıyla hesaba erişimin
          engellenebileceğini kabul eder. ŞİRKET bu durumda en seri iletişim
          aracıyla KULLANICI’yı bilgilendirecektir. KULLANICI böyle bir blokenin
          hesabının güvenliği için yapılabileceğini önceden bildiğini, böyle bir
          durumun gerçekleşmesi halinde hesabına ilişkin emir iletememekten
          dolayı doğabilecek zararlar nedeniyle ŞİRKET’i sorumlu tutmayacağını
          gayrı kabili rücu kabul, beyan ve taahhüt etmiştir.
        </p>
        <p>
          <b>7.13. </b> KULLANICI Platformda ilan edilen süreler içerisinde emir
          iletebilir. Platformda 7/24 hizmet verilecektir. Şu kadar ki,
          KULLANICI ŞİRKET’in kararına bağlı olarak ulusal ve/veya uluslararası
          piyasaların tatil olduğu günlerde emir kabul etmeyebileceğini, yaz
          saati uygulaması, bakım hizmetleri ve benzeri değişiklikler nedeniyle
          emir iletim saatlerinde değişikliğe gidebileceğini kabul eder. Emir
          iletim saatlerinde değişikliğe gidilmesi durumunda bu husus uygun
          ortam ile KULLANICI’ya bildirilir.
        </p>
        <p>
          <b>7.14. </b> ŞİRKET, kendisinden kaynaklanmayan teknik nedenlerden
          ve/veya mücbir sebeplerden ötürü KULLANICI tarafından Platforma
          iletilen emir ve talimatların yerine getirilmemiş olmasından veya
          eksik ya da yanlış yerine getirilmesinden sorumlu değildir. KULLANICI,
          Platform üzerinden gerçekleştirilecek işlemlere ilişkin her türlü
          riski bildiğini ve bu risklerin gerçekleşmesi neticesinde doğabilecek
          tüm zararın ŞİRKET’in kastı veya kusuru olan durumlar hariç kendisine
          ait olduğunu kabul, beyan ve taahhüt eder.
        </p>
        <p>
          <b>7.15. </b> KULLANICI, Platformun internet dahil, elektronik
          ortamlar üzerinden emir iletebilen bir uygulama olduğunu bildiğini ve
          kabul ettiğini beyan eder. Bu çerçevede, kendisine iletişim hizmeti
          sağlayan internet servis sağlayıcılardan, mobil iletişim
          hizmetlerinden, ulusal ve/veya uluslararası internet bağlantılarından
          kaynaklanabilecek sorunlardan, kendisi ve/veya ŞİRKET merkezli olarak
          internet hizmetlerinin kesintiye uğramasından ve bunlara bağlı
          performans kayıplarından ŞİRKET’e doğrudan kusur atfedilebilecek
          haller dışından ŞİRKET’i sorumlu tutamaz.
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Emir ve İşlemlerin İptali</strong>
        </p>
        <p>
          <b>8.1. </b>KULLANICI, kötü niyetli yazılımlar aracılığı ile veya
          ŞİRKET tarafından sağlanmayan, desteklenmeyen ya da kabul edilmeyen
          üçüncü parti uygulamalar aracılığıyla ya da Platforma müdahale
          edilerek oluşturulmuş fiyatlar üzerinden işlem yapması ve söz konusu
          durumun ŞİRKET tarafından tespit edilmesi halinde söz konusu emir veya
          işlemlerin ŞİRKET tarafından iptal edilebileceğini, bu pozisyonlardan
          oluşan kar ya da zararların silinebileceğini ve Platform nezdindeki
          hesabının işleme kapatılabileceğini kabul ve beyan eder.
        </p>
        <p>
          <b>8.2. </b>KULLANICI, elektronik işlem platformuna yansıtılan
          fiyatların temin edildiği sistemlerde ya da Platformlarda yaşanan
          problemler nedeniyle kripto varlık çiftleri alım satım fiyatlarının
          farklılıklar yaşanması, o anda diğer Platformlardaki aynı kripto
          varlık çiftlerinde geçerli fiyatlara göre bariz derecede farklı
          fiyatların yer alması durumunda bilerek veya bilmeyerek, bu durumu
          avantaj haline getirerek, aslında var olmayan fiyatlar üzerinden kar
          sağlaması halinde bahsi geçen tüm işlemlerinin ŞİRKET tarafından iptal
          edilebileceğini ve bu işlemler nedeniyle elde etmiş olduğu karın
          silineceğini kabul ve beyan eder.
        </p>
        <p>
          <b>8.3. </b> KULLANICI, iletişimde yaşanabilecek güçlükler, olağandışı
          piyasa koşulları veya belirli bir kripto varlık çifti ile ilgili
          likidite sorunları nedeniyle Platformda yer alan fiyat kotasyonları
          ile aynı anda diğer Platformlarda oluşan fiyatlar arasında
          farklılıklar oluştuğu takdirde; ŞİRKET’in bekleyen emirleri iptal
          edebileceğini, fiyatlarını değiştirebileceğini, emirleri
          gerçekleştirmeyebileceğini veya müşteri hesaplarını işleme
          kapatabileceğini kabul ve beyan eder.
        </p>
        <p>
          <b>8.4. </b> ŞİRKET, işlemlerin ve/veya işlemlerin iletildiği
          Platformu, platformun altyapısı özelliğinin, kripto varlık çiftlerinin
          özelliklerinin, fiyat verilerinin istismarına yönelik işlem yapan
          KULLANICI’nın işlemlerini iptal etme ve/veya KULLANICI hesabını
          kapatma hakkını saklı tutar. KULLANICI’nın bu tarz işlemlerinden
          dolayı ŞİRKET’in doğrudan veya dolaylı olarak zarara uğraması hâlinde;
          uğrayacağı her türlü zararın tazminini KULLANICI’dan talep etme
          hakkına sahiptir.
        </p>
        <p>
          Platformda, emirlerin gerçekleşmesini ve/veya fiyat akışını
          yavaşlatan, geciktiren ve engelleyen ya da bunlarla sınırlı olmamak
          kaydıyla sebebi ne olursa olsun yaşanan teknik sorunlardan haksız
          kazanç sağlayan veya yatırımcılar arasında haksız rekabete yol açan
          her türlü faaliyet işlem istismarı olarak kabul edilir. KULLANICI’nın
          doğrudan işlem istismarı faaliyetinde bulunmadığı ancak bundan fayda
          sağladığı durumlarda da ŞİRKET işlem istismarı kapsamında
          KULLANICI’nın işlemlerini iptal etme ve/veya hesabını kapatma hakkını
          saklı tutar.
        </p>
        <p>
          <b>8.5. </b> KULLANICI tarafından Platforma iletilen emirlerin veya
          nakit ya da kripto varlık transfer talimatlarının gerçekleşip
          gerçekleşmediğinin, hesabında oluşan kar veya zararın, takibi
          KULLANICI’nın sorumluluğundadır.
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Kullanıcının Yükümlülükleri</strong>
        </p>
        <p>
          <b>9.1. </b>KULLANICI hesap açılışı sırasında ve iş ilişkisinin devamı
          süresince ŞİRKET’e vereceği bilgilerin doğru, kesin, güncel ve
          eksiksiz olduğunu, kimliğini herhangi bir amaçla gizlemediğini,
          bilgilerde değişiklik olması halinde bilgileri ivedilikle ancak en geç
          5 işgünü içerisinde güncelleyeceğini, aksi takdirde yaptığı işlemlerde
          değişiklik öncesi bilgilerin esas alacağını kabul, beyan ve taahhüt
          eder.
        </p>
        <p>
          <b>9.2. </b>KULLANICI’nın hesap açılışı sırasında kimlik ve adres
          teyidi amacıyla tevdi etmiş olduğu evrakın, bilgi ve belgelerin sahte
          olması ya da üzerinde tahrifat yapılmış olması, hile yapması, yanlış,
          yanlış ve yanıltıcı bilgiler vermesi gibi durumlarda kendisine
          atfedilemeyecek kusurlardan dolayı ŞİRKET sorumlu tutulamaz. KULLANICI
          bu konulara ilişkin oluşabilecek maddi ve manevi her türlü sorumluluk
          ve zararın kendisine ait olduğunu kabul eder.
        </p>
        <p>
          <b>9.3. </b>Müşterek hesaplar hariç, KULLANICI kendi adına açılmış
          olan hesaplarda kendi adı ve hesabına işlem gerçekleştireceğini,
          hesabın gerçek faydalanıcısının kendisi olduğunu, hesabında kendi
          malvarlığı dışında başkaları adına da ya da borçlanma dışında
          başkalarından temin etmiş olduğu nakit veya kripto varlıklar ile
          portföy yöneticiliği olarak nitelendirebileceği işlemlerde
          bulunmayacağını, hesabında gerçekleştirdiği işlemlere dayalı olarak
          başkalarından doğrudan veya dolaylı olarak komisyon, ücret veya
          benzeri ad altında herhangi bir tutarı tahsil etmeyeceğini, ŞİRKET
          tarafından sunulan veya benzeri nitelikteki hizmetlerde bulunmak
          amacıyla mekan, teknik donanım, personel ve benzeri organizasyonlar
          oluşturmayacağını; yasaklanan bu eylemlerde bulunması halinde ortaya
          çıkabilecek tüm cezai ve hukuki sorumluluğun kendisine ait olacağını;
          ŞİRKET’in bu eylemler nedeniyle para cezası, tazminat veya her ne nam
          altında olursa olsun herhangi bir şekilde zarar veya ziyan görmesi
          halinde ŞİRKET’in ödemekle yükümlü kalacağı tutarı ŞİRKET’in yazılı
          talebi üzerine başkaca bir işleme ya da mahkeme kararına gerek
          olmaksızın defaetle karşılayacağını beyan ve taahhüt eder.
        </p>
        <p>
          <b>9.4. </b> KULLANICI kendi veya kontrolünde bulunan başka hesaplar
          üzerinden sosyal medya, yazılı veya görsel basın veya herhangi bir
          mecrada ŞİRKET’in adını içerecek şekilde ya da Platformda yer alan
          bilgi, belge, analiz, broşür, bülten veya her ne ad altında olursa
          olsun içeriği, kripto varlıklara ilişkin paylaşımda bulunmayacağını;
          ŞİRKET ile ilgisi, acentesi, çalışanı olduğu şeklinde veya bu şekilde
          anlaşılabilecek ifadelere yer vermeyeceğini; bu içeriği kullanarak
          yatırım danışmanlığı faaliyet kapsamında sayılabilecek yönlendirici
          nitelikte tavsiyelerde bulunmayacağını beyan ve taahhüt eder. Aksi
          yöndeki davranışı nedeniyle ortaya çıkabilecek tüm cezai ve hukuki
          sorumluluğun kendisine ait olacağını; ŞİRKET’in bu eylemler nedeniyle
          para cezası, tazminat veya her ne nam altında olursa olsun herhangi
          bir şekilde zarar veya ziyan görmesi halinde ŞİRKET’in ödemekle
          yükümlü kalacağı tutarı ŞİRKET’in yazılı talebi üzerine başkaca bir
          işleme ya da mahkeme kararına gerek olmaksızın defaetle
          karşılayacağını beyan ve taahhüt eder.
        </p>
        <p>
          <b>9.5. </b> KULLANICI ŞİRKET’in yazılı onayı olmaksızın hesabında
          bulunan varlıkları üzerinde herhangi bir kişiye teminat hakkı
          tanımayacaktır. Bu yönde bir teminat hakkının tanınmış olması hukuken
          geçerli olmayacaktır.
        </p>
        <p>
          <b>9.6. </b> Hesaplarının güvenliği KULLANICI sorumluluğundadır.
          ŞİRKET tarafından önerilen güvenlik önlemleri tavsiye niteliğindedir.
          Güvenlik önemleri alınmış olmasına rağmen, KULLANICI’nın doğmuş veya
          doğacak olan zararlarından ŞİRKET hiçbir şekilde sorumlu değildir.
          TARAFLAR, KULLANICI hesaplarının güvenliği için kendilerine düşen
          azami özeni göstermekle yükümlüdür. ŞİRKET, tüm çabalarına rağmen
          oluşabilecek hırsızlık / dolandırıcılık olaylarından hiçbir şekilde
          sorumluluk kabul etmez.
        </p>
        <p>
          <b>9.7. </b> KULLANICI hesap açılışı sırasında beyan etmiş olduğu
          telefon ve elektronik posta adresini ulaşılabilir şekilde tutmakla
          yükümlüdür. ŞİRKET’in, bu telefon ve elektronik posta adresine
          yapacağı bildirimlerin KULLANICI’ya tebliğ edildiği kabul edilir.
          KULLANICI mesajın kendisine ulaşmadığını iddiası ile ŞİRKET’e herhangi
          bir sorumluluk yükleyemez. Hesabın bütün kontrolü KULLANICI’nın
          sorumluluğundadır. ŞİRKET hesapta oluşan kar / zarar ve gerçekleşen
          işlemlerin takibi amacıyla gerekli önlemleri alır.
        </p>
        <p>
          <b>9.8. </b> KULLANICI, hesabını üçüncü şahıslara devredemez, satamaz,
          bağışlayamaz veya her ne gerekçe ile olursa olsun üçüncü kişilere
          kullandıramaz. Bu yasağa aykırı hareketlerin tespiti halinde KULLANICI
          hesabının önceden hiçbir bildirime ihtiyaç olmaksızın iptal edilmesi,
          durdurulması veya askıya alınmasını kabul ettiğini beyan ve taahhüt
          eder. Bu nedenle meydana gelen tüm zararlardan KULLANICI sorumlu olup
          tüm cezai müeyyideler de KULLANICI’ya aittir. Bu maddeye aykırı
          eylemleri nedeniyle KULLANICI hesabının iptali, durdurulması veya
          askıya alınması durumunda kullanıcının kripto para varlıklarının
          satışına izin verilmez, bu varlıklar KULLANICI tarafından bildirilecek
          cüzdana gönderilir.
        </p>
        <p>
          <b>9.9. </b> KULLANICI kendisine fazla yapılan ödeme ve/veya
          transferleri 2 (iki) iş günü içerisinde iade eder. İade edilmemesi
          durumunda herhangi bir ihtara veya başkaca bir hukuki işleme gerek
          olmaksızın ŞİRKET yapılan sebepsiz ödeme ve/veya transferleri geri
          alma, KULLANICI’nın bu yöndeki taleplerini reddetme hakkına sahiptir.
        </p>
        <p>
          <b>9.10. </b> KULLANICI, Platformda yer alan, şifreli veya şifresiz
          giriş yapılabilen sayfalardaki duyuru, bilgi, ücret, değişiklik,
          kampanya ve tarifeler gibi bilcümle bildirimleri takip edeceğini,
          bunları bilmemesi nedeniyle uğrayabileceği zararlardan dolayı
          ŞİRKET’in sorumlu olmayacağını kabul ve beyan eder.
        </p>
        <p>
          <b>9.11. </b> KULLANICI, Platformun kendisi veya kendisi ile
          ilişkilendirilebilecek üçüncü kişiler tarafından kötüye
          kullanılmasından dolayı ortaya çıkan bütün zarar ve ziyandan sorumlu
          olduğunu ve tazmin edeceğini; bu yöndeki bütün itiraz ve def’ilerden
          feragat ettiğini gayrikabili rücu kabul, beyan ve taahhüt eder.
        </p>

        <p>
          KKULLANICI, işbu Sözleşmedeki iş ve işlemlerden kaynaklanan borçlarını
          zamanında ve tamamen ödemediği takdirde ŞİRKET’in uğrayacağı her türlü
          kayıptan, hasardan, masraftan, harcamadan, zarardan veya ziyandan
          sorumlu olacağını kabul ve taahhüt eder.
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Nakit Yatırma / Çekme İşlemleri</strong>
        </p>
        <p>
          <b>10.1. </b>KULLANICI hesabına ancak kendi adına açılmış olan bir
          banka hesabından nakit transferi yapabilir. KULLANICI hesabına üçüncü
          kişilerden gelen nakit yatırma talepleri kabul edilmez. Nakit yatırma
          işlemlerinde bankacılık sisteminden kaynaklanan gecikmeler nedeniyle
          ŞİRKET sorumluluk kabul etmez.
        </p>
        <p>
          <b>10.2. </b>KULLANICI’nın nakit çekme talebinde bulunabilmesi için
          kendi kullanıcı kodu ve şifresi ile giriş yapacağı Platformda yer alan
          nakit çekme talebinin oluşturulmuş veya talebin yazılı olarak ŞİRKET’e
          iletilmiş olması gerekir. ŞİRKET para çekme taleplerini en geç 2 (iki)
          işgünü içerisinde tam ve nakden yerine getirir. Nakit transfer
          talepleri ancak KULLANICI adına açılmış olan Türkiye’de yerleşik banka
          hesaplarına yapılır, üçüncü bir kişi hesabına yapılmaz. Nakit çekme
          taleplerine ilişkin usul ve esaslar Platformda yer alır. ŞİRKET bu
          usul ve esaslar değişiklik yapma hakkını saklı tutar. KULLANICI hesap
          açılışı ile Platformda yer alan usul ve esaslar ile bunlarda yapılacak
          değişiklikleri kabul etmiş sayılır.
        </p>
        <p>
          KULLANICI’nın ABD Doları veya diğer para cinsleri üzerinden nakit
          çekme talebi oluşturması durumunda haricinde farklı bir para birimi
          cinsinden çekmek istemesi halinde, bu varlıklar bankalar arası satış
          kuru üzerinden ilgili para birimi cinsine çevrilerek ödeme yapılır.
          Yabancı para cinsleri üzerinden yapılacak nakit çekme işlemlerinde
          bankacılık sisteminden kaynaklanan gecikmeler nedeniyle ŞİRKET hiçbir
          sorumluluk kabul etmez. ŞİRKET gerçekleşen işlemlere ilişkin olarak
          nakit yükümlülüklerinin kredi veya banka kartı ile ödenmesi konusunda
          karar verebilir. ŞİRKET, Platformda yer alan nakit ya da kripto varlık
          yatırma ve nakit çekme işlemlerine ilişkin usul ve esaslara aykırı ya
          da şüphelenilen işlemleri reddetme; aykırılığın veya şüphenin transfer
          / ödeme işlemlerinin gerçekleşmesinden sonra tespit edilmesi veya
          ortaya çıkması halinde ise transfer/ödemeleri iade etme veya söz
          konusu tutarlar ile iletilecek emirleri kabul etmeme hakkına sahiptir.
          KULLANICI, ŞİRKET tarafından reddedilen veya gerçekleştirilmeyen
          transfer/ödemelerin iadesi için kimlik ibrazı ve transfer/ödeme
          gerçekleştiren kişinin banka hesabı bilgisini isteyebileceğini, bu
          bilgilerin tevdi edilmemesi durumunda işlemin gerçekleştirilmeyeceğini
          kabul eder.
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Ücret ve Komisyonlar</strong>
        </p>
        <p>
          <b>11.1. </b>Platforma gerçekleştirilen işlemler karşılığında tahsil
          edilecek komisyon, ücret, faiz, maliyet ya da her ne nam altında
          olursa olsun KULLANICI’nın ödemekle yükümlü olacağı tüm gider ve
          masraflar Platforma vasıtası ile ilan edilecektir. KULLANICI işbu
          Sözleşmeyi kabul ile Platform nezdinde hesap açması durumunda tüm
          gider ve masrafları kabul ettiğini beyan eder.
        </p>
        <p>
          <b>11.2. </b>ŞİRKET Platform vasıtası ile ilan etmek kaydıyla gider ve
          masraflarda değişiklik yapabilir. Değişiklikler Platformda ilan
          edildikten sonra 5 işgünü içerisinde uygulanır. KULLANICI’nın ücret,
          gider ve masrafa tarifelerinde yapılan değişiklik nedeniyle Sözleşmeyi
          feshetme hakkı saklıdır.
        </p>
        <p>
          <b>11.3. </b>Sözleşme kapsamında yer alan iş ve işlemler nedeniyle
          ortaya çıkan her türlü vergi, harç, masraf ve hizmet bedelleri, hesap
          işletim gideri, hesap ekstresi ve diğer bildirimlere ilişkin posta ve
          noter ücretleri, EFT ve diğer transfer masrafları ile diğer ücretler,
          her ne ad altında olursa olsun kamuya ödenen tutarlar ile üçüncü
          kişilere ödenen diğer gider ve hizmet bedelleri KULLANICI tarafından
          ödenir.
        </p>
        <p>
          <b>11.4. </b>Mahiyeti ne olursa olsun masraf, vergi, fon ve yükümlerin
          ve sair her türlü yükümlülüklerin ödenmesi ve sorumluluğu ister
          Türkiye içinde ister Türkiye dışında olsun KULLANICI’ya aittir.
          KULLANICI, ŞİRKET’in vergi, resim, harç, fon, damga vergisi, komisyon,
          posta masrafı, kendisine ait olan muamele ücreti ve kendi namına
          yapılan bütün masraflar için hesabını borçlandırmaya veya tahsile
          yetkili olduğunu kabul eder.
        </p>
        <p>
          <b>11.5. </b>ŞİRKET bu madde kapsamındaki ücret, gider ve bilumum
          masrafları KULLANICI’nın hesabından ihbara gerek olmaksızın mahsup
          etme veya hesabını borçlandırmaya yetkilidir.
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Gizlilik</strong>
        </p>
        <p>
          <b>12.1.</b> KULLANICI, ŞİRKET’in ürünlerini ve hizmetlerini
          Sözleşmede yazılan amaçlar dışında kullanamaz.
        </p>
        <p>
          <b>12.2.</b> KULLANICI, Platform ve diğer ürünler ile hizmetlerin
          ticari sır niteliğinde olduğunu ve bunların profesyonel emek, zaman ve
          para harcanarak geliştirilmiş olduklarını bildiğini beyan eder.
          KULLANICI, ŞİRKET'in fikri mülkiyetinde bulunan bu ticari sırları
          yayınlamayacak, dağıtmayacak veya böyle bir bilgiyi üçüncü kişilere
          sağlamayacaktır. KULLANICI, hiçbir şekilde Platform ve diğer ürün ve
          hizmetlerini kopya etmeyecek, ayrıştırmayacak bu amaçlara hizmet
          edecek çalışmalar yapmayacaktır.
        </p>
        <p>
          <b>12.3.</b> KULLANICI, yasal olarak zorunlu olan ya da yetkili idari
          merciler veya yargı makamları tarafından talep edildiği haller
          dışında; işbu Sözleşme çerçevesinde gerçekleştirilecek olan işlemler
          ile ilgili olarak ŞİRKET’in işleri, diğer kullanıcılar, iç işleyişi ve
          diğer her türlü faaliyetleri konusunda her ne şekilde olursa olsun
          öğreneceği her türlü bilgi ve belgeleri gizli tutacak ve söz konusu
          bilgi ve belgelere ilişkin olarak kanunen görevli ve yetkili personel
          dışında hiçbir kimseye herhangi bir açıklamada bulunmayacaktır. Aksi
          halde, ŞİRKET’in bu nedenle uğrayacağı doğrudan veya dolaylı zararları
          tazminle yükümlüdür.
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Kişisel Verilen Korunması </strong>
        </p>
        <p>
          <b>13.1. </b>ŞİRKET, elde edeceği KULLANICI’ya ait kişisel verileri,
          kişisel verilerin işleme amacına ve hukuka aykırı şekilde
          işlemeyeceğini, yasal istisnalar dışında KULLANICI’nın rızası
          olmaksızın üçüncü kişiler ile paylaşmayacağını, kişisel verilerin
          hukuka aykırı işlenmesi ile erişilmesinin engellenmesi amacıyla
          gerekli idari ve teknik önlemleri alacağını ve bu verileri güvenli
          ortamda saklayacağını kabul ve taahhüt eder.
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Bildirimler</strong>
        </p>
        <p>
          <b>14.1. </b>TARAFLAR, işbu Sözleşmede yer verilen adreslerinin yasal
          tebligat adresleri olduğunu ve belirtilen telefon ve/veya faks
          numaraları ile elektronik posta adreslerinin hâlen kullanımda olan
          geçerli ve güncel numara ve adresler olduğunu; söz konusu adres veya
          numaralarda meydana gelecek bir değişikliği yazılı olarak diğer tarafa
          bildirmeyi ve değişiklik diğer tarafa bildirilmediği takdirde burada
          belirtilen adres, telefon/faks numaraları veya elektronik posta
          adreslerine yapılacak tebligatların hukuken geçerli bir tebligatın tüm
          hüküm ve sonuçlarını doğuracağını kabul ve taahhüt ederler.
        </p>
        <p>
          <b>14.2. </b> İşbu Sözleşmenin diğer bir maddesine konu olmayan ihbar,
          tebligat ve diğer bildirimler TARAFLAR’ın iş bu Sözleşmede belirtilen
          adreslerinden herhangi birine iadeli taahhütlü mektupla veya kurye ile
          elden teslim yoluyla veya KULLANICI tarafından bildirilen elektronik
          posta adresine elektronik posta gönderilmesi suretiyle yapılacaktır.
        </p>
        <p>
          <b>14.3. </b> Bu madde hükmü, Kullanım Sözleşmesi’nin herhangi bir
          şekilde sona ermesinden sonra da uygulanmaya devam eder. Bu hükme
          aykırılık nedeniyle ortaya çıkacak zarar ve kayıplardan ya da maddi ve
          manevi tazminattan, aykırılığı gerçekleştirilen TARAF sorumludur
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Sözleşmenin Tadili</strong>
        </p>
        <p>
          <b>15.1. </b>Sözleşme hükümleri, değişikliklerin KULLANICI’ya
          tebliğinden itibaren 2 iş günü sonra yürürlüğe girmek ve KULLANICI’nın
          bu nedenle Sözleşmeyi feshetme hakkı saklı kalmak kaydıyla; ŞİRKET
          tarafından tek taraflı olarak değiştirilebilir. KULLANICI tarafından
          bildirimin alındığı tarihten itibaren 2 iş günü içerisinde yazılı
          olarak itiraz edilmediği takdirde; değişiklik TARAFLAR arasındaki
          işlemlerde geçerli olur. KULLANICI, sözleşmede yapılacak
          değişikliklerin elektronik ortamda kendisine bildirilebileceğini ve
          değişikliğin kendisi tarafından elektronik ortam üzerinden kabul
          edilmesi suretiyle yürürlüğe gireceğini kabul eder.
        </p>
        <p>
          <b>15.2. </b>KULLANICI, ŞİRKET tarafından yapılan değişikliklere
          itiraz ettiği takdirde işbu Sözleşme sona erer. Bu durumda,
          KULLANICI’nın nakdi kendi adına açılmış bir banka hesabına kripto
          varlıkları ise beyan edeceği cüzdan adresine transfer edilir.
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Sözleşmenin Feshi</strong>
        </p>
        <p><b>16.1.</b> Sözleşme süresizdir</p>
        <p>
          <b>16.2.</b> TARAFLAR'dan her biri, 3 (üç) işgünü önceden (Noter
          vasıtasıyla veya iadeli taahhütlü mektupla) ihbarda bulunmak kaydıyla;
          işbu Sözleşmeyi tek taraflı olarak feshetmeye yetkilidir.
        </p>
        <p>
          <b>16.3.</b> Sözleşmenin taraflardan herhangi birince feshedilmesi
          durumunda ŞİRKET’in işbu Sözleşmeden doğmuş ve doğacak her nevi
          alacağı, KULLANICI tarafından işbu sözleşme hükümleri çerçevesinde
          ödenecektir.
        </p>
        <p>
          <b>16.4.</b> Sözleşmenin feshi halinde fesih tarihinden itibaren 2
          (iki) işgünü içinde KULLANICI’nın nakdi kendi adına açılmış bir banka
          hesabına kripto varlıkları ise beyan edeceği cüzdan adresine transfer
          edilir.
        </p>
        <p>
          <b>16.5.</b> ŞİRKET, aşağıdaki hallerin varlığı halinde, sözleşmeyi
          tek taraflı olarak feshetme yetkisine sahiptir:
        </p>
        <p>
          <b>a)</b> Ödemede temerrüt hali: KULLANICI’nın, Sözleşme çerçevesinde
          girmiş olduğu herhangi bir işlemle ilgili olarak ödemekle yükümlü
          olduğu borçlarından herhangi birisini ifada temerrüde düşmesi ve bu
          temerrüt halinin, kendisine gönderilen ödeme ihtarında yer alan süre
          içerisinde ortadan kaldırılmaması,
        </p>
        <p>
          <b>b)</b> Sözleşmeye aykırılık: KULLANICI’nın, bu Sözleşme’de ayrıca
          belirlenmiş ve özel olarak düzenlenmiş durumlar saklı kalmak üzere,
          Sözleşme ile üstlenmiş olduğu yükümlülüklere ve beyan etmiş olduğu
          taahhütlerine aykırı davranması halinde, kendisine ihtar edilmesi ve
          ihtarın ulaşmasından itibaren 2 (iki) iş günü içerisinde aykırılığın
          giderilmemesi,
        </p>
        <p>
          <b>c)</b> ŞİRKET’in yasal hakları saklı kalmak kaydıyla, KULLANICI’nın
          Sözleşme çerçevesinde vermiş olduğu bilgi ve yapmış olduğu beyanlardan
          herhangi birisinin gerçeğe aykırı olduğunun tespit edilmesi ve bu
          aykırılığın Sözleşme’nin devamına imkân vermeyecek bir aykırılık
          olması,
        </p>
        <p>
          <b>d)</b> İflas veya aciz hali: KULLANICI aleyhinde iflas başvurusunda
          bulunulması, kayyum idaresine girmesi, konkordato ilanı, iflas kararı
          verilmesi gibi KULLANICI’nın aciz haline girmesini doğuran durumlar
          ile KULLANICI’nın aciz halinde olduğuna veya yakın bir gelecekte
          olabileceğine ilişkin başkaca ciddi emarelerin ve duyumların olması,
        </p>
        <p>
          <b>e)</b> KULLANICI hesabında başkası adına işlem
          gerçekleştirildiğinin, portföy yöneticiliği veya yatırım danışmanlığı
          faaliyetlerinde bulunulduğunun, suçtan elde edilen gelirlerinin
          aklanması ve terörizmin finansmanının önlenmesi düzenlemelerine aykırı
          davranıldığının ve bu nitelikteki eylemlerde bulunulduğunun,
          Platformun etkin işlemlerini engelleyecek veya sekteye uğratacak
          işlemlerde bulunulduğunun, diğer kullanıcıların emirlerinin ve işlem
          gerçekleştirmelerinin engellenmesi veya buna neden olabilecek
          fiillerde bulunulduğunun; ŞİRKET çalışanlarına karşı kaba, ahlaksızlık
          olarak nitelendirilebilecek davranış ve söylemlerde bulunulduğunun, bu
          sayılanlarla sınırlı olmamak üzere işbu Sözleşme ile yasaklanan iş,
          işlem ve eylemlerde bulunulduğunun tespiti veya bulunulduğu yönünde
          makul şüphenin varlığı,
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Kayıtların Delil Niteliği</strong>
        </p>
        <p>
          Bu sözleşmeden ve sözleşme çerçevesinde gerçekleştirilen işlemlerden
          doğabilecek anlaşmazlık ve uyuşmazlıklarda ŞİRKET defter, kayıt ve
          belgeleri Hukuk Muhakemeleri Kanunu’nun 193 üncü maddesi anlamında
          muteber ve geçerli delil niteliğindedir. KULLANICI, ŞİRKET yetkilileri
          ve personeli ile yapacağı telefon konuşmalarının kaydedilebileceğini
          ve bu kayıtların “Kullanıcı mutabakatını içeren kayıt” niteliğinde
          olduğunu ve münhasır delil niteliği taşıyacağını kabul ve beyan eder.
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Hapis Hakkı</strong>
        </p>
        <p>
          <b>18.1. </b>ŞİRKET, Sözleşmeden kaynaklanan doğmuş ve doğacak
          alacakları ile gerçekleşmiş ya da muhtemel her tür zararlarının
          teminatı olarak, borcun ya da zararın doğum tarihinden itibaren
          KULLANICI hesabında bulunan varlıklar üzerinde, borç ödeninceye ya da
          zarar nakit olarak karşılanıncaya kadar Medeni Kanun’un 950 ve
          devamındaki maddeler çerçevesinde hapis hakkına sahiptir.
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Hukuki ve Cezai Sorumluluk</strong>
        </p>
        <p>
          <b>19.1. </b>ŞİRKET, ŞİRKET ortakları, yönetim kurulu üyeleri,
          yöneticileri, müdürleri ve çalışanları ile Platformda yer alan bilgi,
          belge ve dokümanları hazırlayanlar hakkında, KULLANICI hatasından
          doğan veya doğacak olan her türlü zarar, ziyan ve/veya kayıplardan
          dolayı hiçbir şekilde hukuki ve cezai sorumluluğu ileri sürülemez.
        </p>
        <p>
          <b>19.2. </b>KULLANICI’ya kullanıcı kodu ve şifre ile gerçekleştirilen
          işlem ve eylemler nedeniyle ortaya çıkacak hukuki ve cezai sorumluluk
          tamamen ve sadece KULLANICI’ya aittir. Bu işlemlerde ŞİRKET’in hiçbir
          hukuki ya da cezai sorumluluğu yoktur. KULLANICI tarafından Platformda
          gerçekleştirilen işlemlerin hukuka uygun olduğu kabul edilir.
          KULLANICI’nın hukuka aykırı işlem yaptığının tespit edilmesi ya da bu
          konuda makul şüphenin varlığı halinde, ŞİRKET her türlü hukuki yola
          başvurma ve hesabın dondurulması, iptal edilmesi ya da sözleşmenin
          feshedilmesi gibi gerekli her türlü idari ve güvenlik tedbirlerini
          alabilir. Bu tedbirleri almamış olması vazgeçtiği anlamına gelmez.
        </p>
        <p>
          <b>19.3. </b>Kripto varlıkların kullanımıyla ilgili yasal
          sorumluluklar ile vergi mükellefiyetinden KULLANICI bizzat sorumludur.
          KULLANICI’nın kazanç ve kayıplarından oluşan vergisel
          mükellefiyetlerinden ŞİRKET sorumlu tutulamaz.
        </p>
        <p>
          <b>19.4. </b>Kripto varlıkların hangi amaçla alım / satıma konu
          edildikleri, nereye ve ne amaçla transfer edildiği, bir suçta veya
          suçun örtülmesi için kullanılıp kullanılmadığı konularının takibi
          teknik olarak mümkün olmayabilir. Kripto varlıkların hukuka ve bu
          Sözleşmeye aykırı olarak, kötü amaçlarda kullanılmasından doğan ya da
          doğacak her türlü hukuki ve cezai sorumluluk KULLANICI’ya aittir. Bu
          nitelikteki eylemler nedeniyle KULLANICI veya üçüncü kişilerin zarar
          ve kayıpları ile menfi ya da müspet zararlarından ŞİRKET sorumlu
          değildir.
        </p>
        <p>
          <b>19.5. </b> ŞİRKET, KULLANICI hesaplarında bulunan kripto
          varlıkların güvenliğinin temininde azami özeni gösterir. Platform
          nezdindeki kripto varlıklar herhangi bir siber saldırıya karşı, kısmen
          veya tamamen internet bağlantısı olmayan ortamlarda soğuk depolama
          yöntemiyle saklanır. Kendisine objektif kriterler çerçevesinde
          yüklenebilecek kusur dışında, dolandırıcılık, hırsızlık ve siber
          saldırı olaylarından ve bu olaylar nedeniyle oluşabilecek zarar ve
          ziyandan dolayı ŞİRKET sorumlu değildir.
        </p>
        <p>
          <b>19.6. </b> ŞİRKET kontrolü dışındaki teknik arıza ve hatalardan
          kaynaklanabilecek KULLANICI zararlarından, kendisine atfı kabil kusuru
          dışında hiçbir şekilde sorumlu tutulamaz ve kendisine yönelik hak
          talebinde bulunulamaz. ŞİRKET teknik arıza veya hata durumunda sistemi
          düzeltmek ve doğru çalışmasını sağlamak için yapılan işlemleri iptal
          edebilir veya geri alabilir; iptal ve geri alma sırasında KULLANICI
          hesaplarını dondurabilir; herhangi haksız bir ödeme yapılmış olması
          halinde bu ödemenin iadesini isteyebilir; iade edilmemesi halinde
          hapis hakkını kullanabilir, bunlarla sınırlı olmaksızın uygun göreceği
          diğer her türlü tedbire ve her türlü hukuki yola başvurabilir.
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Mücbir Sebep</strong>
        </p>
        <p>
          <b>20.1. </b>KULLANICI, ŞİRKET’in Türkiye’de ve/veya işlemlerin
          gerçekleştirildiği ülkelerde meydana gelebilecek ekonomik, sosyal veya
          politik karışıklıklar, ŞİRKET faaliyetlerinin önemli ölçüde
          etkileyebilecek bölgesel ve/veya küresel savaşlar, ulusal, bölgesel
          veya global ekonomik krizler ve dalgalanmalar, Türkiye’nin veya diğer
          ülkelerin kripto varlık ya da kambiyo rejimlerine, emtia ve kıymetli
          maden piyasalarına getirebilecekleri kısıtlamalar, doğal afetler ve
          olağandışı piyasa koşulları gibi nedenlerle veya Türkiye’de ve diğer
          ülkelerde yapılabilecek yasal düzenlemeler, kamu otoritelerinin
          tasarrufları ya da bunlardan farklı olarak önceden öngörülmesi ve
          objektif kriterler çerçevesinde üstesinden gelinmesi mümkün olmayan
          diğer olaylar ya da sebepler nedeniyle, işbu Sözleşmeden kaynaklanan
          yükümlülüklerini yerine getiremeyebileceğini, yükümlülüklerini bu
          gerekçelerle yerine getirememesi durumunda KULLANICI’ya karşı herhangi
          bir sorumluluğunun olmayacağını kabul ve beyan eder.
        </p>
        <p>
          <b>20.2. </b>Mücbir sebebin varlığı halinde ŞİRKET herhangi bir
          bildirimde bulunmasına gerek olmaksızın; KULLANICI’nın Platformda
          bulunan gerçekleşmemiş emirlerini iptal edebilir; Sözleşmede yer alan
          yükümlülüklerin yerine getirilmesini askıya alabilir veya
          değiştirebilir, kripto varlık bazından veya tüm varlıklar için
          Platformdaki işlem koşullarını değiştirebilir, emir kabulünü
          durdurabilir, nakit veya kripto varlık transfer ve ödemelerini
          reddedebilir ya da uygun göreceği diğer tedbirleri alabilir. KULLANICI
          bu madde kapsamında mücbir sebebe dayalı olarak tedbir alınması
          nedeniyle işlemlerin iptali ve emir kabulünün durdurulması başta olmak
          üzere ortaya çıkacak hiçbir zarar, kayıp, masraf ve gider nedeniyle
          ŞİRKET’e sorumluluk atfetmeyeceğini, tazminat veya her ne nam altında
          olursa olsun talepte bulunmayacağını kabul, beyan ve taahhüt eder.
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Muacceliyet</strong>
        </p>
        <p>
          <b>21.1. </b>Aşağıda belirtilen hallerin varlığı halinde KULLANICI’nın
          Platformda gerçekleştirilen işlemler nedeniyle varsa oluşan borcu
          muaccel hale gelir ve KULLANICI başka bir ihbara gerek kalmaksızın
          kendiliğinden temerrüde düşmüş sayılır:
        </p>
        <p>
          <b>a. </b>Müşteri'nin komisyon, faiz ve sair her türlü borcu zamanında
          ödeyememesi veya işbu Sözleşmede belirtilen diğer yükümlülüklerini
          yerine getirememesi
        </p>
        <p>
          <b>b. </b>Yetkili otoriteler, mahkemeler ve icra daireleri tarafından
          KULLANICI aleyhine hesap hareketlerini kısıtlayıcı nitelikte bir karar
          verilmesi,
        </p>
        <p><b>c. </b>KULLANICI’nın iflas etmesi veya konkordatoya gitmesi,</p>
        <p>
          <b>d. </b>Sözleşmenin feshedilmesine neden olacak fiillerde
          bulunulması
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Bölünebilirlik</strong>
        </p>
        <p>
          <b>22.1. </b>İşbu Sözleşme’nin herhangi bir hükmü, maddesi veya
          koşulunun herhangi bir zamanda ve herhangi bir sebeple tamamen veya
          kısmen geçersiz, yasadışı veya ifa edilemez olması veya sayılması
          halinde, bu durum, diğer hükümlerin, maddelerin veya koşulların
          geçerliliğini ve ifa kabiliyetini kesinlikle etkilemeyecek veya
          bozmayacak ve diğer hükümler, maddeler veya koşullar tam olarak
          yürürlükte kalacaktır. Bu durumda, TARAFLAR, kısmen veya tamamen
          geçersiz, yasadışı veya ifa edilemez olan veya sayılan hükümleri,
          TARAFLAR üzerinde aynı ekonomik ve hukuki etkilere sahip yeni, geçerli
          ve ifa edilebilir hükümlerle değiştirmek için iyi niyetle müzakerede
          bulunacaklardır.
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Uygulanacak Hukuk ve Uyuşmazlıkların Çözümü</strong>
        </p>
        <p>
          <b>23.1. </b>Bu Sözleşme, Türkiye Cumhuriyeti kanunlarına tâbidir.
        </p>
        <p>
          <b>23.2. </b>Bu Sözleşmesinin uygulanması veya yorumlanmasından
          kaynaklanan uyuşmazlıkların çözümlenmesinde İstanbul Çağlayan Adliyesi
          Mahkemeleri ve İcra Daireleri yetkilidir.
        </p>
        <p>
          <b>23.3. </b>Bu Sözleşmede hüküm bulunmayan hallerde genel hükümler
          uygulanır.
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>Yürürlük</strong>
        </p>
        <p>
          <b>24.1. </b>KULLANICI'nın Platforma üye olmak amacıyla
          www.bitistanbul.com adresinde bulunan “Kullanıcı Sözleşmesi ve
          Şartlarını” elektronik ortamda onaylaması ile Sözleşme imzalanmış ve
          yürürlüğe girmiş sayılır. KULLANICI, internet üzerinden yapacağı
          onaylama ile Sözleşme’nin tamamını okuduğu, anladığını ve tüm
          maddeleri kabul ettiğini beyan ve taahhüt eder.
        </p>
      </div>
      <div class="row style-content">
        <p>
          <strong>RİSK BİLDİRİMİ</strong>
        </p>
        <p>
          Sayın Müşterimiz, Finans piyasalarında tecrübe sahibi profesyoneller
          tarafından kurulan ve yönetilen Hotto Kripto Varlık Alım Satım Platformu A.Ş.
          (Bitİstanbul) nezdinde, uluslararası en iyi uygulamalar ve güvenlik
          standartları kapsamında tamamen kendi yazılımcıları ile oluşturduğu
          kripto varlık alım satım platformunda (Platform) işlemlere başlamadan
          önce bazı risklerin sizlerle paylaşılmasının yatırım kararlarınız
          açısından önemli olduğu düşünülmektedir.
        </p>
        <p>
          Önemli gördüğümüz riskler aşağıda verilmekle birlikte sayılan
          hususların tüm riskleri içermeyebileceğini, burada sayılan risklerden
          başka risklerle de karşılaşma ihtimalinizin bulunduğunu hatırlatmak
          isteriz. Platformda alım – satım işlemlerine başlamadan önce bu metni
          dikkatle okumanız, muhtemel riskler konusunda bilgi sahibi olmanız ve
          özellikle anlamanız, gerekmesi halinde Bitİstanbul, avukatınız,
          yatırım danışmanınız ya da başka bir profesyonelden destek ve detaylı
          bilgi almanız uygun olacaktır.
        </p>

        <p>
          <b>1. </b> Kripto varlıklar çok ciddi fiyat ve piyasa riski içerir.
          Fiyat değişiklikleri önemli seviyede, ani, önceden tahmin edilemeyecek
          şekilde ve öngörüde bulunmaya imkân sağlayacak herhangi bir belirti
          göstermeksizin gerçekleşebilir. Fiyatların beklendiğiniz şekilde
          gelişmemesi durumunda, tüm malvarlığınızın kaybı dahil ciddi mali
          kayıplarla karşı karşıya kalabilirsiniz. Fiyatların geçmişte göstermiş
          oldukları performans ve getiriler, gelecekteki fiyatlar ya da
          getirilerin güvenilir bir göstergesi olmayabilir.
        </p>
        <p>
          <b>2.</b> Kripto varlık işlemleri 7 gün 24 saat esasına bağlı olarak
          gerçekleştirilir. Ayrıca uluslararası piyasalarda işlemlerin
          yoğunlaştığı saatler ile ülkemiz arasındaki saat farkları
          bulunmaktadır. Bu nedenle fiyatlarda görülen ani ve önemli
          dalgalanmalar mesai saatleri dışında, gece geç ve fiyat hareketlerini
          takip edemeyeceğiniz saatlerde gerçekleşebilir.
        </p>
        <p>
          <b>3.</b> Kripto varlıklara ilişkin fiyat tahminlerinde klasik
          finansal araçlara özgü model ve analiz teknikleri doğru sonuçlar
          vermeyebilir. Bitİstanbul tarafından hazırlanan ve Platform ya da
          internet sitesinde yayımlanan veya sizlerle doğrudan paylaşılan
          bülten, analiz raporları ile benzeri dokümanlar hiçbir şekilde yatırım
          danışmanlığı, fiyat garantisi veya fiyatlara ilişkin kesin bir öngörü
          olarak kabul edilemez. Bu bültenlere dayalı olarak alacağınız yatırım
          kararları tamamen şahsınıza ait olup bu bülten, rapor ve benzeri diğer
          dokümanlara ya da paylaşımlara dayalı yatırım yapılması sonucunda
          karşı karşıya kalınacak herhangi bir zarar, ziyan, kar mahrumiyeti
          veya mal varlığı değer düşüklüğünden Bitİstanbul sorumlu tutulamaz.
        </p>
        <p>
          <b>4. </b> Sosyal medyada kripto varlıklara ilişkin çok fazla yorum,
          analiz, değerlendirme ve hatta yönlendirme yer almaktadır. Bu
          paylaşımlara dayalı olarak alınacak yatırım kararları, paylaşımlar ve
          beklentileriniz çerçevesinde sonuç doğurmayabilir. Özellikle gerçek
          kişiler tarafından yapılan sosyal medya paylaşımlarına dayalı işlem
          gerçekleştirmemenizi öneririz. Bu paylaşımlara dayalı işlem
          gerçekleştirmeden önce mutlaka bir ikinci görüş almaya çalışın.
        </p>
        <p>
          <b>5.</b> Platformda fiyatlar alıcı ve satıcı tarafından belirlenir.
          Bitİstanbul sadece alıcı ve satıcıyı bir araya getiren platformun
          işleticisidir. Bitİstanbul emirlerin kısmen veya tamamen
          gerçekleşeceğini hiçbir şekilde garanti etmez. Piyasada yeterli
          derinliğin olmadığı durumlarda emirleriniz gerçekleşmeyebilir.
        </p>
        <p>
          <b>6.</b> Bitİstanbul Platformda listelemiş olduğu kripto varlıklara
          ilişkin hiçbir fiyat garantisi veya taahhüdü vermez. Bitİstanbul,
          Platformda listelenen kripto varlıkları kendi takdirine bağlı olarak
          önceden herhangi bir bildirimde bulunmaksızın listeden çıkarılabilir,
          işleme kapatabilir. Bitİstanbul Platformda listelediği kripto
          varlıkların her zaman piyasada işlem görmesi konusunda garanti ve
          taahhüt vermez.
        </p>
        <p>
          <b>7.</b> Kripto varlıklar likidite riski içerir. Likidite sıkışıklığı
          satış yapmanızı veya pozisyonlarınızı istediğiniz zaman kapatmanızı
          zorlaştırabilir veya imkânsız hale getirebilir. Likidite sıkışıklığı
          sadece hızlı fiyat hareketlerinin olduğu zamanlarda değil herhangi bir
          zamanda ortaya çıkabilir.
        </p>
        <p>
          <b>8.</b> Herhangi bir fiyat belirtmeden girmiş olduğunuz piyasa
          emirlerinde, yaşanan fiyat hareketleri nedeniyle emir iletirken
          ekranda görmüş olduğunuz fiyat ile emrin gerçekleştiği fiyat arasında
          fark olabilir. Bitİstanbul piyasaya ileteceğiniz piyasa emirlerinin,
          zarar – durdur veya kar – al gibi şarta bağlı limit emirlerin gerek
          miktar gerekse de fiyat olarak gerçekleşmesini garanti etmez. Bu
          şekilde şarta bağlı emirlerin kullanılmış olması durumunda dahi
          kayıplarınızı sınırlama imkânınız olmayabilir.
        </p>
        <p>
          <b>9.</b> Yabancı para cinslerine bağlı kripto varlıklarda, diğer
          risklere ek olarak başa kur riski olmak üzere, ilgili para birimine
          veya kripto varlığa yönelik kısıtlamalar, engellemeler ya da kripto
          varlıklar genelinde ek veya yeni vergiler getirilmesi gibi riskler söz
          konusu olabilir.
        </p>
        <p>
          <b>10.</b> Bitİstanbul hiçbir şekilde fon veya varlık yönetmez.
          Nakdinize ya da kripto varlıklarınıza erişim ve alım – satım
          sorumluluğu ile bu işlemler sonucunda oluşacak kar – zarar tamamen
          sizin inisiyatifinizde ve sorumluluğunuzdadır.
        </p>
        <p>
          <b>11.</b> Bitİstanbul gerekli gördüğü hallerde, listelediği kripto
          varlıklara yönelik dilediği zaman ve süreyle piyasa performansını
          kısmen veya tamamen engelleyecek nitelikte tavan ya da taban fiyat
          uygulaması getirebilir. Buna ek olarak Bitİstanbul zorunlu limit emir
          uygulaması, piyasa veya limit emirlerin işlemlere kapatılması,
          maksimum emir değeri sınırı gibi bunlarla sınırlı olmamak üzere uygun
          göreceği her türlü tedbiri alma ve uygulama hakkına sahiptir.
        </p>
        <p>
          <b>12.</b> Bitİstanbul Platformun uygun ve doğru bir şekilde çalışması
          için en iyi gayreti gösterir. Bitİstanbul Platformun her zaman
          çalışacağını garanti etmez. Bilgi sistemleri, yazılım ya teknik alt
          yapıda yaşanacak kesintiler ya da problemler nedeniyle Bitİstanbul
          tarafından sunulan hizmetler aksayabilir, donabilir, kesintiye
          uğrayabilir, emir iletimi, fiyat oluşumu, alım – satım işlemlerinin
          gerçekleşmesi, gerçekleşen emirlerin tasfiyesi gibi hizmetler
          sağlanamayabilir. İşlemlerin kesintiye uğraması durumunda oluşabilecek
          zararlar ile hesap açma, Platformun kullanımı ve bu kullanıma ilişkim
          sonuçların doğurduğu risklerin her türlü sorumluluğu tamamen müşteriye
          aittir. Bitİstanbul hiçbir şekilde sorumluluk kabul etmez.
        </p>
        <p>
          <b>13.</b> Bitİstanbul, sunulacak hizmetlere ilişkin olarak blok –
          zincir ağları ve protokolleri üzerinde erişim ve yazma, saklama ve
          transfer hizmetleri, ödeme sağlayıcıları, teknoloji hizmet
          sağlayıcıları, güvenlik hizmet sağlayıcıları, cüzdan altyapı
          sağlayıcıları, varlık mutabakat hizmetleri, likidite sağlayıcıları,
          kripto varlık saklayıcıları, bankalar ve sigorta şirketleri gibi
          üçüncü taraflardan dolaylı veya doğrudan hizmet veya destek
          alınabilir. Bitİstanbul hiçbir şekilde bu şirketlerin ve bu
          şirketlerin personelleri ile bu hizmetlere müdahale eden kişilerin
          eylemleri ve nedeniyle oluşacak zarar, ziyan, tazminat, değer
          düşüklüğü, kar mahrumiyeti ya da her ne ad altında olursa olsun maddi
          kayıptan, ilgili kişi ya da kurumun seçiminden hiçbir şekilde sorumlu
          tutulamaz.
        </p>
        <p>
          <b>14.</b> Platformda gerçekleşen tüm işlemlere ilişkin her türlü
          zarar, ziyan, değer kaybı, çalınma, bilgisayar korsanlığı, bilgi
          sistemlerine veya cüzdan altyapılarına dışarıdan veya içeriden sızma
          gibi nedenlerle ortaya çıkabilecek her türlü kayıp ve bu kayba ilişkin
          bütün riskler müşteriye aittir.
        </p>
        <p>
          <b>15.</b> Bitİstanbul Platforma, bu Platformda gerçekleştirilen
          işlemlerle varlıklarına ilişkin tüm güvenlik risklerini ortadan
          kaldıramaz. Platforma bağlantı sırasında kullanılan kullanıcı adı ve
          şifrenizin güvenliği tarafınıza aittir. Hesabınıza tahsis edilen
          kullanıcı adı ve şifre ile gerçekleştirilen tüm işlemlerden şahsınız
          sorumlu kabul edilecektir. Kripto varlıkların doğası gereği
          hesabınızda gerçekleştirilecek işlemler geri alınamaz; hileli veya
          yetkisiz işlemlerden kaynaklanan kayıpların telafisi mümkün
          olamayabilir. Bu şekilde oluşacak tüm zarar, ziyan, tazminat, değer
          düşüklüğü, kar mahrumiyeti veya her ne ad altında olursa olsun maddi
          kaybın sorumluluğu müşteriye aittir.
        </p>
        <p>
          <b>16.</b> Kripto varlıkların teknolojik özellikleri göz önüne
          alındığında, aşağıdakiler dahil ancak bunlarla sınırlı olmamak üzere
          bir dizi ek içsel riski barındırır; bu riskler
        </p>
        <p>
          <b>a.</b> İlgili kripto varlığın dayandığı teknolojiler veya ekonomik
          sistemlerle ilgili olarak meydana gelen arızalar, kusurlar, bilgisayar
          korsanlığı, hatalar, protokol arızaları, ağ ve protokol sonlanmaları,
          onaylayıcıların ağlardan çekilmeleri ya da erişememeleri sonucu
          doğabilecek zararlar, kripto varlıkların, ağların, protokollerin ve
          işlemlerin genel olarak veya herhangi bir resmi kurum, merci veya
          devlet tarafından yasaklanması ya da engellenmesi ile öngörülemeyen
          diğer durumlar;
        </p>
        <p>
          <b>b.</b> İlgili kripto varlığa ilişkin işlemlerin ait olduğu blok
          zincire kazınmasının, blok zincir yoğunluğu, kesintisi ya da başkaca
          bir şekilde gecikmesi ya da hiç gerçekleştirilememesi,
        </p>
        <p>
          <b>c.</b> Blok zincirin doğası gereği kripto varlıklarda
          gerçekleştirilen hileli ya da kazara yapılan işlemlerin geri
          alınamaması, bu işlemlerden kaynaklanan kayıpların tazmin edilememesi,
        </p>
        <p>
          <b>d.</b> Kripto varlığın ve üzerinde işlem gördüğü blok zincirin
          eskimesi, teknolojik gelişmeler karşısında kullanılamaz, ekonomisinin
          anlamsız ya da gerçekleştirilemez hale gelmesi,
        </p>
        <p>
          <b>e.</b> Sayılanlarla sınırlı olmamak üzere, DDos atakları, siber
          saldırılar, e- dolandırıcılık, oltalama, sosyal mühendislik,
          bilgisayar korsanlığı, kötü amaçlı yazılımlar, içeriden veya dışarıdan
          kripto varlık cüzdanlarının şifrelerinin kırılması, ele geçirilmesi,
          hesap ya da cüzdanlara yetkisiz erişim, bu cüzdanlara bağlı özel
          anahtarların (private – key) kopyalanması ya da çalınması, Bitİstanbul
          çalışanlarının tedbirsizlik ve ihmali sonucunda meydana gelebilecek
          zararlar ile çift harcama, platform işlem ve transfer hataları, fikir
          birliğine dayalı veya diğer her türlü madencilik saldırıları, çatallar
          ve sahtekarlık eylemleri
        </p>
        <p>
          Bu risklerin gerçekleşmesinden dolayı müşteri hesabında oluşabilecek
          tüm zarar ve ziyan ile her türlü sorumluluk müşteriye aittir, bu
          risklerin gerçekleşmesinden dolayı Bitİstanbul, paydaşları ve yönetimi
          hiçbir şekilde sorumlu tutulamaz.
        </p>

        <p>
          <b>17.</b> Kripto varlıklar, kripto varlık platformları ve kripto
          varlık hizmet sunucuları mevcut durum itibariyle lisanslama, iş ve
          işlem kuralları özelinde herhangi bir düzenlemeye tabi olmadığı gibi
          SPK, BDDK ya da başkaca bir kamu otoritesi gözetim ve denetimine tabi
          de değildir. Uyuşmazlıklar genel hukuk hükümlerine göre
          sonuçlandırılır. Kripto varlıklar ve platformları önemli ölçüde
          düzenleme riski içerir.
        </p>
        <p>
          <b>18.</b> Kripto varlıkların alım satımına, platformlarda
          listelenmesinde, yurtiçinde veya yurtdışına transferine ya da
          Bitİstanbul tarafından sunulan hizmetlerden herhangi birine, kripto
          varlık platformları ile dolaylı veya doğrudan hizmet sağlayıcılarına
          yasak getirilmesi, işlemlerin durdurulması, alım – satım kazancı
          ve/veya değer artış kazançlarına vergi getirilmesi gibi nedenlerle
          kripto varlıklar değerini kısmen veya tamamen kaybedebilir, müşteri
          varlıklarına ulaşılamaz veya herhangi bir şekilde kullanılamaz,
          transfer veya alım – satıma konu edilemez hale gelebilir. Bitİstanbul
          bu durumlar nedeniyle müşteri nezdinde ortaya çıkacak zarar, ziyan,
          değer düşüklüğü, kar mahrumiyeti ya da her ne ad altında olursa olsun
          herhangi bir maddi kayıptan hiçbir şekilde sorumlu tutulamaz, tüm risk
          ve sorumluluk müşteriye aittir.
        </p>
        <p>
          <b>19.</b> Bitistanbul, uhdesinde açılmış hesaplarda şüpheli bir
          faaliyette bulunulduğu, yasalara veya Kullanım Sözleşmesi'ne aykırı
          davranıldığı yönünde makul bir şüphe duyması halinde ya da diğer meşru
          nedenlerle belirli bir süreyle veya süresiz şekilde kısmen veya
          tamamen müşteri hesaplarını dondurabilir, hesapta bulunan nakit veya
          kripto varlıkların transferlerini kısmen veya tamamen engelleyebilir,
          hesabı kapatabilir, hesapta bulunan nakit ya da kripto varlıklara
          tedbir uygulayabilir, kullanımını kısıtlayabilir. Bu durumda müşteri
          hesabında işlem gerçekleştirilemeyebilir veya transfer
          yapılamayabilir.
        </p>
        <p>
          <b>20.</b> Bitİstanbul nezdinde bulunan nakit ve kripto varlıklar,
          menkul kıymet yatırımcı tazmin fonu, mevduat sigorta fonu veya benzeri
          yasal veya özel, herhangi bir kamu, kamu teşekkülü, kamu iştiraki,
          düzenleyici otorite veya sektör destekli sigorta veya koruma programı
          kapsamında değildir. Bu varlıkların sorumluluğu müşteriye ait olup
          içeriden veya dışarıdan kripto varlık cüzdanlarının şifrelerinin
          kırılması ve ele geçirilmesi, hesap ve cüzdanlara yetkisiz erişim, bu
          cüzdanlara bağlı özel anahtarların (private – key) kopyalanması veya
          çalınması, Bitİstanbul çalışanlarının tedbirsizlik ve ihmali sonucunda
          meydana gelebilecek zararlar nedeniyle Bitİstanbul’a herhangi bir
          sorumluluk yüklenemez.
        </p>
        <p>
          <b>21.</b> Kripto varlıklar ve ilgili ağları ve protokolleri
          kullanmanın ciddi riskler içerir. Kripto varlıklar ve ilgili tüm
          protokoller ve ağlarla ilgili tüm riskler hakkında bilgi edinmek
          müşterinin görevidir. Bitİstanbul, kripto varlıkların değeri veya
          herhangi bir ilgili ağ veya protokolün güvenliği veya performansı ile
          ilgili hiçbir beyanda bulunmaz veya garanti vermez.
        </p>
        <p>
          <b>22.</b> Bitİstanbul, kripto varlıkların kullanılabilirliği veya
          erişilebilirliği ile ilgili hiçbir beyanda bulunmaz veya garanti
          vermez. Bitİstanbul, müşteri tarafından başlatılan transferlerin blok
          zincire zamanında işlenmesini sağlamak için makul çabayı gösterir.
          Bitİstanbul kendi kontrolü dışındaki faktörlere bağlı olan
          transferlerin işlenmesi ya da tamamlanması için gereken süreye ilişkin
          hiçbir beyanda bulunmaz veya garanti vermez. Zincir yoğunluğu veya
          benzeri nedenlerle yaşanacak gecikmeler nedeniyle ortaya çıkacak her
          türlü zarar, ziyan, tazminat, değer düşüklüğü, kar mahrumiyeti veya
          her ne ad altında olursa olsun maddi ve manevi kayıp nedeniyle
          Bitİstanbul’a herhangi bir sorumluluk yüklenemez.
        </p>
        <p>
          Müşteri tüm bu Risk Bildirimi’nde yer alan maddeleri ve
          bilgilendirmeleri okuduğunu, anladığını ve sözleşmenin ayrılmaz bir
          parçası olarak kabul ettiğini; kripto varlıkların bu Risk
          Bildirimi’nde sayılan risklerle sınırlı olmayabileceğini; işbu
          risklerin gerçekleşmesi durumunda tüm sorumluluğun kendisinde olduğunu
          bilerek ve kabul ederek Bitİstanbul hizmetlerinden yararlanmak
          istediğini ve bu sebeplerle oluşabilecek zarar, ziyan, değer
          düşüklüğü, tazminat, kar mahrumiyeti ya da her ne ad altında olursa
          olsun maddi ve manevi zararlardan Bitİstanbul’u hiçbir şekilde sorumlu
          tutmayacağını beyan, kabul ve taahhüt eder.
        </p>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

import { Component, OnInit } from '@angular/core';
import { IntermediateService } from '../../Services/intermediate.service';
 
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  isDisabled = false;
  error: string | null = null;
  success: string | null = null;
  resResult: any;

  forgotPasswordDataform = {
    email: null,
    recaptcha: null,
  };

  constructor(private myservice: IntermediateService) {}

  ngOnInit(): void {
  }

  onChangeEvent(event: any): void {
    this.error = null;
    this.success = null;
  }

  onSubmit(): void {
    this.error = null;
    this.success = null;
    this.isDisabled = true;
    this.forgotPasswordDataform.recaptcha = null;

   this.myservice.getForgotPasswordLink(this.forgotPasswordDataform).subscribe(
      (data) => this.handleResponse(data),
      (error) => this.handleError(error)
    );
  }

  handleResponse(res: any): void {
    this.resResult = res;
    this.isDisabled = false;
    if (!res.success) {
      this.error = res.message;
    } else {
      this.success = res.message;
    }
  }

  handleError(error: any): void {
    this.isDisabled = false;
    this.error = error.message;
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class IntermediateService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  login(data) {
    return this.http.post(`${this.baseUrl}/login`, data);
  }

  register(data) {
    return this.http.post(`${this.baseUrl}/register`, data);
  }

  getForgotPasswordLink(data) {
    return this.http.post(`${this.baseUrl}/password/email`, data);
  }

  resetPassword(data) {
    return this.http.post(`${this.baseUrl}/password/reset`, data);
  }
  
  userlogout(data = []) {
    return this.http.post(`${this.baseUrl}/logout`, data);
  }
}

<!-- Create Ticked Pages -->
<app-header></app-header>

<app-alert-modal
  *ngIf="error"
  [error]="error"
  (close)="error = null"
></app-alert-modal>

<app-alert-modal-success
  *ngIf="success"
  [success]="success"
  (close)="success = null"
>
</app-alert-modal-success>

<article>
  <section class="innerbannerpage">
    <div class="container">
      <div class="row">
        <!-- createTickedHeader -->
        <div class="box-section">
          <table id="createTickedHeader">
            <tbody>
              <tr>
                <td style="text-align: center; height: 35px">
                  <img
                    src="assets/images/071.svg"
                    alt=""
                    class="text-center"
                    style="width: 16px; margin-right: 155px"
                  />
                </td>
              </tr>
              <tr>
                <td style="text-align: center">
                  <img
                    alt=""
                    src="assets/images/070.svg"
                    class="text-center"
                    style="width: 24px; vertical-align: sub"
                  />
                  &nbsp;&nbsp;
                  <small> {{ "CreateTicket" | translate }}</small>
                </td>
              </tr>
              <tr>
                <td colspan="3" style="height: 25px">
                  <hr />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-8 col-xs-12 center-content">
          <div class="centerwhitebox">
            <div class="title-header text-center">&nbsp;</div>
            <form
              class="innerformbg"
              #createticketForm="ngForm"
              (ngSubmit)="onSubmit()"
              id="createticketForm"
            >
              <!-- title -->
              <!-- pattern="^[a-zA-Z0-9ğüşöçjıĞÜŞÖÇJI?!(),.()\s]+$" -->
              <div class="form-group cpybtnbg">
                <input
                  type="text"
                  name="title"
                  class="form-control"
                  [class.is-invalid]="title.invalid && title.touched"
                  #title="ngModel"
                  maxlength="20"
                  placeholder="{{ 'Subject' | translate }} giriniz"
                  [(ngModel)]="form.title"
                  pattern=""
                  required
                />
                <span *ngIf="title.errors">
                  <small class="text-danger-input" *ngIf="title.errors.required"
                    >{{ "PleaseSpecifySubject" | translate }}
                  </small>
                  <small
                    class="text-danger-input"
                    *ngIf="title.errors.pattern"
                    >{{ "Messagevaild" | translate }}</small
                  >
                </span>
              </div>

              <!-- message -->
              <div class="form-group cpybtnbg1">
                <textarea
                  rows="5"
                  class="form-control"
                  name="message"
                  style="height: 200px !important"
                  placeholder="Mesajınızı giriniz"
                  [class.is-invalid]="message.invalid && message.touched"
                  #message="ngModel"
                  [(ngModel)]="form.message"
                  required
                ></textarea>

                <span *ngIf="message.errors">
                  <small
                    class="text-danger-input"
                    *ngIf="message.errors.required"
                    >{{ "PleaseMessage" | translate }}
                  </small>
                  <small
                    class="text-danger-input"
                    *ngIf="message.errors.pattern"
                    >{{ "Messagevaild" | translate }}</small
                  >
                </span>
              </div>

              <!-- submit -->
              <div class="col-md-12 text-center">
                <input
                  [disabled]="!createticketForm.valid"
                  type="submit"
                  name="submit"
                  class="btn orange-btn"
                  value="{{ 'Submit' | translate }}"
                  *ngIf="!isDisabled"
                />
                <input
                  type="submit"
                  name=""
                  value="{{ 'PleaseWait' | translate }}..."
                  class="btn orange-btn mt-10"
                  disabled
                  *ngIf="isDisabled"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <br />

      <!-- destek -->
      <div class="row">
        <div class="text-center backtosupport">
          <a [routerLink]="'/destek'"> {{ "Backtosupport" | translate }}</a>
        </div>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

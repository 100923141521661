import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { io } from "socket.io-client";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class WebSocketIOService {
  private socketIOurlBoard: string = environment.socketIOurlBoard; // socket URL
  private websocketIOBoard: any;

  // Tüm verileri tutacak genel bir subject
  private dataSubject = new BehaviorSubject<any>(null);

  constructor() {
    this.connectSocket();
  }

  private connectSocket(): void {
    const token = localStorage.getItem("token");

    this.websocketIOBoard = io(this.socketIOurlBoard, {
      transports: ["websocket"],
      query: { token },
    });

    // Hata ve bağlantı durumlarını yönet
    this.websocketIOBoard.on("connect_error", (err) => {
      console.error("Bağlantı Hatası");
    });

    this.websocketIOBoard.on("disconnect", (reason) => {
      console.warn("Bağlantı Kesildi:", reason);
    });

    // Genel veri dinleyicisi - tüm yeni verileri dataSubject'e gönder
    this.websocketIOBoard.on("new_data", (data) => {
      this.dataSubject.next(data); // Veriyi genel subject'e gönder
    });
  }

  setBookRoom(symbol) {
    this.websocketIOBoard.emit("set_book_room", symbol);
  }


  setPair(coin1, coin2) {
    const symbol = { coin1, coin2 };
    this.websocketIOBoard.emit("set_pair", symbol);
  }
  
  // Genel veri çekme fonksiyonu
  getData(): Observable<any> {
    return this.dataSubject.asObservable();
  }

  // Tüm pair odalarından çıkma işlemi (leaveAllPairRooms)
  leaveAllPairRooms() {
    if (this.websocketIOBoard) {
      this.websocketIOBoard.emit("leave_all_pair_rooms");
    }
  }

  // Bağlantıyı kesme işlemi
  disconnectSocket() {
    if (this.websocketIOBoard) {
      this.websocketIOBoard.disconnect();
    }
  }
}

import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HttpClientModule,
  HttpClientXsrfModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { ApiInterceptor } from './Services/api-interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TokenInterceptorService } from '././Services/token-interceptor.service';
import { AppRoutingModule } from './app-routing.module';
import { DatePipe } from '@angular/common';
import {
  NgxUiLoaderModule,
  NgxUiLoaderHttpModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
} from 'ngx-ui-loader';

import {
  RecaptchaModule,
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
} from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MatInputModule } from '@angular/material/input';
//import { TooltipModule } from 'ng2-tooltip-directive';
import { LyThemeModule, LY_THEME } from '@alyle/ui';
import { LyResizingCroppingImageModule } from '@alyle/ui/resizing-cropping-images';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { LyTypographyModule } from '@alyle/ui/typography';
/** Import theme */
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layouts/header/header.component';
import { headerEmptyComponent } from './layouts/headerEmpty/headerEmpty.component';
import { UserheaderComponent } from './layouts/userheader/userheader.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { HomeComponent } from './Components/home/home.component';
import { ContactComponent } from './Components/contact/contact.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ProfileComponent } from './Components/profile/profile.component';
import { UserfooterComponent } from './layouts/userfooter/userfooter.component';
import { WalletsComponent } from './Components/wallets/wallets.component';
import { SupportComponent } from './Components/support/support.component';
import { ChatComponent } from './Components/chat/chat.component';
import { CreateticketComponent } from './Components/createticket/createticket.component';
import { ExchangeComponent } from './Components/exchange/exchange.component';
import { smsVerificationComponent } from './Components/smsVerification/smsVerification.component';
import { sequrityAccountComponent } from './Components/sequrityAccount/sequrityAccount.component';
import { verifyemailComponent } from './Components/emailVerification/verifyemail.component';
import { fileuploadComponent } from './Components/fileupload/fileupload.component';
import { fileuploadStatusComponent } from './Components/fileuploadStatus/fileuploadStatus.component';
import { DepositComponent } from './Components/deposit/deposit.component';
import { WithdrawComponent } from './Components/withdraw/withdraw.component';
import { DeposithistroyComponent } from './Components/deposithistroy/deposithistroy.component';
import { WithdrawhistroyComponent } from './Components/withdrawhistroy/withdrawhistroy.component';
import { TradehistroyComponent } from './Components/tradehistroy/tradehistroy.component';
import { BanklistComponent } from './Components/banklist/banklist.component';
import { PageNotFoundComponent } from './Components/page-not-found/page-not-found.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { KvkkComponent } from './StaticPages/kvkk/kvkk.component';
import { CompanyInfoComponent } from './StaticPages/company-info/company-info.component';
import { CompanyAnnouncementComponent } from './StaticPages/company-announcement/company-announcement.component';
import { CompanyLegalRegulationComponent } from './StaticPages/company-legal-regulation/company-legal-regulation.component';
import { UserAgreementComponent } from './StaticPages/user-agreement/user-agreement.component';
import { PrivacyPolicyComponent } from './StaticPages/privacy-policy/privacy-policy.component';
import { UserNotificationComponent } from './StaticPages/user-notification/user-notification.component';
import { WhatIsBitcoinComponent } from './StaticPages/what-is-bitcoin/what-is-bitcoin.component';
import { HowToStoreBitcoinComponent } from './StaticPages/how-to-store-bitcoin/how-to-store-bitcoin.component';
import { HowToBuyBitcoinComponent } from './StaticPages/how-to-buy-bitcoin/how-to-buy-bitcoin.component';
import { SecurityPageComponent } from './StaticPages/security-page/security-page.component';
import { BitcoinWalletComponent } from './StaticPages/bitcoin-wallet/bitcoin-wallet.component';
import { DepositAndWithdrawalComponent } from './StaticPages/deposit-and-withdrawal/deposit-and-withdrawal.component';
import { FSQComponent } from './StaticPages/fsq/fsq.component';
import { LimitsAndRulesComponent } from './StaticPages/limits-and-rules/limits-and-rules.component';
import { CookiesPolicyComponent } from './StaticPages/cookies-policy/cookies-policy.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { SelectPaginationModule } from 'ngx-select-pagination';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { AlertModalComponent } from './Components/modals/alert-modal/alert-modal.component';
import { AlertModalSuccessComponent } from './Components/modals/alert-modal-success/alert-modal-success.component';
import { TwoFactorsLoginComponent } from './Components/two-factors-login/two-factors-login.component';
import { TwofaKriptoComponent } from './Components/modals/twofa-kripto/twofa-kripto-seq.component';
import { DeviceVerifyComponent } from './Components/deviceVerification/device-verify.component';
import { LocalizedDatePipe } from './Services/localized-date.pipe';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#00a8ff',
  bgsPosition: 'center-center',
  bgsSize: 50,
  bgsType: SPINNER.rectangleBounce, // background spinner type
  fgsType: SPINNER.rectangleBounce, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
  hasProgressBar: false,
  fgsColor: '#00a8ff', // icon color
  fgsSize: 50,
  bgsOpacity: 0.8,
  overlayColor: '#202549',
  fgsPosition: 'center-center',
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    headerEmptyComponent,
    FooterComponent,
    HomeComponent,
    ContactComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ProfileComponent,
    UserheaderComponent,
    UserfooterComponent,
    WalletsComponent,
    SupportComponent,
    ChatComponent,
    CreateticketComponent,
    ExchangeComponent,
    smsVerificationComponent,
    sequrityAccountComponent,
    verifyemailComponent,
    fileuploadComponent,
    fileuploadStatusComponent,
    DepositComponent,
    WithdrawComponent,
    DeposithistroyComponent,
    WithdrawhistroyComponent,
    TradehistroyComponent,
    BanklistComponent,
    PageNotFoundComponent,
    KvkkComponent,
    CompanyInfoComponent,
    CompanyAnnouncementComponent,
    CompanyLegalRegulationComponent,
    UserAgreementComponent,
    PrivacyPolicyComponent,
    UserNotificationComponent,
    WhatIsBitcoinComponent,
    HowToStoreBitcoinComponent,
    HowToBuyBitcoinComponent,
    SecurityPageComponent,
    BitcoinWalletComponent,
    DepositAndWithdrawalComponent,
    FSQComponent,
    LimitsAndRulesComponent,
    CookiesPolicyComponent,
    AlertModalComponent,
    AlertModalSuccessComponent,
    TwoFactorsLoginComponent,
    TwofaKriptoComponent,
    DeviceVerifyComponent,
    LocalizedDatePipe
  ],
  imports: [
    BrowserModule,
    ModalModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'My-Xsrf-Cookie',
      headerName: 'My-Xsrf-Header',
    }),
    BrowserAnimationsModule,
    MatInputModule,
    ReactiveFormsModule,
    LyThemeModule.setTheme('minima-light'), // or minima-light
    LyResizingCroppingImageModule,
    LyButtonModule,
    LyIconModule,
    LyTypographyModule,
    NgxPaginationModule,
    SelectPaginationModule,
    BsDatepickerModule.forRoot(),
    NgxUiLoaderHttpModule.forRoot({ showForeground: false }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    RecaptchaModule,
    RecaptchaFormsModule,
    Ng2SearchPipeModule,
    Ng2TelInputModule,

    Ng2OrderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader, // burada doğru fonksiyonu kullandığınızdan emin olun
        deps: [HttpClient],
      },
    }),
    // TooltipModule
  ],
  providers: [
    { provide: LY_THEME, useClass: MinimaLight, multi: true },
    { provide: LY_THEME, useClass: MinimaDark, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6Le5KqYZAAAAAKEIe0KxinDwYdYvLpI4m9VI-h9r',
      } as RecaptchaSettings,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  //return new TranslateHttpLoader(http);
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

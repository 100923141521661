import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WalletService } from 'src/app/Services/wallet.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
declare var $: any;
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: 'app-banklist',
  templateUrl: './banklist.component.html',
  styleUrls: ['./banklist.component.css'],
})
export class BanklistComponent implements OnInit {
  modalRef: BsModalRef;
  lstBank: any = [];
  public banks = null;
  public error = null;
  public data = null;
  public iban = null;
  public error2 = false;
  public success = null;
  public selectedBankId = 0;
  private bankListSubscription: Subscription | undefined;
  updateMode = false;

  public form = {
    currency: 'TR',
    account_name: null,
    account_no: null,
    bank_name: null,
    bank_branch: null,
    bank_code: null,
    bank_address: null,
    username: null,
  };

  constructor(private wallet: WalletService, private router: Router, private metaService: Meta, private titleService: Title) {}

  ngOnInit() {
    // Meta etiketlerini ayarla
    this.metaService.addTags([
      {
        name: "description",
        content:
          "Kolay kullanım, yüksek likidite ve hızlı kripto işlemleri. 7/24 para yatırma çekme. Rekabetçi fiyatlar, düşük komisyon oranlarıyla güvenle kripto al, sat, sakla!",
      },
      {
        name: "keywords",
        content: "hesap",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);

    const pageTitle = "Bitistanbul | Türkiye'nin Güvenilir Kripto Para Platformu";
    this.titleService.setTitle(pageTitle);
 
    this.getTicketlist();
  }

  ngOnDestroy(): void {
    if (this.bankListSubscription) {
      this.bankListSubscription.unsubscribe();
    }
  }

  addBanks() {
    this.updateMode = false;
    this.error2 = false;
    this.error = null;
    this.success = null;
    this.form = {
      currency: 'TR',
      account_name: null,
      account_no: null,
      bank_name: null,
      bank_branch: null,
      bank_code: null,
      bank_address: null,
      username: null,
    };
    $('#BankAddModal').modal('show');
  }


  editBank(accountBank) {
    this.updateMode = true;
    this.error2 = false;
    this.error = null;
    this.success = null;
    // Set form data for updating
    this.form = { ...accountBank };
    $('#BankAddModal').modal('show');
  }

  cancelModal() {
    $('.modal, .suspend, #suspend, #addressDelete, #exampleModalCenter').modal(
      'hide'
    );

    (<HTMLFormElement>document.getElementById('AddbankForm')).reset();

    this.error2 = false;
    this.error = null;
    this.success = null;
  }

  getTicketlist() {
    this.bankListSubscription = this.wallet.getuserbanklists().subscribe(
      (data) => this.handleBankDetails(data),
      (error) => this.handleError(error)
    );
  }

  handleBankDetails(data) {
    if (data.success) {
      this.lstBank = data?.result;
      this.banks = this.lstBank;
    } else {
      this.router.navigateByUrl('/hesabim');
    }
  }

  onKeyPress(event: any): void {
    const pattern = /[0-9]/;

    // Sadece rakam girişine izin ver
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onSubmit() {
    this.error2 = false;
    this.error = null;
    this.success = null;
    return this.wallet.adduserbankdetails(this.form).subscribe(
      (data) => this.handleResponse(data),
      (error) => this.handleError(error)
    );
  }

  handleResponse(res) {
    if (res.success) {
      this.error = null;
      this.success = res.message;
      (<HTMLFormElement>document.getElementById('AddbankForm')).reset();
      this.exitModal();
    } else {
      this.success = null;
      this.error = res.message;
    }
  }

  bankUpdate() {
    this.error2 = false;
    this.error = null;
    this.success = null;
    const bank = this.lstBank[this.selectedBankId];
    const myFormData = new FormData();
    myFormData.append('bankid', bank.id);
    myFormData.append('account_name', this.form.account_name);
    myFormData.append('account_no', this.form.account_no);
    myFormData.append('bank_name', this.form.bank_name);
    myFormData.append('bank_branch', this.form.bank_branch);
    myFormData.append('bank_code', this.form.bank_code);
    myFormData.append('bank_address', this.form.bank_address);
    return this.wallet.edituserbankdetails(myFormData).subscribe(
      (data) => this.handleResponseBankUpdate(data),
      (error) => this.handleError(error)
    );
  }

  handleResponseBankUpdate(res) {
    if (res.success) {
      this.error = null;
      this.success = res.message;
      (<HTMLFormElement> document.getElementById('AddbankForm')).reset();

      this.exitModal();
    } else {
      this.success = null;
      this.error = res.message;
    }
  }

  delete(bank) {
    if (confirm('Banka kaydını silmek istediğinize emin misiniz ?')) {
      this.wallet.deleteuserbankdetails(bank.id).subscribe((Response) => {
        (error) => this.handleError(error);
        this.getTicketlist();
      });
    }
  }

  IbanOnChange() {
    this.error2 = false;
  }

  exitModal() {
    this.updateMode = false;
    this.getTicketlist();
    $('.modal').modal('hide');
  }

  handleError(error) {
    this.error = error.message;
  }
}

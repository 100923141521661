<!-- Yatırma Çekme Pages -->
<app-header></app-header>
<article class="innerpagearticle">
  <section class="innerbannerpage">
    <div class="container">
      <div class="row">
        <table id="deposit-withdraw-header">
          <tbody>
            <tr>
              <td style="height: 31px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: 210px"
                />
              </td>
            </tr>
            <tr>
              <td class="text-center">
                <img
                  src="assets/images/070.svg"
                  alt=""
                  style="width: 24px; vertical-align: sub"
                />
                &nbsp;&nbsp;
                <small>Yatırma & Çekme Limitleri</small>
              </td>
            </tr>
            <tr>
              <td style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row row-content">
        <p class="text-center">
          Kimlik bilgileri doğrulanmış tüm Bitistanbul kullanıcıları
          cüzdanlarına kolaylıkla Türk Lirası ve kripto yatırabilir.
        </p>
        <br />
        <p class="text-center">
          <b>FAST</b> ile <b>100.000 TL</b>’ye kadar olan Türk Lirası yatırma
          işlemlerinizi dilediğiniz zaman, dilediğiniz bankadan
          gerçekleştirebilirsiniz.
          <br />

          Bitistanbul cüzdanınıza 
          <b> Vakıfbank</b> ve  <b> Vakıfkatılım</b>  üzerinden<b> 7/24</b> Türk Lirası yatırabilirsiniz.
          Diğer bankalardan yapacağınız, <br />
          100,000.00 TL’nin üzerinde Türk Lirası yatırma işlemleri iş günlerinde
          09:00 -17:00 saatleri arasında Bitistanbul cüzdanınıza yansıyacaktır.
        </p>
        <br />
        <p class="text-center">
          Bitistanbul cüzdanınıza,
          <b>Bitcoin (BTC), Ethereum(ETH), Tether(USDT) </b> cinsinden kripto
          paraları yukarıda belirtilen <br />alt limit kurallarına uyarak,
          <b>7/24 kolayca yatırabilirsiniz.</b>
        </p>
        <br />
        <p class="text-center">
          Türk Lirası çekim işlemlerinde alt limit 10TL'dir.<br />
          Bitistanbul cüzdanlarından yapacağınız Türk Lirası çekme işlemlerinde
          hiçbir ücret ödemezsiniz.
        </p>
        <br />
        <p class="text-center">
          Yatırma ve çekme işlemlerinde üst limitler hesabınıza tanımlanmış
          oranlarda olup hesap tipine göre değişiklik gösterebilmektedir.
        </p>
        <!-- Komisyon Oranları -->
      </div>

      <div class="row">
        <table id="deposit-withdraw-table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col" class="text-center">
                <span>Minimum Yatırma Limiti</span>
              </th>
              <th scope="col" class="text-center">
                <span>Minimum Çekim Limiti</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">BTC</td>
              <td class="text-center">0,001</td>
              <td class="text-center">0,007</td>
            </tr>
            <tr>
              <td class="text-center">ETH</td>
              <td class="text-center">0,01</td>
              <td class="text-center">0,001</td>
            </tr>
            <tr>
              <td class="text-center">USDT</td>
              <td class="text-center">5</td>
              <td class="text-center">20</td>
            </tr>
            <tr>
              <td class="text-center">TRY</td>
              <td class="text-center">0</td>
              <td class="text-center">10</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

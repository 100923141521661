import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ProfileService } from "src/app/Services/profile.service";
import { AuthService } from "src/app/Services/auth.service";
import { TokenService } from "../../Services/token.service";
import { WalletService } from "src/app/Services/wallet.service";
import { SharedService } from "src/app/Services/shared.service";
import { IntermediateService } from "../../Services/intermediate.service";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { of, Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Meta, Title } from "@angular/platform-browser";


declare var $: any;
//declare function tabResponse2FA(): any;
//declare function widAddressSeq(): any;
//declare function tabResponsePhishing(): any;
declare function tabResponseaccountPasschange(): any;
declare function tabResponseFishing(): any;
declare function tabResponseLoginVarlik(): any;
declare function tabResponsetimePeriod(): any;

@Component({
  selector: "app-sequrityAccount",
  templateUrl: "./sequrityAccount.component.html",
  styleUrls: ["./sequrityAccount.css"],
})
export class sequrityAccountComponent implements OnInit {
  hideold_password = true;
  hidenew_password = true;
  hideconfirm_password = true;
  UserLogin: any = [];
  userLoginDevice: any = [];
  userLoginBrowser: any = [];
  userdata: any = [];
  user_fa_types: any = [];
  girisObj: any = [];
  girisObjActive: any = [];
  girisObjActiveEmail: any = [];
  girisObjActiveGoogle: any = [];
  cekmeObjActive: any = [];
  cekmeObjActiveEmail: any = [];
  cekmeObjActiveGoogle: any = [];
  cekimObj: any = [];
  guvenlikObj: any = [];
  guvenlikObjActive: any = [];
  guvenlikObjActiveEmail: any = [];
  guvenlikObjActiveGoogle: any = [];
  delete: any = [];
  isDisabled = false;
  isDisabledFishing = false;
  isDisabledFishingUpd = false;
  isDisabledTimePeriod = false;
  whitelist: any = [];
  display = false;
  public error = null;
  public success = null;
  public lstCoins: any = ["BTC", "ETH", "USDT"];
  public data = null;
  public disabled = false;
  public userlogin = null;
  public lstAddress = null;
  public filterAddr: any;
  public filterAddrType: any;
  public selectedAddr;
  public accountSuspendData;
  public whitelistDeleteItem;
  public accountSuspendSuccess = false;
  public FA2Success;
  public collectionSize: any;
  private walletSubscription: Subscription;
  private profileDetailsSubscription: Subscription;
  private whiteAddress: Subscription;
  private twofa_auth_multi: Subscription;
  private updatePassword: Subscription;
  private device_session_close: Subscription;
  private device_session_delete: Subscription;
  private selectedDeviceCloseBitIst: Subscription;
  private selectedDeviceDeleteBitIst: Subscription;
  isDisabledBackButton: boolean = false;
  page = 1;
  pageSize = 10;
  config: any;
  p: any = 1;
  limit: any = environment.pagination;
  offset: any = 0;

  constructor(
    private profile: ProfileService,
    private Token: TokenService,
    private wallet: WalletService,
    private sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private Auth: AuthService,
    private router: Router,
    private myservice: IntermediateService,
    private ngxService: NgxUiLoaderService,
    private metaService: Meta, private titleService: Title
  ) {}

  ngOnInit() {



    // Meta etiketlerini ayarla
    this.metaService.addTags([
      {
        name: "description",
        content:
          "Kolay kullanım, yüksek likidite ve hızlı kripto işlemleri. 7/24 para yatırma çekme. Rekabetçi fiyatlar, düşük komisyon oranlarıyla güvenle kripto al, sat, sakla!",
      },
      {
        name: "keywords",
        content: "hesap",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);

    const pageTitle = "Bitistanbul | Türkiye'nin Güvenilir Kripto Para Platformu";
    this.titleService.setTitle(pageTitle);
 




    
    this.refreshHistorys();
    this.walletSubscription = this.wallet
      .getAddress()
      .subscribe((data) => this.handleGetAddress(data));
    this.getprofile();
    this.getprofileWhiteAddres();
    this.getDevices();
    this.getDevicesBrowser();
  }

  refreshHistorys() {
    if (this.userLoginBrowser) {
      this.userLoginBrowser
        .map((histroys, i) => ({ id: i + 1, ...histroys }))
        .slice(
          (this.page - 1) * this.pageSize,
          (this.page - 1) * this.pageSize + this.pageSize
        );
    }
  }

  ngOnDestroy() {
    // Tüm abonelikleri iptal et
    if (this.walletSubscription) {
      this.walletSubscription.unsubscribe();
    }
    if (this.profileDetailsSubscription) {
      this.profileDetailsSubscription.unsubscribe();
    }
    if (this.whiteAddress) {
      this.whiteAddress.unsubscribe();
    }
    if (this.twofa_auth_multi) {
      this.twofa_auth_multi.unsubscribe();
    }
    if (this.updatePassword) {
      this.updatePassword.unsubscribe();
    }
    if (this.device_session_close) {
      this.device_session_close.unsubscribe();
    }
    if (this.device_session_delete) {
      this.device_session_delete.unsubscribe();
    }
    if (this.selectedDeviceCloseBitIst) {
      this.selectedDeviceCloseBitIst.unsubscribe();
    }
    if (this.selectedDeviceDeleteBitIst) {
      this.selectedDeviceDeleteBitIst.unsubscribe();
    }
  }

  handleGetAddress(data) {
    if (data.success) {
      this.lstAddress = data.result;
    }
  }

  //#region getDevices

  getDevices(page: number = 1) {
    this.profile.userloginDevice().subscribe(
      (data) => this.handleuserloginDevice(data, page),
      (error) => this.handleError(error)
    );
  }

  handleuserloginDevice(data, page) {
    if (data.success) {
      this.userLoginDevice = data.result;
      this.collectionSize = this.userLoginDevice.length;

      this.config = {
        currentPage: page,
        itemsPerPage: environment.pagination,
        totalItems: this.data?.count,
      };
    }
  }

  getDevicesBrowser(page: number = 1) {
    this.profile.userloginBrowser().subscribe(
      (data) => this.handleDevicesBrowser(data, page),
      (error) => this.handleError(error)
    );
  }

  handleDevicesBrowser(data, page) {
    if (data.success) {
      this.userLoginBrowser = data.result;
      this.collectionSize = this.userLoginBrowser.length;

      this.config = {
        currentPage: page,
        itemsPerPage: environment.pagination,
        totalItems: this.data?.count,
      };
    }
  }

  allDeviceClose() {
    this.device_session_close = this.profile
      .device_session_close(null)
      .subscribe(
        (data) => {
          this.handleSessionResponse(data, "allDeviceClose");
        },
        (error) => this.handleError(error)
      );
  }

  allDeviceDelete() {
    this.device_session_delete = this.profile
      .device_session_delete(null)
      .subscribe(
        (data) => {
          this.handleSessionResponse(data, "allDeviceDelete");
        },
        (error) => this.handleError(error)
      );
  }

  selectedDeviceClose(deviceId: number) {
    this.selectedDeviceCloseBitIst = this.profile

      .device_session_close(deviceId)
      .subscribe(
        (data) => {
          this.handleSessionResponse(data, "selectedDeviceClose");
        },
        (error) => this.handleError(error)
      );
  }

  selectedDeviceDelete(deviceId: number) {
    this.selectedDeviceDeleteBitIst = this.profile
      .device_session_delete(deviceId)
      .subscribe(
        (data) => {
          this.handleSessionResponse(data, "selectedDeviceDelete");
        },
        (error) => this.handleError(error)
      );
  }

  handleSessionResponse(data, action) {
    if (!data.success) {
      this.error = data.message;
    } else {
      this.success = data.message;
      this.getDevices();

      if (action === "allDeviceClose" || action === "allDeviceDelete") {
        setTimeout(() => {
          this.apiLogout();
          this.Token.remove();
          this.Auth.changeAuthStatus(false);
          this.router.navigateByUrl("/giris");
        }, 3000);
      }
    }
  }

  //#endregion getDevices

  //#region getprofile
  getprofile() {
    this.profileDetailsSubscription = this.profile
      .getprofileDetails()
      .subscribe(
        (data) => this.handleProfileDetails(data),
        (error) => this.handleError(error)
      );
  }

  handleProfileDetails(data) {
    if (data.success) {
      this.userdata = data.result;
      // this.girisObj = data.result.giris;
      this.girisObjActive = data.result.giris[0].is_actived;
      this.girisObjActiveEmail = data.result.giris[1].is_actived;
      this.girisObjActiveGoogle = data.result.giris[2].is_actived;
      // this.cekimObj = data.result.cekim;
      this.cekmeObjActive = data.result.cekim[0].is_actived;
      this.cekmeObjActiveEmail = data.result.cekim[1].is_actived;
      this.cekmeObjActiveGoogle = data.result.cekim[2].is_actived;
      //this.guvenlikObj = data.result.guvenlik;
      this.guvenlikObjActive = data.result.guvenlik[0].is_actived;
      this.guvenlikObjActiveEmail = data.result.guvenlik[1].is_actived;
      this.guvenlikObjActiveGoogle = data.result.guvenlik[2].is_actived;
    }
  }

  //#endregion getprofile

  //#region getprofileWhiteAddres

  getprofileWhiteAddres() {
    this.whiteAddress = this.profile.getwhitelistUser().subscribe(
      (data) => this.handlegetprofileWhiteAddres(data),
      (error) => this.handleError(error)
    );
  }

  handlegetprofileWhiteAddres(data) {
    if (data.success) {
      this.whitelist = data.result;
    }
  }

  //#endregion getprofileWhiteAddres

  //#region  2FA Google Doğrulama

  public frm_google_code = {
    google_code: null,
    pageroot: "verifygoogle",
  };
  async FA2Buttonfunc() {
    this.error = null;
    this.success = null;

    return this.profile.verifyCodeSeq(this.frm_google_code).subscribe(
      (data) => this.handleResponseFA2(data),
      (error) => this.handleError(error)
    );
  }

  handleResponseFA2(res) {
    this.FA2Success = res.success;
    if (res.success) {
      this.success = res.message;
    } else {
      this.error = res.message;
    }
  }

  //#endregion 2FA

  //#region Güvenlik ve Doğrulama Ayarları

  input1Value: string;
  input2Value: string;
  input3Value: string;
  input4Value: string;
  input5Value: string;
  input6Value: string;
  input7Value: string;
  input8Value: string;
  input9Value: string;
  isFormChanged = false;

  onFormChange() {
    this.isFormChanged = true;
  }

  loginAndVarlikG() {
    this.error = null;
    this.success = null;
    this.display = false;
    tabResponseLoginVarlik();
  }

  async onSubmitLoginAndVarlik() {
    this.isDisabled = true;
    this.error = null;
    this.success = null;
    this.display = false;
    const selectedCount =
      (this.girisObjActive ? 1 : 0) +
      (this.girisObjActiveEmail ? 1 : 0) +
      (this.girisObjActiveGoogle ? 1 : 0) +
      (this.cekmeObjActive ? 1 : 0) +
      (this.cekmeObjActiveEmail ? 1 : 0) +
      (this.cekmeObjActiveGoogle ? 1 : 0) +
      (this.guvenlikObjActive ? 1 : 0) +
      (this.guvenlikObjActiveEmail ? 1 : 0) +
      (this.guvenlikObjActiveGoogle ? 1 : 0);

    // En az 2 checkbox seçili değilse alert göster
    if (selectedCount < 6) {
      this.error = "İşlemler için en az iki adet yöntem seçmelisiniz. ";
    } else {
      /*
      const actionKey = await this.getActionCreate().toPromise();
      if (!actionKey) {
        // handle error
        this.isDisabled = false;
        return;
      }
      */
      let formDataLoginAndVarlik = {
        twofa_id: {
          "1": this.girisObjActive,
          "2": this.girisObjActiveEmail,
          "3": this.girisObjActiveGoogle,
          "4": this.cekmeObjActive,
          "5": this.cekmeObjActiveEmail,
          "6": this.cekmeObjActiveGoogle,
          "7": this.guvenlikObjActive,
          "8": this.guvenlikObjActiveEmail,
          "9": this.guvenlikObjActiveGoogle,
        },
        //action_key: actionKey,
        pageroot: "change_twofa_auth_multi",
        action_type: "secure",
      };

      this.twofa_auth_multi = this.profile
        .twofa_auth_multi(formDataLoginAndVarlik)
        .subscribe(
          (data) =>
            this.handleResponseChangeFishing(data, formDataLoginAndVarlik),
          (error) => this.handleError(error)
        );
    }
  }

  //#endregion Giriş ve Varlık Güvenlik Ayarları

  //#region Giriş Şifresi Güncelleme

  public form = {
    old_password: null,
    new_password: null,
    confirm_password: null,
    googleverify: true,
  };

  isCheckedPasschangeMethod() {
    this.error = null;
    this.success = null;
    this.form.old_password = "";
    this.form.new_password = "";
    this.form.confirm_password = "";
    this.display = false;
    (<HTMLFormElement>document.getElementById("changePasswordForm")).reset();
    //javascript ile tabpane yönlendirme custom.js
    tabResponseaccountPasschange();
  }

  async onSubmitChangePassw() {
    this.isDisabled = true;
    this.error = null;
    this.success = null;
    this.display = false;

    let formDataChangePassw = {
      "current-password": this.form.old_password,
      "new-password": this.form.new_password,
      "confirm-password": this.form.confirm_password,
      //action_key: actionKey,
      pageroot: "changepassword",
      action_type: "secure",
    };

    this.updatePassword = this.profile
      .updatePassword(formDataChangePassw)
      .subscribe(
        (data) => this.handleResponseChangeFishing(data, formDataChangePassw),
        (error) => this.handleError(error)
      );
  }

  //#endregion Hesap Giriş Şifre Ayarı

  //#region  cekimadresi ekle

  onChangedAddress(event: Event) {
    this.formAddressTitle.address = null;
  }

  AddressAdd() {
    $("#exampleModalCenter").modal("show");
    this.error = null;
    this.success = null;
  }

  setFilterAddr(filterName) {
    var sv = this.lstAddress.filter((t) => t.currency === filterName);
    this.filterAddr = sv;
  }

  public formAddressTitle = {
    addressTitle: null,
    addressTitleUpdate: null,
    address: null,
    addressUpdate: null,
    coin: null,
    coinUpdate: null,
    coinNetwork: null,
    coinNetworkUpdate: null,
  };

  async widrwAddressTitleAdd() {
    this.error = null;
    this.success = null;
    const actionKey = await this.getActionCreate(
      "whitelistcreateaddress"
    ).toPromise();
    if (!actionKey) {
      // handle error
      return false;
    }
    let widrwAddressTitleAdd = {
      title: this.formAddressTitle.addressTitle,
      pair: this.formAddressTitle.coin,
      network: this.formAddressTitle.coinNetwork,
      address: this.formAddressTitle.address,
      action_key: actionKey,
      pageroot: "whitelistcreateaddress",
      action_mail: "whitelistcreateaddress",
    };
    this.sharedService.withdrawFormData(widrwAddressTitleAdd);
    this.display = !this.display;
    this.cancelModal();
    (<HTMLFormElement>document.getElementById("widrwAddressform")).reset();

    return false;
  }

  //#region update

  public selectedlv = 0;
  async widrwAddressTitleUpdate() {
    this.error = null;
    this.success = null;
    const actionKey = await this.getActionCreate().toPromise();
    if (!actionKey) {
      // handle error
      return false;
    }

    var vl = this.whitelist[this.selectedlv];
    let widrwAddressTitleUpdate = {
      id: vl.id,
      title: this.formAddressTitle.addressTitleUpdate,
      pair: vl.pair,
      network: vl.network,
      address: this.formAddressTitle.addressUpdate,
      action_key: actionKey,
      pageroot: "whitelistupdateaddress",
    };
    this.sharedService.withdrawFormData(widrwAddressTitleUpdate);
    this.display = !this.display;
    this.cancelModal();
    (<HTMLFormElement>document.getElementById("widrwAddressform")).reset();
    return false;
  }

  updateWhiteLists(whitelistid) {
    this.error = null;
    this.success = null;
    var vl = this.whitelist[whitelistid];
    this.selectedlv = whitelistid;
    this.formAddressTitle.addressTitleUpdate = vl.title;
    this.formAddressTitle.addressUpdate = vl.address;
  }

  //#endregion  update

  //#region delete

  deleteWhiteListsID(item) {
    this.whitelistDeleteItem = item;
    setTimeout(() => {
      $("#addressDelete").modal("show");
    }, 500);
  }

  async deleteWhiteLists(whitelistDeleteItem) {
    this.error = null;
    this.success = null;
    this.display = false;
    this.cancelModal();

    var WhiteListsForm = parseFloat(whitelistDeleteItem.id);
    return this.profile.deleteWhiteAddress(WhiteListsForm).subscribe(
      (data) => this.handleResponseDelete(data),
      (error) => this.handleError(error)
    );
  }

  handleResponseDelete(res) {
    if (res.success) {
      //this.success = res.message;
      this.getprofileWhiteAddres();
    } else {
      this.error = res.message;
    }
  }

  cancelModal() {
    $(".modal, .suspend, #suspend, #addressDelete, #exampleModalCenter").modal(
      "hide"
    );
    (<HTMLFormElement>document.getElementById("widrwAddressform")).reset();
    this.error = null;
    this.success = null;
  }

  //#endregion delete

  //#endregion cekimadresi

  //#region Oltalama başlangiç

  public frm_fishing_code = {
    fishing_code: null,
    fishing_status: "1",
    fishing_code_old: null,
    fishing_code_new: null,
  };

  oltalamaResponse() {
    this.error = null;
    this.success = null;
    this.frm_fishing_code.fishing_code_new = null;
    this.display = false;
    this.getprofile();
    tabResponseFishing();
  }

  async FishingButtonfunc() {
    this.error = null;
    this.success = null;
    this.isDisabledFishing = true;
    this.display = false;
    const actionKey = await this.getActionCreate().toPromise();
    if (!actionKey) {
      this.isDisabledFishing = false;
      return false;
    }

    let FishingButtonfunc = {
      fishing_code: this.frm_fishing_code.fishing_code,
      fishing_status: this.frm_fishing_code.fishing_status,
      action_key: actionKey,
      pageroot: "newfishingcode",
    };
    this.sharedService.withdrawFormData(FishingButtonfunc);
    this.display = !this.display;
    this.isDisabledFishing = false;
    return false;
  }

  async FishingButtonfuncUpdate() {
    this.error = null;
    this.success = null;
    this.isDisabledFishingUpd = true;

    let FishingButtonfunc = {
      fishing_code_old: this.userdata.fishing_code,
      fishing_code: this.frm_fishing_code.fishing_code_new,
      pageroot: "changedfishingcode",
      action_type: "secure",
    };

    this.profile.changeFishing(FishingButtonfunc).subscribe(
      (data) => this.handleResponseChangeFishing(data, FishingButtonfunc),
      (error) => this.handleError(error)
    );
  }

  public action_key: string = "";

  handleResponseChangeFishing(res, postData) {
    this.isDisabledFishingUpd = false;
    this.isDisabled = false;
    if (res?.form_error === true) {
      this.error = res.message;
    } else {
      if (this.action_key === "") {
        this.action_key = res.result.action_key;
      }
      postData["action_key"] = this.action_key;
      this.sharedService.withdrawFormData(postData);
      this.display = !this.display;
      this.isDisabledFishingUpd = false;
      return false;
    }
  }

  //#endregion SMS EMAIL Dogrula bolümü

  //#region  zaman aşımı süresi
  isCheckedtimeout_Period() {
    this.error = null;
    this.success = null;
    this.display = false;

    //javascript ile tabpane yönlendirme custom.js
    tabResponsetimePeriod();
  }

  public formTimePeriod = {
    timePeriod: null,
  };

  async onSubmitTimePeriod() {
    this.error = null;
    this.success = null;
    this.isDisabledTimePeriod = true;
    this.display = false;

    const actionKey = await this.getActionCreate().toPromise();
    if (!actionKey) {
      // handle error
      this.isDisabledTimePeriod = false;
      return false;
    }

    let onSubmitTimePeriod = {
      expired_time: this.formTimePeriod.timePeriod,
      action_key: actionKey,
      pageroot: "changedtimeexpired",
    };
    this.sharedService.withdrawFormData(onSubmitTimePeriod);
    this.display = !this.display;
    this.isDisabledTimePeriod = false;
    return false;
  }

  //#endregion zaman aşımı süresi

  //#region action_key
  getActionCreate(action_mail = ""): Observable<string> {
    const formData = new FormData();
    formData.append("action_type", "secure");
    formData.append("action_mail", action_mail);
    formData.append("address", this.formAddressTitle.address);

    return this.profile.actioncreateApiAddress(formData).pipe(
      map((data: any) => {
        if (data.success) {
          return data.result.action_key;
        } else {
          return "";
        }
      }),
      catchError((error) => {
        return of("");
      })
    );
  }

  //#endregion action_key

  //#region askıya al
  suspendMetod() {
    this.error = null;
    this.success = null;
    this.accountSuspendSuccess = true;
    this.cdr.detectChanges();
    setTimeout(() => {
      $("#suspend").modal("show");
    }, 500);
  }

  accountSuspendMethod() {
    this.error = null;
    this.success = null;
    return this.profile.accountSuspend().subscribe(
      (data) => {
        this.handleResponse(data);
        this.cdr.detectChanges();
      },
      (error) => {
        this.handleError(error);
        this.cdr.detectChanges();
      }
    );
  }

  handleResponse(res) {
    this.accountSuspendData = res;
    if (!this.accountSuspendData.success) {
      this.error = this.accountSuspendData.message;
    } else {
      this.accountSuspendSuccess = false;

      setTimeout(() => {
        $("#suspend").modal("show");
      }, 400);
    }
  }

  cancelModalloggedOut(event: MouseEvent) {
    this.ngxService.start();
    setTimeout(() => {
      event.preventDefault();
      this.apiLogout();
      this.Token.remove();
      this.Auth.changeAuthStatus(false);
      document.location.href = "/giris";
    }, 0);
  }

  apiLogout() {
    this.myservice.userlogout().subscribe();
  }
  //#endregion askıya al


backHome() {
  this.isDisabledBackButton = true; // Butonun tıklanabilirliğini kapatıyoruz
  this.display = false;
  setTimeout(() => {
    window.location.reload();
  }, 500);  
}
 

  handleError(error) {
    this.error = error.message;
  }
}

import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/Services/auth.service";
import { TokenService } from "../../Services/token.service";
import { IntermediateService } from "../../Services/intermediate.service";
import { TranslateService } from "@ngx-translate/core";
import { GlobalConstants } from "src/common/global-constants";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ProfileService } from "src/app/Services/profile.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-header-empty",
  templateUrl: "./headerEmpty.component.html",
  styleUrls: ["./headerEmpty.component.css"],
})
export class headerEmptyComponent implements OnInit {
  public loggedIn: boolean;
  public username: null;
  public image: null;
  public error = null;
  informationData: any = [];

  constructor(
    private profile: ProfileService,
    private Auth: AuthService,
    private Token: TokenService,
    private myservice: IntermediateService,
    public translate: TranslateService,
    private ngxService: NgxUiLoaderService,
    private router: Router
  ) {
    translate.addLangs(["English", "Arabic", "Armenian"]);
    translate.setDefaultLang("English");
    if (localStorage.getItem("locale")) {
      const browserLang = localStorage.getItem("locale");
      translate.use(
        browserLang.match(/English|Arabic|Armenian/) ? browserLang : "English"
      );
    } else {
      localStorage.setItem("locale", "English");
      translate.setDefaultLang("English");
    }
  }

  changeLang(language: string) {
    localStorage.setItem("locale", language);
    this.translate.use(language);
  }

  ngOnInit() {
    this.Auth.authStatus.subscribe((value) => (this.loggedIn = value));
    if (this.loggedIn) {
      const userDetails = this.Token.getUserDetails();
      /*@@@*/
      GlobalConstants.token = this.Token;
      this.username = userDetails.name;
      this.image = userDetails.image;
    }

    this.profile.getprofileDetailsInfo().subscribe(
      (data) => this.handleInformationsDetails(data),
      (error) => this.handleError(error)
    );
    // loader
    this.ngxService.start();
    setTimeout(() => {
      this.ngxService.stop();
    }, 500);
  }

  handleInformationsDetails(data) {
    if (data.success) {
      this.informationData = data.result;
    } else {
      this.informationData = [];
    }
  }

  handleError(error) {
    this.error = error.message;
  }

  // logout(event: MouseEvent) {
  //   event.preventDefault();
  //   this.apiLogout();
  //   this.Token.remove();
  //   this.Auth.changeAuthStatus(false);
  //   this.router.navigateByUrl('/giris');
  // }

  // apiLogout() {
  //   this.myservice.userlogout().subscribe();
  // }

  logout(event: MouseEvent) {
    this.ngxService.start();
    setTimeout(() => {
      event.preventDefault();
      this.myservice.userlogout().subscribe();
      this.Token.remove();
      this.Auth.changeAuthStatus(false);
      //this.router.navigateByUrl('/giris');
      document.location.href = "/giris";
    }, 0);
  }
}

<app-header></app-header>
<article>
  <div class="pagecontent">
    <section class="innerbannerpage">
      <div class="container">
        <!-- aboutHeader -->
        <table id="aboutHeader">
          <tbody>
            <tr>
              <td style="height: 31px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: 35px"
                />
              </td>
            </tr>
            <tr>
              <td class="text-center">
                <img
                  alt=""
                  src="assets/images/070.svg"
                  style="width: 24px; vertical-align: sub"
                />
                &nbsp;&nbsp;
                <small>{{ "Chat" | translate }}</small>
              </td>
            </tr>
            <tr>
              <td style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>

        <div class="messagebox" style="color: white">
          <h4 class="h4">
            <span style="color: #eb6e04; font-weight: 600"
              >{{ "Subject" | translate }} :</span
            >

            {{ subject }}
          </h4>
          <!-- <h4 class="h4"> <span style="color: #1f73b3; font-weight: 600;">{{ 'Message' | translate }} :</span>  {{ ticketmessage }}</h4> -->
        </div>

        <!-- content -->
        <div
          class="table-responsive sitescroll"
          style="max-height: 480px"
          data-simplebar
        >
          <div *ngFor="let chat of chats">
            <div class="adminMessages" *ngIf="chat.reply">
              <table style="width: 100%">
                <tr>
                  <td>{{ chat.reply }}</td>
                  <td style="width: 50px" class="text-right">
                    <i>Admin</i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="color: #a5a5a5 !important">{{
                      chat.date
                    }}</span>
                  </td>
                </tr>
              </table>
            </div>
            <div class="meMessages" *ngIf="chat.message">
              <table style="width: 100%">
                <tr>
                  <td>{{ chat.message }}</td>
                  <td style="width: 50px" class="text-right">
                    <i>Ben</i>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="color: #a5a5a5 !important">{{
                      chat.date | localizedDate
                    }}</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <!-- messagescontent form -->
        <div class="mt-20">
          <form
            class="innerformbg"
            id="chatbox"
            #createchatForm="ngForm"
            (ngSubmit)="onSubmit()"
          >
            <div class="form-group">
              <textarea
                placeholder="Mesaj giriniz"
                rows="5"
                class="form-control"
                name="message"
                [class.is-invalid]="message.invalid && message.touched"
                #message="ngModel"
                [(ngModel)]="form.message"
                pattern=""
                required
              ></textarea>
            </div>
            <div class="text-center">
              <button
                [disabled]="!createchatForm.valid"
                type="submit"
                class="btn orange-btn mt-10"
                *ngIf="!isDisabled"
              >
                {{ "Send" | translate }}
              </button>
              <input
                type="submit"
                name=""
                value="{{ 'PleaseWait' | translate }}..."
                class="btn orange-btn mt-10"
                disabled
                *ngIf="isDisabled"
              />
            </div>

            <div class="text-center backtosupport mt-20">
              <a routerLink="/destek"> {{ "Backtosupport" | translate }}</a>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</article>
<app-footer></app-footer>

<app-header></app-header>
<article class="innerpagearticle">
  <section class="innerbannerpage">
    <div class="container">
      <div class="row">
        <!-- ContactHeader -->
        <table id="ContactHeader">
          <tbody>
            <tr>
              <td style="height: 31px">
                <img
                  src="assets/images/071.svg"
                  alt=""
                  class="text-center"
                  style="width: 16px; margin-right: 7px;"
                />
              </td>
            </tr>
            <tr>
              <td class="text-center">
                <img
                  src="assets/images/070.svg"
                  alt=""
                  style="width: 24px; vertical-align: sub"
                />
                &nbsp;&nbsp;
                <small>{{ "Contact" | translate }}</small>
              </td>
            </tr>
            <tr>
              <td style="height: 25px">
                <hr />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>&nbsp;</div>
      <div class="text-center">
        <span class="multicolortext">Aşağıdaki formu doldurarak</span>
        <span class="multicolortext1"> bizimle iletişime geçebilirsiniz</span>
      </div>
      <br />
      <div class="text-center">
        <form
          class="contact100-form validate-form"
          #formGroup="ngForm"
          id="ContactForm"
          (ngSubmit)="onSubmit()"
        >
          <!-- firstName -->
          <div class="form-group row validate-input" id="input-position">
            <input
              type="text"
              id="firstame"
              class="form-control"
              [(ngModel)]="form.firstName"
              name="firstName"
              placeholder="Adınız*"
              pattern=""
              autofocus
              required
            />
            <span class="focus-input100"></span>
          </div>

          <!-- lastName -->
          <div
            class="form-group row validate-input"
            data-validate="Lütfen soyadınızı yazınız"
            id="input-position"
          >
            <input
              class="form-control input100"
              type="text"
              name="lastName"
              type="text"
              placeholder="Soyadınız*"
              [(ngModel)]="form.lastName"
              pattern=""
              required
            />
            <span class="focus-input100"></span>
          </div>

          <!-- email -->
          <div
            class="form-group row validate-input"
            data-validate="Email adresiniz*"
            id="input-position"
          >
            <input
              id="email"
              class="form-control input100"
              type="text"
              name="email"
              [(ngModel)]="form.email"
              name="email"
              placeholder="Email Adresiniz*"
              pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              required
            />
            <span class="focus-input100"></span>
          </div>

          <!-- phone -->
          <div class="form-group row validate-input" id="input-position">
            <input
              id="phone"
              class="form-control input100"
              type="tel"
              name="phone"
              [(ngModel)]="form.phone"
              placeholder="Telefon Numaranız*"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
              pattern=""
              maxlength="10"
              required
            />
            <!-- (input)="validatePhoneNo($event.target)" -->
            <span class="focus-input100"></span>
          </div>

          <!-- msg -->
          <div
            class="form-group row validate-input"
            data-validate="Lütfen Mesajınızı Yazınız"
            id="input-position"
          >
            <textarea
              id="message"
              class="form-control input100"
              name="msg"
              rows="6"
              [(ngModel)]="form.msg"
              placeholder="Mesajınız*"
              pattern=""
              required
            ></textarea>
            <span class="focus-input100"></span>
          </div>

          <!-- messages -->
          <div class="form-group row text-center" id="input-position">
            <div class="alert alert-danger" [hidden]="!error">{{ error }}</div>

            <div class="alert alert-success" [hidden]="!success">
              {{ success }}
            </div>
          </div>

          <!-- Gönder -->
          <div class="form-group row" style="margin-top: -20px !important">
            <input
              type="submit"
              name=""
              value="Formu Gönder"
              *ngIf="!isDisabled"
              [disabled]="!formGroup.form.valid"
              class="btn orange-btn mt-10"
            />
            <input
              type="submit"
              name=""
              value="{{ 'Pleasewait' | translate }}..."
              class="btn orange-btn mt-10"
              disabled
              *ngIf="isDisabled"
            />
          </div>
        </form>
      </div>

      <!-- info -->
      <div class="info">
        <div class="row">
          <div class="col-md-3 div-bordered text-center">
            <img src="assets/images/032.svg" alt="" style="width: 40px" />
            <span>
              <a href="mailto:info@bitistanbul.com"
                >&nbsp;&nbsp;info&#64;bitistanbul.com</a
              ></span
            >
          </div>

          <div class="col-md-1"></div>
          <div class="col-md-3 div-bordered-telephone text-center">
            <img src="assets/images/034.svg" alt="" style="width: 20px" />
            <span class="span-telephone">
              <span
                style="color: #ec6e00 !important; font-size: 15pt !important"
                >+90 (850)</span
              >
              677 77 70<br
            /></span>
          </div>

          <div class="col-md-1"></div>
          <div class="col-md-4 div-bordered-address text-left">
            <table id="adrs">
              <tr>
                <td class="text-left">
                  <img src="assets/images/035.svg" alt="" style="width: 40px" />
                </td>
                <td style="width: 20px">&nbsp;</td>
                <td>
                  Esentepe Mah. Büyükdere Cad. <br />
                  No: 102 Maya Akar Center 16. Kat <br />
                  Daire: 60 Şişli / İSTANBUL
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <!-- iframe -->
      <div class="info-BTisAddress">
        <div class="row">
          <div class="col-md-12 BTisAddress text-left">
            <div>
              <iframe
                width="100%"
                height="300"
                style="margin-top: 10px; margin-bottom: 5px"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d192556.56971664703!2d28.97110269756462!3d41.05376783723484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab650429ab09d%3A0xf94afe9b869417c2!2sMaya%20Akar%20Center!5e0!3m2!1str!2str!4v1681815793141!5m2!1str!2str"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>

export interface Theme {
  id: number;
  icon;
  name: string;
  properties: any;
}
export const theme_bitistanbul: Theme = {
  id: 1,
  icon: "1",
  name: "theme_bitistanbul",
  properties: {
    '--status-bar-bg-color-piyasa':
      'linear-gradient(to bottom, #213259 100%, #0f1128 100%)',
    '--status-bar-bg-color':
      'linear-gradient(to bottom, #213259 10%, #0f1128 100%)',
    '--coinlistfh5-font-weight': '600',
    '--tab-panel-text-color': '#fff',
    '--tab-panel-text-color-td': '#ccc',
    '--tab-panel-text-color-td1': '#fff',
    '--tab-panel-text-color-td2': '#fff',
    '--searchInput-text-color': '#fff',
    '--tab-panel-text-color-td4': '#ccc',
    '--modal-border': '1px solid #246798',
    '--modal-color': '#fff',
    '--input-group-btn-bg': '#fff',
    '--form-steepline': '1px solid rgba(178, 178, 178, .2)',
    '--header-bar-border-top': '1px solid rgba(178, 178, 178, .2)',
    '--header-bar-border-left': '1px solid rgba(178, 178, 178, .2)',
    '--header-bar-border-right': '1px solid rgba(178, 178, 178, .2)',
    '--table-head-border-color': '1px solid rgba(178, 178, 178, .2)',
    '--site-table-nobold-color': '#ccc',
    '--site-table-nobold2-color': '#ccc',
    '--table-tr-hover-color': '#21254a',
    '--table-history-color': '#fff',
    '--table-head-text-color': '#ccc',
    '--table-head-text-color-hvr': 'white',
    '--sell-active': '#ec6e00',
    '--content-bar-border-color': '1px solid rgba(178, 178, 178, .2)',
    '--site-table-border': '1px solid rgba(178, 178, 178, .2)',
    '--table-box-border-color': '1px solid rgba(178, 178, 178, .2)',
    '--header-bar-color': '#ec6e00',
    '--chart-bg-color': 'transparent',
    '--tab-active-color': 'none',
    '--control-background-color': '#fff',
    '--content-head-color': 'none',
    '--tab-panel-text-color2': '#ccc',
    '--red-btn-all': 'white',
    '--btn-group-button-hover': '#54abcb',
    '--sell-hover': '#ec6e00',
    '--btn-group-button-active': '#54abcb',
    '--coins-color': '#fff',
    '--coins-color-weight': '600',
    '--balance-text-color': '#fff',
    '--balance-text-weight': '600',
    '--control-label-color': '#fff',
    '--control-label-background-color': 'none',
    '--control-label-font-weight': '600',
    '--process-header-color': '#fff',
    '--input-group-btn-backg': '#fff',
    '--input-group-label-color': '#21254a',
    '--control-border-color': '#fff',
    // ------------------------------------------------------------------------------------------------------------------------
    // '--content-border-color': 'red',
    // '--content-bg-color': '#24345c',
    // '--container-background-color': '#24345c',
    '--control-color': 'black',
    '--control-group-panel-text-color': '#24345c',
    '--control-group-panel-font-weight': 'bold',
    '--control-group-panel-background-color': '#fff',
    '--control-group-panel-border-color': '#b2b2b2',
    '--page-content-color': '#24345c',
    '--tab-text-color2': '#fff',
    '--icon-colors': '#fff',
    '--valueprice-color': 'white',
    '--valueprice-font-weight': '600',
    '--control-color-placeholder': 'black',
  },
};

export const theme_light: Theme = {
  id: 2,
  icon: '2',
  name: 'theme_light',
  properties: {
    '--status-bar-bg-color-piyasa': 'white',
    '--status-bar-bg-color': 'white',
    '--coinlistfh5-font-weight': '600',
    '--tab-panel-text-color': 'black',
    '--tab-panel-text-color-td': '#6d6a6a',
    '--tab-panel-text-color-td1': '#1e3158',
    '--tab-panel-text-color-td2': 'black',
    '--tab-panel-text-color-td4': '#6d6a6a',
    '--modal-border': '1px solid #fff',
    '--modal-color': 'black',
    '--table-history-color': 'black',
    '--input-group-btn-bg': 'white',
    '--form-steepline': '1px solid rgba(178, 178, 178, .3)',
    '--header-bar-border-left': '1px solid rgba(178, 178, 178, .3)',
    '--header-bar-border-top': '1px solid rgba(178, 178, 178, .3)',
    '--header-bar-border-right': '1px solid rgba(178, 178, 178, .3)',
    '--table-head-border-color': '1px solid rgba(178, 178, 178, .3)',
    '--site-table-nobold-color': '#54abcb',
    '--btn-group-button-active': '#54abcb',
    '--sell-active': '#ec6e00',
    '--site-table-nobold2-color': '#6d6a6a',
    '--table-tr-hover-color': '#e0e0e0',
    '--table-head-text-color-hvr': 'black',
    '--table-head-text-color': '#6d6a6a',
    '--content-bar-border-color': '1px solid rgba(178, 178, 178, .3)',
    '--table-box-border-color': '1px solid rgba(178, 178, 178, .3)',
    '--site-table-border': '1px solid rgba(178, 178, 178, .3)',
    '--header-bar-color': '#ec6e00',
    '--valueprice-color': '#1e3158',
    '--chart-bg-color': 'none',
    '--tab-active-color': 'none',
    '--control-background-color': 'none',
    '--content-head-color': 'none',
    '--tab-panel-text-color2': '#6d6a6a',
    '--red-btn-all': 'black',
    '--btn-group-button-hover': '#54abcb',
    '--sell-hover': '#ec6e00',
    '--coins-color': 'black',
    '--coins-color-weight': '600',
    '--balance-text-color': 'black',
    '--balance-text-weight': '600',
    '--process-header-color': '#2783a5',
    '--control-label-font-weight': '600',
    '--control-border-color': '1px solid rgba(178, 178, 178, .9)',
    '--input-group-btn-backg': 'none',
    '--input-group-label-color': '#1e3158',
    '--searchInput-text-color': 'black',
    // ------------------------------------------------------------------------------------------------------------------------
    // '--container-background-color': '#e6eaf1',
    '--control-color': '#000',
    '--control-group-panel-text-color': '#333',
    '--control-group-panel-font-weight': 'bold',
    '--control-group-panel-background-color': '#fff',
    '--control-group-panel-border-color': '#ccc',
    '--control-label-color': 'black',
    '--control-label-background-color': '',
    '--page-content-color': '#e6eaf1',
    '--tab-hover-color': 'red',
    '--tab-text-color2': '#000',
    '--icon-colors': '#000',
    '--valueprice-font-weight': '600',
    '--control-color-placeholder': 'black',
  },
};

export const theme_dark: Theme = {
  id: 3,
  icon: '3',
  name: 'theme_dark',
  properties: {
    '--status-bar-bg-color-piyasa': 'black',
    '--status-bar-bg-color': 'black',
    '--coinlistfh5-font-weight': '600',
    '--tab-panel-text-color': '#fff',
    '--tab-panel-text-color-td': '#ccc',
    '--tab-panel-text-color-td1': '#fff',
    '--tab-panel-text-color-td2': '#fff',
    '--tab-panel-text-color-td4': '#ccc',
    '--modal-border': '1px solid #fff',
    '--modal-color': '#fff',
    '--input-group-btn-bg': '#303030',
    '--table-history-color': '#fff',
    '--form-steepline': '1px solid rgba(178, 178, 178, .4)',
    '--header-bar-border-top': '1px solid rgba(178, 178, 178, .4)',
    '--header-bar-border-left': '1px solid rgba(178, 178, 178, .4)',
    '--header-bar-border-right': '1px solid rgba(178, 178, 178, .4)',
    '--table-head-border-color': '1px solid rgba(178, 178, 178, .4)',
    '--site-table-nobold-color': '#ccc',
    '--site-table-nobold2-color': '#ccc',
    '--table-tr-hover-color': '#1d1d1d',
    '--table-head-text-color': '#ccc',
    '--sell-active': '#ec6e00',
    '--table-head-text-color-hvr': 'white',
    '--content-bar-border-color': '1px solid rgba(178, 178, 178, .4)',
    '--table-box-border-color': '1px solid rgba(178, 178, 178, .4)',
    '--site-table-border': '1px solid rgba(178, 178, 178, .4)',
    '--header-bar-color': '#ec6e00',
    '--chart-bg-color': 'none',
    '--tab-active-color': 'none',
    '--control-background-color': '#303030',
    '--content-head-color': 'none',
    '--tab-panel-text-color2': '#ccc',
    '--red-btn-all': 'white',
    '--btn-group-button-active': '#54abcb',
    '--btn-group-button-hover': '#54abcb',
    '--sell-hover': '#ec6e00',
    '--coins-color': '#fff',
    '--coins-color-weight': '600',
    '--balance-text-color': '#fff',
    '--balance-text-weight': '600',
    '--process-header-color': '#fff',
    '--control-label-font-weight': '600',
    '--input-group-btn-backg': '#303030',
    '--input-group-label-color': '#b2b2b2',
    '--control-border-color': 'black',
    '--searchInput-text-color': '#fff',
    // ------------------------------------------------------------------------------------------------------------------------
    // '--container-background-color': '#000',
    '--control-color': '#fff',
    '--control-label-color': '#fff',
    '--control-label-background-color': '',
    '--control-group-panel-text-color': '#fff',
    '--control-group-panel-font-weight': 'bold',
    '--control-group-panel-background-color': '#191919',
    '--control-group-panel-border-color': '#b3b3b3',
    '--page-content-color': '#232323',
    '--tab-hover-color': '#191919',
    '--tab-text-color2': '#fff',
    '--valueprice-color': 'white',
    '--valueprice-font-weight': '600',
    '--control-color-placeholder': '#ccc',
  },
};

<footer class="footerbottom">
  <section class="footer-gray-bg">
    <div class="container">
      <div class="footer-links" *ngIf="!loggedIn">
        <div class="footer-logo" id="none-footerLogo">
          <img
            width=""
            height=""
            src="../../../assets/images/logo.svg"
            alt="Bitistanbul Logo"
            class="image1"
            style="margin-top: -13px; width: 189px"
          />
        </div>
        <div class="pull-right bottom-links" id="text-size">
          <li>
            <a routerLink="/giris" routerLinkActive="active">{{
              "Borsa" | translate
            }}</a>
          </li>
          <li>
            <a routerLink="/giris" routerLinkActive="active"
              >{{ "Cüzdan" | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/giris" routerLinkActive="active">{{
              "Yatırma" | translate
            }}</a>
          </li>
          <li>
            <a routerLink="/giris" routerLinkActive="active">{{
              "Çekme" | translate
            }}</a>
          </li>
          <li>
            <a routerLink="/giris" routerLinkActive="active">{{
              "Destek" | translate
            }}</a>
          </li>
          <li>
            <a routerLink="/giris" class="blue-outline"
              ><span class="ss-icon"
                ><img
                  src="assets/images/user-s.png"
                  alt="kullanici"
                  width=""
                  height=""
              /></span>
              {{ "Giriş Yap" | translate }}</a
            >
          </li>
          <li>
            <a routerLink="/hesap-olustur" class="orange-outline">
              <span class="ss-icon"
                ><img
                  alt="Hesap Oluştur"
                  src="assets/images/user-plus.png"
                  width=""
                  height=""
              /></span>
              {{ "Hesap Oluştur" | translate }}</a
            >
          </li>
        </div>
      </div>
      <br />
      <div class="footer-menu">
        <div class="col-md-3">
          <ul class="foot-list">
            <li class="t-blue fnt-bld">{{ "KURUMSAL" | translate }}</li>
            <li>
              <a href="/hakkimizda">{{ "Hakkımızda" | translate }}</a>
            </li>
            <li>
              <a href="/duyurular">{{ "Duyurular" | translate }}</a>
            </li>
            <li>
              <a href="/yasal-duzenlemeler">{{
                "Yasal Düzenlemeler" | translate
              }}</a>
            </li>
            <li>
              <a href="/iletisim">{{ "İletişim" | translate }}</a>
            </li>
          </ul>
        </div>
        <div class="col-md-3">
          <ul class="foot-list">
            <li class="t-blue fnt-bld">{{ "SÖZLEŞMELER" | translate }}</li>

            <li>
              <a href="/kullanici-sozlesmesi">{{
                "Kullanıcı Sözleşmesi" | translate
              }}</a>
            </li>
            <li>
              <a href="/gizlilik-politikasi">{{
                "Gizlilik Politikası" | translate
              }}</a>
            </li>
            <li>
              <a href="/kvkk">{{
                "Kişisel Verilerin Korunması" | translate
              }}</a>
            </li>
            <li>
              <a href="/kullanici-bilgilendirme">{{
                "Kullanıcı Bilgilendirme" | translate
              }}</a>
            </li>
          </ul>
        </div>
        <div class="col-md-3">
          <ul class="foot-list">
            <li class="t-blue fnt-bld">{{ "DESTEK" | translate }}</li>

            <li>
              <a href="/bitcoin-nedir" class="btc">{{
                "Bitcoin Nedir?" | translate
              }}</a>
            </li>
            <li>
              <a href="/bitcoin-nasil-saklanir" class="btc">{{
                "Bitcoin Nasıl Saklanır?" | translate
              }}</a>
            </li>
            <li>
              <a href="/bitcoin-nasil-alinir" class="btc">{{
                "Bitcoin Nasıl Alınır?" | translate
              }}</a>
            </li>
            <li>
              <a href="/sikca-sorulan-sorular">{{
                "Sıkça Sorulan Sorular" | translate
              }}</a>
            </li>
          </ul>
        </div>

        <div class="col-md-3">
          <ul class="foot-list">
            <li class="t-blue fnt-bld">
              {{ "MERAK ETTİKLERİNİZ" | translate }}
            </li>

            <li>
              <a href="/guvenlik">{{ "Güvenlik" | translate }}</a>
            </li>
            <li>
              <a href="/komisyon-oranlari">{{
                "Komisyon Oranları" | translate
              }}</a>
            </li>
            <li>
              <a href="/yatirma-ve-cekme">{{
                "Yatırma Ve Çekme" | translate
              }}</a>
            </li>
            <li>
              <a href="/bitcoin-cuzdani">{{ "Bitcoin Cüzdanı" | translate }}</a>
            </li>
          </ul>
        </div>

        <div class="col-md-3">
          <div class="chat-link">
            <div class="">
              <a routerLink="/iletisim" class="m-border-btn t-white">
                <span class="chat-pic"
                  ><img src="assets/images/support-icon.png" alt="" /></span
                >{{ "Bize Ulaşın" | translate }}</a
              >
            </div>
            <br />

            <!-- <div class="custom-select">
              <label for="languageSelect" class="language-label">{{
                "Dil" | translate
              }}</label>
              <select
                id="languageSelect"
                title="dil"
                (change)="changeLang($event.target.value)"
                [(ngModel)]="selectedLanguage"
              >
                <option value="tr">TR</option>
                <option value="en">EN</option>
              </select>
              <span class="arrow-down"></span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="footerbottomsect footer-gray-bg">
    <div class="container">
      <div class="col-sm-12 col-xs-12">
        <div class="pull-left">
          <ul class="sociallist" *ngFor="let result of data">
            <li>
              <a href="{{ result.fb }}"><i class="fa fa-facebook"></i></a>
            </li>
            <li>
              <a href="{{ result.twitter }}" target="_blank"
                ><i class="fa fa-twitter"></i
              ></a>
            </li>
            <li>
              <a href="{{ result.instagram }}" target="_blank"
                ><i class="fa fa-instagram"></i
              ></a>
            </li>
            <li>
              <a href="{{ result.telegram }}"><i class="fa fa-telegram"></i></a>
            </li>
          </ul>
        </div>
        <div class="chat-link pull-right mob-view">
          <div class="">
            <a class="m-border-btn t-white" href="/iletisim">
              <span class="chat-pic"
                ><img
                  src="assets/images/support-icon.png"
                  alt="chat"
                  width=""
                  height=""
              /></span>
              {{ "Bize Ulaşın" | translate }} <br />
            </a>
          </div>
        </div>
        <div class="footer-container">
          <div class="social-icons">
            <a href="https://www.facebook.com/bitistanbulofficial" target="_blank" rel="noopener">
              <img src="assets/images/facebook-icon.png" alt="Facebook" class="my-imgFacebook" />
            </a>
            <a href="https://twitter.com/bitistanbul" target="_blank" rel="noopener">
              <img src="assets/images/x-icon.png" alt="Twitter" class="my-imgTwitter" />
            </a>
            <a href="https://www.instagram.com/bitistanbulofficial" target="_blank" rel="noopener">
              <img src="assets/images/instagram-icon.png" alt="Instagram" class="my-imgInstagram" />
            </a>
            <a href="https://www.linkedin.com/company/bitistanbul-kripto-para-borsasi/" target="_blank" rel="noopener">
              <img src="assets/images/linkedin-icon.png" alt="Linkedin" class="my-Linkedin" />
            </a>
          </div>
        
          <div class="footer-text t-grey">
            {{ date | date : "yyyy" }} {{
              "Hotto Kripto Varlık Alım Satım Platformu A.Ş. / Tüm Hakları Saklıdır." | translate
            }}
          </div>
        
          <div class="footer-version t-grey">
            Version: {{ currentApplicationVersion }}
          </div>
        </div>
        
        <br />
      </div>
    </div>
  </section>
</footer>


import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-company-info",
  templateUrl: "./company-info.component.html",
  styleUrls: ["./company-info.component.css"],
})
export class CompanyInfoComponent implements OnInit {
  constructor(private metaService: Meta, private titleService: Title) {}

  ngOnInit(): void {

    // Meta etiketlerini ayarla
    this.metaService.addTags([
      {
        name: "description",
        content:
          "Güçlü sermaye altyapısı, yetkin ve tecrübeli ekibimiz, etkin risk yönetimi ve güvenlik tedbirleri ile yatırımcılarına kripto varlık piyasalarında 7/24 en üst düzeyde hizmetlerini sunmaktadır.",
      },
      {
        name: "keywords",
        content: "hakkımızda, Bitistanbul, kripto borsa, sigorta, Bitcoin",
      },
      { name: "robots", content: "index, follow" }, // Arama motorları için
    ]);

    const pageTitle = 'Hakkımızda';
    this.titleService.setTitle(pageTitle);
  }
}
